import React, { Component } from "react";
import { connect } from "react-redux";
import { collateralChangeHistory } from "../../../store/action/collateralList.action";
import Loader from "../Common/Loader/Loader";

class CollateralHistory extends Component {
  state = {
    LoadingIs: false,
    history: "",
    historyList : 1,
    dataIs : [],
    totalPagesIs : ''
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleChangeHistory = () =>{
    // const{ historyList } = this.state;
    this.setState({
      LoadingIs: false
    })
    // this.props.$collateralChangeHistory(historyList)
  }

  componentDidMount(){
    this.handleChangeHistory()
  }

  // componentWillReceiveProps(nextProps){
  //   const historyData = nextProps._collateralChangeHistory.collChangeHistory;
  //   if(historyData){
  //     this.setState({
  //       dataIs : historyData.users,
  //       totalPagesIs : historyData.totalPages,
  //       LoadingIs : false
  //     })
  //   }
  // }

  render() {
    const { LoadingIs, history } = this.state;
    return (
      <React.Fragment>
        <section className="client">
          <div className="container-fluid mx-auto">
            {/*------------------Search ---------------------*/}
            <div className="row col-md-12">
              <div className="col">
                <form className="row align-items-center">
                  <div className="col-auto pr-0">
                    <span className="fe fe-search text-muted"></span>
                  </div>
                  <div className="col">
                    <input
                      type="search"
                      className="form-control form-control-flush search"
                      autoComplete="off"
                      placeholder="Search"
                      name="history"
                      value={history}
                      onChange={this.handleOnChange}
                    />
                  </div>
                </form>
              </div>
            </div>
            {/*------------------Search ---------------------*/}

            {LoadingIs ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <table className="table table-sm table-nowrap card-table">
                  <thead>
                    <tr>
                      <th>
                        <a href="!#" className="text-muted sort">
                          Edit by
                        </a>
                      </th>
                      <th>
                        <a href="!#" className="text-muted sort">
                          Edit Date
                        </a>
                      </th>
                      <th>
                        <a href="!#" className="text-muted sort">
                          Status
                        </a>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    <tr>
                      <td className="orders-order">#6520</td>
                      <td className="orders-product">5' x 3' Wall Poster</td>
                      <td className="orders-status">
                        <div className="badge badge-soft-success">Shipped</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {/**** Pagination****** */}
            {/* <Pagination {...this.state} {...this.props}/> */}
          </div>
        </section>
      </React.Fragment>
    );
  }
}
const mapState = ({ collateralChangeHistory }) => {
  return {
    _collateralChangeHistory: collateralChangeHistory,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $collateralChangeHistory: (values) =>
    dispatchEvent(collateralChangeHistory(values)),
});
export default connect(
  mapState,
  mapDispatch
)(CollateralHistory);
