import React, { Component } from "react";
import { connect } from "react-redux";
import {
  activeTransactions,
  closeProject,
  rejectedBriefList,
} from "../../../store/action/transaction.action";
// import Table from "./Table";
import ReactPaginate from "react-paginate";
import ErrorApi from "../Common/Error/ErrorApi";
import TableArtistTrans from "./TableArtistTrans";

class RejectedTransactionList extends Component {

  state = {
    transactionsList: 1,
    transactionActive: false,
    LoadingIs: false,
    transactionData: [],
    transactionIs: "",
    minBudget: "",
    maxBudget: "",
    deadline: "",
    totalPages: 0,
    closeProjectActive: false,
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    const artistId=this.props.location.pathname.split(':');

    this.setState(
      {
        [name]: value,
      },
      () => {
        const {
          transactionsList,
          transactionIs,
          minBudget,
          maxBudget,
          deadline,
        } = this.state;

        const transactionData = {
          List: transactionsList,
          project: transactionIs,
          min_budget: minBudget,
          max_budget: maxBudget,
          deadline: deadline,
          id:artistId[1],
        };
        this.props.$rejectedBriefList(transactionData);
      }
    );
  };

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    const { transactionIs, minBudget, maxBudget, deadline } = this.state;
    const artistId=this.props.location.pathname.split(':');

    const transactionData = {
      List: selectedData,
      project: transactionIs,
      min_budget: minBudget,
      max_budget: maxBudget,
      deadline: deadline,
      id:artistId[1]
    };
    this.props.$rejectedBriefList(transactionData);
  };

  handleCloseProject = (id) => {
    this.setState({
      closeProjectActive: true,
    });
    const closeProjectData = { _id: id };
    this.props.$closeProject(closeProjectData);
  };

  handleActiveTransaction = () => {
    const { transactionsList, transactionIs } = this.state;
    const artistId=this.props.location.pathname.split(':');

    this.setState({
      LoadingIs: true,
    });

    const transactionData = {
      List: transactionsList,
      project: transactionIs,
      id:artistId[1]
    };
    this.props.$rejectedBriefList(transactionData);
  };

  componentDidMount() {
    this.handleActiveTransaction();
  }

  handleApiCall = () => {
   this.handleActiveTransaction();
  }

  componentWillReceiveProps(nextProps) {
    const activetransaction = nextProps._rejectedBriefList.rejectedBriefListIs;
    const closedProject = nextProps._closeProject.closeProjectIs;
    const { closeProjectActive } = this.state;
    if (activetransaction) {
      this.setState({
        transactionData: activetransaction.PostProjects,
        LoadingIs: false,
        totalPages: activetransaction.totalPages,
      });
    }
    if (closedProject && closeProjectActive) {
      alert(closedProject.message);
      this.setState({
        closeProjectActive: false,
      });
      this.handleActiveTransaction();
    }
  }

  render() {
    const { totalPages } = this.state;
    const transactionFunc = {
      handleOnChange: this.handleOnChange,
      handleCloseProject: this.handleCloseProject,
      handleApiCall: this.handleApiCall
    };
    return (
      <React.Fragment>
        {this.props._rejectedBriefList.rejectedBriefListErrIs ? (
          <ErrorApi {...this.props} />
        ) : (
          <section className="client">
            <div className="container-fluid mx-auto">
              <TableArtistTrans
                {...this.state}
                {...this.props}
                handleTransaction={transactionFunc}
                artistId = {"/transactions/RejectedTransactionList"}
              />

              {/******** pagination ************* */}

              {totalPages > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </div>
          </section>
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({ activeTransactions, closeProject, rejectedBriefList }) => {
  return {
    _activeTransactions: activeTransactions,
    _closeProject: closeProject,
    _rejectedBriefList: rejectedBriefList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $activeTransactions: (values) => dispatchEvent(activeTransactions(values)),
  $closeProject: (values) => dispatchEvent(closeProject(values)),
  $rejectedBriefList: (values) => dispatchEvent(rejectedBriefList(values)),
});
export default connect(mapState, mapDispatch)(RejectedTransactionList);
