import { put, call } from "redux-saga/effects";
import { getPublicAPI, postAPI, deleteAPI } from "../api-interface";

import {
  backendListSucces,
  getBackendUserSuccess,
  getTAbListSuccess,
  addTabListSuccess,
  addBackendUserSuccess,
  addBackendUserError,
  deleteBackendUserSuccess,
  backendDeleteListSucces
} from "../action/BackendUser.action";

// backend user list *****************

export function* onBackendUser(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/admin-user-list?page=${values.page}&limit=10&sort=${values.sortIs}&email=${values.email}&username=${values.user}`,
        values
      );
      if (data.status === 200) {
        yield put(backendListSucces(data.data));
      }
    }
  } catch (error) {
    //alert("Error: ", error.response.status);
  }
}

// backend delete list *****************

export function* onBackendDeleteList(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/admin-delete-user-list?page=${values.page}&limit=10&sort=${values.sortIs}&email=${values.email}&username=${values.user}`,
        values
      );
      if (data.status === 200) {
        yield put(backendDeleteListSucces(data.data));
      }
    }
  } catch (error) {
    //alert("Error: ", error.response.status);
  }
}

// backend User based on id *******************

export function* onGetBackendUser(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/get-admin-user?_id=${values}`,
        values
      );
      if (data.status === 200) {
        yield put(getBackendUserSuccess(data.data));
      }
    }
  } catch (error) {
    // alert("Error: ", error.response.status);
  }
}

// get tab list ******************************

export function* onGetTabList(data) {
  let values = data.values;
  try {
    const data = yield call(
      getPublicAPI,
      `/backendUser/admin-tab-list`,
      values
    );
    if (data.status === 200) {
      yield put(getTAbListSuccess(data.data));
    }
  } catch (error) {
    // alert("Error: ", error.response.status);
  }
}

// add Tab list ********************

export function* onAddTabList(data) {
  let values = data.values;
  try {
    if (values) {
    const data = yield call(
      postAPI,
      `/backendUser/add-tab-admin-user`,
      values
    );
    if (data.status === 200) {
      yield put(addTabListSuccess(data.data));
    }
   }
  } catch (error) {
    // alert("Error: ", error.response.status);
  }
}

// add backend User (backend User) *************************

export function* onAddBackendUser(data) {
  let values = data.values;
  try {
    if (values) {
    const data = yield call(
      postAPI,
      `/backendUser/add-admin-user`,
      values
    );
    if (data.status === 200) {
      yield put(addBackendUserSuccess(data.data));
    }
   }
  } catch (error) {
    yield put(addBackendUserError(error.response.status))
  }
}

// delete backend user
export function* ondeleteBackendUser(data) {
  let values = data.values;
  try {
    if (values) {
    const data = yield call(
      deleteAPI,
      `/backendUser/delete-backend-user`,
      values
    );
    if (data.status === 200) {
      yield put(deleteBackendUserSuccess(data.data));
    }
   }
  } catch (error) {
    // yield put(addBackendUserError(error.response.status))
  }
}