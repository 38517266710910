import React, { Component } from "react";
import { connect } from "react-redux";
import SubmitMilestone from "../Common/ModalCommon/SubmitMilestone";
import {
  completedMiles,
  requestChangeProposal,
  transactionGetData,
} from "../../../store/action/transaction.action";
import ReqChangeProposal from "./ReqChangeProposal";
import Modal from "react-modal";
import { uploadFileUrl } from "../../../Utils/BaseUrl";
import LinkImage from "../../../Assets/img/link-file.jpg";
import PdfImage from "../../../Assets/img/big-pdf.jpg";
import DocImage from "../../../Assets/img/big-doc.jpg";
import ZipImage from "../../../Assets/img/big-zip.jpg";
import PPtImage from "../../../Assets/img/big-ppt.jpg";
import moment from "moment";
import NotesModal from "../Common/ModalCommon/NotesModal";
import ArtistProjectMediaModal from "../Artist/ArtistProjectMediaModal";
import { separateNumber,sanitiseSummernoteText } from '../../../Utils/HelperFunction';
import { putAPI } from "../../../store/api-interface";
import { activeTransactions } from "../../../store/action/transaction.action";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    borderRadius: "8px",
    maxWidth: "1200px",
    maxheight: "800px",
    border: "none",
    minWidth: "35vw",
  },
};

class ProposalModal extends Component {
  state = {
    showVersionList: false,
    CreatorData: "",
    proposalIs: "",
    DeliverdDate: "",
    mileStineList: [],

    preProposalIs: "",
    PreDeliverdDate: "",
    preMileStineList: [],
    paymentTerms: [],

    submitMilesOpen: false,
    submitMilesData: "",
    deliverableMiles: "",
    modalIsOpen: false,
    ReqChangeActive: false,

    proposalType: "",
    ActualCycleArr: [],
    actulalMilestoneList: [],
    loc: "",
    milestoneRetainerAdvance: [],
    milestoneRetainer: [],
    compMiles: [],
    compMilesList: [],
    submitMilesName: "",

    notesModalOpen: false,
    notesModalContent: "",
    milestoneIdx: "",
    fixedPaymentTerms: [],

    // ------------------------------------  artist - project  ------------------------------------
    artistProject: [],
    artistIndex: "",
    aboutProject: "",
    projectTitle: "",
    projectEndMonth: "",
    projectEndYear: "",
    artistRole: "",
    projectKeywords: [],
    projectTools: [],
    projectImageLinks: [],
    ProjectMediaIs: false,
    LinkedCollateral: [],
    MediaData: [],
    artistProfile: '',
    projectId: '',
    FinalDelvierable: {},

    paymentStatusChecked: false,
    // statusCheckedStatus:[],
    allPaymentTerms: [],

    projectStatus: '',
  };

  handleState = (stateObj) => {
    this.setState(stateObj);
  };

  handleReqChange = () => {
    this.setState({ modalIsOpen: true });
  };

  handleCloseReqModal = () => {
    this.setState({ modalIsOpen: false });
  };

  handleShowVersions = () => {
    this.setState({
      showVersionList: !this.state.showVersionList,
    });
  };

  closeNotesModal = () => {
    this.setState({
      notesModalOpen: false,
    });
  };

  percentofTotalPayment = (price, totalPayment) => {
    let percent = (price * 100) / totalPayment;
    if (percent !== parseInt(percent, 10)) return percent.toFixed(2);
    else return percent;
  };

  handleViewMiles = (obj, index) => {
    this.setState({
      submitMilesOpen: true,
      submitMilesData: obj,
      deliverableMiles: String.fromCharCode(index + this.state.milestoneIdx),
      compMiles: obj?.milestonecomplete,
      submitMilesName: obj?.name,
    });
    // this.props.$completedMiles(obj._id);
  };

  handleCloseMilesModal = () => {
    this.setState({ submitMilesOpen: false });
  };

  handlePreviousData = () => {
    const {
      preProposalIs,
      PreDeliverdDate,
      preMileStineList,
      prefixedPaymentTerms,
    } = this.state;

    const MilesProposal = this.props?.MilesData?.newProposalList;

    let tempStatusArr = []
    if (preMileStineList?.length > 0)
      for (let x of prefixedPaymentTerms) {
        tempStatusArr.push(true)
      }
    else if (MilesProposal?.[0]?.milestoneActual?.length > 0)
      for (let x of MilesProposal?.[0]?.milestoneActual) {
        tempStatusArr.push(true)
      }
    else if (MilesProposal?.[0]?.milestoneRetainer?.filter((el) => el?.type !== "token")?.length > 0)
      for (let x of MilesProposal?.[0]?.milestoneRetainer?.filter((el) => el?.type !== "token")) {
        tempStatusArr.push(true)
      }


    this.setState({
      proposalIs: preProposalIs,
      mileStineList: preMileStineList,
      DeliverdDate: PreDeliverdDate,

      proposalType: MilesProposal?.[0]?.type,
      ActualCycleArr: MilesProposal?.[0]?.milestoneActual,
      actulalMilestoneList: MilesProposal?.[0]?.deliverables?.filter(
        (el) => el.type !== "token"
      ),
      milestoneRetainer: MilesProposal?.[0]?.milestoneRetainer?.filter(
        (el) => el?.type !== "token"
      ),
      milestoneRetainerAdvance: MilesProposal?.[0]?.milestoneRetainer?.filter(
        (el) => el?.type === "token"
      ),
      milestoneIdx: preProposalIs?.[0]?.type === "token" ? 64 : 65,

      fixedPaymentTerms: prefixedPaymentTerms,

      artistProject: MilesProposal?.[0]?.allProjectIdsWithBrief,

      // statusCheckedStatus:tempStatusArr
    });
  };


  handleVersionData = (obj) => {
    let milestoneList = [];

    const tempPaymetTerms = [];
    if (obj?.payment_terms) {
      for (let x of obj?.payment_terms) {
        if (
          x?.milestone_reference?.[0]?.label === "Advance Fees" &&
          x?.price_without_tax !== 0
        )
          tempPaymetTerms.push(x);
        else if (x?.milestone_referece?.[0]?.label !== "Advance Fees")
          tempPaymetTerms.push(x);
      }
    }

    if (obj?.deliverables) {
      // const advancePayment = obj?.deliverables.filter(
      //   (el) => el.type === "token" && el.price_without_tax !== 0
      // );
      const tempMilestonList = obj?.deliverables.filter(
        (el) => el.type !== "token"
      );
      // if (advancePayment.length > 0)
      //   tempMilestonList.unshift(advancePayment?.[0]);
      milestoneList = tempMilestonList;
    }



    let tempStatusArr = []
    if (milestoneList?.length > 0)
      for (let x of tempPaymetTerms) {
        tempStatusArr.push(true)
      }
    else if (obj?.milestoneActual?.length > 0)
      for (let x of obj?.milestoneActual) {
        tempStatusArr.push(true)
      }
    else if (obj?.milestoneRetainer?.filter(
      (el) => el?.type !== "token"
    )?.length > 0)
      for (let x of obj?.milestoneRetainer?.filter(
        (el) => el?.type !== "token"
      )) {
        tempStatusArr.push(true)
      }

    this.setState({
      proposalIs: obj,
      mileStineList: milestoneList,
      DeliverdDate:
        obj && obj?.deliverables && obj?.deliverables?.slice(-1).pop()?.due_date
          ? moment(obj?.deliverables?.slice(-1).pop()?.due_date).format(
            "DD/MM/YYYY"
          )
          : "",

      ActualCycleArr: obj?.milestoneActual,
      actulalMilestoneList: obj?.deliverables?.filter(
        (el) => el.type !== "token"
      ),
      milestoneRetainer: obj?.milestoneRetainer?.filter(
        (el) => el?.type !== "token"
      ),
      milestoneRetainerAdvance: obj?.milestoneRetainer?.filter(
        (el) => el?.type === "token"
      ),
      milestoneIdx: milestoneList?.[0]?.type === "token" ? 64 : 65,

      fixedPaymentTerms: tempPaymetTerms,

      artistProject: obj?.allProjectIdsWithBrief,

      // statusCheckedStatus:tempStatusArr
    });
  };

  handleShowArtistMedia = (obj, index) => {
    this.setState({
      artistIndex: index,
      reviewIndex: "",
      ProjectMediaIs: true,
      aboutProject: obj?.project.about,
      projectTitle: obj?.project.title,
      projectEndMonth: obj?.project.end_month,
      projectEndYear: obj?.project.end_year,
      projectKeywords: obj?.project.keywords,
      projectTools: obj?.project.tools,
      projectImageLinks: obj?.project.images_or_links,
      artistRole: obj?.project.role,
      LinkedCollateral: obj?.project_collateral,
      MediaData: obj,
      projectId: obj?.project._id
    });
  }

  handleCloseMediaModal = () => {
    this.setState({
      ProjectMediaIs: false
    })
  }

  updatePaymentStatus = async (id, type) => {
    // this.props.handleFunc.handleApiCall(id,type,this.state.proposalType,this.state.CreatorData._id)
    try {
      const res = await putAPI('/proposal/payment-status-update', { status: true, _id: id, type })
      if (res.status == 200) {
        if (this.props.location.pathname == '/transactions/ActiveTransactions' || this.props.location.pathname == '/transactions/InactiveProjects') {
          this.handleApiCall(id, type)
          // this.closeModal()
        }
        else {
          this.props.handleFunc.handleApiCall(id, type, this.state.proposalType, this.state.CreatorData._id)
        }
        alert('Marked Successfully')
      }
    } catch (err) {
      alert('Something Went Wrong')
    }
  }

  handleApiCall = (id, type) => {
    const transactionData = {
      List: 1,
      project: '',
      id: ''
    };
    // this.props.handleTransaction.handleApiCall(transactionData)
    this.props.handleTransaction.UpdateNewProposalList(id, type, this.state.proposalType, this?.props?.itemIndex)
  }

  closeModal = () => {
    this.props.handleFunc.handleCloseProposalModal()
  }

  handleUpdatePaymentStatus = (id, index, type) => {

    //  {console.log(this.props?.MilesData?.newProposalList.filter(el=>el.payment_terms==this.state.allPaymentTerms),'0000000000000')}

    // let paymentTemp = this.props?.MilesData?.newProposalList.filter(el=>el.payment_terms==this.state.allPaymentTerms)?.[0]
    // // paymentTempstatus=2
    // console.log(paymentTemp)
    let confirmIs = window.confirm(`Do you want to countinue ?`);
    // console.log(confirmIs,'-----------',id)
    if (confirmIs) {
      this.setState({ paymentStatusChecked: true })
      this.updatePaymentStatus(id, type)


      // let tempArr = [...this.state.statusCheckedStatus]
      // tempArr[index] = false
      // this.setState({statusCheckedStatus:tempArr})
    } else {
      this.setState({ paymentStatusChecked: false })
    }

  }

  handleActualProjectQuote(deliverables) {
    const { proposalIs } = this.state;
    if (proposalIs?.proposal_type !== 'fixed' && proposalIs?.proposal_type !== 'retainer') {

      // if(ActualCycleArr?.length>0){

      //   const latestDeliverable = ActualCycleArr[ActualCycleArr?.length-1]?.deliverable?.filter(el=>el.type!=='token'?el:false)
      //   if(latestDeliverable?.length>1){
      //     console.log(deliverables,'000000000000000000000  this is deliverable')
      //     return '₹ ' + separateNumber(this.state.proposalIs?.sub_total_cost?.toFixed(2)) + '/' + latestDeliverable?.[0]?.name +' + ' +Number((latestDeliverable?.length)-1) 
      //   }
      //   else{
      //     return '₹ ' + separateNumber(proposalIs?.sub_total_cost?.toFixed(2)) + '/' + latestDeliverable?.[0]?.name
      //   }

      // }else{ 
      const deliverableName = deliverables?.filter(el => el.type !== 'token' ? el : false)
      if (deliverableName.length > 1)
        return '₹ ' + separateNumber(deliverableName?.[0]?.price?.toFixed(2)) + '/' + deliverableName?.[0]?.name + ' + ' + Number((deliverableName?.length) - 1)
      else
        return '₹ ' + separateNumber(deliverableName?.[0]?.price?.toFixed(2)) + '/' + deliverableName?.[0]?.name
      // }

    }
    else if (proposalIs?.proposal_type == 'retainer') {
      return '₹ ' + separateNumber(proposalIs?.retainer_fees?.toFixed(2)) + '/' + proposalIs?.retainer_fees_type
    }
    else return '₹ ' + separateNumber(proposalIs?.sub_total_cost?.toFixed(2))
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      // console.log('------------------------------>nextProps proposalmodal', nextProps?.MilesData)
      const data = nextProps._requestChangeProposal.reqChangeIs;
      const MilesProposal = nextProps?.MilesData?.newProposalList;

      let milestoneList = [];
      let tempFinalMilestoneComplete = [];
      let tempFinalDeliverable = [];
      let FinalDelvierable = []
      if (MilesProposal?.[0]?.deliverables) {
        // const advancePayment = MilesProposal?.[0]?.deliverables.filter(
        //   (el) => el.type === "token" && el.price_without_tax !== 0
        // );
        const tempMilestonList = MilesProposal?.[0]?.deliverables.filter(
          (el) => el.type !== "token"
        );
        // if (advancePayment.length > 0)
        //   tempMilestonList.unshift(advancePayment?.[0]);
        milestoneList = tempMilestonList;
        tempFinalDeliverable = MilesProposal[0]?.RawFile
        if(tempFinalDeliverable.length){

          let desc=""

          tempFinalDeliverable.forEach(el => {
            if(el.type=='rowDescription'){
              desc = el.name
            }
          });
          
          let tempAllFiles = tempFinalDeliverable?.filter(el=>el.type!='rowDescription'?el:false)
          tempFinalMilestoneComplete = {
            allFiles:tempAllFiles,milestone_id:"642d34f0c408855c6287a8f9",proposal_id :MilesProposal[0]?.newProposalList?.[0]?.user_id,
            user_id:MilesProposal[0]?.newProposalList?.[0]?.user_id,description:desc
          }
          FinalDelvierable = {
            due_date: tempFinalDeliverable?.[0]?.createdAt, iteration: 0, iteration_charge: 0,
            milestonecomplete: [tempFinalMilestoneComplete], name: "Final Milestone", price: 0, price_creator: 0, price_new: 0,
          proposal_id: MilesProposal[0]?.newProposalList?.[0]?._id, restrict: true,
          submitted_date: "", submitted_iteration: 0, tax: 0, type: "milestone", user_id: MilesProposal[0]?.newProposalList?.[0]?.user_id
        }

        milestoneList = [...milestoneList,FinalDelvierable]
      }

      }

      const tempPaymetTerms = [];
      if (MilesProposal?.[0]?.payment_terms) {
        for (let x of MilesProposal?.[0]?.payment_terms) {
          if (x?.milestone_reference?.[0]?.label === "Advance Fees") {
            if (x.price_without_tax)
              tempPaymetTerms.push(x)
          }
          else if (x?.milestone_referece?.[0]?.label !== "Advance Fees")
            tempPaymetTerms.push(x);
        }
      }


      let allPaymentTerms = MilesProposal?.[0]?.payment_terms
      let tempStatusArr = []
      if (MilesProposal?.[0]?.proposal_type == 'fixed' && tempPaymetTerms?.length > 0)
        for (let x of tempPaymetTerms) {
          tempStatusArr.push(true)
        }
      else if (MilesProposal?.[0]?.milestoneActual?.length > 0)
        for (let x of MilesProposal?.[0]?.milestoneActual) {
          tempStatusArr.push(true)
        }
      else if (MilesProposal?.[0]?.milestoneRetainer?.filter((el) => el?.type !== "token")?.length > 0)
        for (let x of MilesProposal?.[0]?.milestoneRetainer) {
          tempStatusArr.push(true)
        }

      let artistData = []
      if (MilesProposal?.[0]?.allProjectIdsWithBrief) {
        artistData = MilesProposal?.[0]?.allProjectIdsWithBrief
      }

      const { ReqChangeActive } = this.state;

      this.setState({
        CreatorData: nextProps.MilesData.user_id2,
        proposalIs: MilesProposal?.[0],
        mileStineList:milestoneList,
        milestoneIdx: milestoneList?.[0]?.type === "token" ? 64 : 65,

        DeliverdDate:
          MilesProposal &&
            MilesProposal?.[0] &&
            MilesProposal?.[0]?.deliverables?.slice(-1).pop()?.due_date
            ? moment(
              MilesProposal?.[0]?.deliverables?.slice(-1).pop()?.due_date
            ).format("DD/MM/YYYY")
            : "",

        preProposalIs: MilesProposal && MilesProposal?.[0],
        preMileStineList: milestoneList,

        PreDeliverdDate:
          MilesProposal &&
            MilesProposal?.[0] &&
            MilesProposal?.[0]?.deliverables?.slice(-1).pop()?.due_date
            ? moment(
              MilesProposal?.[0]?.deliverables?.slice(-1).pop()?.due_date
            ).format("DD/MM/YYYY")
            : "",

        prefixedPaymentTerms: tempPaymetTerms,

        proposalType: MilesProposal?.[0]?.proposal_type,
        ActualCycleArr: MilesProposal?.[0]?.milestoneActual,
        loc: this.props.loc,
        actulalMilestoneList: MilesProposal?.[0]?.deliverables?.filter(
          (el) => el?.type !== "token"
        ),
        milestoneRetainer: MilesProposal?.[0]?.milestoneRetainer?.filter(
          (el) => el?.type !== "token"
        ),
        milestoneRetainerAdvance: MilesProposal?.[0]?.milestoneRetainer?.filter(
          (el) => el?.type === "token"
        ),
        fixedPaymentTerms: tempPaymetTerms,

        artistProject: artistData,

        artistProfile: MilesProposal?.[0]?.userData,

        FinalDelvierable: FinalDelvierable,
        // statusCheckedStatus:tempStatusArr,

        allPaymentTerms: allPaymentTerms,

        projectStatus: nextProps?.projectDataIs?.status

      });
      if (data?.status === "1" && ReqChangeActive) {
        this.setState({ ReqChangeActive: false });
        const transactionId = nextProps?.keyCheck
          ? nextProps.MilesData?._id
          : this.props.match.params.id.split(":").pop();
        this.props.$transactionGetData(transactionId);
      }
    }
  }

  render() {
    const { showProposalModal, MilesData, handleFunc, projectDataIs } =
      this.props;
    const {
      CreatorData,
      proposalIs,
      DeliverdDate,
      mileStineList,
      showVersionList,
      ActualCycleArr,
      loc,
      actulalMilestoneList,
      milestoneRetainer,
      milestoneRetainerAdvance,
      milestoneIdx,
      fixedPaymentTerms,

      artistProject,
      ProjectMediaIs,
      projectImageLinks,
      artistIndex,
      proposalType
    } = this.state;

    const notes = proposalIs?.note?.length ? sanitiseSummernoteText(proposalIs?.note.replace(/\n/g,'<br/>')) : "";
    const projectFunc = {
      // handleOnChange: this.handleOnChange,
      // handleEditProjectImageOrLinks: this.handleEditProjectImageOrLinks,
      // handleRating: this.handleRating,
      handleCloseMediaModal: this.handleCloseMediaModal
    };

    const versionData = MilesData?.newProposalList;

    const deliverableHeader = [
      { name: "#" },
      { name: "Deliverable" },
      { name: "Due Amount" },
      { name: "Due Date" },
      { name: "Iterations" },
      { name: "Status" },
      { name: "Submit Iteration" },
      { name: "View Milestone" },
    ]
    if (projectDataIs.flow_type !== 'old') {
      deliverableHeader.splice(2, 1)
    }

    const renderMilestoneTableHeader = (type) => {
      let tableHeader =
        type === "actual"
          ? [
            { name: "Name" },
            { name: "Price" },
            { name: "Pending" },
            { name: "Approved" },
            { name: "Rejected" },
            { name: "View Milestone" },
          ]
          : type === "fixed"
            ? deliverableHeader
            : type === "actual_brief"
              ? [
                { name: "#" },
                { name: "Deliverable" },
                { name: "Due Amount" },
                { name: "Iterations" },
                { name: "Status" },
                { name: "Submit Iteration" },
                { name: "View Milestone" },
              ]
              : type === "retainer"
                ? [
                  { name: "Duration" },
                  { name: "Hours" },
                  { name: "Task List" },
                  { name: " " },
                ]
                : "";

      return (
        <tr>
          {tableHeader.map((obj, index) => {
            return (
              <th key={index}>
                <a href="#!" className="text-muted sort">
                  {obj.name}
                </a>
              </th>
            );
          })}
        </tr>
      );
    };

    const renderDeliverables = (object, type) => {
      if (!object.length > 0)
        return (
          <tr>
            <td className="text-center">No Data Found</td>
          </tr>
        );
      return object.map((obj, index) => {
        if (obj?.type !== "token" && type === "actual") {
          return (
            <tr key={index}>
              <td className="orders-order">{obj?.name}</td>
              <td className="orders-order">{obj?.price ? '₹ ' + obj?.price : 'N/A'}</td>
              <td className="orders-order">{obj?.PendingCount > 0 ? obj?.PendingCount : '-'}</td>
              <td className="orders-order text-success">
                {obj?.ApprovedCount > 0 ? obj?.ApprovedCount : '-'}
              </td>
              <td className="orders-order text-danger">{obj?.RejectCount > 0 ? obj?.RejectCount : '-'}</td>
              <td className="orders-order">
                {obj?.milestonecomplete?.filter(el => el.allFiles.length > 0 ? el : false)?.length > 0 ? (
                  <button
                    className="btn btn-outline-success btn-sm"
                    // disabled
                    onClick={() => this.handleViewMiles(obj)}
                  >
                    <i className="fe fe-eye"></i>
                  </button>
                ) : '-'}
              </td>
            </tr>
          );
        } else if (type === "retainer" && obj?.type !== "token") {
          return (
            <tr key={index}>
              <td className="orders-order">
                {obj?.latestWorkLog?.start_duration &&
                  obj?.latestWorkLog?.end_duration &&
                  moment(obj?.latestWorkLog?.start_duration).format("DD/MM/YYYY") +
                  " - " +
                  moment(obj?.latestWorkLog?.end_duration).format("DD/MM/YYYY")}
              </td>
              <td className="orders-order">
                {obj?.latestWorkLog?.number_of_hour_worked_on &&
                  obj?.latestWorkLog?.number_of_hour_worked_on + " Hours"}
              </td>
              {/* <td className="orders-order" type="button" data-toggle="tooltip" data-placement="right" title={
           obj?.task_list?.map(item=>item.name)
          }>
            { obj?.task_list?.length>0 && obj?.task_list[0]?.name+`${obj?.task_list?.length>1?"...":""}`}
          
          </td> */}

              <td>
                {obj?.latestWorkLog?.task_list?.length > 0 && (
                  <span
                    style={
                      (obj?.latestWorkLog?.task_list?.length > 1 || obj?.latestWorkLog?.task_list?.[0]?.name?.length > 30)
                        ? { cursor: "pointer" }
                        : { cursor: "default" }
                    }
                    onClick={
                      (obj?.latestWorkLog?.task_list?.length > 1 || obj?.latestWorkLog?.task_list?.[0]?.name?.length > 30)
                        ? () =>
                          this.setState({
                            notesModalOpen: true,
                            notesModalContent: obj?.latestWorkLog?.task_list
                              ?.map((obj) => obj?.name)
                              .join("\n"),
                          })
                        : () => this.setState({ notesModalOpen: false })
                    }
                  >
                    {obj?.latestWorkLog?.task_list[0]?.name?.length > 30 ? obj?.latestWorkLog?.task_list[0]?.name?.slice(0, 20) + "..." : obj?.latestWorkLog?.task_list[0]?.name + " "}
                    {(obj?.latestWorkLog?.task_list?.length > 1 || obj?.latestWorkLog?.task_list?.[0]?.length > 30) && (
                      <span className="fe fe-alert-circle"></span>
                    )}
                  </span>
                )}
              </td>

              <td className="orders-order">
                {obj?.milestonecomplete?.filter(el => el.allFiles.length > 0 ? el : false)?.length > 0 && (
                  <button
                    className="btn btn-outline-success btn-sm"
                    // disabled
                    onClick={() => this.handleViewMiles(obj)}
                  >
                    <i className="fe fe-eye"></i>
                  </button>
                )}
              </td>
            </tr>
          );
        }
      });
    };

    // const tablePaymentTerms = proposalIs && proposalIs?.proposal_type==='fixed'?
    // [
    //   {name:'#'},
    //   {name:'Payment Milestones'},
    //   {name:'Breakup'}
    // ]
    // :[
    //   {name:'PREFERRED BILLING CYCLE'},
    //   {name:'RETAINER FEES'},
    //   {name:'PROJECT ADVANCE - FEES'}
    // ]

    const milesFunc = {
      handleCloseMilesModal: this.handleCloseMilesModal,
      handleCloseReqModal: this.handleCloseReqModal,
      handleState: this.handleState,
    };

    return (
      <React.Fragment>
        <div>
          <Modal
            isOpen={showProposalModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <button
              type="button"
              className="close media-upload-modal"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleFunc.handleCloseProposalModal}
            >
              <span
                aria-hidden="true"
                style={{ color: "black", fontSize: "32px" }}
              >
                &times;
              </span>
            </button>
            <div className="row">
              <div className="col">
                <div className="modal-head">
                  <p>{projectDataIs?.project_name}</p>
                  <div className="row">
                    <div className="col-6">
                      <h3>
                        Project Proposal (Version{" "}
                        {proposalIs && proposalIs?.number})
                      </h3>
                    </div>

                    <div className="col-6">
                      <div className="text-right">
                        {versionData && versionData.length > 1 && (
                          <div
                            className="badge badge-soft-secondary"
                            onClick={this.handleShowVersions}
                          >
                            <div style={{ cursor: "pointer" }}>
                              {showVersionList ? (
                                <i
                                  className="fe fe-arrow-left "
                                  onClick={this.handlePreviousData}
                                >
                                  Back
                                </i>
                              ) : (
                                "See Version history"
                              )}
                            </div>
                          </div>
                        )}
                        {/* <br /> */}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4">
                      <div className="text-left">
                        Proposal Type{" "}
                        <div className="badge badge-soft-secondary">
                          <div style={{ cursor: "pointer" }}>
                            {proposalIs && proposalIs?.proposal_type}{" "}{proposalIs && proposalIs?.proposal_type == 'retainer' && "(" + proposalIs?.retainer_fees_type + ")"}
                          </div>
                        </div>
                      </div>
                      <p className="mb-0">{`shared by ${CreatorData && CreatorData?.first_name
                        } ${CreatorData && CreatorData?.last_name}`}</p>
                    </div>
                    <div className="col-4"></div>
                    <div className="col-4 text-right">
                      {MilesData?.status !== 2 &&
                        MilesData.status !== 4 &&
                        proposalIs?.status === 4 &&
                        proposalIs?.admin_request_changes === 0 && (
                          <button
                            className="btn btn-primary"
                            onClick={this.handleReqChange}
                          >
                            Request Change
                          </button>
                        )}
                    </div>
                  </div>
                  {(proposalIs?.status === 0 ||
                    proposalIs?.status === 1 ||
                    proposalIs?.status === 2 ||
                    proposalIs?.status === 4) && (
                      <div className=" mt-3 text-left">
                        <div className="badge badge-soft-secondary">
                          <div style={{ cursor: "pointer" }}>
                            {proposalIs && proposalIs?.status === 0
                              ? "Pending"
                              : proposalIs && proposalIs?.status === 1
                                ? "Ongoing"
                                : proposalIs && proposalIs?.status === 2
                                  ? "Complete"
                                  : proposalIs && proposalIs?.status === 4
                                    ? "Waiting for Approval"
                                    : ""}
                          </div>
                        </div>
                      </div>
                    )}
                  <hr className="my-3" />
                  <div className="row">
                    <div className="col custom-modal">
                      <div className="row m-0">
                        <div className="col-12 col-sm-12 dflex">
                          <div className="w-50">
                            <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">
                              Project quote
                            </span>
                            <span className="font-20 mob-font-18 text-dark-black font-weight-600">
                              <span className="project-date-star position-relative">
                                {/* {proposalIs?.proposal_type !== "fixed" && proposalIs?.proposal_type !== "retainer"? 
                                this.handleActualProjectQuote(proposalIs)
                                :separateNumber(proposalIs?.sub_total_cost?.toFixed(2))} */}
                                {proposalIs && this.handleActualProjectQuote(proposalIs?.deliverables)}
                              </span>
                            </span>
                            <span className="d-block font-11 text-sub font-weight-500">
                              Excl. of GST
                            </span>
                          </div>
                          {proposalIs &&
                            proposalIs?.proposal_type !== "retainer" &&
                            DeliverdDate && (
                              <div className="w-50 project-delivery-date position-relative">
                                <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">
                                  Project delivery date
                                </span>
                                <span className="font-20 mob-font-18 text-dark-black font-weight-600 text-capitalize">
                                  {DeliverdDate}
                                </span>
                              </div>
                            )}
                          {projectDataIs &&
                            projectDataIs?.status === 7 &&
                            proposalIs &&
                            proposalIs?.status === 2 && (
                              <div className="w-50 project-delivery-date position-relative">
                                <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">
                                  Project Completed date
                                </span>
                                <span className="font-20 mob-font-18 text-dark-black font-weight-600 text-capitalize">
                                  {projectDataIs &&
                                    projectDataIs?.project_completed_date &&
                                    moment(
                                      projectDataIs?.project_completed_date
                                    ).format("DD/MM/YYYY")}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>
                      <hr className="my-3" />

                      {/* ***********************************     Actual     *********************************** */}

                      {proposalIs &&
                        proposalIs?.proposal_type !== "retainer" &&
                        proposalIs?.proposal_type !== "fixed" && (
                          <div>

                            <div className="col-12 col-sm-12 dflex mb-3">
                              <div className="w-50">
                                <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">
                                  Terms
                                </span>
                              </div>
                            </div>
                            <div className="card">
                              {/* <div
                                  className="card-header"
                                  id="headingOne"
                                >
                                            Payment Terms                                     
                                </div> */}
                              <div className='card-header'>
                                <table className="table table-sm table-nowrap card-table">
                                  <tbody className="list">
                                    <tr style={{ borderTop: "hidden" }}>
                                      <th className='btn btn-link sort '>
                                        Proposed Duration
                                      </th>
                                      <td className='text-right'>
                                        {proposalIs && proposalIs?.proposed_duration} {proposalIs?.proposed_duration > 1 ? ' Months' : ' Month'}
                                      </td>
                                    </tr>
                                    <tr style={{ borderTop: "hidden" }}>
                                      <th className='btn btn-link sort'>
                                        Preferred Billing Cycle
                                      </th>
                                      <td className='text-right'>
                                        {proposalIs && proposalIs?.preferred_billing_cycle}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <hr className="my-3" />

                            <div className="col-12 col-sm-12 dflex">
                              <div className="w-50">
                                <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">
                                  Milestones
                                </span>
                              </div>
                            </div>

                            {
                              // loc==='transaction'
                              ActualCycleArr?.length > 0 ? (
                                <div className="mb-2">
                                  <div
                                    className="accordion mt-3"
                                    id="accordionExample"
                                    style={{ minWidth: "50vw" }}
                                  >
                                    {ActualCycleArr && ActualCycleArr.length > 0
                                      ? ActualCycleArr?.map((obj, index) => {
                                        return (
                                          <div className="card" key={index}>
                                            <div
                                              className="card-header"
                                              id="headingOne"
                                            >
                                              <h2 className="mb-0">
                                                <button
                                                  className="btn btn-link btn-block text-left"
                                                  type="button"
                                                  data-toggle="collapse"
                                                  data-target={`#collapse${index}`}
                                                  aria-expanded="true"
                                                  aria-controls={`collapse${index}`}
                                                >
                                                  Cycle #{index + 1}
                                                  <span
                                                    className={`badge badge-soft-${obj?.status === 0
                                                        ? "secondary"
                                                        : obj?.status === 1
                                                          ? "warning"
                                                          : obj?.status === 2
                                                            ? "success"
                                                            : ""
                                                      } mx-2`}
                                                  >
                                                    {obj?.status === 0
                                                      ? "Not Started"
                                                      : obj?.status === 1
                                                        ? "In Progress"
                                                        : obj?.status === 2
                                                          ? "Paid"
                                                          : ""}
                                                  </span>
                                                  {
                                                    <div className="d-flex justify-content-between">
                                                      <small className="text-dark orders-order">
                                                        Client Pays :
                                                        {obj?.price_without_tax
                                                          ? " ₹ " + obj?.price_without_tax
                                                          : " N/A"}
                                                      </small>
                                                      <small className="text-dark orders-order">
                                                        Creator Earning :
                                                        {obj?.total_earning
                                                          ? " ₹ " +
                                                          obj?.total_earning
                                                          : " N/A"}
                                                      </small>
                                                      {(obj?.price_without_tax && this.state.projectStatus == 6) ? <div><input type="checkbox"
                                                        checked={obj?.admin_approved_client || obj?.type == 'token' || obj?.payment_type == 1}
                                                        disabled={obj?.admin_approved_client || obj?.type == 'token' || obj?.payment_type == 1}
                                                        onChange={() => this.handleUpdatePaymentStatus(obj?._id, index, 1)}
                                                      />&nbsp;<h5 className="fs-6 d-inline"> MARK AS PAID FROM CLIENT</h5>
                                                      </div> : ""}
                                                      {(obj?.price_without_tax && this.state.projectStatus == 6) ? <div><input type="checkbox"
                                                        checked={obj?.admin_approved || obj?.type == 'token'}
                                                        disabled={obj?.status == 0 || obj?.admin_approved || obj?.type == 'token'}
                                                        onChange={() => this.handleUpdatePaymentStatus(obj?._id, index, 2)}
                                                      />&nbsp;<h5 className="fs-6 d-inline"> MARK AS PAID TO CREATOR</h5>
                                                      </div> : ""}
                                                      {(obj?.price_without_tax && this.state.projectStatus == 6) && <small className="text-dark orders-order">
                                                        {`Paid On: ${obj?.payment_date ? moment(obj?.payment_date).format("DD/MM/YYYY") : "-"}`}
                                                      </small>}
                                                    </div>
                                                  }
                                                </button>
                                              </h2>
                                              {/* <button className="btn btn-sm btn-outline-success" onClick={()=>this.handleUpdatePaymentStatus(obj?._id)}>MARK AS PAID TO CREATOR</button> */}

                                            </div>
                                            <div
                                              id={`collapse${index}`}
                                              className={`collapse ${index === 0 && "show"
                                                }`}
                                              aria-labelledby="headingOne"
                                            // data-parent="#accordionExample"
                                            >
                                              <div className="card-body">
                                                <table className="table table-sm table-nowrap card-table">
                                                  <thead className="mb-2">
                                                    {renderMilestoneTableHeader(
                                                      "actual"
                                                    )}
                                                  </thead>
                                                  <tbody className="list">
                                                    {renderDeliverables(
                                                      obj?.deliverable,
                                                      "actual"
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })
                                      : "No Data Found"}
                                  </div>
                                </div>
                              ) : (
                                <div className="container-fluid mx-auto">
                                  <div className="table-responsive overflow-content user-work-content">
                                    <table className="table table-sm card-table">
                                      <thead>
                                        {renderMilestoneTableHeader(
                                          "actual_brief"
                                        )}
                                      </thead>
                                      <tbody className="list">
                                        {actulalMilestoneList &&
                                          actulalMilestoneList?.length > 0 ? (
                                          actulalMilestoneList?.map(
                                            (obj, index) => (
                                              <tr key={index}>
                                                <td className="orders-order">
                                                  {String.fromCharCode(
                                                    index + 65
                                                  )}
                                                </td>
                                                <td className="orders-order">
                                                  {obj?.type === "token"
                                                    ? "Project - Advance Fees"
                                                    : obj?.name}
                                                </td>
                                                <td className="orders-order">
                                                  {obj?.price ? '₹ ' + obj?.price : 'N/A'}

                                                </td>
                                                <td className="orders-order text-center">
                                                  {obj?.type === "token"
                                                    ? ""
                                                    : obj?.iteration ? obj?.iteration : ' - '}
                                                </td>
                                                <td className="orders-order">
                                                  {obj?.status === 0
                                                    ? "Pending"
                                                    : obj?.status === 1
                                                      ? "Active"
                                                      : obj?.status === 2
                                                        ? "Complete"
                                                        : ""}
                                                </td>
                                                <td className="orders-order text-center">
                                                  {obj?.submitted_iteration > 0 ? obj?.submitted_iteration : '-'}
                                                </td>
                                                <td className="orders-order text-center">
                                                  {obj?.type !== "token" &&
                                                    obj?.submitted_iteration >
                                                    0 && obj?.milestonecomplete
                                                      ?.length > 0 ? (
                                                    <button
                                                      className={`btn btn-outline-success btn-sm`}
                                                      // disabled
                                                      onClick={() =>
                                                        this.handleViewMiles(
                                                          obj,
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <i className="fe fe-eye"></i>
                                                    </button>
                                                  ) : '-'}
                                                </td>
                                              </tr>
                                            )
                                          )
                                        ) : (
                                          <tr>
                                            <td>No data Found..</td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                        )}

                      {/* ******************************************      Retainer      ****************************************** */}
                      {proposalIs && proposalIs?.proposal_type === "retainer" && (
                        <div>
                          {!milestoneRetainer?.length > 0 ? (
                            <div>
                              <div className="col-12 col-sm-12 dflex">
                                <div className="w-50">
                                  <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">
                                    Time Commitment
                                  </span>
                                </div>
                              </div>

                              <div className="container-fluid mx-auto">
                                <div className="table-responsive overflow-content user-work-content">
                                  <table className="table table-sm card-table">
                                    <thead>
                                      <tr>
                                        <th className="text-muted sort">
                                          Proposed Duration
                                        </th>
                                        <th className="text-muted sort">
                                          No. of Hours
                                        </th>
                                        <th className="text-muted sort">
                                          Working days
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="list">
                                      <tr>
                                        <td className="orders-order">
                                          {proposalIs &&
                                            proposalIs?.proposed_duration}{" "}
                                          {proposalIs?.proposed_duration > 1 ? 'Months' : "Month"}
                                        </td>
                                        <td className="orders-order">
                                          {proposalIs &&
                                            proposalIs?.number_of_hours} {proposalIs?.number_of_hours > 1 ? 'Hours' : 'Hour'}/{proposalIs?.number_of_hours_type}
                                        </td>
                                        <td className="orders-order">
                                          {proposalIs &&
                                            proposalIs?.working_days_in_week
                                              ?.map((item) => {
                                                if (item?.isActive)
                                                  return item?.label;
                                              })
                                              .join(" ")}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              <hr className="my-3" />

                              <div className="col-12 col-sm-12 dflex">
                                <div className="w-50">
                                  <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">
                                    Payment Terms
                                  </span>
                                </div>
                              </div>

                              <div className="container-fluid mx-auto">
                                <div className="table-responsive overflow-content user-work-content">
                                  <table className="table table-sm card-table">
                                    <thead>
                                      <tr>
                                        <th className="text-muted sort text-center">
                                          Retainer Fees
                                        </th>
                                        <th className="text-muted sort text-center">
                                          Project Advance - Fees
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody className="list">
                                      <tr>
                                        <td className="orders-order text-center">
                                          &#x20B9;{" "}
                                          {proposalIs &&
                                            proposalIs?.retainer_fees}
                                        </td>
                                        <td className="orders-order text-center">
                                          {proposalIs?.advance_amount ? <>&#x20B9;</> : ""}{" "}
                                          {proposalIs &&
                                            proposalIs?.advance_amount ? proposalIs?.advance_amount : '-'}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              {/* **************************    Payment Terms   ************************************* */}
                              <div className="col-12 col-sm-12 dflex mb-3">
                                <div className="w-50">
                                  <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">
                                    Payment Terms
                                  </span>
                                </div>
                              </div>
                              <div className="card">
                                {/* <div
                                  className="card-header"
                                  id="headingOne"
                                >
                                            Payment Terms                                     
                                </div> */}
                                <div className='card-header'>
                                  <table className="table table-sm table-nowrap card-table">
                                    <tbody className="list" >
                                      <tr>
                                        <th className='btn btn-link sort '>
                                          Retainer Fees
                                        </th>
                                        <td className='text-right'> &#x20B9;{" "}
                                          {proposalIs && proposalIs?.retainer_fees}
                                        </td>
                                      </tr>
                                      <tr>
                                        {proposalIs?.advance_amount ? <th className='btn btn-link sort'>
                                          Project Advance - Fees
                                        </th> : null}
                                        {proposalIs?.advance_amount ? <td className='text-right'> &#x20B9;{" "}
                                          {proposalIs?.advance_amount}
                                        </td> : null}
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              <hr className="my-3" />
                              <div className="col-12 col-sm-12 dflex">
                                <div className="w-50">
                                  <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">
                                    Work Log
                                  </span>
                                </div>
                              </div>

                              <div className="mb-2">
                                <div
                                  className="accordion mt-3"
                                  id="accordionExample"
                                  style={{ minWidth: "50vw" }}
                                >
                                  {milestoneRetainerAdvance &&
                                    milestoneRetainerAdvance.length > 0 &&
                                    milestoneRetainerAdvance?.map(
                                      (obj, index) => {
                                        return (
                                          <div className="card" key={index}>
                                            <div
                                              className="card-header"
                                              id="headingOne"
                                            >
                                              <h2 className="mb-0">
                                                <div
                                                  className="btn btn-link btn-block text-left"
                                                  style={{ cursor: "default" }}
                                                >
                                                  Advance Fee
                                                  <span
                                                    className={`badge badge-soft-${obj?.status === 0
                                                        ? "secondary"
                                                        : obj?.status === 1
                                                          ? "warning"
                                                          : obj?.status === 2
                                                            ? "success"
                                                            : ""
                                                      } mx-2`}
                                                  >
                                                    {obj?.status === 0
                                                      ? "Not Started"
                                                      : obj?.status === 1
                                                        ? "In Progress"
                                                        : obj?.status === 2
                                                          ? "Paid"
                                                          : ""}
                                                  </span>
                                                  {
                                                    <div className="d-flex justify-content-between">
                                                      <small className="text-dark orders-order">
                                                        Client Pays :
                                                        {obj?.price_without_tax
                                                          ? " ₹ " + obj?.price_without_tax
                                                          : " N/A"}
                                                      </small>
                                                      <small className="text-dark orders-order">
                                                        Creator Earning :
                                                        {obj?.total_earning
                                                          ? " ₹ " +
                                                          obj?.total_earning
                                                          : " N/A"}
                                                      </small>
                                                      <div><input type="checkbox"
                                                        checked={true}
                                                        disabled={true}
                                                      />&nbsp;<h5 className="fs-6 d-inline"> MARK AS PAID TO CREATOR</h5></div>
                                                      <div><input type="checkbox"
                                                        checked={true}
                                                        disabled={true}
                                                      />&nbsp;<h5 className="fs-6 d-inline"> MARK AS PAID FROM CLIENT</h5></div>
                                                      <small className="text-dark orders-order">
                                                        Paid On:
                                                        {obj?.payment_date ? moment(obj?.payment_date).format("DD/MM/YYYY") : "-"}
                                                      </small>
                                                    </div>
                                                  }
                                                </div>
                                              </h2>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>

                                <div
                                  className="accordion mt-3"
                                  id="accordionExample"
                                  style={{ minWidth: "50vw" }}
                                >
                                  {milestoneRetainer &&
                                    milestoneRetainer.length > 0
                                    ? milestoneRetainer?.map((obj, index) => {
                                      return (
                                        <div className="card" key={index}>
                                          <div
                                            className="card-header"
                                            id="headingOne"
                                          >
                                            <h2 className="mb-0">
                                              <button
                                                className="btn btn-link btn-block text-left"
                                                type="button"
                                                data-toggle="collapse"
                                                data-target={`#collapse${index}`}
                                                aria-expanded="true"
                                                aria-controls={`collapse${index}`}
                                              >
                                                Cycle #{index + 1}
                                                <span
                                                  className={`badge badge-soft-${obj?.status === 0
                                                      ? "secondary"
                                                      : obj?.status === 1
                                                        ? "warning"
                                                        : obj?.status === 2
                                                          ? "success"
                                                          : ""
                                                    } mx-2`}
                                                >
                                                  {obj?.status === 0
                                                    ? "Not Started"
                                                    : obj?.status === 1
                                                      ? "In Progress"
                                                      : obj?.status === 2
                                                        ? "Paid"
                                                        : ""}
                                                </span>
                                                {
                                                  <div className="d-flex justify-content-between">
                                                    <small className="text-dark orders-order">
                                                      Client Pays :
                                                      {obj?.price_without_tax
                                                        ? "₹ " + obj?.price_without_tax
                                                        : " N/A"}
                                                    </small>
                                                    <small className="text-dark orders-order">
                                                      Creator Earning :
                                                      {obj?.total_earning
                                                        ? "₹ " +
                                                        obj?.total_earning
                                                        : " N/A"}
                                                    </small>
                                                    {(obj?.price_without_tax && this.state.projectStatus == 6) ?
                                                      <div><input type="checkbox"
                                                        checked={obj?.payment_type == 1 || obj?.type == 'token' || obj?.admin_approved_client}
                                                        disabled={obj?.status == 0 || obj?.admin_approved_client || obj?.type == 'token' || obj?.payment_type == 1}
                                                        onChange={() => this.handleUpdatePaymentStatus(obj?._id, index, 1)}
                                                      />&nbsp;<h5 className="fs-6 d-inline"> MARK AS PAID FROM CLIENT</h5></div> : ""}
                                                    {(obj?.price_without_tax && this.state.projectStatus == 6) ?
                                                      <div><input type="checkbox"
                                                        checked={obj?.type == 'token' || obj?.admin_approved}
                                                        disabled={obj?.status == 0 || obj?.type == 'token' || obj?.admin_approved}
                                                        onChange={() => this.handleUpdatePaymentStatus(obj?._id, index, 2)}
                                                      />&nbsp;<h5 className="fs-6 d-inline"> MARK AS PAID TO CREATOR</h5></div> : ""}
                                                    {(obj?.price_without_tax && this.state.projectStatus == 6) && <small className="text-dark orders-order">
                                                      {`Paid On: ${obj?.payment_date ? moment(obj?.payment_date).format("DD/MM/YYYY") : "-"}`}
                                                    </small>}
                                                  </div>
                                                }
                                              </button>
                                              {/* <button className="btn btn-sm btn-outline-success" onClick={()=>this.handleUpdatePaymentStatus(obj?._id)}>MARK AS PAID TO CREATOR</button> */}
                                            </h2>
                                          </div>
                                          <div
                                            id={`collapse${index}`}
                                            className={`collapse ${index === 0 && "show"
                                              }`}
                                            aria-labelledby="headingOne"
                                          // data-parent="#accordionExample"
                                          >
                                            <div className="card-body">
                                              <table className="table table-sm table-nowrap card-table">
                                                <thead className="mb-2">
                                                  {renderMilestoneTableHeader(
                                                    "retainer"
                                                  )}
                                                </thead>
                                                <tbody className="list">
                                                  {renderDeliverables(
                                                    obj?.deliverable,
                                                    "retainer"
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                    : "No Data Found"}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {/* ************************************    Fixed   ******************************* */}

                      {proposalIs && proposalIs?.proposal_type === "fixed" && (
                        <div>
                          <div className="col-12 col-sm-12 dflex">
                            <div className="w-50">
                              <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">
                                Deliverables
                              </span>
                            </div>
                          </div>

                          <div className="container-fluid mx-auto">
                            <div className="table-responsive overflow-content user-work-content">
                              <table className="table table-sm card-table">
                                {/*----------------- table head --------------------*/}
                                <thead>
                                  {renderMilestoneTableHeader("fixed")}
                                </thead>
                                {/*----------------- table head --------------------*/}
                                <tbody className="list">
                                  {mileStineList && mileStineList.length > 0 ? (
                                    mileStineList.map((obj, index) => (
                                      <tr key={index}>
                                        <td className="orders-order">
                                          {obj?.type === "token" ? (
                                            <i
                                              className="fe fe-alert-circle"
                                              style={{ color: "#3454d1" }}
                                            ></i>
                                          ) : (
                                            String.fromCharCode(
                                              index + milestoneIdx
                                            )
                                          )}
                                        </td>
                                        <td className="orders-order">
                                          {obj?.type === "token"
                                            ? "Project - Advance Fees"
                                            : obj?.name}
                                        </td>
                                        {projectDataIs.flow_type === 'old' ?
                                          <td className="orders-order">
                                            &#x20B9; {obj?.price}
                                            {` (${this.percentofTotalPayment(
                                              obj?.price,
                                              proposalIs?.sub_total_cost
                                            )}% of total Payment)`}
                                          </td>
                                          : ""}
                                        <td className="orders-order">
                                          {obj?.due_date &&
                                            moment(obj?.due_date).format(
                                              "DD/MM/YYYY"
                                            )}
                                        </td>
                                        <td className="orders-order text-center">
                                          {obj?.type === "token"
                                            ? ""
                                            : obj?.iteration ? obj?.iteration : ' - '}
                                        </td>
                                        <td className="orders-order">
                                          {obj?.status === 0
                                            ? "Pending"
                                            : obj?.status === 1
                                              ? "Active"
                                              : obj?.status === 2
                                                ? "Complete"
                                                : ""}
                                        </td>
                                        <td className="orders-order text-center">
                                          {obj?.submitted_iteration > 0 ? obj?.submitted_iteration : '-'}
                                        </td>
                                        <td className="orders-order text-center">
                                          {(obj?.milestonecomplete?.[0]?.allFiles?.length > 0 &&
                                            obj?.type !== "token") ?
                                            (
                                              <button
                                                className={`btn btn-outline-success btn-sm`}
                                                // disabled
                                                onClick={() => {
                                                  this.handleViewMiles(
                                                    obj,
                                                    index
                                                  )
                                                }
                                                }
                                              >
                                                <i className="fe fe-eye"></i>
                                              </button>
                                            ) : '-'}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td>No data Found..</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <hr className="my-3" />
                          {projectDataIs.flow_type === 'new' ?
                            <>

                              <div className="col-12 col-sm-12 dflex">
                                <div className="w-50">
                                  <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">
                                    Payment Terms
                                  </span>
                                </div>
                              </div>

                              <div className="container-fluid mx-auto">
                                <div className="table-responsive overflow-content user-work-content">
                                  <table className="table table-sm card-table">
                                    <thead>
                                      <tr>
                                        <th className="text-muted sort text-center">
                                          #
                                        </th>
                                        <th className="text-muted sort text-center">
                                          Payment Milestones
                                        </th>
                                        <th className="text-muted sort text-center">
                                          Breakup
                                        </th>
                                        {this.state.projectStatus == 6 && <th className="text-muted sort text-center">
                                          MARK AS PAID FROM CLIENT
                                        </th>}
                                        {this.state.projectStatus == 6 && <th className="text-muted sort text-center">
                                          MARK AS PAID TO CREATOR
                                        </th>}
                                        {this.state.projectStatus == 6 && <th className="text-muted sort text-center">
                                          PAID ON
                                        </th>}
                                      </tr>
                                    </thead>

                                    <tbody className="list">

                                      {proposalIs &&
                                        fixedPaymentTerms &&
                                        fixedPaymentTerms?.length > 0 ? (
                                        fixedPaymentTerms?.map((obj, index) => (
                                          <tr key={index}>
                                            <td className="orders-order text-center">
                                              {index + 1}
                                            </td>
                                            <td className="orders-order text-center">
                                              {obj?.milestone_reference &&
                                                obj?.milestone_reference?.length > 0
                                                ? obj?.milestone_reference?.[0]
                                                  ?.label === "Advance Fees"
                                                  ? obj?.milestone_reference?.map(
                                                    (item) => {
                                                      return item?.label;
                                                    }
                                                  )
                                                  : "Completion of " +
                                                  obj?.milestone_reference
                                                    ?.map((item) => {
                                                      return item?.label ? item?.label : item?.value;
                                                    })
                                                    .join(",")
                                                : ""}
                                            </td>
                                            <td className="orders-order text-center">
                                              &#x20B9;{" "}
                                              {obj?.price_without_tax ? separateNumber(obj?.price_without_tax) : "0"}
                                            </td>
                                            {this.state.projectStatus == 6 && <td className='orders-order text-center'><input type="checkbox"
                                              checked={obj?.type == 'token' || obj?.admin_approved_client || obj?.payment_type == 1}
                                              disabled={obj?.payment_type == 1 || obj?.type == 'token' || obj?.admin_approved_client || obj?.status == 0}
                                              onChange={() => { this.handleUpdatePaymentStatus(obj?._id, index, 1) }} /></td>}
                                            {this.state.projectStatus == 6 && <td className='orders-order text-center'><input type="checkbox"
                                              checked={obj?.admin_approved || obj?.type == 'token'}
                                              disabled={obj?.status == 0 || obj?.admin_approved || obj?.type == 'token'}
                                              onChange={() => { this.handleUpdatePaymentStatus(obj?._id, index, 2) }} /></td>}

                                            {this.state.projectStatus == 6 && <td className='orders-order text-center'>{obj?.payment_date ? moment(obj?.payment_date).format("DD/MM/YYYY") : "-"}</td>}
                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td>No data Found..</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </>
                            : ''}
                        </div>
                      )}

                      <div className="row m-0">
                        {proposalIs && proposalIs?.proposal_type === 'fixed' && <div className="col-sm-12">
                          <div className="pt-3 pb-4 dlfex">
                            <div className="row-border row m-0 py-3">
                              <div className="col-sm-9 offset-sm-3">
                                <div className="card-wrapper mb-0">
                                  <div className="d-flex align-items-center">
                                    <div className="w-60 text-right">
                                      <h2 className="font-13 font-weight-500 text-third letter-spacing-015">
                                        Sub Total
                                      </h2>
                                    </div>
                                    <div className="w-40 pl-5">
                                      <h2 className="font-13 font-weight-500 text-third">
                                        &#x20B9;{" "}
                                        {proposalIs &&
                                          separateNumber(proposalIs?.sub_total_cost?.toFixed(2))}
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-wrapper mb-0">
                                  <div className="d-flex align-items-center">
                                    <div className="w-60 text-right">
                                      <h2 className="font-13 font-weight-500 text-third letter-spacing-015">
                                        {/* {proposalIs && proposalIs?.proposal_type==='retainer'?  "Total Earning":"G.S.T @ 18%"}  */}
                                        G.S.T @ 18%
                                      </h2>
                                    </div>
                                    <div className="w-40 pl-5">
                                      <h2 className="font-13 font-weight-500 text-third">
                                        &#x20B9; &nbsp;
                                        {/* {proposalIs && proposalIs?.proposal_type==='retainer'?Math.round((proposalIs?.total_earning + Number.EPSILON) * 100) / 100:proposalIs?.tax}  */}
                                        {proposalIs &&
                                          proposalIs?.tax !== 0 &&
                                          proposalIs?.tax
                                          ? separateNumber(proposalIs?.tax?.toFixed(2))
                                          : proposalIs?.tax === 0
                                            ? separateNumber((
                                              proposalIs?.total_cost -
                                              proposalIs?.sub_total_cost
                                            )?.toFixed(2))
                                            : "0"}
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-wrapper mb-0">
                                  <div className="d-flex align-items-center">
                                    <div className="w-60 text-right">
                                      <h2 className="font-16 font-weight-700 text-dark-black letter-spacing-023">
                                        Total Project Cost for Client
                                      </h2>
                                    </div>
                                    <div className="w-40 pl-5">
                                      <h2 className="font-16 font-weight-700 text-dark-black">
                                        &#x20B9; &nbsp;
                                        {proposalIs &&
                                          separateNumber(proposalIs?.total_cost?.toFixed(2))}
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>}

                        {(proposalIs?.link_image || proposalIs?.urls) &&
                          (proposalIs?.link_image?.length > 0 ||
                            proposalIs?.urls?.length > 0) && (

                            <div className="col-12 col-sm-12">
                              <div className="w-50 mb-2">
                                <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">
                                  Links, Images & Files
                                </span>
                              </div>

                              <div className="col-sm-12 uploading-image review_milestone_uploading">

                                {/* <div className="col-12 col-sm-12 dflex">
                            <div className="w-50 mb-2">
                              <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">
                                Files & Links
                              </span>
                            </div>
                          </div> */}

                                <div className="upload-thumbnail mb-2">
                                  {proposalIs?.link_image?.length > 0 &&
                                    proposalIs?.link_image?.map((obj, index) => {
                                      const data = [
                                        "gif",
                                        "jpg",
                                        "jpeg",
                                        "png",
                                        "pdf",
                                        "zip",
                                        "ppt",
                                        "doc",
                                        "docx",
                                      ];
                                      if (
                                        obj?.type === "files" ||
                                        data?.includes(
                                          obj?.name?.split(".").pop()
                                        )
                                      ) {
                                        return (
                                          <span
                                            className="upload-thumb"
                                            draggable="true"
                                            style={{ opacity: 1 }}
                                            key={index}
                                          >
                                            <a
                                              href={uploadFileUrl + obj.name}
                                              target="_blank"
                                              style={{ display: "contents" }}
                                            >
                                              <span
                                                className="upload_image_thumbnail"
                                                style={{
                                                  backgroundImage: `url("${obj?.name?.includes(".gif") ||
                                                      obj?.name?.includes(".png") ||
                                                      obj?.name?.includes(".jpg") ||
                                                      obj?.name?.includes(".jpeg")
                                                      ? uploadFileUrl + obj?.name
                                                      : obj?.name?.includes(
                                                        ".pdf"
                                                      )
                                                        ? PdfImage
                                                        : obj?.name?.includes(
                                                          ".zip"
                                                        )
                                                          ? ZipImage
                                                          : obj?.name?.includes(
                                                            ".ppt"
                                                          ) ||
                                                            obj?.nane?.includes(
                                                              ".pptx"
                                                            )
                                                            ? PPtImage
                                                            : obj?.name?.includes(
                                                              ".doc"
                                                            ) ||
                                                              obj?.name?.includes(
                                                                ".docx"
                                                              )
                                                              ? DocImage
                                                              : LinkImage
                                                    }")`,
                                                }}
                                              />
                                            </a>
                                          </span>
                                        );
                                      }
                                    })}

                                  {proposalIs?.urls?.length > 0 &&
                                    proposalIs?.urls?.map((obj, index) => {
                                      return (
                                        <span
                                          className="upload-thumb"
                                          draggable="true"
                                          style={{ opacity: 1 }}
                                          key={index}
                                        >
                                          <a
                                            href={obj.name?.includes('http') ? obj.name : 'http://' + obj.name}
                                            target="_blank"
                                            style={{ display: "contents" }}
                                          >
                                            {/* {console.log(obj.name)} */}
                                            <span
                                              className="upload_image_thumbnail"
                                              style={{
                                                backgroundImage: `url("${LinkImage}")`,
                                              }}
                                            />
                                          </a>
                                        </span>
                                      );
                                    })}

                                  {proposalIs?.files?.length > 0 && (
                                    <React.Fragment>
                                      <hr className="my-3" />
                                      <div className="col">
                                        <div className="row">
                                          {proposalIs.files.map((p, i) => {
                                            return (
                                              <div className="d-flex ml-3" key={i}>
                                                <a
                                                  className="avatar avatar-lg avatar-4by3"
                                                  href={uploadFileUrl + p.name}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  {/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(
                                                    p.name
                                                  ) ? (
                                                    <img
                                                      src={uploadFileUrl + p.name}
                                                      alt="..."
                                                      className="avatar-img rounded"
                                                      style={{
                                                        border: "1px solid #e2e6ed",
                                                      }}
                                                    />
                                                  ) : (
                                                    <div
                                                      style={{
                                                        backgroundColor: "#e2e6ed",
                                                        height: "inherit",
                                                      }}
                                                      className="text-center rounded"
                                                    >
                                                      <h2
                                                        style={{ paddingTop: 20 }}
                                                        className="text-uppercase"
                                                      >
                                                        {p.name.split(".").pop()}
                                                      </h2>
                                                    </div>
                                                  )}
                                                </a>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  )}

                                </div>
                              </div>
                            </div>
                          )}



                        {/* ------------------------------    Link urls   -------------------------------- */}
                        {/* {proposalIs?.urls && proposalIs?.urls?.length > 0 && (
                          <div className="col-sm-12 uploading-image review_milestone_uploading">
                            <div className="upload-thumbnail mb-2">
                                {proposalIs?.urls?.length > 0 &&
                                  proposalIs?.urls?.map((obj, index) => {
                                     
                                      return (
                                        <span
                                          className="upload-thumb"
                                          draggable="true"
                                          style={{ opacity: 1 }}
                                          key={index}
                                        >
                                          <a
                                            href={obj.name}
                                            target="_blank"
                                            style={{ display: "contents" }}
                                          >
                                            <span
                                              className="upload_image_thumbnail"
                                              style={{
                                                backgroundImage: `url("${LinkImage}")`,
                                              }}
                                            />
                                          </a>
                                        </span>
                                      );
                                  })}
                              </div>
                          </div>
                        )} */}

                        {/* ----------------------------------    Artist Project    --------------------------------- */}

                        {artistProject &&
                          (artistProject?.length > 0 && artistProject?.[0]?.project?.images_or_links) && (

                            <div className="col-12 col-sm-12">
                              <div className="w-50 mb-2">
                                <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">
                                  Relevant Projects
                                </span>
                              </div>

                              <div className="col-sm-12 uploading-image review_milestone_uploading">
                                <div className="upload-thumbnail mb-2">
                                  {artistProject?.map((obj, index) => {
                                    return (
                                      <span
                                        className="upload-thumb"
                                        draggable="true"
                                        style={{ opacity: 1 }}
                                        key={index}
                                        onClick={() => this.handleShowArtistMedia(obj, index)}
                                      >
                                        <a
                                          // href={obj.name}
                                          target="_blank"
                                          style={{ display: "contents" }}
                                        >
                                          <span
                                            className="upload_image_thumbnail"
                                            style={{
                                              backgroundImage: `url("${obj?.project?.images_or_links?.filter(el => el.isThumbnail !== 0)?.length > 0 ?
                                                  obj?.project?.images_or_links?.filter(el => el.isThumbnail !== 0)?.[0]?.type == 'url' ? LinkImage :
                                                    obj?.project?.images_or_links?.filter(el => el.isThumbnail !== 0)?.[0]?.value?.includes('pdf') ? PdfImage :
                                                      obj?.project?.images_or_links?.filter(el => el.isThumbnail !== 0)?.[0]?.value?.includes('zip') ? ZipImage :
                                                        obj?.project?.images_or_links?.filter(el => el.isThumbnail !== 0)?.[0]?.value?.includes('ppt') ? PPtImage :
                                                          obj?.project?.images_or_links?.filter(el => el.isThumbnail !== 0)?.[0]?.value?.includes('doc') ? DocImage :
                                                            obj?.project?.images_or_links?.filter(el => el.isThumbnail !== 0)?.[0]?.value
                                                  :
                                                  obj?.project?.images_or_links?.filter(el => el.isThumbnail == 0)?.length > 0 ?
                                                    obj?.project?.images_or_links?.filter(el => el.isThumbnail == 0)?.[0]?.type == 'url' ? LinkImage :
                                                      obj?.project?.images_or_links?.filter(el => el.isThumbnail == 0)?.[0]?.value?.includes('pdf') ? PdfImage :
                                                        obj?.project?.images_or_links?.filter(el => el.isThumbnail == 0)?.[0]?.value?.includes('zip') ? ZipImage :
                                                          obj?.project?.images_or_links?.filter(el => el.isThumbnail == 0)?.[0]?.value?.includes('ppt') ? PPtImage :
                                                            obj?.project?.images_or_links?.filter(el => el.isThumbnail == 0)?.[0]?.value?.includes('doc') ? DocImage :
                                                              obj?.project?.images_or_links?.filter(el => el.isThumbnail == 0)?.[0]?.value == '' ? obj?.project?.images_or_links?.filter(el => el.isThumbnail == 0)?.[1]?.value : obj?.project?.images_or_links?.filter(el => el.isThumbnail == 0)?.[0]?.value
                                                    : obj?.project?.images_or_links?.filter(el => el.isThumbnail == 0)?.[0]?.value
                                                }")`,
                                            }}
                                          />
                                        </a>
                                      </span>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          )}

                        {proposalIs?.note && (
                          <div className="col-sm-12 my-3">
                            <div className=" row">
                              <div className="col-2 font-weight-bold">
                                {" "}
                                Note :{" "}
                              </div>
                              <div
                                className="col-10"
                                style={{ wordWrap: "break-word" }}
                                dangerouslySetInnerHTML={{
                                  __html: notes,
                                }}
                              ></div>
                            </div>
                          </div>
                        )}
                      </div>
                      {proposalIs?.admin_request_note && (
                        <React.Fragment>
                          <hr className="my-3" />
                          <div className="col">
                            <h5>Requested Changes Note: </h5>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: proposalIs?.admin_request_note,
                              }}
                            ></span>
                            <br />
                            <p>
                              {proposalIs?.admin_email &&
                                `(By ${proposalIs?.admin_email})`}
                            </p>
                          </div>
                        </React.Fragment>
                      )}

                    </div>

                    {showVersionList && (
                      <div className="col-4">
                        <div className="card">
                          <div className="card-body">
                            {versionData.length > 0
                              ? versionData.map((obj, index) => (
                                <React.Fragment key={index}>
                                  <div
                                    className="row align-items-center"
                                    onClick={() =>
                                      this.handleVersionData(obj)
                                    }
                                    style={{ cursor: "pointer" }}
                                    key={index}
                                  >
                                    <div className="col">
                                      {/* <h5 className="mb-0">{`Version ${obj?.proposal?.number}`}</h5> */}
                                      <h5 className="mb-0">{`Version ${obj?.number}`}</h5>
                                      <p className="mb-0">
                                        {obj &&
                                          obj?.note &&
                                          obj?.note
                                            .replace(/<[^>]+>/g, "")
                                            .replace(/&nbsp;/g, "")
                                            .slice(0, 25)}
                                      </p>
                                    </div>
                                    <div className="col-auto">
                                      <time className="small text-muted">
                                        {obj?.createdAt &&
                                          moment(obj?.createdAt).format(
                                            "DD/MM/YYYY"
                                          )}
                                      </time>
                                    </div>
                                  </div>
                                  <hr />
                                </React.Fragment>
                              ))
                              : "No data found"}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        {/*------------------submit milestones Modal---------------------*/}
        {this.state.submitMilesOpen && this.state.compMiles && (
          <SubmitMilestone
            {...this.state}
            {...this.props}
            // submitMilesOpen={this.state.submitMilesOpen}
            handleMiles={milesFunc}
          />
        )}


        {/**-------------------Req Change Modal--------------------- */}

        {this.state.modalIsOpen && (
          <ReqChangeProposal
            {...this.state}
            {...this.props}
            handleMiles={milesFunc}
          />
        )}

        {/**-------------------Req Change Modal--------------------- */}

        {this.state.notesModalContent && (
          <NotesModal
            notesModalOpen={this.state.notesModalOpen}
            closeNotesModal={this.closeNotesModal}
            notesModalContent={this.state.notesModalContent}
          />
        )}

        {/* ---------------------------- artist project modal -------------------- */}
        {ProjectMediaIs && projectImageLinks && (
          <ArtistProjectMediaModal
            {...this.props}
            {...this.state}
            handleProject={projectFunc}
          />
        )}

      </React.Fragment>
    );
  }
}
const mapState = ({
  completedMiles,
  requestChangeProposal,
  transactionGetData,
}) => {
  return {
    _completedMiles: completedMiles,
    _requestChangeProposal: requestChangeProposal,
    _transactionGetData: transactionGetData,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $completedMiles: (values) => dispatchEvent(completedMiles(values)),
  $requestChangeProposal: (values) =>
    dispatchEvent(requestChangeProposal(values)),
  $transactionGetData: (values) => dispatchEvent(transactionGetData(values)),
  $activeTransactions: (values) => dispatchEvent(activeTransactions(values)),
});
export default connect(mapState, mapDispatch)(ProposalModal);

//  {data?.includes(obj?.name?.split('.')[1]) ?
//                                                 <a href={uploadFileUrl + obj.name} target="_blank" key={index} style={{ display: "contents" }}>
//                                                   <span
//                                                     className="upload_image_thumbnail"
//                                                     style={{
//                                                       backgroundImage: `url("${ uploadFileUrl + obj.name }")`
//                                                     }}
//                                                   />
//                                                 </a>
//                                                 :
//                                                 <span
//                                                   className="upload_image_thumbnail"
//                                                   style={{
//                                                     backgroundImage: `url("${
//                                                         obj?.name?.includes(".pdf") ?
//                                                           PdfImage :
//                                                           obj?.name?.includes(".zip") ?
//                                                             ZipImage :
//                                                             obj?.name?.includes(".ppt") ?
//                                                               PPtImage :
//                                                               DocImage
//                                                       }")`
//                                                   }}
//                                                 />
//                                               }
