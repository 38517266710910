import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  collateralList,
  statusCollateral,
  hideCollateral,
  featuredCollaterl,
  resetApprovedCreator
} from "../../../store/action/collateralList.action";
import { profileBaseUrl } from "../../../Utils/BaseUrl";
import ReactPaginate from "react-paginate";
import Loader from "../Common/Loader/Loader";
import ErrorApi from "../Common/Error/ErrorApi";

class Collaterals extends Component {
  state = {
    LoadingIs: false,
    collateralList: 1,
    collateralData: [],
    collateralActive: false,
    name: "",
    statusLoader: false,
    totalPages: 0,
    hideStatus: false,
    featuredActive : false
  };

  handleOnChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const { collateralList, name } = this.state;
        const listData = { page: collateralList, name: name };
        this.props.$collateralList(listData);
      }
    );
  };

  handleGetConfirmation(obj, id) {
    let confirmIs = window.confirm(
      `Do you want to ${obj === 1 ? `accept` : `reject`} this Collateral ?`
    );
    if (confirmIs === true) {
      const statusData = { _id: id, status: obj };
      this.props.$statusCollateral(statusData);
      return true;
    } else {
      return false;
    }
  }

  handleCollateralStatus = (status, id) => {
    this.setState({
      statusLoader: true,
    });
    let statusIs = 0;
    if (status === "Accept") {
      statusIs = 1;
    } else if (status === "Reject") {
      statusIs = 2;
    }
    this.handleGetConfirmation(statusIs, id);
  };

  handlePageClick = (data) => {
    const { name } = this.state;
    let selectedData = data.selected + 1;
    const listData = { page: selectedData, name: name };
    this.props.$collateralList(listData);
  };

  handleGetHideConfirmation = (currentStatus, id) => {
    let confirmIs = window.confirm(
      `Do you want to ${
        currentStatus === 1 ? "show" : "Hide"
      } this Collateral ?`
    );
    if (confirmIs === true) {
      this.setState({ hideStatus: true });
      const statusData = { status: currentStatus, _id: id };
      this.props.$hideCollateral(statusData);
      return true;
    } else {
      return false;
    }
  };

  handleHideCollateral = (status, id) => {
    var currentStatus = null;
    if (status === 1) {
      currentStatus = 0;
    } else {
      currentStatus = 1;
    }
    this.handleGetHideConfirmation(currentStatus, id);
  };

  getCollateralVisibility = (obj) => {
    return (
      <span>
        <div
          className="badge badge-soft-secondary mr-2"
          style={{ cursor: "pointer" }}
          onClick={() => this.handleHideCollateral(obj.visible_status, obj._id)}
        >
          {obj.visible_status === 0 ? "Show" : "Hide"}
        </div>
        <div className="badge badge-soft-primary mr-2 disable">
          {obj.visible_status === 1 ? "Show" : "Hide"}
        </div>
      </span>
    );
  };

  getConfirmation = (obj, status) => {
    let confirmIs = window.confirm(`Do you want to countinue ?`);
    if (confirmIs === true) {
      this.setState({featuredActive : true})
      const currentData = { _id: obj._id, status: status };
      this.props.$featuredCollaterl(currentData);
      return true;
    } else {
      return false;
    }
  };

  getFeaturedCollateral = (obj) => {
    return (
      <React.Fragment>
        <span>
          <div
            className="badge badge-soft-secondary mr-2"
            style={{ cursor: "pointer" }}
            onClick={() =>
              this.getConfirmation(obj, obj.features === 1 ? 0 : 1)
            }
          >
            {obj.features === 1 ? "Off" : "On"}
          </div>
          <div className="badge badge-soft-primary mr-2 disable">{`${
            obj.features === 1 ? `On` : `Off`
          }`}</div>
        </span>
      </React.Fragment>
    );
  };

  handleCollateralDetails = () => {
    const { collateralList, name } = this.state;
    this.setState({
      LoadingIs: true,
    });
    const listData = { page: collateralList, name: name };
    this.props.$collateralList(listData);
  };

  componentDidMount() {
    this.props.$resetApprovedCreator()
    this.handleCollateralDetails();
    this.setState({
      collateralActive: true,
    });
    this.props.handleClients.handlepathName("/collaterals");
    this.props.handleClients.mainIndexstate({
      currentCollateralActiveIs: true,
    });
  }

  getfeaturedCollateralRes = (featuredData , featuredActive) =>{
    if(featuredData && featuredActive){
      this.setState({featuredActive : false})
      this.handleCollateralDetails();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { statusLoader, hideStatus, featuredActive } = this.state;
    const collateralIs = nextProps._collateralList.collateralList;
    const statusData = nextProps._statusCollateral.collStatus;
    const hideStatusData = nextProps._hideCollateral.hideCollateralIs;
    const featuredData = nextProps._featuredCollaterl.featuredCollateralIs;
    if (statusData?.status === "1" && statusLoader) {
      this.handleCollateralDetails();
      this.setState({
        statusLoader: false,
      });
    }
    if (collateralIs) {
      this.setState({
        collateralData: collateralIs.CollateralS,
        LoadingIs: false,
        totalPages: collateralIs.totalPages,
      });
    }
    if (hideStatusData?.status === "1" && hideStatus) {
      this.handleCollateralDetails();
      this.setState({ hideStatus: false });
    }
    (featuredActive && featuredData) && this.getfeaturedCollateralRes(featuredData , featuredActive)
  }

  render() {

    const { collateralData, LoadingIs, name } = this.state;

    /*-----------------list Sort ------------------------*/

    function listCompare(a, b) {
      const name = a.name.toLowerCase();
      const nameIs = b.name.toLowerCase();
      if (name < nameIs) {
        return -1;
      }
      if (name > nameIs) {
        return 1;
      }
      return 0;
    }

    const ListIs = collateralData.sort(listCompare);

    /*-----------------list Sort ------------------------*/

    const tableContentArr = [
      { name: "Action" },
      { name: "Name" },
      { name: "Transactions" },
      { name: "Active Transactions" },
      { name: "Complete Transactions" },
      { name: "Inactive Transactions" },
      { name: "Closed Transactions" },
      { name: "Users" },
      { name: "Users (Approved)" },
      { name: "Project" },
      { name: "Project (Approved)" },
      { name: "Status" },
      { name: "Hide Collateral (Client)" },
      { name: "Admin user(Approved/Rejected)" },
      { name: "Featured Collateral" },      
    ];

    return (
      <React.Fragment>
        {this.props._collateralList.collateralListErrorIs && (
          <ErrorApi {...this.props} />
        )}
        <section className="client mx-auto">
          <div className="container-fluid mx-auto">
            {/*------------------Search ---------------------*/}

            <div className="row col-md-12">
              <div className="col">
                <form className="row align-items-center">
                  <div className="col-auto pr-0">
                    <span className="fe fe-search text-muted"></span>
                  </div>
                  <div className="col">
                    <input
                      type="search"
                      className="form-control form-control-flush search"
                      autoComplete="off"
                      placeholder="Search"
                      name="name"
                      value={name}
                      onChange={this.handleOnChange}
                    />
                  </div>
                </form>
              </div>
            </div>

            {/*------------------Search ---------------------*/}

            {LoadingIs ? (
              <Loader />
            ) : (
              <div className="table-responsive mb-5 mx-auto">
                <table className="table table-sm table-nowrap card-table">
                  {/*------------------table head ----------------------------*/}

                  <thead>
                    <tr>
                      {tableContentArr.map((obj, index) => (
                        <th key={index}>
                          <Link to="#!" className="text-muted sort">
                            {obj.name}
                          </Link>
                        </th>
                      ))}
                    </tr>
                  </thead>

                  {/*------------------table head ----------------------------*/}
                  <tbody className="list">
                    {ListIs.map((obj, index) => (
                      <tr key={index}>
                        
                          <td className="orders-method">
                          <Link to={`/collateralsQuestions/:view/:${obj._id}`}>
                            <button className="btn btn-outline-success btn-sm mr-2">
                              <i className="fe fe-eye"></i>
                            </button>
                          </Link>
                          <Link to={`/collateralsQuestions/:edit/:${obj._id}`}>
                            <button className="btn btn-outline-info btn-sm mr-2">
                              <i className="fe fe-edit"></i>
                            </button>
                          </Link>
                        </td>
                        <td className="orders-order">
                          <div className="avatar-group mr-3">
                            <a
                              href="profile-posts.html"
                              className="avatar avatar-xs"
                              data-toggle="tooltip"
                              title={obj.name}
                            >
                              <img
                                src={
                                  obj.image
                                    ? `${profileBaseUrl + obj.image}`
                                    : require("../../../Assets/img/avatars/profiles/images.jpeg")
                                }
                                className="avatar-img rounded-circle"
                                alt="..."
                              />
                            </a>
                          </div>
                          {obj.name}
                        </td>
                        <td className="orders-date">{obj.postProject}</td>
                        <td className="orders-date">
                          {obj.active_postProject}
                        </td>
                        <td className="orders-date">
                          {obj.completed_postProject}
                        </td>
                        <td className="orders-date">
                          {obj.inactive_postProject}
                        </td>
                        <td className="orders-date">
                          {obj.closed_postProject}
                        </td>
                        <td className="orders-date">{obj.userCollateral}</td>
                        <td className="text-center">
                          {obj.usercolletralApproved}
                        </td>
                        <td className="text-center">{obj.projectCollateral}</td>
                        <td className="text-center">
                          {obj.projectCollateralApproved}
                        </td>
                        {/*-----------------status update------------------------------*/}
                        <td className="orders-date">
                          {obj.status === 0 && (
                            <span>
                              <div
                                className="badge badge-soft-info mr-2"
                                onClick={() =>
                                  this.handleCollateralStatus("Accept", obj._id)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                Accept
                              </div>
                              <div
                                className="badge badge-soft-info"
                                onClick={() =>
                                  this.handleCollateralStatus("Reject", obj._id)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                Reject
                              </div>
                            </span>
                          )}
                          {obj.status === 1 && (
                            <div className="badge badge-soft-success mr-2">
                              Accepted
                            </div>
                          )}
                          {obj.status === 2 && (
                            <div className="badge badge-soft-danger mr-2">
                              Rejected
                            </div>
                          )}
                        </td>
                        {/*-----------------status update------------------------------*/}

                        {/*------------------------hide Collateral -------------------*/}

                        <td className="text-center">
                          {this.getCollateralVisibility(obj)}                          
                        </td>

                        {/*------------------------hide Collateral -------------------*/}

                       <td className="orders-total">{obj.admin_email}</td>
                        {/*------------------------Featured Collateral -------------------*/}

                        <td className="text-center">
                          {this.getFeaturedCollateral(obj)}
                        </td>
                        {/*------------------------Featured Collateral -------------------*/}                      
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {/*-------------- Pagination------------------- */}

            {this.state.totalPages > 0 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </section>
      </React.Fragment>
    );
  }
}
const mapState = ({
  collateralList,
  statusCollateral,
  hideCollateral,
  featuredCollaterl,
}) => {
  return {
    _collateralList: collateralList,
    _statusCollateral: statusCollateral,
    _hideCollateral: hideCollateral,
    _featuredCollaterl: featuredCollaterl,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $collateralList: (values) => dispatchEvent(collateralList(values)),
  $statusCollateral: (values) => dispatchEvent(statusCollateral(values)),
  $hideCollateral: (values) => dispatchEvent(hideCollateral(values)),
  $featuredCollaterl: (values) => dispatchEvent(featuredCollaterl(values)),
  $resetApprovedCreator : (values) => dispatchEvent(resetApprovedCreator(values))
});
export default connect(mapState, mapDispatch)(Collaterals);
