import React, { Component } from "react";
import CollateralTable from "./CollateralTable";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { stausArtistCollateral } from "../../../../store/action/clientList.action";
import { pendingCollateral } from "../../../../store/action/collateralList.action";
import ErrorApi from "../../Common/Error/ErrorApi";

class PendingCollateral extends Component {
  state = {
    currentPage: 1,
    listData: [],
    totalPage: "",
    showAction: false,
    showActionIndex: "",
    acceptRejectIs: false,
    LoadinListIs: false,
    sortIs: -1,
    sortFlagIs : false
  };

  setArtistState = (stateObj) => {
    this.setState(stateObj);
  };

  handleShowAction = (index) => {
    const { showAction } = this.state;
    this.setState({
      showAction: !showAction,
      showActionIndex: index,
    });
  };

  handleHideAction = () => {
    this.setState({
      showAction: true,
    });
  };

  handleSorting = () => {
    this.setState({ sortFlagIs : !this.state.sortFlagIs },()=>{
      const { currentPage } = this.state;
      let Data = null;
      if(this.state.sortFlagIs){
         Data = { page: currentPage, sortData: 1 };
      }else{
         Data = { page: currentPage, sortData: -1 };
      }
      this.setState({ sortIs : this.state.sortFlagIs ? 1 : -1 })
      this.props.$pendingCollateral(Data);
    })

  };

  handleGetConfirmation(obj, id) {
    let confirmIs = window.confirm(
      `Do you want to ${obj === 1 ? `accept` : `reject`} this Collateral ?`
    );
    if (confirmIs === true) {
      const statusData = { _id: id, status: obj };
      this.props.$stausArtistCollateral(statusData);
      this.setState({
        acceptRejectIs: true,
      });
      return true;
    } else {
      return false;
    }
  }

  handleCollateralStatus = (id, status) => {
    let currentStatusIs = 0;
    if (status === "accept") {
      currentStatusIs = 1;
    } else if (status === "reject") {
      currentStatusIs = 2;
    }
    this.handleGetConfirmation(currentStatusIs, id);
  };

  handlePageClick = (data) => {
    const { sortIs } = this.state;
    let selectedData = data.selected + 1;
    const Data = { page: selectedData, sortData: sortIs };
    this.props.$pendingCollateral(Data);
  };

  handlePendingCollateralList = () => {
    const { currentPage, sortIs } = this.state;
    this.setState({
      LoadinListIs: true,
    });
    const Data = { page: currentPage, sortData: sortIs };
    this.props.$pendingCollateral(Data);
  };

  componentDidMount() {
    this.handlePendingCollateralList();
  }

  componentWillReceiveProps(nextProps) {
    const pendingCollateral = nextProps._pendingCollateral.pendingCollateral;
    const collateralStatusDta =
      nextProps._stausArtistCollateral.artistColstatus;
    const { acceptRejectIs } = this.state;
    if (pendingCollateral && !acceptRejectIs) {
      this.setState({
        listData: pendingCollateral.userCollateral,
        totalPage: pendingCollateral.totalPages,
        LoadinListIs: false,
      });
    }
    if (acceptRejectIs && collateralStatusDta.status === "1") {
      this.handlePendingCollateralList();
      this.setState({
        acceptRejectIs: false,
      });
    }
  }

  render() {
    const collaterlFunc = {
      handleShowAction: this.handleShowAction,
      handleCollateralStatus: this.handleCollateralStatus,
      handleSorting: this.handleSorting,
    };

    return (
      <React.Fragment>
        <section className="creator">
            {this.props._pendingCollateral.pendingCollateralListErrIs ? (
              <ErrorApi {...this.props} />
            ) : (
              <section className="client mt-5">
                <div className="container-fluid mx-auto">
                  <CollateralTable
                    {...this.props}
                    {...this.state}
                    handleCollateral={collaterlFunc}
                  />

                  {/****************** Pagination ********************/}

                  {this.state.totalPage > 0 && (
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.totalPage}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  )}
                </div>
              </section>
            )}
        </section>
      </React.Fragment>
    );
  }
}
const mapState = ({ pendingCollateral, stausArtistCollateral }) => {
  return {
    _pendingCollateral: pendingCollateral,
    _stausArtistCollateral: stausArtistCollateral,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $pendingCollateral: (values) => dispatchEvent(pendingCollateral(values)),
  $stausArtistCollateral: (values) =>
    dispatchEvent(stausArtistCollateral(values)),
});
export default connect(mapState, mapDispatch)(PendingCollateral);
