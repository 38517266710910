import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getPublicAPI } from "../../../store/api-interface";
import LoaderSmall from '../Common/Loader/LoaderSmall';

export default class SidebarLink extends Component {
  state = {
    userTabs: [],
    PandingApprovalsIs: false,
    RoleIs: 1,
    LoaderIs : false
  };

  handleDynamicLink = () => {
    this.setState({LoaderIs : true})
    getPublicAPI(`/backendUser/get-admin-user-data`).then((res) => {
      if (res) {
        let tabIs = res.data.tabList;
        res.data.role && localStorage.setItem("adminRole", res.data.role);
        this.setState({ userTabs: tabIs, RoleIs : res.data.role, LoaderIs : false});
      }
    }).catch((err)=>{
      if(err?.response?.status === 401){
        const { handleClients } = this.props;
        handleClients.handleLogout();
      }
    });
  };

  componentDidMount() {
    this.handleDynamicLink();
  }

  render() {
    const { handleClients } = this.props;
    const { userTabs, PandingApprovalsIs, RoleIs, LoaderIs } = this.state;

    const contentArr = [
      {
        name: "Clients",
        currentPath: "/clients",
        icon: "fe-zap",
        totalUser: "",
      },
      {
        name: "Artist",
        currentPath: "/artist",
        icon: "fe-user",
        totalUser: "",
      },
      {
        name: "Collaterals",
        currentPath: "/collaterals",
        icon: "fe-list",
        totalUser: "",
      },
      // {
      //   name: "Packages",
      //   currentPath: "/packages",
      //   icon: "fe-package",
      //   totalUser: "",
      // },
      {
        name: "Projects",
        currentPath: "/projects",
        icon: "fe-layers",
        totalUser: "",
      },
      {
        name: "Transactions",
        currentPath: "/transactions",
        icon: "fe-activity",
        totalUser: "",
      },
      {
        name: "AM Transactions",
        currentPath: "/milestones",
        icon: "fe-sidebar",
        totalUser: "",
      },      
      {
        name: "Reports/alerts",
        currentPath: "/reports",
        icon: "fe-flag",
        totalUser: "",
      },
      {
        name: "Other DBs",
        currentPath: "/otherdbs",
        icon: "fe-database",
        totalUser: "",
      },
      {
        name: "Parent Creative Section",
        currentPath: "/parentcreative",
        icon: "fe-plus-circle",
        totalUser: "",
      },
      {
        name: "Approvals",
        currentPath: "/approvals",
        icon: "fe-chevrons-right",
        totalUser: PandingApprovalsIs,
      },
      {
        name: "Backend users",
        currentPath: "/backendusers",
        icon: "fe-users",
        totalUser: "",
      },
      {
        name: "Collateral Searches",
        currentPath: "/collateralSearches",
        icon: "fe-users",
        totalUser: "",
      },
      {
        name: "Messages",
        currentPath: "/messages",
        icon: "fe-message-square",
        totalUser: "",
      },
      {
        name: "Custom",
        currentPath: "/custom",
        icon: "fe-grid",
        totalUser: "",
      },
    ];

    return (
      <React.Fragment>
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link
              to="/"
              className="nav-link"
              onClick={() => handleClients.handlepathName("/")}
            >
              <i className={`fe fe-home`}></i> Dashboard
            </Link>
          </li>
          {!LoaderIs ? contentArr.map((obj, index) => (
            <React.Fragment key={index}>
              {RoleIs === 1 ? (
                <li className="nav-item">
                  <Link
                    to={obj.currentPath}
                    className="nav-link"
                    onClick={(e) =>
                      obj.currentPath !== "/milestones" ? handleClients.handlepathName(obj.currentPath) : e.preventDefault()
                    }
                    style={obj.currentPath === "/milestones"?{cursor:'default',color:'darkgray'}:{cursor:'pointer'}}
                  >
                    <i className={`fe ${obj.icon}`}></i> {obj.name}
                    {obj.totalUser ? (
                      <span className="badge badge-pill badge-soft-secondary ml-2">
                        pending
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                </li>
              ) : userTabs.length > 0 ? (
                userTabs.map((tabObj, index) => (
                  <React.Fragment key={index}>
                    {obj.name === tabObj.tab && (
                      <li className="nav-item">
                        <Link
                          to={obj.currentPath}
                          className="nav-link"
                          onClick={() =>
                            handleClients.handlepathName(obj.currentPath)
                          }
                        >
                          <i className={`fe ${obj.icon}`}></i> {obj.name}
                          {obj.totalUser ? (
                            <span className="badge badge-pill badge-soft-secondary ml-2">
                              pending
                            </span>
                          ) : (
                            ""
                          )}
                        </Link>
                      </li>
                    )}
                  </React.Fragment>
                ))
              ) : (
                ""
              )}
            </React.Fragment>
          )):<div className="text-center">
                <LoaderSmall />
            </div>}
        </ul>
      </React.Fragment>
    );
  }
}

