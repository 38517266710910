import {
    MILESTONE_LIST,
    MILESTONE_LIST_SUCCESS,
    MILESTONE_LIST_ERR,
    TRANSITION_DASHBOARD,
    TRANSITION_DASHBOARD_SUCCESS,
    TRANSITION_DASHBOARD_ERROR
  } from "../type.action";

  // Milestone list

  export const milesList = (values) => ({
    type: MILESTONE_LIST,
    values
  })
  export const milesListSuccess = (values) => ({
    type: MILESTONE_LIST_SUCCESS,
    values
  })
  export const milesListError = (error) => ({
    type: MILESTONE_LIST_ERR,
    error
  })

  // transition Dashboard =======

export const transitionDashboard = (values) => ({
  type: TRANSITION_DASHBOARD,
  values,
});
export const transitionDashboardSuccess = (values) => ({
  type: TRANSITION_DASHBOARD_SUCCESS,
  values,
});
export const transitionDashboardError = (error) => ({
  type: TRANSITION_DASHBOARD_ERROR,
  error,
});
