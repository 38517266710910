import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { deleteClient, clientList, clientBriefList } from '../../../../store/action/clientList.action';

class ClientHeaderTab extends Component {

  render() {
      const { pathIs, location, _deleteClient, _clientList, _clientBriefList } = this.props;
      // const deletedCount = clientList?.clientData?.deleteduser;
      const artistId = location.pathname.split("/:").pop();
  
      const artistCurrent = (path) => {
        const currentPath = location.pathname.split("/");
        return currentPath[2] === path;
      };  

      const currentPath = location.pathname.split("/");     
      const currentLocation = currentPath.pop();
      const forSignPath = location.pathname.split("/");     

    return (
      <React.Fragment>
         {pathIs === "Clients" &&
          currentPath[2] !== ":edit" && 
          currentPath[2] !== "clientscompany" &&
          currentPath[2] !== "allBrief" &&
          (artistId === "/clients" ||
          artistId === '/clientDeleted' ||
          forSignPath[1] !== "signup") && ( 
            <ul className="nav nav-tabs nav-overflow header-tabs">
              <li className="nav-item">
                <Link
                  to={`/clients`}
                  className={`nav-link ${
                    artistId === "/clients" ? "active" : ""
                  }`}
                >
                  All Client
                  <span className="badge badge-pill badge-soft-secondary ml-2">
                      {_clientList?.clientData?.totalUsers}                   
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={`/clients/clientDeleted`}
                  className={`nav-link ${
                    artistCurrent("clientDeleted") ? "active" : ""
                  }`}
                >
                  Deleted
                  <span className="badge badge-pill badge-soft-secondary ml-2">
                     {currentLocation==="clientDeleted"?_deleteClient?.DeleteClientIs?.totalUsers:_clientList?.clientData?.deleteduser}                     
                  </span>
                </Link>
              </li>              
            </ul>
          )}
        {pathIs === "Clients" &&
          artistId !== "/clients" &&
          artistId !== "/clients/clientDeleted"&&
          forSignPath[1] !== "signup" && (
            <ul className="nav nav-tabs nav-overflow header-tabs">
              <li className="nav-item">
                <Link
                  to={`/clients/:edit/:${artistId}`}
                  className={`nav-link ${
                    artistCurrent(":edit") ? "active" : ""
                  }`}
                >
                  Basic Details
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={`/clients/clientscompany/:${artistId}`}
                  className={`nav-link ${
                    artistCurrent("clientscompany") ? "active" : ""
                  }`}
                >
                  Company
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={`/clients/allBrief/:${artistId}`}
                  className={`nav-link ${
                    artistCurrent("allBrief") ? "active" : ""
                  }`}
                >
                 All Brief
                  <span className="badge badge-pill badge-soft-secondary ml-2">
                     {_deleteClient.DeleteClientIs.totalUsers}                     
                  </span>
                <span className="badge badge-pill badge-soft-secondary ml-2">{_clientBriefList.clientAllBriefIs.totalUsers}</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={`/clients/notifications/:${artistId}`}
                  className={`nav-link ${
                    artistCurrent("notifications") ? "active" : ""
                  }`}
                >
                      Notifications
                  <span className="badge badge-pill badge-soft-secondary ml-2">             
                  </span>
                <span className="badge badge-pill badge-soft-secondary ml-2"></span>
                </Link>
              </li>
            </ul>
          )}
      </React.Fragment>
    );
  }
}
const mapState = ({ deleteClient, clientList, clientBriefList }) => {
  return {
    _deleteClient: deleteClient,   
    _clientList: clientList,
    _clientBriefList : clientBriefList
  };
};
const mapDispatch = (dispatchEvent) => ({
  $deleteClient: (values) => dispatchEvent(deleteClient(values)),  
  $clientList: (values) => dispatchEvent(clientList(values)), 
  $clientBriefList: (values) => dispatchEvent(clientBriefList(values)), 
});
export default connect(mapState, mapDispatch)(ClientHeaderTab);