import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import { approvedCreatorList,approvedStarredList } from "../../../../store/action/collateralList.action";

class CollateralHeaderTab extends Component {

  state = {
    totalApprovedCreator: '',
    totalApprovedStarred:'',
    collateralDataCreator:'',
    collateralDataStarred:'',
  };

  // componentDidMount() {
  //   const { location, currentCollateralActiveIs } = this.props;
  //   console.log(currentCollateralActiveIs)
    // if(currentCollateralActiveIs){
    //   const artistId = location.pathname.split("/:").pop();
    //   const currentDataIs = { pageIs: 1, sortIs: 1, id: artistId };
    //   this.props.$approvedCreatorList(currentDataIs);
    //   this.props.$approvedStarredList(currentDataIs);
    // }    
  // }

  componentWillReceiveProps(nextProps) {
    
    const creatorListIs = nextProps._approvedCreatorList?.approvedCreatorIs;
    const starredListIs = nextProps._approvedStarredList?.approvedStarredIs;
    const collateralData = nextProps._getCollateral?.collateralDataIs;
    // if (creatorListIs || starredListIs) {
      this.setState({
        totalApprovedCreator: creatorListIs?.totalUsers!==0?creatorListIs?.totalUsers:"0",
          
        totalApprovedStarred: 
           starredListIs?.totalUsers!==0?starredListIs?.totalUsers:"0"
          
      });
    // }
    if(collateralData){
      this.setState({
        collateralDataCreator:collateralData?.creatorCount!==0 ? collateralData?.creatorCount:"0",
        collateralDataStarred:collateralData?.starredCreatorCount!==0 ? collateralData?.starredCreatorCount:"0"
      })
    }

  }

  render() {
    const { pathIs, location } = this.props;
    const { totalApprovedCreator ,totalApprovedStarred, collateralDataCreator,collateralDataStarred} = this.state;
    const artistId = location.pathname.split("/:").pop();
    const currentCollateralTab = (path) => {
      const currentPath = location.pathname.split("/");
      return currentPath[2] === path;
    };

    const addCollateral = this.props.location.pathname.split("/");

    const collateralArr = [
      {
        name: "Basic Details",
        path: `/collateralsQuestions/:edit/:${artistId}`,
        activeBorder: ":edit",
        totalUsers: "",
      },
      {
        name: "Questions",
        path: `/collateralsQuestions/questions/:${artistId}`,
        activeBorder: "questions",
        totalUsers: "",
      },
      {
        name: "Creator",
        path: `/collateralsQuestions/creator/:${artistId}`,
        activeBorder: "creator",
        totalUsers:location.pathname.split('/').includes('creator')?totalApprovedCreator:collateralDataCreator,
      },
       {
        name: "Starred",
        path: `/collateralsQuestions/Starred/:${artistId}`,
        activeBorder: "Starred",
        totalUsers: location.pathname.split('/').includes('Starred')?totalApprovedStarred:collateralDataStarred
      }
    ];

    return (
      <React.Fragment>
        {pathIs === "Collaterals" &&
          artistId !== "/collaterals" &&
          addCollateral[2] !== ":add" && (
            <ul className="nav nav-tabs nav-overflow header-tabs">
              {collateralArr.map((obj, index) => (
                <li className="nav-item" key={index}>
                  <Link
                    to={obj.path}
                    className={`nav-link ${
                      currentCollateralTab(`${obj.activeBorder}`)
                        ? "active"
                        : ""
                    }`}
                  >
                    {obj.name}
                    {(obj.totalUsers ) ? (
                      <span className="badge badge-pill badge-soft-secondary ml-3">
                        {obj.totalUsers}
                      </span>
                    ): ""}
                  </Link>
                </li>
              ))}
            </ul>
          )}
      </React.Fragment>
    );
  }
}
const mapState = ({ approvedCreatorList,approvedStarredList }) => {
  return {
    _approvedCreatorList: approvedCreatorList,
    _approvedStarredList:approvedStarredList
  };
};
const mapDispatch = (dispatchEvent) => ({
  // $approvedCreatorList: (values) => dispatchEvent(approvedCreatorList(values)),
  // $approvedStarredList: (values) => dispatchEvent(approvedStarredList(values)),
});
export default connect(mapState, mapDispatch)(CollateralHeaderTab);
