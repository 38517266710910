import React, { Component } from "react";
import { connect } from "react-redux";
import {
  transactionList,
  closeProject,
  // activeTransactions,
  // incompleteTransactions,
  // incompleteHighTransaction,
  // completeProject,
  // closeTransactionList,


  // DontLikeTranscationList,


  // pendingTransactions,
  // rejectedBriefList,
  resetTransactionList,
  deleteProjectList,
  // deleteBriefList,
  // deleteProjectAllList,
  // closedBriefAllList,
  // closeBriefRequestList,
  // inactiveBrief,
  // inactiveProject
} from "../../../store/action/transaction.action";
// import {updateHeaderCount} from "../../../store/action/Header.action";
import ReactPaginate from "react-paginate";
// import Table from "./Table";
import ErrorApi from "../Common/Error/ErrorApi";
import { deleteAPI } from "../../../store/api-interface";
import TableArtistTrans from "./TableArtistTrans";

class Transactions extends Component {
  state = {
    transactionsList: 1,
    selectedPage: 1,
    transactionActive: false,
    LoadingIs: false,
    transactionData: [],
    transactionIs: "",
    minBudget: "",
    maxBudget: "",
    deadline: "",
    totalPages: 0,
    closeProjectActive: false,
    deleteActiveIs: false,
    headCheckbox: false,
    scopeValue:""
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        const {
          transactionsList,
          transactionIs,
          minBudget,
          maxBudget,
          deadline,
          scopeValue
        } = this.state;
        const artistId=this.props.location.pathname.split(':');
        
        const transactionData = {
          List: transactionsList,
          project: transactionIs,
          min_budget: minBudget,
          max_budget: maxBudget,
          deadline: deadline,
          budget_scope: scopeValue.value,
          id:artistId[1]
        };
        this.props.$transactionList(transactionData);
        
        // const headerData={
        //   isCountUpdating:true,
        //   location:this.props.location.pathname
        // }
        // this.props.$updateHeaderCount(headerData)
      }
    );
  };

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    const { transactionIs, minBudget, maxBudget, deadline,scopeValue } = this.state;
    const artistId=this.props.location.pathname.split(':');

    const transactionData = {
      List: selectedData,
      project: transactionIs,
      min_budget: minBudget,
      max_budget: maxBudget,
      deadline: deadline,
      budget_scope: scopeValue.value,
      id:artistId[1]
    };
    this.setState({ selectedPage: selectedData });
    this.props.$transactionList(transactionData);
  };

  handleCloseProject = (id) => {
    this.setState({
      closeProjectActive: true,
    });
    const closeProjectData = { _id: id };
    this.props.$closeProject(closeProjectData);
  };

  handleDeleteProject = (id) => {
    this.setState({ deleteActiveIs: true });
    const currentData = { _id: id };
    this.props.$deleteProjectList(currentData);
  };

  handleTransactionDetails = () => {
    const { transactionsList, transactionIs } = this.state;
    const artistId=this.props.location.pathname.split(':');

    this.setState({
      LoadingIs: true,
    });

    const transactionData = {
      List: transactionsList,
      project: transactionIs,
      id:artistId[1]
    };
    this.props.$transactionList(transactionData);
  };

  // handleAllTransactionApi = () => {
  //   const artistId=this.props.location.pathname.split(':');

  //   const transactionData = {
  //     List: 1,
  //     project: "",
  //     min_budget: "",
  //     max_budget: "",
  //     deadline: "",
  //     id:artistId[1]
  //   };
    // this.props.$activeTransactions(transactionData);
    // this.props.$incompleteTransactions(transactionData);


    // this.props.$incompleteHighTransaction(transactionData);

    // these two lines should uncomment
    // this.props.$completeProject(transactionData);
    // this.props.$closeTransactionList(transactionData);


    // this.props.$DontLikeTranscationList(transactionData);


    // this.props.$pendingTransactions(transactionData);
    // this.props.$rejectedBriefList(transactionData);
    // this.props.$deleteBriefList(transactionData);
    // this.props.$deleteProjectAllList(transactionData);
    // this.props.$closedBriefAllList(transactionData);
    // this.props.$closeBriefRequestList(transactionData);
    // this.props.$inactiveBrief(transactionData);
    // this.props.$inactiveProject(transactionData);
  // };

  componentDidMount() {
    this.props.$resetTransactionList()
    this.setState({
      transactionActive: true,
    });
    this.handleTransactionDetails();
    if(this.props.handleClients)
    this.props.handleClients.mainIndexstate({
      transactionAllFieldActive: true,
    });
    // this.handleAllTransactionApi();
  }

  handleApiCall = () => {
    this.setState({
      transactionActive: true,
    });
    this.handleTransactionDetails();
  }

  componentWillReceiveProps(nextProps) {
    const transactionData = nextProps._transactionList.transactionDetails;
    const closedProject = nextProps._closeProject.closeProjectIs;
    const deleteProject = nextProps._deleteProjectList.deleteProjectIs;
    const { closeProjectActive, deleteActiveIs } = this.state;
    if (transactionData && !transactionData.LoadingError) {
      this.setState({
        transactionData: transactionData.PostProjects,
        LoadingIs: false,
        totalPages: transactionData.totalPages,
      });
    }
    if (closedProject && closeProjectActive) {
      alert(closedProject.message);
      this.setState({
        closeProjectActive: false,
      });
      this.handleTransactionDetails();
    }
    if (deleteProject && deleteActiveIs) {
      this.setState({ deleteActiveIs: false });
      this.handleTransactionDetails();
    }
  }

  onCheckboxClick = (id) => {
    const { headCheckbox, transactionData } = this.state;
    if (id === "all") {
      const newTransactionList = transactionData.map((tranx) => {
        headCheckbox ? (tranx.selected = false) : (tranx.selected = true);
        return tranx;
      });
      this.setState({
        transactionData: newTransactionList,
        headCheckbox: !this.state.headCheckbox,
      });
    } else {
      const newTransactionList = transactionData.map((tranx) => {
        if (tranx._id === id) {
          tranx.selected = !tranx.selected;
        }
        return tranx;
      });
      this.setState({ transactionData: newTransactionList });
    }
  };

  handleDeleteAll = () => {
    const { transactionData } = this.state;
    this.setState({ isDeleting: true });

    const deletedIds = [];
    transactionData.forEach((tranx) => {
      tranx.selected && deletedIds.push(tranx._id);
    });

    if (window.confirm("Are you sure you want to delete these transactions.")) {
      deleteAPI(`/postProject/delete-postproject-multiple`, { _id: deletedIds })
        .then((res) => {
          this.handlePageClick({ selected: this.state.selectedPage - 1 });
          this.setState({ isDeleting: false });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isDeleting: false });
        });
    } else {
      this.setState({ isDeleting: false });
    }
  };

  handleOnSelect = (selectedOption) => {
    // console.log("selectedOption = ", selectedOption, name)
    this.setState({ scopeValue: selectedOption }, () => {
      
      const {
        transactionsList,
        transactionIs,
        minBudget,
        maxBudget,
        deadline,
        scopeValue
      } = this.state;
      const artistId=this.props.location.pathname.split(':');

      const transactionData = {
        List: transactionsList,
        project: transactionIs,
        min_budget: minBudget,
        max_budget: maxBudget,
        deadline: deadline,
        id:artistId[1],
        budget_scope: scopeValue.value
      };
      this.props.$transactionList(transactionData);
    })
  };

  render() {
    const { totalPages, isDeleting } = this.state;

    const transactionFunc = {
      handleOnChange: this.handleOnChange,
      handleCloseProject: this.handleCloseProject,
      handleDeleteProject: this.handleDeleteProject,
      onCheckboxClick: this.onCheckboxClick,
      handleApiCall: this.handleApiCall,
      handleOnSelect: this.handleOnSelect
    };
    const anySelectedTranx = this.state.transactionData.some(
      (val) => val.selected
    );
    // console.log(this.state.selectedPage)
    return (
      <React.Fragment>
        {/* {console.log(this.props,"--------------------------------")} */}
        {this.props._transactionList.LoadingError ? (
          <ErrorApi {...this.props} />
        ) : (
          <section className="client">
            <div className="container-fluid mx-auto">
              <TableArtistTrans
                {...this.state}
                {...this.props}
                handleTransaction={transactionFunc}
                artistId = {"/transactions"}
              />

              <div className="">
                {anySelectedTranx && (
                  <button
                    className="btn btn-danger"
                    onClick={this.handleDeleteAll}
                    disabled={isDeleting}
                  >
                    Delete selected
                  </button>
                )}

                {/******** pagination ************* */}

                <div className="text-center">
                  {totalPages > 0 && (
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({
  transactionList,
  closeProject,
  // activeTransactions,
  // incompleteTransactions,
  // incompleteHighTransaction,
  // completeProject,
  // closeTransactionList,
  
  // DontLikeTranscationList,
  // pendingTransactions,
  // rejectedBriefList,
  deleteProjectList,
  // deleteBriefList,
  // deleteProjectAllList,
  // closedBriefAllList,
  // closeBriefRequestList,
  // inactiveBrief,
  // inactiveProject,
  // updateHeaderCount
}) => {
  return {
    _transactionList: transactionList,
    _closeProject: closeProject,
    // _activeTransactions: activeTransactions,
    // _incompleteTransactions: incompleteTransactions,
    // _incompleteHighTransaction: incompleteHighTransaction,
    // _completeProject: completeProject,
    // _closeTransactionList: closeTransactionList,


    // _DontLikeTranscationList: DontLikeTranscationList,


    // _pendingTransactions: pendingTransactions,
    // _rejectedBriefList: rejectedBriefList,
    _deleteProjectList: deleteProjectList,
    // _deleteBriefList: deleteBriefList,
    // _deleteProjectAllList: deleteProjectAllList,
    // _closedBriefAllList: closedBriefAllList,
    // _closeBriefRequestList: closeBriefRequestList,
    // _inactiveBrief : inactiveBrief,
    // _inactiveProject : inactiveProject,
    // _updateHeaderCount:updateHeaderCount
  };
};
const mapDispatch = (dispatchEvent) => ({
  $transactionList: (values) => dispatchEvent(transactionList(values)),
  $closeProject: (values) => dispatchEvent(closeProject(values)),
  // $activeTransactions: (values) => dispatchEvent(activeTransactions(values)),
  // $incompleteTransactions: (values) =>  dispatchEvent(incompleteTransactions(values)),
  // $incompleteHighTransaction: (values) => dispatchEvent(incompleteHighTransaction(values)),
  // $completeProject: (values) => dispatchEvent(completeProject(values)),
  // $closeTransactionList: (values) =>  dispatchEvent(closeTransactionList(values)),


  // $DontLikeTranscationList: (values) => dispatchEvent(DontLikeTranscationList(values)),


  // $pendingTransactions: (values) => dispatchEvent(pendingTransactions(values)),
  // $rejectedBriefList: (values) => dispatchEvent(rejectedBriefList(values)),
  $deleteProjectList: (values) => dispatchEvent(deleteProjectList(values)),
  $resetTransactionList : (values) => dispatchEvent(resetTransactionList(values)),

  // $deleteBriefList: (values) => dispatchEvent(deleteBriefList(values)),
  // $deleteProjectAllList: (values) =>dispatchEvent(deleteProjectAllList(values)),
  // $closedBriefAllList: (values) => dispatchEvent(closedBriefAllList(values)),
  // $closeBriefRequestList: (values) => dispatchEvent(closeBriefRequestList(values)),
  // $inactiveBrief: (values) =>dispatchEvent(inactiveBrief(values)), 
  // $inactiveProject: (values) =>dispatchEvent(inactiveProject(values)) 
  // $updateHeaderCount:(values)=>dispatchEvent(updateHeaderCount(values))
});
export default connect(mapState, mapDispatch)(Transactions);
