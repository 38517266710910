import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import {
  AllCollateralList,
  getUserList,
  addPostProject,
  getPostProjectData,
  updateProject,
} from "../../../../store/action/projectList.action";
import ProjectOtherDetails from "./ProjectOtherDetails";
import LoaderSmall from "../../Common/Loader/LoaderSmall";
import { getPublicAPI } from "../../../../store/api-interface";
import CompanyDetails from "./CompanyDetails";
import Review from "./Review";
import LastMsgBy from "./LastMsgBy";
import ProjectStatus from "./ProjectStatus";
import {validationForm, validation} from "./AddProjectFunction";
import AskQuestion from "../AskQuestion";
import ProjectType from "./ProjectType";

class AddProject extends Component {
  state = {
    selectedOption: null,
    deadlineStatus: 0,
    selectedClient: null,
    selectedCreator: null,
    selectedRepresntative: null,
    collateralName: "",
    collateralData: [],
    AllClientList: [],

    minBudget: "",
    maxBudget: "",
    unit: "",
    project: "",
    projectDescription: "",
    inputCollateral: "",
    LoadingIs: false,
    EditIs: false,
    projectDetailsIs: [],
    LoaderIs: false,
    flag: false,
    errorIs: {
      minBudget: "",
      maxBudget: "",
      project: "",
      projectDescription: "",
      unit: "",
      companyJob: "",
      companyLink: "",
      companyDesc: "",
    },
    clientMsg: "",
    creatorMsg: "",
    representativeMsg: "",
    StatusIs: 0,
    editRes: false,
    clientId: "",
    creatorId: "",
    representativeId: "",
    companyName: null,
    companyJob: "",
    companyLink: "",
    companyLocation: "",
    companyIndustry: null,
    companyDesc: "",
    companyType: "Company",
    selectedLocation: null,
    selectedEmp: null,
    selectedLocIndividual: null,
    industryMsg: "",
    locationMsg: "",
    empMsg: "",
    locationIndividualMsg: "",
    companyMsg: "",
    clientListIs: [],
    creatorListIs: [],
    ReviewIs: [],
    projectCompDate: "",
    LastMsgIs: "",
    reviewQuestn :[],
    project_type:'fixed',
    number_hour:0,
    number_month:0,
    number_hour_msg:"",
    number_month_msg:"",
    project_deadline_date:new Date(),
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    validationForm(name, value, this.state);
  };

  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    this.setState({ inputCollateral: inputValue }, () => {
      this.handleCollateralList(inputValue);
    });
  };

  handleCollChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleOnChangeDeadlineDate = (value) =>{
    this.setState({project_deadline_date:value})
  }

  handleUserList = (emailValue) => {
    getPublicAPI(`/postProject/get-user-for-project?email=${emailValue}`).then(
      (res) => {
        this.setState({ AllClientList: res.data.data ? res.data.data : "" });
      }
    );
  };

  handleClientList = (clientValue) => {
    getPublicAPI(
      `/postProject/get-client-for-project?email=${clientValue}`
    ).then((res) => {
      const response = res.data.data;
      if (response) {
        this.setState({ clientListIs: response });
      }
    });
  };

  handleChangeProjectType=(e)=>{
    const {value} = e.target
    this.setState({project_type:value})    
  }

  handleChangeRetainerWorkHour = (e) =>{
    const {name,value} = e.target;
    // const {errorIs} = this.state;
    // errorIs[name]=""
    if(name=='number_hour')
    this.setState({[name]:value,number_hour_msg:""})
    else
    this.setState({[name]:value,number_month_msg:""})
  }

  handleCreatorList = (creatorEmail) => {
    const { selectedOption } = this.state;
    const id = selectedOption ? selectedOption._id : "";
    if (id) {
      getPublicAPI(
        `/postProject/get-creator-for-project?email=${creatorEmail}&collateral_id=${id}`
      ).then((res) => {
        const response = res.data.data;
        if (response) {
          this.setState({ creatorListIs: response });
        }
      });
    }
  };

  handleClient = (inputValue) => {
    this.handleClientList(inputValue);
  };

  // handleClientChange = (selectedClient) => {
  //   this.setState({ selectedClient, clientMsg: "" });
  // };

  handleClientChange = (selectedClient) => {
    // console.log("clientListIs = ",this.state.clientListIs)
    let arr = this.state.clientListIs.filter(obj => obj?._id === selectedClient?._id)
    let op = arr ? arr[0] : ""
    this.setState({ selectedClient, clientMsg: "", clientAll: op }, () => {
      this.handleClientDetails(this.state.selectedClient?._id)
    });
  };

  handleCreatorChange = (selectedCreator) => {
    this.setState({ selectedCreator, creatorMsg: "" });
  };

  handleCreator = (inputValue) => {
    this.handleCreatorList(inputValue);
  };

  handleRepresntativeChange = (selectedRepresntative) => {
    this.setState({ selectedRepresntative, representativeMsg: "" });
  };

  handleRepresntative = (inputValue) => {
    this.handleUserList(inputValue);
  };

  handleCompanyType = (status) => {
    const {
      errorIs,
      companyIndustry,
      selectedLocation,
      selectedEmp,
    } = this.state;
    this.setState({ companyType: status });
    if (status === "Company" || status === "Agency") {
      this.setState({
        locationIndividualMsg: "",
        empMsg: selectedEmp && selectedEmp.label && "",
        locationMsg: selectedLocation && selectedLocation.label && "",
        industryMsg: companyIndustry && companyIndustry.label && "",
      });
    } else if (status === "Individual") {
      errorIs.companyName = "";
      errorIs.companyJob = "";
      errorIs.companyLink = "";
      errorIs.companyDesc = "";
      this.setState({ empMsg: "", locationMsg: "", industryMsg: "", errorIs });
    }
  };

  handleChangeEmp = (selectedEmp) => {
    this.setState({ selectedEmp: selectedEmp, empMsg: "" });
  };

  handleLocationSelect = (selectedLocation) => {
    this.setState({ selectedLocation, locationMsg: "" });
  };

  handleLocaIndividual = (selectedLocIndividual) => {
    this.setState({ selectedLocIndividual, locationIndividualMsg: "" });
  };

  handleIndustrySelect = (companyIndustry) => {
    this.setState({ companyIndustry, industryMsg: "" });
  };

  handleChange = (companyName) => {
    this.setState({ companyName, companyMsg: "" });
  };



  handleUpdate = (e) => {
    const {
      selectedOption,
      minBudget,
      maxBudget,
      unit,
      project,
      projectDescription,
      deadlineStatus,
      projectDetailsIs,
      selectedClient,
      selectedCreator,
      selectedRepresntative,
      clientId,
      representativeId,
      creatorId,
      companyType,
      companyName,
      companyJob,
      companyLink,
      selectedLocation,
      selectedEmp,
      companyDesc,
      companyIndustry,
      selectedLocIndividual,
      project_type,
      number_hour,
      number_month,
      project_deadline_date,
    } = this.state;
    if (
      validation(this.state) &&
      selectedClient &&
      selectedCreator &&
      // selectedRepresntative &&
      (((companyType === "Company" || companyType === "Agency") &&
        companyIndustry?.label &&
        selectedLocation?.label &&
        selectedEmp?.label &&
        companyName?.label) ||
        (companyType === "Individual" && selectedLocIndividual?.label))
        &&
        ((project_type=='retainer' && number_hour>0 && number_month>0) || (project_type=='fixed'))
    ) {
      const data = {
        _id: projectDetailsIs ? projectDetailsIs.project._id : "",
        collateral_id: selectedOption._id,
        client_id: selectedClient._id ? selectedClient._id : clientId,
        creator_id: selectedCreator._id ? selectedCreator._id : creatorId,
        // representative_id: selectedRepresntative._id
        //   ? selectedRepresntative._id
        //   : representativeId,
        type: "post_project_admin",
        min_budget: minBudget,
        max_budget: maxBudget,
        unit: unit ? unit : "",
        dealline: deadlineStatus,
        project_name: project,
        project_description: projectDescription,
        status: projectDetailsIs ? projectDetailsIs.project.status : "",
        company_work_type: companyType,
        company_name: companyType === "Individual" ? "" : companyName ? companyName.label : "",
        company_job_role: companyType === "Individual" ? "" : companyJob,
        company_add_link: companyType === "Individual" ? "" : companyLink,
        company_location: companyType === "Individual" ? "" : selectedLocation ? selectedLocation.label : "",
        company_employees: companyType === "Individual" ? "" : selectedEmp ? selectedEmp.label : "",
        company_describes: companyType === "Individual" ? "" : companyDesc,
        company_industry: companyType === "Individual" ? "" : companyIndustry ? companyIndustry.label : "",
        company_individual_location:
          companyType !== "Company"
            ? selectedLocIndividual.label
            : ""
            ? selectedLocIndividual.label
              : "",
        project_type:project_type,
        number_hour:number_hour,
        number_month:number_month,
        project_deadline_date:project_deadline_date,
      };
      this.setState({ LoaderIs: true });
      this.props.$updateProject(data);
    } else {
      this.setState({
        clientMsg: !selectedClient ? "Required Field" : "",
        creatorMsg: !selectedCreator ? "Required Field" : "",
        representativeMsg: !selectedRepresntative ? "Required Field" : "",
        companyMsg:
          (companyType === "Company" || companyType === "Agency") &&
          !companyName?.label
            ? " Required Field"
            : "",
        industryMsg:
          (companyType === "Company" || companyType === "Agency") &&
          !companyIndustry.label
            ? "Required Field"
            : "",
        locationMsg:
          (companyType === "Company" || companyType === "Agency") &&
          !selectedLocation?.label
            ? "Required Field"
            : "",
        empMsg:
          (companyType === "Company" || companyType === "Agency") &&
          !selectedEmp?.label
            ? "Required Field"
            : "",
        locationIndividualMsg:
          companyType === "Individual" && !selectedLocIndividual?.label
            ? "Required Field"
            : "",
            number_hour_msg:(project_type === 'retainer' && number_hour<=0)?'Required Field':"",
            number_month_msg:(project_type === 'retainer' && number_month<=0)?'Required Field':"",
      });
    }
  };

  handleSubmit = () => {
    const {
      selectedOption,
      minBudget,
      maxBudget,
      unit,
      project,
      projectDescription,
      selectedClient,
      selectedCreator,
      selectedRepresntative,
      deadlineStatus,
      companyName,
      companyJob,
      companyLink,
      companyIndustry,
      companyDesc,
      companyType,
      selectedEmp,
      selectedLocation,
      selectedLocIndividual,
      project_type,
      number_hour,
      number_month,
      project_deadline_date,
    } = this.state;
    
    if (
      validation(this.state) &&
      selectedClient &&
      selectedCreator &&
      // selectedRepresntative &&
      (((companyType === "Company" || companyType === "Agency") && 
        companyIndustry?.label   &&
        selectedLocation?.label &&
        selectedEmp?.label &&
        companyName?.label) ||
        (companyType === "Individual" && selectedLocIndividual?.label))
        &&
        ((project_type=='retainer' && number_hour>0 && number_month>0) || (project_type=='fixed'))
    ) {
      const data = {
        collateral_id: selectedOption._id,
        client_id: selectedClient._id,
        creator_id: selectedCreator._id,
        // representative_id: selectedRepresntative._id,
        type: "post_project_admin",

        min_budget: minBudget,
        max_budget: maxBudget,
        unit: unit ? unit : "",
        dealline: deadlineStatus,
        project_name: project,
        project_description: projectDescription,
        company_work_type: companyType,
        company_name: companyType === "Individual" ? "" : companyName ? companyName.label : "",
        company_job_role: companyType === "Individual" ? "" : companyJob,
        company_add_link: companyType === "Individual" ? "" : companyLink,
        company_location: companyType === "Individual" ? "" : selectedLocation ? selectedLocation.label : "",
        company_employees: companyType === "Individual" ? "" : selectedEmp ? selectedEmp.label : "",
        company_describes: companyType === "Individual" ? "" : companyDesc,
        company_industry: companyType === "Individual" ? "" : companyIndustry ? companyIndustry.label : "",
        company_individual_location: selectedLocIndividual
          ? selectedLocIndividual.label
          : "",
        project_from:"admin_add_project",
        project_type:project_type,
        number_hour:number_hour,
        number_month:number_month,
        project_deadline_date:project_deadline_date,
            };

      this.setState({
        LoadingIs: true,
        clientMsg: "",
      });
      // console.log("data = == ",data)
      this.props.$addPostProject(data);
    } else {
      //  console.log("123 = == ")
      this.setState({
        clientMsg: !selectedClient ? "Required Field" : "",
        creatorMsg: !selectedCreator ? "Required Field" : "",
        representativeMsg: !selectedRepresntative ? "Required Field" : "",
       companyMsg:
          (companyType === "Company" || companyType === "Agency") &&
          !companyName?.label
            ? " Required Field"
            : "",
        industryMsg:
          (companyType === "Company" || companyType === "Agency") &&
          !companyIndustry?.label
            ? "Required Field"
            : "",
        locationMsg:
          (companyType === "Company" || companyType === "Agency") &&
          !selectedLocation?.label
            ? "Required Field"
            : "",
        empMsg:
          (companyType === "Company" || companyType === "Agency") &&
          !selectedEmp?.label
            ? "Required Field"
            : "",
        locationIndividualMsg:
          companyType === "Individual" && !selectedLocIndividual?.label
            ? "Required Field"
            : "",
        number_hour_msg:(project_type === 'retainer' && number_hour<=0)?'Required Field':"",
        number_month_msg:(project_type === 'retainer' && number_month<=0)?'Required Field':"",
      });
    }
  };

  handleCollateralList = (inputCollateral) => {
    this.setState({ chooseCollateralIs: false });
    this.props.$AllCollateralList(inputCollateral);
  };

  handleGetProjectData = (id) => {
    this.setState({ flag: true });
    this.props.$getPostProjectData(id);
  };

  componentDidMount() {
    const newValue = "";
    this.handleUserList(newValue);
    this.handleClientList(newValue);
    this.handleCreatorList(newValue);
    setTimeout(() => {
      const projectId = this.props.match.params.id.split(":").pop();
      if (projectId) {
        this.handleGetProjectData(projectId);
        this.setState({
          EditIs: true,
          editRes: true,
        });
      }
    }, 200);
  }

  handleClientDetails = (id) => {
    // console.log("id = ",id)
    getPublicAPI(`/users/get-company-details?userId=${id}`).then(
        (res) => {
        let data = res?.data
        // console.log("data = = ", data)
        this.setState({
          companyType: data?.company_work_type?.toLowerCase() == "individual" ? "Individual" : data?.company_work_type?.toLowerCase() == "company" ? "Company" : "Agency",
          companyName: data
            ? { label: data.company_name, value: data.company_name }
            : "",
          companyJob: data?.company_job_role,
          companyLink: data?.company_add_link,
          companyDesc: data?.company_describes,
          selectedLocation: data
            ? {
              value: data.company_location,
              label: data.company_location,
            }
            : "",
          companyIndustry: data
            ? {
              value: data.company_industry,
              label: data.company_industry,
            }
            : "",
          selectedEmp: data
            ? {
              value: data.company_employees,
              label: data.company_employees,
            }
            : "",
          selectedLocIndividual: data.company_individual_location
            ? {
              value: data.company_individual_location,
              label: data.company_individual_location,
            }
            : "",
        });
      }
      ).catch(err => {
      console.log(err)
    })
  }

  componentWillReceiveProps(nextProps) {
    const { LoadingIs, EditIs, LoaderIs, flag } = this.state;
    const dataList = nextProps._AllCollateralList.allCollateralIs;
    const ProjectData = nextProps._addPostProject.addProjectIs;
    const getData = nextProps._getPostProjectData.postProjectDataIs;
    const updateData = nextProps._updateProject.updateDataIs;
    const feedbackIs = getData.completeProjectReview;
    const closeReason = getData.closeProjectReview;
    const lastMsgData = getData.lastMsg;
    if (ProjectData && LoadingIs) {
      alert("Successfully done");
      const id = ProjectData.project._id;
      this.props.history.push(`/transactions/ProjectMilestone/:${id}`);
      this.setState({ LoadingIs: false });
    }
    if (dataList.status === "1") {
      this.setState({
        collateralData: dataList ? dataList.data : "",
      });
    }
    if (getData && EditIs && flag) {
      const collateralData = getData.collateral;
      const projectData = getData.project;
      const userData = getData.users;
      const clientNow = userData?.client_id;
      const creatorNow = userData?.creator_id;
      const repNow = userData?.representative_id;
      // let id_client = getData?.clientUser?.user_id1
      this.setState({
        projectDetailsIs: getData,
        selectedOption: collateralData
          ? {
              value: collateralData.name,
              label: collateralData.name,
              ...collateralData,
            }
          : "",
        deadlineStatus: projectData?.dealline,
        minBudget: projectData?.min_budget,
        maxBudget: projectData?.max_budget,
        project: projectData?.project_name,
        projectDescription: projectData?.project_description ? this.convertToPlain(projectData?.project_description) : "",
        unit: projectData?.unit,
        StatusIs: projectData?.status,
        LastMsgIs: lastMsgData ? lastMsgData : "",
        flag: false,
        listIs: false,
        selectedClient: clientNow
          ? {
              label: `${clientNow.first_name} ${clientNow.last_name} (${clientNow.email})`,
              _id: clientNow._id,
              value: clientNow.first_name + " " + clientNow.last_name,
              email: clientNow.email,
            }
          : "",
        selectedCreator: creatorNow
          ? {
              _id: creatorNow._id,
              label: `${creatorNow.first_name} ${creatorNow.last_name} (${creatorNow.email})`,
              value: creatorNow.first_name + " " + creatorNow.last_name,
              email: creatorNow.email,
            }
          : "",
        selectedRepresntative: repNow
          ? {
              _id: repNow._id,
              label: `${repNow.first_name} ${repNow.last_name} (${repNow.email})`,
              value: repNow.first_name + " " + repNow.last_name,
              email: repNow.email,
            }
          : "",
        editRes: false,
        clientId: userData?.client_id,
        creatorId: userData?.creator_id,
        representativeId: userData?.representative_id,
        companyType: projectData?.company_work_type,
        companyName: projectData
          ? { label: projectData.company_name, value: projectData.company_name }
          : "",
        companyJob: projectData?.company_job_role,
        companyLink: projectData?.company_add_link,
        companyDesc: projectData?.company_describes,
        selectedLocation: projectData
          ? {
              value: projectData.company_location,
              label: projectData.company_location,
            }
          : "",
        companyIndustry: projectData
          ? {
              value: projectData.company_industry,
              label: projectData.company_industry,
            }
          : "",
        selectedEmp: projectData
          ? {
              value: projectData.company_employees,
              label: projectData.company_employees,
            }
          : "",
        selectedLocIndividual: projectData.company_individual_location
          ? {
              value: projectData.company_individual_location,
              label: projectData.company_individual_location,
            }
          : "",
        ReviewIs:
          projectData.status === 7 && feedbackIs
            ? feedbackIs
            : (projectData.status === 8 && closeReason) ||
              (projectData.status === 5 && closeReason) ||
              (projectData.status === 6 && closeReason)
            ? closeReason
            : [],
        projectCompDate: projectData?.project_completed_date,
        reviewQuestn : getData.completeProjectQuestion,
        project_type:projectData.sub_type,
        number_hour:projectData.number_hour,
        number_month:projectData.number_month,
        project_deadline_date:new Date(projectData.project_deadline_date),
      });
    }
    if (updateData && LoaderIs) {
      alert("Successfully Updated ...");
      this.setState({ LoaderIs: false });
      const projectId = this.props.match.params.id.split(":").pop();
      this.handleGetProjectData(projectId);
    }
  }

  convertToPlain = (html) => {

    var tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = html;
  
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }

  render() {
    // console.log(this.state.projectDetailsIs)
    const {
      selectedOption,
      collateralData,
      LoadingIs,
      EditIs,
      LoaderIs,
      StatusIs,
      ReviewIs,
      projectCompDate,
      LastMsgIs,
      project_type,
    } = this.state;

    const optionData = collateralData?.map((obj) => {
      let options = {
        value: obj.name,
        label: obj.name,
        ...obj,
      };
      return options;
    });

    const projectFunc = {
      handleOnChange: this.handleOnChange,
      handleClientChange: this.handleClientChange,
      handleCreatorChange: this.handleCreatorChange,
      handleCreator: this.handleCreator,
      handleRepresntativeChange: this.handleRepresntativeChange,
      handleRepresntative: this.handleRepresntative,
      handleClient: this.handleClient,
      handleCompanyType: this.handleCompanyType,
      handleLocationSelect: this.handleLocationSelect,
      handleChangeEmp: this.handleChangeEmp,
      handleLocaIndividual: this.handleLocaIndividual,
      handleIndustrySelect: this.handleIndustrySelect,
      handleChange: this.handleChange,
      handleChangeProjectType:this.handleChangeProjectType,
      handleChangeRetainerWorkHour:this.handleChangeRetainerWorkHour,
      handleOnChangeDeadlineDate:this.handleOnChangeDeadlineDate,
    };

    return (
      <React.Fragment>
        <section className="container">
              { this.state.projectDetailsIs.project?.admin_name&&(
          <div className="row mb-4">
            <div className="col-sm-12">
              <label className="text-dark text-capitalize h3">created by</label>
              
              <div className="row">
                 <div className="col-sm-4 d-flex align-items-center">
                   <label className="mb-0 text-secondary">Username :</label>
                   <h3 className="mb-0 ml-2">{this.state.projectDetailsIs.project.admin_name}</h3>
                 </div>
                 <div className="col-sm-5 d-flex align-items-center">
                   <label className="mb-0 text-secondary">Email :</label>
                   <h3 className="mb-0 ml-2">{this.state.projectDetailsIs.project.admin_email}</h3>
                 </div>
               </div>
            </div>  
          </div>)}
          <hr className="my-5" />
          <form>
            <div className="form-group">
              <div className="row">
              
                <div className="col">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="text-dark h3"
                  >
                    Choose Collateral
                  </label>
                  
                  <Select
                    value={selectedOption}
                    onChange={this.handleCollChange}
                    options={optionData}
                    onInputChange={this.handleInputChange}
                    cacheOptions
                    defaultOptions
                    isDisabled={
                      StatusIs === 6 ||
                      StatusIs === 7 ||
                      StatusIs === 8 ||
                      StatusIs === 10
                        ? true
                        : false
                    }
                  />
                </div>               
              </div>
              <hr className="my-5" />
            </div>
            {/* ------------------------Project Type------------------------------------- */}
            {selectedOption && <><ProjectType     
                {...this.state}
                {...this.props}
                handleFunc={projectFunc}
            />
            <hr className="my-5" />
            </>
            }


            {/*----------------------Project Other Details --------------------------*/}

            {selectedOption &&(
              <ProjectOtherDetails
                {...this.state}
                {...this.props}
                handleFunc={projectFunc}
              />
            )}

            {/*----------------------Project Other Details --------------------------*/}

            {/*---------------- project Company details -------------------------- */}
            {selectedOption &&<hr className="my-5" />}
            {selectedOption &&(
              <CompanyDetails
                {...this.props}
                {...this.state}
                handleFunc={projectFunc}
              />
            )}

            {/*----------------project Company details -------------------------- */}
            {/*------------------------Review -----------------------------*/}

            {ReviewIs &&<Review {...this.state} {...this.props} />}

            {/*-----------------------Review ------------------------------*/}

            {/*--------------------review Questions -------------------------*/}

            {StatusIs === 7 && <AskQuestion {...this.state} {...this.props}/>}
            {StatusIs === 7 &&<hr className="my-5"/>}

            {/*--------------------review Questions -------------------------*/}

            {/*--------------------last msg by--------------------------------*/}

            {LastMsgIs &&<LastMsgBy {...this.state} {...this.props} />}

            {/*--------------------last msg by--------------------------------*/}

            {/*-----------------------Project status -----------------------*/}

            {StatusIs === 7 &&(
              <ProjectStatus projectCompDate={projectCompDate} />
            )}

            {/*-----------------------Project status ------------------------*/}
          </form>
          {selectedOption &&(
            <div className="row">
              <div className="col text-right mb-4">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={
                    EditIs ? (e) => this.handleUpdate(e) : this.handleSubmit
                  }
                  disabled={
                    StatusIs === 7 || StatusIs === 8 || StatusIs === 10
                      ? "disabled"
                      : ""
                  }
                >
                  {EditIs ? (
                    LoaderIs ? (
                      <LoaderSmall />
                    ) : (
                      "Update"
                    )
                  ) : LoadingIs ? (
                    <LoaderSmall />
                  ) : (
                    "submit"
                  )}
                </button>
              </div>
            </div>
          )}
        </section>
      </React.Fragment>
    );
  }
}
const mapState = ({
  AllCollateralList,
  getUserList,
  addPostProject,
  getPostProjectData,
  updateProject,
}) => {
  return {
    _AllCollateralList: AllCollateralList,
    _getUserList: getUserList,
    _addPostProject: addPostProject,
    _getPostProjectData: getPostProjectData,
    _updateProject: updateProject,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $AllCollateralList: (values) => dispatchEvent(AllCollateralList(values)),
  $getUserList: (values) => dispatchEvent(getUserList(values)),
  $addPostProject: (values) => dispatchEvent(addPostProject(values)),
  $getPostProjectData: (values) => dispatchEvent(getPostProjectData(values)),
  $updateProject: (values) => dispatchEvent(updateProject(values)),
});
export default connect(mapState, mapDispatch)(AddProject);
