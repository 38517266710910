import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getPostProjectData,
  addProposal,
  updateProposal,
} from "../../../../store/action/projectList.action";
import LoaderSmall from "../../Common/Loader/LoaderSmall";
import {
  completedMiles,
  milestoneApproved,
} from "../../../../store/action/transaction.action";
import SubmitMilestone from "../../Common/ModalCommon/SubmitMilestone";
import Milestone from './Milestone';
import * as moment from "moment";
import { userProfileBaseUrl } from "../../../../Utils/BaseUrl";
import Manager from "../Manager/Manager";
import { transactionGetData } from "../../../../store/action/transaction.action";
import { getPublicAPI } from "../../../../store/api-interface";
import ProposalModal from "../ProposalModal";
import { getPostProjectDataSuccess } from "../../../../store/action/projectList.action";
import Loader from "../../Common/Loader/Loader";

class ProjectMilestone extends Component {
  state = {
    Gst: "",
    clientBudget: "",
    note: "",
    creatorCost: "",
    selectedDate: new Date(),
    milestoneInputs: [{ name: "", due_date: "", iteration: 1, status: 1, restrict: false, type: 'milestone',toggle:false,iteration:1,iteration_charge:0, dateError: false, iterationError: false, nameError: false }],
    projectDetails: [],
    LoadingIs: false,
    LoaderIs: false,
    errorIs: { Gst: "", clientBudget: "", creatorCost: "" },
    errorRetainer: { retainer_fees_creator: false, retainer_fees: false, workingDays: false, proposed_duration: false, number_of_hours: false },
    mileNameIs: "",
    mileDueDateIs: "",
    EditIs: false,
    DeletedMileStone: [],
    LoaderIs: false,
    showCalendar: false,
    disableDate: false,
    statusIs: 0,

    proposed_start_date: setTimeToZero(new Date()),

    // submitMilesOpen: false,
    deliverableMiles: "",
    submitMilesData: "",
    milesApprovedIs: false,
    projectDataIs: "",
    clientData: "",
    managerList: [],
    manager_id_validation: "",
    managerError: false,
    proposal_type: 'fixed',
    workingDays: [],
    proposed_duration: 1,
    number_of_hours: 1,
    number_of_hours_type: 'Day',
    retainer_fees: 0,
    retainer_fees_creator: 0,
    retainer_fees_type: "Hourly",
    preferred_billing_cycle: "Monthly",
    completed_milestone_no: 0,

    payment_terms: [{ milestone_reference: [], price: 0, price_without_tax: "", restrict: false, status: 0, type: "milestone", id: "", tax: 0, total_cost: 0, sub_total_cost: 0, total_earning: "", creatorCostError: false, clientBudgetError: false, nameError: false }],
    PaymentTermsError: false,
    paymentTermsOptions: [],

    deliverablesActual: [{ iteration: 1, iteration_charge: 0, name: "", tax: 0, price: "", price_creator: "", nameError: false, priceError: false, priceCreatorError: false }],
    showProposalModal: false,
    MilesData: "",

    Loader:true,

  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    // this.validationForm(name, value);
  };

  handleChangeProposalType = (e) => {
    const { value } = e.target;
    this.setState({
      proposal_type: value,
      Gst: "",
      clientBudget: "",
      creatorCost: "",
    })
  }

  handleChangeRetainerFees = (e) => {
    this.setState({ retainer_fees: e.target.value })

    if (this.state.errorRetainer.retainer_fees) {
      let errorRetainerTemp = this.state.errorRetainer
      errorRetainerTemp['retainer_fees'] = false
      this.setState({ errorRetainer: errorRetainerTemp })
    }

  }
  handleChangeRetainerFeesCreator = (e) => {
    this.setState({ retainer_fees_creator: e.target.value })

    if (this.state.errorRetainer.retainer_fees_creator) {
      let errorRetainerTemp = this.state.errorRetainer
      errorRetainerTemp['retainer_fees_creator'] = false
      this.setState({ errorRetainer: errorRetainerTemp })
    }

  }
  

  handleChangeRetainerFeesType = (value) => {
    this.setState({ retainer_fees_type: value.value })
  }

  handleChangeProposedDuration = (e, type) => {
    e.preventDefault();
    let temp = Number(this.state.proposed_duration);
    if (type === 'inc') {
      temp = temp + 1;
    }
    else if (type == 'dec') {
      if (this.state.completed_milestone_no) {
        if (temp == this.state.completed_milestone_no) {
          return
        }
      }
      else if (temp == 1) {
        return;
      }
      temp = temp - 1
    }
    this.setState({ proposed_duration: temp })
    if (this.state.errorRetainer.proposed_duration) {
      let errorRetainerTemp = this.state.errorRetainer
      errorRetainerTemp['proposed_duration'] = false
      this.setState({ errorRetainer: errorRetainerTemp })
    }
  }

  handleChangeNumberHoursDropdown = (value) => {
    this.setState({ number_of_hours_type: value.value })
  }

  handleSetManagerId = (manager_id) => {
    this.setState({ manager_id_validation: manager_id, managerError: false })
  }
  handleChangePreferredBillingCycle = (value, type) => {

    // if (type == 'retainer'){
    this.setState({ preferred_billing_cycle: value.value })
    // }
    // else{
    //   this.setState({ preferred_billing_cycle: value.value })
    // }
  }

  handleChangeProposedDurationInput = (e) => {
    const { value } = e.target
    this.setState({ proposed_duration: value })

    if (this.state.errorRetainer.proposed_duration) {
      let errorRetainerTemp = this.state.errorRetainer
      errorRetainerTemp['proposed_duration'] = false
      this.setState({ errorRetainer: errorRetainerTemp })
    }
  }

  handleChangeNumberHoursInput = (e) => {
    const { value } = e.target
    this.setState({ number_of_hours: value })

    if (this.state.errorRetainer.number_of_hours) {
      let errorRetainerTemp = this.state.errorRetainer
      errorRetainerTemp['number_of_hours'] = false
      this.setState({ errorRetainer: errorRetainerTemp })
    }

  }



  handleChangeNumberHours = (e, type) => {
    e.preventDefault();
    let temp = Number(this.state.number_of_hours);
    if (type == 'inc') {
      temp = temp + 1
    }
    else {
      if (temp == 1) {
        return;
      }
      temp = temp - 1
    }
    this.setState({ number_of_hours: temp })

    if (this.state.errorRetainer.number_of_hours) {
      let errorRetainerTemp = this.state.errorRetainer
      errorRetainerTemp['number_of_hours'] = false
      this.setState({ errorRetainer: errorRetainerTemp })
    }
  }
  setWorkingDays = (workDays) => {
    this.setState({ workingDays: workDays })

    if (this.state.errorRetainer.workingDays) {
      let errorRetainerTemp = this.state.errorRetainer
      errorRetainerTemp['workingDays'] = false
      this.setState({ errorRetainer: errorRetainerTemp })
    }
  }

  handleChangeActualDeliverables = (e, i) => {
    const { name, value, checked } = e.target;
    let deliverablesActual = this.state.deliverablesActual


    if (name == 'tax') {
      if (checked) {
        deliverablesActual[i][name] = 18
      }
      else {
        deliverablesActual[i][name] = 0

      }
    }
    else {

      if (name == 'price' || name == 'price_creator')
        deliverablesActual[i][name] = Number(value)
      else
        deliverablesActual[i][name] = value
    }

    const currentMile = deliverablesActual[i];
    if (name == 'name') {
      currentMile.nameError && delete currentMile['nameError'];
    }
    else if (name == 'price') {
      currentMile.priceError && delete currentMile['priceError'];
    }
    if (name == 'price_creator') {
      currentMile.priceCreatorError && delete currentMile['priceCreatorError'];
    }
    this.setState({ deliverablesActual })
  }

  handleAppendActualDeliverables = () => {
    let deliverablesActual = this.state.deliverablesActual
    deliverablesActual.push({ iteration: 1, iteration_charge: 0, name: "", tax: "", price: "", price_creator: "", nameError: false, priceError: false, priceCreatorError: false })
    this.setState({ deliverablesActual })
  }

  handleMilestoneChange = (e, index) => {
    const { milestoneInputs, paymentTermsOptions } = this.state;
    let tempPaymentTerms = [...paymentTermsOptions];
    if (e.target) {
      if(e.target.name){

        const { name, value } = e.target;
        const list = [...milestoneInputs];
        list[index][name] = value;
        this.setState({
          [name]: value,
      });
      const currentMile = milestoneInputs[index];
      if (e.target.name === "name") {
        
        
        if (milestoneInputs.length > 0 && milestoneInputs.length > tempPaymentTerms.length) {
          tempPaymentTerms.push({ value: String.fromCharCode(65 + index), label: String.fromCharCode(65 + index), disabled: false })
        }
        
        
        currentMile.nameError && delete currentMile.nameError;
      } else {
        currentMile.iterationError && delete currentMile.iterationError;
      }
    }else{
      const {checked} = e.target
      milestoneInputs[index].toggle = checked
    }
    this.setState({ milestoneInputs, paymentTermsOptions: tempPaymentTerms });
  } 
    else {
      const list = [...milestoneInputs];
      const currentDate = e;
      list[index].due_date = currentDate.toDateString();
      list[index].selectedDate = currentDate.toDateString();
      if (milestoneInputs.length > 1) {
        const nextDateIs =
          milestoneInputs[index + 1] &&
          new Date(milestoneInputs[index + 1].due_date);
        if (nextDateIs && this.getDateDiff(currentDate?.toDateString(), nextDateIs?.toDateString()) > 0) {
          milestoneInputs[index + 1].dateError = true;
          const currentMileIs = milestoneInputs[index];
          currentMileIs.dateError && delete currentMileIs.dateError;
        } else {
          const currentMileIs = milestoneInputs[index];
          currentMileIs.dateError && delete currentMileIs.dateError;
          this.setState({ milestoneInputs });
        }
      } else {
        const currentMileIs = milestoneInputs[index];
        currentMileIs.dateError && delete currentMileIs.dateError;
      }

      this.setState({
        milestoneInputs,
      });

    }
  };

  handleIteration = (i,type) =>{
    let tempMilestoneInputs = [...this.state.milestoneInputs]
    if(type=='plus'){
      tempMilestoneInputs[i].iteration = tempMilestoneInputs[i].iteration +1
    }else{
      if(tempMilestoneInputs[i].iteration == 1){
        return 
      }
      tempMilestoneInputs[i].iteration = tempMilestoneInputs[i].iteration - 1
    }
    this.setState({milestoneInputs:tempMilestoneInputs})
  }

  handleAdvancePayment = (e) =>{
    if(this.state.proposal_type == 'fixed'){
      let temp = [...this.state.payment_terms]
      if(e.target.checked){

        temp=[{ price_without_tax:0,total_earning:0,price: "", milestone_reference: [{ index: 0, label: "Advance Fees" }], status: 0, type: "token"},...this.state.payment_terms]
      }else{
        temp.shift()
      }
      this.setState({payment_terms:temp})
    }
  }

  handleRemovePaymentTerms = (index, obj) => {

    const { payment_terms, paymentTermsOptions } = this.state;
    let tempPaymentTerms = [...payment_terms]
    if (index !== -1) {
      if (tempPaymentTerms[index].milestone_reference.length > 0) {
        let deleted = tempPaymentTerms[index].milestone_reference;
        for (let x of paymentTermsOptions) {
          for (let y of deleted) {

            if (x.label == y.label) {
              x.disabled = false
            }
          }

        }
      }

      const remainpaymentterms = payment_terms.filter((_, i) => i !== index);
      this.setState({
        payment_terms: remainpaymentterms,

      });
    }

  }


  getDateDiff = (a, b) => {
    if (a) {
      const x = moment(a);
      const y = moment(b);
      const r = x.diff(y, "days");
      return r;
    }
  };

  handleAppendMilestone = () => {
    const { milestoneInputs, paymentTermsOptions } = this.state;
    var list = [...milestoneInputs, { id: "", name: "", due_date: "", iteration: 1, status: 1, restrict: false, type: 'milestone',toggle:false,iteration_charge:0, nameError: false, dateError: false, iterationError: false }];

    // let tempPaymentTerms = list.map((el, i) => ({ label: String.fromCharCode(65 + i), value: String.fromCharCode(65 + i), disabled: false }))
    // let paymentTermsOptionsTemp = [...paymentTermsOptions]

    // for (let x of paymentTermsOptionsTemp) {
    //   for (let y of tempPaymentTerms) {
    //     if (x.label == y.label) {
    //       y.disabled = x.disabled
    //     }
    //   }
    // }

    this.setState({
      milestoneInputs: list,
      // paymentTermsOptions:tempPaymentTerms,
      selectedDate: milestoneInputs.slice(-1).pop().due_date
        ? milestoneInputs.slice(-1).pop().due_date
        : new Date(),
    });

  };

  handleAppendPaymentTerms = () => {
    const { payment_terms } = this.state;
    var list = [...payment_terms, { milestone_reference: [], price: 0, price_without_tax: "", restrict: false, status: 0, type: "milestone", id: "", total_earning: "", tax: 0, creatorCostError: false, clientBudgetError: false, nameError: false }]
    this.setState({ payment_terms: list })
  }

  handleChangePaymentTerms = (value, i) => {

    if (this.state.PaymentTermsError) {
      this.setState({ PaymentTermsError: false })
    }

    const { paymentTermsOptions, payment_terms } = this.state;
    let tempPaymentTerms = [...payment_terms]
    if (tempPaymentTerms[i].nameError) {
      tempPaymentTerms[i].nameError = false
    }
    let tempPaymentTermsOptions = [...paymentTermsOptions]
    let tempMilestoneReferece = []

    if (payment_terms[i].milestone_reference.length < value.length) {
      for (let x of paymentTermsOptions) {
        for (let y of value) {
          if (x.label == y.label) {
            x.disabled = true
          }
        }

      }
      // tempPaymentTermsOptions[i].disabled= true
    }

    else if (payment_terms[i].milestone_reference.length > value.length) {
      let deleted = []



      if (value.length) {
        let paymentTermsArr = payment_terms[i].milestone_reference.map(el => el.label)
        let valueArr = value.map(el => el.label)
        // console.log(value,payment_terms[i].milestone_reference.map(el=>el.label!==))
        // deleted = payment_terms[i].milestone_reference

        for (let x of paymentTermsArr) {
          if (!valueArr.includes(x)) {
            // console.log(x,x.charCodeAt(0)-64)
            deleted.push({ index: Number(x.charCodeAt(0) - 64), label: x })
          }
        }

      }
      else {
        // console.log('not having length')
        deleted = payment_terms[i].milestone_reference
        // deleted.push(temp)
      }

      // console.log(deleted, 'the deleted')

      for (let x of paymentTermsOptions) {
        for (let y of deleted) {

          if (x.label == y.label) {
            x.disabled = false
          }
        }

      }
    }

    if (value.length > 1) {
      for (let index = 0; index < value.length; index++) {
        tempMilestoneReferece.push({ index: Number(value[index].value.charCodeAt(0)) - 64, label: value[index].value })
      }
    } else if (value.length == 1) {
      tempMilestoneReferece.push({ index: Number(value[0].value.charCodeAt(0)) - 64, label: value[0].value })
    }


    tempPaymentTerms[i].milestone_reference = tempMilestoneReferece
    // tempPaymentTerms[i]._id = ""




    this.setState({ payment_terms: tempPaymentTerms, paymentTermsOptions: tempPaymentTermsOptions })


  }

  handleChangeBreakup = (e, i) => {
    const { name, value } = e.target;
    const { payment_terms } = this.state;
    if (name == 'client') {
      payment_terms[i].price_without_tax = Number(value)
      if (payment_terms[i].clientBudgetError) {
        payment_terms[i].clientBudgetError = false
      }
    }
    else {
      payment_terms[i].total_earning = Number(value)
      if (payment_terms[i].creatorCostError) {
        payment_terms[i].creatorCostError = false
      }
    }
    this.setState({ payment_terms })

  }

  handleChangeTax = (e, i) => {
    let temp = this.state.payment_terms
    if (e.target.checked) {
      temp[i].tax = 18
      this.setState({ payment_terms: temp })
    }
    else {
      temp[i].tax = 0
      this.setState({ payment_terms: temp })
    }
  }

  handleRemoveMilestone = (index, obj) => {
    const { milestoneInputs, DeletedMileStone, paymentTermsOptions, payment_terms } = this.state;

    if (index !== -1) {
      const remainMilestone = milestoneInputs.filter((_, i) => i !== index);
      // const tempPaymentTerms = paymentTermsOptions.filter((_,i)=>i!==index);
      let tempPaymentTerms = remainMilestone.map((el, i) => ({ label: String.fromCharCode(65 + i), value: String.fromCharCode(65 + i), disabled: false }))
      let paymentTermsOptionsTemp = [...paymentTermsOptions]

      let deleted1 = []
      deleted1.push({index:index+1, label: String.fromCharCode(65 + index)})
      
      let deleted = []
        for(let x of payment_terms){
            for (let y of x.milestone_reference){
               for (let z of deleted1){
                console.log(z.label,y.label)
                if(z.label <= y.label){
                  if(!deleted.includes(...x.milestone_reference))
                  deleted = [...deleted,...x.milestone_reference]
                }
               }
            }
        }

        console.log(deleted,'deleted payment')
        
        for(let x of paymentTermsOptions){
          for (let y of tempPaymentTerms){
            if(x.label == y.label){
              y.disabled = x.disabled
            }
          }
        }

        
        for (let x of tempPaymentTerms) {
          for(let y of deleted){
            if(x.label == y.label){
              x.disabled = false
            }
          }

        }

      let arr = []
      for (let x of payment_terms) {
        let check = false
        for (let y of x.milestone_reference) {
          if (y.label >= String.fromCharCode(index + 65)) {
            check = true
          }
        }
        if (check) {
          arr.push({ ...x, milestone_reference: [] })
        } else {
          arr.push(x)
        }
      }

      const deletedMileStone = DeletedMileStone.concat(obj);


      this.setState({
        milestoneInputs: remainMilestone,
        payment_terms:arr,
        paymentTermsOptions: tempPaymentTerms,
        DeletedMileStone: deletedMileStone,
      });
    }
  };

  handleRemoveActualDeliverables = (index, obj) => {
    const { deliverablesActual } = this.state;
    let temp1 = [];
    deliverablesActual.map((el, i) => { if (i !== index) { temp1.push(el) } })
    this.setState({ deliverablesActual: temp1 })
  };

  handleViewcompletedMiles = (obj, index) => {
    this.setState({
      submitMilesOpen: true,
      deliverableMiles: index + 1,
      submitMilesData: obj,
    });
    this.props.$completedMiles(obj._id);
  };

  handleCloseMilesModal = () => {
    this.setState({ submitMilesOpen: false });
  };

  handleGetConfirmation = (currentData) => {
    let confirmIs = window.confirm(`Do you want to approve this Milestone ?`);
    if (confirmIs === true) {
      this.setState({ milesApprovedIs: true });
      this.props.$milestoneApproved(currentData);
      return true;
    } else {
      return false;
    }
  };

  handleApprovedMiles = (obj) => {
    const currentData = {
      proposal_id: obj.proposal_id,
      milestone_id: obj._id,
      type: "milestone",
    };
    this.handleGetConfirmation(currentData);
  };

  validationForm = (name, value) => {
    const { errorIs } = this.state;
    switch (name) {
      // case "Gst":
      //   errorIs.Gst = value === "" ? "Required Field" : "";
      //   break;
      case "clientBudget":
        errorIs.clientBudget = value === "" ? "Required Field" : "";
        break;
      case "creatorCost":
        errorIs.creatorCost = value === "" ? "Required field" : "";
        break;
      default:
        break;
    }
  };

  validation = () => {
    const { errorIs, Gst, clientBudget, creatorCost, payment_terms, milestoneInputs, paymentTermsOptions } = this.state;
    // errorIs.Gst = Gst === "" ? "Required Field" : "";
    // errorIs.clientBudget = clientBudget === "" ? "Required Field" : "";
    // errorIs.creatorCost = creatorCost === "" ? "Required field" : "";

    // let selectedPaymentTermsNumber = paymentTermsOptions.filter(el => el.disabled ? el : false).length
    let paymentTermsIpt = payment_terms.filter(el=>el.type=='milestone')
    let selectedPaymentTermsNumber = []
    for (let x of paymentTermsIpt) {
      for (let y of x.milestone_reference) {
        selectedPaymentTermsNumber.push(y)

      }
    }



    let valid = true

    if (selectedPaymentTermsNumber.length < milestoneInputs.length) {
      valid = false
      this.setState({ PaymentTermsError: true })
    }
    else if (selectedPaymentTermsNumber.length > milestoneInputs.length) {
      valid = false
      this.setState({ PaymentTermsError: true })
    } else {
      valid = true
      this.setState({ PaymentTermsError: false })
    }




    // this.setState({ errorIs });
    this.validationManager();
    this.validationMileStone();
    this.validationPaymentTerms();

    return valid
    // return !errorIs.Gst && !errorIs.clientBudget && !errorIs.creatorCost;
  };

  validationRetainer = () => {
    const { retainer_fees, retainer_fees_creator, workingDays, errorRetainer, proposed_duration, number_of_hours } = this.state;
    let valid = true;
    let errorRetainerTemp = errorRetainer
    if (retainer_fees <= 0) {
      valid = false
      errorRetainerTemp['retainer_fees'] = true
    }
    if (retainer_fees_creator <= 0) {
      valid = false
      errorRetainerTemp['retainer_fees_creator'] = true
    }
    if (workingDays.length > 0) {
      let temp = workingDays.filter(el => el.isActive ? el : false)
      if (temp.length == 0) {
        valid = false
        errorRetainerTemp['workingDays'] = true
      }
    }
    if (!workingDays.length) {
      valid = false
      errorRetainerTemp['workingDays'] = true
    }
    if (proposed_duration <= 0) {
      valid = false
      errorRetainerTemp['proposed_duration'] = true
    }
    if (number_of_hours <= 0) {
      valid = false
      errorRetainer['number_of_hours'] = true
    }
    this.setState({ errrorRetainer: errorRetainerTemp })
    this.validationManager();
    return valid;
  }

  validationActual = () => {
    const { deliverablesActual } = this.state
    let formValidIs = true
    const updatedMileStone = deliverablesActual.map((obj, index) => {
      if (obj.name === "") {
        deliverablesActual[index].nameError = true;
        formValidIs = false;
      }
      if (obj.price_creator === "" || obj.price_creator <= 0) {
        deliverablesActual[index].priceCreatorError = true;
        formValidIs = false;
      } if (obj.price === "" || obj.price <= 0) {
        deliverablesActual[index].priceError = true;
        formValidIs = false;
      }
      return obj;
    });
    this.setState({ deliverablesActual: updatedMileStone })
    this.validationManager();
    return formValidIs;
  }

  validationManager = () => {
    const { manager_id_validation } = this.state

    let valid = true
    if (!manager_id_validation) {
      valid = false
      this.setState({ managerError: true })
    }
    else {
      this.setState({ managerError: false })
      valid = true
    }
    return valid;
  }

  validationMileStone = () => {
    const { milestoneInputs } = this.state;
    let formValidIs = true;
    const updatedMileStone = milestoneInputs.map((obj, index) => {
      if (obj.name === "") {
        milestoneInputs[index].nameError = true;
        formValidIs = false;
      }
      if (obj.due_date === "") {
        milestoneInputs[index].dateError = true;
        formValidIs = false;
      } else if (obj.due_date !== "" && milestoneInputs[index].dateError) {

        formValidIs = false;
      }
      if (obj.toggle && !obj.iteration_charge ) {

        milestoneInputs[index].iterationError = true;
        formValidIs = false;
      }
      return obj;
    });
    this.setState({ milestoneInputs: updatedMileStone });
    return formValidIs;
  };


  validationPaymentTerms = () => {
    const { payment_terms } = this.state;
    let formValidIs = true;
    const updatedPaymentTerms = payment_terms.map((obj, index) => {
      if (obj.milestone_reference.length == 0) {
        payment_terms[index].nameError = true;
        formValidIs = false;
      }

      if (obj.total_earning == "" || obj.total_earning == undefined) {
        payment_terms[index].creatorCostError = true;
        formValidIs = false;
      }
      if (obj.price_without_tax == "" || obj.price_without_tax == undefined) {
        payment_terms[index].clientBudgetError = true;
        formValidIs = false;
      }

      return obj;
    });


    this.setState({ payment_terms: updatedPaymentTerms });


    return formValidIs;

  }

  handleUpdateProposal = () => {
    const {
      Gst,
      clientBudget,
      note,
      creatorCost,
      milestoneInputs,
      projectDetails,
      proposal_type,
      number_of_hours,
      number_of_hours_type,
      preferred_billing_cycle,
      proposed_duration,
      proposed_start_date,
      retainer_fees,
      retainer_fees_type,
      workingDays,
      deliverablesActual,
      retainer_fees_creator,
      payment_terms
    } = this.state;

    payment_terms.forEach(el => {

      el.tax = el.tax ? (0.18 * Number(el.price_without_tax)) : 0
      el.total_cost = Number(el.price_without_tax) + Number(el.tax)
      el.price = Number(el.price_without_tax) + Number(el.tax)
    })

    if (deliverablesActual.length) {
      deliverablesActual.forEach(el => {
        el.tax = el.tax ? (0.18 * Number(el.price)) : 0
      })
    }

    const initialValue = 0;
    const intialValueTotalEarning = 0;
    const intialTax = 0;


    let TotalTax = 0;
    let subTotalCost = 0;
    let TotalEarning = 0;

    if (proposal_type && proposal_type == 'fixed') {
      TotalTax = payment_terms.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.tax ? (currentValue.tax) : 0),
        intialTax
      );

      subTotalCost = payment_terms.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.price_without_tax),
        initialValue
      );
      TotalEarning = payment_terms.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.total_earning),
        intialValueTotalEarning
      );

    } else if (proposal_type == 'actual') {
      TotalTax = deliverablesActual.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.tax ? currentValue.tax : 0),
        intialValueTotalEarning
      );

      subTotalCost = deliverablesActual.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.price),
        initialValue
      );
      TotalEarning = deliverablesActual.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.price_creator),
        intialValueTotalEarning
      );
    }

    let paymentTerms = [...payment_terms]
    if(!payment_terms.filter(el=>el.type=='token'?el:false).length){
      paymentTerms = [
        { price: "", milestone_reference: [{ index: 0, label: "Advance Fees" }], status: 0, type: "token" }, ...payment_terms]
    }

    // for(let i =0;i<paymentTerms.length;i++){
    //   paymentTerms[i]['sub_total_cost'] = subTotalCost;
    //   paymentTerms[i]['total_earning'] = TotalEarning;
    //   paymentTerms[i]['total_cost'] = subTotalCost + paymentTerms[i].tax
    // }



    if (proposal_type == 'fixed' && (!this.validation() || !this.validationManager() || !this.validationMileStone() || !this.validationPaymentTerms())) {
      return;
    }
    else if (proposal_type == 'retainer' && (!this.validationRetainer() || !this.validationManager())) {
      return;
    }
    else if (proposal_type == 'actual' && (!this.validationActual() || !this.validationManager())) {
      return;
    }
    let milestoneInputsTemp = [{ due_date: "", iteration: 0, iteration_charge: 0, name: "advance_payment", type: "token", status: 0 }, ...milestoneInputs]

    milestoneInputsTemp.forEach(el=>{
      if(!el.toggle){
        el.iteration = 0
        el.iteration_charge = 0
      }
    })

    //   const mileIs = milestoneInputs.map((obj, index) => {
    //     let dataMile = {
    //       name: obj.name,
    //       due_date: obj.due_date,
    //       number: index + 1,
    //       iteration: obj.iteration,
    //     };
    //     return dataMile;
    //   });
    // let sumWithInitial=0
    // if(proposal_type=='fixed'){

    //   sumWithInitial = paymentTerms.reduce(
    //     (accumulator, currentValue) => accumulator + parseInt(currentValue.price),
    //     0
    //     );
    //   }
    //   else if(proposal_type=='retainer'){
    //     sumWithInitial=retainer_fees
    //   }
    //   else{
    //     sumWithInitial = deliverablesActual.reduce(
    //       (acc,curr)=>acc+parseInt(curr.pricing),0
    //     );
    //   }

    if (projectDetails) {

      const data = {
        advance_amount: 0,
        proposal_id: projectDetails.Proposal
          ? projectDetails.Proposal._id
          : "",
        deliverables: proposal_type == 'actual' ? deliverablesActual : proposal_type == 'fixed' ? milestoneInputsTemp : [],
        link_image: [],
        number_of_hours: number_of_hours,
        number_of_hours_type: number_of_hours_type,
        payment_terms: proposal_type == 'fixed' ? paymentTerms : [],
        preferred_billing_cycle: preferred_billing_cycle,
        project_ids: [],
        proposal_type: proposal_type,
        proposed_duration: proposed_duration,
        proposed_duration_type: "Months",
        proposed_start_date: this.addHrMinDate(proposed_start_date, 5, 30),
        retainer_fees: retainer_fees,
        retainer_fees_creator: retainer_fees_creator,
        retainer_fees_type: retainer_fees_type,
        urls: [],
        working_days_in_week: workingDays,
        creator_id: projectDetails.users
          ? projectDetails.users.creator_id
          : "",
        post_project_id: this.props.match.params.id.split(":")[1]
          ? this.props.match.params.id.split(":")[1]
          : "",
        tax: proposal_type == 'retainer' ? Gst ? (0.18 * Number(retainer_fees)) : 0 : TotalTax,
        sub_total_cost: proposal_type == 'retainer' ? Number(retainer_fees) : Number(subTotalCost),
        total_cost: proposal_type == 'fixed' || proposal_type == 'actual' ? TotalTax + Number(subTotalCost) : (Gst ? (0.18 * Number(retainer_fees)) : 0) + Number(retainer_fees),
        total_earning: proposal_type == 'retainer' ? Number(retainer_fees_creator) : Number(TotalEarning),
        note: note,
        // milestone: mileIs,
      };
      this.setState({ LoaderIs: true });
      // console.log(data,'tje data')
      this.props.$updateProposal(data);
    }

  };
  // handleUpdateProposal = () => {
  //   const {
  //     Gst,
  //     clientBudget,
  //     note,
  //     creatorCost,
  //     milestoneInputs,
  //     projectDetails,
  //     DeletedMileStone,
  //   } = this.state;
  //   if (this.validation() && this.validationMileStone()) {
  //     if (projectDetails) {
  //       const ExistMile = milestoneInputs.filter((item) => item._id);
  //       const currentExistMile = ExistMile.map((obj, index) => {
  //         let dataMile = {
  //           _id: obj._id,
  //           name: obj.name,
  //           due_date: obj.due_date,
  //           iteration: obj.iteration,
  //           number: index + 1,
  //         };
  //         return dataMile;0
  //       });
  //       const newMile = milestoneInputs.filter(
  //         ({ _id: id1 }) =>
  //           !projectDetails.Milestone.some(({ _id: id2 }) => id2 === id1)
  //       );

  //       const currentNewMile = newMile.map((obj, index) => {
  //         let dataMile = {
  //           name: obj.name,
  //           due_date: obj.due_date,
  //           iteration: obj.iteration,
  //           number: index + 1 + currentExistMile.slice(-1).pop().number,
  //         };
  //         return dataMile;
  //       });
  //       const currentData = {
  //         creator_id: projectDetails.users
  //           ? projectDetails.users.creator_id
  //           : "",
  //         proposal_id: projectDetails.Proposal
  //           ? projectDetails.Proposal._id
  //           : "",
  //         tax: Gst,
  //         sub_total_cost: clientBudget,
  //         total_cost: clientBudget ? Number(Gst) + Number(clientBudget) : 0,
  //         total_earning: creatorCost,
  //         note: note,
  //         milestone_existing: currentExistMile,
  //         milestone_new: currentNewMile,
  //         milestone_delete: DeletedMileStone,
  //       };

  //       this.setState({ LoaderIs: true });
  //       this.props.$updateProposal(currentData);
  //     }
  //   }
  // };

  addHrMinDate = (value, hr, min) => {
    let date = new Date(value);
    date.setHours(date.getHours() + hr);
    date.setMinutes(date.getMinutes() + min);
    return date;
  };

  handleProposalSubmit = () => {
    const {
      Gst,
      clientBudget,
      note,
      creatorCost,
      milestoneInputs,
      projectDetails,
      proposal_type,
      number_of_hours,
      number_of_hours_type,
      preferred_billing_cycle,
      proposed_duration,
      proposed_start_date,
      retainer_fees,
      retainer_fees_type,
      workingDays,
      deliverablesActual,
      retainer_fees_creator,
      payment_terms
    } = this.state;



    // console.log(subTotalCost,TotalEarning,TotalTax)
    // if(payment_terms.length){

    //   for (let i = 0; i < payment_terms.length; i++) {
    //     // paymentTerms[i]['sub_total_cost'] = subTotalCost;
    //     // paymentTerms[i]['total_earning'] = TotalEarning;
    //     payment_terms[i]['tax'] = Number(payment_terms[i].tax ?(payment_terms[i].price_without_tax * (payment_terms[i].tax/100)) : 0)
    //     payment_terms[i]['total_cost'] = Number(payment_terms[i].price_without_tax) + Number(payment_terms[i].tax)
    //     payment_terms[i]['price'] = Number(payment_terms[i].price_without_tax) + Number(payment_terms[i].tax)

    //   }
    // }

    payment_terms.forEach(el => {

      el.tax = el.tax ? (0.18 * Number(el.price_without_tax)) : 0
      el.total_cost = Number(el.price_without_tax) + Number(el.tax)
      el.price = Number(el.price_without_tax) + Number(el.tax)
    })

    if (deliverablesActual.length) {
      deliverablesActual.forEach(el => {
        el.tax = el.tax ? (0.18 * Number(el.price)) : 0
      })
    }

    const initialValue = 0;
    const intialValueTotalEarning = 0;
    const intialTax = 0;


    let TotalTax = 0;
    let subTotalCost = 0;
    let TotalEarning = 0;

    if (proposal_type && proposal_type == 'fixed') {
      TotalTax = payment_terms.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.tax ? (currentValue.tax) : 0),
        intialTax
      );

      subTotalCost = payment_terms.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.price_without_tax),
        initialValue
      );
      TotalEarning = payment_terms.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.total_earning),
        intialValueTotalEarning
      );

    } else if (proposal_type == 'actual') {
      TotalTax = deliverablesActual.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.tax ? currentValue.tax : 0),
        intialValueTotalEarning
      );

      subTotalCost = deliverablesActual.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.price),
        initialValue
      );
      TotalEarning = deliverablesActual.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.price_creator),
        intialValueTotalEarning
      );
    }

    let paymentTerms = [...payment_terms]
    if(!payment_terms.filter(el=>el.type=='token'?el:false).length){
      paymentTerms = [
        { price: "", milestone_reference: [{ index: 0, label: "Advance Fees" }], status: 0, type: "token" }, ...payment_terms]
    }



    if (proposal_type == 'fixed' && (!this.validation() || !this.validationMileStone() || !this.validationPaymentTerms() || !this.validationManager())) {
      return;
    }
    else if (proposal_type == 'retainer' && (!this.validationRetainer() || !this.validationManager())) {
      return;
    }
    else if (proposal_type == 'actual' && (!this.validationActual() || !this.validationManager())) {
      return;
    }

    let milestoneInputsTemp = [{ due_date: "", iteration: 0, iteration_charge: 0, name: "advance_payment", type: "token", status: 0 }, ...milestoneInputs]

    milestoneInputsTemp.forEach(el=>{
      if(!el.toggle){
        el.iteration = 0
        el.iteration_charge = 0
      }
    })

    //   const mileIs = milestoneInputs.map((obj, index) => {
    //     let dataMile = {
    //       name: obj.name,
    //       due_date: obj.due_date,
    //       number: index + 1,
    //       iteration: obj.iteration,
    //     };
    //     return dataMile;
    //   });
    // let sumWithInitial=0
    // if(proposal_type=='fixed'){

    //   sumWithInitial = paymentTerms.reduce(
    //     (accumulator, currentValue) => accumulator + parseInt(currentValue.price),
    //     0
    //     );
    //   }
    //   else if(proposal_type=='retainer'){
    //     sumWithInitial=retainer_fees
    //   }
    //   else{
    //     sumWithInitial = deliverablesActual.reduce(
    //       (acc,curr)=>acc+parseInt(curr.pricing),0
    //     );
    //   }

    if (projectDetails) {

      const data = {
        advance_amount: 0,
        deliverables: proposal_type == 'actual' ? deliverablesActual : proposal_type == 'fixed' ? milestoneInputsTemp : [],
        link_image: [],
        number_of_hours: number_of_hours,
        number_of_hours_type: number_of_hours_type,
        payment_terms: proposal_type == 'fixed' ? paymentTerms : [],
        preferred_billing_cycle: preferred_billing_cycle,
        project_ids: [],
        proposal_type: proposal_type,
        proposed_duration: proposed_duration,
        proposed_duration_type: "Months",
        proposed_start_date:this.addHrMinDate(proposed_start_date, 5, 30),
        retainer_fees: Number(retainer_fees),
        retainer_fees_creator: Number(retainer_fees_creator),
        retainer_fees_type: retainer_fees_type,
        urls: [],
        working_days_in_week: workingDays,
        creator_id: projectDetails.users
          ? projectDetails.users.creator_id
          : "",
        post_project_id: this.props.match.params.id.split(":")[1]
          ? this.props.match.params.id.split(":")[1]
          : "",
        tax: proposal_type == 'retainer' ? Gst ? (0.18 * Number(retainer_fees)) : 0 : TotalTax,
        sub_total_cost: proposal_type == 'retainer' ? Number(retainer_fees) : Number(subTotalCost),
        total_cost: proposal_type == 'fixed' || proposal_type == 'actual' ? TotalTax + Number(subTotalCost) : (Gst ? (0.18 * Number(retainer_fees)) : 0) + Number(retainer_fees),
        total_earning: proposal_type == 'retainer' ? Number(retainer_fees_creator) : Number(TotalEarning),
        note: note,
        // milestone: mileIs,
      };
      this.setState({ LoadingIs: true });
      // console.log(data,'this is the data')
      this.props.$addProposal(data);
    }
    // }
  };

  handleGetProjectData = (id) => {
    this.props.$getPostProjectData(id);
  };

  handleApiCall = (id,role,proposal_type) =>{
    console.log(proposal_type)

    // console.log(id)
    // const projectId = this.props.match.params.id.split(":").pop();
    // this.handleGetProjectData(projectId)
    console.log(this.props._transactionGetData.transactionData)
    let data = this.props._transactionGetData.transactionData
    
    if(proposal_type=='fixed'){
    for (let x of data.newProposalList){
      for(let y of x.payment_terms){
        if(y._id == id){
          console.log(y)
            if(role==2){
              y.admin_approved = true
            }
            else{
              y.admin_approved_client = true
            }
        }
      }
    }
  }else if(proposal_type=='retainer'){
    for (let x of data.newProposalList){
      for(let y of x.milestoneRetainer){
        if(y._id == id){
          console.log(y)
            if(role==2){
              y.admin_approved = true
            }
            else{
              y.admin_approved_client = true
            }
        }
      }
    }
    }
    else{
      for (let x of data.newProposalList){
        for(let y of x.milestoneActual){
          if(y._id == id){
            console.log(y)
              if(role==2){
                y.admin_approved = true
              }
              else{
                y.admin_approved_client = true
              }
          }
        }
      }
    }

    console.log(data)
    this.props.$getPostProjectDataSuccess(data)

  }

  

  componentDidMount() {
    const transactionId = this.props.match.params.id.split(":").pop();

    this.handleTransactionData(transactionId);
    const projectId = this.props.match.params.id.split(":").pop();
    this.handleGetProjectData(projectId);
    getPublicAPI('/backendUser/get-manger?email=').then(res => {
      this.setState({ managerList: res.data.data })
    }).catch(err => console.log(err)

    )
  }

  componentWillReceiveProps(nextProps) {
    const projectDetails = nextProps._getPostProjectData.postProjectDataIs;
    const proposalData = nextProps._addProposal.addProjectIs;
    const updateData = nextProps._updateProposal.updateProposalIs;

    const milesApprovedData = nextProps._milestoneApproved.approvedMilesIs;
    const postProjectData = nextProps._transactionGetData.transactionData;

    const { LoadingIs, LoaderIs, milesApprovedIs } = this.state;
    if (postProjectData) {
      const data = postProjectData.projectClientData;
      this.setState({
        clientData: data && data,
        projectDataIs: postProjectData.project
      })

    }

    const mileData =
      projectDetails &&
      projectDetails?.newProposalList?.length > 0 && projectDetails.newProposalList[0]?.deliverables?.length > 0 &&
      projectDetails.newProposalList[0]?.deliverables?.map((obj) => {
        const splitDate = obj?.due_date
          ? obj?.due_date?.split("T").slice(0)[0]
          : "";
        let dataMile = {
          id: obj?._id,
          restrict: obj?.restrict,
          name: obj?.name,
          due_date: new Date(splitDate),
          selectedDate: new Date(splitDate),
          iteration: obj?.iteration?obj?.iteration:1,
          toggle:obj?.iteration_charge?true:false,
          iteration_charge:obj?.iteration_charge?obj.iteration_charge:0,
          status: obj?.status,
          submitted_iteration: obj?.submitted_iteration,
          proposal_id: obj?.proposal_id,
          type: obj?.type
        };
        this.setState({
          selectedDate: projectDetails?.Milestone?.due_date
            ? projectDetails?.Milestone?.slice(-1)
              .pop()
              ?.due_date?.split("T")
              ?.slice(0)[0]
            : new Date(),
        });
        return dataMile;
      });

    if (projectDetails) {
      const data = projectDetails.Proposal;
      const project = projectDetails.project;
      const latestProposal = projectDetails.newProposalList[0]
      let tempPaymentTerms = []
      if (latestProposal && latestProposal.proposal_type && latestProposal.proposal_type == 'fixed' && latestProposal.deliverables.length > 0) {
        tempPaymentTerms = latestProposal.deliverables.filter(el=>el.type=='milestone'?el:false).map((el, i) => ({ label: String.fromCharCode(65 + i), value: String.fromCharCode(65 + i), disabled: true }))
        
      }
      
      let tempPayment = []
      if(latestProposal && latestProposal.proposal_type && latestProposal.proposal_type == 'fixed' && latestProposal.payment_terms.length > 0){
        latestProposal.payment_terms.forEach(el=>{
          if(el.type == 'token' && (el.price_without_tax || el.total_earning)){
            tempPayment.push(el)
          }
          else if(el.type == 'milestone'){
            tempPayment.push(el)
          }
        })
      }
      
      

      
      // if(
      //   latestProposal.payment_terms
      // )
      // for ( let x of latestProposal.payment_terms){

      //   for (let y of x.milestone_reference){
      //       for (let z of tempPaymentTerms){
      //         if(y.label == z.label)

      //       }
      //   }
      // }

      
      this.setState({
        Loader:false,
        projectDetails: projectDetails,
        milestoneInputs:( latestProposal && latestProposal.proposal_type == 'fixed') ? mileData
          ? mileData.filter(el => el.type == 'milestone' ? el : false)
          : [{ name: "", due_date: "", iteration: 1, status: 1, restrict: false, type: 'milestone',toggle:false,iteration_charge:0 }] : [{ name: "", due_date: "", iteration: 1, status: 1, restrict: false, type: 'milestone',toggle:false,iteration_charge:0, }] ,
        Gst: latestProposal ? latestProposal.tax : "",
        clientBudget: latestProposal ? latestProposal.sub_total_cost : "",
        creatorCost: latestProposal ? latestProposal.total_earning : "",
        // note: latestProposal ? latestProposal.note : "",
        note: latestProposal ? this.convertToPlain(latestProposal.note) : "",

        proposal_type: latestProposal ? latestProposal.proposal_type : 'fixed',
        EditIs: projectDetails?.Milestone?.length > 0 ? true : false || latestProposal ? true : false,
        statusIs: project ? project.status : "",
        proposed_start_date: latestProposal ? latestProposal?.proposed_start_date? setTimeToZero(latestProposal?.proposed_start_date) : setTimeToZero(new Date()) : setTimeToZero(new Date()),
        proposed_duration: latestProposal ? latestProposal.proposed_duration : 1,
        preferred_billing_cycle: latestProposal ? latestProposal.preferred_billing_cycle : "Monthly",
        deliverablesActual: latestProposal ? latestProposal.proposal_type == 'actual' ? latestProposal?.deliverables : [{ iteration: 1, iteration_charge: 0, name: "", tax: 0, price: "", price_creator: "" }] : [{ iteration: 1, iteration_charge: 0, name: "", tax: 0, price: "", price_creator: "" }],
        number_of_hours: latestProposal ? latestProposal.number_of_hours : 1,
        number_of_hours_type: latestProposal ? latestProposal.number_of_hours_type : 'Day',
        workingDays: latestProposal ? data.working_days_in_week : [],
        retainer_fees: data ? latestProposal?.retainer_fees? latestProposal.retainer_fees : 0: 0  ,
        retainer_fees_creator: latestProposal ? latestProposal.retainer_fees_creator : 0,
        retainer_fees_type:latestProposal?latestProposal.retainer_fees_type:'Hourly',
        manager_id_validation: project ? project.manager_id : "",
        completed_milestone_no: latestProposal ? latestProposal.completed_milestone_no : 0,
        payment_terms: latestProposal ? latestProposal.proposal_type == 'fixed' ? latestProposal.payment_terms
          ? tempPayment
          : [{ milestone_reference: [], price: "0", restrict: false, status: 0, type: "milestone", id: "" }] : [{ milestone_reference: [], price: "0", restrict: false, status: 0, type: "milestone", id: "" }] : [{ milestone_reference: [], price: "0", restrict: false, status: 0, type: "milestone", id: "" }],
        paymentTermsOptions: tempPaymentTerms,
      });

    }
    if (proposalData && LoadingIs) {
      const projectId = this.props.match.params.id.split(":").pop();
      this.props.history.push(`/transactions/addProject/:${projectId}`);
    }
    if (updateData && LoaderIs) {
      alert("Update Successfully...");
      const projectId = this.props.match.params.id.split(":").pop();
      this.handleGetProjectData(projectId);
      this.setState({ LoaderIs: false });
    }
    if (milesApprovedData && milesApprovedIs) {
      this.setState({
        milesApprovedIs: false,
      });
      const projectId = this.props.match.params.id.split(":").pop();
      this.handleGetProjectData(projectId);
    }
  }

  convertToPlain = (html) => {

    var tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = html;
  
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }


  handleEditManager = () => {
    this.setState({ modalManagerOpen: true })
  }

  handleCloseManagerModal = () => {
    this.setState({ modalManagerOpen: false })
  }

  handleViewProposal = () => {
    let data = this.props._transactionGetData.transactionData
    let projectDataIs = data?.project
    let newProposalList = data?.newProposalList
    let user_id2 = {
      first_name: data?.users?.creator_id?.first_name,
      last_name: data?.users?.creator_id?.last_name
    }
    let obj = {
      newProposalList,
      user_id2,
      status: projectDataIs?.status,
      _id: projectDataIs?._id

    }
    this.setState({ MilesData: obj, projectDataIs }, () => { this.setState({ showProposalModal: true }) });
  };

  handleEditClient = () => {
    this.setState({
      modalClientOpen: true,
    });
  };

  handleCloseClintModal = () => {
    this.setState({
      modalClientOpen: false,
    });
  };

  handleOnChange = (obj, checkedIs) => {

    const { approvedCreatorData } = this.state;
    if (checkedIs && obj) {
      const projectId = this.props.match.params.id.split(":")[1];
      const DataIs = {
        post_project_id: projectId,
        user_id: obj?.user_id2?._id,
        proposal_id: obj?.proposal?.proposal?._id,
        note: obj?.proposal?.proposal?.note,
      };
      let chooseData = approvedCreatorData;
      chooseData.push(DataIs);
      const currentData = { data: chooseData };
      this.setState({
        approvedCreatorData: chooseData,
        selectedCreator: currentData,
      });
    } else {
      const remainData = approvedCreatorData?.filter(
        (el) => el.user_id !== obj.user_id2._id
      );
      const currentData = { data: remainData };
      this.setState({
        approvedCreatorData: remainData,
        selectedCreator: currentData,
      });
    }
  };

  handleOnCheckGST = (e) => {
    if (e.target.checked) {
      this.setState({ Gst: 18 })
    }
    else {
      this.setState({ Gst: 0 })
    }
  }

   addHrMinDate = (value, hr, min) => {
    let date = new Date(value);
    date.setHours(date.getHours() + hr);
    date.setMinutes(date.getMinutes() + min);
    return date;
  };

  handleApprovedProposal = () => {
    const { selectedCreator } = this.state;
    this.setState({ creatorLoader: true });
    this.props.$approvedProposal(selectedCreator);
  };

  handleTransactionData = (obj) => {

    this.props.$transactionGetData(obj, { admin: true });
    // this.props.$transactionGetData(obj, { admin: false });
  };

  handleCloseProposalModal = () => {
    this.setState({ showProposalModal: false });
  };

  handleChooseCreator = () => {
    let artist = {
      page: 1,
      firstname: "",
      lastname: "",
      username: "",
      email: "",
    };
    this.props.$artistDataList(artist);
  };

  getDeleteConfirmation = (id) => {
    let confirmIs = window.confirm(`Do you want to continue ?`);
    if (confirmIs === true) {
      this.setState({ deletedLoader: true });
      const projectId = this.props.match.params.id.split(":").slice(0)[1];
      const currentData = { _id: projectId, user_id: id };
      this.props.$deletePendingCreator(currentData);
      return true;
    } else {
      return false;
    }
  };

  handleOnChangeProposedStartDate = (value) => {
    this.setState({ proposed_start_date: new Date(value) })
  }


  handleCloseRejectModal = () => {
    this.setState({ showRejectModal: false });
    this.componentDidMount();
  };


  render() {
    const {

      // clientData,

      projectDataIs,
      proposal_type
    } = this.state;
    const func = {
      handleCloseProposalModal: this.handleCloseProposalModal,
      handleCloseClintModal: this.handleCloseClintModal,
      handleCloseManagerModal: this.handleCloseManagerModal,
      handleCloseRejectModal: this.handleCloseRejectModal,
      handleSetManagerId: this.handleSetManagerId,
      handleApiCall:this.handleApiCall,
    };
    const {
      LoadingIs,
      EditIs,
      LoaderIs,
      statusIs,
      managerError,
    } = this.state;

    const milesFunc = {
      handleCloseMilesModal: this.handleCloseMilesModal,
      handleChange: this.handleChange,
      handleOnCheckGST: this.handleOnCheckGST,
      handleMilestoneChange: this.handleMilestoneChange,
      handleRemoveMilestone: this.handleRemoveMilestone,
      handleViewcompletedMiles: this.handleViewcompletedMiles,
      handleApprovedMiles: this.handleApprovedMiles,
      handleAppendMilestone: this.handleAppendMilestone,
      handleChangePaymentTerms: this.handleChangePaymentTerms,
      handleAppendPaymentTerms: this.handleAppendPaymentTerms,
      handleRemovePaymentTerms: this.handleRemovePaymentTerms,
      handleChangeBreakup: this.handleChangeBreakup,
      handleChangeTax: this.handleChangeTax,
      handleChangeProposalType: this.handleChangeProposalType,
      setWorkingDays: this.setWorkingDays,
      handleChangeProposedDuration: this.handleChangeProposedDuration,
      handleChangeNumberHours: this.handleChangeNumberHours,
      handleChangeNumberHoursInput: this.handleChangeNumberHoursInput,
      handleChangeProposedDurationInput: this.handleChangeProposedDurationInput,
      handleChangeNumberHoursDropdown: this.handleChangeNumberHoursDropdown,
      handleChangeRetainerFees: this.handleChangeRetainerFees,
      handleChangeRetainerFeesCreator: this.handleChangeRetainerFeesCreator,
      handleChangeRetainerFeesType: this.handleChangeRetainerFeesType,
      handleChangePreferredBillingCycle: this.handleChangePreferredBillingCycle,
      handleChangeActualDeliverables: this.handleChangeActualDeliverables,
      handleAppendActualDeliverables: this.handleAppendActualDeliverables,
      handleRemoveActualDeliverables: this.handleRemoveActualDeliverables,
      handleOnChangeProposedStartDate: this.handleOnChangeProposedStartDate,
      handleIteration:this.handleIteration,
      handleAdvancePayment:this.handleAdvancePayment,
      resetField: this.resetField,
    };

    return (
      <React.Fragment>

       { this.state.Loader?<>
        <Loader/>
        </>
        :
        <>
        <section className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="card card-new">
                <div className="card-body">
                  <div className="row align-items-center">
                    {projectDataIs && projectDataIs.manager_image && <div className="col-auto rounded">
                      <a
                        href={`/backendusers/backendusersForm/:${projectDataIs?.manager_id}`}
                        className="avatar avatar-lg"
                      >
                        <img
                          src={
                            projectDataIs.manager_image
                              ? userProfileBaseUrl + projectDataIs.manager_image
                              : require("../../../../Assets/img/avatars/profiles/images.jpeg")
                          }
                          alt="..."
                          className="avatar-img rounded"
                        />
                      </a>
                    </div>}
                    <div className="col ml-n2">
                      <h4 className="card-title mb-1 name">
                        <a href={`#!`}>
                          {projectDataIs?.manager_name ? projectDataIs?.manager_name : "Not Found"}
                        </a>
                      </h4>

                      <p className="card-text small text-muted mb-1">
                        <i className="fe fe-user"></i> Manager
                      </p>
                      <div className="col-auto text-right">
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={this.handleEditManager}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                  {managerError && (
                    <span className="error">Required Field</span>
                  )}
                </div>
              </div>
            </div>

          </div>

          {projectDataIs?.manager_name ? <>
            <Milestone {...this.state}{...this.props} handleFunc={milesFunc} />
            <div className="d-flex justify-content-end py-4" style={{ bottom: '0' }}>
              {EditIs && this.state.proposal_type ? <div className="">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => this.handleViewProposal()}
                >View Proposal
                </button>
              </div> : null}
             {(proposal_type=='fixed' || ((proposal_type=='retainer' || proposal_type=='actual') && !EditIs)) && <div className="mr-2 ml-5">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={
                    EditIs ? this.handleUpdateProposal : this.handleProposalSubmit
                  }
                  disabled={
                    statusIs === 7 || statusIs === 8 || statusIs === 10
                      ? "disabled"
                      : ""
                  }
                >
                  {EditIs ? (
                    LoaderIs ? (
                      <LoaderSmall />
                    ) : (
                      "Update proposal"
                    )
                  ) : LoadingIs ? (
                    <LoaderSmall />
                  ) : (
                    "Save proposal"
                  )}
                </button>
              </div>}
            </div>
          </>
            :
            <span className=" py-4" >
              <span className="error">* </span> Please choose account manager to proceed further.
            </span>
          }
          {/*------------------submit milestones Modal---------------------*/}

          {/* {this.state.submitMilesOpen && (
            <SubmitMilestone
              {...this.state}
              {...this.props}
              handleMiles={milesFunc}
            />
          )} */}

          {/*------------------submit milestones Modal---------------------*/}
        </section>

        <style>
          {`.react-date-picker__clear-button{
              display: none
            }
            .react-date-picker__wrapper{
              border : none
            }
            .disabled{
              opacity : 0.5 !important;
              background-color : #f0f0f0 !important
            }
            
            .react-date-picker__calendar.react-date-picker__calendar--open {
              top: 0!important;
          }
            `}
        </style>
        <Manager admin={true} {...this.state} {...this.props} handleFunc={func} />
        <ProposalModal {...this.state} {...this.props} handleFunc={func} />
        </>}
      </React.Fragment>
    );
  }
}

const mapState = ({
  getPostProjectData,
  addProposal,
  updateProposal,
  completedMiles,
  milestoneApproved,
  transactionGetData
}) => {
  return {
    _transactionGetData: transactionGetData,

    _getPostProjectData: getPostProjectData,
    _addProposal: addProposal,
    _updateProposal: updateProposal,
    _completedMiles: completedMiles,
    _milestoneApproved: milestoneApproved,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $transactionGetData: (values, payload) => dispatchEvent(transactionGetData(values, payload)),

  $getPostProjectData: (values) => dispatchEvent(getPostProjectData(values)),

  $addProposal: (values) => dispatchEvent(addProposal(values)),
  $updateProposal: (values) => dispatchEvent(updateProposal(values)),
  $completedMiles: (values) => dispatchEvent(completedMiles(values)),
  $milestoneApproved: (values) => dispatchEvent(milestoneApproved(values)),
  $getPostProjectDataSuccess:(values)=>dispatchEvent(getPostProjectDataSuccess(values))
});
export default connect(mapState, mapDispatch)(ProjectMilestone);


export const setTimeToZero = (value) => {
  var time = moment(value).toDate();
  time.setHours(0);
  time.setMinutes(0);
  time.setSeconds(0);
  time.setMilliseconds(0);

  return time;
};