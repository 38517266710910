import React, { Component } from "react";
import { postAPI } from "../../../../store/api-interface";
import LoaderSmall from "../Loader/LoaderSmall";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default class UserSignup extends Component {
  state = {
    signupFirstName: "",
    signupLastName: "",
    signupEmailIs: "",
    signupPass: "",
    phone: "",
    post_Project: false,
    freelancer: false,
    LoadingIs: false,
    signupResponse: "",
    fireBaseUserIs: false,
    phoneNumLength: 0,
    phoneError: false,

    errorIs: {
      signupFirstName: "",
      signupLastName: "",
      signupPass: "",
      signupPhone: "",
    },
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    this.validateForm(name, value);
  };

  handlePhoneChange = (phone1, country) => {
    let phoneNumLength =
      country.format.match(/\./g).length - country.dialCode.length;

    if (phoneNumLength < 12) {
      phoneNumLength = phoneNumLength + country.dialCode.length;
    } else {
      phoneNumLength = 12 + country.dialCode.length;
    }

    this.setState({
      phone: phone1,
      phoneError: false,
      phoneNumLength,
    });
  };

  validateForm(name, value) {
    const { errorIs } = this.state;
    const { fireBaseUserIs } = this.props;
    switch (name) {
      case "signupFirstName":
        errorIs.signupFirstName = !value.length ? "* Required Field" : "";
        break;
      case "signupLastName":
        errorIs.signupLastName = !value.length ? "* Required Field " : "";
        break;
      case "signupPass":
        const is8andAbove = value.length > 7;
        const hasUpperCase = /(?=.*[A-Z])/.test(value);
        const hasLowerCase = /(?=.*[a-z])/.test(value);
        errorIs.signupPass = !fireBaseUserIs
          ? !value.length
            ? "* Required Field"
            : !is8andAbove
            ? "Enter Valid Password (must be 8 character, 1 uppercase and 1 lowercase)"
            : !hasUpperCase
            ? "Atleast 1 Uppercase is required"
            : !hasLowerCase
            ? "Atleast 1 lowercase is required"
            : ""
          : "";
        break;
      default:
        break;
    }
    return (
      !errorIs.signupFirstName && !errorIs.signupLastName && !errorIs.signupPass
    );
  }

  validation() {
    const {
      errorIs,
      signupFirstName,
      signupLastName,
      phone,
      signupPass,
    } = this.state;

    const is8andAbove = signupPass.length > 7;
    const hasUpperCase = /(?=.*[A-Z])/.test(signupPass);
    const hasLowerCase = /(?=.*[a-z])/.test(signupPass);
    const { fireBaseUserIs } = this.props;
    errorIs.signupFirstName = !signupFirstName.length ? "* Required Field" : "";
    errorIs.signupLastName = !signupLastName.length ? "* Required Field" : "";
    errorIs.signupPass = !fireBaseUserIs
      ? !signupPass.length
        ? "* Required Field"
        : !is8andAbove
        ? "Enter valid password (must be 8 character, 1 uppercase and 1 lowercase )"
        : !hasUpperCase
        ? "Atleast 1 Uppercase is required"
        : !hasLowerCase
        ? "Atleast 1 Lowercase is Required"
        : ""
      : "";
    // errorIs.signupPhone =
    //   (!this.state.phoneNumLength ||
    //     phone.length < this.state.phoneNumLength) &&
    //   this.setState({ phoneError: true });
    
    errorIs.signupPhone =
      (!this.state.phoneNumLength ||
        phone.length < this.state.phoneNumLength) ?
      "required field" : "" ;
    // this.setState({ phoneError: true })

    this.setState({
      errorIs,
      phoneError: (!this.state.phoneNumLength ||phone.length < this.state.phoneNumLength) ? true : false
    });

    return (
      !errorIs.signupFirstName &&
      !errorIs.signupLastName &&
      !errorIs.signupPass &&
      !errorIs.signupPhone
    );
  }

  handleSignUp = (e) => {
    e.preventDefault();
    const {
      signupFirstName,
      signupLastName,
      signupPass,
      phone,
    } = this.state;
    const { fireBaseUserIs } = this.props;
    const param = this.props.match.params.email.split(":").pop();
    const paramNow = this.props.match.params.param1.split(":").pop();
    if (this.validation()) {
      this.setState({
        LoadingIs: true,
      });
      const createdUserIs = {
        email: param,
        phone_no: `${"+" + phone}`,
        first_name: signupFirstName,
        last_name: signupLastName,
        login_type: "manual_login",
        user_type: `${paramNow === "clients" ? "1" : "2"}`,
        password: `${!fireBaseUserIs ? signupPass : ""}`,
      };
      // console.log("7",createdUserIs)
      this.handleSignupRequest(createdUserIs);
    }
  };

  handleSignupRequest = (obj) => {
    postAPI("/users/admin-signup", obj)
      .then((res) => {
        const dataIs = res.data.status;
        this.handleSignupResponse(dataIs);
      })
      .catch((error) => {
        this.setState({
          LoadingIs: false,
        }, () => {
          alert(error.response.data.message);
        });
      });
  };

  handleSignupResponse = (obj) => {
    const currentParam = "/" + this.props.match.params.param1.split(":").pop();
    if (obj === "0") {
      this.setState({
        LoadingIs: false,
      }, () => {
        alert("Account could not be created. Please try again");
      });
    } else if (obj === "1") {
      this.setState({
        LoadingIs: false,
      }, () => {
        alert("Successful...");
        this.props.history.push(currentParam);
        });
    } else {
        this.setState({
          LoadingIs: false,
        });
        alert("Something went wrong. Please try again.");
    }
    this.resetField();
  };

  resetField() {
    this.setState({
      signupResponse: "",
    });
  }

  componentDidMount() {
    const currentParam = "/" + this.props.match.params.param1.split(":").pop();
    this.props.handleClients.handlepathName(currentParam);
    const { userArtist } = this.props;
    if (!userArtist) {
      this.props.history.push(currentParam);
    }
  }

  render() {
    const param = this.props.match.params.email.split(":").pop();
    const paramNow = this.props.match.params.param1.split(":").pop();

    const { fireBaseUserIs } = this.props;

    const {
      signupFirstName,
      signupLastName,
      signupPass,
      phone,
      LoadingIs,
      errorIs,
      phoneError,
    } = this.state;

    const signUpArr = [
      {
        type: "text",
        placeholder: "Enter firstname",
        nameIs: "signupFirstName",
        value: signupFirstName,
        label: "First Name",
      },
      {
        type: "text",
        placeholder: "Enter lastname",
        nameIs: "signupLastName",
        value: signupLastName,
        label: "Last Name",
      },
      {
        type: "email",
        placeholder: "Enter email",
        nameIs: "signupEmailIs",
        value: param,
        label: "Email Address",
      },
    ];
    return (
      <React.Fragment>
        <section
          className="d-flex align-items-center bg-auth "
          style={{
            minHeight: "-webkit-fill-available",
          }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-5 col-xl-8 my-5">
                <h1 className="display-4 text-center mb-5">
                  {paramNow === "clients" ? "Create Client" : "Create Artist"}
                </h1>
                {/* <!-- Form --> */}
                <form onSubmit={this.handleSignUp}>
                  <div className="row">
                    {signUpArr.map((obj, index) => (
                      <div className="col-md-6" key={index}>
                        <div className="form-group">
                          <label> {obj.label} </label>
                          <input
                            className={`form-control 
                              ${
                                ((obj.label === "Firstname" &&
                                  errorIs.signupFirstName) ||
                                  (obj.label === "LastName" &&
                                    errorIs.signupLastName)) &&
                                `error-show`
                              }
                              `}
                            type={obj.type}
                            placeholder={obj.placeholder}
                            name={obj.nameIs}
                            value={obj.value}
                            onChange={this.handleOnChange}
                            disabled={`${
                              obj.type === `email` ? `disabled` : ``
                            }`}
                            autoComplete="off"
                          />

                          {/*-------------error message --------------------*/}
                          {((obj.label === "Firstname" &&
                            errorIs.signupFirstName) ||
                            (obj.label === "LastName" &&
                              errorIs.signupLastName)) && (
                            <span className="error">
                              {errorIs.signupFirstName ||
                                errorIs.signupLastName}
                            </span>
                          )}
                          {/*-------------error message --------------------*/}
                        </div>
                      </div>
                    ))}

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Phone Number</label>
                        <PhoneInput
                          country={"in"}
                          value={phone}
                          onChange={(phone1, country) =>
                            this.handlePhoneChange(phone1, country)
                          }
                          inputClass={`${phoneError ? "error-show" : ""}`}
                        />
                        {phoneError && (
                          <span className="error">Add Phone to continue</span>
                        )}
                      </div>
                    </div>
                  </div>
                  {!fireBaseUserIs ? (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label> Password </label>
                          <input
                            className={`form-control ${
                              errorIs.signupPass && `error-show`
                            }`}
                            type="text"
                            placeholder="Enter your password"
                            name="signupPass"
                            value={signupPass}
                            onChange={this.handleOnChange}
                          />
                          {/*------------------error message ------------*/}
                          {errorIs.signupPass && (
                            <span className="error">{errorIs.signupPass}</span>
                          )}
                          {/*------------------error message ------------*/}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <!-- Submit --> */}
                  <button
                    type="submit"
                    className="btn btn-lg btn-block btn-primary mb-3"
                  >
                    {paramNow === "clients" ? (
                      LoadingIs ? (
                        <LoaderSmall />
                      ) : (
                        "Create Client"
                      )
                    ) : LoadingIs ? (
                      <LoaderSmall />
                    ) : (
                      "Create Artist"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
