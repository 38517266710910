import React,{ Component } from 'react';
import { Switch } from 'react-router';
import Project from './Project';
// import ProjectDetailsForm from './ProjectDetailsForm';

export default class ProjectChildRoute extends Component{

    componentWillMount(){
        this.props.handleClients.handlepathName("/projects");
    }

    render(){
        return(
            <React.Fragment>
                <Switch>
                    {/* <Route path="/projects/projectDetails/" render={(props)=> <ProjectDetailsForm {...props} />}/> */}
                    <Project {...this.props} {...this.state}/>
                </Switch>
            </React.Fragment>
        )
    }
}