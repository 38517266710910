import {
  REPORT_FOR_PROJECT_BRIEF_SUCCESS,
  REPORT_FOR_ENTERPRISE_PROJECT_SUCCESS,
  REPORT_FOR_PROJECT_SUCCESS,
  REPORT_FOR_COLLATERAL_SUCCESS,
  REPORT_FOR_ALL_ARTIST_SUCCESS,
  REPORT_FOR_APPROVED_ARTIST_SUCCESS,
  REPORT_FOR_CLIENT_SUCCESS
} from "../type.action";

const initState = {
  reportForProjectIs: "",
  reportForEnterprise: "",
  reportForProject: "",
  reportForCollateral: "",
  reportforall: "",
  approvedArtistReport : '',
  clientReport: ""
};

const reducers = (state = initState, { type, values, error }) => {
  switch (type) {
    case REPORT_FOR_PROJECT_BRIEF_SUCCESS:
      return {
        ...state,
        reportForProjectIs: values,
      };
    case REPORT_FOR_ENTERPRISE_PROJECT_SUCCESS:
      return {
        ...state,
        reportForEnterprise: values,
      };
    case REPORT_FOR_PROJECT_SUCCESS:
      return {
        ...state,
        reportForProject: values,
      };
    case REPORT_FOR_COLLATERAL_SUCCESS:
      return {
        ...state,
        reportForCollateral: values,
      };
    case REPORT_FOR_ALL_ARTIST_SUCCESS:
      return {
        ...state,
        reportforall: values,
      };
    case REPORT_FOR_APPROVED_ARTIST_SUCCESS:
      return{
        ...state,
        approvedArtistReport : values
      }  
    case REPORT_FOR_CLIENT_SUCCESS:
      return{
        ...state,
        clientReport : values
      }  
    default:
      return state;
  }
};
export default reducers;
