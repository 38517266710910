import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import {
  collateralUploadPhoto,
  collateralPCFName,
  getCollateral,
} from "../../../store/action/collateralList.action";
import { otherOptionDefaultImageUrl } from "../../../Utils/BaseUrl";
import { putAPI, postImage, postAPI } from "../../../store/api-interface";
import CollateralQuestion from "../Collaterals/CollateralQuestion";
import CollateralCreator from "../Collaterals/CollateralCreator";
import CollateralStarred from "../Collaterals/CollateralStarred";
import CollateralProjects from "../Collaterals/CollateralProjects";
import CollateralTransactions from "../Collaterals/CollateralTransactions";
import CollateralHistory from "../Collaterals/CollateralHistory";
import CollateralBasicDetailsForm from "./CollateralBasicDetailsForm";

class CollateralBasicDetails extends Component {
  state = {
    profileImage: "",
    firstName: "",
    alternativeName: "",
    parentCF: "",
    pcfDropdownData: [],
    pcfSelectedItems: [],
    pcfListVisibility: false,
    parentUnit: "",
    aboutCollateral: "",
    typeQs: "",
    styleQs: "",
    essentialQs: "",
    projectDetailsQs: "",
    companyDetailsQs: "",
    typeQsList: false,
    styleQsList: false,
    essentialList: false,
    projectList: false,
    companyList: false,
    styleDropDownQs: "",
    essentialDropdownQs: "",
    projectDropdownQs: "",
    companyDropdownQs: "",
    typeDropdownQs: "",

    styleQuestionIs: [],
    essentialQsIs: [],
    projectQsIs: [],
    companyQsIs: [],
    typeQsIs: [],
    OptionInputs: [],

    alternateNameIs: [],
    collateralEditIs: false,
    optionIndexIs: "",
    uploadLoadingIs: false,
    LoadingIs: false,

    styleOptionIndex: "",
    styleOptionEdit: false,
    essentialIndex: "",
    essentialOptionEdit: false,
    projectOptionEdit: false,
    projectIndex: "",
    companyIndex: "",
    companyOptionEdit: false,

    optionImgIndex: "",
    optionImageIs: [],
    optionUploadImage: false,
    typeOptionIndex: "",
    typeOptionEdit: false,

    budgetHeading: "",
    budgetSubtext: "",
    deadlineHeading: "",
    deadlineSubtext: "",
    projectNameHeading: "",
    projectDescriptionHeading: "",
    projectNameSubtext: "",
    projectDescriptionSubtext: "",
    projectRefernceLinkHeading: "",
    projectReferenceLinkSubtext: "",
    projectReferencefilesHeading: "",
    projectReferencefilesSubtext: "",
    companyNameHeading: "",
    companyNameSubtext: "",
    companyWorkTypeHeading: "",
    companyWorkTypeSubtext: "",
    companyJobRoleHeading: "",
    companyJObRoleSubtext: "",
    companyLinkHeading: "",
    companyLinkSubtext: "",
    companydescribesHeading: "",
    companydescribesSubtext: "",
    companyLocationHeading: "",
    companyLocationSubtext: "",
    companyIndustryHeading: "",
    companyIndustrySubtext: "",
    companyEmpHeading: "",
    companyEmpSubtext: "",
    companyIndivualHeading: "",
    companyIndivualSubtext: "",
    projectNamePlaceholder: "",
    projectdescPlaceholder: "",
    companyNamePlaceholder: "",
    companyJobRolePlaceholder: "",
    companydescPlaceholder: "",
    standardProject: "",
    ubitQsSubtext: '',

    subtext: "",
    qsMandatory: "",
    ansPlaceholder: "",
    startRange: "",
    endRange: "",
    ansLimit: "",

    unit: "",
    ubitQs: "",
    secondImageLoader: false,
    secondImage: "",

    imagesTextOption: "",
    currentImageIs: false,
    imageTextOptionIndex: "",
    textOptionEdit: false,
    selectedImagePath: "",
    otherOption: false,
    pcfActive: false,

    artistBasic: false,

    errorIs: {
      firstName: "",
      profileImage: "",
      secondImage: "",
      standardProject: "",
    },

    frontendUrl : ''
  };


  setCollateralState=(stateObj)=>{
    this.setState(stateObj)
  }

  /* ----------------------------- onChange --------------------------------- */

  handleOnchange = (e) => {
    const { name, value } = e.target;
    if (name === "styleQs") {
      if (value === null || value === "Select Option") {
        this.setState({
          styleQsList: false,
        });
      } else {
        this.setState({
          styleQsList: true,
        });
      }
      this.setState({
        styleQuestionActive: true,
        projectList: false,
        companyList: false,
        otherOption: false,
        typeQsList: false,
      });
      this.resetOptions();
    } else if (name === "projectDetailsQs") {
      if (value === null || value === "Select Option") {
        this.setState({
          projectList: false,
        });
      } else {
        this.setState({
          projectList: true,
        });
      }
      this.setState({
        companyList: false,
        typeQsList: false,
        otherOption: false,
        projectQsActive: true,
        styleQsList: false,
      });
      this.resetOptions();
    } else if (name === "companyDetailsQs") { 
      if (value === null || value === "Select option") {
        this.setState({
          companyList: false,
        });
      } else {
        this.setState({
          companyList: true,
        });
      }
      this.setState({
        companyQsActive: true,
        styleQsList: false,
        projectList: false,
        typeQsList: false,
        otherOption: false,
      });
      this.resetOptions();
    } else if (name === "typeQs") {
      if (value === null || value === "Select Option") {
        this.setState({
          typeQsList: false,
        });
      } else {
        this.setState({
          typeQsList: true,
        });
      }
      this.setState({
        companyQsActive: true,
        styleQsList: false,
        projectList: false,
        companyList: false,
        otherOption: false,
      });
      this.resetOptions();
    } else if (name === "parentCF") {
      this.handleParentCreativeSection(value);
      this.setState({
        pcfListVisibility: true,
        pcfNameExist: name,
        pcfActive: true,
      });
    } else if (name === "imagesTextOption") {
      this.setState({
        currentImageIs: true,
      });
    } else if (name === "parentUnit") {
      this.setState({
        ubitQs: "",
        unit: "",
      });
    }
    this.setState({
      [name]: value,
    });
    this.getValidation(name, value);
  };

  resetOptions = () => {
    this.setState({
      styleDropDownQs: "",
      styleCheckboxQs: "",
      projectDropdownQs: "",
      companyDropdownQs: "",
      typeDropdownQs: "",
      OptionInputs: [],
      essentialDropdownQs: "",
      optionImageIs: [],
      subtext: "",
      qsMandatory: "",
      ansPlaceholder: "",
      startRange: "",
      endRange: "",
      ansLimit: "",
      imagesTextOption: "",
      currentImageIs: false,
    });
  };

  getValidation(name, value) {
    const { errorIs } = this.state;
    switch (name) {
      case "firstName":
        errorIs.firstName = !value.length ? "* Required Field" : "";
        break;      
      default:
        break;
    }
  }

  /* ----------------------------- onChange --------------------------------- */

  /*---------------------------- pcf -------------------------------------*/

  handleParentCreativeSection = (value) => {
    this.props.$collateralPCFName(value);
  };

  handleSelectedListItem = (obj) => {
    const { pcfSelectedItems } = this.state;

    const pcfAlreadyExist = this.state.pcfSelectedItems.some(
      (item) => item.parent_creative_field_name === obj.name
    );
    if (!pcfAlreadyExist) {
      var currentValue = pcfSelectedItems.concat({
        parent_creative_field_id: obj._id,
        parent_creative_field_name: obj.name,
      });
      this.setState({
        pcfSelectedItems: currentValue,
        parentCF: obj.name,
        pcfListVisibility: false,
      });
    } else {
      alert("This name is already selected.....");
      this.setState({
        pcfListVisibility: false,
      });
    }
  };

  handlecollateralListPcf = () => {
    const { pcfListVisibility } = this.state;
    this.setState({
      pcfListVisibility: !pcfListVisibility,
    });
  };

  handleDeleteParentCreativeField = (index) => {
    const { pcfSelectedItems } = this.state;
    if (index !== -1) {
      var removePcf = pcfSelectedItems.splice(0, index);
      this.setState({
        pcfSelectedItems: removePcf,
      });
    }
  };

  /*---------------------------- pcf -------------------------------------*/

  /*--------------------------- upload collateral Image ------------------------------*/

  handleUploadImage = (e) => {
    const { name } = e.target;
    let collateralImage = null;
    if (name === "firstImage") {
      this.setState({
        uploadLoadingIs: true,
      });
      collateralImage = e.target.files[0];
      let value = collateralImage;
      this.getValidation(name, value);
    } else if (name === "secondImage") {
      this.setState({
        secondImageLoader: true,
      });
      collateralImage = e.target.files[0];
      let value = collateralImage;
      this.getValidation(name, value);
    }
    if (collateralImage) {
      const formData = new FormData();
      formData.append("images", collateralImage);
      this.props.$collateralUploadPhoto(formData);
    }
  };

  /*--------------------------- upload collateral Image ------------------------------*/

  /*-------------------------------- add collateral ----------------------------*/

  validateForm() {
    const {
      firstName,      
      errorIs,
    } = this.state;

    errorIs.firstName = !firstName.length ? "* Required Field" : "";   
    this.setState({
      errorIs,
    });

    return !errorIs.firstName;
  }

  handleAddCollateral = (e) => {
    e.preventDefault();
    const current = this.state;

    const addCollateral = {
      name: current.firstName,
      image: current.profileImage,
      about: current.aboutCollateral,
      pricing_unit: current.parentUnit,
      explain: "",
      type_question: current.typeQsIs,
      style_question: current.styleQuestionIs,
      essential_question: current.essentialQsIs,
      company_detail_question: current.companyQsIs,
      project_detail_question: current.projectQsIs,
      pcf_collateral: current.pcfSelectedItems,
      alternative_name: current.alternateNameIs,
      image_second: current.secondImage,
      unit: current.unit,
      unit_question: current.ubitQs,
      budget_heading: current.budgetHeading,
      deadline_heading: current.deadlineHeading,
      budget_subtext: current.budgetSubtext,
      deadline_subtext: current.deadlineSubtext,
      project_name_heading: current.projectNameHeading,
      project_description_heading: current.projectDescriptionHeading,
      project_name_subtext: current.projectNameSubtext,
      project_description_subtext: current.projectDescriptionSubtext,
      project_add_reference_link_heading: current.projectRefernceLinkHeading,
      project_add_reference_files_heading: current.projectReferencefilesHeading,
      project_add_reference_link_subtext: current.projectReferenceLinkSubtext,
      project_add_reference_files_subtext: current.projectReferencefilesSubtext,
      company_name_heading: current.companyNameHeading,
      company_name_subtext: current.companyNameSubtext,
      company_work_type_heading: current.companyWorkTypeHeading,
      company_work_type_subtext: current.companyWorkTypeSubtext,
      company_job_role_heading: current.companyJobRoleHeading,
      company_job_role_subtext: current.companyJObRoleSubtext,
      company_add_link_heading: current.companyLinkHeading,
      company_add_link_subtext: current.companyLinkSubtext,
      company_describes_heading: current.companydescribesHeading,
      company_describes_subtext: current.companydescribesSubtext,
      company_location_heading: current.companyLocationHeading,
      company_location_subtext: current.companyLocationSubtext,
      company_industry_heading: current.companyIndustryHeading,
      company_industry_subtext: current.companyIndustrySubtext,
      company_employees_heading: current.companyEmpHeading,
      company_employees_subtext: current.companyEmpSubtext,
      company_individual_location_heading: current.companyIndivualHeading,
      company_individual_location_subtext: current.companyIndivualSubtext,
      project_name_placeholder: current.projectNamePlaceholder,
      project_description_placeholder: current.projectdescPlaceholder,
      company_name_placeholder: current.companyNamePlaceholder,
      company_job_role_placeholder: current.companyJobRolePlaceholder,
      company_describes_placeholder: current.companydescPlaceholder,
      standard_project_unit_description: current.standardProject,
      unit_question_subtext: current.ubitQsSubtext,
      page_url :current.frontendUrl
    };

    if (this.validateForm()) {
      this.setState({
        LoadingIs: true,
      });
      postAPI("/collateral/add-collateral", addCollateral)
        .then((res) => {          
          if (res.data.status === "1") {
            let id = res.data.data._id;
            this.props.history.push("/collateralsQuestions/questions/:" + id);
            this.setState({
              LoadingIs: false,
              collateralEditIs: true,
            });
          }
        })
        .catch((error) => {         
          alert(`Error : ${error.response.status}`)
          this.setState({
            LoadingIs : false
          })
        });
    }
  };

  /*-------------------------------- add collateral ----------------------------*/

  /*--------------------------------upload options Image ------------------------- */

  handleoptionImage = (obj) => {
    const {
      optionImageIs,
      imagesTextOption,
      textOptionEdit,
      imageTextOptionIndex,
    } = this.state;
    postImage("/collateral/upload-images", obj)
      .then((res) => {        
        if (res.data.status === "1") {
          const imagePath = res.data.name;
          if (!textOptionEdit) {
            const imgeUrl = { item: imagesTextOption, image: imagePath };
            var currentImagePathIs = optionImageIs.concat(imgeUrl);
            this.setState({
              optionImageIs: currentImagePathIs,
              currentImageIs: false,
              imagesTextOption: "",
            });
          } else {
            optionImageIs[imageTextOptionIndex] = {
              ...optionImageIs[imageTextOptionIndex],
              item: imagesTextOption,
              image: imagePath,
            };
            this.setState({
              optionImageIs,
              optionUploadImage: true,
            });
          }
        }
      })
      .catch((error) => {
        console.log("option image error--->", error);
        alert(`Error : ${error.response.status}`)
      });
  };

  handleformOnChange = (e, index) => {
    const { OptionInputs } = this.state;
    const { name, value } = e.target;
    if (name === "image") {
      const img = e.target.files[0];
      if (img.size < 2097152) {
        const formData = new FormData();
        formData.append("images", img);
        this.handleoptionImage(formData);
        this.setState({
          optionImgIndex: index,
          optionUploadImage: true,
        });
      } else {
        alert("Image size is more than 2 mb..");
      }
    } else {
      const list = [...OptionInputs];
      list[index][name] = value;
      this.setState({
        [name]: value,
        optionIndexIs: index,
      });
    }
  };

  /*--------------------------------upload options Image ------------------------- */

  /*-------------------------- update Collateral ------------------------------- */

  handleUpdateCollateral = () => {
    const current = this.state;

    const addCollateral = {
      _id: this.props.match.params.id.split(":").pop(),
      name: current.firstName,
      image: current.profileImage,
      about: current.aboutCollateral,
      pricing_unit: current.parentUnit,
      explain: "",
      type_question: current.typeQsIs,
      style_question: current.styleQuestionIs,
      essential_question: current.essentialQsIs,
      company_detail_question: current.companyQsIs,
      project_detail_question: current.projectQsIs,
      pcf_collateral: current.pcfSelectedItems,
      alternative_name: current.alternateNameIs,
      image_second: current.secondImage,
      unit: current.unit,
      unit_question: current.ubitQs,
      budget_heading: current.budgetHeading,
      deadline_heading: current.deadlineHeading,
      budget_subtext: current.budgetSubtext,
      deadline_subtext: current.deadlineSubtext,
      project_name_heading: current.projectNameHeading,
      project_description_heading: current.projectDescriptionHeading,
      project_name_subtext: current.projectNameSubtext,
      project_description_subtext: current.projectDescriptionSubtext,
      project_add_reference_link_heading: current.projectRefernceLinkHeading,
      project_add_reference_files_heading: current.projectReferencefilesHeading,
      project_add_reference_link_subtext: current.projectReferenceLinkSubtext,
      project_add_reference_files_subtext: current.projectReferencefilesSubtext,
      company_name_heading: current.companyNameHeading,
      company_name_subtext: current.companyNameSubtext,
      company_work_type_heading: current.companyWorkTypeHeading,
      company_work_type_subtext: current.companyWorkTypeSubtext,
      company_job_role_heading: current.companyJobRoleHeading,
      company_job_role_subtext: current.companyJObRoleSubtext,
      company_add_link_heading: current.companyLinkHeading,
      company_add_link_subtext: current.companyLinkSubtext,
      company_describes_heading: current.companydescribesHeading,
      company_describes_subtext: current.companydescribesSubtext,
      company_location_heading: current.companyLocationHeading,
      company_location_subtext: current.companyLocationSubtext,
      company_industry_heading: current.companyIndustryHeading,
      company_industry_subtext: current.companyIndustrySubtext,
      company_employees_heading: current.companyEmpHeading,
      company_employees_subtext: current.companyEmpSubtext,
      company_individual_location_heading: current.companyIndivualHeading,
      company_individual_location_subtext: current.companyIndivualSubtext,
      project_name_placeholder: current.projectNamePlaceholder,
      project_description_placeholder: current.projectdescPlaceholder,
      company_name_placeholder: current.companyNamePlaceholder,
      company_job_role_placeholder: current.companyJobRolePlaceholder,
      company_describes_placeholder: current.companydescPlaceholder,
      standard_project_unit_description: current.standardProject,
      unit_question_subtext: current.ubitQsSubtext,
      page_url :current.frontendUrl
    };

    if (this.validateForm()) {
      putAPI("/collateral/update-collateral", addCollateral)
        .then((res) => {          
          if (res.data.status === "1") {
            this.props.history.push("/collaterals");
          }
        })
        .catch((error) => {
          alert(`Error :${error.response.data}`)
        });
    }
  };

  /*-------------------------- update Collateral ------------------------------- */

  /* ---------------------- save all options ---------------------------------*/

  handleimageOptionsField = () => {
    const { optionUploadImage, optionImageIs } = this.state;
    if (optionUploadImage) {
      this.setState(
        {
          OptionInputs: optionImageIs,
          optionUploadImage: false,
        },
        () => {
          this.handleSaveOptions();
        }
      );
    }
  };

  handleSaveOptions = () => {
    const {
      styleQsList,
      styleQs,
      styleQuestionIs,
      OptionInputs,
      styleDropDownQs,
      projectList,
      projectDetailsQs,
      projectDropdownQs,
      projectQsIs,
      companyList,
      companyDetailsQs,
      companyQsIs,
      companyDropdownQs,
      typeQsIs,
      typeQsList,
      typeQs,
      typeDropdownQs,
      subtext,
      qsMandatory,
      ansPlaceholder,
      startRange,
      endRange,
      ansLimit,
    } = this.state;

    if (styleQsList) {
      if (styleQuestionIs.length) {
        alert("You can create one question at one time..");
        this.setState({
          styleQsList: false,
        });
      } else {
        var styleData = {
          type: styleQs,
          question: styleDropDownQs,
          subtext: subtext,
          option: OptionInputs,
        };
        const currentQsAns = styleQuestionIs.concat(styleData);
        this.setState({
          styleQuestionIs: currentQsAns,
          styleQsList: false,
        });
      }
    } else if (typeQsList) {
      if (typeQsIs.length) {
        alert("You can create one question at one time..");
        this.setState({
          typeQsList: false,
        });
      } else {
        styleData = {
          type: typeQs,
          question: typeDropdownQs,
          subtext: subtext,
          option: OptionInputs,
        };
        const currentQsAns = typeQsIs.concat(styleData);
        this.setState({
          typeQsIs: currentQsAns,
          typeQsList: false,
        });
      }
    } else if (projectList) {
      var projectData = {
        type: projectDetailsQs,
        question: projectDropdownQs,
        subtext: subtext,
        is_mandatory: Number(qsMandatory),
        ans_placeholder: ansPlaceholder,
        start_rang: startRange.length ? startRange : 0,
        end_rang: endRange.length ? endRange : 0,
        option: OptionInputs,
        ans_limit: ansLimit.length ? ansLimit : 0,
      };
      if (
        projectData.type !== "scale_range" ||
         Number(projectData.start_rang) < Number(projectData.end_rang)     
      ) {
        const currentQsAns = projectQsIs.concat(projectData);        
        this.setState({
          projectQsIs: currentQsAns,
          projectList: false,
          projectDetailsQs : ''
        });
      } else {
        alert("Start range will be smaller from End Range...");
        this.setState({
          projectList: false,
          projectDetailsQs: ''
        })
      }
    } else if (companyList) {
      const companyData = {
        type: companyDetailsQs,
        question: companyDropdownQs,
        is_mandatory: Number(qsMandatory),
        ans_placeholder: ansPlaceholder,
        start_rang: startRange.length ? startRange : 0,
        end_rang: endRange.length ? endRange : 0,
        option: OptionInputs,
        ans_limit: ansLimit.length ? ansLimit : 0,
        subtext: subtext,
      };

      if (
        companyData.type !== "scale_range" ||
        Number(companyData.start_rang) < Number(companyData.end_rang)
      ) {
        const currentQsAns = companyQsIs.concat(companyData);
        this.setState({
          companyQsIs: currentQsAns,
          companyList: false,
          companyDetailsQs: ''
        });
      } else {
        alert("Start range will be smaller from End Range...");
        this.setState({
          companyList: false,
          companyDetailsQs : ''
        })
      }
    }
    this.resetOptions();
  };

  /* ---------------------- save all options ---------------------------------*/

  /*----------------------------- edit options -------------------------------*/

  handleOptionEdit = (index, obj) => {
    // console.log("")
    this.setState({
      imageTextOptionIndex: index,
      imagesTextOption: obj.item,
      currentImageIs: true,
      textOptionEdit: true,
      selectedImagePath: obj.image,
      optionUploadImage: false,
    });
  };

  /*----------------------------- edit options -------------------------------*/

  /*-------------------- update options (image with text) ---------------------*/

  handleUpdateQsImage = () => {
    const { optionImageIs } = this.state;
    this.setState(
      {
        OptionInputs: optionImageIs,
      },
      () => {
        this.handleUpdateOptions();
      }
    );
  };

  handleImageWithTextOptionUpdate = () => {
    const {
      optionUploadImage,
      imageTextOptionIndex,
      imagesTextOption,
      optionImageIs,
      selectedImagePath,
    } = this.state;
    if (!optionUploadImage) {
      optionImageIs[imageTextOptionIndex] = {
        ...optionImageIs[imageTextOptionIndex],
        item: imagesTextOption,
        image: selectedImagePath,
      };
      this.setState({
        optionImageIs,
        optionUploadImage: true,
        textOptionEdit: false,
        imagesTextOption: "",
        selectedImagePath: "",
      });
    } else {
      this.setState({
        textOptionEdit: false,
        imagesTextOption: "",
        selectedImagePath: "",
      });
    }
  };

  /*-------------------- update options (image with text) ---------------------*/

  /*---------------------- cancel/(hide) form --------- */

  handleQsCancel = () => {
    this.setState({
      projectList: false,
      styleQsList: false,
      typeQsList: false,
      companyList: false,
      styleOptionEdit: false,
      projectOptionEdit: false,
      companyOptionEdit: false,
      typeOptionEdit: false,
    });
  };

  /*---------------------- cancel/(hide) form --------- */

  /*---------------------------- Update Options -----------------------*/

  handleUpdateOptions = () => {
    const {
      styleQs,
      styleQuestionIs,
      OptionInputs,
      styleDropDownQs,
      projectDetailsQs,
      projectDropdownQs,
      projectQsIs,
      companyDetailsQs,
      companyQsIs,
      companyDropdownQs,
      styleOptionIndex,
      styleOptionEdit,
      projectOptionEdit,
      projectIndex,
      companyOptionEdit,
      companyIndex,
      typeOptionEdit,
      typeQsIs,
      typeOptionIndex,
      typeQs,
      typeDropdownQs,
      subtext,
      qsMandatory,
      ansPlaceholder,
      startRange,
      endRange,
      ansLimit,
    } = this.state;

    if (styleOptionEdit) {
      styleQuestionIs[styleOptionIndex] = {
        ...styleQuestionIs[styleOptionIndex],
        type: styleQs,
        question: styleDropDownQs,
        option: OptionInputs,
        subtext: subtext,
      };
      this.setState({
        styleQuestionIs,
        styleQsList: false,
        styleOptionEdit: false,
      });
    } else if (typeOptionEdit) {
      typeQsIs[typeOptionIndex] = {
        ...typeQsIs[typeOptionIndex],
        type: typeQs,
        question: typeDropdownQs,
        option: OptionInputs,
        subtext: subtext,
      };
      this.setState({
        typeQsIs,
        typeQsList: false,
        typeOptionEdit: false,
      });
    } else if (projectOptionEdit) {
      projectQsIs[projectIndex] = {
        ...projectQsIs[projectIndex],
        type: projectDetailsQs,
        question: projectDropdownQs,
        option: OptionInputs,
        subtext: subtext,
        is_mandatory: Number(qsMandatory),
        ans_placeholder: ansPlaceholder,
        start_rang: startRange.length ? startRange : 0,
        end_rang: endRange.length ? endRange : 0,
        ans_limit: ansLimit.length ? ansLimit : 0,
      };
      if (projectDetailsQs !== "scale_range" || startRange < endRange) {
        this.setState({
          projectQsIs,
          projectList: false,
          projectOptionEdit: false,
        });
      } else {
        alert("Scale Range will be smaller from End Range...");
      }
    } else if (companyOptionEdit) {      
      if (companyDetailsQs !== "scale_range" || startRange < endRange) {       
        this.setState({
          companyQsIs,
          companyList: false,
          companyOptionEdit: false,
        });
      } else {       
        alert("Scale Range will be smaller from End Range....");
        this.setState({
          startRange : 0
        })
      }
      companyQsIs[companyIndex] = {
        ...companyQsIs[companyIndex],
        type: companyDetailsQs,
        question: companyDropdownQs,
        option: OptionInputs,
        subtext: subtext,
        is_mandatory: Number(qsMandatory),
        ans_placeholder: ansPlaceholder.length,
        start_rang: startRange !== 0 ? startRange : 0,
        end_rang: endRange !== 0 ? endRange : 0,
        ans_limit: ansLimit.length ? ansLimit : 0,
      };
    }
  };

  /*---------------------------- Update Options -----------------------*/

  /*------------------- append options --------------------------*/

  appendOption = () => {
    const { styleQs } = this.state;
    const { OptionInputs } = this.state;

    if (styleQs === "checkbox_with_images_with_text") {
      var list = [...OptionInputs, { image: "" }];
    } else {
      list = [...OptionInputs, { item: "" }];
    }
    this.setState({
      OptionInputs: list,
    });
  };

  /*------------------- append options --------------------------*/

  /*-------------------- remove Questions Option ---------------------------*/

  handleRemoveOption = (index) => {
    const { OptionInputs } = this.state;
    if (index !== -1) {
      const removeOption = OptionInputs.filter((_, i) => i !== index);
      this.setState({
        OptionInputs: removeOption,
      });
    }
  };

  handleRemoveOptionImage = (obj, index) => {
    const currentOption = obj.item === "other";
    const { optionImageIs } = this.state;
    if (currentOption) {
      this.setState({
        otherOption: false,
      });
    }
    if (index !== -1) {
      const removeImage = optionImageIs.filter((_, i) => i !== index);
      this.setState({
        optionImageIs: removeImage,
      });
    }
  };

  /*-------------------- remove Questions Option ---------------------------*/

  /* --------------------- Delete  question Listing --------------------*/

  handleStyleQsDelete = (index) => {
    const { styleQuestionIs } = this.state;
    if (index !== -1) {
      var removeStyleListing = styleQuestionIs.filter((_, i) => i !== index);
      this.setState({
        styleQuestionIs: removeStyleListing,
        styleQs: "",
      });
    }
  };

  handleTypeQsDelete = (index) => {
    const { typeQsIs } = this.state;
    if (index !== -1) {
      var removeTypeListing = typeQsIs.filter((_, i) => i !== index);
      this.setState({
        typeQsIs: removeTypeListing,
        typeQs: "",
      });
    }
  };

  handleProjectQsDelete = (index) => {
    const { projectQsIs } = this.state;
    if (index !== -1) {
      var removeProjectListing = projectQsIs.filter((_, i) => i !== index);
      this.setState({
        projectQsIs: removeProjectListing,
        projectDetailsQs: "",
      });
    }
  };

  handleCompanyQsDelete = (index) => {
    const { companyQsIs } = this.state;
    if (index !== -1) {
      var removeCompanyListing = companyQsIs.filter((_, i) => i !== index);
      this.setState({
        companyQsIs: removeCompanyListing,
        companyDetailsQs: "",
      });
    }
  };

  /* --------------------- Delete  question Listing --------------------*/

  /*---------------------------- AlterNate Name ----------------------*/

  handleAlternateName = (e) => {
    e.preventDefault();
    const { alternateNameIs, alternativeName } = this.state;
    const currentExist = alternateNameIs.some(
      (i) => i.name === alternativeName
    );
    if (!currentExist) {
      const currentAlterName = [...alternateNameIs, { name: alternativeName }];
      this.setState({
        alternateNameIs: currentAlterName,
        alternativeName: "",
      });
    } else {
      alert("This name is already selected...");
      this.setState({
        alternativeName: "",
      });
    }
  };

  handleDeleteAlternateName = (index) => {
    const { alternateNameIs } = this.state;
    if (index !== -1) {
      var removeAlterName = alternateNameIs.splice(0, index);
      this.setState({
        alternateNameIs: removeAlterName,
      });
    }
  };

  /*---------------------------- AlterNate Name ----------------------*/

  /*--------------------------- Edit all Question --------------------------------*/

  handleTypeEditQues = (obj, index) => {
    const current = obj.option.some((val) => val.item === "other");

    if (current) {
      this.setState({
        otherOption: true,
      });
    }
    if (
      obj.type === "checkbox_with_images_with_text" ||
      obj.type === "radio_button_with_images_with_text"
    ) {
      this.setState({
        optionImageIs: obj.option,
      });
    } else {
      this.setState({
        OptionInputs: obj.option,
      });
    }

    this.setState({
      typeQs: obj.type,
      typeDropdownQs: obj.question,
      typeQsList: true,
      subtext: obj.subtext,
      typeOptionIndex: index,
      typeOptionEdit: true,
      projectList: false,
      companyList: false,
      styleQsList: false,
    });
  };

  handleStyleEditQues = (obj, index) => {
    const current = obj.option.some((val) => val.item === "other");

    if (current) {
      this.setState({
        otherOption: true,
      });
    }
    if (
      obj.type === "checkbox_with_images_with_text" ||
      obj.type === "radio_button_with_images_with_text"
    ) {
      this.setState({
        optionImageIs: obj.option,
      });
    } else {
      this.setState({
        OptionInputs: obj.option,
      });
    }
    this.setState({
      styleQs: obj.type,
      styleDropDownQs: obj.question,
      styleQsList: true,
      styleOptionIndex: index,
      subtext: obj.subtext,
      styleOptionEdit: true,
      projectList: false,
      companyList: false,
      typeQsList: false,
    });
  };

  handleProjectEditQues = (obj, index) => {
    const current = obj.option.some((val) => val.item === "other");

    if (current) {
      this.setState({
        otherOption: true,
      });
    }
    if (
      obj.type === "checkbox_with_images_with_text" ||
      obj.type === "radio_button_with_images_with_text"
    ) {
      this.setState({
        optionImageIs: obj.option,
      });
    } else {
      this.setState({
        OptionInputs: obj.option,
      });
    }
    this.setState({
      projectDetailsQs: obj.type,
      projectDropdownQs: obj.question,
      projectOptionEdit: true,
      projectIndex: index,
      subtext: obj.subtext,
      projectList: true,
      styleQsList: false,
      companyList: false,
      typeQsList: false,
      qsMandatory: obj.is_mandatory.toString(),
      ansLimit: obj.ans_limit,
      ansPlaceholder: obj.ans_placeholder,
      startRange: obj.start_rang,
      endRange: obj.end_rang,
    });    
  };

  handleCompanyEditQues = (obj, index) => {
    const current = obj.option.some((val) => val.item === "other");
    if (current) {
      this.setState({
        otherOption: true,
      });
    }
    if (
      obj.type === "checkbox_with_images_with_text" ||
      obj.type === "radio_button_with_images_with_text"
    ) {
      this.setState({
        optionImageIs: obj.option,
      });
    } else {
      this.setState({
        OptionInputs: obj.option,
      });
    }
    this.setState({
      companyDetailsQs: obj.type,
      companyDropdownQs: obj.question,
      companyIndex: index,
      subtext: obj.subtext,
      companyOptionEdit: true,
      companyList: true,
      styleQsList: false,
      projectList: false,
      typeQsList: false,
      qsMandatory: obj.is_mandatory.toString(),
      ansLimit: obj.ans_limit,
      ansPlaceholder: obj.ans_placeholder,
      startRange: obj.start_rang,
      endRange: obj.end_rang,
    });
  };

  /*--------------------------- Edit all Question --------------------------------*/

  /*-------------------------------- Question Other Option ----------------------------*/

  handleOtherOptions = () => {
    const { optionImageIs } = this.state;
    const otherData = { item: "other", image: otherOptionDefaultImageUrl };
    const currentArr = optionImageIs.concat(otherData);
    this.setState({
      optionImageIs: currentArr,
      otherOption: true,
      optionUploadImage: true,
    });
  };

  /*-------------------------------- Question Other Option ----------------------------*/

  /*-------------- get Collateral List ---------------*/

  handleEditCollateral = (id) => {
    this.props.$getCollateral(id);
  };

  /*-------------- get Collateral List ---------------*/

  componentDidMount() {

    this.props.handleClients.handlepathName("/collaterals");
    const collateralParam = this.props.match.params.id.split(":").pop();      
    if (collateralParam && collateralParam !== "approved" && collateralParam !== "negociations") {
      this.handleEditCollateral(collateralParam);
    }else if(collateralParam === "approved" || collateralParam === "negociations"){
      this.props.history.push('/collaterals')
    }
    if (this.state.artistBasic) {
      this.props.handleClients.mainIndexstate({ creatorIs: true });
    } else {
      this.props.handleClients.mainIndexstate({ creatorIs: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { uploadLoadingIs, secondImageLoader, pcfActive } = this.state;
    const collateralParam = this.props.match.params.id.split(":").pop();
    const uploadDataIs = nextProps._collateralUploadPhoto?.collateralPic;
    const pcfData = nextProps._collateralPCFName?.pcfName;
    const collateralGettingData = nextProps?._getCollateral?.collateralDataIs;
    const dataIs = collateralGettingData?.collateral;   
    if (pcfData?.status === "1") {
      this.setState({
        pcfDropdownData: pcfData.data,
      });
    }
    if (
      collateralGettingData &&
      collateralGettingData.status === "1" &&
      collateralParam &&
      !uploadLoadingIs &&
      !secondImageLoader &&
      !pcfActive
    ) {
      this.setState({
        firstName: dataIs.name,
        profileImage: dataIs.image,
        aboutCollateral: dataIs.about,
        parentUnit: dataIs.pricing_unit,
        pcfSelectedItems:
          collateralGettingData.parent_creative_field_collateral,

        styleQuestionIs: collateralGettingData.Collateral_styl_question,
        essentialQsIs: collateralGettingData.collateral_essentia_question,
        projectQsIs: collateralGettingData.collateral_project_Detail_question,
        companyQsIs:
          collateralGettingData.collateral_company_detail_questionata,
        typeQsIs: collateralGettingData.Collateral_type_question,

        alternateNameIs: collateralGettingData.collatera_alternative_name,
        collateralEditIs: true,
        LoadingIs: false,

        budgetHeading: dataIs.budget_heading,
        budgetSubtext: dataIs.budget_subtext,
        deadlineHeading: dataIs.deadline_heading,
        deadlineSubtext: dataIs.deadline_subtext,
        projectNameHeading: dataIs.project_name_heading,
        projectDescriptionHeading: dataIs.project_description_heading,
        projectNameSubtext: dataIs.project_name_subtext,
        projectDescriptionSubtext: dataIs.project_description_subtext,
        projectRefernceLinkHeading: dataIs.project_add_reference_link_heading,
        projectReferenceLinkSubtext: dataIs.project_add_reference_link_subtext,
        projectReferencefilesHeading:
          dataIs.project_add_reference_files_heading,
        projectReferencefilesSubtext:
          dataIs.project_add_reference_files_subtext,
        companyNameHeading: dataIs.company_name_heading,
        companyNameSubtext: dataIs.company_name_subtext,
        companyWorkTypeHeading: dataIs.company_work_type_heading,
        companyWorkTypeSubtext: dataIs.company_work_type_subtext,
        companyJobRoleHeading: dataIs.company_job_role_heading,
        companyJObRoleSubtext: dataIs.company_job_role_subtext,
        companyLinkHeading: dataIs.company_add_link_heading,
        companyLinkSubtext: dataIs.company_add_link_subtext,

        companydescribesHeading: dataIs.company_describes_heading,
        companydescribesSubtext: dataIs.company_describes_subtext,
        companyLocationHeading: dataIs.company_location_heading,
        companyLocationSubtext: dataIs.company_location_subtext,
        companyIndustryHeading: dataIs.company_industry_heading,
        companyIndustrySubtext: dataIs.company_industry_subtext,
        companyEmpHeading: dataIs.company_employees_heading,
        companyEmpSubtext: dataIs.company_employees_subtext,
        companyIndivualHeading: dataIs.company_individual_location_heading,
        companyIndivualSubtext: dataIs.company_individual_location_subtext,
        projectNamePlaceholder: dataIs.project_name_placeholder,
        projectdescPlaceholder: dataIs.project_description_placeholder,
        companyNamePlaceholder: dataIs.company_name_placeholder,
        companyJobRolePlaceholder: dataIs.company_job_role_placeholder,
        companydescPlaceholder: dataIs.company_describes_placeholder,
        secondImage: dataIs.image_second,
        unit: dataIs.unit,
        ubitQs: dataIs.unit_question,
        standardProject: dataIs.standard_project_unit_description,
        ubitQsSubtext: dataIs.unit_question_subtext,
        frontendUrl : dataIs?.page_url
      });
    }
    if (uploadDataIs && uploadLoadingIs) {
      this.setState({
        secondImageLoader: false,
        profileImage: uploadDataIs.data.name,
        uploadLoadingIs: false,
      });
    }
    if (uploadDataIs && secondImageLoader) {
      this.setState({
        uploadLoadingIs: false,
        secondImage: uploadDataIs.data.name,
        secondImageLoader: false,
      });
    }
    
  }

  render() {
    const quesFunc = {
      handleOnchange: this.handleOnchange,
      handleTypeEditQues: this.handleTypeEditQues,
      handleTypeQsDelete: this.handleTypeQsDelete,
      handleStyleEditQues: this.handleStyleEditQues,
      handleStyleQsDelete: this.handleStyleQsDelete,
      handleProjectEditQues: this.handleProjectEditQues,
      handleProjectQsDelete: this.handleProjectQsDelete,
      handleCompanyEditQues: this.handleCompanyEditQues,
      handleCompanyQsDelete: this.handleCompanyQsDelete,
      handleformOnChange: this.handleformOnChange,
      handleRemoveOptionImage: this.handleRemoveOptionImage,
      handleQsCancel: this.handleQsCancel,
      handleUpdateQsImage: this.handleUpdateQsImage,
      handleUpdateOptions: this.handleUpdateOptions,
      handleimageOptionsField: this.handleimageOptionsField,
      handleSaveOptions: this.handleSaveOptions,
      appendOption: this.appendOption,
      handleUpdateCollateral: this.handleUpdateCollateral,
      handleAddCollateral: this.handleAddCollateral,
      handleOptionEdit: this.handleOptionEdit,
      handleImageWithTextOptionUpdate: this.handleImageWithTextOptionUpdate,
      handleOtherOptions: this.handleOtherOptions,
      handleRemoveOption: this.handleRemoveOption,

      handleDeleteAlternateName: this.handleDeleteAlternateName,
      handleDeleteParentCreativeField: this.handleDeleteParentCreativeField,
      handleSelectedListItem: this.handleSelectedListItem,
      handleUploadImage: this.handleUploadImage,
      handleAlternateName: this.handleAlternateName,
      setCollateralState : this.setCollateralState
    };

    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/collateralsQuestions/questions/:id/"
            render={(props) => (
              <CollateralQuestion
                {...props}
                {...this.state}
                handlefunc={quesFunc}
              />
            )}
          />
          <Route
            path="/collateralsQuestions/creator/:id"
            render={(props) => <CollateralCreator {...props} />}
          />
          <Route
            path="/collateralsQuestions/starred/:id"
            render={(props) => <CollateralStarred {...props} />}
          />
          <Route
            path="/collateralsQuestions/projects/:id"
            render={(props) => <CollateralProjects {...props} />}
          />
          <Route
            path="/collateralsQuestions/transactions/:id"
            render={(props) => <CollateralTransactions {...props} />}
          />
          <Route
            path="/collateralsQuestions/history/:id"
            render={(props) => <CollateralHistory {...props} />}
          />
          <CollateralBasicDetailsForm
            {...this.props}
            {...this.state}
            handlefunc={quesFunc}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapState = ({
  collateralUploadPhoto,
  collateralPCFName,
  getCollateral,
}) => {
  return {
    _collateralUploadPhoto: collateralUploadPhoto,
    _collateralPCFName: collateralPCFName,
    _getCollateral: getCollateral,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $collateralUploadPhoto: (values) =>
    dispatchEvent(collateralUploadPhoto(values)),
  $collateralPCFName: (values) => dispatchEvent(collateralPCFName(values)),
  $getCollateral: (values) => dispatchEvent(getCollateral(values)),
});
export default connect(
  mapState,
  mapDispatch
)(CollateralBasicDetails);
