import React, { Component } from "react";
import { connect } from "react-redux";
import { transactionList } from "../../../../store/action/transaction.action";

class ErrorApi extends Component {

  state = {
    showErrorModal: false,
  };

  handleCloseModal = () => {
    this.setState({
      showErrorModal : false
    })
    localStorage.removeItem("adminLogin");
    this.props.history.push("/");
    window.location.reload();
  }

  componentDidMount() {
    this.handleCloseModal();    
    this.setState({
      showErrorModal: true,
    });
  }

  render() {
    // const { showErrorModal } = this.state;    
    return (
      <React.Fragment>
        {/* {showErrorModal && (
          <div
            className="modal"
            id="exampleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ display: "block" }}
          >          
            <div
              className="alert alert-danger alert-dismissible fade show "
              role="alert"
              style={{ position: "absolute", right: "10px", top: "10px", width: "20%" }}
            >
              <strong>Invalid Token!!!</strong>
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={this.handleCloseModal}>×</span>
              </button>
            </div>
          </div>
        )} */}
      </React.Fragment>
    );
  }
}
const mapState = ({ transactionList }) => {
  return {
    _transactionList: transactionList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $transactionList: (values) => dispatchEvent(transactionList(values)),
});
export default connect(mapState, mapDispatch)(ErrorApi);
