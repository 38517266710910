import React, { Component } from "react";
import { connect } from "react-redux";
import {
  // artistDataList,
  // artistApprovalList,
  pendingCreatorApproval,
  // artistUpdateInfo,
  // clientRejectsCreatorList,
} from "../../../../store/action/clientList.action";
import Table from "./Table";
import ReviewStatusModal from "../../Artist/ReviewStatusModal";
import ReactPaginate from "react-paginate";
import ErrorApi from "../../Common/Error/ErrorApi";
// import {
//   pendingCollateral,
//   deleteCollateralList,
// } from "../../../../store/action/collateralList.action";
// import {
//   pendingProjectList,
//   projectReviewList,
// } from "../../../../store/action/projectList.action";
// import {
//   callBackRequestList,
//   transactionApprovalList,
//   inCompleteBriefList,
//   creatorRejectsBriefList,
//   closeProjectRequestList,
// } from "../../../../store/action/transaction.action";

class PendingCreator extends Component {
  state = {
    statusEmailIs: "",
    createArtistIs: false,
    artistIs: [],
    artistList: 1,
    ListLoading: false,
    showAction: false,
    showActionIndex: "",
    showReviewPopUp: false,
    artistId: "",
    profileReviewStatus: "",
    totalArtistIs: "",
    reviewStatus: false,
    artistApproval: false,
    totalPages: 0,

    firstname: "",
    lastname: "",
    username: "",
    email: "",
    sortIs: -1,
    sortFlagIs: false,
  };

  setArtistState = (stateObj) => {
    this.setState(stateObj);
  };

  handleShowAction = (index) => {
    const { showAction } = this.state;
    this.setState({
      showAction: !showAction,
      showActionIndex: index,
    });
  };

  handleHideAction = () => {
    this.setState({
      showAction: true,
    });
  };

  handleSorting = () => {
    this.setState({ sortFlagIs: !this.state.sortFlagIs }, () => {
      const { artistList, firstname, lastname, username, email } = this.state;
      let artist = null;
      if (this.state.sortFlagIs) {
        artist = {
          page: artistList,
          firstname: firstname,
          lastname: lastname,
          username: username,
          email: email,
          sortData: 1,
        };
      } else {
        artist = {
          page: artistList,
          firstname: firstname,
          lastname: lastname,
          username: username,
          email: email,
          sortData: -1,
        };
      }
      this.setState({ sortIs: this.state.sortFlagIs ? 1 : -1 });
      this.props.$pendingCreatorApproval(artist);
    });
  };

  handleReviewStatus = (obj, pStatus) => {
    if (pStatus === "accepted") {
      this.setState({
        profileReviewStatus: 2,
        reviewStatus: true,
      });
    } else if (pStatus === "rejected") {
      this.setState({
        profileReviewStatus: 3,
        reviewStatus: false,
      });
    }
    this.setState({
      showReviewPopUp: true,
      artistId: obj._id,
    });
  };

  handleArtistPagination = () => {
    const {
      artistList,
      firstname,
      lastname,
      username,
      email,
      sortIs,
    } = this.state;
    this.setState({
      ListLoading: true,
    });
    let artist = {
      page: artistList,
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
      sortData: sortIs,
    };
    this.props.$pendingCreatorApproval(artist);
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        const {
          artistList,
          firstname,
          lastname,
          username,
          email,
          sortIs,
        } = this.state;
        let artist = {
          page: artistList,
          firstname: firstname,
          lastname: lastname,
          username: username,
          email: email,
          sortData: sortIs,
        };
        this.props.$pendingCreatorApproval(artist);
      }
    );
  };

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    const { firstname, lastname, username, email, sortIs } = this.state;
    let artist = {
      page: selectedData,
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
      sortData: sortIs,
    };
    this.props.$pendingCreatorApproval(artist);
  };

  // handleAllApi = () => {
    // const Data = { page: 1, sortData: -1 };
    // this.props.$pendingCollateral(Data);
    // const dataIs = { page: 1, sortIs: -1 };
    // this.props.$deleteCollateralList(dataIs);
    // let artist = {
    //   page: 1,
    //   firstname: "",
    //   lastname: "",
    //   username: "",
    //   email: "",
    //   sort: -1,
    // };
    // this.props.$artistUpdateInfo(artist);
    // const dataList = { page: 1, sortIs: -1 };
    // this.props.$pendingProjectList(dataList);
    // this.props.$projectReviewList(dataList);
    // const currentData = { pageIs: 1, sortIs: -1 };
    // this.props.$callBackRequestList(currentData);
    // this.props.$transactionApprovalList(currentData);
    // this.props.$inCompleteBriefList(dataList);
    // this.props.$creatorRejectsBriefList(dataList);
    // this.props.$clientRejectsCreatorList(dataList);
    // this.props.$closeProjectRequestList(dataList)
  // };

  componentDidMount() {
    this.props.handleClients.handlepathName("/approvals");
    this.setState({
      createArtistIs: true,
      artistActive: false,
      artistApproval: true,
    });
    this.handleArtistPagination();
    this.props.handleClients.mainIndexstate({
      profileUpdates: false,
      artistApprovalIs: true,
    });

    // this.handleAllApi();
  }

  componentWillReceiveProps(nextProps) {
    const artistListApproval =
      nextProps._pendingCreatorApproval.pendingListApprIs;
    if (artistListApproval) {
      this.setState({
        artistIs: artistListApproval.users,
        ListLoading: false,
        totalPages: artistListApproval.totalPages,
      });
    }
  }

  render() {
    const { showReviewPopUp } = this.state;

    const artistFunc = {
      handleOnChange: this.handleOnChange,
      handleShowAction: this.handleShowAction,
      handleReviewStatus: this.handleReviewStatus,
      handleSorting: this.handleSorting,
    };

    return (
      <React.Fragment>
        <section className="creator">
          <div className="container-fluid mx-auto">
            {this.props._pendingCreatorApproval.pendingListAppErrIs ? (
              <ErrorApi {...this.props} />
            ) : (
              <section className="client">
                <div className="container-fluid mx-auto">
                  {/************Artist data Table ***************/}
                  <Table
                    {...this.state}
                    {...this.props}
                    handleArtist={artistFunc}
                  />

                  {/****************** Pagination ********************/}

                  {this.state.totalPages > 0 && (
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.totalPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  )}
                </div>
              </section>
            )}
            {/*----------------------Review Popup -------------------------*/}
            {showReviewPopUp && (
              <ReviewStatusModal
                setArtistState={this.setArtistState}
                {...this.state}
              />
            )}
          </div>
        </section>
      </React.Fragment>
    );
  }
}
const mapState = ({
  // artistDataList,
  // artistApprovalList,
  pendingCreatorApproval,
  // pendingCollateral,
  // deleteCollateralList,
  // artistUpdateInfo,
  // pendingProjectList,
  // projectReviewList,
  // callBackRequestList,
  // transactionApprovalList,
  // inCompleteBriefList,
  // creatorRejectsBriefList,
  // clientRejectsCreatorList,
  // closeProjectRequestList,
}) => {
  return {
    // _artistDataList: artistDataList,
    // _artistApprovalList: artistApprovalList,
    _pendingCreatorApproval: pendingCreatorApproval,
    // _pendingCollateral: pendingCollateral,
    // _deleteCollateralList: deleteCollateralList,
    // _artistUpdateInfo: artistUpdateInfo,
    // _pendingProjectList: pendingProjectList,
    // _projectReviewList: projectReviewList,
    // _callBackRequestList: callBackRequestList,
    // _transactionApprovalList: transactionApprovalList,
    // _inCompleteBriefList: inCompleteBriefList,
    // _creatorRejectsBriefList: creatorRejectsBriefList,
    // _clientRejectsCreatorList: clientRejectsCreatorList,
    // _closeProjectRequestList: closeProjectRequestList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  // $artistDataList: (values) => dispatchEvent(artistDataList(values)),
  // $artistApprovalList: (values) => dispatchEvent(artistApprovalList(values)),
  $pendingCreatorApproval: (values) => dispatchEvent(pendingCreatorApproval(values)),
  // $pendingCollateral: (values) => dispatchEvent(pendingCollateral(values)),
  // $deleteCollateralList: (values) =>
  //   dispatchEvent(deleteCollateralList(values)),
  // $artistUpdateInfo: (values) => dispatchEvent(artistUpdateInfo(values)),
  // $pendingProjectList: (values) => dispatchEvent(pendingProjectList(values)),
  // $projectReviewList: (values) => dispatchEvent(projectReviewList(values)),
  // $callBackRequestList: (values) => dispatchEvent(callBackRequestList(values)),
  // $transactionApprovalList: (values) =>
  //   dispatchEvent(transactionApprovalList(values)),
  // $inCompleteBriefList: (values) => dispatchEvent(inCompleteBriefList(values)),
  // $creatorRejectsBriefList: (values) =>
  //   dispatchEvent(creatorRejectsBriefList(values)),
  // $clientRejectsCreatorList: (values) =>
  //   dispatchEvent(clientRejectsCreatorList(values)),
  // $closeProjectRequestList: (values) =>
  //   dispatchEvent(closeProjectRequestList(values)),
});
export default connect(mapState, mapDispatch)(PendingCreator);
