import React,{ Component } from "react";
import { Switch, Route } from "react-router";
import PendingCreator from "./Creator/PendingCreator";
import PendingCollateral from '../../Autorized/Approvals/Collateral/PendingCollateral';
import CreatorProfileUpdate from '../../Autorized/Approvals/CreatorProfile/CreatorProfileUpdate';
import ProjectUpload from '../../Autorized/Approvals/ProjectUpload/ProjectUpload';
import ProjectReview from '../../Autorized/Approvals/ProjectReview/ProjectReview';
import CallBackRequest from '../../Autorized/Approvals/CallBackRequest/CallBackRequest';
import TransactionApproval from '../../Autorized/Approvals/Transactions/TransactionApproval';
import CollateralDeletion from '../../Autorized/Approvals/CollateralDeletion/CollateralDeletion';
import InCompleteBrief from '../../Autorized/Approvals/InCompleteBrief/InCompleteBrief';
import CreatorRejectsBrief from '../../Autorized/Approvals/CreatorRejectsBrief/CreatorRejectsBrief';
import ClientRejectsCreator from '../../Autorized/Approvals/ClientRejectsCreator/ClientRejectsCreator';
import ViewPendingCollateral from '../../Autorized/Approvals/Collateral/ViewPendingCollateral';
import ViewProjectReview from '../../Autorized/Approvals/ProjectReview/ViewProjectReview';
import ViewCallBackRequest from '../../Autorized/Approvals/CallBackRequest/ViewCallBackRequest';
import ViewCreatorRejectsBrief from '../../Autorized/Approvals/CreatorRejectsBrief/ViewCreatorRejectsBrief';
import CloseBriefRequest from "../../Autorized/Approvals/CloseBriefRequest/CloseBriefRequest";
import CloseProjectRequest from '../../Autorized/Approvals/CloseProjectRequest/CloseProjectRequest';

export default class ApprovalsRoute extends Component{

    componentDidMount(){
        this.props.handleClients.handlepathName("/approvals");
    }

    render(){
        return(
            <React.Fragment>
                <Switch>
                    <Route path="/approvals/projectCloseRequest" render={(props)=> <CloseProjectRequest {...props}/>}/> 
                    <Route path="/approvals/briefCloseRequest" render={(props)=> <CloseBriefRequest {...props}/>}/> 
                    <Route path="/approvals/ViewCreatorReject/:id" render={(props)=> <ViewCreatorRejectsBrief {...props}/>}/> 
                    <Route path="/approvals/ViewCallBackRequest/:id" render={(props)=> <ViewCallBackRequest {...props}/>}/>
                    <Route path="/approvals/ViewProjectReview/:id" render={(props)=> <ViewProjectReview {...props} />}/>
                    <Route path="/approvals/PendingViewCollateral/:id" render={(props)=> <ViewPendingCollateral {...props} />}/>  
                    <Route path="/approvals/ClientRejectsCreator" render={(props)=> <ClientRejectsCreator {...props} />}/> 
                    <Route path="/approvals/CreatorRejectsBrief" render={(props)=> <CreatorRejectsBrief {...props} />}/>
                    <Route path="/approvals/InCompleteBrief" render={(props)=> <InCompleteBrief {...props} />}/>    
                    <Route path="/approvals/CollateralDeletion" render={(props)=> <CollateralDeletion {...props} />}/>
                    <Route path="/approvals/TransactionApproval" render={(props)=> <TransactionApproval {...props} />}/>
                    <Route path="/approvals/CallBackRequest" render={(props)=> <CallBackRequest {...props}/>}/>
                    <Route path="/approvals/ProjectReview" render={(props)=> <ProjectReview {...props}/>}/>
                    <Route path="/approvals/ProjectUpload" render={(props)=> <ProjectUpload {...props} />}/> 
                    <Route path="/approvals/PendingCreatorProfile" render={(props)=> <CreatorProfileUpdate {...props} />}/>
                    <Route path="/approvals/PendingCollateral" render={(props)=> <PendingCollateral {...props} />}/>  
                    <PendingCreator  {...this.props}/>
                </Switch>
            </React.Fragment>
        )
    }
}