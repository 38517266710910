import {
  TRANSACTIONS_LIST_SUCCESS,
  TRANSACTIONS_DATA_SUCCESS,
  TRANSACTIONS_STATUS_SUCCESS,
  TRANSACTIONS_LIST_ERROR,
  ACTIVE_TRANSACTIONS_SUCCESS,
  ACTIVE_TRANSACTIONS_ERROR,
  PENDING_TRANSACTIONS_SUCCESS,
  PENDING_TRANSACTIONS_ERROR,
  INCOMPLETE_TRANSACTIONS_SUCCESS,
  INCOMPLETE_TRANSACTIONS_ERROR,
  INCOMPLETE_HIGH_TRANSACTIONS_SUCCESS,
  INCOMPLETE_HIGH_TRANSACTIONS_ERROR,
  CLOSE_PROJECT_SUCCESS,
  COMPLETE_PROJECT_SUCCESS,
  CLOSE_TRANSACTION_LIST_SUCCESS,
  COMPLETE_PROJECT_ERROR,
  CLOSE_PROJECT_ERROR,
  DONT_LIKE_TRANSACTION_LIST_SUCCESS,
  DONT_LIKE_TRANSACTION_LIST_ERROR,
  CHOOSE_CREATOR_SUCCESS,
  TRANSCATION_APPROVAL_LIST_SUCCESS,
  CALL_BACK_REQUEST_SUCCESS,
  CALL_BACK_DATA_SUCCESS,
  CALL_BACK_SEARCH_CREATOR_SUCCESS,
  RECOMMENDED_CREATOR_SUCCESS,
  INCOMPLETE_BRIEF_LIST_SUCCESS,
  CRETAOR_REJECTS_BRIEF_LIST_SUCCESS,
  REJECTS_CREATOR_DATA_SUCCESS,
  CLEAR_BRIEF_LIST_SUCCESS,
  CALL_BACK_REQUEST_ERROR,
  TRANSCATION_APPROVAL_LIST_ERROR,
  INCOMPLETE_BRIEF_LIST_ERROR,
  CRETAOR_REJECTS_BRIEF_LIST_ERROR,
  CLOSED_BRIEF_PROJECT_SUCCESS,
  CLOSED_BRIEF_PROJECT_ERROR,
  REJECTED_BRIEF_LIST_SUCCESS,
  REJECTED_BRIEF_LIST_ERROR,
  DELETE_PROJECT_LIST_SUCCESS,
  DELETE_BRIEF_LIST_SUCCESS,
  DELETE_BRIEF_LIST_ERROR,
  DELETE_PROJECT_ALL_LIST_SUCCESS,
  DELETE_PROJECT_ALL_LIST_ERROR,
  CLOSED_BRIEF_ALL_LIST_SUCCESS,
  CLOSED_BRIEF_ALL_LIST_ERROR,
  CLOSE_BRIEF_REQUEST_LIST_SUCCESS,
  CLOSE_BRIEF_REQUEST_ERR,
  CLOSE_PROJECT_REQUEST_LIST_SUCCESS,
  CLOSE_PROJECT_REQUEST_ERR,
  COMPLETED_MILES_SUCCESS,
  MILESTONE_COMMENT_SUCCESS,
  MILESTONE_APPROVED_SUCCESS,
  ADD_MULTIPLE_CREATOR_SUCCESS,
  DELETE_PENDING_CREATOR_SUCCESS,
  OFFLINE_PAYMENT_SUCCESS,
  UPDATE_TRANSCATIONS_SUCCESS,
  APPROVED_PROPOSAL_SUCCESS,
  APPROVED_PROPOSAL_JOB_SUCCESS,
  REQUEST_CHANGE_PROPOSAL_SUCCESS,
  EDIT_CLIENT_SUCCESS,
  MANAGER_DETAILS_SUCCESS,
  INACTIVE_BRIEF_SUCCESS,
  INACTIVE_BRIEF_ERROR,
  INACTIVE_PROJECT_SUCCESS,
  INACTIVE_PROJECT_ERR,
  INACT_BRIEF_FOR_AM_SUCCESS,
  INACT_BRIEF_FOR_AM_ERR,
  TRANSACTIONS_COUNT_SUCCESS,
  TRANSACTIONS_COUNT_ERROR,
  RESET_TRANSACTION_LIST
} from "../type.action";

const initState = {
  LoadingError: false,
  transactionDetails: "",
  transactionData: "",
  postProjectStatus: "",

  transactionListingError: "",
  activeList: "",
  activeProErrIs: "",
  pendingList: "",
  pendingListErrIs: "",
  incompleteList: "",
  incompleteHighList: "",
  closeProjectIs: "",
  completeTransactionsIs: "",
  closedTransactionIs: "",
  completeProjectErrIs: "",
  closedProjectErrIs: "",
  dontLikeListIs: "",
  dontLikeError: "",
  chooseCreator: "",
  transactionApprIs: "",
  callBackRequestIs: "",
  callBackDataIs: "",
  searchCreatorIs: "",
  recommendIs: "",
  inCompleteBriefIs: "",
  rejectsCreatorListIs: "",
  rejectionDataUIs: "",
  clearListIs: "",
  callBackErrIs: "",
  transactionAppListErrIs: "",
  incompleteBriefListErrIs: "",
  ceatorRejectsErrIs: "",
  closedCriefProjectIs: "",
  closedBriefProjectErrIs: "",
  rejectedBriefListIs: "",
  rejectedBriefListErrIs: "",
  deleteProjectIs: "",
  deletedBriefsIs: "",
  deleteBriefsErrIs: "",
  deleteProjectsIs: "",
  deleteProjectErrIs: "",
  closedBriefAll: "",
  closedBriefAllErr: "",
  closedBriefRequestIs: "",
  closedBriefRequestErrIs: "",
  projectCloseReq: "",
  projectCloseReqErr: "",
  compMilesIs: "",
  milesCommentIs: "",
  approvedMilesIs: "",
  allCreatorIs: "",
  deleteCreatorIs: "",
  paymentIs: "",
  updateDetailsIs: "",
  approvedProposal: "",
  approvedProposalJob:"",
  reqChangeIs: "",
  editClientIs: "",
  managerDetailsIs: "",
  inactiveBriefData : "",
  inactiveBriefErr : "",
  inactiveProjectData : '',
  inactiveProjectErr : "",
  inActBriefData : "",
  inActBriefErr : "",
  transactionsCount:"",
  transactionsCountErr:""
};

const reducers = (state = initState, { type, values, error }) => {
  switch (type) {
    case TRANSACTIONS_LIST_SUCCESS:
      return {
        ...state,
        transactionDetails: values,
        transactionListingError: "",
        LoadingError: false,
      };
    case TRANSACTIONS_LIST_ERROR:
      return {
        ...state,
        transactionListingError: error,
        LoadingError: true,
      };
    case TRANSACTIONS_DATA_SUCCESS:
      return {
        ...state,
        transactionData: values,
        LoadingError: false,
      };
    case TRANSACTIONS_STATUS_SUCCESS:
      return {
        ...state,
        postProjectStatus: values,
        LoadingError: false,
      };
    case ACTIVE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        activeList: values,
      };
    case ACTIVE_TRANSACTIONS_ERROR:
      return {
        ...state,
        activeProErrIs: error,
        LoadingError: true,
      };
    case PENDING_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        pendingList: values,
      };
    case PENDING_TRANSACTIONS_ERROR:
      return {
        ...state,
        pendingListErrIs: error,
        LoadingError: true,
      };
    case INCOMPLETE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        incompleteList: values,
      };
    case INCOMPLETE_TRANSACTIONS_ERROR:
      return {
        ...state,
        incmplteListErrIs: error,
        LoadingError: true,
      };
    case INCOMPLETE_HIGH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        incompleteHighList: values,
      };
    case INCOMPLETE_HIGH_TRANSACTIONS_ERROR:
      return {
        ...state,
        incmplteHighListErrIs: error,
        LoadingError: true,
      };
    case CLOSE_PROJECT_SUCCESS:
      return {
        ...state,
        closeProjectIs: values,
      };
    case COMPLETE_PROJECT_SUCCESS:
      return {
        ...state,
        completeTransactionsIs: values,
      };
    case COMPLETE_PROJECT_ERROR:
      return {
        ...state,
        completeProjectErrIs: error,
      };
    case CLOSE_TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        closedTransactionIs: values,
      };
    case CLOSE_PROJECT_ERROR:
      return {
        ...state,
        closedProjectErrIs: error,
      };
    case DONT_LIKE_TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        dontLikeListIs: values,
      };
    case DONT_LIKE_TRANSACTION_LIST_ERROR:
      return {
        ...state,
        dontLikeError: error,
      };
    case CHOOSE_CREATOR_SUCCESS:
      return {
        ...state,
        chooseCreator: values,
      };
    case TRANSCATION_APPROVAL_LIST_SUCCESS:
      return {
        ...state,
        transactionApprIs: values,
      };
    case CALL_BACK_REQUEST_SUCCESS:
      return {
        ...state,
        callBackRequestIs: values,
      };
    case CALL_BACK_DATA_SUCCESS:
      return {
        ...state,
        callBackDataIs: values,
      };
    case CALL_BACK_SEARCH_CREATOR_SUCCESS:
      return {
        ...state,
        searchCreatorIs: values,
      };
    case RECOMMENDED_CREATOR_SUCCESS:
      return {
        ...state,
        recommendIs: values,
      };
    case INCOMPLETE_BRIEF_LIST_SUCCESS:
      return {
        ...state,
        inCompleteBriefIs: values,
      };
    case CRETAOR_REJECTS_BRIEF_LIST_SUCCESS:
      return {
        ...state,
        rejectsCreatorListIs: values,
      };
    case REJECTS_CREATOR_DATA_SUCCESS:
      return {
        ...state,
        rejectionDataIs: values,
      };
    case CLEAR_BRIEF_LIST_SUCCESS:
      return {
        ...state,
        clearListIs: values,
      };
    case CALL_BACK_REQUEST_ERROR:
      return {
        ...state,
        callBackErrIs: error,
      };
    case TRANSCATION_APPROVAL_LIST_ERROR:
      return {
        ...state,
        transactionAppListErrIs: error,
      };
    case INCOMPLETE_BRIEF_LIST_ERROR:
      return {
        ...state,
        incompleteBriefListErrIs: error,
      };
    case CRETAOR_REJECTS_BRIEF_LIST_ERROR:
      return {
        ...state,
        ceatorRejectsErrIs: error,
      };
    case CLOSED_BRIEF_PROJECT_SUCCESS:
      return {
        ...state,
        closedCriefProjectIs: values,
      };
    case CLOSED_BRIEF_PROJECT_ERROR:
      return {
        ...state,
        closedBriefProjectErrIs: error,
      };
    case REJECTED_BRIEF_LIST_SUCCESS:
      return {
        ...state,
        rejectedBriefListIs: values,
      };
    case REJECTED_BRIEF_LIST_ERROR:
      return {
        ...state,
        rejectedBriefListErrIs: error,
      };
    case DELETE_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        deleteProjectIs: values,
      };
    case DELETE_BRIEF_LIST_SUCCESS:
      return {
        ...state,
        deletedBriefsIs: values,
      };
    case DELETE_BRIEF_LIST_ERROR:
      return {
        ...state,
        deleteBriefsErrIs: error,
      };
    case DELETE_PROJECT_ALL_LIST_SUCCESS:
      return {
        ...state,
        deleteProjectsIs: values,
      };
    case DELETE_PROJECT_ALL_LIST_ERROR:
      return {
        ...state,
        deleteProjectErrIs: error,
      };
    case CLOSED_BRIEF_ALL_LIST_SUCCESS:
      return {
        ...state,
        closedBriefAll: values,
      };
    case CLOSED_BRIEF_ALL_LIST_ERROR:
      return {
        ...state,
        closedBriefAllErr: error,
      };
    case CLOSE_BRIEF_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        closedBriefRequestIs: values,
      };
    case CLOSE_BRIEF_REQUEST_ERR:
      return {
        ...state,
        closedBriefRequestErrIs: error,
      };
    case CLOSE_PROJECT_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        projectCloseReq: values,
      };
    case CLOSE_PROJECT_REQUEST_ERR:
      return {
        ...state,
        projectCloseReqErr: error,
      };
    case COMPLETED_MILES_SUCCESS:
      return {
        ...state,
        compMilesIs: values,
      };
    case MILESTONE_COMMENT_SUCCESS:
      return {
        ...state,
        milesCommentIs: values,
      };
    case MILESTONE_APPROVED_SUCCESS:
      return {
        ...state,
        approvedMilesIs: values,
      };
    case ADD_MULTIPLE_CREATOR_SUCCESS:
      return {
        ...state,
        allCreatorIs: values,
      };
    case DELETE_PENDING_CREATOR_SUCCESS:
      return {
        ...state,
        deleteCreatorIs: values,
      };
    case OFFLINE_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentIs: values,
      };
    case UPDATE_TRANSCATIONS_SUCCESS:
      return {
        ...state,
        updateDetailsIs: values,
      };
    case APPROVED_PROPOSAL_SUCCESS:
      return {
        ...state,
        approvedProposal: values,
      }; 
    case APPROVED_PROPOSAL_JOB_SUCCESS:
      return {
        ...state,
        approvedProposalJob: values,
      };
    case REQUEST_CHANGE_PROPOSAL_SUCCESS:
      return {
        ...state,
        reqChangeIs: values,
      };
    case EDIT_CLIENT_SUCCESS:
      return {
        ...state,
        editClientIs: values,
      };
    case MANAGER_DETAILS_SUCCESS:
      return {
        ...state,
        managerDetailsIs: values,
      };
    case INACTIVE_BRIEF_SUCCESS:
      return {
        ...state,
        inactiveBriefData :  values
      }  
    case INACTIVE_BRIEF_ERROR:
      return {
        ...state,
        inactiveBriefErr : error
      }  
    case INACTIVE_PROJECT_SUCCESS:
      return {
        ...state,
        inactiveProjectData : values 
      } 
    case INACTIVE_PROJECT_ERR:
      return {
        ...state,
        inactiveProjectErr : error
      }   
    case INACT_BRIEF_FOR_AM_SUCCESS:
      return {
        ...state,
        inActBriefData : values
      } 
    case INACT_BRIEF_FOR_AM_ERR:
      return {
        ...state,
        inActBriefErr : error
      }   
    case TRANSACTIONS_COUNT_SUCCESS:
      return {
        ...state,
        transactionsCount:values
      }
    case TRANSACTIONS_COUNT_ERROR:
      return {
        ...state,
        transactionsCountErr:error
      }
      case RESET_TRANSACTION_LIST:
        return {
          ...state,
          transactionDetails:""
        }
    default:
      return state;
  }
};

export default reducers;
