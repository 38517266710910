import React, { Component } from "react";
import Modal from "react-modal";
import Select from "react-select";
import { getPublicAPI } from "../../../store/api-interface";
import { connect } from "react-redux";
import { editClient, transactionGetData } from "../../../store/action/transaction.action";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    width: "100%",
    minHeight: "40vh",
  },
};

class EditClient extends Component {
  state = {
    selectedOption: null,
    clientListIs: [],    
    editClientLoader: false,
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleClient = (newValue) => {
    this.handleClientList(newValue);
  };

  handleClientList = (clientValue) => {
    getPublicAPI(
      `/postProject/get-client-for-project?email=${clientValue}`
    ).then((res) => {
      const response = res.data.data;
      if (response) {
        this.setState({ clientListIs: response });
      }
    });
  };

  handleSave = () => {
    const { selectedOption } = this.state;
    if (selectedOption) {
      this.setState({editClientLoader : true})
      const PId = this.props.match.params.id.split(":")[1];
      const currentData = { _id: PId, user_id: selectedOption._id };
      this.props.$editClient(currentData);
    } else {
      alert("Please select Client...");
    }
  };

  componentWillReceiveProps(nextProps){
    const editData = nextProps._editClient.editClientIs;    
    const {editClientLoader} = this.state;
    if(editData && editClientLoader){
        this.setState({editClientLoader: false})
        this.props.handleFunc.handleCloseClintModal();
        const transactionId = this.props.match.params.id.split(":").pop();
        this.props.$transactionGetData(transactionId);
    }
  }

  render() {
    const { modalClientOpen, handleFunc } = this.props;
    const { selectedOption, clientListIs } = this.state;
    const options = clientListIs?.map((obj) => {
      let optionIs = {
        label: `${obj.first_name} ${obj.last_name} (${obj.email})`,
        value: obj.first_name + " " + obj.last_name,
        ...obj,
      };
      return optionIs;
    });
    return (
      <React.Fragment>
        <Modal
          isOpen={modalClientOpen}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <button
            type="button"
            className="close media-upload-modal"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleFunc.handleCloseClintModal}
          >
            <span
              aria-hidden="true"
              style={{ color: "black", fontSize: "32px" }}
            >
              &times;
            </span>
          </button>
          <div className="container">
            <h3 className="text-center">Edit Client</h3>
            <div className="row">
              <div className="col mb-5">
                <label>Choose Client</label>
                <Select
                  value={selectedOption}
                  onChange={this.handleChange}
                  options={options}
                  onInputChange={this.handleClient}
                />
              </div>
              <div className="col-12 col-md-12 text-right">
                <button
                  className="btn btn-primary col-12"
                  onClick={this.handleSave}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
const mapState = ({ editClient, transactionGetData }) => {
  return {
    _editClient: editClient,
    _transactionGetData: transactionGetData
  };
};
const mapDispatch = (dispatchEvent) => ({
  $editClient: (values) => dispatchEvent(editClient(values)),
  $transactionGetData: (values) => dispatchEvent(transactionGetData(values)),
});
export default connect(mapState, mapDispatch)(EditClient);
