import React from "react";

const checkUrl = (currentUrl) => {
  var finalvalidUrl = null;
  if (currentUrl.includes("https://drive.google.com/")) {
    finalvalidUrl = true;
  } else if (currentUrl.includes("vimeo.com")) {
    finalvalidUrl = true;
  } else if (
    currentUrl.includes("www.youtube.com") ||
    currentUrl.includes("youtu.be")
  ) {
    console.log(currentUrl.trim())
    if((currentUrl.trim() == 'www.youtube.com') || (currentUrl.trim() == 'http://www.youtube.com') || (currentUrl.trim() === 'https://www.youtube.com') || (currentUrl.trim() == 'http://www.youtube.com/') || (currentUrl.trim() === 'https://www.youtube.com/'))
    finalvalidUrl = false
    else{
      currentUrl =(currentUrl?.includes('&') && !currentUrl?.includes('watch?v='))? currentUrl?.split('&')?.[0]:(currentUrl?.includes('&') && currentUrl?.includes('watch?v='))?currentUrl?.split('&')?.[0].replace('watch?v=','embed/'):currentUrl
      finalvalidUrl = true;
    }
  } else if (currentUrl.includes("sketchfab.com")) {
    finalvalidUrl = true;
  } else if (
    currentUrl.includes("www.dailymotion.com") ||
    currentUrl.includes("https://dai.ly")
  ) {
    finalvalidUrl = true;
  } else if (currentUrl.includes("xd.adobe.com")) {
    finalvalidUrl = true;
  } else {
    finalvalidUrl = false;
  }

  return (
    <React.Fragment>
      {finalvalidUrl ? (
        <iframe
          className="videoIframe js-videoIframe"
          src={currentUrl}
          frameBorder="0"
          allowtransparency="true"
          allowFullScreen
          height="400"
          width="100%"
          title=".."
          id="video"
        ></iframe>
        // <object data={currentUrl}
        // // width='560px' height='315px'
        //   className="videoIframe js-videoIframe"
        //   src={currentUrl}
        //   frameBorder="0"
        //   allowtransparency="true"
        //   allowFullScreen
        //   height="400"
        //   width="100%"
        //   title=".."
        // >
        // </object>
        
      ) : (
        <div className="text-center" width="100%" height="600">
          <p>Url Is</p>
          <div  className='d-flex'>
          <input className="form-control" value={currentUrl}/><span className="d-flex align-items-center">
            <a href={(currentUrl?.includes('http') || currentUrl?.includes('https'))?currentUrl:'http://'
          + currentUrl} target='_blank'><i style={{fontSize:"30px"}} className="fe fe-external-link"/></a> 
            </span> 
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default checkUrl;
