import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../../Common/Loader/Loader";
import { connect } from "react-redux";
import {
  transactionApprovalList,
  transactionStatus,
} from "../../../../store/action/transaction.action";
import ReactPaginate from "react-paginate";
import ErrorApi from "../../Common/Error/ErrorApi";
import moment from "moment";

class TransactionApproval extends Component {
  state = {
    LoadingIs: false,
    currentPage: 1,
    transactionData: [],
    totalPage: "",
    statusLoader: false,
    sort: -1,
    sortFlag: false,
  };

  handleSorting = () => {
    this.setState({ sortFlag: !this.state.sortFlag }, () => {
      const { currentPage } = this.state;
      let currentData = null;
      if (this.state.sortFlag) {
        currentData = { pageIs: currentPage, sortIs: 1 };
      } else {
        currentData = { pageIs: currentPage, sortIs: -1 };
      }
      this.setState({ sort: this.state.sortFlag ? 1 : -1 });
      this.props.$transactionApprovalList(currentData);
    });
  };

  handleGetConfirmation(id, obj) {
    let confirmIs = window.confirm(
      `Do you want to ${obj === 5 ? `accept` : `reject`} this Transaction ?`
    );
    if (confirmIs === true) {
      this.setState({
        statusLoader: true,
      });
      const statusData = { _id: id, status: obj };
      this.props.$transactionStatus(statusData);
      return true;
    } else {
      return false;
    }
  }

  handleTransactonStatus = (id, status) => {
    let currentStatus = 0;
    if (status === "accept") {
      currentStatus = 5;
    } else {
      currentStatus = 9;
    }
    this.handleGetConfirmation(id, currentStatus);
  };


  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    const { sort } = this.state;
    const currentData = { pageIs: selectedData, sortIs: sort };
    this.handleTransactionBrief(currentData);
  };

  handleTransactionBrief = (currentData) => {
    this.setState({
      LoadingIs: true,
    });
    this.props.$transactionApprovalList(currentData);
  };

  componentDidMount() {
    const { currentPage, sort } = this.state;
    const currentData = { pageIs: currentPage, sortIs: sort };
    this.handleTransactionBrief(currentData);
  }

  componentWillReceiveProps(nextProps) {
    const dataIs = nextProps._transactionApprovalList.transactionApprIs;
    const statusData = nextProps._transactionStatus.postProjectStatus;
    const { statusLoader } = this.state;
    if (dataIs.status === "1") {
      this.setState({
        transactionData: dataIs.postProject,
        totalPage: dataIs.totalPages,
        LoadingIs: false,
      });
    }
    if (statusData.status === "1" && statusLoader) {
      const { currentPage, sort } = this.state;
      this.setState({
        statusLoader: false,
      });
      const currentData = { pageIs: currentPage, sortIs: sort };
      this.handleTransactionBrief(currentData);
    }
  }

  render() {
    const transactionArr = [
      { name: "Action" },
      { name: "transaction name" },
      { name: "collateral name" },
      { name: "client name" },
      // { name: "client company" },
      { name: "client email" },
      { name: "Client phone number" },
      { name: "Brief created date" },      
    ];

    const {
      LoadingIs,
      transactionData,
    } = this.state;
    return (
      <section>
        {this.props._transactionApprovalList.transactionAppListErrIs ? (
          <ErrorApi {...this.props} />
        ) : (
          <React.Fragment>
            <section className="client mt-5">
              <div className="container-fluid mx-auto">
                {LoadingIs ? (
                  <Loader />
                ) : (
                  <div className="table-responsive">
                    <table className="table table-sm table-nowrap card-table">
                      {/*----------------- table head --------------------*/}
                      <thead>
                        <tr>
                          {transactionArr.map((obj, index) => (
                            <React.Fragment key={index}>
                              {obj.name !== "Brief created date" && (
                                <th>
                                  <Link to="#!" className="text-muted">
                                    {obj.name}
                                  </Link>
                                </th>
                              )}
                              {obj.name === "Brief created date" && (
                                <th>
                                  <Link
                                    to="#!"
                                    className="text-muted sort"
                                    data-sort="tables-row"
                                    onClick={this.handleSorting}
                                  >
                                    {obj.name}
                                  </Link>
                                </th>
                              )}
                            </React.Fragment>
                          ))}
                        </tr>
                      </thead>
                      {/*----------------- table head --------------------*/}
                      <tbody className="list">
                        {transactionData.map((obj, index) => (
                          <tr key={index}>
                            <td className="text-center">
                              <div
                                className={`dropdown `}
                              >
                                <a
                                  href="#!"
                                  className="dropdown-ellipses dropdown-toggle text-secondary"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i
                                    className="fe fe-more-vertical"
                                    
                                  ></i>
                                </a>
                                
                                  <div
                                    className={`ma-auto dropdown-menu dropdown-menu-right text-center`}
                                    
                                  >
                                    {obj.status === 3 && (
                                      <span>
                                        <Link
                                          to="#!"
                                          className="dropdown-item"
                                          datatoggle="modal"
                                          datatarget="#exampleModalCenter"
                                          onClick={() =>
                                            this.handleTransactonStatus(
                                              obj._id,
                                              "accept"
                                            )
                                          }
                                        >
                                          Accept Brief
                                        </Link>
                                        <Link
                                          to="#!"
                                          className="dropdown-item mb-2"
                                          onClick={() =>
                                            this.handleTransactonStatus(
                                              obj._id,
                                              "reject"
                                            )
                                          }
                                        >
                                          Reject Brief
                                        </Link>
                                      </span>
                                    )}
                                    <span>
                                      <Link
                                        to={`/transactions/transactionsDetails/:${obj._id}`}
                                        className="dropdown-item mb-2"
                                        datatoggle="modal"
                                        datatarget="#exampleModalCenter"
                                      >
                                        View transaction
                                      </Link>
                                    </span>
                                  </div>
                              </div>
                            </td>
                            <td className="orders-order">{`${obj.project_name.substring(
                              0,
                              30
                            )} ${
                              obj.project_name.substring(20) ? "..." : ""
                            }`}</td>
                            <td className="orders-product">
                              {obj.collateral.name}
                            </td>
                            <td className="orders-date">
                              {obj.client.first_name +
                                " " +
                                obj.client.last_name}
                            </td>
                            {/* <td className="orders-total">$55.25</td> */}
                            <td className="orders-status">
                              {obj.client.email}
                            </td>
                            <td className="orders-method">
                              {obj.client.phone_no}
                            </td>
                            <td className="orders-method">
                              {obj?.updatedAt && moment(obj?.updatedAt).format("DD/MM/YYYY")}
                            </td>                            
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {/****************** Pagination ********************/}

                {this.state.totalPage > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                )}
              </div>
            </section>
          </React.Fragment>
        )}
      </section>
    );
  }
}
const mapState = ({ transactionApprovalList, transactionStatus }) => {
  return {
    _transactionApprovalList: transactionApprovalList,
    _transactionStatus: transactionStatus,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $transactionApprovalList: (values) =>
    dispatchEvent(transactionApprovalList(values)),
  $transactionStatus: (values) => dispatchEvent(transactionStatus(values)),
});
export default connect(mapState, mapDispatch)(TransactionApproval);
