import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import {
  AllCollateralList,
  getUserList,
  addPostProject,
  getPostProjectData,
  updateProject,
} from "../../../../store/action/projectList.action";
import {
    completedMiles,
    milestoneApproved,
  } from "../../../../store/action/transaction.action";
import ProjectOtherDetails from "./ProjectOtherDetails";
// import LoaderSmall from "../../Common/Loader/LoaderSmall";
import { getPublicAPI,postAPI } from "../../../../store/api-interface";
import CompanyDetails from "./CompanyDetails";
import Review from "./Review";
import LastMsgBy from "./LastMsgBy";
import ProjectStatus from "./ProjectStatus";
import {validationForm, validation} from "./AddProjectFunction";
import AskQuestion from "../AskQuestion";
// import SubmitMilestone from "../../Common/ModalCommon/SubmitMilestone";
import Milestone2 from "./Milestone2";
import {        
    addProposal,
    updateProposal,
  } from "../../../../store/action/projectList.action";
import moment from 'moment'

class CompleteProject extends Component {
  state = {
      selectedOption: '',
      deadlineStatus: 0,
      selectedClient: '',
      selectedCreator: '',
      selectedRepresntative: '',  
      collateralName: "",
      collateralData: [],
      AllClientList: [],
      
    minBudget: "",
    maxBudget: "",
    unit: "",
    project: "",
    projectDescription: "",
    inputCollateral: "",
    EditIs: false,
    projectDetailsIs: [],
    flag: false,
    // errorIs: {
    //     minBudget: "",
    //     maxBudget: "",
    //     project: "",
    //     projectDescription: "",
    //     unit: "",
    //     companyJob: "",
    //     companyLink: "",
    //     companyDesc: "",
    // },
    clientMsg: "",
    creatorMsg: "",
    representativeMsg: "",
    StatusIs: 0,
    editRes: false,
    clientId: "",
    creatorId: "",

    companyName: '',
    companyJob: "",
    companyLink: "",
    companyLocation: "",
    companyIndustry: '',
    companyDesc: "",
    companyType: "Company",
    selectedLocation: '',
    selectedEmp: '',
    selectedLocIndividual: '',
    industryMsg: "",
    locationMsg: "",
    empMsg: "",
    locationIndividualMsg: "",
    companyMsg: "",
    clientListIs: [],
    creatorListIs: [],
    ReviewIs: [],
    projectCompDate: "",
    LastMsgIs: "",
    reviewQuestn :[],
    Gst: "",
    clientBudget: "",
    note: "",
    creatorCost: "",
    selectedDate: new Date(),
    milestoneInputs: [{ name: "", due_date: "", iteration: "" }],
    projectDetails: [],
    LoadingIs: false,
    errorIs: { Gst: "", clientBudget: "", creatorCost: "" },
    mileNameIs: "",
    mileDueDateIs: "",
    DeletedMileStone: [],
    LoaderIs: false,
    showCalendar: false,
    disableDate: false,
    statusIs: 0,

    submitMilesOpen: false,
    deliverableMiles: "",
    submitMilesData: "",
    milesApprovedIs: false,
  };
async handleLastSubmit(e){
  let flag=0;
  let obj1={};
  let obj2={};
  const {
    Gst,
    clientBudget,
    note,
    creatorCost,
    milestoneInputs,
    projectDetails,
    selectedOption,
    minBudget,
    maxBudget,
    unit,
    project,
    projectDescription,
    selectedClient,
    selectedCreator,
    deadlineStatus,
    companyName,
    companyJob,
    companyLink,
    companyIndustry,
    companyDesc,
    companyType,
    selectedEmp,
    selectedLocation,
    selectedLocIndividual,
    
  } = this.state;
  if (
  validation(this.state) &&
  selectedClient &&
  selectedCreator  &&
  (((companyType === "Company" || companyType === "Agency") &&
    companyIndustry?.label &&
    selectedLocation?.label &&
    selectedEmp?.label &&
    companyName?.label) ||
    (companyType === "Individual" && selectedLocIndividual))
) {
  const data = {
    collateral_id: selectedOption._id,
    client_id: selectedClient._id,
    creator_id: selectedCreator._id,
    type: "post_project_admin",
    min_budget: minBudget,
    max_budget: maxBudget,
    unit: unit ? unit : "",
    dealline: deadlineStatus,
    project_name: project,
    project_description: projectDescription,
    company_work_type: companyType,
    company_name: companyType === "Individual" ? "" : companyName ? companyName?.label : "",
    company_job_role: companyType === "Individual" ? "" : companyJob,
    company_add_link: companyType === "Individual" ? "" : companyLink,
    company_location: companyType === "Individual" ? "" : selectedLocation ? selectedLocation?.label : "",
    company_employees: companyType === "Individual" ? "" : selectedEmp ? selectedEmp?.label : "",
    company_describes: companyType === "Individual" ? "" : companyDesc,
    company_industry: companyType === "Individual" ? "" : companyIndustry ? companyIndustry?.label : "",
    company_individual_location: selectedLocIndividual
      ? selectedLocIndividual?.label
      : "",
    project_from:"admin_complete_project"
  };
  

  this.setState({
    LoadingIs: true,
    clientMsg: "",
  });
 flag++;
 obj1=Object.assign(data)
} else {
  this.setState({
    clientMsg: !selectedClient ? "Required Field" : "",
    creatorMsg: !selectedCreator ? "Required Field" : "",
    // representativeMsg: !selectedRepresntative ? "Required Field" : "",
    companyMsg:
      (companyType === "Company" || companyType === "Agency") &&
      !companyName?.label
        ? " Required Field"
        : "",
    industryMsg:
      (companyType === "Company" || companyType === "Agency") &&
      !companyIndustry?.label
        ? "Required Field"
        : "",
    locationMsg:
      (companyType === "Company" || companyType === "Agency") &&
      !selectedLocation?.label
        ? "Required Field"
        : "",
    empMsg:
      (companyType === "Company" || companyType === "Agency") &&
      !selectedEmp?.label
        ? "Required Field"
        : "",
    locationIndividualMsg:
      companyType === "Individual" && !selectedLocIndividual?.label
        ? "Required Field"
        : "",
  });
}


    if (this.validation1() && this.validationMileStone()) {
      const mileIs = milestoneInputs.map((obj, index) => {
        let dataMile = {
          name: obj.name,
          due_date: obj.due_date,
          number: index + 1,
          iteration: obj.iteration,
        };
        return dataMile;
      });
      if (projectDetails) {
        const data = {
          // creator_id: projectDetails.users
          //   ? projectDetails.users.creator_id
          //   : "",
          tax: Gst,
          sub_total_cost: clientBudget,
          total_cost: Number(Gst) + Number(clientBudget),
          total_earning: creatorCost,
          note: note,
          milestone: mileIs,
        };
        this.setState({ LoadingIs: true });
        flag++;
        obj2=Object.assign(data)
    
       
      }
        
    }
    if(flag===2){
    const finalObj=Object.assign(obj1,obj2)
    // console.log(finalObj)
    try{

      const res= await postAPI('/postProject/add-post-completed-project',finalObj);
      console.log(res);

      alert("Success")
      const projectid= res.data.users.post_project_id
      this.props.history.push(`/transactions/transactionsDetails/:${projectid}`)
    }catch(ex){
alert('something went wrong')
    }
    }



    

}
  
    handleClientDetails = (id) => {
    // console.log("id = ",id)
    getPublicAPI(`/users/get-company-details?userId=${id}`).then(
        (res) => {
        let data = res?.data
        // console.log("data = = ", data)
        this.setState({
          companyType: data?.company_work_type?.toLowerCase() == "individual" ? "Individual" : data?.company_work_type?.toLowerCase() == "company" ? "Company" : "Agency",
          companyName: data
            ? { label: data.company_name, value: data.company_name }
            : "",
          companyJob: data?.company_job_role,
          companyLink: data?.company_add_link,
          companyDesc: data?.company_describes,
          selectedLocation: data
            ? {
              value: data.company_location,
              label: data.company_location,
            }
            : "",
          companyIndustry: data
            ? {
              value: data.company_industry,
              label: data.company_industry,
            }
            : "",
          selectedEmp: data
            ? {
              value: data.company_employees,
              label: data.company_employees,
            }
            : "",
          selectedLocIndividual: data.company_individual_location
            ? {
              value: data.company_individual_location,
              label: data.company_individual_location,
            }
            : "",
        });
      }
      ).catch(err => {
      console.log(err)
    })
  }
  
  handleOnChange = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
      validationForm(name, value, this.state);

  };

  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    this.setState({ inputCollateral: inputValue }, () => {
      this.handleCollateralList(inputValue);
    });
  };

  handleCollChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleUserList = (emailValue) => {
    getPublicAPI(`/postProject/get-user-for-project?email=${emailValue}`).then(
      (res) => {
        this.setState({ AllClientList: res.data.data ? res.data.data : "" });
      }
    );
  };
  

  handleClientList = (clientValue) => {
    getPublicAPI(
      `/postProject/get-client-for-project?email=${clientValue}`
    ).then((res) => {
      const response = res.data.data;
      if (response) {
        this.setState({ clientListIs: response });
      }
    });
  };

  handleCreatorList = (creatorEmail) => {
    const { selectedOption } = this.state;
    const id = selectedOption ? selectedOption._id : "";
    if (id) {
      getPublicAPI(
        `/postProject/get-creator-for-project?email=${creatorEmail}&collateral_id=${id}`
      ).then((res) => {
        const response = res.data.data;
        if (response) {
          this.setState({ creatorListIs: response });
        }
      });
    }
  };

  handleClient = (inputValue) => {
    this.handleClientList(inputValue);
  };

  // handleClientChange = (selectedClient) => {
  //   this.setState({ selectedClient, clientMsg: "" });
  // };

  handleClientChange = (selectedClient) => {
    // console.log("clientListIs = ",this.state.clientListIs)
    let arr = this.state.clientListIs.filter(obj => obj?._id === selectedClient?._id)
    let op = arr ? arr[0] : ""
    this.setState({ selectedClient, clientMsg: "", clientAll: op }, () => {
      this.handleClientDetails(this.state.selectedClient?._id)
    });
  };

  handleCreatorChange = (selectedCreator) => {
    this.setState({ selectedCreator, creatorMsg: "" });
  };

  handleCreator = (inputValue) => {
    this.handleCreatorList(inputValue);
  };

 

  handleRepresntative = (inputValue) => {
    this.handleUserList(inputValue);
  };

  handleCompanyType = (status) => {
    const {
      errorIs,
      companyIndustry,
      selectedLocation,
      selectedEmp,
    } = this.state;
    this.setState({ companyType: status });
    if (status === "Company" || status === "Agency") {
      this.setState({
        locationIndividualMsg: "",
        empMsg: selectedEmp && selectedEmp.label && "",
        locationMsg: selectedLocation && selectedLocation.label && "",
        industryMsg: companyIndustry && companyIndustry.label && "",
      });
    } else if (status === "Individual") {
      errorIs.companyName = "";
      errorIs.companyJob = "";
      errorIs.companyLink = "";
      errorIs.companyDesc = "";
      this.setState({ empMsg: "", locationMsg: "", industryMsg: "", errorIs });
    }
  };

  handleChangeEmp = (selectedEmp) => {
    this.setState({ selectedEmp: selectedEmp, empMsg: "" });
  };

  handleLocationSelect = (selectedLocation) => {
    this.setState({ selectedLocation, locationMsg: "" });
  };

  handleLocaIndividual = (selectedLocIndividual) => {
    this.setState({ selectedLocIndividual, locationIndividualMsg: "" });
  };

  handleIndustrySelect = (companyIndustry) => {
    this.setState({ companyIndustry, industryMsg: "" });
  };

  handleChange = (companyName) => {
    this.setState({ companyName, companyMsg: "" });
  };



//   handleUpdate = (e) => {
//     const {
//       selectedOption,
//       minBudget,
//       maxBudget,
//       unit,
//       project,
//       projectDescription,
//       deadlineStatus,
//       projectDetailsIs,
//       selectedClient,
//       selectedCreator,
//       selectedRepresntative,
//       clientId,
//       representativeId,
//       creatorId,
//       companyType,
//       companyName,
//       companyJob,
//       companyLink,
//       selectedLocation,
//       selectedEmp,
//       companyDesc,
//       companyIndustry,
//       selectedLocIndividual,
//     } = this.state;
//     if (
//       validation(this.state) &&
//       selectedClient &&
//       selectedCreator &&
//       selectedRepresntative &&
//       (((companyType === "Company" || companyType === "Agency") &&
//         companyIndustry.label &&
//         selectedLocation.label &&
//         selectedEmp.label &&
//         companyName) ||
//         (companyType === "Individual" && selectedLocIndividual))
//     ) {
//       const data = {
//         _id: projectDetailsIs ? projectDetailsIs.project._id : "",
//         collateral_id: selectedOption._id,
//         client_id: selectedClient._id ? selectedClient._id : clientId,
//         creator_id: selectedCreator._id ? selectedCreator._id : creatorId,
//         representative_id: selectedRepresntative._id
//           ? selectedRepresntative._id
//           : representativeId,
//         type: "post_project_admin",
//         min_budget: minBudget,
//         max_budget: maxBudget,
//         unit: unit ? unit : "",
//         dealline: deadlineStatus,
//         project_name: project,
//         project_description: projectDescription,
//         status: projectDetailsIs ? projectDetailsIs.project.status : "",
//         company_work_type: companyType,
//         company_name:
//           companyType !== "Individual" && companyName ? companyName.label : "",
//         company_job_role: companyType !== "Individual" ? companyJob : "",
//         company_add_link: companyType !== "Individual" ? companyLink : "",
//         company_location:
//           selectedLocation && companyType !== "Individual"
//             ? selectedLocation.label
//             : "",
//         company_employees:
//           selectedEmp && companyType !== "Individual" ? selectedEmp.label : "",
//         company_describes: companyType !== "Individual" ? companyDesc : "",
//         company_industry:
//           companyIndustry && companyType !== "Individual"
//             ? companyIndustry.label
//             : "",
//         company_individual_location:
//           companyType !== "Company"
//             ? selectedLocIndividual.label
//             : ""
//             ? selectedLocIndividual.label
//             : "",
//       };
//       this.setState({ LoaderIs: true });
//       this.props.$updateProject(data);
//     } else {
//       this.setState({
//         clientMsg: !selectedClient ? "Required Field" : "",
//         creatorMsg: !selectedCreator ? "Required Field" : "",
//         representativeMsg: !selectedRepresntative ? "Required Field" : "",
//         companyMsg:
//           (companyType === "Company" || companyType === "Agency") &&
//           !companyName
//             ? " Required Field"
//             : "",
//         industryMsg:
//           (companyType === "Company" || companyType === "Agency") &&
//           companyIndustry &&
//           !companyIndustry.label
//             ? "Required Field"
//             : "",
//         locationMsg:
//           (companyType === "Company" || companyType === "Agency") &&
//           selectedLocation &&
//           !selectedLocation.label
//             ? "Required Field"
//             : "",
//         empMsg:
//           (companyType === "Company" || companyType === "Agency") &&
//           selectedEmp &&
//           !selectedEmp.label
//             ? "Required Field"
//             : "",
//         locationIndividualMsg:
//           companyType === "Individual" && !selectedLocIndividual.label
//             ? "Required Field"
//             : "",
//       });
//     }
//   };


  handleSubmit = () => {
    const {
      selectedOption,
      minBudget,
      maxBudget,
      unit,
      project,
      projectDescription,
      selectedClient,
      selectedCreator,
      deadlineStatus,
      companyName,
      companyJob,
      companyLink,
      companyIndustry,
      companyDesc,
      companyType,
      selectedEmp,
      selectedLocation,
      selectedLocIndividual,
      
    } = this.state;
    if (validation(this.state) &&
      selectedClient &&
      selectedCreator &&
      (((companyType === "Company" || companyType === "Agency") &&
        companyIndustry &&
        selectedLocation &&
        selectedEmp &&
        companyName) ||
        (companyType === "Individual" && selectedLocIndividual))
    ) {
      const data = {
        collateral_id: selectedOption._id,
        client_id: selectedClient._id,
        creator_id: selectedCreator._id,
        
        type: "post_project_admin",
        min_budget: minBudget,
        max_budget: maxBudget,
        unit: unit ? unit : "",
        dealline: deadlineStatus,
        project_name: project,
        project_description: projectDescription,
        company_work_type: companyType,
        company_name: companyName ? companyName.label : "",
        company_job_role: companyJob,
        company_add_link: companyLink,
        company_location: selectedLocation ? selectedLocation.label : "",
        company_employees: selectedEmp ? selectedEmp.label : "",
        company_describes: companyDesc,
        company_industry: companyIndustry ? companyIndustry.label : "",
        company_individual_location: selectedLocIndividual
          ? selectedLocIndividual.label
          : "",
        project_from:"admin_complete_project"
      };

      this.setState({
        LoadingIs: true,
        clientMsg: "",
      });
      
      return data
    } else {
      this.setState({
        clientMsg: !selectedClient ? "Required Field" : "",
        creatorMsg: !selectedCreator ? "Required Field" : "",
        companyMsg:
          (companyType === "Company" || companyType === "Agency") &&
          !companyName
            ? " Required Field"
            : "",
        industryMsg: !(
          (companyType === "Company" || companyType === "Agency") &&
          companyIndustry
        )
          ? "Required Field"
          : "",
        locationMsg: !(
          (companyType === "Company" || companyType === "Agency") &&
          selectedLocation
        )
          ? "Required Field"
          : "",
        empMsg: !(
          (companyType === "Company" || companyType === "Agency") &&
          selectedEmp
        )
          ? "Required Field"
          : "",
        locationIndividualMsg: !(
          companyType === "Individual" && selectedLocIndividual
        )
          ? "Required Field"
          : "",
      });
      return ;
    }
  };

  handleCollateralList = (inputCollateral) => {
    this.setState({ chooseCollateralIs: false });
    this.props.$AllCollateralList(inputCollateral);
  };

  handleGetProjectData = (id) => {
    this.setState({ flag: true });
    this.props.$getPostProjectData(id);
  };

  componentDidMount() {
    const newValue = "";
    this.handleUserList(newValue);
    this.handleClientList(newValue);
    this.handleCreatorList(newValue);
    setTimeout(() => {
    //   const projectId = this.props.match.params.id.split(":").pop();
    //   if (projectId) {
    //     this.handleGetProjectData(projectId);
        this.setState({
          EditIs: true,
          editRes: true,
        });
    //   }
    }, 200);
  }

  componentWillReceiveProps(nextProps) {
    // const proposalData = nextProps._addProposal.addProjectIs;
    // const updateData1 = nextProps._updateProposal.updateProposalIs;
    // const milesApprovedData = nextProps._milestoneApproved.approvedMilesIs;
    const { LoadingIs, LoaderIs, EditIs, flag } = this.state;
    // const { LoadingIs, EditIs, LoaderIs, flag } = this.state;
    const dataList = nextProps._AllCollateralList.allCollateralIs;
    const ProjectData = nextProps._addPostProject.addProjectIs;
    const getData = nextProps._getPostProjectData.postProjectDataIs;
    const updateData = nextProps._updateProject.updateDataIs;
    const feedbackIs = getData.completeProjectReview;
    const closeReason = getData.closeProjectReview;
    const lastMsgData = getData.lastMsg;
    if (ProjectData && LoadingIs) {
    //   alert("Successfully done");
    //   const id = ProjectData.project._id;
    //   this.props.history.push(`/transactions/ProjectMilestone/:${id}`);
      this.setState({ LoadingIs: false });
    }
    if (dataList.status === "1") {
      this.setState({
        collateralData: dataList ? dataList.data : "",
      });
    }
    if (getData && EditIs && flag) {
      const collateralData = getData.collateral;
      const projectData = getData.project;
      const userData = getData.users;
      const clientNow = userData.client_id;
      const creatorNow = userData.creator_id;
      const repNow = userData.representative_id;
 
      this.setState({
        projectDetailsIs: getData,
        selectedOption: collateralData
          ? {
              value: collateralData.name,
              label: collateralData.name,
              ...collateralData,
            }
          : "",
        deadlineStatus: projectData?.dealline,
        minBudget: projectData?.min_budget,
        maxBudget: projectData?.max_budget,
        project: projectData?.project_name,
        projectDescription: projectData?.project_description,
        unit: projectData?.unit,
        StatusIs: projectData?.status,
        LastMsgIs: lastMsgData ? lastMsgData : "",
        flag: false,
        listIs: false,
        selectedClient: clientNow
          ? {
              label: `${clientNow.first_name} ${clientNow.last_name} (${clientNow.email})`,
              _id: clientNow._id,
              value: clientNow.first_name + " " + clientNow.last_name,
              email: clientNow.email,
            }
          : "",
        selectedCreator: creatorNow
          ? {
              _id: creatorNow._id,
              label: `${creatorNow.first_name} ${creatorNow.last_name} (${creatorNow.email})`,
              value: creatorNow.first_name + " " + creatorNow.last_name,
              email: creatorNow.email,
            }
          : "",
        selectedRepresntative: repNow
          ? {
              _id: repNow._id,
              label: `${repNow.first_name} ${repNow.last_name} (${repNow.email})`,
              value: repNow.first_name + " " + repNow.last_name,
              email: repNow.email,
            }
          : "",
        editRes: false,
        clientId: userData?.client_id,
        creatorId: userData?.creator_id,
        
        
        companyType: projectData?.company_work_type,
        companyName: projectData
          ? { label: projectData.company_name, value: projectData.company_name }
          : "",
        companyJob: projectData?.company_job_role,
        companyLink: projectData?.company_add_link,
        companyDesc: projectData?.company_describes,
        selectedLocation: projectData
          ? {
              value: projectData.company_location,
              label: projectData.company_location,
            }
          : "",
        companyIndustry: projectData
          ? {
              value: projectData.company_industry,
              label: projectData.company_industry,
            }
          : "",
        selectedEmp: projectData
          ? {
              value: projectData.company_employees,
              label: projectData.company_employees,
            }
          : "",
        selectedLocIndividual: projectData.company_individual_location
          ? {
              value: projectData.company_individual_location,
              label: projectData.company_individual_location,
            }
          : "",
        ReviewIs:
          projectData.status === 7 && feedbackIs
            ? feedbackIs
            : (projectData.status === 8 && closeReason) ||
              (projectData.status === 5 && closeReason) ||
              (projectData.status === 6 && closeReason)
            ? closeReason
            : [],
        projectCompDate: projectData?.project_completed_date,
        reviewQuestn : getData.completeProjectQuestion
      });
    }
    if (updateData && LoaderIs) {
      alert("Successfully Updated ...");
      this.setState({ LoaderIs: false });
    //   const projectId = this.props.match.params.id.split(":").pop();
    //   this.handleGetProjectData(projectId);
    }
  }
  handleMilestoneChange = (e, index) => {
    const { milestoneInputs } = this.state;
    if (e.target) {
      const { name, value } = e.target;
      const list = [...milestoneInputs];
      list[index][name] = value;
      this.setState({
        [name]: value,
      });
      const currentMile = milestoneInputs[index];
      if (e.target.name === "name") {
        currentMile.nameError && delete currentMile.nameError;
      } else {
        currentMile.iterationError && delete currentMile.iterationError;
      }
      this.setState({ milestoneInputs });
    } else {
      const list = [...milestoneInputs];
      const currentDate = e;
      list[index].due_date = currentDate.toDateString();
      list[index].selectedDate = currentDate.toDateString();
      if (milestoneInputs.length > 1) {
        const nextDateIs =
          milestoneInputs[index + 1] &&
          new Date(milestoneInputs[index + 1].due_date);        
        if (nextDateIs && this.getDateDiff(currentDate?.toDateString(), nextDateIs?.toDateString()) > 0) {
          milestoneInputs[index + 1].dateError = true;
          const currentMileIs = milestoneInputs[index];
          currentMileIs.dateError && delete currentMileIs.dateError;
        } else {
          const currentMileIs = milestoneInputs[index];
          currentMileIs.dateError && delete currentMileIs.dateError;
          this.setState({ milestoneInputs });
        }
      } else {
        const currentMileIs = milestoneInputs[index];
        currentMileIs.dateError && delete currentMileIs.dateError;
      }
      this.setState({ milestoneInputs });
    }
  };


  getDateDiff = (a , b) => {
    if (a) {
      const x = moment(a);
      const y = moment(b);
      const r = x.diff(y, "days");
      return r;
    }
  };
  validation1 = () => {
    const { errorIs, Gst, clientBudget, creatorCost } = this.state;
    errorIs.Gst = Gst === "" ? "Required Field" : "";
    errorIs.clientBudget = clientBudget === "" ? "Required Field" : "";
    errorIs.creatorCost = creatorCost === "" ? "Required field" : "";

    this.setState({ errorIs });
    this.validationMileStone();
    return !errorIs.Gst && !errorIs.clientBudget && !errorIs.creatorCost;
  };
  validationMileStone = () => {
    const { milestoneInputs } = this.state;
    let formValidIs = true;
    const updatedMileStone = milestoneInputs.map((obj, index) => {
      if (obj.name === "") {
        milestoneInputs[index].nameError = true;
        formValidIs = false;
      }
      if (obj.due_date === "") {
        milestoneInputs[index].dateError = true;
        formValidIs = false;
      } else if (obj.due_date !== "" && milestoneInputs[index].dateError) {
        formValidIs = false;
      }
      if (obj.iteration === "" || !(obj.iteration > 0)) {
        milestoneInputs[index].iterationError = true;
        formValidIs = false;
      }
      return obj;
    });
    this.setState({ milestoneInputs: updatedMileStone });
    return formValidIs;
  };

  handleProposalSubmit = () => {
    const {
      Gst,
      clientBudget,
      note,
      creatorCost,
      milestoneInputs,
      projectDetails,
    } = this.state;

    if (this.validation1() && this.validationMileStone()) {
      const mileIs = milestoneInputs.map((obj, index) => {
        let dataMile = {
          name: obj.name,
          due_date: obj.due_date,
          number: index + 1,
          iteration: obj.iteration,
        };
        return dataMile;
      });
      if (projectDetails) {
        const data = {
          creator_id: projectDetails.users
            ? projectDetails.users.creator_id
            : "",
          tax: Gst,
          sub_total_cost: clientBudget,
          total_cost: Number(Gst) + Number(clientBudget),
          total_earning: creatorCost,
          note: note,
          milestone: mileIs,
        };
        this.setState({ LoadingIs: true });
        // this.props.$addProposal(data);
        return data
      }
        
    }
  };
  handleAppendMilestone = () => {
    const { milestoneInputs } = this.state;
    var list = [...milestoneInputs, { name: "", due_date: "", iteration: "" }];
    this.setState({
      milestoneInputs: list,
      selectedDate: milestoneInputs.slice(-1).pop().due_date
        ? milestoneInputs.slice(-1).pop().due_date
        : new Date(),
    });
  };

  handleRemoveMilestone = (index, obj) => {
    const { milestoneInputs, DeletedMileStone } = this.state;
    if (index !== -1) {
      const remainMilestone = milestoneInputs.filter((_, i) => i !== index);
      const deletedMileStone = DeletedMileStone.concat(obj);

      this.setState({
        milestoneInputs: remainMilestone,
        DeletedMileStone: deletedMileStone,
      });
    }
  };

  handleViewcompletedMiles = (obj, index) => {
    this.setState({
      submitMilesOpen: true,
      deliverableMiles: index + 1,
      submitMilesData: obj,
    });
    this.props.$completedMiles(obj._id);
  };

  handleCloseMilesModal = () => {
    this.setState({ submitMilesOpen: false });
  };

  handleGetConfirmation = (currentData) => {
    let confirmIs = window.confirm(`Do you want to approve this Milestone ?`);
    if (confirmIs === true) {
      this.setState({ milesApprovedIs: true });
      this.props.$milestoneApproved(currentData);
      return true;
    } else {
      return false;
    }
  };

  handleApprovedMiles = (obj) => {
    const currentData = {
      proposal_id: obj.proposal_id,
      milestone_id: obj._id,
      type: "milestone",
    };
    this.handleGetConfirmation(currentData);
};
handleChange1 = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.validationForm1(name, value);
  };
  validationForm1 = (name, value) => {
    const { errorIs } = this.state;
    switch (name) {
      case "Gst":
        errorIs.Gst = value === "" ? "Required Field" : "";
        break;
      case "clientBudget":
        errorIs.clientBudget = value === "" ? "Required Field" : "";
        break;
      case "creatorCost":
        errorIs.creatorCost = value === "" ? "Required field" : "";
        break;
      default:
        break;
    }
  };

  handleUpdateProposal = () => {
    const {
      Gst,
      clientBudget,
      note,
      creatorCost,
      milestoneInputs,
      projectDetails,
      DeletedMileStone,
    } = this.state;
    if (this.validation1() && this.validationMileStone()) {
      if (projectDetails) {
        const ExistMile = milestoneInputs.filter((item) => item._id);
        const currentExistMile = ExistMile.map((obj, index) => {
          let dataMile = {
            _id: obj._id,
            name: obj.name,
            due_date: obj.due_date,
            iteration: obj.iteration,
            number: index + 1,
          };
          return dataMile;
        });
        const newMile = milestoneInputs.filter(
          ({ _id: id1 }) =>
            !projectDetails.Milestone.some(({ _id: id2 }) => id2 === id1)
        );

        const currentNewMile = newMile.map((obj, index) => {
          let dataMile = {
            name: obj.name,
            due_date: obj.due_date,
            iteration: obj.iteration,
            number: index + 1 + currentExistMile.slice(-1).pop().number,
          };
          return dataMile;
        });
        const currentData = {
          creator_id: projectDetails.users
            ? projectDetails.users.creator_id
            : "",
          proposal_id: projectDetails.Proposal
            ? projectDetails.Proposal._id
            : "",
          tax: Gst,
          sub_total_cost: clientBudget,
          total_cost: Number(Gst) + Number(clientBudget),
          total_earning: creatorCost,
          note: note,
          milestone_existing: currentExistMile,
          milestone_new: currentNewMile,
          milestone_delete: DeletedMileStone,
        };

        this.setState({ LoaderIs: true });
        this.props.$updateProposal(currentData);
      }
    }
  };

  render() {    
    const {
      selectedOption,
      collateralData,
      // LoadingIs,
      // EditIs,
      // LoaderIs,
      StatusIs,
      ReviewIs,
      projectCompDate,
      LastMsgIs,
      // statusIs
    } = this.state;

    const optionData = collateralData?.map((obj) => {
      let options = {
        value: obj.name,
        label: obj.name,
        ...obj,
      };
      return options;
    });
    const projectFunc = {
      handleOnChange: this.handleOnChange,
      handleClientChange: this.handleClientChange,
      handleCreatorChange: this.handleCreatorChange,
      handleCreator: this.handleCreator,

      handleRepresntative: this.handleRepresntative,
      handleClient: this.handleClient,
      handleCompanyType: this.handleCompanyType,
      handleLocationSelect: this.handleLocationSelect,
      handleChangeEmp: this.handleChangeEmp,
      handleLocaIndividual: this.handleLocaIndividual,
      handleIndustrySelect: this.handleIndustrySelect,
      handleChange: this.handleChange,
    };
    const milesFunc = {
        handleCloseMilesModal: this.handleCloseMilesModal,
        handleChange : this.handleChange1,
        handleMilestoneChange: this.handleMilestoneChange,
        handleRemoveMilestone: this.handleRemoveMilestone,
        handleViewcompletedMiles: this.handleViewcompletedMiles,
        handleApprovedMiles: this.handleApprovedMiles,
        handleAppendMilestone: this.handleAppendMilestone,
      };

    return (
      <React.Fragment>

        <section className="container">
          <form>
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <h2
                    htmlFor="exampleInputEmail2"
                    className ="header-title text-secondary"
                  >
                    Choose Collateral
                  </h2>
                  <div className="my-3"></div>
                  <Select
                    value={selectedOption}
                    onChange={this.handleCollChange}
                    options={optionData}
                    onInputChange={this.handleInputChange}
                    cacheOptions
                    defaultOptions
                    isDisabled={
                      StatusIs === 6 ||
                      StatusIs === 7 ||
                      StatusIs === 8 ||
                      StatusIs === 10
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
              <hr className="my-5" />
            </div>

            {/*----------------------Project Other Details --------------------------*/}

            {selectedOption && (
                
              
              <ProjectOtherDetails noRep='true'
                {...this.state}
                {...this.props}
                handleFunc={projectFunc}
              />
            )}

            {/*----------------------Project Other Details --------------------------*/}

            {/*---------------- project Company details -------------------------- */}
            {selectedOption && <hr className="my-5" />}
            {selectedOption && (
              <CompanyDetails
                {...this.props}
                {...this.state}
                handleFunc={projectFunc}
              />
            )}

            {/*----------------project Company details -------------------------- */}
            {/*------------------------Review -----------------------------*/}

            {ReviewIs && <Review {...this.state} {...this.props} />}

            {/*-----------------------Review ------------------------------*/}

            {/*--------------------review Questions -------------------------*/}

            {StatusIs === 7 && <AskQuestion {...this.state} {...this.props}/>}
            {StatusIs === 7 && <hr className="my-5"/>}

            {/*--------------------review Questions -------------------------*/}

            {/*--------------------last msg by--------------------------------*/}

            {LastMsgIs && <LastMsgBy {...this.state} {...this.props} />}

            {/*--------------------last msg by--------------------------------*/}

            {/*-----------------------Project status -----------------------*/}

            {StatusIs === 7 && (
              <ProjectStatus projectCompDate={projectCompDate} />
            )}

            {/*-----------------------Project status ------------------------*/}
          </form>
         
         
         
{/*          
         
          {selectedOption && (
            <div className="row">
              <div className="col text-right">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={ (e) => this.handleSubmit()  
                  }
                //   disabled={
                //     StatusIs === 7 || StatusIs === 8 || StatusIs === 10
                //       ? "disabled"
                //       : ""
                //   }
                >
                 
                    submit
                  
                </button>
              </div>
            </div>
          )} */}
        </section>
        {/* seperation or joinment    */}
        <section className="container">
       <h2  className="header-title text-secondary">Creator Proposal</h2>
       <hr className="my-4"></hr>
          <Milestone2 {...this.state}{...this.props} handleFunc={milesFunc}/>
          <div className="row">
            <div className="col text-right">
              {/* <button
                type="submit"
                className="btn btn-primary"
                onClick={
                  EditIs ? e=>this.handleProposalSubmit():'ba'   
                }
                disabled={
                  statusIs === 7 || statusIs === 8 || statusIs === 10
                    ? "disabled"
                    : ""
                }
              >
                {EditIs ? (
                  LoaderIs ? (
                    <LoaderSmall />
                  ) : (
                    "Update proposal"
                  )
                ) : LoadingIs ? (
                  <LoaderSmall />
                ) : (
                  "Save proposal"
                )}
              </button>*/}
            </div>
            </div>
          {/*------------------submit milestones Modal---------------------*/}

          {/* {true && (
            <SubmitMilestone
              {...this.state}
              {...this.props}
              handleMiles={milesFunc}
            />
          )} */}

          {/*------------------submit milestones Modal---------------------*/}
        </section>

        <style>
          {`.react-date-picker__clear-button{
              display: none
            }
            .react-date-picker__wrapper{
              border : none
            }
            .disabled{
              opacity : 0.5 !important;
              background-color : #f0f0f0 !important
            }
            
            .react-date-picker__calendar.react-date-picker__calendar--open {
              top: 0!important;
          }
            `}
        </style>
        <button className='btn btn-primary' style={{float:'right',marginTop:'15px'}}onClick={e=>this.handleLastSubmit(e)}       disabled={
                    StatusIs === 7 || StatusIs === 8 || StatusIs === 10
                      ? "disabled"
                      : ""
                  }>Submit</button>
      </React.Fragment>
    );
  }
}
const mapState = ({
  AllCollateralList,
  getUserList,
  addPostProject,
  getPostProjectData,
  updateProject,
  addProposal,
  updateProposal,
  completedMiles,
  milestoneApproved,
}) => {

  return {
    _getPostProjectData: getPostProjectData,
    _addProposal: addProposal,
    _updateProposal: updateProposal,
    _completedMiles: completedMiles,
    _milestoneApproved: milestoneApproved,
    _AllCollateralList: AllCollateralList,
    _getUserList: getUserList,
    _addPostProject: addPostProject,
    _updateProject: updateProject,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $AllCollateralList: (values) => dispatchEvent(AllCollateralList(values)),
  $getUserList: (values) => dispatchEvent(getUserList(values)),
  $addPostProject: (values) => dispatchEvent(addPostProject(values)),
  $getPostProjectData: (values) => dispatchEvent(getPostProjectData(values)),
  $updateProject: (values) => dispatchEvent(updateProject(values)),

  $addProposal: (values) => dispatchEvent(addProposal(values)),
  $updateProposal: (values) => dispatchEvent(updateProposal(values)),
  $completedMiles: (values) => dispatchEvent(completedMiles(values)),
  $milestoneApproved: (values) => dispatchEvent(milestoneApproved(values)),
});
export default connect(mapState, mapDispatch)(CompleteProject);
