import React, { Component } from "react";
import { connect } from "react-redux";
import { getClientData } from "../../../store/action/clientList.action";
import ErrorApi from "../Common/Error/ErrorApi";

class ClientCompanyDetails extends Component {
  state = {
    companyName: "",
    role: "",
    employes: "",
    companyUrl: "",
    cityName: "",
    description: "",
  };

  handleOnChange = (e) => {
    const { name, value } = e.traget;
    this.setState({
      [name]: value,
    });
  };

  handleGetClient = (id) => {
    this.props.$getClientData(id);
  };

  componentDidMount() {
    // this.props.handleClients.handlepathName("/clients");
    const clientParamsId = this.props.match.params.id.split(":").pop();
    this.handleGetClient(clientParamsId);
  }

  componentWillReceiveProps(nextProps) {
    const clientDetails = nextProps._getClientData.clientDtaWithId;
    const companyData = clientDetails.userComapny
      ? clientDetails.userComapny
      : "";
    if (companyData) {
      this.setState({
        companyName: companyData.company_name,
        role: companyData.role,
        employes: companyData.no_of_employees,
        companyUrl: companyData.company_url,
        cityName: companyData.city_name,
        description: companyData.description,
      });
    }
  }

  render() {
    const {
      companyName,
      role,
      employes,
      companyUrl,
      cityName,
      description,
    } = this.state;

    const CompanyDetailsArr = [
      { label: "Company Name", name: "companyName", value: companyName },
      { label: "Role", name: "role", value: role },
      { label: "No of Employes", name: "employes", value: employes },
      { label: "Company Url", name: "companyUrl", value: companyUrl },
      { label: "City Name", name: "cityName", value: cityName },
      { label: "Description", name: "description", value: description },
    ];

    return this.props._getClientData.clientDtaErrIs ? (
      <ErrorApi {...this.props} />
    ) : (
      <React.Fragment>
        <section className="container">
          <form>
            <div className="row">
              {CompanyDetailsArr.map((obj, index) => (
                <div className="col-12 col-md-6" key={index}>
                  <div className="form-group">
                    <label>{obj.label}</label>
                    <input
                      type="text"
                      className={`form-control `}
                      name={obj.name}
                      value={obj.value}
                      onChange={this.handleOnChange}
                      autoComplete="off"
                      disabled="disabled"
                    />
                  </div>
                </div>
              ))}
            </div>
          </form>
          <hr className="my-5" />
        </section>
      </React.Fragment>
    );
  }
}
const mapState = ({ getClientData }) => {
  return {
    _getClientData: getClientData,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $getClientData: (values) => dispatchEvent(getClientData(values)),
});
export default connect(mapState, mapDispatch)(ClientCompanyDetails);
