import React, { Component } from "react";
import { connect } from "react-redux";
// import {
//   collateralUploadPhoto,
//   collateralPCFName,
//   getCollateral,
// } from "../../../../store/action/collateralList.action";
import {
  getArtistData,
  artistCleanUpdates,
  // locationUserSuccess,
  // artistDataList,
  // artistUpdateInfo,
  // artistApprovalList,

  // artistBusyList,
  // artistStarredList,

  // getClientData,
  // approvedArtist,
  // rejectedArtist
} from "../../../../store/action/clientList.action";
// import {
//   transactionGetData,
//   transactionList,
// } from "../../../../store/action/transaction.action";
import { postAPI,getPublicAPI } from "../../../../store/api-interface";
import ArtistHeaderTab from "./ArtistHeaderTab";
import CollateralHeaderTab from "./CollateralHeaderTab";
import ClientHeaderTab from "./ClientHeaderTab"; 
import BackendUserHeader from "./BackendUserHeader";
import TransactionHeaderTab from "./TransactionHeaderTab";
import HeaderUpperSection from "./HeaderUpperSection";
import HeaderRightSection from "./HeaderRightSection";
import ApprovalHeaderTab from './ApprovalHeaderTab';
import MilestoneHeaderTab from './MilestoneHeaderTab';
import PackagesHeaderTab from './PackagesHeaderTab';
import CustomHeader from './CustomHeader';

class Header extends Component {
  state = {
    detailsUpdateInfo: 0,
    collateralUpdateInfo: 0,
    workUpdateInfo: 0,
    projectUpdateInfo: 0,
    educationUpdateInfo: 0,
    updateProfileId: "",
    LoadingIs: false,
    DuplicateCollaterlIs: false,
    collateralIs: "",
    collateralStatus: "",
    allArtist: "",
    profileUpdateAll: "",
    approvalLength: "",
    busyLength: "",
    starredLength:"",
    artistFirstName: "",
    artistLastName: "",
    clientData: "",
    transactionData: "",
    transactionListLength: "",
    approvedArtistLength : '',
    rejectedArtistLength: '',
    currentTabs: [],
    amDashboard:[]

  };

  handleArtistUpdates = (id) => {
    this.props.$getArtistData(id);
  };

  handleClearUpdates = () => {
    const { updateProfileId } = this.state;
    this.setState({
      LoadingIs: true,
    });
    let user = { _id: updateProfileId };
    this.props.$artistCleanUpdates(user);
  };

  handleDuplicateCollateral = () => {
    const { collateralIs } = this.state;

    this.setState({
      DuplicateCollaterlIs: true,
    });

    const duplicateData = { _id: collateralIs._id };

    postAPI("/collateral/copy-collateral", duplicateData)
      .then((res) => {
        if (res.data.status === "1") {
          this.props.history.push("/collaterals");
          this.setState({
            DuplicateCollaterlIs: false,
          });
        }
      })
      .catch((error) => {
        console.log("add collateral api error==>", error);
      });
  };


  handleAuthHeader = () => {
    getPublicAPI(`/backendUser/get-admin-user-data`).then((res) => {
      if (res) {
        let tabIs = res.data.tabList;
        res.data.role && localStorage.setItem("adminRole", res.data.role);
        this.setState({ currentTabs: tabIs });
      }
    });
  }

  componentDidMount(){
    this.handleAuthHeader()
    // this.handleMangerDahboardList()
  }

  componentWillReceiveProps(nextProps) {
    const {
      artistActiveIs,
      clientActiveIs,
      transactionActiveIs,
      transactionAllFieldActive,
    } = this.props;
    const { LoadingIs } = this.state;
    const creatorData = nextProps._getArtistData.getCreatorData.userData;
    const artistListUpdate = nextProps._artistUpdateInfo.updateInfo;
    const clearData = nextProps._artistCleanUpdates.cleanUpdate;
    const collateralGettingData = nextProps._getCollateral.collateralDataIs;
    const artistListData = nextProps._artistDataList.artistDataList; 
    const approvalList = nextProps._artistApprovalList.approvalList;

    const busyList = nextProps._artistBusyList?.busyList;
    const starredList = nextProps._artistStarredList?.starredList;

    const clientDetails = nextProps._getClientData.clientDtaWithId;
    const postProjectData = nextProps._transactionGetData.transactionData;
    const transactionData = nextProps._transactionList.transactionDetails;
    const artistApprovedData = nextProps._approvedArtist.artistApprovedIs;
    const artistRejectedData = nextProps._rejectedArtist.rejectedArtist;

    if (creatorData) {
      this.setState({
        updateProfileId: creatorData._id,
        detailsUpdateInfo: creatorData.update_profile_status,
        collateralUpdateInfo: creatorData.update_collateral_status,
        workUpdateInfo: creatorData.update_work_status,
        projectUpdateInfo: creatorData.update_project_status,
        educationUpdateInfo: creatorData.update_education_status,
        artistFirstName: creatorData.first_name,
        artistLastName: creatorData.last_name,
      });
    }
    if (LoadingIs && clearData.status === "1") {
      this.setState({
        LoadingIs: false,
      });
      const artistId = this.props.location.pathname.split("/:").pop();
      this.props.$getArtistData(artistId);
      // this.props.history.push("/artist/artistProfileUpdates");
    }
    if (collateralGettingData) {
      this.setState({
        collateralIs: collateralGettingData.collateral,
        DuplicateCollaterlIs: false,
      });
    }
    if (artistListData && artistActiveIs) {
      this.setState({
        allArtist: artistListData.totalUsers,
      });
    }
    if (artistListUpdate) {
      this.setState({
        profileUpdateAll: artistListUpdate.totalUsers,
      });
    }
    if (approvalList) {
      this.setState({
        approvalLength: approvalList.totalUsers,
      });
    }


    if (busyList) {
      this.setState({
        busyLength: busyList.totalUsers,
      });
    }
    if (starredList) {
      this.setState({
        starredLength: starredList.totalUsers,
      });
    }


    if (clientDetails && clientActiveIs) {
      this.setState({
        clientData: clientDetails.data,
      });
    }
    if (postProjectData && transactionActiveIs) {
      this.setState({
        transactionData: postProjectData.project_data,
      });
    }
    if (transactionData && transactionAllFieldActive) {
      this.setState({
        transactionListLength: transactionData.totalPages,
      });
    }
    if (artistApprovedData) {
      this.setState({
        approvedArtistLength: artistApprovedData.totalUsers,
      });
    }
    if (artistRejectedData) {
      this.setState({
        rejectedArtistLength: artistRejectedData.totalUsers,
      });
    }
    
  }

  handleMangerDahboardList = () => {
    getPublicAPI(`/backendUser/transaction-dashboard`).then((res) => {
      if (res) {
        let data = res?.data?.data;
        let arr = []
        data?.length > 0 && res?.data?.role == 1 && arr.push({label: "All",value:""})
        data?.length > 0 && data.forEach(item => {
          arr.push({label: item?.first_name + " " + item?.last_name,value:item?._id})
        })
        this.setState({ amDashboard: arr });
      }
    }).catch((err)=>{
      if(err?.response?.status === 401){
        this.handleLogout();
      }
    });
  };

  render() {
    const headerFunc = {
      handleDuplicateCollateral: this.handleDuplicateCollateral,
      handleClearUpdates: this.handleClearUpdates,
    };

    const{ currentTabs } = this.state;
    const{ pathIs, RoleIs,location } = this.props;
    const headerRightSectionRoutes = location.pathname.split('/').some(r=> ['collaterals','artist','milestones'].includes(r))

    return (
      <React.Fragment>
        <div className="header">
          <div className="container-fluid">
            <div className={`${RoleIs === 1 ? `header-body` : currentTabs.map(item => item.tab).includes(pathIs) ? `header-body` : ""}`}>
              {/*------------------header overview Section ---------------------*/}

              <HeaderUpperSection
                {...this.state}
                {...this.props}
                handlefunc={headerFunc}
              />

              {/*------------------header overview Section ---------------------*/}

              <div className="row align-items-center">
                <div className="col">
                  {
                    RoleIs === 1 ?
                    <React.Fragment>
                      {location.pathname.split('/').includes('artist') && <ArtistHeaderTab {...this.state} {...this.props} />}
                      {location.pathname.split('/').includes('clients') && <ClientHeaderTab {...this.state} {...this.props} />}
                      {location.pathname.split('/').includes('collateralsQuestions') && <CollateralHeaderTab {...this.state} {...this.props} />}
                      {location.pathname.split('/').includes('transactions') && <TransactionHeaderTab {...this.state} {...this.props} />}   
                      {location.pathname.split('/').includes('approvals') && <ApprovalHeaderTab {...this.state} {...this.props}/>} 
                      {location.pathname.split('/').includes('milestones') && <MilestoneHeaderTab {...this.state} {...this.props}/>}
                      {location.pathname.split('/').includes('packages') && <PackagesHeaderTab {...this.state} {...this.props} />}
                      {location.pathname.split('/').includes('backendusers') && <BackendUserHeader {...this.state} {...this.props} />} 
                      {location.pathname.split('/').includes('custom') && <CustomHeader {...this.state} {...this.props} />} 
                    </React.Fragment> :
                    <React.Fragment>
                        {currentTabs.map(item => item.tab).includes("Artist") && <ArtistHeaderTab {...this.state} {...this.props} />}
                        {currentTabs.map(item => item.tab).includes("Clients") && <ClientHeaderTab {...this.state} {...this.props} />}
                        {currentTabs.map(item => item.tab).includes("Collaterals") && <CollateralHeaderTab {...this.state} {...this.props} />}
                        {currentTabs.map(item => item.tab).includes("Transactions") && <TransactionHeaderTab {...this.state} {...this.props} />}
                        {currentTabs.map(item => item.tab).includes("Approvals") && <ApprovalHeaderTab {...this.state} {...this.props} />}
                        {currentTabs.map(item => item.tab).includes("Milestones") && <MilestoneHeaderTab {...this.state} {...this.props} />}
                        {currentTabs.map(item => item.tab).includes("Packages") && <PackagesHeaderTab {...this.state} {...this.props} />}
                        {currentTabs.map(item => item.tab).includes("BackendUsers") && <BackendUserHeader {...this.state} {...this.props} />}
                        {currentTabs.map(item => item.tab).includes("custom") && <CustomHeader {...this.state} {...this.props} />}
                    </React.Fragment>
                  }                  
                 
                </div>

                {/*--------------Header Right Section ---------------------*/}
                {headerRightSectionRoutes && <HeaderRightSection
                  {...this.state}
                  {...this.props}
                  handlefunc={headerFunc}
                />}

                {/*--------------Header Right Section ---------------------*/}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapState = ({
  collateralUploadPhoto,
  collateralPCFName,
  getCollateral,
  getArtistData,
  artistCleanUpdates,
  artistDataList,
  artistUpdateInfo,
  artistApprovalList,

  artistBusyList,
  artistStarredList,

  getClientData,
  transactionGetData,
  transactionList,
  approvedArtist,
  rejectedArtist
}) => {
  return {
    _collateralUploadPhoto: collateralUploadPhoto,
    _collateralPCFName: collateralPCFName,
    _getCollateral: getCollateral,
    _getArtistData: getArtistData,
    _artistCleanUpdates: artistCleanUpdates,
    _artistDataList: artistDataList,
    _artistUpdateInfo: artistUpdateInfo,
    _artistApprovalList: artistApprovalList,

    _artistBusyList: artistBusyList,
    _artistStarredList:artistStarredList,

    _getClientData: getClientData,
    _transactionGetData: transactionGetData,
    _transactionList: transactionList,
    _approvedArtist : approvedArtist,
    _rejectedArtist : rejectedArtist
  };
};
const mapDispatch = (dispatchEvent) => ({
  // $collateralUploadPhoto: (values) => dispatchEvent(collateralUploadPhoto(values)),
  // $collateralPCFName: (values) => dispatchEvent(collateralPCFName(values)),
  // $getCollateral: (values) => dispatchEvent(getCollateral(values)),
  $getArtistData: (values) => dispatchEvent(getArtistData(values)),
  $artistCleanUpdates: (values) => dispatchEvent(artistCleanUpdates(values)),
  // $artistDataList: (values) => dispatchEvent(artistDataList(values)),
  // $artistUpdateInfo: (values) => dispatchEvent(artistUpdateInfo(values)),
  // $artistApprovalList: (values) => dispatchEvent(artistApprovalList(values)),

  // $artistBusyList: (values) => dispatchEvent(artistBusyList(values)),
  // $artistStarredList: (values) => dispatchEvent(artistStarredList(values)),

  // $getClientData: (values) => dispatchEvent(getClientData(values)),
  // $transactionGetData: (values) => dispatchEvent(transactionGetData(values)),
  // $transactionList: (values) => dispatchEvent(transactionList(values)),
  // $approvedArtist: (values) => dispatchEvent(approvedArtist(values)),
  // $rejectedArtist: (values) => dispatchEvent(rejectedArtist(values)),
});
export default connect(
  mapState,
  mapDispatch
)(Header);
