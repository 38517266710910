import React, { Component } from "react";
import { profileBaseUrl } from "../../../Utils/BaseUrl";

export default class CollateralQuestionForm extends Component {
  
  /*--------------drag and drop ------------------*/

  handleDragStart = (e, index) => {
    let dragData = JSON.stringify({ indexIs: index });
    e.dataTransfer.setData("DragIs", dragData);
  };

  handleDragOver = (e) => {
    e.preventDefault();
    return false;
  };

  handleDrop = (e, index) => {
    e.preventDefault();
    let formOption = JSON.parse(e.dataTransfer.getData("DragIs"));
    let toOption = { indexIs: index }; 

    this.handleMoveOptions(formOption, toOption);
  };

  handleMoveOptions = (from, to) => {    
    let currentOptionIs = this.props.optionImageIs.slice();
    let fromIndex = -1;
    let toIndex = -1;

    for (let i = 0; i < currentOptionIs.length; i++) {
      if (i === from.indexIs) {
        fromIndex = i;
      }
      if (i === to.indexIs) {
        toIndex = i;
      }
    }

    if (fromIndex !== -1 && toIndex !== -1) {   

      currentOptionIs.splice(toIndex, 0, currentOptionIs.splice(fromIndex, 1)[0]);           

      this.props.handlefunc.setCollateralState({
        optionImageIs: currentOptionIs,
      });
    }
  };

  handleDropSimpleOptions = (e, index)=>{
    e.preventDefault();
    let formOption = JSON.parse(e.dataTransfer.getData("DragIs"));
    let toOption = { indexIs: index };

    this.handleSwapSimpleOptions(formOption, toOption);
  }

  handleSwapSimpleOptions = (from , to) => {
    
    let dropdownOptions = this.props.OptionInputs.slice();
    let fromIndex = -1;
    let toIndex = -1;

    for (let i = 0; i < dropdownOptions.length; i++) {
      if (i === from.indexIs) {
        fromIndex = i;
      }
      if (i === to.indexIs) {
        toIndex = i;
      }
    }

    if (fromIndex !== -1 && toIndex !== -1) {

      dropdownOptions.splice(toIndex, 0, dropdownOptions.splice(fromIndex, 1)[0]);     

      this.props.handlefunc.setCollateralState({
        OptionInputs: dropdownOptions,
      });
    }
  }; 



  /*--------------drag and drop ------------------*/

  render() {
    const {
      styleQsList,
      essentialList,
      projectList,
      companyList,
      styleDropDownQs,
      essentialDropdownQs,
      projectDropdownQs,
      companyDropdownQs,
      essentialQs,
      projectDetailsQs,
      companyDetailsQs,
      styleQs,
      styleOptionEdit,
      essentialOptionEdit,
      projectOptionEdit,
      companyOptionEdit,
      optionImageIs,
      optionUploadImage,
      typeQsList,
      typeDropdownQs,
      typeQs,
      typeOptionEdit,

      handlefunc,
      subtext,
      qsMandatory,
      ansPlaceholder,
      startRange,
      endRange,
      ansLimit,
      imagesTextOption,
      currentImageIs,
      textOptionEdit,
      otherOption,
    } = this.props;

    return (
      <React.Fragment>
        <div className="mx-auto">
          <div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1" className="text-secondary">
                Question
              </label>
              <input
                placeholder="Enter Qs"
                className="form-control"
                name={
                  styleQsList
                    ? "styleDropDownQs"
                    : essentialList
                    ? "essentialDropdownQs"
                    : projectList
                    ? "projectDropdownQs"
                    : companyList
                    ? "companyDropdownQs"
                    : typeQsList
                    ? "typeDropdownQs"
                    : ""
                }
                value={
                  styleQsList
                    ? `${styleDropDownQs}`
                    : essentialList
                    ? `${essentialDropdownQs}`
                    : projectList
                    ? `${projectDropdownQs}`
                    : companyList
                    ? `${companyDropdownQs}`
                    : typeQsList
                    ? `${typeDropdownQs}`
                    : ""
                }
                onChange={handlefunc.handleOnchange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1" className="text-secondary">
                Subtext
              </label>
              <input
                type="text"
                placeholder="Enter subtext"
                name="subtext"
                value={subtext}
                className="form-control"
                onChange={handlefunc.handleOnchange}
              />
            </div>

            {((projectDetailsQs === "checkbox_with_images_with_text" ||
              projectDetailsQs === "radio_button_with_images_with_text" ||
              projectDetailsQs === "scale_range" ||
              projectDetailsQs === "file_upload") &&
              projectList) ||
            ((companyDetailsQs === "checkbox_with_images_with_text" ||
              companyDetailsQs === "radio_button_with_images_with_text" ||
              companyDetailsQs === "scale_range" ||
              companyDetailsQs === "file_upload") &&
              companyList) ||
            ((typeQs === "checkbox_with_images_with_text" ||
              typeQs === "radio_button_with_images_with_text") &&
              typeQsList) ||
            ((styleQs === "checkbox_with_images_with_text" ||
              styleQs === "radio_button_with_images_with_text") &&
              styleQsList) ? (
              ""
            ) : (
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="text-secondary">
                  Answer Placeholder
                </label>
                <input
                  type="text"
                  placeholder="Enter placeholder"
                  name="ansPlaceholder"
                  value={ansPlaceholder}
                  className="form-control"
                  onChange={handlefunc.handleOnchange}
                />
              </div>
            )}

            {(projectList || companyList) && (
              <span>
                <div className="form-group">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="text-secondary"
                  >
                    Mandatory Question
                  </label>
                  <div className="row col">
                    <div className="col ml-n2">
                      <small className="text-muted">
                        <input
                          type="radio"
                          checked={qsMandatory === "1"}
                          name="qsMandatory"
                          value="1"
                          onChange={handlefunc.handleOnchange}
                        />{" "}
                        Yes
                      </small>
                    </div>
                    <div className="col ml-n2">
                      <small className="text-muted">
                        <input
                          type="radio"
                          name="qsMandatory"
                          checked={qsMandatory === "0"}
                          value="0"
                          onChange={handlefunc.handleOnchange}
                        />{" "}
                        No
                      </small>
                    </div>
                  </div>
                </div>

                {(((projectDetailsQs === "long_answer" ||
                  projectDetailsQs === "short_answer") &&
                  projectList) ||
                  ((companyDetailsQs === "long_answer" ||
                    companyDetailsQs === "short_answer") &&
                    companyList)) && (
                  <div className="form-group">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="text-secondary"
                    >
                      Answer Limit
                    </label>
                    <input
                      type="number"
                      placeholder="Enter limit"
                      name="ansLimit"
                      value={ansLimit}
                      className="form-control"
                      onChange={handlefunc.handleOnchange}
                    />
                  </div>
                )}

                {((projectDetailsQs === "scale_range" && projectList) ||
                  (companyDetailsQs === "scale_range" && companyList)) && (
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="text-secondary"
                        >
                          Start Range
                        </label>
                        <input
                          type="number"
                          placeholder="Enter subtext"
                          name="startRange"
                          value={startRange}
                          className="form-control"
                          onChange={handlefunc.handleOnchange}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="text-secondary"
                        >
                          End Range
                        </label>
                        <input
                          type="number"
                          placeholder="Enter subtext"
                          name="endRange"
                          value={endRange}
                          className="form-control"
                          onChange={handlefunc.handleOnchange}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </span>
            )}

            <div className="form-group">
              <div className="form-row">
                {((typeQs === "checkbox_with_images_with_text" ||
                  typeQs === "radio_button_with_images_with_text") &&
                  typeQsList) ||
                ((styleQs === "checkbox_with_images_with_text" ||
                  styleQs === "radio_button_with_images_with_text") &&
                  styleQsList) ||
                ((essentialQs === "checkbox_with_images_with_text" ||
                  essentialQs === "radio_button_with_images_with_text") &&
                  essentialList) ||
                ((projectDetailsQs === "checkbox_with_images_with_text" ||
                  projectDetailsQs === "radio_button_with_images_with_text") &&
                  projectList) ||
                ((companyDetailsQs === "checkbox_with_images_with_text" ||
                  companyDetailsQs === "radio_button_with_images_with_text") &&
                  companyList) ? (
                  <div className=" form-group col-md-12">
                    <div className="d-flex col-md-4">
                      <input
                        className="form-control mt-3"
                        type="file"
                        name="image"
                        onChange={(e) => handlefunc.handleformOnChange(e)}
                        ref={(ref) => (this.uploadOption = ref)}
                        accept="jpg,jpeg,png,gif"
                        style={{ display: "none" }}
                      />
                      <input
                        type="text"
                        className="form-control col-md-12 mr-2"
                        autoComplete="off"
                        placeholder="Enter text"
                        name="imagesTextOption"
                        value={imagesTextOption}
                        onChange={handlefunc.handleOnchange}
                      />
                      {currentImageIs && (
                        <button
                          type="button"
                          onClick={() => this.uploadOption.click()}
                          className="btn btn-outline-primary"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Add Image
                        </button>
                      )}
                      {!otherOption &&
                        (((typeQs === "checkbox_with_images_with_text" ||
                          typeQs === "radio_button_with_images_with_text") &&
                          typeQsList) ||
                          ((styleQs === "checkbox_with_images_with_text" ||
                            styleQs === "radio_button_with_images_with_text") &&
                            styleQsList) ||
                          ((projectDetailsQs ===
                            "checkbox_with_images_with_text" ||
                            projectDetailsQs ===
                              "radio_button_with_images_with_text") &&
                            projectList) ||
                          ((companyDetailsQs ===
                            "checkbox_with_images_with_text" ||
                            companyDetailsQs ===
                              "radio_button_with_images_with_text") &&
                            companyList)) && (
                          <button
                            className="btn btn-primary ml-2"
                            onClick={handlefunc.handleOtherOptions}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Other option
                          </button>
                        )}
                      {textOptionEdit && (
                        <button
                          className="btn btn-info ml-2"
                          onClick={handlefunc.handleImageWithTextOptionUpdate}
                        >
                          {" "}
                          update
                        </button>
                      )}
                    </div>
                    <div className="row col">
                      {!textOptionEdit &&
                        optionImageIs.map((obj, index) => (
                          <div
                            className="mt-2 col-3"
                            key={index}
                            draggable="true"
                            onDragStart={(e) => this.handleDragStart(e, index)}
                             onDragOver={(e) => this.handleDragOver(e, index)}                           
                            onDrop={(e) => this.handleDrop(e, index)}
                            style={{ cursor: "move" }}
                          >
                            <span className="d-flex align-items-center border p-3">
                              <p className="mr-2 mb-0 col">{obj.item}</p>
                              <img
                                src={profileBaseUrl + obj.image}
                                height="30px"
                                width="30px"
                                style={{ paddingRight: "5px" }}
                                alt=""
                              />
                              {!(obj.item === "other") && (
                                <i
                                  className="fe fe-edit mr-2"
                                  onClick={() =>
                                    handlefunc.handleOptionEdit(index, obj)
                                  }
                                ></i>
                              )}
                              <i
                                className="fe fe-trash mr-3"
                                onClick={() =>
                                  handlefunc.handleRemoveOptionImage(obj, index)
                                }
                              ></i>
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    {this.props.OptionInputs.map((obj, index) => (
                      <div className="form-group col" key={index}>
                        <div className="d-flex ">
                          <input
                            className="form-control"
                            placeholder="Enter option"
                            name="item"
                            value={obj.item}
                            onChange={(e) =>
                              handlefunc.handleformOnChange(e, index)
                            }
                            draggable="true"
                            onDragStart={(e) => this.handleDragStart(e, index)}
                            onDragOver={(e) => this.handleDragOver(e, index)}
                            onDrop={(e) => this.handleDropSimpleOptions(e, index)}
                            style={{ cursor: "move" }}
                          />
                          <span className="p-2 d-flex align-items-center">
                            <i
                              className="fe fe-trash"
                              onClick={() =>
                                handlefunc.handleRemoveOption(index)
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {((typeQs === "checkbox_with_images_with_text" ||
                typeQs === "radio_button_with_images_with_text") &&
                typeQsList) ||
              ((styleQs === "checkbox_with_images_with_text" ||
                styleQs === "radio_button_with_images_with_text") &&
                styleQsList) ||
              (projectDetailsQs === "short_answer" && projectList) ||
              (projectDetailsQs === "checkbox_with_images_with_text" &&
                projectList) ||
              (projectDetailsQs === "scale_range" && projectList) ||
              (projectDetailsQs === "date_picker" && projectList) ||
              (projectDetailsQs === "long_answer" && projectList) ||
              (projectDetailsQs === "file_upload" && projectList) ||
              (projectDetailsQs === "radio_button_with_images_with_text" &&
                projectList) ||
              (companyDetailsQs === "short_answer" && companyList) ||
              (companyDetailsQs === "checkbox_with_images_with_text" &&
                companyList) ||
              (companyDetailsQs === "scale_range" && companyList) ||
              (companyDetailsQs === "date_picker" && companyList) ||
              (companyDetailsQs === "long_answer" && companyList) ||
              (companyDetailsQs === "radio_button_with_images_with_text" &&
                companyList) ||
              (companyDetailsQs === "file_upload" && companyList) ? (
                ""
              ) : (
                <span>
                  <button
                    className="btn btn-secondary"
                    onClick={handlefunc.appendOption}
                  >
                    Add Options
                  </button>
                </span>
              )}
              <button
                className="btn btn-success ml-3"
                onClick={
                  styleOptionEdit ||
                  essentialOptionEdit ||
                  typeOptionEdit ||
                  projectOptionEdit ||
                  companyOptionEdit
                    ? ((styleQs === "checkbox_with_images_with_text" ||
                        styleQs === "radio_button_with_images_with_text") &&
                        styleQsList) ||
                      (essentialQs === "checkbox_with_images_with_text" &&
                        essentialList) ||
                      ((projectDetailsQs === "checkbox_with_images_with_text" ||
                        projectDetailsQs ===
                          "radio_button_with_images_with_text") &&
                        projectList) ||
                      ((typeQs === "checkbox_with_images_with_text" ||
                        typeQs === "radio_button_with_images_with_text") &&
                        typeQsList) ||
                      ((companyDetailsQs === "checkbox_with_images_with_text" ||
                        companyDetailsQs ===
                          "radio_button_with_images_with_text") &&
                        companyList)
                      ? handlefunc.handleUpdateQsImage
                      : handlefunc.handleUpdateOptions
                    : optionUploadImage
                    ? handlefunc.handleimageOptionsField
                    : handlefunc.handleSaveOptions
                }
              >
                {styleOptionEdit ||
                essentialOptionEdit ||
                projectOptionEdit ||
                typeOptionEdit ||
                companyOptionEdit
                  ? "Update"
                  : "Save"}
              </button>
              {(styleOptionEdit ||
                essentialOptionEdit ||
                projectOptionEdit ||
                typeOptionEdit ||
                companyOptionEdit) && (
                <button
                  className="btn btn-info ml-3"
                  onClick={handlefunc.handleQsCancel}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
