import React, { Component } from "react";
import { connect } from "react-redux";
import { getCollateral } from "../../../store/action/collateralList.action";
import { profileBaseUrl } from "../../../Utils/BaseUrl";
import CollateralQuestionForm from "./CollateralQuestionForm";
import LoaderSmall from "../Common/Loader/LoaderSmall";
import CollateralHeadingSubtext from "./CollateralHeadingSubtext";

class CollateralQuestion extends Component {
  render() {
    const currentVal = this.props;
    const { handlefunc } = this.props;

    const StyleQues = [
      { name: "dropdown", value: "dropdown" },
      {
        name: "checkbox with images with text",
        value: "checkbox_with_images_with_text",
      },
      {
        name: "radio button with images with text",
        value: "radio_button_with_images_with_text",
      },
    ];

    const ProjectDetailQs = [
      { name: "dropdown", value: "dropdown" },
      {
        name: "checkbox with images with text",
        value: "checkbox_with_images_with_text",
      },
      {
        name: "radio button with images with text",
        value: "radio_button_with_images_with_text",
      },
      {
        name: "long answer",
        value: "long_answer",
      },
      { name: "short answer", value: "short_answer" },
      {
        name: "file upload",
        value: "file_upload",
      },
      { name: "scale range", value: "scale_range" },
      {
        name: "date picker",
        value: "date_picker",
      },
    ];

    const unitArr = [
      { name: "unit", value: currentVal.unit, label: "Define Unit" },
      { name: "ubitQs", value: currentVal.ubitQs, label: "Unit question" },
      {
        name: "ubitQsSubtext",
        value: currentVal.ubitQsSubtext,
        label: "Unit question (Subtext)",
      },
    ];

    return (
      <React.Fragment>
        <section className="container">
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label htmlFor="exampleInputEmail1" className="text-secondary">
                  Pricing Unit
                </label>
                <select
                  className="form-control text-secondary"
                  name="parentUnit"
                  value={currentVal.parentUnit ? currentVal.parentUnit : ""}
                  onChange={handlefunc.handleOnchange}
                >
                  <option value="">Select Option</option>
                  <option value="per_project">Per Project</option>
                  <option value="per_unit">Per Unit</option>
                </select>
              </div>
            </div>
          </div>

          {/*----------------------unit ------------------------*/}
          {currentVal.parentUnit === "per_unit" && (
            <div className="row">
              {unitArr.map((obj, index) => (
                <div className="form-group col-6" key={index}>
                  <div className="col-12">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="text-secondary"
                    >
                      {obj.label}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name={obj.name}
                      value={obj.value}
                      onChange={handlefunc.handleOnchange}
                    />
                  </div>
                </div>
              ))}              
            </div>
          )}
          {/*----------------------unit ------------------------*/}

          <hr className="my-5" />

          {/*------------------all Heading Subtext placeholder -----------------------*/}

          <CollateralHeadingSubtext {...this.state} {...this.props} />

          {/*------------------all Heading Subtext placeholder-----------------------*/}

          <hr className="my-5" />

          <div className="form-group">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="exampleInputEmail1" className="text-secondary">
                  Type Qs
                </label>
                {(currentVal.typeQsIs.length === 0 ||
                  currentVal.typeOptionEdit) && (
                  <select
                    className="form-control text-secondary"
                    name="typeQs"
                    value={currentVal.typeQs}
                    onChange={handlefunc.handleOnchange}
                  >
                    <option>Select Option</option>
                    {StyleQues.map((obj, index) => (
                      <option value={obj.value} key={index}>
                        {obj.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              {currentVal.typeQsList && (
                <div className="col-md-12 mt-5">
                  <CollateralQuestionForm {...this.state} {...this.props} />
                </div>
              )}
              {!currentVal.typeOptionEdit && (
                <div className="col-md-12">
                  {currentVal.typeQsIs.map((obj, index) => (
                    <div className="mx-auto mb-3" key={index}>
                      <div className="questin-Listing">
                        <div className="row col-md-12">
                          <div className="col-md-4">
                            <h5 className="mb-0 text-secondary">{obj.type}</h5>
                          </div>
                          <div className="col-md-6">
                            <ul className="mb-0 pl-0 text-secondary show-ques">
                              <li>Qs : {obj.question}</li>
                            </ul>
                          </div>
                          <div className="col-md-2">
                            <ul className="mb-0 mr-auto d-flex">
                              <li>
                                <i
                                  className="fe fe-edit"
                                  style={{
                                    display: "inline-block",
                                    marginRight: "5px",
                                  }}
                                  onClick={() =>
                                    handlefunc.handleTypeEditQues(obj, index)
                                  }
                                ></i>
                              </li>
                              <li>
                                <i
                                  className="fe fe-trash"
                                  onClick={() =>
                                    handlefunc.handleTypeQsDelete(index)
                                  }
                                ></i>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <ul className="mb-0 pl-0 text-secondary show_option">
                            Options :
                            {obj.option.map((val, index) => (
                              <li
                                key={index}
                                style={{
                                  display: "inline-block",
                                  marginRight: "10px",
                                }}
                              >
                                {!val.image ? (
                                  val.item
                                ) : val.image ? (
                                  <span className="d-flex">
                                    <p className="mb-0 mr-2">{val.item}</p>
                                    <img
                                      src={`${profileBaseUrl + val.image}`}
                                      height="20px"
                                      width="20px"
                                      alt=""
                                    />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <hr className="my-5" />

          <div className="form-group">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="exampleInputEmail1" className="text-secondary">
                  Style Qs
                </label>
                {(currentVal.styleQuestionIs.length === 0 ||
                  currentVal.styleOptionEdit) && (
                  <select
                    className="form-control text-secondary"
                    name="styleQs"
                    value={currentVal.styleQs}
                    onChange={handlefunc.handleOnchange}
                  >
                    <option>Select Option</option>
                    {StyleQues.map((obj, index) => (
                      <option value={obj.value} key={index}>
                        {obj.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              {currentVal.styleQsList && (
                <div className="col-md-12 mt-5">
                  <CollateralQuestionForm {...this.state} {...this.props} />
                </div>
              )}
              {!currentVal.styleOptionEdit && (
                <div className="col-md-12">
                  {currentVal.styleQuestionIs.map((obj, index) => (
                    <div className="mx-auto mb-3" key={index}>
                      <div className="questin-Listing">
                        <div className="row col-md-12">
                          <div className="col-md-4">
                            <h5 className="mb-0 text-secondary">{obj.type}</h5>
                          </div>
                          <div className="col-md-6">
                            <ul className="mb-0 pl-0 text-secondary show-ques">
                              <li>Qs : {obj.question}</li>
                            </ul>
                          </div>
                          <div className="col-md-2">
                            <ul className="mb-0 mr-auto d-flex">
                              <li>
                                <i
                                  className="fe fe-edit"
                                  style={{
                                    display: "inline-block",
                                    marginRight: "5px",
                                  }}
                                  onClick={() =>
                                    handlefunc.handleStyleEditQues(obj, index)
                                  }
                                ></i>
                              </li>
                              <li>
                                <i
                                  className="fe fe-trash"
                                  onClick={() =>
                                    handlefunc.handleStyleQsDelete(index)
                                  }
                                ></i>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <ul className="mb-0 pl-0 text-secondary show_option">
                            Options :
                            {obj.option.map((val, index) => (
                              <li
                                key={index}
                                style={{
                                  display: "inline-block",
                                  marginRight: "10px",
                                }}
                              >
                                {!val.image ? (
                                  val.item
                                ) : val.image ? (
                                  <span className="d-flex">
                                    <p className="mb-0 mr-2">{val.item}</p>
                                    <img
                                      src={`${profileBaseUrl + val.image}`}
                                      height="20px"
                                      width="20px"
                                      alt=""
                                    />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <hr className="my-5" />

          <div className="form-group">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="exampleInputEmail1" className="text-secondary">
                  Project Detail Qs
                </label>
                <select
                  className="form-control text-secondary"
                  name="projectDetailsQs"
                  value={currentVal.projectDetailsQs}
                  onChange={handlefunc.handleOnchange}
                >
                  <option disabled="">Select Option</option>
                  {ProjectDetailQs.map((obj, index) => (
                    <option value={obj.value} key={index}>
                      {obj.name}
                    </option>
                  ))}
                </select>
              </div>
              {currentVal.projectList && (
                <div className="col-md-12 mt-5">
                  <CollateralQuestionForm {...this.state} {...this.props} />
                </div>
              )}
              <div className="col-md-12">
                {currentVal.projectQsIs.map((obj, index) => (
                  <div className="mx-auto mt-4" key={index}>
                    <div className="questin-Listing">
                      <div className="row col-md-12">
                        <div className="col-md-4">
                          <h5 className="mb-0 text-secondary">{obj.type}</h5>
                        </div>
                        <div className="col-md-6">
                          <ul className="mb-0 pl-0 text-secondary show-ques">
                            <li>Qs : {obj.question}</li>
                          </ul>
                        </div>
                        <div className="col-md-2">
                          <ul className="mb-0 mr-auto d-flex">
                            <li>
                              <i
                                className="fe fe-edit"
                                style={{
                                  display: "inline-block",
                                  marginRight: "5px",
                                }}
                                onClick={() =>
                                  handlefunc.handleProjectEditQues(obj, index)
                                }
                              ></i>
                            </li>
                            <li>
                              <i
                                className="fe fe-trash"
                                onClick={() =>
                                  handlefunc.handleProjectQsDelete(index)
                                }
                              ></i>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-12">
                          <ul className="mb-0 pl-0 text-secondary show_option">
                            Options :
                            {obj.option.map((val, index) => (
                              <li
                                key={index}
                                style={{
                                  display: "inline-block",
                                  marginRight: "10px",
                                }}
                              >
                                {!val.image ? (
                                  val.item
                                ) : val.image ? (
                                  <span className="d-flex">
                                    <p className="mb-0 mr-2">{val.item}</p>
                                    <img
                                      src={`${profileBaseUrl + val.image}`}
                                      height="20px"
                                      width="20px"
                                      alt=""
                                    />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <hr className="my-5" />

          <div className="form-group">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="exampleInputEmail1" className="text-secondary">
                  Company Detail Qs
                </label>
                <select
                  className="form-control text-secondary"
                  name="companyDetailsQs"
                  value={currentVal.companyDetailsQs}
                  onChange={handlefunc.handleOnchange}
                >
                  <option>Select option</option>
                  {ProjectDetailQs.map((obj, index) => (
                    <option value={obj.value} key={index}>
                      {obj.name}
                    </option>
                  ))}
                </select>
              </div>
              {currentVal.companyList && (
                <div className="col-md-12 mt-5">
                  <CollateralQuestionForm {...this.state} {...this.props} />
                </div>
              )}
              <div className="col-md-12">
                {currentVal.companyQsIs.map((obj, index) => (
                  <div className="mx-auto mt-4" key={index}>
                    <div className="questin-Listing">
                      <div className="row col-md-12">
                        <div className="col-md-4">
                          <h5 className="mb-2 text-secondary">{obj.type}</h5>
                        </div>
                        <div className="col-md-6">
                          <ul className="mb-0 pl-0 text-secondary show-ques">
                            <li>Qs : {obj.question}</li>
                          </ul>
                        </div>
                        <div className="col-md-2">
                          <ul className="mb-0 mr-auto d-flex">
                            <li>
                              <i
                                className="fe fe-edit"
                                style={{
                                  display: "inline-block",
                                  marginRight: "5px",
                                }}
                                onClick={() =>
                                  handlefunc.handleCompanyEditQues(obj, index)
                                }
                              ></i>
                            </li>
                            <li>
                              <i
                                className="fe fe-trash"
                                onClick={() =>
                                  handlefunc.handleCompanyQsDelete(index)
                                }
                              ></i>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-12">
                          <ul className="mb-0 pl-0 text-secondary show_option">
                            Options :
                            {obj.option.map((val, index) => (
                              <li
                                key={index}
                                style={{
                                  display: "inline-block",
                                  marginRight: "10px",
                                }}
                              >
                                {!val.image ? (
                                  val.item
                                ) : val.image ? (
                                  <span className="d-flex">
                                    <p className="mb-0 mr-2">{val.item}</p>
                                    <img
                                      src={`${profileBaseUrl + val.image}`}
                                      height="20px"
                                      width="20px"
                                      alt=""
                                    />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* {collateralViewParam !== "view" && ( */}
          <button
            type="button"
            className="btn btn-primary"
            onClick={
              currentVal.collateralEditIs
                ? handlefunc.handleUpdateCollateral
                : handlefunc.handleAddCollateral
            }
          >
            {currentVal.collateralEditIs ? (
              currentVal.LoadingIs ? (
                <LoaderSmall />
              ) : (
                "Update"
              )
            ) : currentVal.LoadingIs ? (
              <LoaderSmall />
            ) : (
              "Submit"
            )}
          </button>
          {/* )} */}
        </section>
      </React.Fragment>
    );
  }
}

const mapState = ({ getCollateral }) => {
  return {
    _getCollateral: getCollateral,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $getCollateral: (values) => dispatchEvent(getCollateral(values)),
});
export default connect(
  mapState,
  mapDispatch
)(CollateralQuestion);
