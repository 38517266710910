import React, { Component } from "react";
import { connect } from "react-redux";
import {
  incompleteTransactions,
  closeProject,
  deleteProjectList
} from "../../../store/action/transaction.action";
import Table from "./Table";
import ReactPaginate from "react-paginate";
import ErrorApi from "../Common/Error/ErrorApi";
import { deleteAPI } from "../../../store/api-interface";

class IncompleteTransactions extends Component {
  state = {
    transactionsList: 1,
    selectedPage: 1,
    transactionActive: false,
    LoadingIs: false,
    transactionData: [],
    transactionIs: "",
    minBudget: "",
    maxBudget: "",
    deadline: "",
    totalPages: 0,
    closeProjectActive: false,
    inCompleteTransaction: false,
    deleteActiveIs : false,
    headCheckbox: false,
    scopeValue:""
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        const {
          transactionsList,
          transactionIs,
          minBudget,
          maxBudget,
          deadline,
          scopeValue
        } = this.state;

        const transactionData = {
          List: transactionsList,
          project: transactionIs,
          min_budget: minBudget,
          max_budget: maxBudget,
          deadline: deadline,
          budget_scope: scopeValue.value
        };
        this.props.$incompleteTransactions(transactionData);
      }
    );
  };

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    const { transactionIs, minBudget, maxBudget, deadline,scopeValue } = this.state;
    const transactionData = {
      List: selectedData,
      project: transactionIs,
      min_budget: minBudget,
      max_budget: maxBudget,
      deadline: deadline,
      budget_scope: scopeValue.value
    };
    this.setState({selectedPage: selectedData});
    this.props.$incompleteTransactions(transactionData);
  };

  handleCloseProject = (id) => {
    this.setState({
      closeProjectActive: true,
    });
    const closeProjectData = { _id: id };
    this.props.$closeProject(closeProjectData);
  };

  handleDeleteProject = (id) => {
    this.setState({ deleteActiveIs : true})
    const currentData = { _id : id}
    this.props.$deleteProjectList(currentData)
  }

  handleInCompleteTransaction = () => {
    const { transactionsList, transactionIs } = this.state;

    this.setState({
      LoadingIs: true,
    });

    const transactionData = {
      List: transactionsList,
      project: transactionIs,
    };
    this.props.$incompleteTransactions(transactionData);
  };

  componentDidMount() {
    this.handleInCompleteTransaction();
    this.setState({
      inCompleteTransaction : true
    })
  }

  componentWillReceiveProps(nextProps) {
    const inCompletetransaction =
      nextProps._incompleteTransactions.incompleteList;

    const closedProject = nextProps._closeProject.closeProjectIs;
    const deleteProject = nextProps._deleteProjectList.deleteProjectIs;
    const { closeProjectActive, deleteActiveIs } = this.state;

    if (inCompletetransaction) {
      this.setState({
        transactionData: inCompletetransaction.PostProjects,
        LoadingIs: false,
        totalPages: inCompletetransaction.totalPages,
      });
    }
    if (closedProject && closeProjectActive) {
      alert(closedProject.message);
      this.setState({
        closeProjectActive: false,
      });
      this.handleInCompleteTransaction();
    }
    if(deleteProject && deleteActiveIs){
      this.setState({ deleteActiveIs : false })
      this.handleInCompleteTransaction()
    }
  }

  onCheckboxClick = (id) => {
    const {headCheckbox, transactionData} = this.state;
    if(id === 'all'){
      const newTransactionList = transactionData.map(tranx => {
        headCheckbox ? tranx.selected = false : tranx.selected = true;
        return tranx
      });
      this.setState({transactionData: newTransactionList, headCheckbox: !this.state.headCheckbox})
    } else {
      const newTransactionList = transactionData.map(tranx => {
        if(tranx._id === id){tranx.selected = !tranx.selected}
        return tranx
      });
      this.setState({transactionData: newTransactionList});
    }
  }

  handleDeleteAll = () => {
    const {transactionData} = this.state;
    this.setState({isDeleting: true});

    const deletedIds = []
    transactionData.forEach(tranx => {
      tranx.selected && deletedIds.push(tranx._id)
    });

    if(window.confirm('Are you sure you want to delete these transactions.')){
      deleteAPI(`/postProject/delete-postproject-multiple`, {_id: deletedIds}).then(res => {
        this.handlePageClick({selected: this.state.selectedPage - 1});
        this.setState({isDeleting: false});
      }).catch(err => {
        console.log(err);
        this.setState({isDeleting: false});
      })
    } else {
      this.setState({isDeleting: false});
    }
  }

  handleApiCall = () => {
    this.handleInCompleteTransaction();
    this.setState({
      inCompleteTransaction : true
    })
  }

  handleOnSelect = (selectedOption) => {
    // console.log("selectedOption = ", selectedOption, name)
    this.setState({scopeValue : selectedOption }, () => {
      const {
        transactionsList,
        transactionIs,
        minBudget,
        maxBudget,
        deadline,
        scopeValue
      } = this.state;

      const transactionData = {
        List: transactionsList,
        project: transactionIs,
        min_budget: minBudget,
        max_budget: maxBudget,
        deadline: deadline,
        budget_scope: scopeValue.value
      };
      this.props.$incompleteTransactions(transactionData);
    })
  };

  render() {
    const { totalPages, isDeleting } = this.state;
    const transactionFunc = {
      handleOnChange: this.handleOnChange,
      handleCloseProject: this.handleCloseProject,
      handleDeleteProject : this.handleDeleteProject,
      onCheckboxClick: this.onCheckboxClick,
      handleApiCall: this.handleApiCall,
      handleOnSelect: this.handleOnSelect
    };

    const anySelectedTranx = this.state.transactionData.some(val => val.selected);

    return (
      <React.Fragment>
        {this.props._incompleteTransactions.incmplteListErrIs ? (
          <ErrorApi {...this.props} />
        ) : (
          <div>
            <Table
              {...this.state}
              {...this.props}
              handleTransaction={transactionFunc}
            />

            <div className=''>
              {anySelectedTranx && 
                <button className="btn btn-danger"
                      onClick={this.handleDeleteAll}
                      disabled={isDeleting}
                >Delete selected</button>}

              {/******** pagination ************* */}

              {totalPages > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({ incompleteTransactions, closeProject, deleteProjectList }) => {
  return {
    _incompleteTransactions: incompleteTransactions,
    _closeProject: closeProject,
    _deleteProjectList : deleteProjectList
  };
};
const mapDispatch = (dispatchEvent) => ({
  $incompleteTransactions: (values) =>
    dispatchEvent(incompleteTransactions(values)),
  $closeProject: (values) => dispatchEvent(closeProject(values)),
  $deleteProjectList: (values) => dispatchEvent(deleteProjectList(values)),
});
export default connect(mapState, mapDispatch)(IncompleteTransactions);
