import {
  PROJECT_LIST_SUCCESS,
  PENDING_PROJECT_LIST_SUCCESS,
  GET_PENDING_PROJECT_DATA_SUCCESS,
  PROJECT_REVIEW_LIST_SUCCESS,
  GET_PROJECT_REVIEW_DATA_SUCCESS,
  PENDING_PROJECT_LIST_ERROR,
  GET_PROJECT_REVIEW_DATA_ERROR,
  ALL_COLLATERAL_LIST_SUCCESS,
  GET_USER_LIST_SUCCESS,
  ADD_POST_PROJECT_SUCCESS,
  GET_POST_PROJECT_DATA_SUCCESS,
  ADD_PROPOSAL_SUCCESS,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROPOSAL_SUCCESS,
  ADD_PROPOSAL_CYCLE_SUCCESS,
  DELETE_PROPOSAL_CYCLE_SUCCESS,
  EDIT_PROPOSAL_CYCLE_SUCCESS,
} from "../type.action";

const initState = {
  projectDetails: "",
  pendingList: "",
  getPendingData: "",
  projectReviewApproval: "",
  projectReviewDataIs: "",
  pendingListErr: "",
  projectReviewErrIs: "",
  allCollateralIs: "",
  userListIs: "",
  postProjectDataIs: "",
  addProjectIs: "",
  updateDataIs: "",
  addCycleIs:"",
  deleteCycleIs:"",
  editCycleIs:"",
};

const reducers = (state = initState, { type, values, error }) => {
  switch (type) {
    case PROJECT_LIST_SUCCESS:
      return {
        ...state,
        projectDetails: values,
      };
    case PENDING_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        pendingList: values,
      };
    case GET_PENDING_PROJECT_DATA_SUCCESS:
      return {
        ...state,
        getPendingData: values,
      };
    case PROJECT_REVIEW_LIST_SUCCESS:
      return {
        ...state,
        projectReviewApproval: values,
      };
    case GET_PROJECT_REVIEW_DATA_SUCCESS:
      return {
        ...state,
        projectReviewDataIs: values,
      };
    case PENDING_PROJECT_LIST_ERROR:
      return {
        ...state,
        pendingListErr: error,
      };
    case GET_PROJECT_REVIEW_DATA_ERROR:
      return {
        ...state,
        projectReviewErrIs: error,
      };
    case ALL_COLLATERAL_LIST_SUCCESS:
      return {
        ...state,
        allCollateralIs: values,
      };
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        userListIs: values,
      };
    case ADD_POST_PROJECT_SUCCESS:
      return {
        ...state,
        addProjectIs: values,
      };
    case GET_POST_PROJECT_DATA_SUCCESS:
      return {
        ...state,
        postProjectDataIs: values,
      };
    case ADD_PROPOSAL_SUCCESS:
      return {
        ...state,
        addProjectIs: values,
      };
    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        updateDataIs: values,
      };
    case UPDATE_PROPOSAL_SUCCESS:
      return{
        ...state,
        updateProposalIs : values
      }  
    case ADD_PROPOSAL_CYCLE_SUCCESS:
      return {
        ...state,
        addCycleIs:values
      }
    case DELETE_PROPOSAL_CYCLE_SUCCESS:
      return {
        ...state,
        deleteCycleIs:values
      }
    case EDIT_PROPOSAL_CYCLE_SUCCESS:
      return {
        ...state,
      editCycleIs:values
      }
    default:
      return state;
  }
};

export default reducers;
