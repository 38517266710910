import React, { useEffect, useState } from "react";
import CustomDatePicker from '../datePickerCustom'
import { addDays } from "../../../../../Utils/HelperFunction";
import Select from "react-select";
const MilestoneFixed = (props) => {
  const { handleFunc, milestoneInputs, statusIs, EditIs, proposed_start_date, payment_terms, paymentTermsOptions, PaymentTermsError } = props

  // const getPaymentTerms = () => {
  //   return milestoneInputs.map((el, i) => { if (el.name) return (String.fromCharCode(65 + i)) })
  // }


  return (
    <React.Fragment>
      <label className="text-secondary text-capitalize h3">Deliverables</label>
      <div className="row">
        <div className="col">
          <label
            htmlFor="exampleInputEmail1"
            className="text-secondary"
          >
            Milestone
          </label>
        </div>
        <div className="col">
          <label
            htmlFor="exampleInputEmail1"
            className="text-secondary"
          >
            Due Date
          </label>
        </div>
        <div className="col">
          {/* <label
                  htmlFor="exampleInputEmail1"
                  className="text-secondary"
                >
                 iterations
                </label> */}
        </div>
      </div>

      {milestoneInputs?.filter(el => el.type !== 'token' ? el : false)?.map((obj, index) => (
        <React.Fragment key={index}>
          <div className="row my-2">
            <div className="row col-11 ">
              <div className="col-1 d-flex align-items-end"><label className="d-flex align-items-end">{String.fromCharCode(65 + index)}</label></div>
              <div className="col">
                {/* <label
                  htmlFor="exampleInputEmail1"
                  className="text-secondary"
                >
                  {`Milestone (${index + 1})`}
                </label> */}
                <input
                  type="text"
                  disabled={obj?.restrict}
                  className={`form-control ${obj.nameError ? "error-show" : ""} ${obj.restrict ? 'disabled' : ""}`}
                  placeholder="Milestone Name"
                  name="name"
                  value={obj.name}
                  onChange={(e) => handleFunc.handleMilestoneChange(e, index)}
                />
                {obj.nameError && (
                  <span className="error">Required Field</span>
                )}
              </div>

              <div className="col">
                {/* <label
                  htmlFor="exampleInputEmail1"
                  className="text-secondary"
                >
                  Due Date
                </label> */}
                <CustomDatePicker
                  handleFunc={handleFunc}
                  obj={obj}
                  index={index}
                  selectedDate={milestoneInputs?.[index - 1]
                    ? new Date(addDays(milestoneInputs?.[index - 1].due_date, 1))
                    : new Date(proposed_start_date)}
                  maxDate={milestoneInputs?.[index + 1]
                    ? new Date(addDays(milestoneInputs?.[index + 1].due_date, -1))
                    : ""}
                  minimumDate={milestoneInputs?.[index - 1]
                    ? new Date(addDays(milestoneInputs?.[index - 1].due_date, 1))
                    : new Date(proposed_start_date)}
                  fixedProp={true}
                />
                {obj.dateError && (
                  <span className="error">
                    Required Field
                  </span>
                )}
              </div>

              <div className="col">
                {/* <input
                type="number"
                className={`form-control`}
                placeholder="iteration"
                name="iteration"
                value={0}
                onChange={(e) => handleFunc.handleMilestoneChange(e, index)}
                disabled={true}
              /> */}
                <input type="checkbox" className="ml-5" checked={obj?.toggle} onChange={(e)=>handleFunc.handleMilestoneChange(e,index)}/><label className="mx-2 text-secondary">Iterations</label>
              </div>
            </div>
            <div className="col-1 d-flex align-items-center">

              {index !== 0 && !obj.restrict &&
                (
                  <span className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                    <i
                      className="fe fe-trash"
                      onClick={() => handleFunc.handleRemoveMilestone(index, obj)}
                    ></i>
                  </span>
                )}

            </div>
          </div>
          {obj?.toggle ? (
            <div className="row  m-0 mb-3 justify-content-end">
              <div className="col-lg-11 pr-0">
                <div className="row table_toggle mx-0">
                  <div className="col-lg-5 col-5  d-flex justify-content-start align-items-center">
                    <span className="title">Number of iterations </span>
                    <div className="btn-group" role="group" aria-label="Basic example">
                      <button
                        type="button"
                        className="btn"
                        // disabled={
                        //   item?.status == 2 || item?.paid_status == 1 || item?.milestonecomplete?.length > 0
                        //     ? true
                        //     : false
                        // }
                        disabled={obj?.restrict ? true : false}
                        onClick={() => handleFunc.handleIteration(index, "minus")}
                      >
                        -
                      </button>
                      <input
                        type="text"
                        className="form-control text-center"
                        name="iteration"
                        value={obj.iteration}
                        disabled={true}
                      />
                      <button
                        type="button"

                        disabled={obj?.restrict ? true : false}
                        className="btn "
                        onClick={() => handleFunc.handleIteration(index, "plus")}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div
                    className="col-lg-7 col-7  d-flex justify-content-start align-items-center"
                    style={{ backgroundColor: "#F5F7FD" }}
                  >
                    <span className="mx-2">
                      Price Per iteration after <b>{obj.iteration} Iterations</b>
                    </span>
                    <div className="price_input">
                      <input
                        type="number"
                        name = 'iteration_charge'
                        className={`form-control input_sm ${obj.iterationError ? "border-danger" : ""}`}
                        placeholder="Price"
                        value={obj.iteration_charge}
                        onKeyDown={(evt) =>
                          (evt.key === "e" ||
                            evt.key === "=" ||
                            evt.key === "-" ||
                            evt.key === "+" ||
                            evt.key === "_" ||
                            evt.key === ".") &&
                          evt.preventDefault()
                        }
                        disabled={obj?.restrict ? true : false}
                        onChange={(e) => handleFunc.handleMilestoneChange(e, index)}
                      />
                      {/* <img src={PriceImage} className="price_icon img-fluid"></img> */}
                    </div>
                    {obj.iterationError ? <p className="text-danger pl-3">Required Field</p> : ""}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

        </React.Fragment>
      ))}
      {(milestoneInputs.slice(-1).pop().due_date && milestoneInputs.slice(-1).pop().name && (statusIs !== 7 && statusIs !== 8 && statusIs !== 10)) && (
        <React.Fragment>
          <div className="col-2">
            <p
              className="mt-3 "
              style={{ color: "#0056b3", cursor: "pointer" }}
              onClick={handleFunc.handleAppendMilestone}
            >
              + Add Milestone
            </p>
          </div>
        </React.Fragment>
      )}
      <hr className="my-5" />
      <div className="row">
        <div className="col-7">
          <label className="text-secondary text-capitalize h3">Payment Terms</label>
        </div>
        <div className="col-5">
          <input type="checkbox" onChange={(e)=>handleFunc.handleAdvancePayment(e)} checked={payment_terms.filter(el=>el.type=='token'?el:false).length}/><label className="mx-2 text-secondary">Advance Payment</label>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label
            htmlFor="exampleInputEmail1"
            className="text-secondary"
          >
            Payment  Milestone
          </label>
        </div>
        <div className="col">
          <label
            htmlFor="exampleInputEmail1"
            className="text-secondary ml-5"
          >
            Client Cost
          </label>
        </div>
        <div className="col">
          <label
            htmlFor="exampleInputEmail1"
            className="text-secondary"
          >
            Creator Cost
          </label>
        </div>
        <div className="col">
          <label
            htmlFor="exampleInputEmail1"
            className="text-secondary"
          >
            {/* GST */}
          </label>
        </div>
      </div>
      <React.Fragment>
        <div className="row ">
          <div className="col">


            {/* {milestoneInputs &&   <input
                    type="text"
                    className={`form-control`}
                    name="payment_terms"
                    value={milestoneInputs[0].name ?`completion of ${getPaymentTerms()}`:"-"}
                    // value={obj.iteration}
                    disabled={true}
                    />} */}

            {payment_terms.map((el, index) =>
              <div key={index} className='row my-2'>

                <div className="row col-11">
                  <div className="col-1 d-flex align-items-center"><label className="d-flex">{index + 1}</label></div>
                  <div className="col">
                    {/* <label
                      className="text-secondary">
                      Payment Milestone</label> */}
                    <Select options={paymentTermsOptions} className={`${el.nameError ? "error-show" : ""}`} hideSelectedOptions={false} onChange={value => handleFunc.handleChangePaymentTerms(value, index)} value={el.milestone_reference.map(e => ({ label: e.label, value: e.label }))} isDisabled={el.restrict || el.type=='token'} isMulti isOptionDisabled={(option) => option.disabled} />
                    {el.nameError && (
                      <span className="error">
                        Required Field
                      </span>
                    )}
                  </div >
                  <div className="col">
                    {/* <label
                      className="text-secondary">
                      Client Cost</label> */}
                    <input disabled={el.restrict} className={`form-control ${el.clientBudgetError ? "error-show" : ""} ${el.restrict ? 'disabled' : ""}`} placeholder="Breakup Client" name='client' onChange={(e) => handleFunc.handleChangeBreakup(e, index)} value={el.price_without_tax} />
                    {el.clientBudgetError && (
                      <span className="error">
                        Required Field
                      </span>
                    )}
                  </div>
                  <div className="col">
                    {/* <label
                      className="text-secondary">
                      Creator Cost
                    </label> */}
                    <input disabled={el.restrict} className={`form-control ${el.creatorCostError ? "error-show" : ""} ${el.restrict ? 'disabled' : ""}`} placeholder="Breakup Creator" name='creator' onChange={(e) => handleFunc.handleChangeBreakup(e, index)} value={el.total_earning} />
                    {el.creatorCostError && (
                      <span className="error">
                        Required Field
                      </span>
                    )}
                  </div>

                 <div className="col pt-3">
                  {/* { el.type!='token' && */}
                  <> <input type='checkbox' placeholder="Breakup Creator" name='creator' onChange={(e) => handleFunc.handleChangeTax(e, index)} disabled={el.restrict} checked={el.tax} value={el.tax} />
                    <label className="ml-2">GST</label></>
                  {/* } */}
                  </div>

                </div>
                <div className="col-1 d-flex align-items-center">
                  {index !== 0 && !el.restrict &&
                    (
                      <span className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <i
                          className="fe fe-trash"
                          onClick={() => handleFunc.handleRemovePaymentTerms(index, el)}
                        ></i>
                      </span>
                    )}

                </div>
              </div>
            )
            }

            {(paymentTermsOptions.filter(el => !el.disabled ? el : false).length > 0 && milestoneInputs.length > 1 && milestoneInputs.slice(-1).pop().due_date && milestoneInputs.slice(-1).pop().name && (statusIs !== 7 && statusIs !== 8 && statusIs !== 10)) && (
              <React.Fragment>
                <div className="col-2">
                  <p
                    className="mt-3 "
                    style={{ color: "#0056b3", cursor: "pointer" }}
                    onClick={handleFunc.handleAppendPaymentTerms}
                  >
                    + Add Payment Terms
                  </p>
                </div>
              </React.Fragment>
            )}
          </div>


          {/* {index !== 0 &&
                  !obj.submitted_iteration > 0 &&
                  (obj.status !== 2)&&
                    ( disabledId?._id !== obj._id) && (
                        <span className="p-2 d-flex align-items-center">
                      <i
                        className="fe fe-trash"
                        onClick={() => handleFunc.handleRemoveMilestone(index, obj)}
                      ></i>
                    </span>
                  )} */}

        </div>
        {PaymentTermsError && (
          <span className="error">Select all Milestones</span>
        )}
      </React.Fragment>
      <hr className="my-5" />

    </React.Fragment>
  )
}
export default MilestoneFixed;