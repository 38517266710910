import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../../Common/Loader/Loader";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { artistUpdateInfo } from "../../../../store/action/clientList.action";

class CreatorProfileUpdate extends Component {
  state = {
    LoadingIs: false,
    currentPageIs: 1,
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    ListData: [],
    totalPage: "",
    sortIs : -1,
    sortFlagIs : false
  };

  handleSorting = () =>{
    this.setState({ sortFlagIs : !this.state.sortFlagIs },()=>{
      const { firstname, lastname, username, email,currentPageIs } = this.state;
       let artist = null;
       if(this.state.sortFlagIs){
         artist = {
            page: currentPageIs,
            firstname: firstname,
            lastname: lastname,
            username: username,
            email: email,
            sort : 1
          };
       }else{
        artist = {
          page: currentPageIs,
          firstname: firstname,
          lastname: lastname,
          username: username,
          email: email,
          sort : -1
        };
       }
       this.setState({ sortIs : this.state.sortFlagIs ? 1 : -1 })
       this.props.$artistUpdateInfo(artist);
    })
  }

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    const { firstname, lastname, username, email, sortIs } = this.state;
    this.setState({
      LoadingIs: true,
    });
    let artist = {
      page: selectedData,
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
      sort : sortIs
    };
    this.props.$artistUpdateInfo(artist);
  };

  handleProfileUpdate = () => {
    const { currentPageIs, firstname, lastname, username, email, sortIs } = this.state;
    this.setState({
      LoadingIs: true,
    });
    let artist = {
      page: currentPageIs,
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
      sort : sortIs
    };
    this.props.$artistUpdateInfo(artist);
  };

  componentDidMount() {
    this.handleProfileUpdate();
  }

  componentWillReceiveProps(nextProps) {
    const artistListData = nextProps._artistUpdateInfo.updateInfo;
    if (artistListData) {
      this.setState({
        LoadingIs: false,
        ListData: artistListData.users,
        totalPage: artistListData.totalPages,
      });
    }
  }

  render() {
    const { LoadingIs, ListData } = this.state;

    const profileUpdateArr = [
      { name: "action" },
      { name: "firstname" },
      { name: "lastname" },
      { name: "username" },
      { name: "update date" },     
    ];

    return (
      <React.Fragment>
        <section className="client mt-5">
          <div className="container-fluid mx-auto">
            {LoadingIs ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <table className="table table-sm table-nowrap card-table">
                  {/*----------------- table head --------------------*/}
                  <thead>
                    <tr>
                      {profileUpdateArr.map((obj, index) => (
                        <React.Fragment key={index}>
                        {obj.name !== "update date" && <th >
                          <Link to="#!" className="text-muted">
                            {obj.name}
                          </Link>
                        </th>}
                       {obj.name === "update date" &&<th >
                          <Link to="#!" className="text-muted sort" data-sort="tables-row" onClick={this.handleSorting}>
                            {obj.name}
                          </Link>
                        </th>}
                        </React.Fragment>
                      ))}
                    </tr>
                  </thead>
                  {/*----------------- table head --------------------*/}
                  <tbody className="list">
                    {ListData.map((obj, index) => (
                      <tr key={index}>
                        <td className="text-method">
                          <Link to={`/artist/:view/:${obj._id}`}>
                            <button className="btn btn-outline-success btn-sm mr-2">
                              <i className="fe fe-eye"></i>
                            </button>
                          </Link>
                        </td>
                        <td className="orders-order">{obj.first_name}</td>
                        <td className="orders-product">{obj.last_name}</td>
                        <td className="orders-date">{obj.username}</td>
                        <td className="orders-total">
                          {obj.updatedAt.split("T").slice(0)[0]}
                        </td>                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {/****************** Pagination ********************/}

            {this.state.totalPage > 0 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </section>
      </React.Fragment>
    );
  }
}
const mapState = ({ artistUpdateInfo }) => {
  return {
    _artistUpdateInfo: artistUpdateInfo,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $artistUpdateInfo: (values) => dispatchEvent(artistUpdateInfo(values)),
});
export default connect(mapState, mapDispatch)(CreatorProfileUpdate);
