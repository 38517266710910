import React, { Component } from "react";
import { Link } from "react-router-dom";
import SidebarLink from "./SidebarLink";

export default class Sidebar extends Component {
  state = {
    showPages: true,
  };

  handlePages = () => {
    const { showPages } = this.state;
    this.setState({
      showPages: !showPages,
    });
  };

  render() {
    const { handleClients } = this.props;

    return (
      <React.Fragment>
        <nav
          className="navbar navbar-vertical fixed-left navbar-expand-md "
          id="sidebar"
          style={{ borderColor: "#e3ebf6" }}
        >
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#sidebarCollapse"
              aria-controls="sidebarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <Link className="navbar-brand" to="/">
              <img
                src={require("../../../Assets/img/logo-black.png")}
                className="navbar-brand-img 
                mx-auto"
                alt="..."
              />
            </Link>

            <div className="navbar-user d-md-none">
              <div className="dropdown">
                <Link
                  to="#!"
                  id="sidebarIcon"
                  className="dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="avatar avatar-sm avatar-online">
                    <img
                      src={require("../../../Assets/img/avatars/profiles/profile_image.png")}
                      className="avatar-img rounded-circle"
                      alt="..."
                    />
                  </div>
                </Link>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="sidebarIcon"
                >
                  <Link to="/resetPassword" className="dropdown-item">
                    Reset Password
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/"
                    onClick={handleClients.handleLogout}
                  >
                    Logout
                  </Link>
                </div>
              </div>
            </div>

            {/* <!-- Collapse --> */}
            <div className="collapse navbar-collapse" id="sidebarCollapse">
              <form className="mt-4 mb-3 d-md-none">
                <div className="input-group input-group-rounded input-group-merge">
                  <input
                    type="search"
                    className="form-control form-control-rounded form-control-prepended"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <span className="fe fe-search"></span>
                    </div>
                  </div>
                </div>
              </form>

              {/*-----------sidebar link ----------*/}

              <SidebarLink {...this.state} {...this.props} />

              {/*-----------sidebar link ----------*/}

              {/*-------- Push content down ------*/}
              <div className="mt-auto"></div>

              <div className="navbar-user d-none d-md-flex" id="sidebarUser">
                <div className="dropup">
                  <Link
                    to="#!"
                    id="sidebarIconCopy"
                    className="dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="avatar avatar-sm avatar-online">
                      <img
                        src={require("../../../Assets/img/avatars/profiles/profile_image.png")}
                        className="avatar-img rounded-circle"
                        alt="..."
                      />
                    </div>
                  </Link>

                  {/* <!-- Menu --> */}
                  <div
                    className="dropdown-menu"
                    aria-labelledby="sidebarIconCopy"
                  >
                    <Link to="/resetPassword" className="dropdown-item">
                      Reset Password
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/"
                      onClick={handleClients.handleLogout}
                    >
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <style jsx="true">
          {`
            .navbar-vertical
              .navbar-nav
              .nav-link[data-toggle="collapse"]:after {
              content: "";
            }
            .nav-link {
              color: #6e84a3;
            }
            .navbar-user {
              border-top-color: #e3ebf6;
            }
          `}
        </style>
      </React.Fragment>
    );
  }
}
