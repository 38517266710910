import {
  REPORT_FOR_PROJECT_BRIEF,
  REPORT_FOR_PROJECT_BRIEF_SUCCESS,
  REPORT_FOR_ENTERPRISE_PROJECT,
  REPORT_FOR_ENTERPRISE_PROJECT_SUCCESS,
  REPORT_FOR_PROJECT,
  REPORT_FOR_PROJECT_SUCCESS,
  REPORT_FOR_COLLATERAL,
  REPORT_FOR_COLLATERAL_SUCCESS,
  REPORT_FOR_ALL_ARTIST,
  REPORT_FOR_ALL_ARTIST_SUCCESS,
  REPORT_FOR_APPROVED_ARTIST,
  REPORT_FOR_APPROVED_ARTIST_SUCCESS,
  REPORT_FOR_CLIENT,
  REPORT_FOR_CLIENT_SUCCESS
} from "../type.action";

// All Project Brief Report
export const reportProjectBrief = (values) => ({
  type: REPORT_FOR_PROJECT_BRIEF,
  values,
});
export const reportProjectBriefSuccess = (values) => ({
  type: REPORT_FOR_PROJECT_BRIEF_SUCCESS,
  values,
});

// enterprise Project report
export const reportEnterpriseProject = (values) => ({
  type: REPORT_FOR_ENTERPRISE_PROJECT,
  values,
});
export const reportEnterpriseProjectSuccess = (values) => ({
  type: REPORT_FOR_ENTERPRISE_PROJECT_SUCCESS,
  values,
});

// All Time Brief Report ...
export const reportProject = (values) => ({
  type: REPORT_FOR_PROJECT,
  values,
});
export const reportProjectSuccess = (values) => ({
  type: REPORT_FOR_PROJECT_SUCCESS,
  values,
});

// All Time Artist Report (All)........
export const reportAllArtist = (values) => ({
  type: REPORT_FOR_ALL_ARTIST,
  values,
});
export const reportAllartistSuccess = (values) => ({
  type: REPORT_FOR_ALL_ARTIST_SUCCESS,
  values,
});

// All Time Artist Report (collateral wise) ....
export const reportCollateral = (values) => ({
  type: REPORT_FOR_COLLATERAL,
  values,
});
export const reportCollateralSuccess = (values) => ({
  type: REPORT_FOR_COLLATERAL_SUCCESS,
  values,
});

// approved artist list  report
export const reportApprovedArtist = (values) =>({
  type: REPORT_FOR_APPROVED_ARTIST,
  values
})
export const reportApprovedArtistSuccess = (values) => ({
  type: REPORT_FOR_APPROVED_ARTIST_SUCCESS,
  values
})

// report for client
export const reportClient = (values) => ({
  type: REPORT_FOR_CLIENT,
  values
})
export const reportClientSuccess = (values) => ({
  type: REPORT_FOR_CLIENT_SUCCESS,
  values
})
