import React, { Component } from "react";

export default class ProjectDeadline extends Component {
  render() {
      const{ selectedOption, deadlineStatus, handleFunc } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="mb-1">{selectedOption.deadline_heading}</label>
              <small className="form-text text-muted">
                {selectedOption.deadline_subtext}
              </small>

              <div className="row col">
                <div className="col ml-n2">
                  <small className="text-muted">
                    <input
                      type="radio"
                      name="deadlineStatus"
                      checked={Number( deadlineStatus) === 7}
                      value={7}
                      onChange={handleFunc.handleOnChange}
                    />{" "}
                    7
                  </small>
                </div>
                <div className="col ml-n2">
                  <small className="text-muted">
                    <input
                      type="radio"
                      name="deadlineStatus"
                      checked={Number(deadlineStatus) === 15}
                      value={15}
                      onChange={handleFunc.handleOnChange}
                    />{" "}
                    15
                  </small>
                </div>
                <div className="col ml-n2">
                  <small className="text-muted">
                    <input
                      type="radio"
                      name="deadlineStatus"
                      checked={Number(deadlineStatus) === 30}
                      value={30}
                      onChange={handleFunc.handleOnChange}
                    />{" "}
                    30
                  </small>
                </div>
                <div className="col ml-n2">
                  <small className="text-muted">
                    <input
                      type="radio"
                      checked={Number(deadlineStatus) === 0}
                      name="deadlineStatus"
                      value={0}
                      onChange={handleFunc.handleOnChange}
                    />{" "}
                    I don't have a deadline.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
