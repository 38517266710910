import React, { Component } from "react";

export default class PackageUploadImage extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row justify-content-between align-items-center">
          <div className="col">
            <div className="row align-items-center">
              <div className="col-auto">
                <div className="avatar">
                  <img
                    className="avatar-img rounded-circle"
                    src="https://ifn-images.s3.ap-south-1.amazonaws.com/profile_images/profile_image.png"
                    alt="..."
                  />
                </div>
              </div>
              <div className="col ml-n2">
                <h4 className="mb-1">Your avatar</h4>
                <small className="text-muted">PNG,JPG or JPEG only.</small>
              </div>
            </div>
          </div>
          <div className="col-auto">
            <input
              type="file"
              accept="jpg,jpeg.png"
              style={{ display: "none" }}
            />
            <button type="button" className="btn btn-sm btn-primary">
              Upload
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
