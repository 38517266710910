import React, { Component } from "react";
import { Switch, Route } from "react-router";
import Packages from "./Packages";
import AddPackages from './AddPackages/AddPackages';

export default class PackagesRoute extends Component {
  componentDidMount() {
    this.props.handleClients.handlepathName("/packages");
  }
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/packages/packageForm/:id" render={(props)=> <AddPackages {...props} />}/>
          <Packages {...this.state} {...this.props} />
        </Switch>
      </React.Fragment>
    );
  }
}
