import React, { Component } from "react";
import { profileBaseUrl } from "../../../Utils/BaseUrl";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { collateralList } from "../../../store/action/collateralList.action";
import LoaderSmall from "../Common/Loader/LoaderSmall";

class DashboardCollateral extends Component {
  state = {
    collateralList: 1,
    collateralData: [],
    name: "",
    CollateralLoading: false,
  };

  handleCollateralData = () => {
    this.setState({
      CollateralLoading: true,
    });
    const { collateralList, name } = this.state;
    const listData = { page: collateralList, name: name };
    this.props.$collateralList(listData);
  };

  componentDidMount() {
    this.handleCollateralData();
  }

  componentWillReceiveProps(nextProps) {
    const dashCollateral = nextProps._collateralList.collateralList;
    if (dashCollateral) {
      this.setState({
        collateralData: dashCollateral.CollateralS,
        CollateralLoading: false,
      });
    }
  }
  render() {
    const { collateralData, CollateralLoading } = this.state;

    const tableContent = [
      { name: "Collateral Name" },
      { name: "Status" },
      { name: "Admin user" },
      { name: "Image" },
    ];

    return (
      <React.Fragment>
        {CollateralLoading ? (
          <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
            <LoaderSmall />
          </div>
        ) : (
          <div
            className="table-responsive mb-0"
            data-toggle="lists"
            data-options='{"valueNames": ["goal-project", "goal-status", "goal-progress", "goal-date"]}'
          >
            <table className="table table-sm table-nowrap card-table">
              {/*----------------------table head ---------------------------*/}
              <thead>
                <tr>
                  {tableContent.map((obj, index) => (
                    <th key={index} className={`${obj.name === "Image" ? "text-right" : ""}`}>
                      <a href="#!" className="text-muted sort">
                        {obj.name}
                      </a>
                    </th>
                  ))}                  
                </tr>
              </thead>
              {/*----------------------table head ---------------------------*/}
              <tbody className="list">
                {collateralData.slice(0, 5).map((obj, index) => (
                  <tr key={index}>
                    <td className="goal-project">{obj.name}</td>
                    <td className="goal-status">
                      {obj.status === 1 && (
                        <div className="badge badge-soft-success mr-2">
                          Accepted
                        </div>
                      )}
                      {obj.status === 2 && (
                        <div className="badge badge-soft-danger mr-2">
                          Rejected
                        </div>
                      )}
                      {obj.status === 0 && (
                        <Link to="/collaterals">
                          <div className="badge badge-soft-info mr-2">
                            Accept
                          </div>
                          <div className="badge badge-soft-info mr-2">
                            Reject
                          </div>
                        </Link>
                      )}
                    </td>
                    <td className="goal-progress">{obj.admin_email}</td>
                    <td className="text-right">
                      <div className="avatar-group">
                        <a
                          href="profile-posts.html"
                          className="avatar avatar-xs"
                          data-toggle="tooltip"
                          title={obj.name}
                        >
                          <img
                            src={
                              obj.image
                                ? `${profileBaseUrl + obj.image}`
                                : require("../../../Assets/img/avatars/profiles/images.jpeg")
                            }
                            className="avatar-img rounded-circle"
                            alt="..."
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({ collateralList }) => {
  return {
    _collateralList: collateralList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $collateralList: (values) => dispatchEvent(collateralList(values)),
});
export default connect(
  mapState,
  mapDispatch
)(DashboardCollateral);
