import {
  CLIENT_LIST_SUCCESS,
  CLIENT_LIST_ERROR,
  CHECK_STATUS_SUCCESS,
  USER_SIGNUP_SUCCESS,
  CREATIVE_USERS_DATA_SUCCESS,
  ADD_USERS_DATA_SUCCESS,
  UPDATE_USERS_DATA_SUCCESS,
  VIEW_USERS_DATA_SUCCESS,
  CLIENT_DATA_SUCCESS,
  ARTIST_LIST_SUCCESS,
  ARTIST_LIST_ERROR,
  GET_ARTIST_DATA_SUCCESS,
  STATUS_ARTIST_COLLATERAL_SUCCESS,
  STATUS_ARTIST_PROJECT_SUCCESS,
  ARTIST_DETAILS_SUCCESS,
  CLIENTS_DETAILS_SUCCESS,
  CLIENTS_PROFILE_SUCCESS,
  ARTIST_PROFILE_SUCCESS,
  ARTIST_UPDATE_INFO_SUCCESS,
  ARTIST_UPDATE_INFO_ERROR,
  ARTIST_APPROVAL_LIST_SUCCESS,
  ARTIST_APPROVAL_LIST_ERROR,

  ARTIST_BUSY_LIST_SUCCESS,
  ARTIST_BUSY_LIST_ERROR,
  ARTIST_STARRED_LIST_SUCCESS,
  ARTIST_STARRED_LIST_ERROR,

  ARTIST_CLEAN_UPDATE_SUCCESS,
  ARTIST_REVIEW_STATUS_SUCCESS,
  TEMP_RESET_PWD_SUCCESS,
  REJECTED_ARTIST_SUCCESS,
  DELETE_USER_SUCCESS,
  APPROVED_ARTIST_SUCCESS,
  DELETE_ARTIST_LIST_SUCCESS,
  CLIENT_DELETE_LIST_SUCCESS,
  LOCATION_USER_SUCCESS,
  REJECTED_ARTIST_ERROR,
  APPROVED_ARTIST_ERROR,
  DELETE_ARTIST_LIST_ERROR,
  DELETE_CLIENT_LIST_ERROR,
  GET_ARTIST_DATA_ERROR,
  CLIENT_DATA_ERROR,
  PENDING_CREATOR_APPR_LIST_SUCCESS,
  CLIENT_REJECTS_CREATOR_LIST_SUCCESS,
  PENDING_CREATOR_APPR_LIST_ERROR,
  CLIENT_REJECTS_CREATOR_LIST_ERROR,
  CLIENT_BRIEF_LIST_SUCCESS,
  CLIENT_BRIEF_LIST_ERR,
  CHANGE_EMBEDDED_LINK_SUCCESS,
  ARTIST_BUSY_OR_AVAILABLE_SUCCESS,
  DISABLED_ARTIST_SUCCESS,
  DISABLE_ENABLE_ARTIST_SUCCESS,
  DISABLED_ARTIST_ERROR
} from "../type.action";

const initState = {
  clientLoading: false,
  clientData: "",
  clientDataErrIs: "",
  clientStatus: "",
  signupstatus: "",
  getUsersData: "",
  addUsercontent: "",
  updateUserContent: "",
  viewUserContent: "",
  clientDtaWithId: "",
  artistDataList: "",
  getCreatorData: "",
  artistColstatus: "",
  artistProStatus: "",
  artistDetails: "",
  artistDataErrIs: "",
  clientsDetail: "",
  clientsProfile: "",
  artistDetailProfile: "",
  updateInfo: "",
  artistUpdateErrIs: "",
  approvalList: "",
  artistApprovalListErrIs: "",


  busyList: "",
  artistBusyListErrIs: "",
  starredList: "",
  artistStarredListErrIs:"",
  

  cleanUpdate: "",
  reviewStatus: "",
  tempResetPwd: "",
  rejectedArtistIs: "",
  userDeletedIs: "",
  DeleteArtistIs: "",
  DeleteClientIs: "",
  locationUserIs: "",
  completeProjectIs: "",
  rejectedArtistErrIs: "",
  artistApprovedErrIs: "",
  DeleteArtistErrIs: "",
  DeletClientErrIs: "",
  getArtistError: "",
  clientDtaErrIs: "",
  clientRejectsCreatorIs: "",
  pendingListAppErrIs: "",
  clientAllBriefIs: "",
  clientAllBriefErr: "",
  embeddedLinkIs: "",
  artistAvailableIs: "",
  disabledArtistIs: "",
  currentDisableIs: "",
  disableErr : ""
};

const reducers = (state = initState, { type, values, error }) => {
  switch (type) {
    case CLIENT_LIST_SUCCESS:
      return {
        ...state,
        clientData: values,
      };
    case CLIENT_LIST_ERROR:
      return {
        ...state,
        clientDataErrIs: error,
        clientLoading: true,
      };
    case CLIENT_DATA_SUCCESS:
      return {
        ...state,
        clientDtaWithId: values,
      };
    case CLIENT_DATA_ERROR:
      return {
        ...state,
        clientDtaErrIs: error,
      };
    case CHECK_STATUS_SUCCESS:
      return {
        ...state,
        clientStatus: values,
      };
    case USER_SIGNUP_SUCCESS:
      return {
        ...state,
        signupstatus: values,
      };
    case CREATIVE_USERS_DATA_SUCCESS:
      return {
        ...state,
        getUsersData: values,
      };
    case ADD_USERS_DATA_SUCCESS:
      return {
        ...state,
        addUsercontent: values,
      };
    case UPDATE_USERS_DATA_SUCCESS:
      return {
        ...state,
        updateUserContent: values,
      };
    case VIEW_USERS_DATA_SUCCESS:
      return {
        ...state,
        viewUserContent: values,
      };
    case ARTIST_LIST_SUCCESS:
      return {
        ...state,
        artistDataList: values,
      };
    case ARTIST_LIST_ERROR:
      return {
        ...state,
        artistDataErrIs: error,
        clientLoading: true,
      };
    case GET_ARTIST_DATA_SUCCESS:
      return {
        ...state,
        getCreatorData: values,
      };
    case GET_ARTIST_DATA_ERROR:
      return {
        ...state,
        getArtistError: error,
      };
    case STATUS_ARTIST_COLLATERAL_SUCCESS:
      return {
        ...state,
        artistColstatus: values,
      };
    case STATUS_ARTIST_PROJECT_SUCCESS:
      return {
        ...state,
        artistProStatus: values,
      };
    case ARTIST_DETAILS_SUCCESS:
      return {
        ...state,
        artistDetails: values,
      };
    case CLIENTS_DETAILS_SUCCESS:
      return {
        ...state,
        clientsDetail: values,
      };
    case CLIENTS_PROFILE_SUCCESS:
      return {
        ...state,
        clientsProfile: values,
      };
    case ARTIST_PROFILE_SUCCESS:
      return {
        ...state,
        artistDetailProfile: values,
      };
    case ARTIST_UPDATE_INFO_SUCCESS:
      return {
        ...state,
        updateInfo: values,
      };
    case ARTIST_UPDATE_INFO_ERROR:
      return {
        ...state,
        artistUpdateErrIs: error,
      };
    case ARTIST_APPROVAL_LIST_SUCCESS:
      return {
        ...state,
        approvalList: values,
      };
    case ARTIST_APPROVAL_LIST_ERROR:
      return {
        ...state,
        artistApprovalListErrIs: error,
      };
    
    // ----------------------------------
    
    case ARTIST_BUSY_LIST_SUCCESS:
      return {
        ...state,
        busyList: values,
    };
    case ARTIST_BUSY_LIST_ERROR:
      return {
        ...state,
        artistBusyListErrIs: error,
      };
     case ARTIST_STARRED_LIST_SUCCESS:
      return {
        ...state,
        starredList: values,
    };
    case ARTIST_STARRED_LIST_ERROR:
      return {
        ...state,
        artistStarredListErrIs: error,
    };
    
    // -----------------------------------

    case ARTIST_CLEAN_UPDATE_SUCCESS:
      return {
        ...state,
        cleanUpdate: values,
      };
    case ARTIST_REVIEW_STATUS_SUCCESS:
      return {
        ...state,
        reviewStatus: values,
      };
    case TEMP_RESET_PWD_SUCCESS:
      return {
        ...state,
        tempResetPwd: values,
      };
    case REJECTED_ARTIST_SUCCESS:
      return {
        ...state,
        rejectedArtist: values,
      };
    case REJECTED_ARTIST_ERROR:
      return {
        ...state,
        rejectedArtistErrIs: error,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        userDeletedIs: values,
      };
    case APPROVED_ARTIST_SUCCESS:
      return {
        ...state,
        artistApprovedIs: values,
      };
    case APPROVED_ARTIST_ERROR:
      return {
        ...state,
        artistApprovedErrIs: error,
      };
    case DELETE_ARTIST_LIST_SUCCESS:
      return {
        ...state,
        DeleteArtistIs: values,
      };
    case DELETE_ARTIST_LIST_ERROR:
      return {
        ...state,
        DeleteArtistErrIs: error,
      };
    case CLIENT_DELETE_LIST_SUCCESS:
      return {
        ...state,
        DeleteClientIs: values,
      };
    case DELETE_CLIENT_LIST_ERROR:
      return {
        ...state,
        DeletClientErrIs: error,
      };
    case LOCATION_USER_SUCCESS:
      return {
        ...state,
        locationUserIs: values,
      };
    case PENDING_CREATOR_APPR_LIST_SUCCESS:
      return {
        ...state,
        pendingListApprIs: values,
      };
    case PENDING_CREATOR_APPR_LIST_ERROR:
      return {
        ...state,
        pendingListAppErrIs: error,
      };
    case CLIENT_REJECTS_CREATOR_LIST_SUCCESS:
      return {
        ...state,
        clientRejectsCreatorIs: values,
      };
    case CLIENT_REJECTS_CREATOR_LIST_ERROR:
      return {
        ...state,
        clientRejectErrIs: error,
      };
    case CLIENT_BRIEF_LIST_SUCCESS:
      return {
        ...state,
        clientAllBriefIs: values,
      };
    case CLIENT_BRIEF_LIST_ERR:
      return {
        ...state,
        clientAllBriefErr: error,
      };
    case CHANGE_EMBEDDED_LINK_SUCCESS:
      return {
        ...state,
        embeddedLinkIs: values,
      };
    case ARTIST_BUSY_OR_AVAILABLE_SUCCESS:
      return {
        ...state,
        artistAvailableIs: values,
      };
    case DISABLED_ARTIST_SUCCESS:
      return {
        ...state,
        disabledArtistIs: values,
      };
    case DISABLED_ARTIST_ERROR:
      return{
        ...state,
        disableErr : error
      }  
    case DISABLE_ENABLE_ARTIST_SUCCESS:
      return{
        ...state,
        currentDisableIs: values
      }  
    default:
      return state;
  }
};

export default reducers;
