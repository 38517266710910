import React, { Component } from "react";
import { connect } from "react-redux";
import { getArtistData } from "../../../store/action/clientList.action";
import { getPublicAPI } from "../../../store/api-interface";
// import ErrorApi from "../Common/Error/ErrorApi";
import StarRatings from "react-star-ratings";
import Loader from "../Common/Loader/Loader";

class ProfileReview extends Component {
  state={
    ReviewIs:[],
    isLoading: true
  }

  componentDidMount(){
    let obj = this.props.location.pathname
    obj = obj.split(':')[1]
    // console.log(obj)

    getPublicAPI(`/postProject/get-completed-project-creator-review?_id=${obj}`)
      .then(res=> {
        this.setState({ReviewIs:res.data.reviewList, isLoading: false})
      }).catch(e => {console.log(e); this.setState({isLoading: false})})
  }

  handleClick(e,id){
    this.props.history.push(`/transactions/transactionsDetails/:${id}`);   
    e.preventDefault();
  }

  render(){
    const {ReviewIs, isLoading}=this.state;

    return (isLoading ? <Loader /> : 
      <>
          {ReviewIs.length===0?<h1>No Review found</h1>:
          <div className="container">

        
          <div className="row ">
       
          {
            ReviewIs.map((obj, index) => (

            <div  className="col-md-10" key={index}>
 

              <div style={{cursor:'pointer'}} onClick={(e)=>this.handleClick(e,obj.post_project_id)} className="card card-new">
                <div className="card-body">
                  <div className="mb-3">
                    <div className="row align-items-center">
                      <div className="col ml-n2">
                        <h4 className="card-title mb-1">{obj.projectData.project_slug}</h4>
                        <p className="card-text small text-muted mb-0">
                          <span className="fe fe-user"></span>
                          <time>{obj.user_type}</time>
                        </p>
                        {/*-----------------rating -----------------------*/}
                        {obj.rating > 0 && (
                          <StarRatings
                          rating={obj.rating}
                          starRatedColor="orange"
                          changeRating={this.changeRating}
                          numberOfStars={5}
                          name="rating"
                          starDimension="15px"
                          starSpacing="2px"
                          />
                          )}
                        {/*-----------------rating -----------------------*/}
                      </div>
                    </div>
                  </div>
                  {obj.description && (
                    <p className="mb-3">{`Description : ${obj.description}`}</p>
                    )}
                  <ol className="mb-3">
                    {obj.feedback &&
                      obj.feedback.map((object, index) => (
                        <li key={index}>{object.text}</li>
                        ))}
                  </ol>
                </div>
              </div>
            </div>
            
            
            ))
          }
          </div>
          </div>}
        </>
        )
      }
    }

    const mapState = ({ getArtistData }) => {
      return {
    _getArtistData: getArtistData,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $getArtistData: (values) => dispatchEvent(getArtistData(values)),
});
export default connect(mapState, mapDispatch)(ProfileReview);
