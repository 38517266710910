import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getClientData,
  clientsDetail,
  clientsDetailsProfile,
  locationUser,
  clientBriefList
} from "../../../store/action/clientList.action";
import { userProfileBaseUrl } from "../../../Utils/BaseUrl";
import ClientDetailVerifiedStatus from "./ClientDetailVerifiedStatus";
import LoaderSmall from "../Common/Loader/LoaderSmall";
import ErrorApi from "../Common/Error/ErrorApi";

class ClientDetailForm extends Component {
  state = {
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    phoneNumber: "",
    location: "",
    secondaryEmail: "",
    emailStatus: "",
    profileImage: "",
    about: "",
    clientId: "",
    clientGetData: false,
    LoadingIs: false,
    imageLoader: false,
    LoactionActive: false,
    errorIs: { firstName: "", lastName: "", userName: "" },
  };

  handleLocationList = (value) => {
    this.setState({
      LoactionActive: true,
    });
    this.props.$locationUser(value);
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name === "location") {
      this.setState({
        [name]: value,
      });
      this.handleLocationList(value);
    }
    this.setState({ [name]: value });
    this.validateForm(name, value);
  };

  handleLocationSelected = (value) => {
    const { LoactionActive } = this.state;
    this.setState({
      LoactionActive: !LoactionActive,
      location: value,
    });
  };

  handledropdown = (e) => {
    if (e.target.name !== "location" || e.target.name === "undefined") {
      this.setState({
        LoactionActive: false,
      });
    }
  };

  handleUploadImage = (e) => {
    let clientProfile = e.target.files[0];
    if (clientProfile) {
      this.setState({
        imageLoader: true,
      });
      const formData = new FormData();
      formData.append("userImages", clientProfile);
      this.props.$clientsDetailsProfile(formData);
    }
  };

  validateForm(name, value) {
    const { errorIs } = this.state;

    switch (name) {
      case "firstName":
        errorIs.firstName = !value.length ? "* Required Field " : "";
        break;
      case "lastName":
        errorIs.lastName = !value.length ? "* Required Field " : "";
        break;
      case "userName":
        errorIs.userName = !value.length ? "* Required Field " : "";
        break;
        case "email":
          errorIs.email = !/^\S+@\S+\.\S+$/.test(value)? "* Required Field " : "";
          break;
        case "phoneNumber":
          errorIs.phoneNumber = value.length<10 ? "* Required Field " : "";
          break;
        default:
        break;
    }

    return !errorIs.firstName && !errorIs.lastName && !errorIs.userName && !errorIs.email&& !errorIs.phoneNumber;
  }

  handleClientForm = (e) => {
    e.preventDefault();

    if (this.validateForm()) {

      this.setState({
        LoadingIs: true,
      });


      const {
        firstName,
        lastName,
        userName,
        email,
        phoneNumber,
        location,
        secondaryEmail,
        clientId,
        emailStatus,
        profileImage,
      } = this.state;

      const dataIs = {
        _id: clientId,
        phone_no: phoneNumber,
        email: email,
        first_name: firstName,
        last_name: lastName,
        secondary_email: secondaryEmail,
        username: userName,
        location: location,
        email_status: Number(emailStatus),
        profile_image: profileImage,
      };

      this.props.$clientsDetail(dataIs);
    }
  };

  handleGetClient = (obj) => {
    this.setState({
      clientGetData: true,
    });
    this.props.$getClientData(obj);
  };
  
  componentDidMount() {
    const clientParamsId = this.props.match.params.id.split(":").pop();
    this.handleGetClient(clientParamsId);
    const currentData = {
      page: 1,
      sortIs: -1,
      id: clientParamsId,
      project: "",
    };
    this.props.$clientBriefList(currentData);
  }

  componentWillReceiveProps(nextProps) {
    const { LoadingIs, imageLoader, clientGetData } = this.state;
    const clientDetails = nextProps._getClientData.clientDtaWithId;
    const dta = clientDetails.data;
    const detailsData = nextProps._clientsDetail.clientsDetail;
    const profileDate = nextProps._clientsDetailsProfile.clientsProfile;
    if (clientDetails && clientDetails.status === "1" && clientGetData) {
      dta && this.setState({
        firstName: dta.first_name,
        lastName: dta.last_name,
        userName: dta.username,
        email: dta.email,
        phoneNumber: dta.phone_no,
        location: dta.location,
        secondaryEmail: dta.secondary_email,
        emailStatus: dta.email_status.toString(),
        profileImage: dta.profile_image,
        about: dta.bio,
        clientId: dta._id,
        clientGetData: false,
      });
    }
    if (detailsData.status === "1" && LoadingIs) {
      const { clientId } = this.state;
      this.setState({
        LoadingIs: false,
      });
      this.props.history.push("/clients/clientscompany/:" + clientId);
    }
    if (profileDate.status === "1" && imageLoader) {
      this.setState({
        imageLoader: false,
        profileImage: profileDate.name,
      });
    }
  }

  render() {
    const clientParamsType = this.props.match.params.type.split(":").pop();
    const {
      firstName,
      lastName,
      userName,
      email,
      phoneNumber,
      location,
      secondaryEmail,
      profileImage,
      LoadingIs,
      imageLoader,
      errorIs,
      LoactionActive,
    } = this.state;

    const clientFunc = {
      handleOnChange: this.handleOnChange,
    };

    const clientArr = [
      { name: "firstName", value: firstName, label: "First name" },
      { name: "lastName", value: lastName, label: "Last name" },
      { name: "email", value: email, label: "Email address" },
      { name: "userName", value: userName, label: "User name" },
      { name: "phoneNumber", value: phoneNumber, label: "Phone" },
      {
        name: "secondaryEmail",
        value: secondaryEmail,
        label: "Secondary Email",
      },
    ];

    const { _locationUser } = this.props;
    const locationData = _locationUser.locationUserIs.data;

    return this.props._getClientData.clientDtaErrIs ? (
      <ErrorApi {...this.props} />
    ) : (
      <React.Fragment>
        <section className="container" onClick={this.handledropdown}>
          <form onSubmit={this.handleClientForm}>
            {/*------------------------client Profile ---------------------------*/}

            <div className="row justify-content-between align-items-center">
              <div className="col">
                <div className="row align-items-center">
                  <div className="col-auto">
                    <div className="avatar">
                      <img
                        className="avatar-img rounded-circle"
                        src={
                          profileImage
                            ? userProfileBaseUrl + profileImage
                            : require("../../../Assets/img/avatars/profiles/avatar-1.jpg")
                        }
                        alt="..."
                      />
                    </div>
                  </div>
                  <div className="col ml-n2">
                    <h4 className="mb-1">Your avatar</h4>
                    <small className="text-muted">PNG,JPG or JPEG only.</small>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <input
                  type="file"
                  ref={(ref) => (this.upload = ref)}
                  accept="jpg,jpeg.png"
                  style={{ display: "none" }}
                  onChange={this.handleUploadImage}
                />
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => this.upload.click()}
                >
                  {imageLoader ? "Uploading...." : "Upload"}
                </button>
              </div>
            </div>

            {/*------------------------client Profile ---------------------------*/}

            <hr className="my-5" />
            {/*-----------------------------main form ----------------------------------*/}

            <div className="row">
              {clientArr.map((obj, index) => (
                <div className="col-12 col-md-6" key={index}>
                  <div className="form-group">
                    <label>{obj.label}</label>
                    <input
                      type="text"
                      className={`form-control 
                      ${
                        ((obj.name === "firstName" && errorIs.firstName) ||
                          (obj.name === "lastName" && errorIs.lastName) ||
                          (obj.name === "userName" && errorIs.userName)||
                          (obj.name === "email" && errorIs.email)||
                          (obj.name === "phoneNumber" && errorIs.phoneNumber)) &&
                        `error-show`
                      }`}
                      name={obj.name}
                      value={obj.value}
                      onChange={this.handleOnChange}
                      autoComplete="off"
                 
                    />
                    {((errorIs.firstName && obj.name === "firstName") ||
                      (errorIs.lastName && obj.name === "lastName") ||
                      (errorIs.email && obj.name === "email") ||
                      (errorIs.phoneNumber && obj.name === "phoneNumber") ||
                      (errorIs.userName && obj.name === "userName"))
                       && (
                      <span className="error">{`${
                        errorIs.firstName ||
                        errorIs.lastName ||
                        errorIs.userName||
                        errorIs.email||
                        errorIs.phoneNumber
                      }`}</span>
                    )}
                  </div>
                </div>
              ))}

              {/*---------------------------location -------------------------------*/}
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>Location</label>
                  <input
                    type="text"
                    className="form-control"
                    name="location"
                    value={location}
                    onChange={this.handleOnChange}
                    autoComplete="off"
                    style={{ position: "relative" }}
                  />
                  {locationData && LoactionActive && (
                    <ul
                      className="list-group list-group"
                      style={{
                        position: "absolute",
                        zIndex: "99",
                        background: "white",
                        width: "95%",
                        maxHeight: "200px",
                        overflow: "scroll",
                      }}
                    >
                      {locationData &&
                        locationData.map((obj, index) => (
                          <li
                            className="list-group-item"
                            key={index}
                            onClick={() =>
                              this.handleLocationSelected(`${obj.name}`)
                            }
                          >
                            {obj.name}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            {/*-----------------------------main form ----------------------------------*/}
            <hr className="mt-4 mb-5" />

            <ClientDetailVerifiedStatus
              {...this.state}
              {...this.props}
              handleFunc={clientFunc}
            />

            <hr className="mt-4 mb-5" />

            {clientParamsType !== "view" && (
              <button type="submit" className="btn btn-primary">
                {LoadingIs ? <LoaderSmall /> : "Update"}
              </button>
            )}
          </form>
        </section>
      </React.Fragment>
    );
  }
}
const mapState = ({
  getClientData,
  clientsDetail,
  clientsDetailsProfile,
  locationUser,
  clientBriefList
}) => {
  return {
    _getClientData: getClientData,
    _clientsDetail: clientsDetail,
    _clientsDetailsProfile: clientsDetailsProfile,
    _locationUser: locationUser,
    _clientBriefList: clientBriefList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $getClientData: (values) => dispatchEvent(getClientData(values)),
  $clientsDetail: (values) => dispatchEvent(clientsDetail(values)),
  $clientsDetailsProfile: (values) =>
    dispatchEvent(clientsDetailsProfile(values)),
  $locationUser: (values) => dispatchEvent(locationUser(values)),
  $clientBriefList: (values) => dispatchEvent(clientBriefList(values)),
});
export default connect(mapState, mapDispatch)(ClientDetailForm);
