

//------------------------------------for-production-----------------------------------*/

// export const Baseurl = 'http://dev-api.getcreator.in';

export const profileBaseUrl = 'https://ifn-images.s3.ap-south-1.amazonaws.com/collateral/';

export const ImageBaseUrl =  "https://ifn-images.s3.ap-south-1.amazonaws.com/assets/";

export const userProfileBaseUrl = "https://ifn-images.s3.ap-south-1.amazonaws.com/profile_images/";

export const ResumeBaseUrl = 'https://ifn-images.s3.ap-south-1.amazonaws.com/resume/';

export const uploadFileUrl = "https://ifn-images.s3.ap-south-1.amazonaws.com/upload-files/";

export const otherOptionDefaultImageUrl = "collateral_default1.png" 

// export const WebsiteUrl = "https://www.indiefolio.com/"

//------------------------------------for-production-----------------------------------*/


export const Baseurl = 'https://api.getcreator.in';

export const WebsiteUrl = "https://www.indiefolio.com/"