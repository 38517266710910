import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import {
  collaterlApprovedProject,
  collaterlPendingProject,
} from "../../../store/action/collateralList.action";
import ApprovedProjects from "./ApprovedProjects";

class CollateralProjects extends Component {
  state = {
    LoadingIs: false,
    projectName: "",
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleCollateralApprovedProject = (id) => {
    this.setState({
      LoadingIs: false,
    });
    // const data = { _id: id };
    // this.$collaterlPendingProject(data);
  };

  componentDidMount() {
    const collateralParam = this.props.match.params.id.split(":").pop();
    this.handleCollateralApprovedProject(collateralParam);
  }

  render() {
    const projectArr = [
      { name: "Name" },
      { name: "Url" },
      { name: "Artist" },
      { name: "Status" },
    ];

    const { LoadingIs, projectName } = this.state;
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/collateralsQuestions/projects/approved/:id/"
            render={(props) => <ApprovedProjects {...props} {...this.state} />}
          />
          <section className="client">
            <div className="container-fluid mx-auto">
              {/*------------------Search ---------------------*/}
              <div className="row col-md-12">
                <div className="col">
                  <form className="row align-items-center">
                    <div className="col-auto pr-0">
                      <span className="fe fe-search text-muted"></span>
                    </div>
                    <div className="col">
                      <input
                        type="search"
                        className="form-control form-control-flush search"
                        autoComplete="off"
                        placeholder="Search"
                        name="projectName"
                        value={projectName}
                        onChange={this.handleOnChange}
                      />
                    </div>
                  </form>
                </div>
              </div>
              {/*------------------Search ---------------------*/}

              {LoadingIs ? (
                <div className="col-md-12">
                  <div className="d-flex justify-content-center align-items-center">
                    <h3>Loading....</h3>
                  </div>
                </div>
              ) : (
                <div className="table-responsive mb-5 mx-auto">
                  <table className="table table-sm table-nowrap card-table">
                    {/*---------------table head -------------------------*/}

                    <thead>
                      <tr>
                        {projectArr.map((obj, index) => (
                          <th key={index}>
                            <a href="!#" className="text-muted sort">
                              {obj.name}
                            </a>
                          </th>
                        ))}
                      </tr>
                    </thead>

                    {/*---------------table head -------------------------*/}
                    <tbody className="list">
                      <tr>
                        <td className="orders-order">#6520</td>
                        <td className="orders-product">5' x 3' Wall Poster</td>
                        <td className="orders-total">$55.25</td>
                        <td className="orders-status">
                          <div className="badge badge-soft-success">
                            Shipped
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              {/**** Pagination****** */}
              {/* <Pagination {...this.state} {...this.props}/> */}
            </div>
          </section>
        </Switch>
      </React.Fragment>
    );
  }
}
const mapState = ({ collaterlApprovedProject, collaterlPendingProject }) => {
  return {
    _collaterlApprovedProject: collaterlApprovedProject,
    _collaterlPendingProject: collaterlPendingProject,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $collaterlApprovedProject: (values) =>
    dispatchEvent(collaterlApprovedProject(values)),
  $collaterlPendingProject: (values) =>
    dispatchEvent(collaterlPendingProject(values)),
});
export default connect(
  mapState,
  mapDispatch
)(CollateralProjects);
