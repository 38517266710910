import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { milesList } from "../../../../store/action/Milestone.action";

class PackagesHeaderTab extends Component {
  render() {
    const { pathIs, location } = this.props;
    const artistId = location.pathname.split("/:").pop();
    const dataArr = [
      {
        name: "All Packages",
        link: "/packages",
        activeBorder: "/packages",
        counting: 0,
      },
    ];

    const artistCurrentPath = (path) => {
      return path;
    };

    return (
      <React.Fragment>
        {pathIs === "Packages" && artistId === "/packages" && (
          <ul className="nav nav-tabs nav-overflow header-tabs">
            {dataArr?.map((obj, index) => (
              <li className="nav-item" key={index}>
                <Link
                  to={obj.link}
                  className={`nav-link ${
                    artistCurrentPath(`${obj.activeBorder}`) ? "active" : ""
                  }`}
                >
                  {obj.name}
                  <span className="badge badge-pill badge-soft-secondary ml-2">
                    {obj.counting}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({ milesList }) => {
  return {
    _milesList: milesList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $milesList: (values) => dispatchEvent(milesList(values)),
});
export default connect(mapState, mapDispatch)(PackagesHeaderTab);
