import React, { Component } from "react";

export default class AskQuestion extends Component {
  render() {
    const { reviewQuestn } = this.props;   
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <h4 className="card-title mb-3">Feedback Questions</h4>
            <div className="card card-new">
              <div className="card-body">
                <div className="mb-3">
                  <p className="card-text small text-muted mb-1">
                    <span className="fe fe-user"></span>
                    <time>Client</time>
                  </p>
                  <div className="row">
                    {reviewQuestn?.map((obj, index) => {
                      return (
                        obj.user_type === "client" && (
                          <div className="col-6 ml-n2" key={index}>
                            <ol className="mb-2" style={{ listStyle: "none" }}>
                              <li>Q: {obj.question}</li>
                            </ol>
                            <ul className="mb-2" style={{ listStyle: "none" }}>
                              <li>A: {obj.answer}</li>
                            </ul>
                          </div>
                        )
                      );
                    })}
                  </div>
                </div>
                <div className="mb-3">
                  <p className="card-text small text-muted mb-1">
                    <span className="fe fe-user"></span>
                    <time>Creator</time>
                  </p>
                  <div className="row">
                    {reviewQuestn?.map((obj, index) => {
                      return (
                        obj.user_type !== "client" && (
                          <div className="col-6 ml-n2" key={index}>
                            <ol className="mb-2" style={{ listStyle: "none" }}>
                              <li>Q: {obj.question}</li>
                            </ol>
                            <ul className="mb-2" style={{ listStyle: "none" }}>
                              <li>A: {obj.answer}</li>
                            </ul>
                          </div>
                        )
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
