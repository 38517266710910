import React, { useState ,useEffect} from "react";
import Select from "react-select";
import {  putAPI } from "../../../store/api-interface";
import { getArtistData } from "../../../store/action/clientList.action";
import { useDispatch } from "react-redux";
const CollateralUpdate = (props) => {
  const dispatch = useDispatch();

   const {_getArtistData} = props;
   const userData = _getArtistData.getCreatorData.userData;

  const [price, setPrice] = useState(userData.time_base_amount || "");
  const [disable, setDisable] = useState(true)
  const [weeklyPrice,setWeeklyPrice] = useState(0);
  const [monthlyPrice,setMonthlyPrice] = useState(0);
  const [isPrice,setIsPrice] = useState(false);
  const [isSave,setIsSave] = useState(false);
  const [time,setTime]= useState({value:userData.time_base_duration ||'hour',label:userData.time_base_duration?`per ${userData.time_base_duration}`: `per hour`})
 
  const option = [
    { value: "hour", label: "per hour" },
    {value:"day", label:"per day"},
    { value: "week", label: "per week" },
    { value: "month", label: "per month" },
  ];

 const onChangeHandler =(e)=>{
    setTime(e)
   }

   
   useEffect(()=>{
    const calPrice=()=>{
      switch (time.value) {
        
        case 'hour':
          setWeeklyPrice(price*5*5);
          setMonthlyPrice(price*5*4*5);
          break;
  
        case 'day':
            setWeeklyPrice(price*5);
            setMonthlyPrice(price*5*4);
          break;
          
        case 'week':
          setWeeklyPrice(price*1);
          setMonthlyPrice(price*4);
              break;
  
        case 'month':
          setWeeklyPrice(price/4);
          setMonthlyPrice(price*1);
           break;
      
        default:
          setWeeklyPrice(0);
          setMonthlyPrice(0);
          break;
      }
    }
    calPrice()
   },[price,time,])

  const editHandler = (e)=>{
    e.preventDefault()
    setDisable(!disable)
    setIsSave(false);
    }
   
  const submitHandler =(e)=>{
    e.preventDefault();
    if(price && !isSave){
    const data = {
      time_base_duration:time.value,
      time_base_amount:Number(price),
      _id:_getArtistData.getCreatorData.userData._id
    }
    setDisable(true)
     setIsSave(true);
    // console.log("submit",data)
    // console.log(time)
    putAPI(`/backendUser/admin-update-time-base-price`, data).then((res) => {
      // console.log(res)
        if (res.data.status==='1') {
     alert("Succesfully added..")
        getFunc();
        }
      })
      .catch((err) => {
        err && alert(err);
      });
    
  }
  else{
  if(!isSave) setIsPrice(true);
  }

  }
    
 function getFunc(){
  dispatch(getArtistData(_getArtistData.getCreatorData.userData._id));  
     props.getData();
  }
  return (
    <>

    <form onSubmit = {submitHandler} className="mx-auto col-md-12 col-lg-10" >
    <div className="d-flex w-50 ">
      
        <input
          className={isPrice?"border border-danger mx-2 w-50":" mx-2 w-50"}
          placeholder="Enter Price"
          disabled={disable}
          value={price}
          
          onChange={(e) =>{setIsPrice(false); setPrice(e.target.value)}}
        />
 
        
        <Select 
        className="w-50 mx-2" 
        options={option} 
        isDisabled = {disable}
        onChange = {onChangeHandler}
        defaultValue={time}

        />
        <button onClick = {!disable?submitHandler:editHandler} type="submit" className="btn btn-primary">{!disable?'Save':'Edit'}</button>
      </div>
     {isPrice && <div className="text-danger ml-2">Set the Time Based Price</div>}
      
       <div className = "m-3 mb-2">
         
          Weekly Price : {weeklyPrice} &nbsp;
          Monthly Price : {monthlyPrice}
      </div>

    </form>
     
     
    </>
  );
};

 export default CollateralUpdate;
