import React, { Component } from "react";
import { connect } from "react-redux";
import { pendingCollateralList } from "../../../../store/action/collateralList.action";
import TypeQuestion from "./TypeQuestion";
import StyleQuestion from "./StyleQuestion";

class ViewPendingCollateral extends Component {
  state = {
    firstName: "",
    lastName: "",
    collateralName: "",
    startPrice: "",
    endPrice: "",
    month: "",
    year: "",
    time: "",
    pricingUnit: "",
    unit: "",
    typeQuestion: [],
    indexIs: "",
    questionIs: "",
    optionIs: [],
    answerIs: [],
    subtext: "",
    Qstype: "",
    typeQuesList: false,
    styleQuestion: [],
    styleQuestionList: false,
  };

  handleTypeEditQues = (obj, index, quesType) => {
    if (quesType === "typeQues") {
      this.setState({ typeQuesList: true, styleQuestionList : false });
    } else {
      this.setState({ typeQuesList: false, styleQuestionList : true });
    }
    this.setState({
      indexIs: index,
      questionIs: obj.question,
      subtext: obj.subtext,
      Qstype: obj.type,
      optionIs: obj.option,
      answerIs: obj.answer,
    });
  };

  handleCancelForm = () => {
    this.setState({
      typeQuesList: false,
      styleQuestionList : false
    });
  };

  handleViewCollateral = (id) => {
    this.props.$pendingCollateralList(id);
  };

  componentDidMount() {
    const collateralParamsId = this.props.match.params.id.split(":").pop();
    this.handleViewCollateral(collateralParamsId);
  }

  componentWillReceiveProps(nextProps) {
    const data = nextProps._pendingCollateralList.pendingCollateralListIs;
    if (data) {
      this.setState({
        firstName: data.userData ? data.userData.first_name : "",
        lastName: data.userData ? data.userData.last_name : "",
        collateralName: data.userCollateral
          ? data.userCollateral.collateral_name
          : "",
        time: data.userCollateral ? data.userCollateral.time : "",
        startPrice: data.userCollateral ? data.userCollateral.start_price : "",
        pricingUnit: data.collateral ? data.collateral.pricing_unit : "",
        unit: data.collateral ? data.collateral.unit : "",
        endPrice: data.userCollateral ? data.userCollateral.end_price : "",
        month: data.userCollateral ? data.userCollateral.month : "",
        year: data.userCollateral ? data.userCollateral.year : "",
        typeQuestion: data.collateralTypeQusAws
          ? data.collateralTypeQusAws
          : [],
        styleQuestion: data.collateralStyleQusAws
          ? data.collateralStyleQusAws
          : [],
      });
    }
  }

  render() {
    const {
      firstName,
      lastName,
      collateralName,
      startPrice,
      endPrice,
      month,
      year,
      time,
      pricingUnit,
      unit,
    } = this.state;
    const dataArr = [
      { name: "firstName", value: firstName, label: "First Name" },
      { name: "lastName", value: lastName, label: "Last Name" },
      {
        name: "collateralName",
        value: collateralName,
        label: "Collateral Name",
      },
      { name: "time", value: time, label: "Time" },
      { name: "startPrice", value: startPrice, label: "Start Price" },
      { name: "endPrice", value: endPrice, label: "End Price" },
      { name: "month", value: month, label: "Month" },
      { name: "year", value: year, label: "Year" },
      { name: "pricingUnit", value: pricingUnit, label: "Pricing Unit" },
      { name: "unit", value: unit, label: "Unit" },
    ];

    const transactionFunc = {
      handleTypeEditQues: this.handleTypeEditQues,
      handleCancelForm: this.handleCancelForm,
    };
    return (
      <React.Fragment>
        <section className="container">
          <div className="row">
            {dataArr.map((obj, index) => (
              <div className="col-12 col-md-6" key={index}>
                <div className="form-group">
                  <label>{obj.label}</label>
                  <input
                    type="text"
                    className="form-control"
                    name={obj.name}
                    value={obj.value}
                    onChange={this.handleOnChange}
                    disabled="disabled"
                  />
                </div>
              </div>
            ))}
          </div>
          <hr className="my-5" />
          <div className="row">
            <div className="col-12 mb-3">
              <label>Type Question</label>            
            <TypeQuestion
              {...this.state}
              {...this.props}
              handlefunc={transactionFunc}
            />
            </div>
          </div>

          <hr className="my-5" />
         { <React.Fragment>
          <div className="row">
            <div className="col-12 mb-3">
              <label>Style Question</label>
            <StyleQuestion
              {...this.state}
              {...this.props}
              handlefunc={transactionFunc}
            />
          </div>
          <hr  className="my-5"/>
          </div>
          </React.Fragment>}
        </section>
      </React.Fragment>
    );
  }
}
const mapState = ({ pendingCollateralList }) => {
  return {
    _pendingCollateralList: pendingCollateralList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $pendingCollateralList: (values) =>
    dispatchEvent(pendingCollateralList(values)),
});
export default connect(mapState, mapDispatch)(ViewPendingCollateral);
