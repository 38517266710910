import React from "react";
import ziplogo from "../../../../Assets/img/images.jpeg";
import { uploadFileUrl } from "../../../../Utils/BaseUrl";

export const getMedia = (value) => {
    const mediaPath =
      value.slice(value.lastIndexOf(".")) === ".pdf" ? (
        <embed src={uploadFileUrl+value} height="400" width="100%" />
      ) : value.slice(value.lastIndexOf(".")) === ".mp3" 
    // || value.slice(value.lastIndexOf(".")) === ".xlsx" 
        ? (
        <div className="text-center" width="100%" height="600">
          <p>Url Is</p>
          <input className="form-control" value={value} />
        </div>
      ) : value.slice(value.lastIndexOf(".")) === ".docx" ||
        value.slice(value.lastIndexOf(".")) === ".doc" ? (
        <iframe
          className=""
          width="100%"
          height="600"
          frameBorder="0"
          src={`https://docs.google.com/gview?url=${value}&embedded=true`}
          title="..."
        ></iframe>
      ) 
       : value.slice(value.lastIndexOf(".")) === ".csv" || value.slice(value.lastIndexOf(".")) === ".xls" || value.slice(value.lastIndexOf(".")) === ".xlsx"? (
        <iframe
          className="m-4"
          width="100%"
          height="600"
          frameBorder="0"
          src={`https://docs.google.com/gview?url=${uploadFileUrl+value}&embedded=true`}
          title="..."
        ></iframe>
      ) 
      : value.slice(value.lastIndexOf(".")) === ".zip" ? (
        <img
          src={value.slice(value.lastIndexOf(".")) === ".zip" ? ziplogo : value}
          height="400"
          width="100%"
          alt="..."
          style={{ borderRadius: "10px" }}
        />

      ) : <div className="project_big_image" style={{backgroundImage: `url("${value.includes('.com')?value:uploadFileUrl+value}")` }}></div>;
  
    return mediaPath;
  };