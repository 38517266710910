import React, { Component } from "react";
import Modal from "react-modal";
import { profileBaseUrl } from "../../../Utils/BaseUrl";
import "react-multi-carousel/lib/styles.css";
import EditLinksModal from "./EditLinksModal";
import AsyncImage from "../Common/asyncImage";
import checkUrl from "../Common/CheckUrl/CheckMediaUrl";
import {getMedia} from '../Common/CheckUrl/MediaImage';
import {getThumbnailImage} from "../Common/CheckUrl/ThumbnailImage";
Modal.setAppElement("#root");
Modal.defaultStyles.overlay.backgroundColor = "rgb(0 0 0 / 50%)";
Modal.defaultStyles.overlay.zIndex = "9999";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#f5f7fd",
    borderRadius: "8px",
    maxWidth: "900px",
    width: "100%",
    border: "none",
    maxHeight:'750px',
  },
};

export default class ArtistProjectMediaModal extends Component {

state = {
      selectedMedia: "",
      ImagesandLinks: [],
      MediaIs: [],
      index: 0,
      EditModalIs: false,
      checkedData: false,
      project_id: "",
    };



  handleEditModal = () => {
    this.setState({
      EditModalIs: true,
      checkedData: true,
    });
  };

  handleCheckedData = () => {
    this.setState({
      checkedData: false,
    });
  };

  handleCloseLinksModal = () => {
    this.setState({
      EditModalIs: false,
    });
  };

  handleCarasoulArrow = (status) => {
    const { MediaIs, index } = this.state;
    const MediaList = MediaIs.filter((item) => item.value.length > 0);
    if (status === "right") {
      const indexIs = index + 1;
      if (indexIs < MediaList.length) {
        const currentArr = MediaList[indexIs];
        this.setState({
          ImagesandLinks: currentArr,
          index: indexIs,
        });
      }
    } else if (status === "left" && index > 0) {
      const indexIs = index - 1;
      const currentArr = MediaList[indexIs];
      this.setState({
        ImagesandLinks: currentArr,
        index: indexIs,
      });
    }
  };

  handleSelectedThumbnail = (obj, indexIs) => {
    const { MediaIs } = this.state;
    const currentSelected = MediaIs[indexIs];
    this.setState({
      ImagesandLinks: currentSelected,
      selectedMedia: obj,
      index: indexIs,
    });
  };


  // static getDerivedStateFromProps(nextProps,state) {
  //   const { projectImageLinks, projectId } = nextProps;
  //   const currentImages = projectImageLinks
  //     ? projectImageLinks.filter((item) => item.value.length > 0)
  //     : "";
  //   return {
  //     ImagesandLinks: currentImages[0],
  //     MediaIs: projectImageLinks ? projectImageLinks : "",
  //     project_id: projectId,
  //   };
  // }
  componentDidMount(){
      const { projectImageLinks, projectId } = this.props;
      const currentImages = projectImageLinks
        ? projectImageLinks.filter((item) => item.value.length > 0)
        : "";
      this.setState( {
        ImagesandLinks: currentImages[0],
        MediaIs: projectImageLinks ? projectImageLinks : "",
        project_id: projectId,
      });
  }

  render() {
    const {
      ProjectMediaIs,
      handleProject,
      aboutProject,
      projectTitle,
      projectEndMonth,
      projectEndYear,
      projectKeywords,
      projectTools,
      LinkedCollateral,
      MediaData,
      artistProfile,
      location,
    } = this.props;

    const { ImagesandLinks, MediaIs } = this.state;

    const dataProject = MediaData.project;
    const dataUser = artistProfile;
    const styleQuestionIs = MediaData.style_question;

    const typeQuestionIs = MediaData.type_question;

    const MediaList = MediaIs.filter((item) => item.value.length > 0);

    const limksFunc = {
      handleCloseLinksModal: this.handleCloseLinksModal,
      handleCheckedData: this.handleCheckedData,
    };

    return (
      <React.Fragment>
        <div>
          <Modal
            isOpen={ProjectMediaIs}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <button
              type="button"
              className="close media-upload-modal"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleProject?.handleCloseMediaModal}
            >
              <span
                aria-hidden="true"
                style={{ color: "black", fontSize: "32px" }}
              >
                &times;
              </span>
            </button>
            {location?.pathname?.includes('/artist') &&    <button
              className="btn btn-primary mb-2"
              onClick={this.handleEditModal}
            >
              <i className="fe fe-edit"> </i>Edit Links
            </button>}
            <div className="row align-items-center">
              <div className="col-sm-8">
                <div
                  className="carasoul-image"
                >
                  {ImagesandLinks && (
                    <React.Fragment>
                      {ImagesandLinks.type === "image" ? (
                        getMedia(ImagesandLinks.value)
                      ) : (
                        ImagesandLinks.value && checkUrl(ImagesandLinks.value)    
                      )}
                    </React.Fragment>
                  )}
                  <div className="">
                    <div
                      className="nav-right nav-icon"
                      onClick={() => this.handleCarasoulArrow("right")}
                    >
                      <span className="fe fe-chevron-right"></span>
                    </div>
                    <div
                      className="nav-left nav-icon"
                      onClick={() => this.handleCarasoulArrow("left")}
                    >
                      <span className="fe fe-chevron-left"></span>
                    </div>
                  </div>
                </div>

                {/*-------------------------thumbnail ------------------------*/}
                <div className="row mt-4">
                  {Array.isArray(MediaList) &&
                    MediaList.map((obj, index) => {
                      return (
                        <React.Fragment key={index}>
                          {obj.type === "image" ? (
                            <div
                              className="thumbnail-div col-3 mb-3"
                              onClick={() =>
                                this.handleSelectedThumbnail(obj, index)
                              }
                            >
                              {obj.value && getThumbnailImage(obj.value)}
                            </div>
                          ) : (
                            <div
                              className="thumbnail-div col-3 mb-3"
                              onClick={() =>
                                this.handleSelectedThumbnail(obj, index)
                              }
                            >
                              {obj.value && <AsyncImage imageurl={obj.value}/>}
                            </div>
                          )}
                        </React.Fragment>
                      );
                    })}
                </div>
                {/*-------------------------thumbnail ------------------------*/}
              </div>
              <div className="col-sm-4 overflow-content user-work-content ">
                <h3>{projectTitle ? projectTitle : ""}</h3>
                <p>{`By ${dataUser ? dataUser.first_name : ""} ${
                  dataUser ? dataUser.last_name : ""
                }`}</p>
                <hr className="my-2" />
                <div className="tag des-scroll">
                  <h4 className="tag-title mb-2">Project Description</h4>
                  <span style={{ color: "#95aac9" }}>
                    {aboutProject ? aboutProject : ""}
                  </span>
                </div>

                {projectTools.length > 0 && (
                  <div className="tag">
                    <h4 className="tag-title mb-2">Software/Tools Used</h4>
                    {projectTools.length > 0
                      ? projectTools.map((obj, index) => (
                          <span className="badge badge-soft-light" key={index}>
                            {obj.name}
                          </span>
                        ))
                      : "No data Found"}
                  </div>
                )}

                {dataProject && dataProject.collaboration === 1 && (
                  <div className="user-details">
                    <p className="mb-0">
                      {dataUser ? dataUser.first_name : ""}'s Role
                    </p>
                    <span style={{ color: "#95aac9" }}>
                      {dataProject && dataProject.collaboration === 1
                        ? dataProject.role
                        : ""}
                    </span>
                  </div>
                )}

                {LinkedCollateral.length > 0 && (
                  <div className="tag">
                    <h4 className="tag-title mb-2">Collateral Type</h4>
                    {LinkedCollateral ? (
                      LinkedCollateral.map((obj, index) => (
                        <span className="badge badge-soft-light" key={index}>
                          {obj.collateral_id.name}
                        </span>
                      ))
                    ) : (
                      <span className="badge badge-soft-light">
                        No Data Found
                      </span>
                    )}
                  </div>
                )}
                {projectKeywords.length > 0 && (
                  <div className="tag">
                    <h4 className="tag-title mb-2">KeyWords</h4>
                    {projectKeywords &&
                      projectKeywords.map((obj, index) => (
                        <span className="badge badge-soft-light" key={index}>
                          {obj}
                        </span>
                      ))}
                  </div>
                )}
                {dataProject && dataProject.industry.length > 0 && (
                  <div className="tag">
                    <h4 className="tag-title mb-2">Industry</h4>
                    <span className="badge badge-soft-light">
                      {dataProject.industry}
                    </span>
                  </div>
                )}
                {dataProject && dataProject.languages.length > 0 && (
                  <div className="tag">
                    <h4 className="tag-title mb-2">Languages</h4>
                    {dataProject &&
                      dataProject.languages &&
                      dataProject.languages.map((obj, index) => (
                        <span className="badge badge-soft-light" key={index}>
                          {obj.name}
                        </span>
                      ))}
                  </div>
                )}
                <div className="user-details">
                  <span>{`Created for ${
                    dataProject ? dataProject.created_type : ""
                  }`}</span>
                </div>
                {dataProject && dataProject.created_type === "company" && (
                  <div className="user-details">
                    <span style={{ color: "#95aac9" }}>
                      Company :{" "}
                      {dataProject && dataProject.created_type === "company"
                        ? dataProject.company_name
                        : ""}
                    </span>
                  </div>
                )}
                <div className="user-details">
                  <span style={{ color: "#95aac9" }}>
                    Completed On :{" "}
                    {`${projectEndMonth ? projectEndMonth : ""} ${
                      projectEndYear ? projectEndYear : ""
                    }`}
                  </span>
                </div>
                {/*----------------------stye qs ------------------------------*/}
                {styleQuestionIs?.map((obj, index) => (
                  <React.Fragment key={index}>
                    <div className="user-details mt-5">
                      <h4 className="tag-title mb-2">Style Question</h4>
                      <h5 style={{ color: "#95aac9" }}>{obj.type} (Type)</h5>
                      <span style={{ color: "#95aac9" }}>{obj.question}</span>
                    </div>
                    <div className="tag">
                      <h4 className="tag-title mb-2">Answer</h4>
                      <div className="row">
                        {obj.answer?.map((object, index) => (
                          <div key={index} className="col-12 mb-2">
                            <div className="questin-Listing">
                              <div className="row">
                                <div className="col-10">{object.item}</div>
                                {object.image && (
                                  <div className="col-2">
                                    <img
                                      src={profileBaseUrl + object.image}
                                      hight="25"
                                      width="25"
                                      alt="..."
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </React.Fragment>
                ))}
                {/*----------------------stye qs ------------------------------*/}

                {/*----------------------type qs ------------------------------*/}
                {typeQuestionIs?.map((obj, index) => (
                  <React.Fragment key={index}>
                    <div className="user-details mt-5">
                      <h4 className="tag-title mb-2">Type Question</h4>
                      <h5 style={{ color: "#95aac9" }}>{obj.type} (Type)</h5>
                      <span style={{ color: "#95aac9" }}>{obj.question}</span>
                    </div>
                    <div className="tag">
                      <h4 className="tag-title mb-2">Answer</h4>
                      <div className="row">
                        {obj.answer?.map((object, index) => (
                          <div key={index} className="col-12 mb-2">
                            <div className="questin-Listing">
                              <div className="row">
                                <div className="col-10">{object.item}</div>
                                {object.image && (
                                  <div className="col-2">
                                    <img
                                      src={profileBaseUrl + object.image}
                                      hight="25"
                                      width="25"
                                      alt="..."
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <hr className="my-3" />
                  </React.Fragment>
                ))}
                {/*----------------------type qs ------------------------------*/}
              </div>
            </div>
          </Modal>
          {/*--------------------------edit links Modal---------------------------------- */}
          {ImagesandLinks && (
            <EditLinksModal
              {...this.state}
              {...this.props}
              handleMyfunc={limksFunc}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}
