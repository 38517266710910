import React, { Component } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
import { locationUser } from "../../../../store/action/clientList.action";
import { getPublicAPI } from "../../../../store/api-interface";

class CompanyDetails extends Component {
  state = {
    locationIs: [],
    industryIs: [],
    companyIs: [],
  };

  handleCurrentComp = (inputValue) => {
    this.handleCompanyName(inputValue);
  };

  handleCompanyName = (companyName) => {
    getPublicAPI(`/company?name=${companyName}`).then((res) => {
      const response = res.data.data;
      if (response) {
        this.setState({ companyIs: response });
      }
    });
  };

  handleCurrentLocation = (inputValue) => {
    this.handleLocation(inputValue);
  };

  handleLocation = (inputValue) => {
    getPublicAPI(`/location?name=${inputValue}`).then((res) => {
      const response = res.data.data;
      if (response) {
        this.setState({ locationIs: response });
      }
    });
  };

  handleCurrentIndustry = (inputValue) => {
    this.handleIndustry(inputValue);
  };

  handleIndustry = (industryValue) => {
    getPublicAPI(`/industry?name=${industryValue}`).then((res) => {
      const response = res.data.data;
      if (response) {
        this.setState({ industryIs: response });
      }
    });
  };

  componentDidMount() {
    const industryValue = "";
    const locationValue = "";
    const companyName = "";
    this.handleLocation(industryValue);
    this.handleIndustry(locationValue);
    this.handleCompanyName(companyName);
  }

  render() {
    const {
      selectedOption,
      companyName,
      companyJob,
      companyLink,
      companyIndustry,
      companyDesc,
      handleFunc,
      companyType,
      selectedLocation,
      selectedEmp,
      selectedLocIndividual,
      errorIs,
      industryMsg,
      locationMsg,
      empMsg,
      locationIndividualMsg,
      companyMsg,
    } = this.props;

    const { locationIs, industryIs, companyIs } = this.state;

    const companyData = [
      {
        name: "companyJob",
        value: companyJob,
        placeholder: "Enter Company job",
        label: selectedOption.company_job_role_heading,
      },
      {
        name: "companyLink",
        value: companyLink,
        placeholder: "Enter link",
        label: selectedOption.company_add_link_heading,
      },
    ];

    const LocationOptions =
      locationIs &&
      locationIs.map((obj) => {
        let optionIs = {
          label: obj.name,
        };
        return optionIs;
      });

    const IndustryOptions =
      industryIs &&
      industryIs.map((obj) => {
        let optionIs = {
          label: obj.name,
        };
        return optionIs;
      });

    const CompanyOptions =
      companyIs &&
      companyIs.map((obj) => {
        let optionIs = {
          label: obj.name,
        };
        return optionIs;
      });

    const EmpOption = [
      { value: "1-10", label: "1-10" },
      { value: "11-20", label: "11-20" },
      { value: "21-30", label: "21-30" },
      { value: "31-40", label: "31-40" },
      { value: "41-50", label: "41-50" },
      { value: "51-200", label: "51-200" },
      { value: "201-500", label: "201-500" },
      { value: "501-1000", label: "501-1000" },
      { value: "1000-5000", label: "1000-5000" },
      { value: "5001-10000", label: "5001-10000" },
      { value: "10000+", label: "10000+" },
    ];

    return (
      <React.Fragment>
        <div className="form-group">
          <div className="row">
            <div className="col">
              <h3>{selectedOption.company_work_type_heading}</h3>
              <label htmlFor="exampleInputEmail1" className="text-secondary">
                {selectedOption.company_work_type_subtext}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div
                className={`comp-ques ${
                  companyType === "Company" ? `active-quse` : ""
                }`}
              >
                <p
                  className="mb-0 p-3"
                  onClick={() => handleFunc.handleCompanyType("Company")}
                >
                  Company
                </p>
              </div>
            </div>
            <div className="col">
              <div
                className={`comp-ques ${
                  companyType === "Agency" ? `active-quse` : ""
                }`}
              >
                <p
                  className={`mb-0 p-3`}
                  onClick={() => handleFunc.handleCompanyType("Agency")}
                >
                  Agency
                </p>
              </div>
            </div>
            <div className="col">
              <div
                className={`comp-ques ${
                  companyType === "Individual" ? `active-quse` : ""
                }`}
              >
                <p
                  className="mb-0 p-3"
                  onClick={() => handleFunc.handleCompanyType("Individual")}
                >
                  Individual
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-5" />
        {(companyType === "Company" || companyType === "Agency") && (
          <React.Fragment>
            <div className={`form-group`}>
              <div className="row">
                <div className="col">
                  <h3>{selectedOption.company_name_heading}</h3>
                  <CreatableSelect
                    value={companyName}
                    isClearable
                    onChange={handleFunc.handleChange}
                    onInputChange={this.handleInputChange}
                    options={CompanyOptions}
                    placeholder="Enter Company Name"
                    className={`${companyMsg ? `error-show` : ``}`}
                  />
                  {companyMsg && <span className="error">{companyMsg}</span>}
                </div>
              </div>
            </div>
            <hr className="my-5" />
          </React.Fragment>
        )}
        {companyData.map((obj, index) => (
          <React.Fragment key={index}>
            {(companyType === "Company" || companyType === "Agency") && (
              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <h3>{obj.label}</h3>
                    <input
                      type="text"
                      className={`form-control ${
                        ((errorIs.companyJob && obj.name === "companyJob") ||
                          (errorIs.companyLink &&
                            obj.name === "companyLink")) &&
                        `error-show`
                      }`}
                      placeholder={obj.placeholder}
                      name={obj.name}
                      value={obj.value}
                      onChange={handleFunc.handleOnChange}
                    />
                    {((errorIs.companyJob && obj.name === "companyJob") ||
                      (errorIs.companyLink && obj.name === "companyLink")) && (
                      <span className="error">
                        {errorIs.companyJob || errorIs.companyLink}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
            {(companyType === "Company" || companyType === "Agency") &&
              obj.name === "companyLink" && (
                <React.Fragment>
                  {<hr className="my-5" />}
                  <div className={`form-group`}>
                    <div className="row">
                      <div className="col">
                        <h3>{selectedOption.company_industry_heading}</h3>
                        <Select
                          value={companyIndustry}
                          onChange={handleFunc.handleIndustrySelect}
                          options={IndustryOptions}
                          onInputChange={this.handleCurrentIndustry}
                          className={`${industryMsg ? `error-show` : ``}`}
                        />
                        {industryMsg && (
                          <span className="error">{industryMsg}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            {(companyType === "Company" || companyType === "Agency") &&
              obj.name === "companyLink" && (
                <React.Fragment>
                  {<hr className="my-5" />}
                  <div className="form-group">
                    <div className="row">
                      <div className="col">
                        <h3>{selectedOption.company_location_heading}</h3>
                        <Select
                          value={selectedLocation}
                          onChange={handleFunc.handleLocationSelect}
                          options={LocationOptions}
                          onInputChange={this.handleCurrentLocation}
                          className={`${locationMsg ? `error-show` : ``}`}
                        />
                        {locationMsg && (
                          <span className="error">{locationMsg}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            {(companyType === "Company" || companyType === "Agency") && (
              <hr className="my-5" />
            )}
          </React.Fragment>
        ))}
        {(companyType === "Company" || companyType === "Agency") && (
          <React.Fragment>
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <h3>{selectedOption.company_employees_heading}</h3>
                  <Select
                    value={selectedEmp}
                    onChange={handleFunc.handleChangeEmp}
                    options={EmpOption}
                    placeholder="no of employees"
                    className={`${empMsg ? `error-show` : ``}`}
                  />
                  {empMsg && <span className="error">{empMsg}</span>}
                </div>
              </div>
            </div>
            <hr className="my-5" />
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <h3>{selectedOption.company_describes_heading}</h3>
                  <textarea
                    type="number"
                    className={`form-control ${
                      errorIs.companyDesc ? `error-show` : ``
                    }`}
                    placeholder="Enter Description"
                    name="companyDesc"
                    value={companyDesc}
                    onChange={handleFunc.handleOnChange}
                  ></textarea>
                  {errorIs.companyDesc && (
                    <span className="error">{errorIs.companyDesc}</span>
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        {companyType === "Individual" && (
          <div className="form-group">
            <div className="row">
              <div className="col">
                <h3>{selectedOption.company_individual_location_heading}</h3>
                <Select
                  value={selectedLocIndividual}
                  onChange={handleFunc.handleLocaIndividual}
                  options={LocationOptions}
                  onInputChange={this.handleCurrentLocation}
                  className={`${locationIndividualMsg ? `error-show` : ``}`}
                />
                {locationIndividualMsg && (
                  <span className="error">{locationIndividualMsg}</span>
                )}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({ locationUser }) => {
  return {
    _locationUser: locationUser,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $locationUser: (values) => dispatchEvent(locationUser(values)),
});
export default connect(mapState, mapDispatch)(CompanyDetails);
