import  Select from "react-select";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import CheckStatus from "../CheckStatus/CheckStatus";
import LoaderSmall from '../Loader/LoaderSmall';

export default class HeaderUpperSection extends Component {

  handleSelect = (value) => {
    this.props.history.push(`/custom/AddForm/:${value.value}/:id`)
  }

  
  render() {
    const {
      DuplicateCollaterlIs,
      collateralIs,
      artistFirstName,
      artistLastName,
      clientData,
      handlefunc,
      
      pathIs,
      location,
      transactionData,
      currentTabs,
      RoleIs
    } = this.props;
    
    const customOption = [{label:'Software',value:'Software'},{label:'Industry',value:'Industry'},{label:'Specialization',value:'Specialization'}]
    
    const artistId = location.pathname.split("/:").pop();

    const addCollateral = this.props.location.pathname.split("/");

    return (
      <React.Fragment>
        {(RoleIs === 1 || ((RoleIs === 0 && currentTabs.map(item => item.tab).includes(pathIs))|| artistId === "/")) &&
        <div className="row align-items-end">
          <div className="col">
            <h6 className="header-pretitle text-secondary">Overview</h6>

            <div className="d-flex align-items-center">
              <h1 className="header-title text-secondary">{pathIs}</h1>
              {collateralIs.name &&
                artistId !== "/collaterals" &&
                pathIs === "Collaterals" &&
                addCollateral[2] !== ":add" && (
                  <span className="badge badge-pill badge-soft-secondary ml-3">
                    {collateralIs.name}
                  </span>
                )}
              {artistFirstName &&
                artistId !== "/artist" &&
                pathIs === "Artist" &&
                // artistId !== "/artist/artistProfileUpdates" &&
                artistId !== "/artist/artistApprovals" &&
                artistId !== "/artist/artistDraft" &&
                artistId !== "/artist/approvedArtist" &&
                artistId !== "/artist/artistRejected" &&
                artistId !== "/artist/artistDeleted" && (
                  <span className="badge badge-pill badge-soft-secondary ml-3">
                    {artistFirstName + " " + artistLastName}
                  </span>
                )}
              {clientData &&
                pathIs === "Clients" &&
                artistId !== "/clients" &&
                artistId !== "/clientDeleted" &&
                artistId !== "/milestones"  && (
                  <span className="badge badge-pill badge-soft-secondary ml-3">
                    {clientData.first_name + " " + clientData.last_name}
                  </span>
                )}
              {transactionData.project_name &&
                artistId !== "/transactions" &&
                artistId !== "/ActiveTransactions" &&
                artistId !== "/PendingTransactions" &&
                artistId !== "/incompleteTransactions" &&
                pathIs === "Transactions" && (
                  <span className="badge badge-pill badge-soft-secondary ml-3">
                    {transactionData.project_name}
                  </span>
                )}
            </div>
          </div>
          {artistId === "/collaterals" && (
            <div className="col-auto">
              <Link
                to="/collateralsQuestions/:add/:"
                className="btn btn-primary lift"
              >
                Add Collateral
              </Link>
            </div>
          )}
          {pathIs === "Collaterals" &&
            addCollateral[2] !== "creator" &&
            addCollateral[2] !== "projects" &&
            addCollateral[2] !== "transactions" &&
            addCollateral[2] !== "history" &&
            artistId !== "/collaterals" &&
            addCollateral[2] !== ":add" && (
              <div className="col-auto">
                <Link
                  to="#!"
                  className="btn btn-primary lift"
                  onClick={handlefunc.handleDuplicateCollateral}
                >
                  {DuplicateCollaterlIs
                    ? <LoaderSmall />
                    : "Duplicate Collateral"}
                </Link>
              </div>
            )}
          {artistId === "/parentcreative" && (
            <div className="col-auto">
              <Link
                to="/parentcreative/:add/:/:"
                className="btn btn-primary lift"
              >
                Add Parent Field
              </Link>
            </div>
          )}
          {(artistId === "/transactions" || artistId === "/transactions/incompleteTransactions") && (
            
            <div className="col-auto">
              <Link to="/transactions/completeProject" className="btn btn-primary mr-2">
                Add Complete Project
              </Link>
           
              <Link to="/transactions/addProject/:" className="btn btn-primary lift ">
                Add Project
              </Link>
      
            </div>
          )}
          {(artistId === "/artist" || artistId === "/clients") && (
            <div className="col-auto">
              <CheckStatus {...this.props} />
            </div>
          )}
          {(artistId === "/backendusers") && (
            <div className="col-auto">
             <Link to="/backendusers/backendusersForm/:" className="btn btn-primary lift">
                Add 
              </Link>
            </div>
          )}
           {(artistId === "/packages") && (
            <div className="col-auto">
             <Link to="/packages/packageForm/:" className="btn btn-primary lift">
                Add Packages
              </Link>
            </div>
          )}

            {(artistId === "/custom" || artistId === '/custom/Industry' || artistId === '/custom/Specialization') && (
                <div className="col-2">
                  {/* <span className="text-primary mx-2">Add </span>  */}
                <Select
                          options={customOption}
                          onChange={this.handleSelect}
                          displayValue="name"
                          // value={selectedTab}
                    />
                {/* <Link to="/custom/AddForm/:" className="btn btn-primary lift">
                    Add
                  </Link> */}

                </div>
              )}

        </div> 
        }
      </React.Fragment>
    );
  }
}
