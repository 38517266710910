import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../Common/Loader/Loader";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { deleteAPI } from "../../../store/api-interface";
import { getCustomSpecializationList } from "../../../store/action/cutom.action";
import { putAPI } from "../../../store/api-interface"

class Customspecialization extends Component {
  state = {
    LoadingIs: false,
    currentPage: 1,
    userData: [],
    totalPage: "",
    // DeleteLoadingIs: false,
    isChecked : false,
    checkedId : 0
  };


  choosePrimarySpecialization = async(data) => {
    const res = await putAPI(`/backendUser/update-specialization`, data)
    if(res){
      this.getSpecList()
    }
  }


  handleChange = (e, obj) => {
    const { checked } = e.target;
    let data;
    if(checked){
      this.setState({isChecked : true, checkedId : obj?._id})
      data = { isPrimary : 1, _id : obj?._id }
    }else{
      this.setState({isChecked : false, checkedId : 0})
      data = { isPrimary : 0, _id : obj?._id }
    }
    this.choosePrimarySpecialization(data)
  }
 
  handleGetConfirmation = (deleteData) => {
    let confirmIs = window.confirm(`Do you want to Delete this item?`);
    if (confirmIs === true) {
      this.setState({ DeleteLoadingIs: true });
      this.handleDeleteItem(deleteData)
      return true;
    } else {
      return false;
    }
  };

  handleDeleteItem = async(deleteData) =>{
    console.log(deleteData)
    try{
      const response = await deleteAPI('/onboarding/delete-software-industry-specialization',deleteData);
      const data = response.data
      if(data?.status===1){
        const data2 = {
          page:this.state.currentPage,
          name:"",
          type:"specialization",
          limit:10
        }
        this.props.$getCustomSpecializationList(data2);
      }
    }catch(err){
      console.log(err)
    }
  }

  handleDelete = (obj) => {
    const deleteData = { type: 'specialization',name:obj?.name };
    this.handleGetConfirmation(deleteData);
  };

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    // const { email, sort, userName } = this.state;
    const dataIs = {
      page: selectedData,
      name: '',
      type: 'specialization',
      limit: 10,
    };
    this.handleBackendUser(dataIs);
  };

  handleBackendUser = (currentData) => {
    this.setState({ LoadingIs: true });
    // this.props.$backendDeleteList(currentData);
    this.props.$getCustomSpecializationList(currentData);

  };

  getSpecList = () => {
    const { currentPage } = this.state;

    const data2 = {
      page:currentPage,
      name:"",
      type:"specialization",
      limit:10
    }
    this.handleBackendUser(data2);
  }

  componentDidMount() {
    this.getSpecList()
    // this.props.$getCustomSpecializationList(data2);

  }

  componentWillReceiveProps(nextProps) {
    const listIs = nextProps?._getCustomList?.customSpecializationList;
    if (nextProps) {
      this.setState({
        userData: listIs.data,
        totalPage: listIs.totalPages,
        LoadingIs: false,
        currentPage:listIs.currentPage,
      });
    }
  }
  
  callComponentDidMount = () => {
    this.componentDidMount()
  }

  render() {
    const { LoadingIs, userData, isChecked, checkedId } = this.state;

    const otherDbsArr = [

      { name: "Action" },
      { name: "name" },
      { name: "Active" },
      { name: "Mark/Unmark (primary)" }
    ];

    return (
      <React.Fragment>
          <section className="client">
            <div className="container-fluid mx-auto">
              {/* <div className="row col-md-12 mb-5">
                <div className="col">
                  <form className="row align-items-center">
                    <div className="col-auto pr-0">
                      <span className="fe fe-search text-muted"></span>
                    </div>
                    {searchTable.map((obj, index) => (
                      <div className="col" key={index}>
                        <input
                          name={obj.name}
                          type="search"
                          value={obj.value}
                          className="form-control form-control-flush search"
                          placeholder={obj.placeholder}
                          onChange={this.handleOnChange}
                          autoComplete="off"
                        />
                      </div>
                    ))}
                  </form>
                </div>
              </div> */}
              {LoadingIs ? (
                <Loader />
              ) : (
                <div className="table-responsive">
                  <table className="table table-sm table-nowrap card-table">
                    {/*----------------- table head --------------------*/}
                    <thead>
                      <tr>
                        {otherDbsArr.map((obj, index) => (
                          <th key={index}>
                            <Link to="#!" className="text-muted sort">
                              {obj.name}
                            </Link>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    {/*----------------- table head --------------------*/}
                    <tbody className="list">
                      {userData?.length > 0 ? (
                        userData.map((obj, index) => (
                          <tr key={index}>
                          < td>
                        { obj.status===1 &&       
                        <div className={`dropdown`}>
                          <a
                            href="#!"
                            className="dropdown-ellipses dropdown-toggle text-secondary"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fe fe-more-vertical"></i>
                          </a>
                            <div
                              className={`ma-auto dropdown-menu dropdown-menu-right text-center`}
                            >
                                <span>
                                  <li
                                    // href={`${WebsiteUrl}creator/${obj?.user?.username}`}
                                    target='_blank'
                                    className="dropdown-item mb-2"
                                    datatoggle="modal"
                                    datatarget="#exampleModalCenter"
                                    style={{cursor:"pointer"}}
                                    onClick={()=>this.handleDelete(obj)}
                                  >
                                    Delete
                                  </li>
                                </span>
                            </div>
                        </div>}
                      
                          </td>

                            <td className="orders-total">{obj.name}</td>
                            <td>
                              <div
                                className={`badge ${
                                  obj.status===1
                                    ? `badge-soft-success`
                                    : `badge-soft-danger`
                                }`}
                              >
                                {obj.status===1?"Active":"Inactive"}
                              </div>
                            </td>
                            <td className="orders-total">
                              <input type="checkbox" name="specialization" 
                              checked={((obj?.isPrimary === 1) || (isChecked && checkedId === obj?._id) ) ? true : false} 
                              onChange={(e) => this.handleChange(e, obj)}/>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>No data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}

              {/****************** Pagination ********************/}

              {this.state.totalPage > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.totalPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </div>
          </section>
      </React.Fragment>
    );
  }
}
const mapState = ({ customReducer }) => {
  return {
    _getCustomList:customReducer,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $getCustomSpecializationList : (values) => dispatchEvent(getCustomSpecializationList(values)),
  
});
export default connect(mapState, mapDispatch)(Customspecialization);
