import React, { Component } from "react";
import LoaderSmall from "../Common/Loader/LoaderSmall";
import { connect } from "react-redux";
import {
  requestChangeProposal,
  transactionGetData,
} from "../../../store/action/transaction.action";
import Modal from "react-modal";
import CKEditor from "ckeditor4-react";
import './modalReview.css'

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    width: "100%",
  },
};

class ReqChangeProposal extends Component {
  state = {
    reqNote: "",
    LoadingIs: false,
  };

  // handleOnChange = (e) => {
  //   const { name, value } = e.target;
  //   this.setState({
  //     [name]: value,
  //   });
  // };

  rteChange = (evt) => {
    this.setState({ reqNote: evt.editor.getData() });
  };

  handleSubmit = () => {
    const { MilesData } = this.props;
    this.props.handleMiles.handleState({ ReqChangeActive: true });
    const currentData = {
      proposal_id: MilesData?.proposal?.proposal._id,
      admin_request_note: this.state.reqNote,
      external:this?.props?.type?true:false
    };
    this.props.$requestChangeProposal(currentData);
    this.props.handleMiles.handleCloseReqModal();
    this.props.handleFunc.handleCloseProposalModal();
  };

  render() {
    // {console.log(this.props,'------------------------------> req change props')}
    const { modalIsOpen, handleMiles } = this.props;
    const { reqNote } = this.state;
    return (
      <React.Fragment>
        <Modal
          isOpen={modalIsOpen}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <button
            type="button"
            className="close media-upload-modal"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleMiles.handleCloseReqModal}
          >
            <span
              aria-hidden="true"
              style={{ color: "black", fontSize: "32px" }}
            >
              &times;
            </span>
          </button>
          <div className="container">
            <h3 className="text-center">Request Change</h3>
            <div className="row">
              <div className="col-12">
                <label>Note</label>
                <div className="form-group">
                  
                  <CKEditor
                    data={reqNote}
                    onChange={this.rteChange}
                    name="reqNote"
                    value={reqNote || ""}
                    config={ {
                      toolbar: [ [ '' ] ] ,
                      
                  } }
                  
                  />
                  {/* <textarea
                    className="form-control"
                    name="reqNote"
                    value={reqNote}
                    onChange={this.handleOnChange}
                  ></textarea> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 text-right">
              <button className={`btn btn-primary`} onClick={this.handleSubmit}>
                {`${
                  this.state.LoadingIs ? (
                    <LoaderSmall {...this.props} />
                  ) : (
                    `Submit`
                  )
                }`}
              </button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
const mapState = ({ requestChangeProposal, transactionGetData }) => {
  return {
    _requestChangeProposal: requestChangeProposal,
    _transactionGetData: transactionGetData,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $requestChangeProposal: (values) =>
    dispatchEvent(requestChangeProposal(values)),
  $transactionGetData: (values) => dispatchEvent(transactionGetData(values)),
});
export default connect(mapState, mapDispatch)(ReqChangeProposal);
