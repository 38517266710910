import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import MilestoneFixed from "./proposalMilestone/MilestoneFixed";
import MilestoneRetainer from "./proposalMilestone/MilestoneRetainer";
import MilestoneActual from "./proposalMilestone/MilestoneActual";
import ProposalType from "./ProposalType";
import ProposalUpperSection from "./proposalMilestone/ProposalUpperSection";
export default class Milestone extends Component {
  render() {
    const {
      milestoneInputs,
      note,
      handleFunc,
      proposal_type,
      payment_terms
    } = this.props;

    // if ((Gst || Gst === 0) && (clientBudget || clientBudget === 0)) {
    // var totalCost = clientBudget?Number(Gst) + Number(clientBudget):0;
    // }

    const disabledMile = milestoneInputs.filter((item) => item.status === 1);
    const disabledId = disabledMile[0];
    let paymentTerms = []
    payment_terms.forEach(el=>{
      if(el.type == 'token' && (el.price_without_tax || el.total_earning)){
        paymentTerms.push(el)
      }
      else{
        paymentTerms.push(el)
      }
    })
    return (
      <React.Fragment>
        <form>

          <hr className="my-5" />
          <ProposalType {...this.props} />
          <hr className="my-5" />
          <ProposalUpperSection handleFunc={handleFunc} {...this.props} />
          <hr className="my-5" />
          {proposal_type == 'fixed' ?
            <MilestoneFixed handleFunc={handleFunc} disabledId={disabledId} {...this.props}/>
            : proposal_type == 'retainer' ?
              <MilestoneRetainer handleFunc={handleFunc} disabledId={disabledId} {...this.props} />
              :
              <MilestoneActual handleFunc={handleFunc} disabledId={disabledId} {...this.props} />}

          <div className="form-group">
            <div className="row">
              <div className="col">
                <label htmlFor="exampleInputEmail1" className="text-secondary">
                  Note
                </label>
                <textarea
                  className="form-control"
                  placeholder="...."
                  name="note"
                  value={note}
                  onChange={handleFunc.handleChange}
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
