import React, { Component } from "react";
import { profileBaseUrl } from "../../../Utils/BaseUrl";
import LoaderSmall from '../Common/Loader/LoaderSmall';

export default class CollateralBasicDetailsForm extends Component {
  render() {
    const collateralViewParam = this.props.match.params.type.split(":").pop();
    const {
      firstName,
      alternativeName,
      parentCF,
      pcfListVisibility,
      pcfDropdownData,
      pcfSelectedItems,
      aboutCollateral,
      alternateNameIs,
      collateralEditIs,
      uploadLoadingIs,
      LoadingIs,
      secondImageLoader,
      standardProject,
      handlefunc,
       errorIs,
       frontendUrl
    } = this.props;
    return (
      <React.Fragment>
        <section className="container">
          {/* <form > */}
          <div className="row">
            <div className="mt-3 mb-3 col">
              <div
                className={`avatar avatar-xl ${errorIs.profileImage && `error-show`}`}
                style={{ height: "58px", width: "276px" }}
              >
                <img
                  src={
                    this.props.profileImage
                      ? `${profileBaseUrl + this.props.profileImage}`
                      : require("../../../Assets/img/avatars/profiles/images.jpeg")
                  }
                  alt="..."
                  className="avatar-img rounded"
                />
              </div>
              <div className="upload mt-4">
                <input
                  type="file"
                  ref={(ref) => (this.uploadfirstImage = ref)}
                  accept="jpg,jpeg,png"
                  style={{ display: "none" }}
                  name="firstImage"
                  onChange={handlefunc.handleUploadImage}
                />
                <button
                  type="button"
                  onClick={() => this.uploadfirstImage.click()}
                  className="btn btn-outline-primary mr-auto"
                >
                  {uploadLoadingIs
                    ? "Uploading...."
                    : "Upload Collateral image"}
                </button>
                <br />
                <small className="mb-0 mt-2 text-muted">
                  PNG, JPG or JPEG no bigger than 500 px * 105 px wide and tall.
                </small>
              </div>
            </div>
            <div className="mt-3 mb-3 col">
              <div
                className={`avatar avatar ${errorIs.secondImage &&  `error-show`}`}
                style={{ height: "114px", width: "224px" }}
              >
                <img
                  src={
                    this.props.secondImage
                      ? `${profileBaseUrl + this.props.secondImage}`
                      : require("../../../Assets/img/avatars/profiles/images.jpeg")
                  }
                  alt="..."
                  className="avatar-img rounded"
                />
              </div>
              <div className="upload mt-4">
                <input
                  type="file"
                  ref={(ref) => (this.uploadSecondImage = ref)}
                  accept="jpg,jpeg,png"
                  style={{ display: "none" }}
                  name="secondImage"
                  onChange={handlefunc.handleUploadImage}
                />
                <button
                  type="button"
                  onClick={() => this.uploadSecondImage.click()}
                  className="btn btn-outline-primary "
                >
                  {secondImageLoader
                    ? "Uploading...."
                    : "Upload Collateral image"}
                </button>
                <br />
                <small className="mb-0 mt-2 text-muted">
                  PNG, JPG or JPEG no bigger than 650 px * 330 px wide and tall.
                </small>
              </div>
            </div>
          </div>
          <hr className="my-5" />
          <div className="form-group">
            <div className="row">
              <div className="col">
                <label htmlFor="exampleInputEmail1" className="text-secondary">
                  Name
                </label>
                <input
                  type="text"
                  className={`form-control ${errorIs.firstName && `error-show`}`}
                  placeholder="name"
                  maxLength="45"
                  name="firstName"
                  value={firstName}
                  onChange={handlefunc.handleOnchange}
                  autoComplete = "off"
                  // disabled={`${(collateralViewParam === "view" || collateralViewParam === "edit")? `disabled` : ``}`}
                />
                {errorIs.firstName && <span className="error">{`${errorIs ? errorIs.firstName : ""}`}</span>}
              </div>
              <div className="col">
                <label htmlFor="exampleInputEmail1" className="text-secondary">
                  Alternative name
                </label>
                {alternateNameIs.map((obj, index) => (
                  <div className="pcf-parent" key={index}>
                    <span className="selected-pcf">
                      {obj.name}
                      <i
                        className="fe fe-x ml-3"
                        style={{ fontSize: "12px" }}
                        onClick={() => handlefunc.handleDeleteAlternateName(index)}
                      ></i>
                    </span>
                  </div>
                ))}
                <form onSubmit={handlefunc.handleAlternateName}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Press Enter after  name"
                    name="alternativeName"
                    value={alternativeName}
                    onChange={handlefunc.handleOnchange}
                    maxLength="45"
                    autoComplete = "off"
                  />
                </form>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col">
                <label htmlFor="exampleInputEmail1" className="text-secondary">
                  Parent cs
                </label>
                {pcfSelectedItems.map((obj, index) => (
                  <div className="pcf-parent" key={index}>
                    <span className="selected-pcf">
                      {obj.parent_creative_field_name}
                      <i
                        className="fe fe-x ml-3"
                        style={{ fontSize: "12px" }}
                        onClick={() =>
                            handlefunc.handleDeleteParentCreativeField(index)
                        }
                      ></i>
                    </span>
                  </div>
                ))}
                <input
                  type="text"
                  className="form-control"
                  placeholder="Parent cs"
                  autoComplete="off"
                  name="parentCF"
                  value={parentCF}
                  onChange={handlefunc.handleOnchange}
                  onClick={handlefunc.handlecollateralListPcf}
                  list="dlist12"
                />
                {pcfListVisibility && (
                  <ul
                    id="dlist12"
                    className={`list-group list-group-sm pcf-list ${
                      pcfDropdownData.length ? "scroll-list" : ""
                    }`}
                    style={{ boxShadow: "0px 0px 3px 0px silver" }}
                  >
                    {pcfDropdownData.map((obj, index) => (
                      <li
                        className="list-group-item"
                        key={index}
                        onClick={() => handlefunc.handleSelectedListItem(obj)}
                      >
                        {obj.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col">
                <label htmlFor="exampleInputEmail1" className="text-secondary">
                  Frontend Url for Collateral
                </label>
                <input
                  className="form-control"
                  placeholder="Enter url"
                  name="frontendUrl"
                  value={frontendUrl}
                  onChange={handlefunc.handleOnchange}    
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col">
                <label htmlFor="exampleInputEmail1" className="text-secondary">
                  About Collateral
                </label>
                <textarea
                  className="form-control"
                  placeholder="enter text"
                  name="aboutCollateral"
                  value={aboutCollateral ? aboutCollateral : ''}
                  onChange={handlefunc.handleOnchange}
                  maxLength="400"
                  autoComplete = "off"
                ></textarea>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label htmlFor="exampleInputEmail1" className="text-secondary">
                  Standard Project/Unit Description
                </label>
                <textarea
                  className={`form-control ${errorIs.standardProject && `error-show`}`}
                  placeholder='standard project'
                  name="standardProject"
                  value={standardProject}
                  onChange={handlefunc.handleOnchange}
                  maxLength="250"
                ></textarea>
                 {errorIs.standardProject && <span className="error">{`${errorIs ? errorIs.standardProject : ""}`}</span>}
              </div>
            </div>
          </div>

          {collateralViewParam !== "view" && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={
                collateralEditIs
                  ? handlefunc.handleUpdateCollateral
                  : handlefunc.handleAddCollateral
              }
            >
              {collateralEditIs
                ? LoadingIs
                  ? <LoaderSmall />
                  : "Update"
                : LoadingIs
                ? <LoaderSmall />
                : "Submit"}
            </button>
          )}
          {/* </form> */}
        </section>
      </React.Fragment>
    );
  }
}
