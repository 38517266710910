import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../Common/Loader/Loader";

export default class Packages extends Component {
  state = {
    LoadingIs: false,
  };

  handleOtherDbs = () => {
    this.setState({
      LoadingIs: false,
    });
  };

  componentDidMount() {
    this.handleOtherDbs();
  }
  render() {
    const otherDbsArr = [
      { name: "Order" },
      { name: "Product" },
      { name: "Date" },
      { name: "Total" },
      { name: "Status" },
      { name: "Payment method" },
      { name: "Action" },
    ];

    const { LoadingIs } = this.state;
    return (
      <React.Fragment>
        <section className="client mt-5">
          <div className="container-fluid mx-auto">
            {LoadingIs ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <table className="table table-sm table-nowrap card-table">
                  {/*----------------- table head --------------------*/}
                  <thead>
                    <tr>
                      {otherDbsArr.map((obj, index) => (
                        <th key={index}>
                          <Link to="#!" className="text-muted sort">
                            {obj.name}
                          </Link>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {/*----------------- table head --------------------*/}
                  <tbody className="list">
                    <tr>
                      <td className="orders-order">#6520</td>
                      <td className="orders-product">5' x 3' Wall Poster</td>
                      <td className="orders-date">07/30/18</td>
                      <td className="orders-total">$55.25</td>
                      <td className="orders-status">
                        <div className="badge badge-soft-success">Shipped</div>
                      </td>
                      <td className="orders-method">Mastercard</td>
                      <td className="text-method">
                        <Link to="#!">
                          <button className="btn btn-outline-success btn-sm mr-2">
                            <i className="fe fe-eye"></i>
                          </button>
                        </Link>
                        <Link to="#!">
                          <button className="btn btn-outline-info btn-sm mr-2">
                            <i className="fe fe-edit"></i>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </section>
      </React.Fragment>
    );
  }
}
