import React, { Component } from "react";

export default class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <h3>home here...</h3>
      </React.Fragment>
    );
  }
}
