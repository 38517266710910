import React, { Component } from "react";
import Select from "react-select";
import ProjectDeadline from './ProjectDeadline';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default class ProjectOtherDetails extends Component {

  render() { 

    const {
      selectedOption,
      minBudget,
      maxBudget,
      unit,
      selectedClient,
      selectedCreator,
      project,
      projectDescription,
      handleFunc,
      AllClientList,
      selectedRepresntative,
      errorIs,
      clientMsg,
      creatorMsg,
      representativeMsg,
      StatusIs,
      clientListIs,
      creatorListIs,
      project_type,
      project_deadline_date,
      number_hour,
      number_month,
      number_hour_msg,
      number_month_msg,
    } = this.props;

    const repOptions = AllClientList && AllClientList.map((obj)=>{
      let optionIs = {
         _id : obj._id,
         label : `${obj.first_name} ${obj.last_name} (${obj.email})`, 
         value : obj.first_name + " " + obj.last_name,
         email : obj.email
      }
      return optionIs
    })

    const clientOptionsIs = clientListIs && clientListIs.map((obj)=>{
      let optionIs = {
        _id : obj._id,
        label : `${obj.first_name} ${obj.last_name} (${obj.email})`, 
        value : obj.first_name + " " + obj.last_name,
        email : obj.email
     }
     return optionIs
    })

    const creatorOptions = creatorListIs && creatorListIs.map((obj)=>{
      let optionIs = {
        _id : obj._id,
        label : `${obj.first_name} ${obj.last_name} (${obj.email})`, 
        value : obj.first_name + " " + obj.last_name,
        email : obj.email
     }
     return optionIs
    })
    
    return (
      <React.Fragment>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <h3>{selectedOption.budget_heading}</h3>
              <label htmlFor="exampleInputEmail1" className="text-secondary">
                {selectedOption.budget_subtext}
              </label>
            </div>
            <div className="col">
              <input
                type="number"
                className={`form-control ${errorIs.minBudget ? `error-show` : ``}`}
                placeholder="min budget"
                name="minBudget"
                value={minBudget}
                onChange={handleFunc.handleOnChange}
              />
              {errorIs.minBudget && <span className="error">{errorIs.minBudget}</span>}
            </div>
            <div className="col">
              <input
                type="number"
                className={`form-control ${errorIs.maxBudget ? `error-show` : ``}`}
                placeholder="max budget"
                name="maxBudget"
                value={maxBudget}
                onChange={handleFunc.handleOnChange}
              />
               {errorIs.maxBudget && <span className="error">{errorIs.maxBudget}</span>}
            </div>
          </div>
        </div>

       
          <React.Fragment>
            <hr className="my-5" />
            <div className="form-group">
              <div className="row">
                <div className="col-12">
                {project_type==='fixed'? 
                <div className="form-inline">
                  <h3
                    htmlFor="exampleInputEmail1"
                    className="text-secondary"
                  >
                    Add a Project Deadline
                  </h3>
                  <DatePicker
                    className="form-control mx-5"
                    selected={project_deadline_date}
                    onChange={(value)=>handleFunc.handleOnChangeDeadlineDate(value)}
                    name="project_deadline_date"
                    minDate={new Date()}
                    endDate = {null}
                    value={project_deadline_date}
                    placeholder="Deadline Date"
                    autoComplete="off"
                    // disabled={editIs ? "disabled" : ""}
                    dateFormat="dd-MM-yyyy"
                  />               
                </div>      
                :  
                <div className="d-flex justify-content-between">
                  <div className="mr-5">
                <label
                  htmlFor="exampleInputEmail1"
                  className="text-secondary"
                >
                  How many months are you looking to hire the resource for?
                </label>
                <input
                  type="number"
                  className={`form-control ${number_month_msg ? `error-show` : ``}`}
                  name="number_month"
                  value={number_month}
                  onChange={handleFunc.handleChangeRetainerWorkHour}
                />                 
                 {number_month_msg && <span className="error">{number_month_msg}</span>}
                 </div>
                 <div className="ml-5">
                <label
                  htmlFor="exampleInputEmail1"
                  className="text-secondary"
                >
                  How many hours do you expect the resource to work in a week?
                </label>
                <input
                  type="number"
                  className={`form-control ${number_hour_msg ? `error-show` : ``}`}
                  name="number_hour"
                  value={number_hour}
                  onChange={handleFunc.handleChangeRetainerWorkHour}
                />                 
                 {number_hour_msg && <span className="error">{number_hour_msg}</span>}
                 </div>
              </div>      
              }
              </div>
              </div>
            </div>
          </React.Fragment>
      

        {selectedOption.pricing_unit === "per_unit" && project_type==='fixed' &&(
          <React.Fragment>
            <hr className="my-5" />
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <h3>{selectedOption.unit_question}</h3>
                  <label
                    htmlFor="exampleInputEmail1"
                    className="text-secondary"
                  >
                    {selectedOption.unit_question_subtext}
                  </label>
                  <input
                    type="number"
                    className={`form-control ${errorIs.unit ? `error-show` : ``}`}
                    placeholder="unit"
                    name="unit"
                    value={unit}
                    onChange={handleFunc.handleOnChange}
                  />                 
                   {errorIs.unit && <span className="error">{errorIs.unit}</span>}
                </div> 
                <div className="col-1">
                  <h3 style={{visibility: "hidden"}}>unit</h3>
                  <label className="text-secondary" style={{visibility: "hidden"}}>unit </label>
                  <p>{selectedOption.unit}</p>
                </div>               
              </div>
            </div>
          </React.Fragment>
        )}
        <hr className="my-5" />
        {/*-----------------------deadline ---------------------------*/}

        <ProjectDeadline
          {...this.state}
          {...this.props}
        //   handleFunc={projectFunc}
        />

        {/*-----------------------deadline ---------------------------*/}
        <hr className="my-5" />
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <h3>{selectedOption.project_name_heading}</h3>
              <label htmlFor="exampleInputEmail1" className="text-secondary">
                {selectedOption.project_name_subtext}
              </label>
            </div>
            <div className="col">
              <input
                type="text"
                className={`form-control ${errorIs.project ? `error-show` :``}`}
                placeholder="Project"
                name="project"
                value={project}
                onChange={handleFunc.handleOnChange}
              />
              {errorIs.project && <span className="error">{errorIs.project}</span>}
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <h3>{selectedOption.project_description_heading}</h3>
              <label htmlFor="exampleInputEmail1" className="text-secondary">
                {selectedOption.project_description_subtext}
              </label>
            </div>
            <div className="col">
              <textarea
                // type="text"
                className={`form-control ${errorIs.projectDescription ? `error-show` : ``}`}
                placeholder="..."
                name="projectDescription"
                value={projectDescription}
                onChange={handleFunc.handleOnChange}
              ></textarea>
              {errorIs.projectDescription && <span className="error">{errorIs.projectDescription}</span>}
            </div>
          </div>
        </div>

        {/* ---------------------------------client creator ---------------------------------- */}
        <React.Fragment>
        <hr className="my-5" />
        <div className="form-group">
          <div className="row">
            <div className="col">
              <label htmlFor="exampleInputEmail1" className="text-secondary">
                Choose Client
              </label>
              <Select
                value={selectedClient}
                onChange={handleFunc.handleClientChange}
                options={clientOptionsIs}
                onInputChange={handleFunc.handleClient}
                name="selectedClient"
                className={`${clientMsg ? `error-show` : ``}`}
                isDisabled={this.props?.disab ? false : (StatusIs === 0 ||StatusIs === -1) ? false : true}
              />
              {clientMsg && <span className="error">{clientMsg}</span>}
            </div>
            <div className="col">
              <label htmlFor="exampleInputEmail1" className="text-secondary">
                Choose Creator
              </label>
              <Select
                value={selectedCreator}
                onChange={handleFunc.handleCreatorChange}
                options={creatorOptions}
                onInputChange={handleFunc.handleCreator}
                className={`${creatorMsg ? `error-show` : ``}`}
                isDisabled={this.props?.disab ? false : (StatusIs === 0 || StatusIs === -1 ) ? false : true}
              />
              {creatorMsg && <span className="error">{creatorMsg}</span>}
            </div>
            {/* {!this.props.noRep&&<div className="col">
              <label htmlFor="exampleInputEmail1" className="text-secondary">
                Choose Repersentative
              </label>
              <Select
                value={selectedRepresntative}
                onChange={handleFunc.handleRepresntativeChange}
                options={repOptions}
                onInputChange={handleFunc.handleRepresntative}
                className={`${representativeMsg ? `error-show` : ``}`}
                isDisabled={(StatusIs === 0 || StatusIs === -1)? false :true}
              />
              {representativeMsg && <span className="error">{representativeMsg}</span>}
            </div>} */}
          </div>
        </div>
        </React.Fragment>
        
      </React.Fragment>
    );
  }
}
