export const LOGIN_DATA = "LOGIN_DATA";
export const LOGIN_DATA_SUCCESS = "LOGIN_DATA_SUCCESS";
export const LOGIN_DATA_ERROR = "LOGIN_DATA_ERROR";

export const GET_ADMIN_USER_DATA = "GET_ADMIN_USER_DATA";
export const GET_ADMIN_USER_DATA_SUCCESS = "GET_AMIN_USER_DATA_SUCCESS";
export const GET_ADMIN_USER_DATA_ERR = "GET_ADMIN_USER_DATA_ERR";

export const RESET_PWD = "RESET_PWD";
export const RESET_PWD_SUCCESS = "RESET_PWD_SUCCESS";
export const RESET_PWD_ERROR = "RESET_PWD_ERROR";

export const CLIENT_LIST = "CLIENT_LIST";
export const CLIENT_LIST_SUCCESS = "CLIENT_LIST_SUCCESS";
export const CLIENT_LIST_ERROR = "CLIENT_LIST_ERROR";

export const CLIENT_DATA = "CLIENT_DATA";
export const CLIENT_DATA_SUCCESS = "CLIENT_DATA_SUCCESS";
export const CLIENT_DATA_ERROR = "CLIENT_DATA_ERROR";

export const CHECK_STATUS = "CHECK_STATUS";
export const CHECK_STATUS_SUCCESS = "CHECK_STATUS_SUCCESS";

export const USER_SIGNUP = "USER_SIGNUP";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";

export const CREATIVE_USERS_DATA = "CREATIVE_USERS_DATA";
export const CREATIVE_USERS_DATA_SUCCESS = "CREATIVE_USERS_DATA_SUCCESS";

export const ADD_USERS_DATA = "ADD_USERS_DATA";
export const ADD_USERS_DATA_SUCCESS = "ADD_USERS_DATA_SUCCESS";

export const UPDATE_USERS_DATA = "UPDATE_USERS_DATA";
export const UPDATE_USERS_DATA_SUCCESS = "UPDATE_USERS_DATA_SUCCESS";

export const VIEW_USERS_DATA = "VIEW_USERS_DATA";
export const VIEW_USERS_DATA_SUCCESS = "VIEW_USERS_DATA_SUCCESS";

export const COLLATERAL_LIST = "COLLATERAL_LIST";
export const COLLATERAL_LIST_SUCCESS = "COLLATERAL_LIST_SUCCESS";
export const COLLATERAL_LIST_ERROR = "COLLATERAL_LIST_ERROR";

export const COLLATERAL_IMAGE = "COLLATERAL_IMAGE";
export const COLLATERAL_IMAGE_SUCCESS = "COLLATERAL_IMAGE_SUCCESS";

export const COLLATERAL_PCF = "COLLATERAL_PCF";
export const COLLATERAL_PCF_SUCCESS = "COLLATERAL_PCF_SUCCESS";

export const COLLATERAL_GET = "COLLATERAL_GET";
export const COLLATERAL_GET_SUCCESS = "COLLATERAL_GET_SUCCESS";
export const COLLATERAL_GET_ERROR = "COLLATERAL_GET_ERROR";

export const PROJECT_LIST = "PROJECT_LIST";
export const PROJECT_LIST_SUCCESS = "PROJECT_LIST_SUCCESS";

export const ARTIST_LIST = "ARTIST_LIST";
export const ARTIST_LIST_SUCCESS = "ARTIST_LIST_SUCCESS";
export const ARTIST_LIST_ERROR = "ARTIST_LIST_ERROR";

export const GET_ARTIST_DATA = "GET_ARTIST_DATA";
export const GET_ARTIST_DATA_SUCCESS = "GET_ARTIST_DATA_SUCCESS";
export const GET_ARTIST_DATA_ERROR = "GET_ARTIST_DATA_ERROR";

export const STATUS_ARTIST_COLLATERAL = "STATUS_ARTIST_COLLATERAL";
export const STATUS_ARTIST_COLLATERAL_SUCCESS =
  "STATUS_ARTIST_COLLATERAL_SUCCESS";

export const STATUS_ARTIST_PROJECT = "STATUS_ARTIST_PROJECT";
export const STATUS_ARTIST_PROJECT_SUCCESS = "STATUS_ARTIST_PROJECT_SUCCESS";

export const ARTIST_DETAILS = "ARTIST_DETAILS";
export const ARTIST_DETAILS_SUCCESS = "ARTIST_DETAILS_SUCCESS";

export const REJECTED_ARTIST = "REJECTED_ARTIST";
export const REJECTED_ARTIST_SUCCESS = "REJECTED_ARTIST_SUCCESS";
export const REJECTED_ARTIST_ERROR = "REJECTED_ARTIST_ERROR";

export const CLIENTS_DETAILS = "CLIENTS_DETAILS";
export const CLIENTS_DETAILS_SUCCESS = "CLIENTS_DETAILS_SUCCESS";

export const CLIENTS_PROFILE = "CLIENTS_PROFILE";
export const CLIENTS_PROFILE_SUCCESS = "CLIENTS_PROFILE_SUCCESS";

export const ARTIST_PROFILE = "ARTIST_PROFILE";
export const ARTIST_PROFILE_SUCCESS = "ARTIST_PROFILE_SUCCESS";

export const COLLATERAL_STATUS = "COLLATERAL_STATUS";
export const COLLATERAL_STATUS_SUCCESS = "COLLATERAL_STATUS_SUCCESS";

export const ARTIST_UPDATE_INFO = "ARTIST_UPDATE_INFO";
export const ARTIST_UPDATE_INFO_SUCCESS = "ARTIST_UPDATE_INFO_SUCCESS";
export const ARTIST_UPDATE_INFO_ERROR = "ARTIST_UPDATE_INFO_ERROR";

export const ARTIST_APPROVAL_LIST = "ARTIST_APPROVAL_LIST";
export const ARTIST_APPROVAL_LIST_SUCCESS = "ARTIST_APPROVAL_LIST_SUCCESS";
export const ARTIST_APPROVAL_LIST_ERROR = "ARTIST_APPROVAL_LIST_ERROR";


export const ARTIST_BUSY_LIST = "ARTIST_BUSY_LIST";
export const ARTIST_BUSY_LIST_SUCCESS = "ARTIST_BUSY_LIST_SUCCESS";
export const ARTIST_BUSY_LIST_ERROR = "ARTIST_BUSY_LIST_ERROR";
export const ARTIST_STARRED_LIST = "ARTIST_STARRED_LIST";
export const ARTIST_STARRED_LIST_SUCCESS = "ARTIST_STARRED_LIST_SUCCESS";
export const ARTIST_STARRED_LIST_ERROR = "ARTIST_STARRED_LIST_ERROR";


export const ARTIST_CLEAN_UPDATE = "ARTIST_CLEAN_UPDATE";
export const ARTIST_CLEAN_UPDATE_SUCCESS = "ARTIST_CLEAN_UPDATE_SUCCESS";

export const ARTIST_REVIEW_STATUS = "ARTIST_REVIEW_STATUS";
export const ARTIST_REVIEW_STATUS_SUCCESS = "ARTIST_REVIEW_STATUS_SUCCESS";

export const TRANSACTIONS_LIST = "TRANSACTIONS_LIST";
export const TRANSACTIONS_LIST_SUCCESS = "TRANSACTIONS_LIST_SUCCESS";
export const TRANSACTIONS_LIST_ERROR = "TRANSACTIONS_LIST_ERROR";

export const TRANSACTIONS_COUNT = "TRANSACTIONS_COUNT";
export const TRANSACTIONS_COUNT_SUCCESS = "TRANSACTIONS_COUNT_SUCCESS";
export const TRANSACTIONS_COUNT_ERROR = "TRANSACTIONS_COUNT_ERROR";

export const COLLATERAL_APPROVED_PROJECT = "COLLATERAL_APPROVED_PROJECT";
export const COLLATERAL_APPROVED_PROJECT_SUCCESS =
  "COLLATERAL_APPROVED_PROJECT_SUCCESS";

export const COLLATERAL_PENDING_PROJECT = "COLLATERAL_PENDING_PROJECT";
export const COLLATERAL_PENDING_PROJECT_SUCCESS =
  "COLLATERAL_PENDING_PROJECT_SUCCESS";

export const COLLATERAL_PENDING_CREATOR = "COLLATERAL_PENDING_CREATOR";
export const COLLATERAL_PENDING_CREATOR_SUCCESS =
  "COLLATERAL_PENDING_CREATOR_SUCCESS";

export const COLLATERAL_APPROVED_CREATOR = "COLLATERAL_APPROVED_CREATOR";
export const COLLATERAL_APPROVED_CREATOR_SUCCESS =
  "COLLATERAL_APPROVED_CREATOR_SUCCESS";

export const COLLATERAL_CHANGE_HISTORY = "COLLATERAL_CHANGE_HISTORY";
export const COLLATERAL_CHANGE_HISTORY_SUCCESS =
  "COLLATERAL_CHANGE_HISTORY_SUCCESS";

export const COLLATERAL_ACTIVE_TRANSACTION = "COLLATERAL_ACTIVE_TRANSACTION";
export const COLLATERAL_ACTIVE_TRANSACTION_SUCCESS =
  "COLLATERAL_ACTIVE_TRANSACTION_SUCCESS";

export const COLLATERAL_ACTIVE_NEGOCIATIONS = "COLLATERAL_ACTIVE_NEGOCIATIONS";
export const COLLATERAL_ACTIVE_NEGOCIATIONS_SUCCESS =
  "COLLATERAL_ACTIVE_NEGOCIATIONS_SUCCESS";

export const TRANSACTIONS_DATA = "TRANSACTIONS_DATA";
export const TRANSACTIONS_DATA_SUCCESS = "TRANSACTIONS_DATA_SUCCESS";

export const TRANSACTIONS_STATUS = "TRANSACTIONS_STATUS";
export const TRANSACTIONS_STATUS2 = "TRANSACTIONS_STATUS2";
export const TRANSACTIONS_STATUS_SUCCESS = "TRANSACTIONS_STATUS_SUCCESS";

export const ACTIVE_TRANSACTIONS = "ACTIVE_TRANSACTIONS";
export const ACTIVE_TRANSACTIONS_SUCCESS = "ACTIVE_TRANSACTIONS_SUCCESS";
export const ACTIVE_TRANSACTIONS_ERROR = "ACTIVE_TRANSACTIONS_ERROR";

export const PENDING_TRANSACTIONS = "PENDING_TRANSACTIONS";
export const PENDING_TRANSACTIONS_SUCCESS = "PENDING_TRANSACTIONS_SUCCESS";
export const PENDING_TRANSACTIONS_ERROR = "PENDING_TRANSACTIONS_ERROR";

export const INCOMPLETE_TRANSACTIONS = "INCOMPLETE_TRANSACTIONS";
export const INCOMPLETE_TRANSACTIONS_SUCCESS =
  "INCOMPLETE_TRANSACTIONS_SUCCESS";
export const INCOMPLETE_TRANSACTIONS_ERROR = "INCOMPLETE_TRANSACTIONS_ERROR";

export const INCOMPLETE_HIGH_TRANSACTIONS = "INCOMPLETE_HIGH_TRANSACTIONS";
export const INCOMPLETE_HIGH_TRANSACTIONS_SUCCESS =
  "INCOMPLETE_HIGH_TRANSACTIONS_SUCCESS";
export const INCOMPLETE_HIGH_TRANSACTIONS_ERROR =
  "INCOMPLETE_HIGH_TRANSACTIONS_ERROR";

export const TEMP_RESET_PWD = "TEMP_RESET_PWD";
export const TEMP_RESET_PWD_SUCCESS = "TEMP_RESET_PWD_SUCCESS";

export const BACKEND_LIST = "BACKEND_LIST";
export const BACKEND_LIST_SUCCESS = "BACKEND_LIST_SUCCESS";

export const BACKEND_DELETE_LIST = "BACKEND_DELETE_LIST";
export const BACKEND_DELETE_LIST_SUCCESS = "BACKEND_DELETE_LIST_SUCCESS";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export const APPROVED_ARTIST = "APPROVED_ARTIST";
export const APPROVED_ARTIST_SUCCESS = "APPROVED_ARTIST_SUCCESS";
export const APPROVED_ARTIST_ERROR = "APPROVED_ARTIST_ERROR";

export const CLOSE_PROJECT = "CLOSE_PROJECT";
export const CLOSE_PROJECT_SUCCESS = "CLOSE_PROJECT_SUCCESS";

export const DELETE_ARTIST_LIST = "DELETE_ARTIST_LIST";
export const DELETE_ARTIST_LIST_SUCCESS = "DELETE_ARTIST_LIST_SUCCESS";
export const DELETE_ARTIST_LIST_ERROR = "DELETE_ARTIST_LIST_ERROR";

export const CLIENT_DELETE_LIST = "CLIENT_DELETE_LIST";
export const CLIENT_DELETE_LIST_SUCCESS = "CLIENT_DELETE_LIST_SUCCESS";
export const DELETE_CLIENT_LIST_ERROR = "DELETE_CLIENT_LIST_ERROR";

export const LOCATION_USER = "LOCATION_USER";
export const LOCATION_USER_SUCCESS = "LOCATION_USER_SUCCESS";

export const COMPLETE_PROJECT = "COMPLETE_PROJECT";
export const COMPLETE_PROJECT_SUCCESS = "COMPLETE_PROJECT_SUCCESS";
export const COMPLETE_PROJECT_ERROR = "COMPLETE_PROJECT_ERROR";

export const CLOSE_TRANSACTION_LIST = "CLOSE_TRANSACTION_LIST";
export const CLOSE_TRANSACTION_LIST_SUCCESS = "CLOSE_TRANSACTION_LIST_SUCCESS";
export const CLOSE_PROJECT_ERROR = "CLOSE_PROJECT_ERROR";

export const DONT_LIKE_TRANSACTION_LIST = "DONT_LIKE_TRANSACTION_LIST";
export const DONT_LIKE_TRANSACTION_LIST_SUCCESS =
  "DONT_LIKE_TRANSACTION_LIST_SUCCESS";
export const DONT_LIKE_TRANSACTION_LIST_ERROR =
  "DONT_LIKE_TRANSACTION_LIST_ERROR";

export const CHOOSE_CREATOR = "CHOOSE_CREATOR";
export const CHOOSE_CREATOR_SUCCESS = "CHOOSE_CREATOR_SUCCESS";

export const PENDING_COLLATERAL = "PENDING_COLLATERAL";
export const PENDING_COLLATERAL_SUCCESS = "PENDING_COLLATERAL_SUCCESS";

export const PENDING_COLLATERAL_LIST = "PENDING_COLLATERAL_LIST";
export const PENDING_COLLATERAL_LIST_SUCCESS =
  "PENDING_COLLATERAL_LIST_SUCCESS";
export const PENDING_COLLATERAL_LIST_ERROR = "PENDING_COLLATERAL_LIST_ERROR";

export const PENDING_PROJECT_LIST = "PENDING_PROJECT_LIST";
export const PENDING_PROJECT_LIST_SUCCESS = "PENDING_PROJECT_LIST_SUCCESS";
export const PENDING_PROJECT_LIST_ERROR = "PENDING_PROJECT_LIST_ERROR";

export const GET_PENDING_PROJECT_DATA = "GET_PENDING_PROJECT_DATA";
export const GET_PENDING_PROJECT_DATA_SUCCESS =
  "GET_PENDING_PROJECT_DATA_SUCCESS";

export const HIDE_COLLATERAL = "HIDE_COLLATERAL";
export const HIDE_COLLATERAL_SUCCESS = "HIDE_COLLATERAL_SUCCESS";

export const PROJECT_REVIEW_LIST = "PROJECT_REVIEW_LIST";
export const PROJECT_REVIEW_LIST_SUCCESS = "PROJECT_REVIEW_LIST_SUCCESS";

export const GET_PROJECT_REVIEW_DATA = "GET_PROJECT_REVIEW_DATA";
export const GET_PROJECT_REVIEW_DATA_SUCCESS =
  "GET_PROJECT_REVIEW_DATA_SUCCESS";
export const GET_PROJECT_REVIEW_DATA_ERROR = "GET_PROJECT_REVIEW_DATA_ERROR";

export const TRANSCATION_APPROVAL_LIST = "TRANSCATION_APPROVAL_LIST";
export const TRANSCATION_APPROVAL_LIST_SUCCESS =
  "TRANSCATION_APPROVAL_LIST_SUCCESS";
export const TRANSCATION_APPROVAL_LIST_ERROR =
  "TRANSCATION_APPROVAL_LIST_ERROR";

export const CALL_BACK_REQUEST = "CALL_BACK_REQUEST";
export const CALL_BACK_REQUEST_SUCCESS = "CALL_BACK_REQUEST_SUCCESS";
export const CALL_BACK_REQUEST_ERROR = " CALL_BACK_REQUEST_ERROR";

export const CALL_BACK_DATA = "CALL_BACK_DATA";
export const CALL_BACK_DATA_SUCCESS = "CALL_BACK_DATA_SUCCESS";

export const CALL_BACK_SEARCH_CREATOR = "CALL_BACK_SEARCH_CREATOR";
export const CALL_BACK_SEARCH_CREATOR_SUCCESS =
  "CALL_BACK_SEARCH_CREATOR_SUCCESS";

export const RECOMMENDED_CREATOR = "RECOMMENDED_CREATOR";
export const RECOMMENDED_CREATOR_SUCCESS = "RECOMMENDED_CREATOR_SUCCESS";

export const PENDING_CREATOR_APPR_LIST = "PENDING_CREATOR_APPR_LIST";
export const PENDING_CREATOR_APPR_LIST_SUCCESS =
  "PENDING_CREATOR_APPR_LIST_SUCCESS";
export const PENDING_CREATOR_APPR_LIST_ERROR =
  "PENDING_CREATOR_APPR_LIST_ERROR";

export const GET_BACKEND_USER = "GET_BACKEND_USER";
export const GET_BACKEND_USER_SUCCESS = "GET_BACKEND_USER_SUCCESS";

export const GET_TAB_LIST = "GET_TAB_LIST";
export const GET_TAB_LIST_SUCCESS = "GET_TAB_LIST_SUCCESS";

export const ADD_TAB_LIST = "ADD_TAB_LIST";
export const ADD_TAB_LIST_SUCCESS = "ADD_TAB_LIST_SUCCESS";

export const DELETE_COLLATERAL_LIST = "DELETE_COLLATERAL_LIST";
export const DELETE_COLLATERAL_LIST_SUCCESS = "DELETE_COLLATERAL_LIST_SUCCESS";
export const DELETE_COLLATERAL_LIST_ERROR = "DELETE_COLLATERAL_LIST_ERROR";

export const INCOMPLETE_BRIEF_LIST = "INCOMPLETE_BRIEF_LIST";
export const INCOMPLETE_BRIEF_LIST_SUCCESS = "INCOMPLETE_BRIEF_LIST_SUCCESS";
export const INCOMPLETE_BRIEF_LIST_ERROR = "INCOMPLETE_BRIEF_LIST_ERROR";

export const CRETAOR_REJECTS_BRIEF_LIST = "CRETAOR_REJECTS_BRIEF_LIST";
export const CRETAOR_REJECTS_BRIEF_LIST_SUCCESS =
  "CRETAOR_REJECTS_BRIEF_LIST_SUCCESS";
export const CRETAOR_REJECTS_BRIEF_LIST_ERROR =
  "CRETAOR_REJECTS_BRIEF_LIST_ERROR";

export const REJECTS_CREATOR_DATA = "REJECTS_CREATOR_DATA";
export const REJECTS_CREATOR_DATA_SUCCESS = "REJECTS_CREATOR_DATA_SUCCESS";

export const CLEAR_BRIEF_LIST = "CLEAR_BRIEF_LIST";
export const CLEAR_BRIEF_LIST_SUCCESS = "CLEAR_BRIEF_LIST_SUCCESS";

export const CLIENT_REJECTS_CREATOR_LIST = "CLIENT_REJECTS_CREATOR_LIST";
export const CLIENT_REJECTS_CREATOR_LIST_SUCCESS = "CLIENT_REJECTS_CREATOR_LIST_SUCCESS";
export const CLIENT_REJECTS_CREATOR_LIST_ERROR = "CLIENT_REJECTS_CREATOR_LIST_ERROR";

export const APPROVED_CREATOR_LIST = "APPROVED_CREATOR_LIST";
export const APPROVED_CREATOR_LIST_SUCCESS = "APPROVED_CREATOR_LIST_SUCCESS"; 

export const RESET_APPROVED_CREATOR_LIST = "RESET_APPROVED_CREATOR_LIST";

export const RESET_TRANSACTION_LIST = "RESET_TRANSACTION_LIST";

export const APPROVED_STARRED_LIST = "APPROVED_STARRED_LIST";
export const APPROVED_STARRED_LIST_SUCCESS = "APPROVED_STARRED_LIST_SUCCESS";

export const ADD_BACKEND_USER = "ADD_BACKEND_USER";
export const ADD_BACKEND_USER_SUCCESS = "ADD_BACKEND_USER_SUCCESS";
export const ADD_BACKEND_UESR_ERROR = "ADD_BACKEND_UESR_ERROR";

export const CLOSED_BRIEF_PROJECT = "CLOSED_BRIEF_PROJECT";
export const CLOSED_BRIEF_PROJECT_SUCCESS = "CLOSED_BRIEF_PROJECT_SUCCESS";
export const CLOSED_BRIEF_PROJECT_ERROR = "CLOSED_BRIEF_PROJECT_ERROR";

export const REJECTED_BRIEF_LIST = "REJECTED_BRIEF_LIST";
export const REJECTED_BRIEF_LIST_SUCCESS = "REJECTED_BRIEF_LIST_SUCCESS";
export const REJECTED_BRIEF_LIST_ERROR = "REJECTED_BRIEF_LIST_ERROR";

export const ALL_COLLATERAL_LIST = "ALL_COLLATERAL_LIST";
export const ALL_COLLATERAL_LIST_SUCCESS = "ALL_COLLATERAL_LIST_SUCCESS";

export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";

export const ADD_POST_PROJECT = "ADD_POST_PROJECT";
export const ADD_POST_PROJECT_SUCCESS = "ADD_POST_PROJECT_SUCCESS";

export const GET_POST_PROJECT_DATA = "GET_POST_PROJECT_DATA";
export const GET_POST_PROJECT_DATA_SUCCESS = "GET_POST_PROJECT_DATA_SUCCESS";

export const ADD_PROPOSAL = "ADD_PROPOSAL";
export const ADD_PROPOSAL_SUCCESS = "ADD_PROPOSAL_SUCCESS";

export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";

export const UPDATE_PROPOSAL = "UPDATE_PROPOSAL";
export const UPDATE_PROPOSAL_SUCCESS = "UPDATE_PROPOSAL_SUCCESS";

export const ADD_PROPOSAL_CYCLE = "ADD_PROPOSAL_CYCLE";
export const ADD_PROPOSAL_CYCLE_SUCCESS = "ADD_PROPOSAL_CYCLE_SUCCESS";

export const EDIT_PROPOSAL_CYCLE = "EDIT_PROPOSAL_CYCLE";
export const EDIT_PROPOSAL_CYCLE_SUCCESS = "EDIT_PROPOSAL_CYCLE_SUCCESS";

export const DELETE_PROPOSAL_CYCLE = "DELETE_PROPOSAL_CYCLE";
export const DELETE_PROPOSAL_CYCLE_SUCCESS = "DELETE_PROPOSAL_CYCLE_SUCCESS";

export const DELETE_PROJECT_LIST = "DELETE_PROJECT_LIST";
export const DELETE_PROJECT_LIST_SUCCESS = "DELETE_PROJECT_LIST_SUCCESS";

export const DELETE_BRIEF_LIST = "DELETE_BRIEF_LIST";
export const DELETE_BRIEF_LIST_SUCCESS = "DELETE_BRIEF_LIST_SUCCESS";
export const DELETE_BRIEF_LIST_ERROR = "DELETE_BRIEF_LIST_ERROR";

export const DELETE_PROJECT_ALL_LIST = "DELETE_PROJECT_ALL_LIST";
export const DELETE_PROJECT_ALL_LIST_SUCCESS =
  "DELETE_PROJECT_ALL_LIST_SUCCESS";
export const DELETE_PROJECT_ALL_LIST_ERROR = "DELETE_PROJECT_ALL_LIST_ERROR";

export const CLOSED_BRIEF_ALL_LIST = "CLOSED_BRIEF_ALL_LIST";
export const CLOSED_BRIEF_ALL_LIST_SUCCESS = "CLOSED_BRIEF_ALL_LIST_SUCCESS";
export const CLOSED_BRIEF_ALL_LIST_ERROR = "CLOSED_BRIEF_ALL_LIST_ERROR";

export const CLOSE_BRIEF_REQUEST_LIST = "CLOSE_BRIEF_REQUEST_LIST";
export const CLOSE_BRIEF_REQUEST_LIST_SUCCESS =
  "CLOSE_BRIEF_REQUEST_LIST_SUCCESS";
export const CLOSE_BRIEF_REQUEST_ERR = "CLOSE_BRIEF_REQUEST_ERR";

export const CLOSE_PROJECT_REQUEST_LIST = "CLOSE_PROJECT_REQUEST_LIST";
export const CLOSE_PROJECT_REQUEST_LIST_SUCCESS =
  "CLOSE_PROJECT_REQUEST_LIST_SUCCESS";
export const CLOSE_PROJECT_REQUEST_ERR = "CLOSE_PROJECT_REQUEST_ERR";

export const CLIENT_BRIEF_LIST = "CLIENT_BRIEF_LIST";
export const CLIENT_BRIEF_LIST_SUCCESS = "CLIENT_BRIEF_LIST_SUCCESS";
export const CLIENT_BRIEF_LIST_ERR = "CLIENT_BRIEF_LIST_ERR";

export const CHANGE_EMBEDDED_LINK = "CHANGE_EMBEDDED_LINK";
export const CHANGE_EMBEDDED_LINK_SUCCESS = "CHANGE_EMBEDDED_LINK_SUCCESS";

export const MILESTONE_LIST = "MILESTONE_LIST";
export const MILESTONE_LIST_SUCCESS = "MILESTONE_LIST_SUCCESS";
export const MILESTONE_LIST_ERR = "MILESTONE_LIST_ERR";

export const TRANSITION_DASHBOARD = "TRANSITION_DASHBOARD";
export const TRANSITION_DASHBOARD_SUCCESS = "TRANSITION_DASHBOARD_SUCCESS";
export const TRANSITION_DASHBOARD_ERROR = "TRANSITION_DASHBOARD_ERROR";

export const COMPLETED_MILES = "COMPLETED_MILES";
export const COMPLETED_MILES_SUCCESS = "COMPLETED_MILES_SUCCESS";

export const MILESTONE_COMMENT = "MILESTONE_COMMENT";
export const MILESTONE_COMMENT_SUCCESS = "MILESTONE_COMMENT_SUCCESS";

export const MILESTONE_APPROVED = "MILESTONE_APPROVED";
export const MILESTONE_APPROVED_SUCCESS = "MILESTONE_APPROVED_SUCCESS";

export const ARTIST_BUSY_OR_AVAILABLE = "ARTIST_BUSY_OR_AVAILABLE";
export const ARTIST_BUSY_OR_AVAILABLE_SUCCESS =
  "ARTIST_BUSY_OR_AVAILABLE_SUCCESS";

export const REPORT_FOR_PROJECT_BRIEF = "REPORT_FOR_PROJECT_BRIEF";
export const REPORT_FOR_PROJECT_BRIEF_SUCCESS =
  "REPORT_FOR_PROJECT_BRIEF_SUCCESS";

export const REPORT_FOR_ENTERPRISE_PROJECT = "REPORT_FOR_ENTERPRISE_PROJECT";
export const REPORT_FOR_ENTERPRISE_PROJECT_SUCCESS =
  "REPORT_FOR_ENTERPRISE_PROJECT_SUCCESS";

export const REPORT_FOR_PROJECT = "REPORT_FOR_PROJECT";
export const REPORT_FOR_PROJECT_SUCCESS = "REPORT_FOR_PROJECT_SUCCESS";

export const REPORT_FOR_COLLATERAL = "REPORT_FOR_COLLATERAL";
export const REPORT_FOR_COLLATERAL_SUCCESS = "REPORT_FOR_COLLATERAL_SUCCESS";

export const REPORT_FOR_ALL_ARTIST = "REPORT_FOR_ALL_ARTIST";
export const REPORT_FOR_ALL_ARTIST_SUCCESS = "REPORT_FOR_ALL_ARTIST_SUCCESS";

export const ADD_MULTIPLE_CREATOR = "ADD_MULTIPLE_CREATOR";
export const ADD_MULTIPLE_CREATOR_SUCCESS = "ADD_MULTIPLE_CREATOR_SUCCESS";

export const DELETE_BACKEND_USER = "DELETE_BACKEND_USER";
export const DELETE_BACKEND_USER_SUCCESS = "DELETE_BACKEND_USER_SUCCESS";

export const DELETE_PENDING_CREATOR = "DELETE_PENDING_CREATOR";
export const DELETE_PENDING_CREATOR_SUCCESS = "DELETE_PENDING_CREATOR_SUCCESS";

export const FEATURED_COLLATERAL = "FEATURED_COLLATERAL";
export const FEATURED_COLLATERAL_SUCCESS = "FEATURED_COLLATERAL_SUCCESS";

export const OFFLINE_PAYMENT = "OFFLINE_PAYMENT";
export const OFFLINE_PAYMENT_SUCCESS = "OFFLINE_PAYMENT_SUCCESS";

export const UPDATE_TRANSCATIONS = "UPDATE_TRANSCATIONS";
export const UPDATE_TRANSCATIONS_SUCCESS = "UPDATE_TRANSCATIONS_SUCCESS";

export const DISABLED_ARTIST = "DISABLED_ARTIST";
export const DISABLED_ARTIST_SUCCESS = "DISABLED_ARTIST_SUCCESS";
export const DISABLED_ARTIST_ERROR = "DISABLED_ARTIST_ERROR";

export const DISABLE_ENABLE_ARTIST = "DISABLE_ENABLE_ARTIST";
export const DISABLE_ENABLE_ARTIST_SUCCESS = "DISABLE_ENABLE_ARTIST_SUCCESS";

export const REPORT_FOR_APPROVED_ARTIST = "REPORT_FOR_APPROVED_ARTIST";
export const REPORT_FOR_APPROVED_ARTIST_SUCCESS =
  "REPORT_FOR_APPROVED_ARTIST_SUCCESS";

export const REPORT_FOR_CLIENT = "REPORT_FOR_CLIENT";
export const REPORT_FOR_CLIENT_SUCCESS = "REPORT_FOR_CLIENT_SUCCESS";

export const APPROVED_PROPOSAL = "APPROVED_PROPOSAL";
export const APPROVED_PROPOSAL_SUCCESS = "APPROVED_PROPOSAL_SUCCESS";

export const APPROVED_PROPOSAL_JOB = "APPROVED_PROPOSAL_JOB";
export const APPROVED_PROPOSAL_JOB_SUCCESS = "APPROVED_PROPOSAL_JOB_SUCCESS";

export const REQUEST_CHANGE_PROPOSAL = "REQUEST_CHANGE_PROPOSAL";
export const REQUEST_CHANGE_PROPOSAL_SUCCESS =
  "REQUEST_CHANGE_PROPOSAL_SUCCESS";

export const EDIT_CLIENT = "EDIT_CLIENT";
export const EDIT_CLIENT_SUCCESS = "EDIT_CLIENT_SUCCESS";

export const MANAGER_DETAILS = "MANAGER_DETAILS";
export const MANAGER_DETAILS_SUCCESS = "MANAGER_DETAILS_SUCCESS";

export const INACTIVE_BRIEF = "INACTIVE_BRIEF";
export const INACTIVE_BRIEF_SUCCESS = "INACTIVE_BRIEF_SUCCESS";
export const INACTIVE_BRIEF_ERROR = "INACTIVE_BRIEF_ERROR"

export const INACTIVE_PROJECT = "INACTIVE_PROJECT";
export const INACTIVE_PROJECT_SUCCESS = "INACTIVE_PROJECT_SUCCESS";
export const INACTIVE_PROJECT_ERR = "INACTIVE_PROJECT_ERR"

export const INACT_BRIEF_FOR_AM  = "INACT_BRIEF_FOR_AM";
export const INACT_BRIEF_FOR_AM_SUCCESS = "INACT_BRIEF_FOR_AM_SUCCESS";
export const INACT_BRIEF_FOR_AM_ERR = "INACT_BRIEF_FOR_AM_ERR";

export const GET_CUSTOM_INDUSTRY_LIST = "GET_CUSTOM_INDUSTRY_LIST";
export const GET_CUSTOM_INDUSTRY_LIST_SUCCESS = "GET_CUSTOM_INDUSTRY_LIST_SUCCESS";
export const GET_CUSTOM_SOFTWARE_LIST = "GET_CUSTOM_SOFTWARE_LIST";
export const GET_CUSTOM_SOFTWARE_LIST_SUCCESS = "GET_CUSTOM_SOFTWARE_LIST_SUCCESS";
export const GET_CUSTOM_SPECIALIZATION_LIST = "GET_CUSTOM_SPECIALIZATION_LIST";
export const GET_CUSTOM_SPECIALIZATION_LIST_SUCCESS = "GET_CUSTOM_SPECIALIZATION_LIST_SUCCESS";
