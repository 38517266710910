import {
  BACKEND_LIST,
  BACKEND_LIST_SUCCESS,
  BACKEND_DELETE_LIST,
  BACKEND_DELETE_LIST_SUCCESS,
  GET_BACKEND_USER,
  GET_BACKEND_USER_SUCCESS,
  GET_TAB_LIST,
  GET_TAB_LIST_SUCCESS,
  ADD_TAB_LIST,
  ADD_TAB_LIST_SUCCESS,
  ADD_BACKEND_USER,
  ADD_BACKEND_USER_SUCCESS,
  ADD_BACKEND_UESR_ERROR,
  DELETE_BACKEND_USER,
  DELETE_BACKEND_USER_SUCCESS
} from "../type.action";

export const backendList = (values) => ({
  type: BACKEND_LIST,
  values,
});
export const backendListSucces = (values) => ({
  type: BACKEND_LIST_SUCCESS,
  values,
});

// -------------------------------------

export const backendDeleteList = (values) => ({
  type: BACKEND_DELETE_LIST,
  values,
});
export const backendDeleteListSucces = (values) => ({
  type: BACKEND_DELETE_LIST_SUCCESS,
  values,
});

// backend user data based on id

export const getBackendUser = (values) => ({
  type: GET_BACKEND_USER,
  values,
});
export const getBackendUserSuccess = (values) => ({
  type: GET_BACKEND_USER_SUCCESS,
  values,
});

// backend user tab list*************************

export const getTabList = (values) => ({
  type: GET_TAB_LIST,
  values,
});
export const getTAbListSuccess = (values) => ({
  type: GET_TAB_LIST_SUCCESS,
  values,
});

// add tab (backend user) ****************************
export const addtabList = (values) => ({
  type: ADD_TAB_LIST,
  values,
});
export const addTabListSuccess = (values) => ({
  type: ADD_TAB_LIST_SUCCESS,
  values,
});

// add user/admin (backend user)***********************
export const addBackendUser = (values) =>({
  type: ADD_BACKEND_USER,
  values
})
export const addBackendUserSuccess = (values) =>({
  type: ADD_BACKEND_USER_SUCCESS,
  values
})
export const addBackendUserError = (error) => ({
  type: ADD_BACKEND_UESR_ERROR,
  error
})

// delete backenduser (backend user)
export const deleteBackendUser = (values) => ({
  type: DELETE_BACKEND_USER,
  values
})
export const deleteBackendUserSuccess = (values) => ({
  type: DELETE_BACKEND_USER_SUCCESS,
  values
})
