import React, { Component } from "react";
import Loader from "../Common/Loader/Loader";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { closeProject,deleteProjectList } from "../../../store/action/transaction.action";
import CloseProjectReasonModal from "./CloseProjectReasonModal";
import { getPublicAPI,postAPI } from "../../../store/api-interface";
// import TableHead from "./TableHead";
import OfflinePayment from "./TableMethod/PaymentOffline";
import RejectDeleteModal from "./RejectDeleteModal";
import ProposalModal from "./ProposalModal";
import TableArtistTransHead from "./TableArtistTransHead";
import Select from "react-select";
import NotesModal from "../Common/ModalCommon/NotesModal";
import moment from "moment";

const seacrhPath = [
  { name: "/transactions/ActiveTransactions" },
  { name: "/transactions/closedTransactions" },
  { name: "/transactions/completeTransactions" },
  { name: "/transactions/DeletedProjects" },
];
class TableArtistTrans extends Component {
  state = {
    statusLoader: false,
    ReasonModal: false,
    modalIsOpen: false,
    transctionId: "",
    closedTranscationIs: "",
    id: "",
    type: "",
    statusCheck: "",
    rejDelModal: false,
    load: false,
    showProposalModal: false,
    MilesData: "",
    projectDataIs: {},
    keyCheck: true,
    notesModalOpen:false,
    notesModalContent:"",
    loc:"transaction",
    itemIndex:''
  };

  closeNotesModal = () =>{
    this.setState({
      notesModalOpen:false
    })
  }
  getTotalBudgetApproved=arr=>{
    const paidarr=arr.filter(m=>m.status==2)
    // console.log(paidarr)
    // userjson.avg_brief_response_time= AvgBriefResponseTime.reduce(function(acc, val) { return acc + val; }, 0)
    const result= paidarr.reduce(function(acc, val) { return acc + val.price; }, 0)
  // console.log(result)
   return result
  }
  handleCloseStatus = (id) =>
    getPublicAPI(`/postProject/check-close-review?_id=${id}`).then((res) => {
      const response = res.data.status;
      if (response === "0") {
        this.setState({ modalIsOpen: true });
      } else {
        let confirmIs = window.confirm(`Do you want to countinue ?`);
        if (confirmIs === true) {
          this.props.handleTransaction.handleCloseProject(id);
          return true;
        } else {
          return false;
        }
      }
    });

  handleCloseProjectConfirmation(id) {
    this.setState({
      transctionId: id,
    });
    this.handleCloseStatus(id);
  }

  handleDeleteConfirm(id) {
    let confirmIs = window.confirm(`Do you want to delete this project ?`);
    if (confirmIs === true) {
      this.props.handleTransaction.handleDeleteProject(id);
      return true;
    } else {
      return false;
    }
  }

  handleCloseModal = () => {
    this.setState({ modalIsOpen: false });
  };

  // only for table---------------------------

  getstatus = (status,scope) => {
    const statusArr = [
      { status: 0, textcolor: "text-warning", text: "Scope Of Work" },
      { status: 1, textcolor: "text-warning", text: (scope && scope === "high") ? "Schedule Call" : "Profile Selection" },
      { status: 2, textcolor: "text-warning", text: "Project Details" },
      { status: 3, textcolor: "text-info", text: "Pending" },
      { status: 4, textcolor: "text-secondary", text: "Call Back Request" },
      { status: 5, textcolor: "text-primary", text: "Negociation" },
      { status: 6, textcolor: "text-primary", text: "Active" },
      { status: 7, textcolor: "text-success", text: "Completed" },
      { status: 8, textcolor: "text-dark", text: "Close" },
      { status: 9, textcolor: "text-danger", text: "Rejected" },
      { status: 10, textcolor: "text-danger", text: "Deleted" },
      { status: -1, textcolor: "text-warning", text: "Incomplete Brief" },
      { status: 11, textcolor: "text-danger", text: "Profile Selection" },
      { status: 12, textcolor: "text-danger", text: "Project Launch" },
      { status: 13, textcolor: "text-danger", text: "Project Launch" },
    ];

    const currentData = statusArr?.filter((item) => item.status === status);
    return (
      <React.Fragment>
        {/* <span className={`mr-2 ${currentData?.[0]?.textcolor}`}> ●</span> */}
        {currentData?.[0]?.text}
      </React.Fragment>
    );
  };

  // getstatusIncHigh = (status) => {
  //   const statusArr = [
  //     { status: 0, textcolor: "text-primary", text: "Scope Offer" },
  //     { status: 1, textcolor: "text-primary", text: "Schedule Meeting" },
  //     { status: 11, textcolor: "text-primary", text: "Profile Selection" },
  //     { status: 12, textcolor: "text-primary", text: "Project Details" },
  //     { status: 13, textcolor: "text-primary", text: "Company Details" },
  //   ];

  //   const currentData = statusArr?.filter((item) => item.status === status);
  //   return (
  //     <React.Fragment>
  //       <span className={`mr-2 ${currentData?.[0]?.textcolor}`}> ●</span>
  //       {currentData?.[0]?.text}
  //     </React.Fragment>
  //   );
  // };

  getTranscationSatge = (stage) => {
    // const artistId = this.props.location.pathname;
    const validFor = [
      { name: "/transactions/incompleteTransactions" },
      { name: "/transactions/IncompleteHighScopeTrans" },
      { name: "/transactions/ClosedBrief" },
      { name: "/transactions/closedTransactions" },
      { name: "/transactions/DeletedBrief" },
      { name: "/transactions/DeletedProjects" },
    ];

    const textIs =
      stage === 0
        ? "Scope of work"
        : stage === 1
          ? "Profile selection"
          : stage === 2
            ? "Project Details"
            : stage === 3
              ? "Pending"
              : stage === 4
                ? "Call Back Request"
                : stage === 5
                  ? "Active Brief"
                  : stage === 6
                    ? "Active Project"
                    : stage === 7
                      ? "Completed"
                      : stage === -1
                        ? "Incomplete"
                        : "";

    const currentStage =
      validFor.find((item) => item.name === this.props.artistId) && textIs;

    return currentStage;
  };

  getProjectMaxMinBudgetAndDeadline = (obj) => {
    // console.log(obj.type === 'post_project_client', obj)
    const dataArr = [
      {
        value: `${obj.project_name?.substring(0, 30)}${
          obj.project_name.substring(30) ? "..." : ""
        }`,
        show: true,
        nameLink: true,
        url: obj.type === `post_project_client`
          ? `/transactions/transactionsDetails/:${obj._id}`
          : `/transactions/addProject/:${obj._id}`
      },
      { value: obj.min_budget, show: this.isShowMinBudget() },
      { value: obj.max_budget, show: this.isShowMinBudget() },
      { value: obj.dealline, show: this.isShowMinBudget() },
    ];

    return dataArr;
  };

  getProject = (project_name) => {
    const pNameIs =  `${project_name?.substring(0, 30)}${ project_name.substring(30) ? "..." : ""}`
    return pNameIs
  }

  getProjectUrl = (obj) => {
    const url = (obj?.status!==7 && (obj?.type === "post_project_admin" || obj?.were_admin_project)) ? 
                `/transactions/addProject/:${obj._id}`
              : `/transactions/transactionsDetails/:${obj._id}`
              
    return url
  }

  getStartDate = (obj) => {
    const artistId = this.props.artistId;
    const arrIs = [
      { path: "/transactions/ActiveTransactions" },
      { path: "/transactions/closedTransactions" },
      { path: "/transactions/completeTransactions" },
      { path: "/transactions/DeletedProjects" },
    ];

    return arrIs?.find((item) => item.path === artistId)
      ? obj?.project_approved_date?.split("T").slice(0)[0]
      : obj?.createdAt?.split("T").slice(0)[0];
  };

  getCloseBriefApprovedDate = (date) => {
    return <td className="orders-order">{date?.split("T").slice(0)[0]}</td>;
  };

  getDate = (obj) => {
    const arrdata = [
      { name: "/transactions" },
      { name: "/transactions/ActiveTransactions" },
      { name: "/transactions/closedTransactions" },
      { name: "/transactions/completeTransactions" },
      { name: "/transactions/DeletedProjects" },
    ];
    const artistId = this.props.artistId;
    const value =
      artistId === "/transactions/DeletedBrief"
        ? obj?.project_delete_date?.split("T").slice(0)[0]
        : artistId === "/transactions/PendingTransactions" ||
          artistId === "/transactions/RejectedTransactionList"
        ? obj?.project_brief_completion_date?.split("T").slice(0)[0]
        : arrdata.find((el) => el.name === artistId)
        ? obj?.project_brief_approved_date?.split("T").slice(0)[0]
        : artistId === "/transactions/ClosedBrief"
        ? obj?.project_close_date?.split("T").slice(0)[0]
        : obj?.updatedAt?.split("T").slice(0)[0];
    return value;
  };

  getDateTime = (end, start) => {
    // console.log(end,start,'nthuth')
    let result = ""
    var diff = ""
    // console.log("1 = ",end,start)
    if (!end || !start) {
      result = "NA"
    } else {
      let createAt = new Date(end)
      let approveAt = new Date(start)
      // console.log("1 = ",createAt,approveAt)
      diff = (createAt.getTime() - approveAt.getTime()) / 1000;
      diff /= (60 * 60);
      if (diff < 0) {
        result = "NA"
      } else{
        result = Math.round(diff * 60)+ " min"
      }
      // else if (diff >= 1) {
      //   let rest = Math.abs(Math.round(diff))
      //   if (rest <= 72) {
      //     result = Math.round(rest) + (rest == 1 ? " hour" : " hours")
      //   } else if (rest <= 365 ) {
      //     result = Math.round(rest / 24) + " days"
      //   } else {
      //     result = Math.round((rest / 24) / 365) + " years"
      //   }
      // }
    }
    return result;
  }

  isShowMinBudget = () => {
    const artistId = this.props.artistId;
    return !seacrhPath.find((el) => el.name === artistId);
  };

  handleThreedates = (arr) => {
    let dates = arr ? arr : []
    return <div style={{display:"flex",flexDirection:"column"}}>
        {dates.map((obj,i) => <span>{`${i+1}. ${obj?.createdAt?.split("T").slice(0)[0]} ${obj?.createdAt?.split("T")[1].slice(0,5)}`}</span>)}
      </div>
  }

  handleRejDelete = (reason) => {
    const { id, statusCheck, type } = this.state;
    this.setState({load:true})
    if (type === "reject") {
      const statusDataR = { _id: id, status: 9 };
      this.props.$transactionStatus(statusDataR);
      const dataR = { post_project_id: this.state.id, status: statusCheck,type:"project",reason };
      this.handleReasonForDisable(dataR)
    } else if(type === "delete"){
      const statusDataD = { _id : id}
      this.props.$deleteProjectList(statusDataD)
      const dataD = { post_project_id: this.state.id, status: statusCheck,type:"project",reason };
      this.handleReasonForDisable(dataD)
    }
  }

  handleReasonForDisable = (data) => {
    postAPI("/users/add-reason-for-disable", data)
      .then((res) => {
        this.setState({
          load: false,
          rejDelModal:false
        }, () => {
          this.props.handleTransaction.handleApiCall()
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  handleModalClose = () => {
    this.setState({rejDelModal:false})
  }



  handleViewProposal = (obj,index) => {
    this.setState({ showProposalModal: true, MilesData: obj,projectDataIs:obj,itemIndex:index });
  };

  handleCloseProposalModal = () => {
    this.setState({ showProposalModal: false });
  };

  handleWordSize = (value) => {
    return value ? value?.length > 30 ? value?.slice(0,30) + "..." : value : ""
  }

  currentMilestone = (data) =>{
    for ( let x = 0; x<data?.length;x++){
      if(data[x]?.status==1){
        return x+1
      }
    }
    return 0;
  }

  navigateToAccountManager = (obj) =>{
    let confirmIs = window.confirm('You must first assign the Manager');
    if(confirmIs)
    this.props.history.push(`/transactions/transactionUserDetails/:${obj?._id}`)
  }
  render() {
    // const artistId = this.props.location.pathname;

    const {
      LoadingIs,
      transactionData,
      transactionIs,
      minBudget,
      // deadline,
      maxBudget,
      handleTransaction,
      // closedTranscationIs,
      artistId
    } = this.props;

    const { onCheckboxClick } = this.props.handleTransaction;

    const tableFunc = {
      handleCloseModal: this.handleCloseModal,
      handleCloseProject: this.props.handleTransaction.handleDeleteProject,
      handleRejDelete: this.handleRejDelete,
      handleModalClose: this.handleModalClose
    };

    const func = {
      handleCloseProposalModal: this.handleCloseProposalModal,
    };

    const searchArr = [
      {
        type: "text",
        name: "transactionIs",
        value: transactionIs,
        placeholder: "Search Project",
        show: true,
      },
      {
        type: "number",
        name: "minBudget",
        value: minBudget,
        placeholder: "Search Min Budget",
        show: this.isShowMinBudget(),
      },
      {
        type: "number",
        name: "maxBudget",
        value: maxBudget,
        placeholder: "Search Max Budget",
        show: this.isShowMinBudget(),
      },
      // {
      //   type: "number",
      //   name: "deadline",
      //   value: deadline,
      //   placeholder: "Search deadline",
      //   show: this.isShowMinBudget(),
      // },
    ];
    // console.log(transactionData)
    return (
      <React.Fragment>
        {/*------------------Search ---------------------*/}
        <div className="row col-md-12 mb-2">
          <div className="col">
            <form className="row align-items-center">
              <div className="col-auto pr-0">
                <span className="fe fe-search text-muted"></span>
              </div>
              {searchArr.map((obj, index) => {
                
                  return (obj.show &&
                    <div className="col" key={index}>
                      <input
                        type={obj.type}
                        className="form-control form-control-flush search"
                        autoComplete="off"
                        placeholder={obj.placeholder}
                        name={obj.name}
                        value={obj.value}
                        onChange={handleTransaction.handleOnChange}
                      />
                    </div>
                  );
                
              })}
               {(artistId === "/transactions/incompleteTransactions" || artistId === "/transactions" || artistId === "/transactions/PendingTransactions")  &&
                <div className="col">
                  <Select
                    options={[{ label: "All", value: "" }, { label: "Low", value: "low" }, { label: "High", value: "high" }]}
                    onChange={handleTransaction.handleOnSelect}
                    placeholder="Scope"
                    value={this.props?.scopeValue}
                  />
                </div>
              }
            </form>
          </div>
        </div>
        {/*------------------Search ---------------------*/}

        {LoadingIs ? (
          <Loader />
        ) : (
          <div className="table-responsive mb-5">
            <table className="table table-sm table-nowrap card-table" style={{minHeight:"350px"}}>
              {/*--------------------table head-------------------*/}

              <TableArtistTransHead {...this.state} {...this.props} />

              {/*--------------------table head-------------------*/}

              <tbody className="list">
                {/* {console.log(transactionData)} */}
                {transactionData.length > 0 ? (
                  transactionData.map((obj, index) => (
                    <tr key={index} >
                      { (artistId === "/transactions" || 
                        artistId === "/transactions/ActiveTransactions" ||
                        artistId === "/transactions/incompleteTransactions" ||
                        artistId === "/transactions/IncompleteHighScopeTrans" ||
                        artistId === "/transactions/PendingTransactions") &&
                        <td>
                          <input className="" type="checkbox" value="" checked={obj.selected} onChange={() => onCheckboxClick(obj._id)} />  
                        </td>
                      }
                      <td className="text-center">
                        <div className={`dropdown`}>
                          <a
                            href="#!"
                            className="dropdown-ellipses dropdown-toggle text-secondary"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fe fe-more-vertical"></i>
                          </a>
                            <div
                              className={`ma-auto dropdown-menu dropdown-menu-right text-center`}
                            >
                              {/***-----------------------Accept/Reject----------------- */}

                              {artistId ===
                                "/transactions/PendingTransactions" && (
                                <span>
                                  <Link
                                    to="#!"
                                    className="dropdown-item mb-2"
                                    datatoggle="modal"
                                    datatarget="#exampleModalCenter"
                                    onClick={() =>{obj?.manager_name ?
                                      handleTransaction.handleStatusChanged(
                                        obj._id,
                                        "accept")
                                        : 
                                         this.navigateToAccountManager(obj)
                                    }}
                                  >
                                    Accept
                                  </Link>
                                </span>
                              )}

                              {artistId ===
                                "/transactions/PendingTransactions" && (
                                <span>
                                  <Link
                                    to="#!"
                                    className="dropdown-item mb-2"
                                    datatoggle="modal"
                                    datatarget="#exampleModalCenter"
                                    // onClick={() =>
                                    //   handleTransaction.handleStatusChanged(
                                    //     obj._id,
                                    //     "reject"
                                    //   )
                                    // }
                                    onClick={() => this.setState({id:obj?._id,statusCheck:obj?.status,rejDelModal:true,type:"reject"}) }
                                  >
                                    Reject
                                  </Link>
                                </span>
                              )}

                              {/***-----------------------Accept/Reject----------------- */}

                              {/***-----------------------Delete----------------- */}
                              {(artistId !==
                              "/transactions/PendingTransactions" &&
                                artistId !==
                                  "/transactions/DeletedBrief" &&
                                artistId !==
                                  "/transactions/DeletedProjects"
                                ) && (
                                <span>
                                  <Link
                                    to="#!"
                                    className="dropdown-item mb-2"
                                    datatoggle="modal"
                                    datatarget="#exampleModalCenter"
                                    // onClick={() =>
                                    //   this.handleDeleteConfirm(obj._id)
                                    // }
                                    onClick={() => this.setState({id:obj?._id,statusCheck:obj?.status,rejDelModal:true,type:"delete"}) }
                                  >
                                    Delete
                                  </Link>
                                </span>
                              )}
                            
                              {/***-----------------------Delete----------------- */}

                              {/******************Close Project ******************** */}
                              {(artistId === "/transactions" ||
                                artistId ===
                                  "/transactions/ActiveTransactions" ||
                                  artistId === "/transactions/InactiveBriefs" ||
                                  artistId === "/transactions/InactiveProjects") && (
                                <span>
                                  <Link
                                    to="#!"
                                    className="dropdown-item mb-2"
                                    datatoggle="modal"
                                    datatarget="#exampleModalCenter"
                                    onClick={() =>
                                      this.handleCloseProjectConfirmation(
                                        obj._id
                                      )
                                    }
                                  >
                                    Close
                                  </Link>
                                </span>
                              )}
                              {/******************Close Project ******************** */}
                              <span>
                                <Link
                                  to={
                                    obj.type === `post_project_client`
                                      ? `/transactions/transactionsDetails/:${obj._id}`
                                      : `/transactions/addProject/:${obj._id}`
                                  }
                                  className="dropdown-item mb-2"
                                  datatoggle="modal"
                                  datatarget="#exampleModalCenter"
                                >
                                  View/Edit
                                </Link>
                            </span>
                            </div>
                        </div>
                      </td>

                      {/* for incomplete *************************************** */}
                      {artistId === "/transactions/incompleteTransactions" &&
                      <>
                      <td>
                        <Link to={this.getProjectUrl(obj)}>
                          {this.handleWordSize(obj?.project_name)}
                        </Link>
                      </td>
                      <td className="orders-order">
                          {this.getstatus (obj.status,obj.budget_scope)}
                      </td>
                      <td className="orders-date">
                        {obj?.budget_scope ? obj.budget_scope : ""}
                      </td>
                      <td className="orders-order">
                        <Link
                          to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                          style={{ color: "#12263f" }}
                        >
                          {this.handleWordSize(obj?.collateral?.name)}
                        </Link>
                      </td>
                      <td>{obj?.sub_type}</td>
                      <td className="orders-order">
                        <Link
                          to={`/clients/:edit/:${
                            obj.user ? obj.user._id : obj.client._id
                          }`}
                          style={{ color: "#12263f" }}
                        >
                          {obj?.user
                              ? this.handleWordSize((obj?.user?.first_name+" "+obj?.user?.last_name))
                              : this.handleWordSize((obj?.client?.first_name+" "+obj?.client?.last_name))
                             }
                        </Link>
                      </td>
                      <td>{obj.min_budget}</td>
                      <td>{obj.max_budget}</td>
                      <td>{this.handleWordSize(obj?.manager_name)}</td>
                      <td>{obj?.project_approved_date ? obj.project_approved_date?.split("T").slice(0)[0]: obj?.createdAt?.split("T").slice(0)[0]}</td>
                      <td>{obj?.updatedAt?.split("T")[0]}</td>
                      </>}
                      
                      {/* for approval pending **************************************** */}
                      
                      {
                        artistId === "/transactions/PendingTransactions" &&
                        <>
                          <td className="orders-order">
                            <Link to={this.getProjectUrl(obj)}>
                              {this.handleWordSize(obj?.project_name)}
                            </Link>                            
                          </td>
                          <td className="orders-date">
                            {obj?.budget_scope ? obj.budget_scope : ""}
                          </td>
                          <td>
                            <Link
                              to={`/clients/:edit/:${
                                obj.user ? obj.user._id : obj.client._id
                              }`}
                              style={{ color: "#12263f" }}
                            >
                             {obj?.user
                              ? this.handleWordSize((obj?.user?.first_name+" "+obj?.user?.last_name))
                              : this.handleWordSize((obj?.client?.first_name+" "+obj?.client?.last_name))
                             }
                            </Link>
                          </td>
                          <td>{this.handleWordSize(obj?.company_name)}</td>
                          <td className="orders-order">
                            <Link
                              to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                              style={{ color: "#12263f" }}
                            >
                               {this.handleWordSize(obj?.collateral?.name)}
                            </Link>
                          </td>
                          <td>{obj?.sub_type}</td>
                          <td>{obj.min_budget}</td>
                          <td>{obj.max_budget}</td>
                          <td>{this.handleWordSize(obj?.manager_name)}</td>
                          <td>{obj.total_creators}</td>
                          <td>{obj?.project_approved_date ? moment(obj.project_approved_date).format("DD/MM/YYYY"): moment(obj?.createdAt).format("DD/MM/YYYY")}</td>
                         <td>{obj?.updatedAt?.split("T")[0] && moment(obj?.updatedAt).format('DD/MM/YYYY')}</td>                                               
                        </>
                      }

                      {/* Active brief ****************************************************** */}
                      {/* {console.log('artist',artistId)} */}
                       
                      {
                        artistId === "/transactions" &&
                        <>
                          <td className="orders-order"
                            style={{  backgroundColor: obj?.manager_email ? "" : "#d1d1d1" }}>
                            <Link to={this.getProjectUrl(obj)}>
                              {this.handleWordSize(obj?.project_name)}
                            </Link>  
                          </td>
                          <td>
                            <Link
                              to={`/clients/:edit/:${
                                obj.user ? obj.user._id : obj.client._id
                              }`}
                              style={{ color: "#12263f" }}
                            >
                             {obj?.user
                              ? this.handleWordSize((obj?.user?.first_name+" "+obj?.user?.last_name))
                              : this.handleWordSize((obj?.client?.first_name+" "+obj?.client?.last_name))
                             } 
                            </Link>
                          </td>
                          <td>{this.handleWordSize(obj?.company_name)}</td>
                          <td className="orders-order">
                            <Link
                              to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                              style={{ color: "#12263f" }}
                            >
                               {this.handleWordSize(obj?.collateral?.name)}
                            </Link>
                          </td>
                          <td>{obj?.sub_type}</td>
                          <td>{this.handleWordSize(obj?.manager_name)}</td>
                          <td><OfflinePayment obj ={obj} type={'active_brief'}/></td>
                          <td>{obj.total_receivedProposal}</td>
                          <td>{obj.total_creators}</td>
                          {/* <td>{ obj?.first_proposal?parseInt((new Date( obj?.first_proposal?.createdAt)-new Date(obj?.project_brief_approved_date).getTime())/1000/60 ):"" }</td> */}

                          {/* <td>{ parseInt((new Date(obj?.project_brief_approved_date).getTime()-new Date( obj?.first_proposal?.createdAt).getTime()/1000)/60) }</td> */}
                          <td> {this.getDateTime(obj?.first_proposal?.createdAt,obj?.project_brief_completion_date)}</td>
                          <td> {this.getDateTime(obj?.first_proposal?.createdAt,obj?.project_brief_approved_date)}</td>

                          <td>{obj?.proposal3hour24 ? obj.proposal3hour24V1 : "NA"}</td>
                          <td>{obj?.proposal3hour24 ? obj.proposal3hour24 : "NA"}</td>
                          <td className="orders-date">
                            {obj?.budget_scope ? obj.budget_scope : ""}
                          </td>
                          <td>{obj.min_budget}</td>
                          <td>{obj.max_budget}</td>
                          <td>{obj?.createdAt?.split("T")[0] && moment(obj?.createdAt).format("DD/MM/YYYY")}</td>
                          <td>{obj?.project_brief_approved_date?.split("T")[0] && moment(obj?.project_brief_approved_date).format("DD/MM/YYYY")}</td>
                          <td>{obj?.updatedAt?.split("T")[0] && moment(obj?.updatedAt).format("DD/MM/YYYY")}</td>
                        </>
                      }

                      {/* Active project ***************************************** */}
                      {
                        artistId === "/transactions/ActiveTransactions" &&
                        <>
                          <td> <span style={{ cursor: "pointer",color:"#2c7be5" }} onClick={() => this.handleViewProposal(obj,index)}>view Proposal</span></td>
                          <td className="orders-order" style={{  backgroundColor: obj?.manager_email ? "" : "#d1d1d1" }}>
                            <Link to={this.getProjectUrl(obj)}>
                              {this.handleWordSize(obj?.project_name)}
                            </Link>  
                          </td>
                          <td className="orders-order">
                            <Link
                              to={`/clients/:edit/:${
                                obj.user ? obj.user._id : obj.client._id
                              }`}
                              style={{ color: "#12263f" }}
                            >
                             {obj?.user
                              ? this.handleWordSize((obj?.user?.first_name+" "+obj?.user?.last_name))
                              : this.handleWordSize((obj?.client?.first_name+" "+obj?.client?.last_name))
                             }
                            </Link>
                          </td>
                          <td>{this.handleWordSize(obj?.company_name)}</td>
                          <td>
                              {obj?.proposal?.creator ? this.handleWordSize((obj?.proposal?.creator?.first_name+" "+obj?.proposal?.creator?.last_name)) : ""}
                          </td>
                          <td className="orders-order">
                            <Link
                              to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                              style={{ color: "#12263f" }}
                            >
                               {this.handleWordSize(obj?.collateral?.name)}
                            </Link>
                          </td>
                          <td>{obj?.sub_type}</td>
                          <td>{obj?.newProposalList?.[0] ? obj?.newProposalList?.[0]?.proposal_type === 'actual' ? 'on actual' : obj?.newProposalList?.[0]?.proposal_type : '-'}</td>
                          <td>{this.handleWordSize(obj?.manager_name)}</td>
                          <td>{obj?.proposal?.proposal?.sub_total_cost?.toFixed(2)}</td>
                          <td>{obj?.proposal?.proposal?.total_earning?.toFixed(2)}</td>
                          <td>
                            <span
                            className={`badge ${
                             ( obj.type === "post_project_admin" || obj?.were_admin_project)
                             ?`badge-soft-primary`
                             :`badge-soft-info`
                            }`}
                          >
                            {obj.type === "post_project_admin" || obj?.were_admin_project
                              ? "Admin"
                              : "Client"}
                          </span>
                          </td>
                          <td>{obj?.newProposalList?.[0]?.payment_terms.length>0 ? "Milestone " +  this.currentMilestone(obj?.newProposalList?.[0]?.payment_terms):""}</td>
                          <td>{obj?.proposal?.total_milsetone}</td>
                          <td>

                              {obj.proposal && 
                                   obj?.proposal?.current_milestone?.due_date && moment(obj?.proposal?.current_milestone?.due_date).format("DD/MM/YYYY")
                                    }
                          </td>
                          <td><OfflinePayment obj ={obj} type={'active_project'}/></td>

                          <td>{obj.total_receivedProposal}</td>
                          <td>{obj.total_creators}</td>
                          <td> {this.getDateTime(obj?.first_shared_proposal_time,obj?.project_brief_completion_date)}</td>
                          <td> {this.getDateTime(obj?.first_shared_proposal_time,obj?.project_brief_approved_date)}</td>

                          <td>{obj?.proposal3hour24 ? obj.proposal3hour24V1 : "NA"}</td>
                          <td>{obj?.proposal3hour24 ? obj.proposal3hour24 : "NA"}</td>
                          <td>{obj?.createdAt?.split("T")[0] && moment(obj?.createdAt).format("DD/MM/YYYY")}</td>
                          <td>{obj?.project_approved_date?.split("T")[0] && moment(obj?.project_approved_date).format("DD/MM/YYYY")}</td>
                          <td>{obj?.updatedAt?.split("T")[0] && moment(obj?.updatedAt).format("DD/MM/YYYY")}</td>
                          <td>
                            {/* {obj?.proposal1?obj?.proposal1.milestone[obj?.proposal1.milestone.length-1]?.due_date?.split("T")[0]:""} */}
                            {(obj.proposal1 && obj?.proposal1?.milestone?.[obj?.proposal1?.milestone?.length-1]?.due_date) && moment(obj?.proposal1?.milestone?.[obj?.proposal1?.milestone?.length-1]?.due_date).format("DD/MM/YYYY")}
                          </td>
                        </>
                      }

                      {/* Inactive brief ***************************************** */}
                      {
                        artistId === "/transactions/InactiveBriefs" &&
                        <>
                          <td className="orders-order">
                            <Link to={this.getProjectUrl(obj)}>
                              {this.handleWordSize(obj?.project_name)}
                            </Link>  
                          </td>
                          <td>{obj?.updatedAt?.split("T")[0]}</td>
                          <td className="orders-order">
                            <Link
                              to={`/clients/:edit/:${
                                obj.user ? obj.user._id : obj.client._id
                              }`}
                              style={{ color: "#12263f" }}
                            >
                             {obj?.user
                              ? this.handleWordSize((obj?.user?.first_name+" "+obj?.user?.last_name))
                              : this.handleWordSize((obj?.client?.first_name+" "+obj?.client?.last_name))
                             }
                            </Link>
                          </td>
                          <td>{this.handleWordSize(obj?.company_name)}</td>
                          <td>{this.handleWordSize(obj?.manager_name)}</td>
                          <td className="orders-order">
                            <Link
                              to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                              style={{ color: "#12263f" }}
                            >
                               {this.handleWordSize(obj?.collateral?.name)}
                            </Link>
                          </td>
                          <td>{obj?.sub_type}</td>
                          <td><OfflinePayment obj ={obj} type={'inactive_brief'}/></td>
                          <td>{obj.total_receivedProposal}</td>
                          <td>{obj.total_creators}</td>
                          <td> {this.getDateTime(obj?.first_shared_proposal_time,obj?.project_brief_completion_date)}</td>
                          <td> {this.getDateTime(obj?.first_shared_proposal_time,obj?.project_brief_approved_date)}</td>

                          <td>{obj?.proposal3hour24 ? obj.proposal3hour24V1 : "NA"}</td>
                          <td>{obj?.proposal3hour24 ? obj.proposal3hour24 : "NA"}</td>
                          <td className="orders-date">
                            {obj?.budget_scope ? obj.budget_scope : ""}
                          </td>
                          <td>{obj.min_budget}</td>
                          <td>{obj.max_budget}</td>                        
                          <td>{obj?.createdAt?.split("T")[0]}</td>
                          <td>{obj?.project_brief_approved_date?.split("T")[0]}</td>                     
                        </>
                      }

                      {/* Inactive project ***************************************** */}
                      {
                        artistId === "/transactions/InactiveProjects" &&
                        <>
                          <td><span style={{ cursor: "pointer",color:"#2c7be5" }} onClick={() => this.handleViewProposal(obj)}>view Proposal</span></td>
                          <td className="orders-order">
                            <Link to={this.getProjectUrl(obj)}>
                              {this.handleWordSize(obj?.project_name)}
                            </Link>  
                          </td>
                          <td>{obj?.updatedAt?.split("T")[0]}</td>
                          <td className="orders-order">
                            <Link
                              to={`/clients/:edit/:${
                                obj.user ? obj.user._id : obj.client._id
                              }`}
                              style={{ color: "#12263f" }}
                            >
                             {obj?.user
                              ? this.handleWordSize((obj?.user?.first_name+" "+obj?.user?.last_name))
                              : this.handleWordSize((obj?.client?.first_name+" "+obj?.client?.last_name))
                             }
                            </Link>
                          </td>
                          <td>{this.handleWordSize(obj?.company_name)}</td>
                          <td>
                              {obj?.proposal?.creator ? this.handleWordSize((obj?.proposal?.creator?.first_name+" "+obj?.proposal?.creator?.last_name)) : ""}
                          </td>
                          <td>{this.handleWordSize(obj?.manager_name)}</td>
                          <td className="orders-order">
                            <Link
                              to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                              style={{ color: "#12263f" }}
                            >
                               {this.handleWordSize(obj?.collateral?.name)}
                            </Link>
                          </td>   
                          <td>{obj?.sub_type}</td>
                          <td>{obj?.newProposalList?.[0] ? obj?.newProposalList?.[0]?.proposal_type === 'actual' ? 'on actual' : obj?.newProposalList?.[0]?.proposal_type : '-'}</td>
                          <td>{obj?.proposal?.proposal?.sub_total_cost.toFixed(2)}</td>
                          <td>{obj?.proposal?.proposal.total_earning.toFixed(2)}</td>
                          <td>
                          <span
                            className={`badge ${
                             ( obj.type === "post_project_admin" || obj?.were_admin_project)
                             ?`badge-soft-primary`
                             :`badge-soft-info`
                            }`}
                          >
                            {obj.type === "post_project_admin" || obj?.were_admin_project
                              ? "Admin"
                              : "Client"}
                          </span>
                          </td>
                          <td>Milestone {obj?.proposal?.current_milestone?.serial_no}</td>
                          <td>{obj?.proposal?.total_milsetone}</td>
                          <td>
                              {obj.proposal
                                  ? obj.proposal.current_milestone?.due_date
                                    ?.split("T")
                                    .slice(0)[0]
                              : ""}
                          </td>
                          <td><OfflinePayment obj ={obj} type={'inactive_project'}/></td>

                          <td>{obj.total_receivedProposal}</td>
                          <td>{obj.total_creators}</td>
                          <td> {this.getDateTime(obj?.first_proposal?.createdAt,obj?.project_brief_completion_date)}</td>
                          <td> {this.getDateTime(obj?.first_proposal?.createdAt,obj?.project_brief_approved_date)}</td>


                          <td>{obj?.proposal3hour24 ? obj.proposal3hour24V1 : "NA"}</td>
                          <td>{obj?.proposal3hour24 ? obj.proposal3hour24 : "NA"}</td>
                          <td>{obj?.createdAt?.split("T")[0]}</td>
                          <td>{obj?.project_approved_date?.split("T")[0]}</td>                          
                          <td>{obj?.proposal1?obj?.proposal1?.milestone?.[obj?.proposal1?.milestone?.length-1]?.due_date?.split("T")[0]:""}</td>
                        </>
                      }

                      {/* completed project **************************************** */}
                      
                      {
                        artistId === "/transactions/completeTransactions" &&
                        <>
                          <td><span style={{ cursor: "pointer",color:"#2c7be5" }} onClick={() => this.handleViewProposal(obj)}>view Proposal</span></td>
                          <td className="orders-order" style={{ backgroundColor: obj?.hasCreatorReviewed == "No" ? "#d1d1d1" : "" }}>
                            <Link to={this.getProjectUrl(obj)}>
                              {this.handleWordSize(obj?.project_name)}
                            </Link> 
                          </td>
                          <td>
                            <Link
                              to={`/clients/:edit/:${
                                obj.user ? obj.user._id : obj.client._id
                              }`}
                              style={{ color: "#12263f" }}
                            >
                             {obj?.user
                              ? this.handleWordSize((obj?.user?.first_name+" "+obj?.user?.last_name))
                              : this.handleWordSize((obj?.client?.first_name+" "+obj?.client?.last_name))
                             }
                            </Link>
                          </td>
                          <td>{this.handleWordSize(obj?.company_name)}</td>
                          <td>
                              {obj?.proposal?.creator ? this.handleWordSize((obj?.proposal?.creator?.first_name+" "+obj?.proposal?.creator?.last_name)) : ""}
                          </td>                          
                          <td>{obj?.client_rating?.rating}</td>
                          <td>{obj?.creator_rating?.rating}</td>
                          <td>{obj?.hasmanagerReviewed}</td>
                          <td>{this.handleWordSize(obj?.manager_name)}</td>
                          <td className="orders-order">
                            <Link
                              to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                              style={{ color: "#12263f" }}
                            >
                               {this.handleWordSize(obj?.collateral?.name)}
                            </Link>
                          </td>
                          <td>{obj?.sub_type}</td>
                          <td>{obj?.newProposalList?.[0] ? obj?.newProposalList?.[0]?.proposal_type === 'actual' ? 'on actual' : obj?.newProposalList?.[0]?.proposal_type : '-'}</td>
                          <td>{obj?.proposal?.proposal?.sub_total_cost.toFixed(2)}</td>
                          <td>{obj?.proposal?.proposal.total_earning.toFixed(2)}</td>
                          <td>
                          <span
                            className={`badge ${
                             ( obj.type === "post_project_admin" || obj?.were_admin_project || obj?.project_from == "admin_complete_project")
                             ?`badge-soft-primary`
                             :`badge-soft-info`
                            }`}
                          >
                            {(obj.type === "post_project_admin" || obj?.were_admin_project || obj?.project_from == "admin_complete_project")
                              ? "Admin"
                              : "Client"}
                          </span>
                          </td>
                          <td><OfflinePayment obj ={obj} disabled={true}/></td>
                          <td>{obj.total_receivedProposal}</td>
                          <td>{obj.total_creators}</td>                        
                          <td> {this.getDateTime(obj?.first_shared_proposal_time,obj?.project_brief_completion_date)}</td>
                          <td> {this.getDateTime(obj?.first_shared_proposal_time,obj?.project_brief_approved_date)}</td>
                          <td>{obj?.proposal3hour24 ? obj.proposal3hour24V1 : "NA"}</td>
                          <td>{obj?.proposal3hour24 ? obj.proposal3hour24 : "NA"}</td>
                          <td>{obj?.createdAt && moment(obj?.createdAt).format("DD/MM/YYYY")}</td>
                          <td>{obj?.project_approved_date && moment(obj?.project_approved_date).format("DD/MM/YYYY")}</td>
                          <td>{obj?.project_completed_date && moment(obj?.project_completed_date).format("DD/MM/YYYY")}</td>
                        </>
                      }
                       
                      {/* Rejected **************************************************** */}

                      {
                        artistId === "/transactions/RejectedTransactionList" &&
                        <>
                          <td className="orders-order">
                            <Link to={this.getProjectUrl(obj)}>
                              {this.handleWordSize(obj?.project_name)}
                            </Link>                             
                          </td>
                          <td>
                            <Link
                                to={`/clients/:edit/:${
                                  obj.user ? obj.user._id : obj.client._id
                                }`}
                                style={{ color: "#12263f" }}
                              >
                                {obj?.user
                                  ? this.handleWordSize((obj?.user?.first_name+" "+obj?.user?.last_name))
                                  : this.handleWordSize((obj?.client?.first_name+" "+obj?.client?.last_name))
                                }
                            </Link>
                          </td>
                          <td>{this.handleWordSize(obj?.company_name)}</td>
                          <td>{obj?.action_by === "admin" ? `${obj?.action_by} ${obj?.admin_email ? `(${this.handleWordSize(obj?.admin_email)})` : ""}` : obj?.action_by}</td>
                          <td>
                            {/* <span style={{ cursor: "pointer" }} data-toggle="tooltip" title={obj?.reject_reason?.reason}>
                              {this.handleWordSize((obj?.reject_reason?.reason))}
                            </span> */}
                           
                            <span style={obj?.reject_reason?.reason?.length>30?{cursor:"pointer"}:{cursor:'default'}} onClick={obj?.reject_reason?.reason?.length>30 ? ()=>this.setState({notesModalOpen: true,notesModalContent:obj?.reject_reason?.reason }):()=>this.setState({notesModalOpen: false })}>
                            {this.handleWordSize((obj?.reject_reason?.reason))}{obj?.reject_reason?.reason?.length>30 && <span className="fe fe-alert-circle"></span>}
                          </span>
                          </td>
                          <td className="orders-order">
                            <Link
                              to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                              style={{ color: "#12263f" }}
                            >
                               {this.handleWordSize(obj?.collateral?.name)}
                            </Link>
                          </td>
                          <td>{obj?.sub_type}</td>
                          <td>{obj?.budget_scope ? obj.budget_scope : ""}</td>                          
                          <td>{obj.min_budget}</td>
                          <td>{obj.max_budget}</td>
                          <td>{this.handleWordSize(obj?.manager_name)}</td>
                          <td>{obj?.total_creators}</td>
                          <td>{obj?.createdAt && moment(obj?.createdAt).format("DD/MM/YYYY")}</td>
                          <td>
                            {
                              obj?.project_brief_rejection_date && moment(obj?.project_brief_rejection_date).format("DD/MM/YYYY")
                            }
                          </td>
                        </>
                      }

                      {/* close brief ************************************************ */}

                      {
                        artistId === "/transactions/ClosedBrief" &&
                        <>
                          <td>
                            <Link to={this.getProjectUrl(obj)}>
                              {this.handleWordSize(obj?.project_name)}
                            </Link>  
                          </td>
                          <td className="orders-order">
                            <Link
                              to={`/clients/:edit/:${
                                obj.user ? obj.user._id : obj.client._id
                              }`}
                              style={{ color: "#12263f" }}
                            >
                             {obj?.user
                              ? this.handleWordSize((obj?.user?.first_name+" "+obj?.user?.last_name))
                              : this.handleWordSize((obj?.client?.first_name+" "+obj?.client?.last_name))
                             }
                            </Link>
                          </td>                         
                          <td>{this.handleWordSize(obj?.company_name)}</td>
                          <td>{obj?.action_by === "admin" ? `${obj?.action_by} ${obj?.admin_email ? `(${this.handleWordSize(obj?.admin_email)})` : ""}` : obj?.action_by}</td>
                          <td>
                            <span style={{cursor:"pointer"}} data-toggle="tooltip" title={obj?.reason?.description}>
                              {this.handleWordSize((obj?.reason?.description))}
                            </span>
                          </td>
                          <td className="orders-order">
                            <Link
                              to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                              style={{ color: "#12263f" }}
                            >
                               {this.handleWordSize(obj?.collateral?.name)}
                            </Link>
                          </td>
                          <td>{obj?.sub_type}</td>
                          <td>{this.handleWordSize(obj?.manager_name)}</td>                         
                          <td><OfflinePayment obj ={obj}/></td>
                          <td>{obj?.total_receivedProposal}</td>
                          <td>{obj?.total_creators}</td>
                          <td> {this.getDateTime(obj?.first_proposal?.createdAt,obj?.project_brief_completion_date)}</td>
                          <td> {this.getDateTime(obj?.first_proposal?.createdAt,obj?.project_brief_approved_date)}</td>

                          <td>{obj?.proposal3hour24 ? obj.proposal3hour24V1 : "NA"}</td>
                          <td>{obj?.proposal3hour24 ? obj.proposal3hour24 : "NA"}</td>
                          <td>{obj?.budget_scope ? obj.budget_scope : ""}</td>   
                          <td>{obj.min_budget}</td>
                          <td>{obj.max_budget}</td>
                          <td>{obj?.createdAt && moment(obj?.createdAt).format("DD/MM/YYYY")}</td>
                          <td>{obj?.project_brief_approved_date && moment(obj?.project_brief_approved_date).format("DD/MM/YYYY")}</td>
                          <td>{obj?.project_close_date && moment(obj?.project_close_date).format("DD/MM/YYYY")}</td>
                        </>
                      }

                      {/* close project *************************************************** */}

                      {
                        artistId === "/transactions/closedTransactions" &&
                        <>
                          <td><span style={{ cursor: "pointer",color:"#2c7be5" }} onClick={() => this.handleViewProposal(obj)}>view Proposal</span></td>
                          <td>
                            <Link to={this.getProjectUrl(obj)}>
                              {this.handleWordSize(obj?.project_name)}
                            </Link> 
                          </td>
                          <td className="orders-order">
                            <Link
                              to={`/clients/:edit/:${
                                obj.user ? obj.user._id : obj.client._id
                              }`}
                              style={{ color: "#12263f" }}
                            >
                             {obj?.user
                              ? this.handleWordSize((obj?.user?.first_name+" "+obj?.user?.last_name))
                              : this.handleWordSize((obj?.client?.first_name+" "+obj?.client?.last_name))
                             }
                            </Link>
                          </td>
                          <td>{this.handleWordSize(obj?.company_name)}</td>
                          <td>
                              {obj?.proposal?.creator ? this.handleWordSize((obj?.proposal?.creator?.first_name+" "+obj?.proposal?.creator?.last_name)) : ""}
                          </td>
                          <td>{obj?.action_by === "admin" ? `${obj?.action_by} ${obj?.admin_email ? `(${this.handleWordSize(obj?.admin_email)})` : ""}` : obj?.action_by}</td>
                          <td>
                            <span style={{cursor:"pointer"}} data-toggle="tooltip" title={obj?.reason?.description}>
                              {this.handleWordSize((obj?.reason?.description))}
                            </span>
                          </td>
                          <td>{obj?.proposal?.proposal?.sub_total_cost.toFixed(2)}</td>
                          <td>{obj?.proposal1?.milestone ? this.getTotalBudgetApproved(obj?.proposal1?.milestone) : 'none'}</td>
                          <td>{obj?.proposal?.proposal.total_earning}</td>                          
                          <td>{obj?.proposal?.paid_amount.toFixed(2)}</td>                          
                          <td className="orders-order">
                            <Link
                              to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                              style={{ color: "#12263f" }}
                            >
                               {this.handleWordSize(obj?.collateral?.name)}
                            </Link>
                          </td>
                          <td>{obj?.sub_type}</td>
                          <td>{obj?.newProposalList?.[0] ? obj?.newProposalList?.[0]?.proposal_type === 'actual' ? 'on actual' : obj?.newProposalList?.[0]?.proposal_type : '-'}</td>
                          <td>{this.handleWordSize(obj?.manager_name)}</td>
                          <td>
                          <span
                            className={`badge ${
                             ( obj.type === "post_project_admin" || obj?.were_admin_project)
                             ?`badge-soft-primary`
                             :`badge-soft-info`
                            }`}
                          >
                            {obj.type === "post_project_admin" || obj?.were_admin_project
                              ? "Admin"
                              : "Client"}
                          </span>
                          </td>
                          <td>Milestone {obj?.proposal?.current_milestone?.serial_no}</td>
                          <td>{obj?.proposal?.total_milsetone}</td>
                          <td><OfflinePayment obj ={obj}/></td>
                          <td>{obj.total_receivedProposal}</td>
                          <td>{obj.total_creators}</td>
                          <td> {this.getDateTime(obj?.first_proposal?.createdAt,obj?.project_brief_completion_date)}</td>
                          <td> {this.getDateTime(obj?.first_proposal?.createdAt,obj?.project_brief_approved_date)}</td>
                          <td>{obj?.proposal3hour24 ? obj.proposal3hour24V1 : "NA"}</td>
                          <td>{obj?.proposal3hour24 ? obj.proposal3hour24 : "NA"}</td>
                          <td>{obj?.createdAt?.split("T")[0]}</td>
                          <td>{obj?.project_approved_date?.split("T")[0]}</td>
                          <td>{obj?.project_close_date?.split("T")[0]}</td>
                        </>
                      }

                      {/* deletd brief ************************************************** */}
                      {
                        artistId === "/transactions/DeletedBrief" &&
                        <>
                          <td>
                            <Link to={this.getProjectUrl(obj)}>
                              {this.handleWordSize(obj?.project_name)}
                            </Link> 
                          </td>
                          <td className="orders-order">
                            <Link
                              to={`/clients/:edit/:${
                                obj.user ? obj.user._id : obj.client._id
                              }`}
                              style={{ color: "#12263f" }}
                            >
                             {obj?.user
                              ? this.handleWordSize((obj?.user?.first_name+" "+obj?.user?.last_name))
                              : this.handleWordSize((obj?.client?.first_name+" "+obj?.client?.last_name))
                             }
                            </Link>
                          </td>
                          <td>{this.handleWordSize(obj?.company_name)}</td>
                          <td>{obj?.action_by === "admin" ? `${obj?.action_by} ${obj?.admin_email ? `(${this.handleWordSize(obj?.admin_email)})` : ""}` : obj?.action_by}</td>
                          <td>
                            <span style={{cursor:"pointer"}} data-toggle="tooltip" title={obj?.delete_reason?.reason}>
                              {this.handleWordSize((obj?.delete_reason?.reason))}
                            </span>
                          </td>
                          <td className="orders-order">
                            <Link
                              to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                              style={{ color: "#12263f" }}
                            >
                               {this.handleWordSize(obj?.collateral?.name)}
                            </Link>
                          </td>
                          <td>{obj?.sub_type}</td>
                          <td>{this.handleWordSize(obj?.manager_name)}</td>
                          <td><OfflinePayment obj ={obj}/></td>
                          <td>{obj.total_receivedProposal}</td>
                          <td>{obj.total_creators}</td>
                          <td> {this.getDateTime(obj?.first_proposal?.createdAt,obj?.project_brief_completion_date)}</td>
                          <td> {this.getDateTime(obj?.first_proposal?.createdAt,obj?.project_brief_approved_date)}</td>
                          <td>{obj?.proposal3hour24 ? obj.proposal3hour24V1 : "NA"}</td>
                          <td>{obj?.proposal3hour24 ? obj.proposal3hour24 : "NA"}</td>
                          <td>{obj?.budget_scope ? obj.budget_scope : ""}</td>                                                   
                          <td>{obj.min_budget}</td>
                          <td>{obj.max_budget}</td>                                                                                                                                                                                                                                      
                          <td>{obj?.createdAt?.split("T")[0]}</td>
                          <td>{obj?.project_brief_approved_date?.split("T")[0]}</td>
                          <td>{obj?.project_delete_date?.split("T")[0]}</td>
                        </>
                      }

                      {/* deletd projects ********************************************** */}

                      {
                        artistId === "/transactions/DeletedProjects" &&
                        <>
                          <td><span style={{ cursor: "pointer",color:"#2c7be5" }} onClick={() => this.handleViewProposal(obj)}>view Proposal</span></td>
                          <td>
                            <Link to={this.getProjectUrl(obj)}>
                              {this.handleWordSize(obj?.project_name)}
                            </Link> 
                          </td>
                          <td className="orders-order">
                            <Link
                              to={`/clients/:edit/:${
                                obj.user ? obj.user._id : obj.client._id
                              }`}
                              style={{ color: "#12263f" }}
                            >
                             {obj?.user
                              ? this.handleWordSize((obj?.user?.first_name+" "+obj?.user?.last_name))
                              : this.handleWordSize((obj?.client?.first_name+" "+obj?.client?.last_name))
                             }
                            </Link>
                          </td>
                          <td>{this.handleWordSize(obj?.company_name)}</td>
                          <td>
                              {obj?.proposal?.creator ? this.handleWordSize((obj?.proposal?.creator?.first_name+" "+obj?.proposal?.creator?.last_name)) : ""}
                          </td>
                          <td>{obj?.action_by === "admin" ? `${obj?.action_by} ${obj?.admin_email ? `(${this.handleWordSize(obj?.admin_email)})` : ""}` : obj?.action_by}</td>
                          <td>
                            <span style={{cursor:"pointer"}} data-toggle="tooltip" title={obj?.delete_reason?.reason}>
                              {this.handleWordSize((obj?.delete_reason?.reason))}
                            </span>
                          </td>
                          <td>{obj?.proposal?.proposal?.sub_total_cost.toFixed(2)}</td>                                                   
                          <td>{obj?.proposal1?.milestone?this.getTotalBudgetApproved(obj?.proposal1?.milestone):''}</td>
                          <td>{obj?.proposal?.proposal?.total_earning.toFixed(2)}</td>
                          <td>{obj?.proposal?.paid_amount?.toFixed(2)}</td>
                          <td className="orders-order">
                            <Link
                              to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                              style={{ color: "#12263f" }}
                            >
                               {this.handleWordSize(obj?.collateral?.name)}
                            </Link>
                          </td>
                          <td>{obj?.sub_type}</td>
                          <td>{obj?.newProposalList?.[0] ? obj?.newProposalList?.[0]?.proposal_type === 'actual' ? 'on actual' : obj?.newProposalList?.[0]?.proposal_type : '-'}</td>
                          <td>{this.handleWordSize(obj?.manager_name)}</td>
                          <td>
                          <span
                            className={`badge ${
                             ( obj.type === "post_project_admin" || obj?.were_admin_project)
                             ?`badge-soft-primary`
                             :`badge-soft-info`
                            }`}
                          >
                            {obj.type === "post_project_admin" || obj?.were_admin_project
                              ? "Admin"
                              : "Client"}
                          </span>
                          </td>
                          <td>{obj?.proposal?.complete_milestone===obj?.proposal?.total_milsetone?"Milestone Completed":  obj?.proposal?.complete_milestone?"Milestone "+(obj?.proposal?.complete_milestone+1):''}</td>
                          <td>{obj?.proposal?.total_milsetone}</td>
                          <td><OfflinePayment obj ={obj}/></td>
                          <td>{obj.total_receivedProposal}</td>
                          <td>{obj.total_creators}</td>
                          <td> {this.getDateTime(obj?.first_proposal?.createdAt,obj?.project_brief_completion_date)}</td>
                          <td> {this.getDateTime(obj?.first_proposal?.createdAt,obj?.project_brief_approved_date)}</td>
                          <td>{obj?.proposal3hour24 ? obj.proposal3hour24V1 : "NA"}</td>
                          <td>{obj?.proposal3hour24 ? obj.proposal3hour24 : "NA"}</td>                                                                                                                                                                                                                                                                                     
                          <td>{obj?.createdAt?.split("T")[0]}</td>
                          <td>{obj?.project_brief_approved_date?.split("T")[0]}</td>
                          <td>{obj?.project_delete_date?.split("T")[0]}</td>
                        </>
                      }
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No data Found ....</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        {/*-------------------------Reason Modal ---------------------------*/}
        <ProposalModal {...this.state} {...this.props} handleFunc={func} />
        <CloseProjectReasonModal
          {...this.state}
          {...this.props}
          funcIs={tableFunc}
        />
        {this.state.rejDelModal &&
          <RejectDeleteModal
            {...this.state}
            {...this.props}
            funcIs={tableFunc}
          />
        }
        
        {this.state.notesModalContent &&
          <NotesModal 
          notesModalOpen={this.state.notesModalOpen} 
          closeNotesModal={this.closeNotesModal} 
          notesModalContent={this.state.notesModalContent}/>
          }
        

      </React.Fragment>
    );
  }
}
const mapState = ({ closeProject,deleteProjectList }) => {
  return {
    _closeProject: closeProject,
    _deleteProjectList:deleteProjectList
  };
};
const mapDispatch = (dispatchEvent) => ({
  $closeProject: (values) => dispatchEvent(closeProject(values)),
  $deleteProjectList: (values) => dispatchEvent(deleteProjectList(values))
});
export default connect(mapState, mapDispatch)(TableArtistTrans);
