import React, { Component } from "react";
import { connect } from "react-redux";
import { getArtistData } from "../../../store/action/clientList.action";
import ErrorApi from "../Common/Error/ErrorApi";

class ArtistWorkExperience extends Component {
  state = {
    artistWorkExp: [],
    workExpFormVisibility: false,
    expIndex: "",
    companyName: "",
    designation: "",
    startMonth: "",
    startYear: "",
    endMonth: "",
    endYear: "",
    location: "",
    industry: "",
    webUrl: "",
    companyId: "",
    showCompanyList: false,
    freelanceType : "Full-Time-Freelancer",
    jobType: '',
    userIndustry : []
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    const { companyId } = this.state;
    this.setState({
      [name]: value,
    });
    if (name === "companyName" && companyId) {
      this.handleCompanyName();
    }
  };

  handleCompanyName = () => {
    const { showCompanyList } = this.state;
    this.setState({
      showCompanyList: !showCompanyList,
    });
  };

  handleCompanySelection = (company) => {
    this.setState({
      showCompanyList: false,
      companyName: company,
    });
  };

  handleShowArtistWorkExp = (obj, index) => {
    const compnayisIs = obj.company_id;
    const{ workExpFormVisibility } = this.state;
    this.setState({
      workExpFormVisibility: !workExpFormVisibility,
      expIndex: index,
      designation: obj.designation,
      startMonth: obj.start_month,
      startYear: obj.start_year,
      endMonth: obj.end_month,
      endYear: obj.end_year,
      location: obj.location,
      industry: obj.industry,
      webUrl: obj.website_url,
      companyId: obj.company_id,
      jobType: obj.job_type,
      showCompanyList: false,
      companyName: "",
    });
    if (!compnayisIs) {
      this.setState({
        companyName: obj.company_name,
      });
    }
  };

  handleGetArtistWorkExp = (id) => {
    this.props.$getArtistData(id);
  };

  componentDidMount() {
    const artistParamsId = this.props.match.params.id.split(":").pop();
    this.handleGetArtistWorkExp(artistParamsId);
  }

  componentWillReceiveProps(nextProps) {
    const creatorData = nextProps._getArtistData.getCreatorData;
    const userDataIs = creatorData.userData
    if (creatorData.status === "1") {
      this.setState({
        artistWorkExp: creatorData.userWork,
        freelanceType : userDataIs.current_work_status,
        userIndustry :  creatorData?.userIndustry
      });
    }
  }

  renderWorkForm() {
    const {
      companyName,
      designation,
      startMonth,
      startYear,
      endMonth,
      endYear,
      location,
      industry,
      webUrl,
      showCompanyList,
      jobType
    } = this.state;

    const workExpArr = [
      { name: "companyName", value: companyName, label: "Company name" },
      { name: "designation", value: designation, label: "Designation" },
      { name: "startMonth", value: startMonth, label: "Start Month" },
      { name: "startYear", value: startYear, label: "Start Year" },
      { name: "endMonth", value: endMonth, label: "End Month" },
      { name: "endYear", value: endYear, label: "End Year" },
      { name: "location", value: location, label: "Location" },
      { name: "industry", value: industry, label: "Industry" },
      { name: "jobType", value: jobType, label: "Type" },
      { name: "webUrl", value: webUrl, label: "Web Url" },
    ];

    return (
      <div
        className="questin-Listing"
        style={{ borderTop: "none", borderRadius: "0 0 5px 5px" }}
      >
        <div className="row mt-3">
          {workExpArr.map((obj, index) => (
            <div className="col-12 col-md-6" key={index}>
              <div className="form-group text-secondary">
                <label>{obj.label}</label>
                <input
                  type="text"
                  className="form-control text-secondary"
                  name={obj.name}
                  value={obj.value}
                  onChange={this.handleOnChange}
                  disabled="disabled"
                />
                {obj.name === "companyName" && showCompanyList && (
                  <ul
                    id="dlist12"
                    className="list-group list-group-sm pcf-list "
                    style={{ boxShadow: "0px 0px 3px 0px silver" }}
                  >
                    <li
                      className="list-group-item"
                      onClick={() => this.handleCompanySelection("Comapny 1")}
                    >
                      Company 1
                    </li>
                  </ul>
                )}
              </div>
            </div>
          ))}
          {/* <div className="col-12">
            <button className="btn btn-primary disabled">Update</button>
          </div> */}
        </div>
      </div>
    );
  }

  render() {
    const { artistWorkExp, workExpFormVisibility, expIndex, freelanceType,userIndustry} = this.state;
    return this.props._getArtistData.getArtistError ? (
      <ErrorApi {...this.props} />
    ) : (
      <React.Fragment>
        {artistWorkExp.length ? (
          <section className="container">
            <div className="row">
            <div className="col-12 col-md-6">
            <div className="form-group text-secondary">
              <input
                type="radio"
                name="freelanceType"
                onChange={this.handleOnChange}
                disabled="disabled"
                checked = { freelanceType === "Full-Time Freelancer" }
              />{" "}
              Full time freelancer
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group text-secondary">
              <input
                type="radio"
                name="freelanceType"
                onChange={this.handleOnChange}
                disabled="disabled"
                checked = {freelanceType === "Part-Time Freelancer"}
              />{" "}
              Part time freelancer
            </div>
          </div> 
          </div>
            {artistWorkExp.map((obj, index) => (
              <div className="mx-auto" key={index}>
                <div className="questin-Listing p-4">
                  <div className="row">
                    <div className="col-md-3 d-flex align-items-center">
                      <h5 className="mb-0 text-secondary">
                        {obj.company_name}
                      </h5>
                    </div>
                    <div className="col-md-7">
                      <ul
                        className="mb-0 show_option text-secondary artist-col"
                        style={{ listStyle: "circle" }}
                      >
                        <li className="mr-3"> {obj.course}</li>
                        <li className="mr-3">{obj.designation}</li>
                        <li className="mr-3">{obj.industry}</li>
                      </ul>
                    </div>
                    <div className="col-md-2">
                      <p
                        className="mb-0"
                        onClick={() => this.handleShowArtistWorkExp(obj, index)}
                      >
                        <i
                          className={`${
                            workExpFormVisibility && expIndex === index
                              ? "fe fe-chevron-up"
                              : "fe fe-chevron-down"
                          }`}
                        ></i>
                      </p>
                    </div>
                  </div>
                </div>
                {workExpFormVisibility &&
                  expIndex === index &&
                  this.renderWorkForm()}
                <hr className="my-5" />
              </div>
            ))}

              <div class="card bg-light border ms-md-4 col-md-6">
                <div class="card-body">
                  <p class="mb-2">
                    Industries
                  </p>
                  <ul className="small text-muted ps-4 mb-0">
                    {userIndustry?.length ?
                    userIndustry.map((obj , index) => {
                      return<li key={index}>
                      {obj.name}
                    </li>
                    }):
                    <p className="small text-muted ps-4 mb-0">
                      Industry Not found.
                    </p>
                    }                
                  </ul>

                </div>
              </div>

          </section>
        ) : (
          <h3>Artist Work Experience data is blank....</h3>
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({ getArtistData }) => {
  return {
    _getArtistData: getArtistData,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $getArtistData: (values) => dispatchEvent(getArtistData(values)),
});
export default connect(mapState, mapDispatch)(ArtistWorkExperience);
