import React, { Component } from "react";
import { connect } from "react-redux";
import {
  callBackData,
  callBackSearchCreator,
  recommendedCreator,
} from "../../../../store/action/transaction.action";
import CallBackQuestionForm from "./CallBackQuestionForm";
import { userProfileBaseUrl } from "../../../../Utils/BaseUrl";

class ViewCallBackRequest extends Component {
  state = {
    firstName: "",
    lastName: "",
    collateralName: "",
    clientEamil: "",
    RequestDate: "",
    PhoneNumber: "",
    typeQuestion: [],
    indexIs: "",
    questionDataIs: "",
    showForm: false,
    question: "",
    questiontype: "",
    subtext: "",
    styleQusetion: [],
    typeStatus: "",
    styleForm: false,
    creatorEmail: "",
    collateralId: "",
    options: [],
    LoadingIs: false,
    selectedCreatorIs: [],
    selectCreatorActive: false,
    projectId: "",
    createCreatorIs: false,
    Reason: [],
    reasonType: "",
    reasonExplain: "",
    minBudget : '',
    maxBudget :'',
    budgetType : '',
    budgetUnit : ''
  };

  handleGetReviewConfirmation(index) {
    let confirmIs = window.confirm(`Do you want to Delete this Creator ?`);
    if (confirmIs === true) {
      const { selectedCreatorIs } = this.state;
      if (index !== -1) {
        var removeCreator = selectedCreatorIs.filter((_, i) => i !== index);
        this.setState({
          selectedCreatorIs: removeCreator,
        });
      }
      return true;
    } else {
      return false;
    }
  }

  handleDeleteCreator = (index) => {
    this.handleGetReviewConfirmation(index);
  };

  handleCreatorSubmit = () => {
    const { selectedCreatorIs, projectId } = this.state;
    const dataCreator = { _id: projectId, user_ids: selectedCreatorIs };
    this.setState({ createCreatorIs: true });
    this.props.$recommendedCreator(dataCreator);
  };

  handleSelectedCreator = (obj) => {
    const { selectedCreatorIs, selectCreatorActive } = this.state;
    let duplicateCreator = selectedCreatorIs.some(
      (item) => item.email === obj.email
    );
    if (!duplicateCreator) {
      let creatorArr = selectedCreatorIs.concat(obj);
      this.setState({
        selectedCreatorIs: creatorArr.slice(0, 3),
        selectCreatorActive: !selectCreatorActive,
      });
    } else {
      alert("This Creator is already selected...");
      this.setState({
        selectCreatorActive: false,
      });
    }
  };

  handleSearch = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.handleCreatorSearch(value);
      }
    );
  };

  handleSearchOnClick = () => {
    const { selectCreatorActive } = this.state;
    this.setState({
      selectCreatorActive: !selectCreatorActive,
    });
  };

  handleCreatorSearch = (email) => {
    const { collateralId } = this.state;
    const ProjectId = this.props.match.params.id.split(":")[1]
    let data = { email: email, id: collateralId, projectId: ProjectId };
    this.setState({
      selectCreatorActive: true,
    });
    this.props.$callBackSearchCreator(data);
  };

  handleTypeEditQues = (obj, index, type) => {
    const { showForm, styleForm } = this.state;
    this.setState({
      indexIs: index,
      questionDataIs: obj,
      typeStatus: type,
    });
    if (type === "type") {
      this.setState({
        showForm: !showForm,
        styleForm: false,
      });
    } else if (type === "style") {
      this.setState({
        styleForm: !styleForm,
        showForm: false,
      });
    }
  };

  handledropdown = (e) => {
    if (e.target.name !== "creatorEmail" || e.target.name === "undefined") {
      this.setState({
        selectCreatorActive: false,
      });
    }
  };

  handleCreatorData = (id) => {
    this.setState({
      LoadingIs: true,
    });
    this.props.$callBackData(id);
  };

  componentDidMount() {
    const ParamsId = this.props.match.params.id.split(":").pop();
    this.handleCreatorData(ParamsId);
  }

  componentWillReceiveProps(nextProps) {
    const { LoadingIs, createCreatorIs } = this.state;
    const dataIs = nextProps._callBackData.callBackDataIs;
    const creatorList = nextProps._callBackSearchCreator.searchCreatorIs;
    const recommendData = nextProps._recommendedCreator.recommendIs;
    if (dataIs && LoadingIs) {
      this.setState({
        firstName: dataIs.clientUser ? dataIs.clientUser.first_name : "",
        lastName: dataIs.clientUser ? dataIs.clientUser.last_name : "",
        collateralName: dataIs.collateral ? dataIs.collateral.name : "",
        clientEamil: dataIs.clientUser ? dataIs.clientUser.email : "",
        PhoneNumber: dataIs.clientUser ? dataIs.clientUser.phone_no : "",
        typeQuestion: dataIs.ProjectTypeQuestion
          ? dataIs.ProjectTypeQuestion
          : "",
        styleQusetion: dataIs.projectStyleQuestion
          ? dataIs.projectStyleQuestion
          : "",
        RequestDate: dataIs.reason
          ? dataIs.reason.createdAt.split("T").slice(0)[0]
          : "",
        collateralId: dataIs.collateral ? dataIs.collateral._id : "",
        LoadingIs: false,
        projectId: dataIs.reason ? dataIs.reason.post_project_id : "",
        Reason: dataIs.reason ? dataIs.reason : "",
        reasonType: dataIs.reason ? dataIs.reason.type :'',
        reasonExplain :  dataIs.reason ? dataIs.reason.explain :'',
        minBudget : dataIs.project_data ? dataIs.project_data.min_budget :'',
        maxBudget : dataIs.project_data ? dataIs.project_data.max_budget :'',
        budgetType : dataIs.project_data ? dataIs.project_data.budget_type :'',
        budgetUnit : dataIs.project_data ? dataIs.project_data.budget_unit :''
      });
    }
    if (creatorList.status === "1") {
      this.setState({
        options: creatorList.data,
      });
    }
    if (recommendData.status === "1" && createCreatorIs) {
      const { projectId } = this.state;
      this.props.history.push(`/transactionsDetails/:${projectId}`);
      this.setState({ createCreatorIs: false });
    }
  }

  render() {
    const {
      firstName,
      lastName,
      collateralName,
      clientEamil,
      RequestDate,
      PhoneNumber,
      typeQuestion,
      indexIs,
      showForm,
      styleQusetion,
      styleForm,
      creatorEmail,
      options,
      selectedCreatorIs,
      selectCreatorActive,
      Reason,
      reasonType,
      reasonExplain,
      budgetType ,
      budgetUnit ,
      minBudget,
      maxBudget
    } = this.state;

    const dataArr = [
      { name: "firstName", value: firstName, label: "First Name" },
      { name: "lastName", value: lastName, label: "Last Name" },
      {
        name: "collateralName",
        value: collateralName,
        label: "Collateral Name",
      },
      { name: "client email", value: clientEamil, label: "Client Email" },
      { name: "PhoneNumber", value: PhoneNumber, label: "Client Phone Number" },
      { name: "RequestDate", value: RequestDate, label: "RequestDate" },
      { name: "minBudget", value: minBudget, label: "Min Budget" },
      { name: "maxBudget", value: maxBudget, label: "Max Budget" },
      { name: "budgetType", value: budgetType, label: "Budget Type" },
      { name: "budgetUnit", value: budgetUnit, label: "Budget unit" },
    ];

    const reasonArr = [
      { name: "reasonType", value: reasonType, label: "Call Back Type" },
      { name: "reasonExplain", value: reasonExplain, label: "Explain" },
    ];

    return (
      <React.Fragment>
        <section className="container" onClick={this.handledropdown}>
          <div className="row">
            {dataArr.map((obj, index) => (
              <div className="col-12 col-md-6" key={index}>
                <div className="form-group">
                  <label>{obj.label}</label>
                  <input
                    type="text"
                    className="form-control"
                    name={obj.name}
                    value={obj.value}
                    onChange={this.handleOnChange}
                    disabled="disabled"
                  />
                </div>
              </div>
            ))}

            <div className="col-12">
              <hr className="my-5" />
              <label className="col-12">Type Question</label>

              {typeQuestion.length > 0 ? (
                typeQuestion.map((obj, index) => (
                  <React.Fragment key={index}>
                    <div className="mx-auto mt-4">
                      <div className="questin-Listing p-3">
                        <div className="row col-md-12">
                          <div className="col">
                            <h5 className="mb-0 text-secondary">
                              Qs : {obj.question}
                            </h5>
                          </div>
                          <div className="col-2">
                            <ul className="mb-0 mr-auto d-flex">
                              <li>
                                <i
                                  className="fe fe-chevron-down"
                                  style={{
                                    display: "inline-block",
                                    marginRight: "5px",
                                  }}
                                  onClick={() =>
                                    this.handleTypeEditQues(obj, index, "type")
                                  }
                                ></i>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {index === indexIs && showForm && (
                      <CallBackQuestionForm {...this.state} {...this.props} />
                    )}
                  </React.Fragment>
                ))
              ) : (
                <p className="text-center">No data Found..</p>
              )}
            </div>

            <div className="col-12">
              <hr className="my-5" />
              <label className="col-12">Style Question</label>

              {styleQusetion.length > 0 ? (
                styleQusetion.map((obj, index) => (
                  <React.Fragment key={index}>
                    <div className="mx-auto mt-4">
                      <div className="questin-Listing p-3">
                        <div className="row col-md-12">
                          <div className="col">
                            <h5 className="mb-0 text-secondary">
                              Qs : {obj.question}
                            </h5>
                          </div>
                          <div className="col-2">
                            <ul className="mb-0 mr-auto d-flex">
                              <li>
                                <i
                                  className="fe fe-chevron-down"
                                  style={{
                                    display: "inline-block",
                                    marginRight: "5px",
                                  }}
                                  onClick={() =>
                                    this.handleTypeEditQues(obj, index, "style")
                                  }
                                ></i>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {index === indexIs && styleForm && (
                      <CallBackQuestionForm {...this.state} {...this.props} />
                    )}
                  </React.Fragment>
                ))
              ) : (
                <p className="text-center">No data found....</p>
              )}
            </div>
          </div>

          <div className="col-12">
            <hr className="my-5" />            
            <div className="row">
              {reasonArr.map((obj, index) => (
                <div className="col-12 col-md-6" key={index}>
                  <div className="form-group">
                    <label>{obj.label}</label>
                    <input
                      type="text"
                      className="form-control"
                      name={obj.name}
                      value={obj.value}
                      onChange={this.handleOnChange}
                      disabled="disabled"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="row">
            {Array.isArray(Reason.reason) && <label className="col-12 ">Reason</label>}
              {Array.isArray(Reason.reason)
                ? Reason.reason.map((obj, index) => (
                    <div className="col-4 mb-2" key={index}>
                      <div className="questin-Listing">
                        <h5>{obj.option}</h5>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
          {/*------------------Search ---------------------*/}
          <div className="col-12 ">
            <hr className="my-5" />
            <div className="row">
              <div className="col questin-Listing p-3">
                <form className="row align-items-center">
                  <div className="col-auto pr-0">
                    <span className="fe fe-search text-muted"></span>
                  </div>
                  <div className="col">
                    <input
                      type="email"
                      className="form-control form-control-flush search"
                      autoComplete="off"
                      placeholder="Search Creator"
                      name="creatorEmail"
                      value={creatorEmail}
                      onChange={this.handleSearch}
                      onClick={this.handleSearchOnClick}
                    />
                    {options?.length > 0 && selectCreatorActive ? (
                      <ul
                        className="list-group list-group"
                        style={{
                          position: "absolute",
                          zIndex: "99",
                          background: "white",
                          width: "95%",
                          maxHeight: "200px",
                          overflow: "scroll",
                        }}
                      >
                        {options.map((obj, index) => (
                          <li
                            className="list-group-item"
                            key={index}
                            onClick={() => this.handleSelectedCreator(obj)}
                          >
                            {obj.first_name +
                              " " +
                              obj.last_name +
                              "  " +
                              `(${obj.email})`}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      ""
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/*------------------Search ---------------------*/}
          {selectedCreatorIs.length > 0 ? (
            <div className="">
              <div className="row mt-4 mb-5">
                {selectedCreatorIs.map((obj, index) => (
                  <div className="col-6" key={index}>
                    <span>
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <a href="#!" className="avatar avatar-4by3">
                            <img
                              src={
                                obj.profile_image
                                  ? userProfileBaseUrl + obj.profile_image
                                  : require("../../../../Assets/img/avatars/profiles/avatar-1.jpg")
                              }
                              alt="..."
                              className="avatar-img rounded"
                            />
                          </a>
                        </div>
                        <div className="col ml-n2">
                          <h4 className="card-title mb-1">
                            <a href="project-overview.html">
                              {obj.first_name + " " + obj.last_name}
                            </a>
                          </h4>
                          <p className="card-text small text-muted">
                            {obj.email}
                          </p>
                        </div>
                        <div
                          className="col-auto"
                          onClick={() => this.handleDeleteCreator(index)}
                        >
                          <i className="fe fe-trash"></i>
                        </div>
                      </div>

                      <hr />
                    </span>
                  </div>
                ))}
              </div>
              <div className="ml-auto">
                <button
                  className="btn btn-primary"
                  onClick={this.handleCreatorSubmit}
                >
                  Submit
                </button>
              </div>
            </div>            
          ) : (
            ""
          )}
          <hr className="my-5"/>
        </section>
      </React.Fragment>
    );
  }
}
const mapState = ({
  callBackData,
  callBackSearchCreator,
  recommendedCreator,
}) => {
  return {
    _callBackData: callBackData,
    _callBackSearchCreator: callBackSearchCreator,
    _recommendedCreator: recommendedCreator,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $callBackData: (values) => dispatchEvent(callBackData(values)),
  $callBackSearchCreator: (values) =>
    dispatchEvent(callBackSearchCreator(values)),
  $recommendedCreator: (values) => dispatchEvent(recommendedCreator(values)),
});
export default connect(mapState, mapDispatch)(ViewCallBackRequest);
