import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../../Common/Loader/Loader";
import { connect } from "react-redux";
import { deleteCollateralList } from "../../../../store/action/collateralList.action";
import { stausArtistCollateral } from "../../../../store/action/clientList.action";
import ReactPaginate from "react-paginate";
import ErrorApi from "../../Common/Error/ErrorApi";

class CollateralDeletion extends Component {
  state = {
    LoadingIs: false,
    currentPage: 1,
    sort: -1,
    userCollateralIs: [],
    totalPage: 0,
    
    acceptRejectIs: false,
    sortFlag : false
  };

  handleGetConfirmation(obj, id) {
    let confirmIs = window.confirm(
      `Do you want to ${obj === 1 ? `reject` : `accept`} this Collateral ?`
    );
    if (confirmIs === true) {
      const statusData = { _id: id, status: obj };
      this.props.$stausArtistCollateral(statusData);
      this.setState({
        acceptRejectIs: true,
      });
      return true;
    } else {
      return false;
    }
  }

  handleTransactonStatus = (id, status) => {
    let currentStatus = 0;
    if (status === "Delete_accept") {
      currentStatus = 3;
    } else if (status === "Delete_reject") {
      currentStatus = 1;
    }

    this.handleGetConfirmation(currentStatus, id);
  };

  handleSorting = () => {
    this.setState({sortFlag : !this.state.sortFlag},()=>{
      const { currentPage } = this.state;
      let dataIs = null;
      if(this.state.sortFlag){
        dataIs = { page: currentPage, sortIs: 1 };
      }else{
        dataIs = { page: currentPage, sortIs: -1 };
      }
      this.setState({ sort : this.state.sortFlag ? 1 : -1})
      this.handleDeleteCollateralList(dataIs);
    })
  };

  handlePageClick = (data) => {
    let currentSelected = data.selected + 1;
    const { sort } = this.state;
    const dataIs = { page: currentSelected, sortIs: sort };
    this.handleDeleteCollateralList(dataIs);
  };

  handleDeleteCollateralList = (currentData) => {
    this.setState({
      LoadingIs: false,
    });
    this.props.$deleteCollateralList(currentData);
  };

  componentDidMount() {
    const { currentPage, sort } = this.state;
    const dataIs = { page: currentPage, sortIs: sort };
    this.handleDeleteCollateralList(dataIs);
  }

  componentWillReceiveProps(nextProps) {
    const { acceptRejectIs } = this.state;
    const deletedDataIs = nextProps._deleteCollateralList.deleteCollListIs;
    const artiststatusDta = nextProps._stausArtistCollateral.artistColstatus;
    if (deletedDataIs) {
      this.setState({
        userCollateralIs: deletedDataIs.userCollateral
          ? deletedDataIs.userCollateral
          : "",
        totalPage: deletedDataIs.totalPages ? deletedDataIs.totalPages : 0,
      });
    }
    if (artiststatusDta.status === "1" && acceptRejectIs) {
      const { currentPage, sort } = this.state;
      const dataIs = { page: currentPage, sortIs: sort };
      this.handleDeleteCollateralList(dataIs);
      this.setState({
        acceptRejectIs: false,
      });
    }
  }

  render() {
    const collateralDelete = [
      { name: "Action" },
      { name: "first name" },
      { name: "last name" },
      { name: "username" },
      { name: "collateral name" },
      { name: "submission date" },      
    ];

    const {
      LoadingIs,
      userCollateralIs,
    } = this.state;

    return (
      <section>
        {this.props._deleteCollateralList.deleteCollateralListErrIs ? (
          <ErrorApi {...this.props} />
        ) : (
          <React.Fragment>
            <section className="client mt-5">
              <div className="container-fluid mx-auto">
                {LoadingIs ? (
                  <Loader />
                ) : (
                  <div className="table-responsive">
                    <table className="table table-sm table-nowrap card-table">
                      {/*----------------- table head --------------------*/}
                      <thead>
                        <tr>
                          {collateralDelete.map((obj, index) => (
                            <React.Fragment key={index}>
                              {obj.name !== "submission date" && (
                                <th>
                                  <Link to="#!" className="text-muted">
                                    {obj.name}
                                  </Link>
                                </th>
                              )}
                              {obj.name === "submission date" && (
                                <th key={index}>
                                  <Link
                                    to="#!"
                                    className="text-muted sort"
                                    data-sort="tables-row"
                                    onClick={this.handleSorting}
                                  >
                                    {obj.name}
                                  </Link>
                                </th>
                              )}
                            </React.Fragment>
                          ))}
                        </tr>
                      </thead>
                      {/*----------------- table head --------------------*/}
                      <tbody className="list">
                        {userCollateralIs.length > 0 ? (
                          userCollateralIs.map((obj, index) => (
                            <tr key={index}>
                              <td className="text-left">
                                <div
                                  className={`dropdown `}
                                >
                                  <a
                                    href="#!"
                                    className="dropdown-ellipses dropdown-toggle text-secondary"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i
                                      className="fe fe-more-vertical"
                                    ></i>
                                  </a>
                                  
                                    <div
                                      className={`ma-auto dropdown-menu dropdown-menu-right text-center`}
                                      
                                    >
                                      {obj.status === 4 && (
                                        <span>
                                          <Link
                                            to="#!"
                                            className="dropdown-item"
                                            datatoggle="modal"
                                            datatarget="#exampleModalCenter"
                                            onClick={() =>
                                              this.handleTransactonStatus(
                                                obj._id,
                                                "Delete_accept"
                                              )
                                            }
                                          >
                                            Delete_Accept
                                          </Link>
                                          <Link
                                            to="#!"
                                            className="dropdown-item mb-2"
                                            onClick={() =>
                                              this.handleTransactonStatus(
                                                obj._id,
                                                "Delete_reject"
                                              )
                                            }
                                          >
                                            Delete_Reject
                                          </Link>
                                        </span>
                                      )}
                                      <span>
                                        <Link
                                          to={`/approvals/PendingViewCollateral/:${obj._id}`}
                                          className="dropdown-item mb-2"
                                          datatoggle="modal"
                                          datatarget="#exampleModalCenter"
                                        >
                                          View
                                        </Link>
                                      </span>
                                    </div>
                                </div>
                              </td>
                              <td className="orders-order">
                                {obj.user.first_name}
                              </td>
                              <td className="orders-product">
                                {obj.user.last_name}
                              </td>
                              <td className="orders-date">
                                {obj.user.username}
                              </td>
                              <td className="orders-total">
                                {obj.collateral_name}
                              </td>
                              <td className="orders-method">
                                {obj.updatedAt.split("T").slice(0)[0]}
                              </td>                              
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>No data Found..</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                {/****************** Pagination ********************/}

                {this.state.totalPage > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                )}
              </div>
            </section>
          </React.Fragment>
        )}
      </section>
    );
  }
}

const mapState = ({ deleteCollateralList, stausArtistCollateral }) => {
  return {
    _deleteCollateralList: deleteCollateralList,
    _stausArtistCollateral: stausArtistCollateral,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $deleteCollateralList: (values) =>
    dispatchEvent(deleteCollateralList(values)),
  $stausArtistCollateral: (values) =>
    dispatchEvent(stausArtistCollateral(values)),
});
export default connect(mapState, mapDispatch)(CollateralDeletion);
