import React, { Component } from "react";

export default class ArtistProjectForm extends Component { 

  render() {
    const {
      aboutProject,
      projectTitle,
      projectEndMonth,
      projectEndYear,
      artistRole,
      projectKeywords,
      projectTools,
      handleProject,
    } = this.props;

    const formContentArr = [
      { name: "projectTitle", value: projectTitle, label: "Title" },
      { name: "artistRole", value: artistRole, label: "Role" },
      { name: "projectEndMonth", value: projectEndMonth, label: "End Month" },
      { name: "projectEndYear", value: projectEndYear, label: "End Year" },
    ];

    return (
      <React.Fragment>
        <div
          className="questin-Listing"
          style={{ borderTop: "none", borderRadius: "0 0 5px 5px" }}
        >
          <div className="row mt-3">
            {/*------------------- Project inputs----------------------*/}
            {formContentArr.map((obj, index) => (
              <div className="col-12 col-md-6" key={index}>
                <div className="form-group">
                  <label>{obj.label}</label>
                  <input
                    type="text"
                    className="form-control"
                    name={obj.name}
                    value={obj.value}
                    onChange={handleProject.handleOnChange}
                    disabled="disabled"
                  />
                </div>
              </div>
            ))}
            {/*------------------- Project inputs----------------------*/}

            <div className="col-12 col-md-12">
              <div className="form-group">
                <label className="mb-1">Keywords</label>
                <div className="selection-tags d-flex align-items-center">
                  <div className="ml-2">
                    {projectKeywords.map((obj, index) => (
                      <span className="keyword-span" key={index}>
                        {obj}
                      </span>
                    ))}
                  </div>
                  <span style={{ display: "inline-block" }}>
                    <input
                      type="text"
                      className={`${
                        projectKeywords.length ? "key-input" : "form-control"
                      }`}
                      name="keywords"
                      //  value='keywords'
                      onChange={handleProject.handleOnChange}
                      disabled="disabled"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12">
              <div className="form-group">
                <label className="mb-1">Tools</label>
                <div className="selection-tags d-flex align-items-center">
                  <div className="ml-2">
                    {projectTools.map((obj, index) => (
                      <span className="keyword-span" key={index}>
                        {obj.name}
                      </span>
                    ))}
                  </div>
                  <span style={{ display: "inline-block" }}>
                    <input
                      type="text"
                      className={`${
                        projectTools.length ? "key-input" : "form-control"
                      }`}
                      name="tools"
                      //  value='tools'
                      onChange={handleProject.handleOnChange}
                      disabled="disabled"
                    />
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-12">
              <div className="form-group">
                <label className="mb-1">About</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="aboutProject"
                  value={aboutProject}
                  onChange={handleProject.handleOnChange}
                  disabled="disabled"
                ></textarea>
              </div>
            </div>           

            {/* <div className="col-12 d-flex justify-content-end">
              <button className="btn btn-primary">Update</button>
            </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
