import React, { Component } from "react";
import { connect } from "react-redux";
import { clientBriefList } from "../../../store/action/clientList.action";
import ReactPaginate from "react-paginate";
import ErrorApi from "../Common/Error/ErrorApi";
import { Link } from "react-router-dom";
import Loader from "../Common/Loader/Loader";
import moment from "moment";

class ClientAllBrief extends Component {
  state = {
    LoadingIs: false,
    page: 1,
    sort: -1,
    totalPages: 0,
    projectName: "",
    briefData: [],
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    },()=>{
      const{page} = this.state;
      this.handleBriefList(page);
    });
  };

  handlePageClick = (data) =>{
    const selectedData = data.selected +1
    this.handleBriefList(selectedData)
  }

  handleBriefList = (page) => {
    const id = this.props.match.params.id.split(":").slice(0)[1];
    const { sort, projectName } = this.state;
    if(id){
      this.setState({LoadingIs : true})
    const currentData = {
      page: page,
      sortIs: sort,
      id: id,
      project: projectName,
    };
    this.props.$clientBriefList(currentData);
  }
  };

  componentDidMount() {
    const{page} = this.state;
    this.handleBriefList(page);
  }

  componentWillReceiveProps(nextProps) {
    const listData = nextProps._clientBriefList.clientAllBriefIs;
    if (listData) {
      this.setState({
        briefData: listData.PostProjects,
        totalPages: listData.totalPages,
        LoadingIs : false
      });
    }
  }

  render() {
    const otherDbsArr = [
      { name: "Project" },
      { name: "Min Budget" },
      { name: "Max Budget" },
      { name: "Deadline" },
      { name: "Status" },
      { name: "stage"},
      { name: "Admin user" },
      { name: "Collateral" },
      { name: "Posted by" },
      { name: "Start date" },
      { name: "Approved date" },
      { name: "Complete date" },
      { name: "CallBackRequest date"},
      { name: "Rejected date" },
      { name: "close date"},
      { name: "delete date"},
      { name: "Action" },
    ];
    const { LoadingIs, projectName, briefData } = this.state;
    return (
      <React.Fragment>
        {this.props._clientBriefList.clientAllBriefErr ? (
          <ErrorApi {...this.props} />
        ) : (
          <section className="client">
            <div className="container-fluid mx-auto">
              <div className="row col-md-12 mb-2">
                <div className="col">
                  <form className="row align-items-center">
                    <div className="col-auto pr-0">
                      <span className="fe fe-search text-muted"></span>
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        className="form-control form-control-flush search"
                        autoComplete="off"
                        placeholder="Enter Project"
                        name="projectName"
                        value={projectName}
                        onChange={this.handleOnChange}
                      />
                    </div>
                  </form>
                </div>
              </div>
              {LoadingIs ? (
                <Loader />
              ) : (
                <div className="table-responsive">
                  <table className="table table-sm table-nowrap card-table">
                    {/*----------------- table head --------------------*/}
                    <thead>
                      <tr>
                        {otherDbsArr.map((obj, index) => (
                          <th key={index}>
                            <Link to="#!" className="text-muted sort">
                              {obj.name}
                            </Link>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    {/*----------------- table head --------------------*/}
                    <tbody className="list">
                      {briefData && briefData.length > 0 ? (
                        briefData.map((obj, index) => (
                          <tr key={index}>
                            <td className="orders-order">
                              {obj.project_name && obj.project_name}
                            </td>
                            <td className="orders-product">{obj.min_budget}</td>
                            <td className="orders-date">{obj.max_budget}</td>
                            <td className="orders-total text-center">{obj?.project_deadline_date ?
                                moment(obj?.project_deadline_date).format("DD/MM/YYYY") :'-'}
                            </td>
                            <td className="orders-status">
                              <span
                                className={`mr-2 ${
                                  obj.status === 0 ||
                                  obj.status === 1 ||
                                  obj.status === 2
                                    ? `text-warning`
                                    : obj.status === 3
                                    ? `text-info`
                                    : obj.status === 4
                                    ? `text-secondary`
                                    : obj.status === 5
                                    ? `text-primary`
                                    : obj.status === 6
                                    ? `text-primary`
                                    : obj.status === 7
                                    ? `text-success`
                                    : obj.status === 8
                                    ? `text-dark`
                                    : obj.status === 9
                                    ? `text-danger`
                                    : obj.status === -1
                                    ? `text-warning`
                                    : obj.status === 10
                                    ? `text-danger`
                                    : ``
                                }`}
                              >
                                ●
                              </span>
                              {`${
                                obj.status === 0 ||
                                obj.status === 1 ||
                                obj.status === 2
                                  ? `Incomplete Brief`
                                  : obj.status === 3
                                  ? `Pending`
                                  : obj.status === 4
                                  ? `Call Back Request`
                                  : obj.status === 5
                                  ? `Negociation`
                                  : obj.status === 6
                                  ? `Active`
                                  : obj.status === 7
                                  ? `Completed`
                                  : obj.status === 8
                                  ? `Close`
                                  : obj.status === 9
                                  ? `Rejected`
                                  : obj.status === -1
                                  ? `Incomplete Brief`
                                  : obj.status === 10
                                  ? `Deleted`
                                  : ``
                              }`}
                            </td>
                            <td className="orders-method">
                            {obj.status === 0
                                ? "Scope of work"
                                : obj.status === 1
                                ? "Profile selection"
                                : obj.status === 2
                                ? "Project Details"
                                : obj.status === 3
                                ? "Pending"
                                : obj.status === 4
                                ? "Call Back Request"
                                : obj.status === 5
                                ? "Active Brief"
                                : obj.status === 6
                                ? "Active Project"
                                : obj.status === 7
                                ? "Completed"
                                : obj.status === 8
                                ? "Close"
                                : obj.status === 9
                                ? "Rejected"
                                : obj.status === 10
                                ? "Deleted"
                                : obj.status === -1
                                ? "Incomplete"
                                : ""}
                            </td>
                            <td className="orders-method">{obj.admin_email}</td>
                            <td className="orders-method">
                              {obj.collateral && obj.collateral.name}
                            </td>
                            <td className="orders-method">
                            {obj.type === "post_project_admin" || obj.were_admin_project
                            ? "Admin"
                            : "Client"}
                            </td>
                            <td className="orders-method">
                              {obj?.createdAt && moment(obj?.createdAt).format("DD/MM/YYYY")}
                            </td>
                            <td className="orders-method">
                              {obj?.project_brief_approved_date &&
                                moment(obj?.project_brief_approved_date).format("DD/MM/YYYY")}
                            </td>
                            <td className="orders-method">
                              {obj.project_brief_completion_date && moment(obj.project_brief_completion_date).format("DD/MM/YYYY")}
                            </td>
                            <td className="orders-method">
                              {obj.project_call_back_request_date &&
                                moment(obj?.project_call_back_request_date).format("DD/MM/YYYY")}
                            </td>
                            <td className="orders-method">
                              {obj.project_brief_rejection_date &&
                                moment(obj.project_brief_rejection_date).format("DD/MM/YYYY")}
                            </td>
                            <td className="orders-method">
                              {obj.project_close_date && moment(obj.project_close_date).format("DD/MM/YYYY")}
                            </td>
                            <td className="orders-method">
                              {obj.project_delete_date &&
                                moment(obj.project_delete_date).format("DD/MM/YYYY")}
                            </td>
                            <td className="text-method">
                              <Link
                                to={obj.type === "post_project_admin" || obj.were_admin_project?`/transactions/addProject/:${obj._id}`: `/transactions/transactionsDetails/:${obj._id}`}
                              >
                                <button className="btn btn-outline-success btn-sm mr-2">
                                  <i className="fe fe-eye"></i>
                                </button>
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>No data found......</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
              {/*------------------ Pagination--------------- */}

              {this.state.totalPages > 0 &&
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />}
            </div>
          </section>
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({ clientBriefList }) => {
  return {
    _clientBriefList: clientBriefList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $clientBriefList: (values) => dispatchEvent(clientBriefList(values)),
});
export default connect(mapState, mapDispatch)(ClientAllBrief);
