import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import {
  
} from "../../../store/action/clientList.action";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    maxWidth: "600px",
    width: "100%",
  },
};

class RejectDeleteModal extends Component {
  state = {
    reasonIs: "",
    reasonErrorIs:false
  };

  handleOnChange = (e) => {
    if (e.target.value.length < 500) {
      this.setState({
        [e.target.name]: e.target.value,
      }, () => {
        if (this.state.reasonIs) this.setState({ reasonErrorIs: false })
        else this.setState({ reasonErrorIs: true })
      });
    }
  };

  handleCloseModal = () => {
    this.props.funcIs.handleModalClose();
  };

  handleDeleteReject = () => {
    if (this.state.reasonIs.trim()) {
      this.props.funcIs.handleRejDelete(this.state.reasonIs.trim())
    } else {
      this.setState({reasonErrorIs:true})
    }
  }

  render() {
    const { reasonIs,reasonErrorIs } = this.state;
    const { load,type } = this.props;
    return (
      <React.Fragment>
        <div>
          <Modal
            isOpen={true}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <h3 className={`text-center mb-4`}>
              {type === "delete" ? "Are you sure you want to delete this profile ?" : "Are you sure you want to reject this profile ?"}
            </h3>

            <div className="form-group">
              <textarea
                className="form-control"
                style={{ borderColor: reasonErrorIs ? "red" : "" }}
                placeholder="Enter Reason"
                name="reasonIs"
                value={reasonIs}
                onChange={this.handleOnChange}
              />
              {reasonErrorIs ? <p style={{ color: "red" }}>Required Field</p> : ""}
            </div>
            <div className="mx-auto text-center">
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={this.handleDeleteReject}
              >
                {load ? "Processing......" : "Yes"}
              </button>
              <button
                type="button"
                className="btn btn-secondary ml-2"
                data-dismiss="modal"
                onClick={this.handleCloseModal}
              >
                No
              </button>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

// const mapState = ({ }) => {
//   return {
//   };
// };
// const mapDispatch = (dispatchEvent) => ({
// });
// export default connect(mapState, mapDispatch)(RejectDeleteModal);

export default RejectDeleteModal;
