import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import {
  editEmbeddedLink
} from "../../../../store/action/clientList.action";
import { pendingProjectList } from "../../../../store/action/projectList.action";
import LoaderSmall from "../../Common/Loader/LoaderSmall";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    maxWidth: "900px",
    width: "100%",
  },
};

class LinkEditModal extends Component {
  state = {
    projectLinksEdit: false,
    projectImageIndex: 0,
    mediaType: "",
    mediaUrl: "",
    MediaList: [],
    LoadingIs: false,
    editIs : false
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    if(name === "mediaUrl"){
      this.setState({
        editIs : true
      })
    }
  };

  handleEditProjectImageOrLinks = (obj, index) => {
    this.setState({
      projectImageIndex: index,
      projectLinksEdit: true,
      mediaType: obj.type,
      mediaUrl: obj.value,
    });
  };

  handleCloseForm = () => {
    this.setState({
      projectLinksEdit: false,
    });
  };

  handleChangeSave = () => {
    const { mediaUrl, projectImageIndex, MediaList } = this.state;
    MediaList[projectImageIndex] = {
      ...MediaList[projectImageIndex],
      value: mediaUrl,
    };
    this.setState({
      projectLinksEdit: false,
      MediaList,
    });
  };

  handleChangeLink = (MediaList, id) => {
    const currentData = { project_id: id, images_or_links: MediaList };
    this.props.$editEmbeddedLink(currentData);
  };

  handleSubmit = () => {
    const id = this.props.projectIdIs
    const { MediaList } = this.state;
    if (id) {
      this.setState({ LoadingIs: true });
      this.handleChangeLink(MediaList, id);
    }
  };

  componentWillReceiveProps(nextProps) {
    const DataIs = nextProps._editEmbeddedLink.embeddedLinkIs;
    const { projectLinksEdit, LoadingIs } = this.state;
    if (nextProps && !projectLinksEdit) {
      this.setState({
        MediaList: nextProps.MediaIs,
      });
    }
    if (DataIs.status === "1" && LoadingIs) {
      this.setState({
        LoadingIs: false,
      });
      this.props.handleMyfunc.handleCloseLinksModal();
      const data = { page: 1, sortIs: -1 };
      this.props.$pendingProjectList(data);
    }
  }

  // edit Form--------------------------------------

  renedrProjectImageEditForm() {
    const { mediaType, mediaUrl } = this.state;
    const formArr = [
      {
        type: "text",
        placeholder: "type",
        name: "mediaType",
        value: mediaType,
      },
      { type: "text", placeholder: "link", name: "mediaUrl", value: mediaUrl },
    ];

    return (
      <div className="col-12 col-md-12 mt-2">
        <div
          className="form-group text-secondary"
          // style={{ alignItems: "flex-end" }}
        >
          {formArr.map((obj, index) => (
            <div className="col-12" key={index}>
              <div className="form-group">
                <input
                  type={obj.type}
                  name={obj.name}
                  value={obj.value}
                  placeholder={obj.placeholder}
                  className="form-control"
                  onChange={this.handleOnChange}
                  disabled={`${obj.name === `mediaType` ? `disabled` : ""}`}
                />
              </div>
            </div>
          ))}
          <div className="col-12">
            <button
              className="btn btn-success mr-2"
              onClick={this.handleChangeSave}
            >
              Save
            </button>
            <button
              className="btn btn-secondary"
              onClick={this.handleCloseForm}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
  // edit form-----------------------------------------------

  //main modal--------------------------------------

  render() {
    const { linkModalOpen, handleMyfunc } = this.props;
    const {
      projectLinksEdit,
      projectImageIndex,
      MediaList,
      LoadingIs,
    } = this.state;

    return (
      <React.Fragment>
        <div>
          <Modal
            isOpen={linkModalOpen}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <button
              type="button"
              className="close media-upload-modal"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleMyfunc.handleCloseLinksModal}
            >
              <span
                aria-hidden="true"
                style={{ color: "black", fontSize: "32px" }}
              >
                &times;
              </span>
            </button>
            <div className="col-12 col-md-12 text-center">Edit links</div>
            <hr className="my-5" />
            <div className="col-12 row mx-auto">
              {MediaList &&
                MediaList.map((obj, index) => (
                  <div className="col-12 col-md-6 mb-4" key={index}>
                    <ul className="list-group list-group-lg list-group-flush list my-n4">
                      <li className="list-group-item px-0">
                        <div className="row align-items-center">
                          {obj.value ? (
                            <div className="col-2 mr-1">
                              <a href="#!" className="avatar avatar-md">
                                {obj.type === "image" ? (
                                  <React.Fragment>
                                    {obj.value.slice(
                                      obj.value.lastIndexOf(".")
                                    ) === ".pdf" ? (
                                      <embed
                                        src={obj.value}
                                        className="avatar-img rounded"
                                      />
                                    ) : (obj.value &&
                                        obj.value.slice(
                                          obj.value.lastIndexOf(".")
                                        ) === ".docx") ||
                                      (obj.value &&
                                        obj.value.slice(
                                          obj.value.lastIndexOf(".")
                                        ) === ".doc") ? (
                                      <iframe
                                        className="avatar-img rounded"
                                        frameborder="0"
                                        src={`https://docs.google.com/gview?url=${obj.value}&embedded=true`}
                                        title="..."
                                        id="12"
                                      ></iframe>
                                    ) : (
                                      <img
                                        src={obj.value}
                                        className="avatar-img rounded"
                                        alt="..."
                                        style={{ borderRadius: "10px" }}
                                      />
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <iframe
                                    className="videoIframe js-videoIframe avatar-img rounded"
                                    src={obj.value}
                                    frameBorder="0"
                                    allowtransparency="true"
                                    allowFullScreen
                                    title=".."
                                    id="video"
                                  ></iframe>
                                )}
                              </a>
                            </div>
                          ) : (
                            <div className="col-2 mr-1">
                              <img
                                src={require("../../../../Assets/img/avatars/profiles/images.jpeg")}
                                className="avatar-img rounded"
                                alt="..."
                                style={{ borderRadius: "10px" }}
                              />
                            </div>
                          )}
                          <div className="col-8 ml-n2 ">
                            <h4 className="card-title mb-1 name">
                              <a href="#!">{obj.type}</a>
                            </h4>
                            <p
                              className="card-text small text-muted mb-1"
                              style={{ wordBreak: "break-all" }}
                            >
                              <a
                                href={obj.value}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "gray" }}
                              >
                                {obj.value ? obj.value : "No data"}
                              </a>
                            </p>
                          </div>
                          {obj.type === "url" && (
                            <div className="col-2">
                              <a
                                href="#!"
                                className="btn btn-sm btn-white d-none d-md-inline-block"
                                onClick={() =>
                                  this.handleEditProjectImageOrLinks(obj, index)
                                }
                              >
                                Edit
                              </a>
                            </div>
                          )}
                        </div>
                      </li>
                    </ul>
                    {projectLinksEdit &&
                      projectImageIndex === index &&
                      this.renedrProjectImageEditForm()}
                  </div>
                ))}
              <div className="col-12 text-right">
                <button className="btn btn-primary" onClick={this.handleSubmit}>
                  {(LoadingIs && this.state.editIs) ? <LoaderSmall /> : "Submit"}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

const mapState = ({ editEmbeddedLink, pendingProjectList }) => {
  return {
    _editEmbeddedLink: editEmbeddedLink,
    _pendingProjectList: pendingProjectList
  };
};
const mapDispatch = (dispatchEvent) => ({
  $editEmbeddedLink: (values) => dispatchEvent(editEmbeddedLink(values)),
  $pendingProjectList: (values) => dispatchEvent(pendingProjectList(values)),
});
export default connect(mapState, mapDispatch)(LinkEditModal);
