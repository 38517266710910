import React, { Component } from "react";
import Modal from "react-modal";
import { profileBaseUrl } from "../../../../Utils/BaseUrl";
import "react-multi-carousel/lib/styles.css";
import LinkEditModal from "./LinkEditModal";
import AsyncImage from "../../Common/asyncImage";
import checkUrl from "../../Common/CheckUrl/CheckMediaUrl";
import {getMedia} from '../../Common/CheckUrl/MediaImage';
import {getThumbnailImage} from "../../Common/CheckUrl/ThumbnailImage";
Modal.setAppElement("#root");
Modal.defaultStyles.overlay.backgroundColor = "rgb(0 0 0 / 50%)";
Modal.defaultStyles.overlay.zIndex = "9999";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    borderRadius: "8px",
    maxWidth: "1024px",
    width: "100%",
    border: "none",
    height: "calc(100vh - 50px)"
  },
};

export default class UploadMedia extends Component {
  state = {
    selectedMedia: "",
    ImagesandLinks: [],
    MediaIs: [],
    index: 0,
    linkModalOpen: false,
  };

  handleOpenLinkModal = () => {
    this.setState({
      linkModalOpen: true,
    });
  };

  handleCloseLinksModal = () => {
    this.setState({
      linkModalOpen: false,
    });
  };

  handleCarasoulArrow = (status) => {
    const { MediaIs, index } = this.state;
    const MediaList = MediaIs.filter((item) => item.value.length > 0);
    if (status === "right") {
      const indexIs = index + 1;
      if (indexIs < MediaList.length) {
        const currentArr = MediaList[indexIs];
        this.setState({
          ImagesandLinks: currentArr,
          index: indexIs,
        });
      }
    } else if (status === "left" && index > 0) {
      const indexIs = index - 1;
      // if (indexIs > MediaList.length) {
      const currentArr = MediaList[indexIs];
      this.setState({
        ImagesandLinks: currentArr,
        index: indexIs,
      });
      // }
    }
  };

  handleSelectedThumbnail = (obj, indexIs) => {
    const { MediaIs } = this.state;
    const currentSelected = MediaIs[indexIs];
    this.setState({
      ImagesandLinks: currentSelected,
      selectedMedia: obj,
      index: indexIs,
    });
  };

  componentWillReceiveProps(nextProps) {
    const ImagesLinksList = nextProps.ModalData.project;
    const currentImages = ImagesLinksList
      ? ImagesLinksList.images_or_links.filter((item) => item.value.length > 0)
      : "";
    if(ImagesLinksList) {
      this.setState({
        ImagesandLinks: currentImages ? currentImages[0] : "",
        MediaIs: ImagesLinksList.images_or_links
          ? ImagesLinksList.images_or_links
          : "",
      });
    }
  }

  render() {
    const { showModalIs, handleFunction, ModalData } = this.props;

    const { ImagesandLinks, MediaIs } = this.state;

    const dataProject = ModalData.project;
    const dataUser = ModalData.userData;
    const styleQuestionIs = ModalData.questionStyle;

    const typeQuestionIs = ModalData.questionType;

    const MediaList = MediaIs.filter((item) => item.value.length > 0);

    const limksFunc = {
      handleCloseLinksModal: this.handleCloseLinksModal,
      handleCheckedData: this.handleCheckedData,
    };

    return (
      <React.Fragment>
        <div>
          <Modal
            isOpen={showModalIs}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <button
              type="button"
              className="close media-upload-modal"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleFunction.handleCloseMediaModal}
            >
              <span
                aria-hidden="true"
                style={{ color: "black", fontSize: "32px" }}
              >
                &times;
              </span>
            </button>
            <button className="btn btn-primary mb-2" onClick={this.handleOpenLinkModal}>
              <i className="fe fe-edit">
                {" "}
              </i>
              Edit Links
            </button>
            <div className="row mt-5">
              <div className="col-sm-8">
                <div
                  className="user-work-section"
                  // style={{
                  //   height: "400px",
                  //   width: "100%",
                  //   borderRadius: "10px",
                  // }}
                >
                  {ImagesandLinks && (
                    <React.Fragment>
                      {ImagesandLinks.type === "image" ? (
                        <div className="image_preview_show">{ImagesandLinks.value && getMedia(ImagesandLinks.value)}</div>
                        // <div className="project_big_image" style={{backgroundImage:`url(getMedia(ImagesandLinks.value)`}}></div>
                      ) : (
                        <div>
                          {ImagesandLinks.value && checkUrl(ImagesandLinks.value)} 
                        </div>
                      )}
                    </React.Fragment>
                  )}
                  <div className="">
                    <div
                      className="nav-right nav-icon"
                      onClick={() => this.handleCarasoulArrow("right")}
                    >
                      <span className="fe fe-chevron-right"></span>
                    </div>
                    <div
                      className="nav-left nav-icon"
                      onClick={() => this.handleCarasoulArrow("left")}
                    >
                      <span className="fe fe-chevron-left"></span>
                    </div>
                  </div>
                </div>
                {/*-------------------------thumbnail ------------------------*/}
                <div className="row mt-4">
                  {Array.isArray(MediaList) &&
                    MediaList.map((obj, index) => {
                      return (
                        <React.Fragment key={index}>
                          {obj.type === "image" ? 
                          <> 
                              <div className="user-work-thumbnail">
                                <div
                                  className="thumbnail-div mb-3"
                                  onClick={() =>
                                    this.handleSelectedThumbnail(obj, index)
                                  }
                                >
                                  {obj.value && getThumbnailImage(obj.value)}
                                </div>
                              </div>  
                          </> : (
                            <div
                              className="thumbnail-div mb-3"
                              onClick={() =>
                                this.handleSelectedThumbnail(obj, index)
                              }
                            >
                              <AsyncImage imageurl={obj.value}/>
                            </div>
                          )}
                        </React.Fragment>
                      );
                    })}
                </div>
                {/*-------------------------thumbnail ------------------------*/}
              </div>
              <div className="col-sm-4">
                <h3>{dataProject ? dataProject.title : ""}</h3>
                <p>{`By ${dataUser ? dataUser.first_name : ""} ${
                  dataUser ? dataUser.last_name : ""
                }`}</p>
                <hr className="my-2" />
                <div className="tag des-scroll">
                  <h4 className="tag-title mb-2">Project Description</h4>
                  <span style={{ color: "#95aac9" }}>
                    {dataProject ? dataProject.about : ""}
                  </span>
                </div>

                <div className="tag">
                  <h4 className="tag-title mb-2">Software/Tools Used</h4>
                  {dataProject && dataProject.tools.length > 0
                    ? dataProject.tools.map((obj, index) => (
                        <span className="badge badge-soft-light" key={index}>
                          {obj.name}
                        </span>
                      ))
                    : "No data Found"}
                </div>

                {dataProject && dataProject.collaboration === 1 && (
                  <div className="user-details">
                    <p className="mb-0">
                      {dataUser ? dataUser.first_name : ""}'s Role
                    </p>
                    <span style={{ color: "#95aac9" }}>
                      {dataProject && dataProject.collaboration === 1
                        ? dataProject.role
                        : ""}
                    </span>
                  </div>
                )}

                <div className="tag">
                  <h4 className="tag-title mb-2">Collateral Type</h4>
                  {ModalData && ModalData.projectCollateral ? (
                    ModalData.projectCollateral.map((obj, index) => (
                      <span className="badge badge-soft-light" key={index}>
                        {obj.collateral_id.name}
                      </span>
                    ))
                  ) : (
                    <span className="badge badge-soft-light">
                      No Data Found
                    </span>
                  )}
                </div>
                {dataProject && dataProject.keywords.length > 0 && (
                  <div className="tag">
                    <h4 className="tag-title mb-2">KeyWords</h4>
                    {dataProject &&
                      dataProject.keywords &&
                      dataProject.keywords.map((obj, index) => (
                        <span className="badge badge-soft-light" key={index}>
                          {obj}
                        </span>
                      ))}
                  </div>
                )}
                {dataProject && dataProject.industry.length > 0 && (
                  <div className="tag">
                    <h4 className="tag-title mb-2">Industry</h4>
                    <span className="badge badge-soft-light">
                      {dataProject.industry}
                    </span>
                  </div>
                )}
                {dataProject && dataProject.languages.length > 0 && (
                  <div className="tag">
                    <h4 className="tag-title mb-2">Languages</h4>
                    {dataProject &&
                      dataProject.languages &&
                      dataProject.languages.map((obj, index) => (
                        <span className="badge badge-soft-light" key={index}>
                          {obj.name}
                        </span>
                      ))}
                  </div>
                )}
                <div className="user-details">
                  <span>{`Created for ${
                    dataProject ? dataProject.created_type : ""
                  }`}</span>
                </div>
                {dataProject && dataProject.created_type === "company" && (
                  <div className="user-details">
                    <span style={{ color: "#95aac9" }}>
                      Company :{" "}
                      {dataProject && dataProject.created_type === "company"
                        ? dataProject.company_name
                        : ""}
                    </span>
                  </div>
                )}
                <div className="user-details">
                  <span style={{ color: "#95aac9" }}>
                    Completed On :{" "}
                    {`${dataProject ? dataProject.end_month : ""} ${
                      dataProject ? dataProject.end_year : ""
                    }`}
                  </span>
                </div>
                {/*--------------------------style qs --------------------------------*/}
                {styleQuestionIs?.map((obj, index) => (
                  <React.Fragment key={index}>
                    <div className="user-details mt-5">
                      <h4 className="tag-title mb-2">Style Question</h4>
                      <h5 style={{ color: "#95aac9" }}>{obj.type} (Type)</h5>
                      <span style={{ color: "#95aac9" }}>{obj.question}</span>
                    </div>
                    <div className="tag">
                      <h4 className="tag-title mb-2">Answer</h4>
                      <div className="row">
                        {obj.answer?.map((object, index) => (
                          <div key={index} className="col-12 mb-2">
                            <div className="questin-Listing">
                              <div className="row">
                                <div className="col-10">{object.item}</div>
                                {object.image && (
                                  <div className="col-2">
                                    <img
                                      src={profileBaseUrl + object.image}
                                      hight="25"
                                      width="25"
                                      alt="..."
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </React.Fragment>
                ))}
                {/*--------------------------style qs --------------------------------*/}

                {/*--------------------------type qs ----------------------------------*/}
                {typeQuestionIs?.map((obj, index) => (
                  <React.Fragment key={index}>
                    <div className="user-details mt-5">
                      <h4 className="tag-title mb-2">Type Question</h4>
                      <h5 style={{ color: "#95aac9" }}>{obj.type} (Type)</h5>
                      <span style={{ color: "#95aac9" }}>{obj.question}</span>
                    </div>
                    <div className="tag">
                      <h4 className="tag-title mb-2">Answer</h4>
                      <div className="row">
                        {obj.answer?.map((object, index) => (
                          <div key={index} className="col-12 mb-2">
                            <div className="questin-Listing">
                              <div className="row">
                                <div className="col-10">{object.item}</div>
                                {object.image && (
                                  <div className="col-2">
                                    <img
                                      src={profileBaseUrl + object.image}
                                      hight="25"
                                      width="25"
                                      alt="..."
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <hr className="my-3" />
                  </React.Fragment>
                ))}
                {/*--------------------------type qs ----------------------------------*/}
              </div>
            </div>
          </Modal>
          {/*-----------------------embedded link edit modal------------------- */}
          <LinkEditModal
            {...this.state}
            {...this.props}
            handleMyfunc={limksFunc}
          />
        </div>
      </React.Fragment>
    );
  }
}
