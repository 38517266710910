import { call, put } from "redux-saga/effects";
import { getPublicAPI, postAPI, putAPI, postImage } from "../api-interface";

import axios from "../api-interface";
import {
  clientListSuccess,
  clientListError,
  checkStatusSuccess,
  userSignupSuccess,
  creativeUsersDataSuccess,
  addUsersDataSuccess,
  updateUsersDataSuccess,
  viewUsersDataSuccess,
  getClientDataSuccess,
  artistDataListSuccess,
  artistDataError,
  getArtistDataSuccess,
  stausArtistCollateralSuccess,
  stausArtistProjectSuccess,
  artistDetailsSuccess,
  clientsDetailSuccess,
  clientsDetailProfileSuccess,
  artistProfileSuccess,
  artistUpdateInfosuccess,
  artistUpdateInfoError,
  artistApprovalListSuccess,
  artistApprovalListError,


  artistBusyListSuccess,
  artistBusyListError,
  artistStarredListSuccess,
  artistStarredListError,

  artistCleanUpdatesSuccess,
  artistReviewStatusSuccess,
  tempResetPwdSuccess,
  rejectedArtsitSuccess,
  userDeleteSuccess,
  approvedArtistSuccess,
  deleteArtistSuccess,
  deleteClientSuccess,
  locationUserSuccess,
  rejectedArtistError,
  approvedArtistError,
  deleteArtistError,
  deleteClientError,
  getArtistDataError,
  getClientError,
  pendingCreatorApprovalSuccess,
  clientRejectsCreatorListSuccess,
  pendingCreatorApprError,
  clientRejectsCreatorListError,
  clientBriefListSuccess,
  clientBriefListErr,
  editEmbeddedLinkSuccess,
  artistBusyOrAvailableSuccess,
  disabledArtistSuccess,
  disableEnableArtistSuccess
} from "../action/clientList.action";

export function* onClientList(data) {
  let values = data.values;
  try {
    if (values) {
      let pageIs = values.page;
      let firstNameIs = values.firstname;
      let lastNameIs = values.lastname;
      let userIs = values.username;
      let email = values.email;
      const data = yield call(
        getPublicAPI,
        "/backendUser/client-user-list?page=" +
          pageIs +
          "&limit=10&email=" +
          email +
          "&first_name=" +
          firstNameIs +
          "&last_name=" +
          lastNameIs +
          "&username=" +
          userIs
      );
      if (data.status === 200) {
        yield put(clientListSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === "401") {
      yield put(clientListError(error.response.status));
    }
    //  else {
    //   alert(`Error: ${error.response.status}`);
    // }
  }
}

// get client data on id  **************
export function* onGetClientData(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        "/backendUser/get-user-profile?_id=" + values
      );
      if (data.status === 200) {
        yield put(getClientDataSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(getClientError(error.response.status));
    } else {
      alert(`Error : ${error.response.status}`);
    }
  }
}

// check status ***************
export function* onCheckStatus(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        "/users/user-exist?email=" + values
      );
      if (data.status === 200) {
        yield put(checkStatusSuccess(data.data.status));
      }
    }
  } catch (error) {
    alert(`Error: ${error.response.status}`);
  }
}

//client basic details update *****************
export function* onClientsDetails(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        putAPI,
        "/users/update-client-user-profile",
        values
      );
      if (data.status === 200) {
        yield put(clientsDetailSuccess(data.data));
      }
    }
  } catch (error) {

    alert(`Error: ${error.response.data.message.message}`);
  }
}

// client basic details profile update ************************

export function* onClientsProfile(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(postImage, "/backendUser/profile-image", values);
      if (data.status === 200) {
        yield put(clientsDetailProfileSuccess(data.data));
      }
    }
  } catch (error) {
    alert(`Error: ${error.response.status}`);
  }
}

//user signup ******
export function* onUserSignup(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(postAPI, "/users/admin-signup", values);
      if (data.status === 200) {
        yield put(userSignupSuccess(data.data.status));
      }
    }
  } catch (error) {
    alert(`Error: ${error.response.status}`);
  }
}

//get creative users field *******
export function* onCreativeUsersData(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield axios.get(
        `/parentCreativeField/get-field-list?page=${values}&limit=20`
      );
      if (data.status === 200) {
        yield put(creativeUsersDataSuccess(data.data));
      }
    }
  } catch (error) {
    alert(`Error: ${error.response.status}`);
  }
}

//add creative users name field********
export function* onAddUserData(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        postAPI,
        `/parentCreativeField/add-field`,
        values
      );
      if (data.status === 200) {
        yield put(addUsersDataSuccess(data.data.status));
      }
    }
  } catch (error) {
    alert(`Error: ${error.response.status}`);
  }
}

//update creative users field ********
export function* onupdateUserData(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        putAPI,
        `/parentCreativeField/update-field`,
        values
      );
      if (data.status === 200) {
        yield put(updateUsersDataSuccess(data.data.status));
      }
    }
  } catch (error) {
    alert(`Error: ${error.response.status}`);
  }
}

// view creative users field data *******

export function* onViewUserData(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield axios.get(
        `/parentCreativeField/get-field?_id=${values}`
      );
      if (data.status === 200) {
        yield put(viewUsersDataSuccess(data.data));
      }
    }
  } catch (error) {
    alert(`Error: ${error.response.status}`);
  }
}

// artist Pagination & artist list **************
export function* onArtistList(data) {
  let values = data.values;
  try {
    if (values) {
      let pageIS = values.page;
      let first_name = values.firstname;
      let last_name = values.lastname;
      let email = values.email;
      let username = values.username;
      let sort = values.sort ? values.sort : '';

      const data = yield call(
        getPublicAPI,
        "/backendUser/artist-uncompleted-onboarding-user-list?page=" +
          pageIS +
          "&limit=10" +
          "&email=" +
          email +
          "&first_name=" +
          first_name +
          "&last_name=" +
          last_name +
          "&username=" +
          username + 
          "&sort=" + sort
      );
      if (data.status === 200) {
        yield put(artistDataListSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(artistDataError(error.response.status));
    } else {
      alert(`Error: ${error.response.status}`);
    }
  }
}

// artist Rejected ************************************
export function* onArtistRejeced(data) {
  let values = data.values;
  try {
    if (values) {
      let pageIS = values.page;
      let first_name = values.firstname;
      let last_name = values.lastname;
      let email = values.email;
      let username = values.username;
      let sort = values.sort ? values.sort : '';

      const data = yield call(
        getPublicAPI,
        "/backendUser/artist-rejected-user-list?page=" +
          pageIS +
          "&limit=10" +
          "&email=" +
          email +
          "&first_name=" +
          first_name +
          "&last_name=" +
          last_name +
          "&username=" +
          username + 
          "&sort=" + sort
      );

      if (data.status === 200) {
        yield put(rejectedArtsitSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(rejectedArtistError(error.response.status));
    } else {
      alert(`Error: ${error.response.status}`);
    }
  }
}

// get artist on id ****************

export function* onGetArtistData(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        "/backendUser/get-artist-user-profile?_id=" + values
      );
      if (data.status === 200) {
        yield put(getArtistDataSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(getArtistDataError(error.response.status));
    } else {
      alert(`Error : ${error.response.status}`);
    }
  }
}

// accept/reject artist collateral ********

export function* onArtistCollateralStatus(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        putAPI,
        "/backendUser/change-user-collateral-status",
        values
      );
      if (data.status === 200) {
        yield put(stausArtistCollateralSuccess(data.data));
      }
    }
  } catch (error) {
    alert(`Error: ${error.response.status}`);
  }
}

// accept/reject artist project *********************

export function* onArtistProjectStatus(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        putAPI,
        "/backendUser/change-user-project-status",
        values
      );
      if (data.status === 200) {
        yield put(stausArtistProjectSuccess(data.data));
      }
    }
  } catch (error) {
    // alert(`Error: ${error.response.status}`);
  }
}

// artist basic details update ***********************
export function* onArtistDetails(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        putAPI,
        "/users/update-artist-user-profile",
        values
      );
      if (data.status === 200) {
        yield put(artistDetailsSuccess(data.data));
      }
    }
  } catch (error) {
    alert(`Error: ${error.response.data.message.message}`);
  }
}

// artist details profile update *************************
export function* onArtistProfile(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(postImage, "/backendUser/profile-image", values);
      if (data.status === 200) {
        yield put(artistProfileSuccess(data.data));
      }
    }
  } catch (error) {
    //alert(`Error: ${error.response.status}`);
  }
}

// artist update profile info ****************************

export function* onArtistUpdateProfileInfo(data) {
  let values = data.values;
  try {
    if (values) {
      let pageIS = values.page;
      let first_name = values.firstname;
      let last_name = values.lastname;
      let email = values.email;
      let username = values.username;

      const data = yield call(
        getPublicAPI,
        "/backendUser/artist-user-list-update?page=" +
          pageIS +
          "&limit=10" +
          "&email=" +
          email +
          "&first_name=" +
          first_name +
          "&last_name=" +
          last_name +
          "&username=" +
          username +
          "&sort=" +
          values.sort
      );
      if (data.status === 200) {
        yield put(artistUpdateInfosuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(artistUpdateInfoError(error.response.status));
    } else {
      //alert(`Error : ${error.response.status}`);
    }
  }
}

// artist approval list  ********************

export function* onArtistApprovalsList(data) {
  let values = data.values;
  try {
    if (values) {
      let pageIS = values.page;
      let first_name = values.firstname;
      let last_name = values.lastname;
      let email = values.email;
      let username = values.username;
      let sort = values.sort ? values.sort : '';

      const data = yield call(
        getPublicAPI,
        "/backendUser/artist-pending-user-list?page=" +
          pageIS +
          "&limit=10" +
          "&email=" +
          email +
          "&first_name=" +
          first_name +
          "&last_name=" +
          last_name +
          "&username=" +
          username +
          "&sort=" + sort
      );
      if (data.status === 200) {
        yield put(artistApprovalListSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(artistApprovalListError(error.response.status));
    } else {
      //alert(`Error: ${error.response.status}`);
    }
  }
}

// ---------------busy------------------------

export function* onArtistBusyList(data) {
  let values = data.values;
  try {
    if (values) {
      let pageIS = values.page;
      let first_name = values.firstname;
      let last_name = values.lastname;
      let email = values.email;
      let username = values.username;
      let sort = values.sort ? values.sort : '';

      const data = yield call(
        getPublicAPI,
        "/backendUser/artist-busy-user-list?page=" +
          pageIS +
          "&limit=10" +
          "&email=" +
          email +
          "&first_name=" +
          first_name +
          "&last_name=" +
          last_name +
          "&username=" +
          username +
          "&sort=" + sort
      );
      if (data.status === 200) {
        yield put(artistBusyListSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(artistBusyListError(error.response.status));
    } else {
      //alert(`Error: ${error.response.status}`);
    }
  }
}

// ------------------starred---------------------

export function* onArtistStarredList(data) {
  let values = data.values;
  try {
    if (values) {
      let pageIS = values.page;
      let first_name = values.firstname;
      let last_name = values.lastname;
      let email = values.email;
      let username = values.username;
      let sort = values.sort ? values.sort : '';

      const data = yield call(
        getPublicAPI,
        "/backendUser/artist-starred-user-list?page=" +
          pageIS +
          "&limit=10" +
          "&email=" +
          email +
          "&first_name=" +
          first_name +
          "&last_name=" +
          last_name +
          "&username=" +
          username +
          "&sort=" + sort
      );
      if (data.status === 200) {
        yield put(artistStarredListSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(artistStarredListError(error.response.status));
    } else {
      //alert(`Error: ${error.response.status}`);
    }
  }
}

// artist clean profile updates *******

export function* onArtistCleanUpdate(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        putAPI,
        "/backendUser/reset-update-status",
        values
      );
      if (data.status === 200) {
        yield put(artistCleanUpdatesSuccess(data.data));
      }
    }
  } catch (error) {
    //alert(`Error: ${error.response.status}`);
  }
}

// artist project Review status accept/Reject

export function* onArtistReviewStatus(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        putAPI,
        "/backendUser/project-review-status",
        values
      );
      if (data.status === 200) {
        yield put(artistReviewStatusSuccess(data.data));
      }
    }
  } catch (error) {
    //alert(`Error: ${error.response.status}`);
  }
}

// temporary reset Password (for 15 min)********

export function* ontempResetPwd(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(putAPI, "/backendUser/reset-password", values);
      if (data.status === 200) {
        yield put(tempResetPwdSuccess(data.data));
      }
    }
  } catch (error) {
    //alert(`Error: ${error.response.status}`);
  }
}

// delete artist/client **********************

export function* onDeleteUser(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        postAPI,
        "/backendUser/account-status-change",
        values
      );
      if (data.status === 200) {
        yield put(userDeleteSuccess(data.data));
      }
    }
  } catch (error) {
    //alert(`Error: ${error.response.status}`);
  }
}

// approved artist *****************************

export function* onApprovedArtist(data) {
  let values = data.values;
  try {
    if (values) {
      let pageIs = values.page;
      let firstNameIs = values.firstname;
      let lastNameIs = values.lastname;
      let userIs = values.username;
      let email = values.email;
      let sort = values.sort ? values.sort : '';
      const data = yield call(
        getPublicAPI,
        "/backendUser/artist-approved-user-list?page=" +
          pageIs +
          "&limit=10&email=" +
          email +
          "&first_name=" +
          firstNameIs +
          "&last_name=" +
          lastNameIs +
          "&username=" +
          userIs +
          "&sort=" + sort
      );
      if (data.status === 200) {
        yield put(approvedArtistSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(approvedArtistError(error.response.status));
    } else {
      //alert(`Error: ${error.response.status}`);
    }
  }
}

// delete artist *************************************

export function* onDeletedArtistList(data) {
  let values = data.values;
  try {
    if (values) {
      let pageIs = values.page;
      let firstNameIs = values.firstname;
      let lastNameIs = values.lastname;
      let userIs = values.username;
      let email = values.email;
      const data = yield call(
        getPublicAPI,
        "/backendUser/artist-user-list-unactive?page=" +
          pageIs +
          "&limit=10&email=" +
          email +
          "&first_name=" +
          firstNameIs +
          "&last_name=" +
          lastNameIs +
          "&username=" +
          userIs
      );
      if (data.status === 200) {
        yield put(deleteArtistSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(deleteArtistError(error.response.status));
    } else {
      //alert(`Error: ${error.response.status}`);
    }
  }
}

// delete client List **********************************

export function* onDeleteClientList(data) {
  let values = data.values;
  try {
    if (values) {
      let pageIs = values.page;
      let firstNameIs = values.firstname;
      let lastNameIs = values.lastname;
      let userIs = values.username;
      let email = values.email;
      const data = yield call(
        getPublicAPI,
        "/backendUser/client-user-list-unactive?page=" +
          pageIs +
          "&limit=10&email=" +
          email +
          "&first_name=" +
          firstNameIs +
          "&last_name=" +
          lastNameIs +
          "&username=" +
          userIs
      );
      if (data.status === 200) {
        yield put(deleteClientSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(deleteClientError(error.response.status));
    } else {
      // alert(`Error: ${error.response.status}`);
    }
  }
}

// location user ***************************

export function* onLocationUser(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(getPublicAPI, `/location?name=${values}`);
      if (data.status === 200) {
        yield put(locationUserSuccess(data.data));
      }
    }
  } catch (error) {
    //alert(`Error: ${error.response.status}`);
  }
}

// pending creator (Approval tab)

export function* onPendingCreatorAppr(data) {
  let values = data.values;
  let email = values.email;
  let page = values.page;
  let first_name = values.firstname;
  let last_name = values.lastname;
  let username = values.username;
  let sortingIs = values.sortData;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/artist-pending-user-list-new?page=${page}&limit=10&sort=${sortingIs}&email=${email}&first_name=${first_name}&last_name=${last_name}&username=${username}`
      );
      if (data.status === 200) {
        yield put(pendingCreatorApprovalSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(pendingCreatorApprError(error.response.status));
    } else {
      //alert(`Error: ${error.response.status}`);
    }
  }
}

// client rejects creator (Approval tab)
export function* onClientRejectsCreator(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/client-rejects-brief-list?page=${values.page}&limit=10&sort=${values.sortIs}`
      );
      if (data.status === 200) {
        yield put(clientRejectsCreatorListSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(clientRejectsCreatorListError(error.response.status));
    }
  }
}

// all brief List (Client tab)
export function* onClientAllBriefList(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/client-all-post-project?_id=${values.id}&page=${values.page}&project_name=${values.project}&limit=10`
      );
      if (data.status === 200) {
        yield put(clientBriefListSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(clientBriefListErr(error.response.status));
    }
  }
}

// edit embedded link
export function* onEmbeddedLink(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        putAPI,
        `/backendUser/creator-project-url-update`,
        values
      );
      if (data.status === 200) {
        yield put(editEmbeddedLinkSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //    yield put(clientBriefListErr(error.response.status));
    // }
  }
}

// artist busy or available
export function* onArtistAvailable(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        putAPI,
        `/backendUser/creator-take-break-or-available`,
        values
      );
      if (data.status === 200) {
        yield put(artistBusyOrAvailableSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //    yield put(clientBriefListErr(error.response.status));
    // }
  }
}

// Disabled Artist List
export function* onDisabledArtist(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/artist-disable-user-list?page=${values.page}&limit=10&email=${values.email}&first_name=${values.firstname}&last_name=${values.lastname}&username=${values.username}`,
        values
      );
      if (data.status === 200) {
        yield put(disabledArtistSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(closeProjectReqListErr(error.response.status));
    // }
  }
}

// disable Enable Artist
export function* ondisableEnableArtist(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        putAPI,
        `/backendUser/user-disable`,
        values
      );
      if (data.status === 200) {
        yield put(disableEnableArtistSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(closeProjectReqListErr(error.response.status));
    // }
  }
}
