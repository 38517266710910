import React, { Component } from "react";
import { connect } from "react-redux";
import { getArtistData } from "../../../store/action/clientList.action";
import { userProfileBaseUrl } from "../../../Utils/BaseUrl";
import ErrorApi from "../Common/Error/ErrorApi";

class ArtistOther extends Component {
  state = {
    clients: [],
    awardIs: [],
    DataIs: "",
    bankDetails : ''
  };

  handleGetArtist = (obj) => {
    this.props.$getArtistData(obj);
  };

  componentDidMount() {
    const artistParamsId = this.props.match.params.id.split(":").pop();
    this.handleGetArtist(artistParamsId);
  }

  componentWillReceiveProps(nextProps) {
    const data = nextProps._getArtistData.getCreatorData;
    const clientDataIs = data.clientData;
    const awards = data.userAwards;
    const userdata = data.userData;
    if (clientDataIs && userdata) {
      this.setState({
        clients: clientDataIs,
        awardIs: awards,
        DataIs: userdata,
        bankDetails: data && data.bankDetails
      });
    }
  }

  render() {
    const { awardIs, clients, DataIs,bankDetails } = this.state;
    const languageData = DataIs.languages;

    return this.props._getArtistData.getArtistError ? (
      <ErrorApi {...this.props} />
    ) : (
      <React.Fragment>
        {awardIs.length || clients.length || DataIs.length ? (
          <section className="container">
            <div className="row">
              {/*----------------------Awards ------------------------*/}
              <div className="col-12 col-xl-12">
                {awardIs.length > 0 && (
                  <React.Fragment>
                    <label className="mb-4">Awards/Mentions</label>
                    <div className="card card-new">
                      <div className="card-body">
                        {awardIs.map((obj, index) => (
                          <React.Fragment key={index}>
                            <div className="row align-items-center">
                              <div className="col">
                                <h5 className="mb-0">
                                  {obj.award_name ? obj.award_name : obj.title}
                                </h5>
                                <small className="text-muted">{obj.type}</small>{" "}
                                <br />
                                <small className="text-muted">{obj.url}</small>
                              </div>
                              <div className="col-auto">
                                <small className="text-muted">
                                  {obj.awarder ? obj.awarder : obj.publication}
                                </small>
                              </div>
                            </div>

                            <hr />
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </React.Fragment>
                )}
                {awardIs.length > 0 && <hr className="my-4" />}
                {/*----------------------Languages ------------------------*/}
                {languageData && (
                  <div className="card card-new">
                    <div className="card-body">
                      {Array.isArray(languageData) && (
                        <div className="card-body">
                          <div className="row align-items-center">
                            <div className="col">
                              <h5 className="mb-0">Languages</h5>
                              {languageData.map((obj, index) => (
                                <small className="text-muted mr-2" key={index}>
                                  {obj}
                                  {obj ? `,` : ""}
                                </small>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/*--------------------------------client ---------------------------------*/}
                <hr className="my-4" />
                {clients && (
                  <div className="row">
                    <label className="col-12 mb-4">Clients</label>
                    {clients.map((obj, index) => (
                      <div className="col-3 text-center" key={index}>
                        <div className="card mb-3 card-new">
                          <div className="card-body">
                            <div className="col-auto">
                              <a href="#!" className="avatar avatar-sm">
                                <img
                                  src={
                                    obj.client_image
                                      ? userProfileBaseUrl + obj.client_image
                                      : require("../../../Assets/img/avatars/profiles/avatar-1.jpg")
                                  }
                                  alt="..."
                                  className="avatar-img rounded-circle"
                                />
                              </a>
                            </div>
                            <div className="col ml-n2 mt-2">
                              <small className="card-title mb-1 text-muted">
                                {obj.client_name
                                  ? obj.client_name
                                  : "No Client Name"}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {/*--------------------------------Bank details---------------------------------*/}
                {bankDetails &&
                <React.Fragment>
                <hr className="my-5"/>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <h6 className="text-uppercase text-center text-muted my-4">
                          Banking Details
                        </h6>

                        <div className="row no-gutters align-items-center justify-content-center">
                          <div className="col-auto">
                                <div className="h2 mb-0">{bankDetails && bankDetails.legal_name}</div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                              <small>Pan Number</small>{" "}
                              <small>{bankDetails && bankDetails.pan_card}</small>
                            </li>
                            <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                              <small>GstIn </small>{" "}
                              <small>{bankDetails && bankDetails.gst_number}</small>
                            </li>
                            <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                                <small>Address</small> <small>{bankDetails && bankDetails.address}</small>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-5"/>
                </div>
                </React.Fragment>}
              </div>
            </div>
          </section>
        ) : (
          "No Data Found"
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({ getArtistData }) => {
  return {
    _getArtistData: getArtistData,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $getArtistData: (values) => dispatchEvent(getArtistData(values)),
});
export default connect(mapState, mapDispatch)(ArtistOther);
