import React, { Component } from "react";
import { getPublicAPI } from "../../../store/api-interface";
import Loader from "../Common/Loader/Loader";
import ReactPaginate from "react-paginate";

class CollateralSearches extends Component {
    state = {
        loadingIs: false,
        dataList: [],
        totalPagesIs: 0,
        pageNumber: 1,
    };

    componentDidMount = () => {
        this.handleCollSearchList(this.state.pageNumber)
    }
     
    handleCollSearchList = (pageNumber) => {
        this.setState({LoadingIs:true})
        getPublicAPI(`/users/get-searches?page=${pageNumber}&limit=100`).then((res) => {
        if (res) {
            let data = res?.data?.data;
            console.log("res = ",data)
            this.setState({ dataList: data,totalPagesIs:res?.data?.totalPages,LoadingIs:false });
        }
        });
    }
    
    handlePageClick = (data) => {
        const selectedPage = data.selected + 1;
        this.setState({ pageNumber: selectedPage }, () => this.handleCollSearchList(this.state.pageNumber));
    };

    render() {  
        const {LoadingIs, totalPagesIs, dataList} = this.state;

        return (
            <React.Fragment>
                {/* <div style={{border:"1px solid #e3ebf6",borderRadius:"6.5px",marginBottom:"2rem"}}>
                    {LoadingIs ? (
                        <Loader />
                    ) : (
                        <>
                            <div className="row m-4" style={{maxHeight:"670px",minHeight:"140px",overflowY:"auto"}}>
                                {dataList.map((item, i) => (
                                    <div className="col-4 p-3" style={{border:"1px solid #6e84a3",borderRadius:"6.5px",backgroundColor:"#f0f6ff"}}>
                                        {item.key}
                                    </div> 
                                ))}   
                            </div>
                                
                        </>
                    )}
                </div> */}
                <section className="creative-field creative_wrapper">
                    <div className="container-custom mx-auto">
                    {
                    LoadingIs ? 
                        <Loader />
                            :
                        <div className="table-responsive mb-5">
                            <table className="table table-sm table-nowrap card-table">
                            <thead>
                                <tr>
                                    <th>
                                        Search
                                    </th>           
                                    <th>
                                        Date
                                    </th>
                                   <th>
                                        Name
                                    </th> 
                                </tr>
                            </thead>
                            <tbody className="list">
                                {dataList.map((item, index) => (
                                    <tr key={index}>
                                        <td className="orders-order">{ item?.key }</td>  
                                        <td className="orders-order">{ item?.createdAt?.split("T")[0] }</td>    
                                        <td className="orders-order">{ item?.userDetails ? item.userDetails?.first_name + " " +  item.userDetails?.last_name : "" }</td>
                                    </tr>
                                ))}
                            </tbody>
                            </table>
                        </div>
                    }
                    </div>
                </section> 

                {/******** pagination ************* */}

                {totalPagesIs > 0 && (
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={totalPagesIs}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default CollateralSearches;