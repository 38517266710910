import React, { Component } from "react";
import { connect } from "react-redux";
import {
  deleteArtist,
  tempResetPwd,
  rejectedArtist,
} from "../../../store/action/clientList.action";
import ArtistListingTable from "./ArtistListingTable";
import ReactPaginate from "react-paginate";
import ReviewStatusModal from "./ReviewStatusModal";
import ErrorApi from "../Common/Error/ErrorApi";

 class ArtistDeleted extends Component {
  state = {
    statusEmailIs: "",
    createArtistIs: false,
    artistIs: [],
    artistList: 1,
    ListLoading: false,
    artistActive: false,
    showAction: false,
    showActionIndex: "",
    showReviewPopUp: false,
    artistId: "",
    profileReviewStatus: "",
    reviewStatus: false,
    tabCounting: false,
    artistApproval: false,
    resetPwdActive: false,

    firstname: "",
    lastname: "",
    username: "",
    email: "",
    totalPages: 0,
  };

  /* -------------set state ------------*/

  setArtistState = (stateObj) => {
    this.setState(stateObj);
  };

  /* -------------set state ------------*/

  /* ----------------------onChange -----------------------*/

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        const { artistList, firstname, lastname, username, email } = this.state;
        let artist = {
          page: artistList,
          firstname: firstname,
          lastname: lastname,
          username: username,
          email: email,
        };
        this.props.$deleteArtist(artist);
      }
    );
  };

  /* ----------------------onChange -----------------------*/

  /*---------------------Pagination ---------------------------*/

  handlePageClick = (data) => {
    let pageSelecetd = data.selected + 1;
    const { firstname, lastname, username, email } = this.state;

    let artist = {
      page: pageSelecetd,
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
    };
    this.props.$deleteArtist(artist);
  };

  /*---------------------Pagination ---------------------------*/
  /* -------------Review dropdown show hide ------------*/

  handleShowAction = (index) => {
    const { showAction } = this.state;
    this.setState({
      showAction: !showAction,
      showActionIndex: index,
    });
  };

  handleHideAction = () => {
    this.setState({
      showAction: true,
    });
  };

  /* -------------Review dropdown show hide------------*/

  /* -------------Review accept/reject selection------------*/

  handleReviewStatus = (obj, pStatus) => {
    if (pStatus === "accepted") {
      this.setState({
        profileReviewStatus: 2,
        reviewStatus: true,
      });
    } else if (pStatus === "rejected") {
      this.setState({
        profileReviewStatus: 3,
        reviewStatus: false,
      });
    }
    this.setState({
      showReviewPopUp: true,
      artistId: obj._id,
    });
  };

  /* -------------Review accept/reject selection------------*/

  /*--------------Reset temp Pwd ------------------*/
  handleGetConfirmation(id) {
    let confirmIs = window.confirm(
      `Do you want to reset password temporary (for 15 min) ?`
    );
    if (confirmIs === true) {
      this.setState({
        resetPwdActive: true,
      });
      const tempResetPwd = { _id: id };
      this.props.$tempResetPwd(tempResetPwd);
      return true;
    } else {
      return false;
    }
  }

  handleResetTemproryPassword = (id) => {
    this.handleGetConfirmation(id);
  };
  /*--------------Reset temp Pwd ------------------*/

  /* ------------------get Artist Data -----------------------*/

  handleArtistPagination = () => {
    this.setState({
      ListLoading: true,
    });
    const { artistList, firstname, lastname, username, email } = this.state;
    let artist = {
      page: artistList,
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
    };
    this.props.$deleteArtist(artist);
  };

  /* ------------------get Artist Data -----------------------*/

  componentDidMount() {
    // this.props.handleClients.handlepathName("/artist");
    this.setState({
      createArtistIs: true,
      artistActive: true,
      artistApproval: false,
    });
    this.handleArtistPagination();
    // this.props.handleClients.mainIndexstate({
    //   profileUpdates: false,
    //   artistActiveIs: true,
    //   artistDeleted: true,    
    //   artistRejected: false  
    // });
  }

  componentWillReceiveProps(nextProps) {
    const { resetPwdActive } = this.state;
    const artistListData = nextProps._deleteArtist.DeleteArtistIs;
    const tempResetPwdResponse = nextProps._tempResetPwd.tempResetPwd;
    if (artistListData) {
      this.setState({
        artistIs: artistListData.users,
        ListLoading: false,
        tabCounting: true,
        totalPages: artistListData.totalPages,
      });
    }
    if (tempResetPwdResponse.status === 1 && resetPwdActive) {
      alert(tempResetPwdResponse.message);
      this.setState({
        resetPwdActive: false,
      });
    }
  }

  render() {
    const { showReviewPopUp } = this.state;

    const artistFunc = {
      handleOnChange: this.handleOnChange,
      handleShowAction: this.handleShowAction,
      handleReviewStatus: this.handleReviewStatus,
      handleResetTemproryPassword: this.handleResetTemproryPassword,
    };
    return (
      <React.Fragment>
        {this.props._deleteArtist.DeleteArtistErrIs ? 
          <ErrorApi {...this.props} />
          :
        <section className="client">
          <div className="container-fluid mx-auto">
            {/*--------------------- Artist data Table ----------------------*/}
            <ArtistListingTable
              {...this.state}
              {...this.props}
              handleArtist={artistFunc}
            />

            {/*------------------------- Pagination -----------------------*/}

            {this.state.totalPages > 0 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )}

            {/*-------------------------------Review Popup ---------------------------------*/}

            {showReviewPopUp && (
              <ReviewStatusModal
                setArtistState={this.setArtistState}
                {...this.state}
              />
            )}    
          </div>
        </section>}
      </React.Fragment>
    );
  }
}
const mapState = ({ deleteArtist, tempResetPwd, rejectedArtist }) => {
    return {
      _deleteArtist: deleteArtist,
      _tempResetPwd: tempResetPwd,
      _rejectedArtist: rejectedArtist,
    };
  };
  const mapDispatch = (dispatchEvent) => ({
    $deleteArtist: (values) => dispatchEvent(deleteArtist(values)),
    $tempResetPwd: (values) => dispatchEvent(tempResetPwd(values)),
    $rejectedArtist: (values) => dispatchEvent(rejectedArtist(values)),
  });
  export default connect(mapState, mapDispatch)(ArtistDeleted);