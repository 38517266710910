import React, { Component } from "react";
import { profileBaseUrl } from "../../../../Utils/BaseUrl";

export default class CallBackQuestionForm extends Component {
  state = {
    question: "",
    questiontype: "",
    subtext: "",
    answerIs: [],
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidMount() {
    const { questionDataIs } = this.props;
    if (questionDataIs) {
      this.setState({
        question: questionDataIs.question ? questionDataIs.question : '',
        questiontype: questionDataIs.type ? questionDataIs.type : '',
        subtext: questionDataIs.subtext ? questionDataIs.subtext : '',
        answerIs: questionDataIs.answer,
      });
    }
  }

  render() {
    const { question, questiontype, subtext, answerIs } = this.state;
    const dataArr = [
      { name: "Question", value: question, label: "Question" },
      { name: "questiontype", value: questiontype, label: "Question Type" },
      {
        name: "subtext",
        value: subtext,
        label: "Subtext",
      },
    ];

    return (
      <React.Fragment>
        <div className="questin-Listing p-5">
          <div className="row">
            {dataArr.map((obj, index) => (
              <div className="col-12 col-md-6" key={index}>
                <div className="form-group">
                  <label>{obj.label}</label>
                  <input
                    type="text"
                    className="form-control"
                    name={obj.name}
                    value={obj.value}
                    onChange={this.handleOnChange}
                    disabled="disabled"
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            {answerIs.length ? answerIs.map((obj, index) => (
              <div className="col-4 questin-Listing mr-2" key={index}>
                <div className="row">
                  <div className="col-9">
                    <p className="mb-0">{obj.item}</p>
                  </div>
                  <div className="col-3">
                    {obj.image ? (
                      <img
                        src={profileBaseUrl + obj.image}
                        height="25"
                        width="25"
                        alt="..."
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )) : "No data Found.."}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
