import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import linkLogo from "../../../../Assets/img/images.png";
import defaultIcon from "../../../../Assets/img/video-thumbnail-default.png";

const AsyncImage = ({ imageurl, className }) => {
  const [image, setImage] = React.useState(null);

  const getSketchfabThumbnail = async (videoCode) => {
    const res = await axios.get(
      `https://api.sketchfab.com/v3/models/${videoCode}`
    );
    const thumbnailImg = await res.data.thumbnails.images[0].url;
    setImage(thumbnailImg);
  };

  const getVimeoThumbnail = async (videoCode) => {
    const res = await axios.get(
      `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoCode}`
    );
    setImage(res.data.thumbnail_url);
  };

  React.useEffect(() => {
    const getThumbnailVideo = (value) => {
      const avilable_provider = [
        {
          name: "google",
          thumbnail: `https://drive.google.com/thumbnail?id=${
            value.split("/").slice(0)[5]
          }`,
        },
        {
          name: "vimeo",
          thumbnail: false,
        },
        {
          name: "youtube",
          thumbnail: `https://img.youtube.com/vi/${
            value.includes("watch?v=") && value.includes("&")
              ? value.split("&")[0].split("watch?v=")[1]
              : value.split("embed/").slice(0)[1]
          }/mqdefault.jpg`,
        },
        {
          name: "dailymotion",
          thumbnail: `https://www.dailymotion.com/thumbnail/video/${
            value.split("/").slice(0)[5]
          }`,
        },
        {
          name: "https://sketchfab",
          thumbnail: false,
        },
        {
          name: "adobe",
          thumbnail: defaultIcon,
        },
      ];

      const provider = avilable_provider.filter(function (obj) {
        return value.split(".").indexOf(obj.name) !== -1;
      })[0];

      if (provider && provider.name === "vimeo") {
        getVimeoThumbnail(imageurl.split("/").slice(0)[4]);
      } else if (provider && provider.name === "https://sketchfab") {
        getSketchfabThumbnail(imageurl.split("/").slice(0)[4]);
      } else {
        setImage(provider && provider.thumbnail ? provider.thumbnail : "");
      }
    };
    getThumbnailVideo(imageurl);
  }, [imageurl]);

  return (
    <React.Fragment>
      {image ? (
        <img
          src={image}
          className={className ? className : "avatar-img rounded"}
          width={className ? "" : 100}
          height={className ? "" : 20}
          alt="..."
        />
      ) : (
        <img
          src={linkLogo}
          className="avatar-img rounded"
          width={className ? "" : 100}
          height={className ? "" : 20}
          alt="..."
        />
      )}
    </React.Fragment>
  );
};

export default AsyncImage;

AsyncImage.propTypes = {
  imageurl: PropTypes.string,
};
