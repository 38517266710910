
export const validationForm = (name, value, props) => {
    const { errorIs, minBudget, selectedOption } = props;
    switch (name) {
      case "minBudget":
        errorIs.minBudget = !value.length > 0 ? "Required Field" : "";
        break;
      case "maxBudget":
        errorIs.maxBudget =
          !value.length > 0
            ? "Required Field"
            : Number(minBudget) > Number(value)
            ? "Max Budget will be grater from min budget"
            : "";
        break;
      case "project":
        errorIs.project = !value.length > 0 ? "Required Field" : "";
        break;
      case "projectDescription":
        errorIs.projectDescription = !value.length > 0 ? "Required Field" : "";
        break;
      case "unit":
        errorIs.unit =
          selectedOption.pricing_unit === "per_unit" && !value.length > 0
            ? "Required Field"
            : "";
        break;
      case "companyJob":
        errorIs.companyJob = !value.length > 0 ? "Required Field" : "";
        break;
      case "companyLink":
        const isLink = /[(http(s)?):(www)?a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/.test(
          value
        );
        errorIs.companyLink =
          !value.length > 0
            ? "Required Field"
            : !isLink
            ? "Please Enter Valid Url"
            : "";
        break;
      case "companyDesc":
        errorIs.companyDesc = !value.length > 0 ? "Required Field" : "";
        break;
      default:
        break;
    }
  };

  export const validation = (props) => {
    const {
      errorIs,
      minBudget,
      maxBudget,
      project,
      projectDescription,
      unit,
      selectedOption,
      companyType,
      companyJob,
      companyLink,
      companyDesc,
      project_type
    } = props;
    const isLink = /[(http(s)?):(www)?a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/.test(
      companyLink
    );
    errorIs.minBudget = !minBudget > 0 ? "Required Field" : "";
    errorIs.maxBudget =
      !maxBudget > 0
        ? "Required Field"
        : Number(minBudget) > Number(maxBudget)
        ? "Max Budget will be grater from min budget"
        : "";
    errorIs.project = !project.length > 0 ? "Required Field" : "";
    errorIs.projectDescription =
      !projectDescription.length > 0 ? "Required Field" : "";
    errorIs.unit = project_type=='fixed'?
      selectedOption.pricing_unit === "per_unit" && !unit > 0
        ? "Required Field"
        : "":"";
    if (companyType === "Company" || companyType === "Agency") {
      errorIs.companyJob = !companyJob.length > 0 ? "Required Field" : "";
      errorIs.companyLink =
        !companyLink.length > 0
          ? "Required Field"
          : !isLink
          ? "Please Enter Valid Url"
          : "";
      errorIs.companyDesc = !companyDesc.length > 0 ? "Required Field" : "";
    }
    // this.setState({ errorIs });
    return (
      !errorIs.minBudget &&
      !errorIs.maxBudget &&
      !errorIs.project &&
      !errorIs.projectDescription &&
      !errorIs.unit &&
      !errorIs.companyName &&
      !errorIs.companyJob &&
      !errorIs.companyLink &&
      !errorIs.companyDesc
    );
  };