import React, { Component } from "react";

export default class ClientDetailVerifiedStatus extends Component {
  render() {

    const { emailStatus, handleFunc } = this.props;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="mb-1">Email verified</label>
              <small className="form-text text-muted">
                Showing your Email status.
              </small>

              <div className="row col">
                <div className="col ml-n2">
                  <small className="text-muted">
                    <input
                      type="radio"
                      checked={emailStatus === "1"}
                      name="emailStatus"
                      value="1"
                      onChange={handleFunc.handleOnChange}
                    />{" "}
                    Yes
                  </small>
                </div>
                <div className="col ml-n2">
                  <small className="text-muted">
                    <input
                      type="radio"
                      name="emailStatus"
                      checked={emailStatus === "0"}
                      value="0"
                      onChange={handleFunc.handleOnChange}
                    />{" "}
                    No
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
