import React, { Component } from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    width:'20%',

  },
};

class NotesModal extends Component {
  
  render() {
    const {
        notesModalOpen,
        notesModalContent
    } = this.props;
    
    return (
      <React.Fragment>
        <div>
  
          <Modal
            isOpen={notesModalOpen}
            style={customStyles}
            contentLabel="Example Modal"
          >
              
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.props.closeNotesModal}
              >
              <span
                aria-hidden="true"
                style={{ color: "black", fontSize: "32px" }}
                >
                &times;
              </span>
            </button>
            <br/>
            {/* <hr className="d-block"/> */}
            
               <div  >
                <textarea className="form-control" rows={notesModalContent?.length>70?8:5} disabled 
                value={notesModalContent} 
                // value={
                //   (typeof notesModalContent) === 'string'?
                //   notesModalContent
                //   :
                //   (typeof notesModalContent) === 'object'?
                //   notesModalContent.map(obj=>obj.name).join('\n')
                //   :""
                // }
                />
              </div>
              
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export default NotesModal;



