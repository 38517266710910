import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { collateralList } from "../../../store/action/collateralList.action";
import DashboardCollateral from './DashboardCollateral';
import DashboardProject from './DashboardProject';
import DashboardCardInfo from './DashboardCardInfo';
import ErrorApi from "../Common/Error/ErrorApi";
// import Chart from "chart.js";

 class Dashboard extends Component {

  render() {   
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-12 col-xl-12">
              <div className="card">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h4 className="card-header-title">Orders</h4>
                    </div>
                    <div className="col-auto mr-n3">
                      <span className="text-muted">Show affiliate:</span>
                    </div>
                    <div className="col-auto">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="cardToggle"
                          data-toggle="chart"
                          data-target="#ordersChart"
                          data-add='{"data":{"datasets":[{"data":[15,10,20,12,7,0,8,16,18,16,10,22],"backgroundColor":"#d2ddec","label":"Affiliate"}]}}'
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="cardToggle"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="chart">
                    <canvas id="ordersChart" className="chart-canvas"></canvas>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-12 col-xl-4">

            <div className="card">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col">

                    <h4 className="card-header-title">
                      Devices
                    </h4>

                  </div>
                  <div className="col-auto">
                    <ul className="nav nav-tabs nav-tabs-sm card-header-tabs">
                      <li className="nav-item" data-toggle="chart" data-target="#devicesChart" data-update='{"data":{"datasets":[{"data":[60,25,15]}]}}'>
                        <a href="#" className="nav-link active" data-toggle="tab">
                          All
                        </a>
                      </li>
                      <li className="nav-item" data-toggle="chart" data-target="#devicesChart" data-update='{"data":{"datasets":[{"data":[15,45,20]}]}}'>
                        <a href="#" className="nav-link" data-toggle="tab">
                          Direct
                        </a>
                      </li>
                    </ul>

                  </div>
                </div> 

              </div>
              <div className="card-body">
                <div className="chart chart-appended">
                  <canvas id="devicesChart" className="chart-canvas" data-toggle="legend" data-target="#devicesChartLegend"></canvas>
                </div>
                <div id="devicesChartLegend" className="chart-legend"></div>

              </div>
            </div>
            
          </div> */}
          </div>
          {/*-------------------------dashboard card info----------------------------*/}

          <div className="row">
            <DashboardCardInfo />                    
          </div>

          {/*-------------------------dashboard Card info ----------------------------*/}

          <div className="row">
            {/*-------------------------dashboard Project ----------------------------*/}
            <div className="col-12 col-xl-4">
              <div className="card">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h4 className="card-header-title">Projects</h4>
                    </div>
                    <div className="col-auto">
                      <Link to="/projects" className="small">
                        View all
                      </Link>
                    </div>
                  </div>
                </div>
                <DashboardProject />
              </div>
            </div>
            {/*-------------------------dashboard Project ----------------------------*/}

            {/*----------------dashboard collateral----------------*/}
            <div className="col-12 col-xl-8">
              <div className="card">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h4 className="card-header-title">Collaterals</h4>
                    </div>
                    <div className="col-auto">
                      <Link to="/collaterals" className="btn btn-sm btn-white">
                        View all
                      </Link>
                    </div>
                  </div>
                </div>
                {this.props._collateralList.collateralListErrorIs && (
                    <ErrorApi {...this.props} />
                )}
                <DashboardCollateral />
              </div>
            </div>
            {/*----------------dashboard collateral----------------*/}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapState = ({ collateralList }) => {
  return {
    _collateralList: collateralList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $collateralList: (values) => dispatchEvent(collateralList(values)),
});
export default connect(mapState, mapDispatch)(Dashboard);