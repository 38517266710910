import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getPendingProjectData, getProjcetReviewData } from "../../../../store/action/projectList.action";
import UploadMedia from "../ProjectUpload/UploadMedia";

class ViewProjectReview extends Component {
  state = {
    firstName: "",
    lastName: "",
    username: "",
    project: "",
    reviewer: "",
    email: "",
    reviewerCompany: "",
    reviewJob: "",
    reviewDate: "",
    feedback: "",
    reviewRating: "",
    creatorEmail: '',
    ModalData : [],
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleViewProjectReview = (id) => {
    this.props.$getProjcetReviewData(id);
  };

  componentDidMount() {
    const collateralParamsId = this.props.match.params.id.split(":").pop();
    this.handleViewProjectReview(collateralParamsId);
  }

  componentWillReceiveProps(nextProps) {
    const dataIs = nextProps._getProjcetReviewData.projectReviewDataIs;
    const projectData = nextProps._getPendingProjectData.getPendingData;
    // console.log(projectData)
    if (dataIs) {
      this.setState({
        firstName:  dataIs?.userData?.first_name ,
        lastName:  dataIs?.userData?.last_name,
        username:  dataIs?.userData?.username,
        creatorEmail: dataIs?.userData?.email,
        project:  dataIs?.project?.title,
        reviewer: dataIs?.projectReview?.name ,
        email: dataIs?.projectReview?.email,
        reviewerCompany: dataIs.projectReview
          ? dataIs.projectReview.company_name
          : "",
        reviewJob: dataIs.projectReview ? dataIs.projectReview.designation : "",
        reviewDate: dataIs?.projectReview && dataIs?.projectReview?.createdAt
          ? moment(dataIs.projectReview.createdAt).format("DD/MM/YYYY")
          : "",
        feedback: dataIs.projectReview ? dataIs.projectReview.feedback : "",
        reviewRating: dataIs.projectReview ? dataIs.projectReview.review : "",
        ModalData : projectData ? projectData : []
      });
    }
  }

  handleViewProject = (e) => {
    e.preventDefault();
    const data = this.props._getProjcetReviewData.projectReviewDataIs
    // console.log(data)
    const { showModalIs } = this.state;
    this.setState({ showModalIs: !showModalIs});
    this.props.$getPendingProjectData(data.project._id);
  }

  handleCloseMediaModal = () => {
    this.setState({ showModalIs: false });
  };

  render() {
    const {
      firstName,
      lastName,
      username,
      project,
      reviewer,
      email,
      reviewerCompany,
      reviewJob,
      reviewDate,
      feedback,
      reviewRating,
      creatorEmail
    } = this.state;
    const dataArr = [
      { name: "firstName", value: firstName, label: "First Name" },
      { name: "lastName", value: lastName, label: "Last Name" },
      {
        name: "username",
        value: username,
        label: "User Name",
      },
      {
        name: "creatorEmail",
        value: creatorEmail,
        label: "Creator Email",
      },
      { name: "project", value: project, label: "Project" },
      { name: "reviewer", value: reviewer, label: "Reviewer Name" },
      { name: "email", value: email, label: "Reviewer Email" },
      {
        name: "reviewerCompany",
        value: reviewerCompany,
        label: "Reviewer Company",
      },
      { name: "reviewJob", value: reviewJob, label: "Review Job Role" },
      { name: "reviewDate", value: reviewDate, label: "Review Date" },
    ];
    const allFunc = {
      handleCloseMediaModal : this.handleCloseMediaModal,
    };
    return (
      <React.Fragment>
        <section className="container">
          <div className="row">
            {dataArr.map((obj, index) => (
              <div className="col-12 col-md-6" key={index}>
                <div className="form-group">
                  <label>{obj.label}</label>
                  <input
                    type="text"
                    className="form-control"
                    name={obj.name}
                    value={obj.value}
                    onChange={this.handleOnChange}
                    disabled="disabled"
                  />
                </div>
              </div>
            ))}
            <div className="col-12 ">
              <div className="form-group">
                <label>Reviewer Comment</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="feedback"
                  value={feedback}
                  onChange={this.handleOnChange}
                  disabled="disabled"
                />
              </div>
            </div>
            {/*---------------------Review Rating --------------------------*/}
            <div className="col-12 col-md-12 row">
              <div className="col-9">
                <div className="form-group">
                  <label className="mb-1">Review</label>
                  <div>
                    <span
                      className={`mr-3 ${
                          reviewRating ? `fa fa-star checked` : `fa fa-star-o`
                      }`}
                      value="2"
                    ></span>
                    <span
                      className={`mr-3 ${
                          reviewRating === 2 ||
                          reviewRating === 3 ||
                          reviewRating === 4 ||
                          reviewRating === 5
                          ? `fa fa-star checked`
                          : `fa fa-star-o`
                      }`}
                      value="2"
                    ></span>
                    <span
                      className={`mr-3 ${
                          reviewRating === 3 ||
                          reviewRating === 4 ||
                          reviewRating === 5
                            ? `fa fa-star checked`
                            : `fa fa-star-o`
                        }`}
                      value="3"
                    ></span>
                    <span
                      className={`mr-3 ${
                          reviewRating === 4 ||
                          reviewRating === 5
                            ? `fa fa-star checked`
                            : `fa fa-star-o`
                        }`}
                      value="4"
                    ></span>
                    <span
                      className={`mr-3 ${
                          reviewRating === 5
                            ? `fa fa-star checked`
                            : `fa fa-star-o`
                        }`}
                      value="5"
                    ></span>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <Link to='/' onClick={this.handleViewProject}>View Project</Link>
              </div>          
            </div>
          </div>
          {this.state.showModalIs && <UploadMedia {...this.state} {...this.props} handleFunction={allFunc}/> }
        </section>
      </React.Fragment>
    );
  }
}
const mapState = ({ getProjcetReviewData, getPendingProjectData }) => {
  return {
    _getProjcetReviewData: getProjcetReviewData,
    _getPendingProjectData: getPendingProjectData,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $getProjcetReviewData: (values) =>
    dispatchEvent(getProjcetReviewData(values)),
  $getPendingProjectData: (values) =>
    dispatchEvent(getPendingProjectData(values))
});
export default connect(mapState, mapDispatch)(ViewProjectReview);
