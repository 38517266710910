import React, { Component } from "react";

export default class ProjectStatus extends Component {
  render() {
      const{ projectCompDate } = this.props;
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col">
                <h5 className="mb-0">Project Completed</h5>
              </div>
              <div className="col-auto">
                <time className="small text-muted">
                  { projectCompDate?.split("T").slice(0)[0]}
                </time>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
