import {
  PROJECT_LIST,
  PROJECT_LIST_SUCCESS,
  PENDING_PROJECT_LIST,
  PENDING_PROJECT_LIST_SUCCESS,
  GET_PENDING_PROJECT_DATA,
  GET_PENDING_PROJECT_DATA_SUCCESS,
  PROJECT_REVIEW_LIST,
  PROJECT_REVIEW_LIST_SUCCESS,
  GET_PROJECT_REVIEW_DATA,
  GET_PROJECT_REVIEW_DATA_SUCCESS,
  PENDING_PROJECT_LIST_ERROR,
  GET_PROJECT_REVIEW_DATA_ERROR,
  ALL_COLLATERAL_LIST,
  ALL_COLLATERAL_LIST_SUCCESS,
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  ADD_POST_PROJECT,
  ADD_POST_PROJECT_SUCCESS,
  GET_POST_PROJECT_DATA,
  GET_POST_PROJECT_DATA_SUCCESS,
  ADD_PROPOSAL,
  ADD_PROPOSAL_SUCCESS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROPOSAL,
  UPDATE_PROPOSAL_SUCCESS,
  ADD_PROPOSAL_CYCLE,
  ADD_PROPOSAL_CYCLE_SUCCESS,
  EDIT_PROPOSAL_CYCLE,
  EDIT_PROPOSAL_CYCLE_SUCCESS,
  DELETE_PROPOSAL_CYCLE,
  DELETE_PROPOSAL_CYCLE_SUCCESS
} from "../type.action";

// Project listing & Pagination***

export const projectList = (values) => ({
  type: PROJECT_LIST,
  values,
});

export const projectListSuccess = (values) => ({
  type: PROJECT_LIST_SUCCESS,
  values,
});

// pending Project List (Approvals)
export const pendingProjectList = (values) => ({
  type: PENDING_PROJECT_LIST,
  values,
});
export const pendingProjectListSuccess = (values) => ({
  type: PENDING_PROJECT_LIST_SUCCESS,
  values,
});
export const pendingProjectListError = (error) => ({
  type: PENDING_PROJECT_LIST_ERROR,
  error,
});

// get pending project data /based on id (Approvals)

export const getPendingProjectData = (values) => ({
  type: GET_PENDING_PROJECT_DATA,
  values,
});
export const getPendingProjectDataSuccess = (values) => ({
  type: GET_PENDING_PROJECT_DATA_SUCCESS,
  values,
});

// project Review List (Approvals)
export const projectReviewList = (values) => ({
  type: PROJECT_REVIEW_LIST,
  values,
});
export const projectReviewListSuccess = (values) => ({
  type: PROJECT_REVIEW_LIST_SUCCESS,
  values,
});

// get Project review based on id (Approvals)
export const getProjcetReviewData = (values) => ({
  type: GET_PROJECT_REVIEW_DATA,
  values,
});
export const getProjectReviewDataSuccess = (values) => ({
  type: GET_PROJECT_REVIEW_DATA_SUCCESS,
  values,
});
export const getProjectReviewDataError = (error) => ({
  type: GET_PROJECT_REVIEW_DATA_ERROR,
  error,
});

// get all collaterals (Transaction tab)
export const AllCollateralList = (values) => ({
  type: ALL_COLLATERAL_LIST,
  values,
});
export const AllCollateralListSuccess = (values) => ({
  type: ALL_COLLATERAL_LIST_SUCCESS,
  values,
});

// get creator, client , representative (transaction tab)
export const getUserList = (values) => ({
  type: GET_USER_LIST,
  values,
});
export const getUserListSuccess = (values) => ({
  type: GET_USER_LIST_SUCCESS,
  values,
});

// add Project (transactions tab)
export const addPostProject = (values) => ({
  type: ADD_POST_PROJECT,
  values,
});
export const addPostProjectSuccess = (values) => ({
  type: ADD_POST_PROJECT_SUCCESS,
  values,
});

// get project data based on id (transaction tab)
export const getPostProjectData = (values) => ({
  type: GET_POST_PROJECT_DATA,
  values,
});
export const getPostProjectDataSuccess = (values) => ({
  type: GET_POST_PROJECT_DATA_SUCCESS,
  values,
});

// add proposal (transaction tab)
export const addProposal = (values) => ({
  type: ADD_PROPOSAL,
  values,
});
export const addproposalSuccess = (values) => ({
  type: ADD_PROPOSAL_SUCCESS,
  values,
});

// update project Details (transaction tab)
export const updateProject = (values) => ({
  type: UPDATE_PROJECT,
  values
})
export const updateProjectSuccess = (values) => ({
  type: UPDATE_PROJECT_SUCCESS,
  values
})

// update Proposal Details (transcation tab)
export const updateProposal = (values) => ({
  type: UPDATE_PROPOSAL,
  values
})
export const updateProposalSuccess = (values) => ({
  type: UPDATE_PROPOSAL_SUCCESS,
  values
})

// add ongoing proposal cycle
export const addCycleProposal = (values) =>({
  type:ADD_PROPOSAL_CYCLE,
  values
})
export const addCycleProposalSuccess = (values) =>({
  type:ADD_PROPOSAL_CYCLE_SUCCESS,
  values
})

// delete ongoing proposal cycle
export const deleteCycleProposal = (values) =>({
  type:DELETE_PROPOSAL_CYCLE,
  values
})
export const deleteCycleProposalSuccess = (values) =>({
  type:DELETE_PROPOSAL_CYCLE_SUCCESS,
  values
})

// edit ongoing proposal cycle
export const editCycleProposal = (values) =>({
  type:EDIT_PROPOSAL_CYCLE,
  values
})
export const editCycleProposalSuccess = (values) =>({
  type:EDIT_PROPOSAL_CYCLE_SUCCESS,
  values
})