import React, { Component } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import {
  transactionGetData,
  deletePendingCreator,
  approvedProposal,
  approvedProposalJob,
  transactionGetDataSuccess
} from "../../../store/action/transaction.action";
import { artistDataList } from "../../../store/action/clientList.action";
import { userProfileBaseUrl, WebsiteUrl } from "../../../Utils/BaseUrl";
import ProposalModal from "./ProposalModal";
// import AddMultipleCreator from "./AddCreator/AddMultipleCreator";
import EditClient from "./EditCilent";
import Manager from "./Manager/Manager";

import RejectReasonModal from "./RejectReasonModal";
import { getPublicAPI, putAPI } from "../../../store/api-interface";
import ChatModal from "./chatModal";
import QuestionModal from "../Common/ModalCommon/QuestionModal";

class TransactionUserDetails extends Component {
  state = {
    clientData: "",
    creatorData: [],
    options: [],
    showProposalModal: false,
    MilesData: "",
    projectDataIs: "",
    projectStatusIs: "",
    deletedLoader: false,
    projectId: "",
    collateralId: "",
    clientId: "",
    creatorCountIs: "",
    transactionsStatus: 0,
    outSideUser: [],
    approvedCreatorData: [],
    approvedCreatorDataJob: [],
    selectedCreator: "",
    selectedCreatorJob: "",
    creatorLoader: false,
    acceptedLoader:false,
    creatorLoaderJob: false,
    modalClientOpen: false,
    modalManagerOpen: false,
    activeProposals: 0,
    rejectData: { user_id2: {} },
    managerList: [],
    client_modal_id: "",
    creator_modal_id: "",
    post_project_modal_id: "",
    showChatModal: false,
    type: "",
    loc:"client/creator",
    approveQuestionsLoader: false,
    approveQuestions: [],
    showQuestionsModal: false,
    QuestionsModalData:[],
    creatorListType:'',
    acceptedCreatorData:[],

    approvedCreators:[],
    approvedQuestions:[],

    manager_modal_id:{manager_id:'',manager_name:''}
  };

  handleEditManager = () => {
    this.setState({ modalManagerOpen: true });
  };

  handleCloseManagerModal = () => {
    this.setState({ modalManagerOpen: false });
  };

  handleEditClient = () => {
    this.setState({
      modalClientOpen: true,
    });
  };

  handleCloseClintModal = () => {
    this.setState({
      modalClientOpen: false,
    });
  };

  handleOnChange = (obj, checkedIs, checkJob,listType) => {
    const { approvedCreatorData, approvedCreatorDataJob, acceptedCreatorData,approveQuestions, creatorData } = this.state;
    if (checkedIs && obj) {
      const projectId = this.props.match.params.id.split(":")[1];
      const DataIs = {
        post_project_id: projectId,
        user_id: obj?.user_id2?._id,
        proposal_id: obj?.proposal?.proposal?._id,
        note: obj?.proposal?.proposal?.note,
      };
      let chooseData = checkJob ? approvedCreatorDataJob : listType=='accepted'? approvedCreatorData:acceptedCreatorData;
      chooseData.push(DataIs);
      const currentData = { data: chooseData };
      if (checkJob) {
        this.setState({
          approvedCreatorDataJob: chooseData,
          selectedCreatorJob: currentData,
   
        });
      } else {
        if(listType==='accepted')
        this.setState({
          approvedCreatorData: chooseData,
          selectedCreator: currentData,
      
        });
        else if(listType==='approved')
        this.setState({
          acceptedCreatorData:chooseData,
          selectedCreator:currentData,

        })
      }
    } else {
      let dataFil = checkJob ? approvedCreatorDataJob : listType==='accepted'? approvedCreatorData:acceptedCreatorData;
      const remainData = dataFil?.filter(
        (el) => el.user_id !== obj.user_id2._id
      );
      const currentData = { data: remainData };
      if (checkJob) {
        this.setState({
          approvedCreatorDataJob: remainData,
          selectedCreatorJob: currentData,
        });
      } else {
        if(listType==='accepted'){
        this.setState({
          approvedCreatorData: remainData,
          selectedCreator: currentData,
        });}
        else if(listType === 'approved')
        this.setState({
          acceptedCreatorData:remainData,
          selectedCreator:currentData,
        })
      }
    }
  };

  handleApprovedProposal = () => {
    let {approvedCreatorData,creatorData} = this.state;
    let apcreatorData = approvedCreatorData?.map(el=>el.user_id)
    let approvedCreators = creatorData.filter(
    (obj) => (obj?.status === 1 && obj?.proposal &&  obj?.proposal?.proposal?.status !== 4))
    let QuestionsApprovedCreatorTemp = creatorData?.filter((obj)=>{if(obj?.askQuestion?.messages?.length > 0 && obj?.askQuestion?.status != 0 && (obj.status !== 2 && obj.status != 4)) return obj })
    let QuestionsApprovedCreator = QuestionsApprovedCreatorTemp?.map(el=>el?.user_id2?._id)
    let approvedCreatorsIds = approvedCreators?.map(el=>el.user_id2?._id)
  
    let combinedArr = [...apcreatorData,...approvedCreatorsIds,...QuestionsApprovedCreator]
    // console.log(Array.from(new Set(combinedArr)),Array.from(new Set(combinedArr)).length)
    if((Array.from(new Set(combinedArr))).length>3){
      alert('you can select upto three creators only')
      
      return ;
    }
    
    const { selectedCreator } = this.state;
    this.setState({ creatorLoader: true });
    this.props.$approvedProposal(selectedCreator);
    this.componentDidMount()
  };

  handleApprovedProposalJob = () => {

    let {creatorData,approvedCreatorDataJob} = this.state;
    let apcreatorData = approvedCreatorDataJob?.map(el=>el.user_id)
    let approvedCreators = creatorData.filter(
    (obj) => (obj?.status === 1 && obj?.proposal && obj?.proposal?.proposal?.status !== 4))
    let QuestionsApprovedCreatorTemp = creatorData?.filter((obj)=>{if(obj?.askQuestion?.messages?.length > 0 && obj?.askQuestion?.status != 0 && (obj.status !== 2 && obj.status != 4)) return obj })
    let QuestionsApprovedCreator = QuestionsApprovedCreatorTemp?.map(el=>el?.user_id2?._id)
    let approvedCreatorsIds = approvedCreators?.map(el=>el.user_id2?._id)
      
    let combinedArr = [...approvedCreatorsIds,...QuestionsApprovedCreator,...apcreatorData]
    // console.log(Array.from(new Set(combinedArr)),Array.from(new Set(combinedArr)).length)
    if((Array.from(new Set(combinedArr))).length>3){
      alert('you can select upto three creators only')
      
      return ;
    }
    const { selectedCreatorJob } = this.state;
    this.setState({ creatorLoaderJob: true });
    this.props.$approvedProposalJob(selectedCreatorJob);
  };

  handleTransactionData = (obj) => {
    // const payload={obj,admin:false}
    this.props.$transactionGetData(obj, { admin: false });
  };

  handleViewProposal = (obj, type) => {
    this.setState({ showProposalModal: true, MilesData: obj, type });
  };

  handleCloseProposalModal = () => {
    this.setState({ showProposalModal: false });
  };

  handleQuestionData = (obj,status) => {
    this.setState({ showQuestionsModal: true, QuestionsModalData: obj, questionStatus:status });
  };

  handleChooseCreator = () => {
    let artist = {
      page: 1,
      firstname: "",
      lastname: "",
      username: "",
      email: "",
    };
    this.props.$artistDataList(artist);
  };

  getDeleteConfirmation = (id) => {
    let confirmIs = window.confirm(`Do you want to continue ?`);
    if (confirmIs === true) {
      this.setState({ deletedLoader: true });
      const projectId = this.props.match.params.id.split(":").slice(0)[1];
      const currentData = { _id: projectId, user_id: id };
      this.props.$deletePendingCreator(currentData);
      return true;
    } else {
      return false;
    }
  };

  getDeleteCreator = (obj) => {
    if (obj.status === 0) {
      return (
        <div
          className="badge badge-soft-secondary"
          style={{ cursor: "pointer" }}
          onClick={() => this.getDeleteConfirmation(obj?.user_id2?._id)}
        >
          Delete
        </div>
      );
    }
  };

  handleApproveQuestions = async (obj) => {
    const {creatorData,approvedQuestions} = this.state;
  
    let approvedCreators = creatorData.filter(
      (obj) => (obj.status === 1 && obj?.proposal.proposal.status !== 4))
    let QuestionsApprovedCreatorTemp = creatorData?.filter((obj)=>{if(obj?.askQuestion?.messages?.length > 0 && obj?.askQuestion?.status != 0 && (obj.status !== 2 && obj.status != 4)) return obj })
    let QuestionsApprovedCreator = QuestionsApprovedCreatorTemp?.map(el=>el?.user_id2?._id)
    let approvedCreatorsIds = approvedCreators?.map(el=>el.user_id2?._id)
    let combinedArr = [...approvedCreatorsIds,...QuestionsApprovedCreator,obj?.user_id2?._id]


      
      // console.log(Array.from(new Set(combinedArr)),Array.from(new Set(combinedArr)).length)
      if((Array.from(new Set(combinedArr))).length>3){
        alert('you can select upto three creators only')
        
        return ;
      }
      else
      this.setState({ approveQuestionsLoader: true });
      let tempQuestionsApproved = [...approvedQuestions]
    try {
      let QuestionsIds = [{id:obj?.user_id2?._id}]
      let body = {
        creator_ids: QuestionsIds,
        project_id: this.props?.match?.params?.id?.split(":")?.[1],
      };
      let res = await putAPI(`/proposal/ask-question-approved`,body);
      if (res) {
        tempQuestionsApproved.push(QuestionsIds)
        this.setState({ ...this.state, approveQuestionsLoader: false,approveQuestions:[],approvedQuestions:tempQuestionsApproved});

        const transactionId = this.props.match.params.id.split(":").pop();
        this.handleTransactionData(transactionId);

        alert("Approved question successfully");

      }
    } catch (err) {
      console.log("error");
      this.setState({ ...this.state, approveQuestionsLoader: false });
    }
  };

  handleOnChangeQuestion = (obj, checkedIs, listType) => {

    const { approveQuestions,approvedCreatorData,creatorData } = this.state;
    let id = obj?.user_id2?._id;

    let arr = [...approveQuestions];
    
      // let check = arr.filter((item) => item?.id == id)?.length > 0;
      if (checkedIs) {
        // if (!check) {
      //   arr.push({ id });
      // }

      
      arr.push({ id });

    } else {
      arr = approveQuestions.filter((item) => item?.id !== id);
    }
    this.setState({approveQuestions: arr, creatorListType:listType });
  };


  rejectCreator = (obj,type) => {
    this.setState({ showRejectModal: true, rejectData: obj, type:type });
  };

  handleCloseRejectModal = () => {
    this.setState({ showRejectModal: false });
    this.componentDidMount();
  };

  handleApiCall=(id,role,proposal_type,creator_id)=>{
    // this.componentDidMount()
    let oldState = this.props._transactionGetData.transactionData
    let data1 = this.props._transactionGetData.transactionData.creatorUser

    let index='';

    for(let i = 0;i<data1.length;i++){
      if(data1[i].user_id2._id==creator_id){
        index = i
      }
    }
    let data = data1[index]    
    if(proposal_type=='fixed'){
    for (let x of data.newProposalList){
      for(let y of x.payment_terms){
        if(y._id == id){
          // console.log(y)
            if(role==2){
              y.admin_approved = true
            }
            else{
              y.admin_approved_client = true
            }
        }
      }
    }
  }else if(proposal_type=='retainer'){
    for (let x of data.newProposalList){
      for(let y of x.milestoneRetainer){
        if(y._id == id){
          // console.log(y)
            if(role==2){
              y.admin_approved = true
            }
            else{
              y.admin_approved_client = true
            }
        }
      }
    }
    }
    else{
      for (let x of data.newProposalList){
        for(let y of x.milestoneActual){
          if(y._id == id){
            // console.log(y)
              if(role==2){
                y.admin_approved = true
              }
              else{
                y.admin_approved_client = true
              }
          }
        }
      }
    }

    oldState.creatorUser[index] = data
    this.props.$transactionGetDataSuccess(oldState)

  }

  handleCloseQuestionModal = () => {
    this.setState({ showQuestionsModal: false });
  };

  componentDidMount() {
    const transactionId = this.props.match.params.id.split(":").pop();
    this.handleTransactionData(transactionId);
    this.handleChooseCreator();
    getPublicAPI("/backendUser/get-manger?email=")
      .then((res) => {
        this.setState({ managerList: res.data.data });
      })
      .catch((err) => console.log(err));
  }

  getdeleteCreatorResponse = (creatorDeletedData, deletedLoader) => {
    if (creatorDeletedData && deletedLoader) {
      this.setState({ deletedLoader: false });
      const transactionId = this.props.match.params.id.split(":").pop();
      this.handleTransactionData(transactionId);
    }
  };

  getApprovedCreatorRes = (approveCreatorData, creatorLoader) => {
    if (approveCreatorData.status === "1" && creatorLoader) {
      this.setState({ creatorLoader: false, approvedCreatorData: [],acceptedCreatorData:[] });
      alert("successfully approved...");
      const transactionId = this.props.match.params.id.split(":").pop();
      this.handleTransactionData(transactionId);
    }
  };

  getApprovedCreatorResJob = (approveCreatorJobData, creatorLoaderJob) => {
    if (approveCreatorJobData.status == "1" && creatorLoaderJob) {
      this.setState({ creatorLoaderJob: false, approvedCreatorDataJob: [] });
      alert("successfully approved...");
      const transactionId = this.props.match.params.id.split(":").pop();
      this.handleTransactionData(transactionId);
    }
  };

  componentWillReceiveProps(nextProps) {
    const postProjectData = nextProps._transactionGetData.transactionData;
    const artistListData = nextProps._artistDataList.artistDataList;
    const creatorDeletedData = nextProps._deletePendingCreator.deleteCreatorIs;
    const approveCreatorData = nextProps._approvedProposal.approvedProposal;
    const approveCreatorJobData = nextProps._approvedProposalJob.approvedProposalJob;
    const managerData = {manager_id:postProjectData.project_data?.manager_id,manager_name:postProjectData.project_data?.manager_name}
    const tempApprovedQuestions = postProjectData?.creatorUser?.filter((obj)=>{if(obj?.askQuestion?.messages?.length > 0 && obj?.askQuestion?.status != 0) return obj })
    const { deletedLoader, creatorLoader, creatorLoaderJob } = this.state;
    if (postProjectData && postProjectData.project_data) {
      const data = postProjectData.projectClientData;
      this.setState({
        clientData: data && data,
        creatorData: postProjectData.creatorUser,
        outSideUser: postProjectData.outSideUser,
        projectDataIs: postProjectData.project_data,
        projectStatusIs: postProjectData.project_data
          ? postProjectData.project_data.status
          : "",
        projectId: postProjectData?.project_data?._id,
        collateralId: postProjectData?.collateral?._id,
        clientId: postProjectData?.projectClientData?._id,
        creatorCountIs: postProjectData?.creatorCount,
        transactionsStatus: postProjectData?.project_data.status,
        activeProposals: postProjectData?.totalActiveProposal,
        client_modal_id: postProjectData?.clientUser?.user_id1,
        post_project_modal_id: postProjectData?.project_data?._id,
        approvedQuestions:tempApprovedQuestions,
        manager_modal_id:managerData
      });
    }
    if (artistListData) {
      this.setState({
        options: artistListData.users ? artistListData.users : "",
      });
    }

    deletedLoader &&
      this.getdeleteCreatorResponse(creatorDeletedData, deletedLoader);

    creatorLoader &&
      this.getApprovedCreatorRes(approveCreatorData, creatorLoader);

    creatorLoaderJob &&
      this.getApprovedCreatorResJob(approveCreatorJobData, creatorLoaderJob);
  }

  setCloseModal = (check) => {
    this.setState({ showChatModal: check });
  };

  handleCallApi = (obj) => {
    // console.log("obj = = = ",obj)
    this.setState({ showChatModal: true, creator_modal_id: obj?.user_id2 });
  };

  getCreatorList = (
    creatorData,
    isProjectActive,
    allowApproval,
    listType,
    checkJob
  ) => {
    
    return (
      <>
      <div className="row">
        {creatorData.length
          ? creatorData.map((obj, index) => (
              <div className="col-6" key={index}>
                <div className="card card-new">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <a
                          href={`/artist/:edit/:${
                            obj?.user_id2 && obj?.user_id2?._id
                          }`}
                          className="avatar avatar-lg"
                        >
                          <img
                            src={
                              obj?.user_id2?.profile_image
                                ? userProfileBaseUrl +
                                  obj?.user_id2?.profile_image
                                : require("../../../Assets/img/avatars/profiles/images.jpeg")
                            }
                            alt="..."
                            className="avatar-img rounded"
                          />
                        </a>
                        <p className="card-text small text-muted mb-1 mt-1">
                          <i className="fe fe-user"></i> Creator<br/>
                          { obj?.user_id2.username && <a href={`${WebsiteUrl}creator/${ obj?.user_id2.username}`} target="_blank">&#8599; Profile</a>}
                        </p>
                      </div>
                      <div className="col ml-n2">
                        <h4 className="card-title mb-1 name">
                          <a
                            href={`/artist/:edit/:${
                              obj?.user_id2 && obj?.user_id2?._id
                            }`}
                          >
                            {obj?.user_id2?.first_name +
                              " " +
                              obj?.user_id2?.last_name}
                          </a>
                        </h4>
                        <p>
                          Total Projects:{" "}
                          <span style={{ color: "#6e84a3" }}>
                            {obj?.totalProject}
                          </span>
                        </p>
                        <p>
                          Hourly Pricing:{" "}
                          <span style={{ color: "#6e84a3" }}>
                            {obj?.user_id2?.time_base_amount_month
                              ? (
                                  obj?.user_id2?.time_base_amount_month /
                                  (20 * 5)
                                ).toFixed(2)
                              : ""}
                          </span>{" "}
                        </p>
                        <p>
                          Collateral Price:{" "}
                          <span style={{ color: "#6e84a3" }}>
                            {obj?.userCollateralData
                              ? obj.userCollateralData?.start_price +
                                "-" +
                                obj.userCollateralData?.end_price +
                                `${
                                  this.state.projectDataIs?.unit_tag
                                    ? ` (${this.state.projectDataIs?.unit_tag})`
                                    : ""
                                }`
                              : ""}
                          </span>
                        </p>

                        {checkJob ?
                          obj?.rejectedProposal && obj?.rejectedProposal?.description &&
                          <p className="card-text small text-muted mb-1">
                            {/* {console.log("----------->",checkJob,obj?.rejectedProposal?.description)} */}
                          {`Desc: ${obj?.rejectedProposal?.description}`}
                        </p> 
                        :
                        (obj?.status === 2 || obj?.status === 4) && !checkJob && (
                            <p className="card-text small text-muted mb-1">
                              {` Desc: ${
                                obj?.reason?.explain
                                  ? obj?.reason?.explain
                                  : obj?.adminReason &&
                                    obj?.adminReason?.description
                                  ? obj?.adminReason?.description
                                  : "Not Found..."
                              }`}
                            </p>
                          )
                        }

                       {/* {obj?.rejectedProposal && obj?.rejectedProposal?.description &&
                          <p className="card-text small text-muted mb-1">
                            {console.log("----------->",obj?.rejectedProposal?.description)}
                          {`Desc: ${obj?.rejectedProposal?.description}`}
                        </p>} */}

                        {/* {(obj?.status === 2 || obj?.status === 4) &&
                          !checkJob && (
                            <p className="card-text small text-muted mb-1">
                              {` Desc: ${
                                obj?.reason?.explain
                                  ? obj?.reason?.explain
                                  : obj?.adminReason &&
                                    obj?.adminReason?.description
                                  ? obj?.adminReason?.description
                                  : "Not Found..."
                              }`}
                            </p>
                          )} */}
                        {(
                          obj?.status === 2 || obj?.status === 4) &&
                          obj?.reason &&
                          obj?.reason?.reason.map((object, index) => (
                            <p
                              className="card-text small text-muted mb-1"
                              key={index}
                            >
                              {object?.text}
                            </p>
                          ))}
                        {(obj?.status === 2 || obj?.status === 4) &&
                          obj?.reason && (
                            <p className="card-text small text-muted mb-1">
                              (Reason)
                            </p>
                        )}
                      </div>
                      
                        <div className="col-auto">
                          <div className="avatar-group d-none d-md-inline-flex">
                            {obj?.status === 1 &&  obj?.proposal?.proposal?.status !== 4 && obj?.last_msg && !checkJob && (
                              <div className={`badge mr-2 badge-soft-success`}>
                                Approved
                              </div>
                            )}

                            {/* {(obj?.status === 1 ||
                              obj?.status === 2 ||
                              obj?.status === 3 ||
                              obj?.status === 4 ||
                              obj?.status === 0)  &&  (
                              <div
                                className={`badge mr-2 ${
                                  obj?.status === 0
                                    ? `badge-soft-info`
                                    : obj?.proposal &&
                                      obj?.proposal.proposal.status === 2
                                    ? `badge-soft-success`
                                    : obj?.status === 1
                                    ? `badge-soft-primary`
                                    : obj?.status === 2 || obj?.status === 4
                                    ? `badge-soft-danger`
                                    : obj?.status === 3
                                    ? `badge-soft-warning`
                                    : ``
                                }`}
                              >
                                {obj?.status === 0
                                  ? "Pending"
                                  : obj?.proposal &&
                                    obj?.proposal.proposal.status === 2
                                  ? `Completed`
                                  : obj?.status === 1
                                  ? "Accepted"
                                  : obj?.status === 2 || obj?.status === 4
                                  ? "Rejected"
                                  : obj?.status === 3
                                  ? "Asking"
                                  : ""}
                              </div>
                            
                            )} */}

                            {checkJob
                            ?
                            obj?.rejectedProposal?
                            <div
                                className="badge mr-2 badge-soft-danger"
                              >
                              Rejected
                              </div>:""
                            :
                              (obj?.status === 1 ||
                              obj?.status === 2 ||
                              obj?.status === 3 ||
                              obj?.status === 4 ||
                              obj?.status === 0)  &&  (
                              <div
                                className={`badge mr-2 ${
                                  obj?.status === 0
                                    ? `badge-soft-info`
                                    : obj?.proposal &&
                                      obj?.proposal.proposal.status === 2
                                    ? `badge-soft-success`
                                    : obj?.status === 1
                                    ? `badge-soft-primary`
                                    : obj?.status === 2 || obj?.status === 4
                                    ? `badge-soft-danger`
                                    : obj?.status === 3
                                    ? `badge-soft-warning`
                                    : ``
                                }`}
                              >
                                {obj?.status === 0
                                  ? "Pending"
                                  : obj?.proposal &&
                                    obj?.proposal.proposal.status === 2
                                  ? `Completed`
                                  : obj?.status === 1
                                  ? "Accepted"
                                  : obj?.status === 2 || obj?.status === 4
                                  ? "Rejected"
                                  : obj?.status === 3
                                  ? "Asking"
                                  : ""}
                              </div>
                            
                            )}

                            {/* {
                          `${checkJob'?!obj?.rejectedProposal :obj?.status !== 0 && obj?.status !== 2 && obj?.status !== 4 && !isProjectActive}` &&
                          <div
                              className="badge badge-soft-secondary mr-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => this.rejectCreator(obj,type)}
                          >
                              Reject
                            </div>    
                            } */}
                            {/* {obj?.status !== 0 && obj?.status !== 2 && obj?.status !== 4 && !isProjectActive &&
                            <div
                              className="badge badge-soft-secondary mr-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => this.rejectCreator(obj)}
                            >
                              Reject
                            </div>
                            } */}

                          {checkJob?
                         !obj?.rejectedProposal &&
                          <div
                            className="badge badge-soft-secondary mr-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.rejectCreator(obj,checkJob)}
                          >
                            Reject
                          </div>
                          :
                          obj?.status !== 0 && obj?.status !== 2 && obj?.status !== 4 && !isProjectActive &&
                            <div
                              className="badge badge-soft-secondary mr-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => this.rejectCreator(obj)}
                            >
                              Reject
                            </div>
                            }

                           { !checkJob && <div
                              className="badge badge-soft-secondary mr-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => this.handleCallApi(obj)}
                            >
                              Chats
                            </div>}

                            {this.getDeleteCreator(obj)}
                          </div>
                          
                          {obj?.proposal &&
                          obj?.proposal?.proposal?.status === 2 ? (
                            <p className="mb-0 mt-1 card-text small text-muted ">
                              Project Completed
                            </p>
                          ) : (
                            (obj?.status === 2 || obj?.status === 4) && (
                              <>
                                <p className="mb-0 mt-1 card-text small text-muted">
                                  {checkJob?
                                  obj?.rejectedProposal && `(By ${obj?.rejectedProposal?.user_type})` :
                                  obj?.reason
                                    ? `(By ${obj?.reason?.user_type})`
                                    : obj?.proposalList?.length < 1
                                    ? "(By Default)"
                                    : obj?.adminReason?.email
                                    ? `(From Admin)`
                                    : "(By Automatic)"
                                    }
                                </p>
                                {checkJob?
                                obj?.rejectedProposal &&
                                <p className="mb-0 mt-1 card-text small text-muted">
                                    By {obj?.rejectedProposal?.email}
                                  </p>
                                
                                :
                                obj?.adminReason?.email ? (
                                  <p className="mb-0 mt-1 card-text small text-muted">
                                    By {obj?.adminReason?.email}
                                  </p>
                                ) : obj?.proposalList?.length < 1 &&
                                  !obj?.reason ? (
                                  <p className="mb-0 mt-1 card-text small text-muted">
                                    {" "}
                                    Didn't Submit Proposal
                                  </p>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          )}
                        </div>
                      
                        {  obj?.askQuestion?.messages?.length > 0 && obj?.askQuestion?.status != 0 && <div className="col-12 mt-3 text-left">
                          <div className="badge badge-soft-info">
                            <div>Questions Approved</div>
                          </div>
                        </div>}
                        

                      { obj?.proposal &&  (
                        <div className="col-12 mt-3 text-right">
                          <div className="badge badge-soft-secondary">
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => this.handleViewProposal(obj, checkJob)}
                            >{`View Proposal (${obj?.proposalList?.length})`}</div>
                          </div>
                        </div>
                      )}

                      {obj?.askQuestion?.messages?.length > 0 && (
                          <div className="col-12 mt-3 text-right">
                            <div className="badge badge-soft-secondary">
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => this.handleQuestionData(obj?.askQuestion?.messages, obj?.askQuestion?.status)}
                              > 
                              View Questions 
                              </div>
                            </div>
                          </div>
                        )}

                        { obj?.askQuestion?.messages?.length > 0 && obj?.askQuestion?.status != 1  && listType!=='rejected' &&(
                          <div className="col-12 mt-3 text-right btn-round">
                            {/* <div className="badge badge-soft-secondary"> */}
                              <button
                                className='btn btn-primary btn-sm'
                                style={{ cursor: "pointer" }}
                                onClick={() => this.handleApproveQuestions(obj)}
                              > 
                              Approve Questions 
                              </button>
                            {/* </div> */}
                          </div>
                        )}

                      <div className="col-12">
                        {obj?.last_msg && (
                          <p className="card-text small text-muted mb-1">
                            Last Message By :{" "}
                            {obj?.last_msg?.user_type === "user"
                              ? obj?.last_msg?.sender_id?.first_name +
                                " " +
                                obj?.last_msg?.sender_id?.last_name
                              : "Getcreator"}{" "}
                            {`(${
                              obj?.last_msg?.createdAt.split("T").slice(0)[0]
                            })`}
                          </p>
                        )}

                  {/* ==========approve question checkbox============ */}

                  {/* {
                           obj?.askQuestion?.messages?.length > 0 && obj?.askQuestion?.status != 1  && listType!=='rejected' &&(
                        <div className="col">
                          <small className="text-muted">
                            <input
                              type="checkbox"
                              name="phoneStatus"
                              value="1"
                              onChange={(e) => this.handleOnChangeQuestion(obj, e.target.checked, listType)}
                            />{" "}
                            Question Approve
                          </small>
                        </div>)} */}

                     

                    {/* ==========end============ */}
                      </div>
                      {
                        listType!=='pending' &&  !obj?.rejectedProposal &&
                      ((obj?.status !== 2 &&
                        obj?.status !== 4 &&
                        obj?.proposal?.proposal?.status === 4 &&
                        allowApproval(obj)) ||
                        (checkJob &&
                          this.state?.projectStatusIs == 5 &&
                          allowApproval(obj))) && (
                        <div>


                      
                        
                        <div className="col">
                          <small className="text-muted">
                            <input
                              type="checkbox"
                              name="phoneStatus"
                              value="1"
                              onChange={(e) =>
                                this.handleOnChange(
                                  obj,
                                  e.target.checked,
                                  checkJob,
                                  listType
                                )
                              }
                            />{" "}
                            Choose Creator for Approvals
                          </small>
                        </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          : "No Creators found.."}
      </div>


      {/* {this.state.approvedCreatorData.length > 0 && checkJob === "Accepted" && this.handleApproveButton()}
      {this.state.approvedCreatorData.length > 0 && checkJob === "Pending" && this.handleApproveButton()} */}

      {/* {this.state.approveQuestions?.length > 0  && (
        <div className="col-12 col-md-12 mt-3 text-right">
          <button className={`btn btn-primary`} onClick={this.handleApproveQuestions}>
            {this.state.approveQuestionsLoader ? "Approving..." : `Approve Question`}
          </button>
        </div>
      )} */}

      {/* {this.state.approvedCreatorDataJob.length > 0 && checkJob === "External" && (
        <div className="col-12 col-md-12 text-right">
          <button
            className={`btn btn-primary ${!this.state.approvedCreatorDataJob.length > 0 ? "disabled" : ""} `}
            disable={`${!this.state.approvedCreatorDataJob > 0 ? true : false}`}
            onClick={this.handleApprovedProposalJob}
          >
            {this.state.creatorLoaderJob ? "Approving..." : `Approve Proposal`}
          </button>
        </div>
      )} */}
  
    </>
                
    );
  };
  ab = () => {
    this.componentDidMount();
  };
  render() {
    const {
      creatorData,
      clientData,
      // creatorCountIs,
      transactionsStatus,
      projectDataIs,
      approvedCreatorData,
      approvedCreatorDataJob,
      activeProposals,
      client_modal_id,
      post_project_modal_id,
      creator_modal_id,
      showChatModal,
      outSideUser,
      manager_modal_id
    } = this.state;

    // console.log("approvedCreatorData = ",approvedCreatorData)
    const func = {
      handleCloseProposalModal: this.handleCloseProposalModal,
      handleCloseClintModal: this.handleCloseClintModal,
      handleCloseManagerModal: this.handleCloseManagerModal,
      handleCloseRejectModal: this.handleCloseRejectModal,
      handleCloseQuestionModal:this.handleCloseQuestionModal,
      handleApiCall: this.handleApiCall
    };
    const allowApproval = (obj) => {
      let {approveQuestions,approvedQuestions} = this.state;
      const isAlreadySelected =
      approvedCreatorData &&
        approvedCreatorData.some(
          (creator) => creator.user_id === obj.user_id2._id
        );
      

      return (
        // isAlreadySelected || 
        // approvedCreators?.length+(approvedCreatorData?.length + approvedQuestions?.length +
        //   approvedCreatorDataJob.length +
        //   activeProposals) <=
        //   3
        true
      );
    };
    const allowApprovalJob = (obj) => {
      let {approvedQuestions} = this.state;
      const isAlreadySelected =
        approvedCreatorDataJob &&
        approvedCreatorDataJob.some(
          (creator) => creator.user_id === obj.user_id2._id
        );
      return (
        // isAlreadySelected ||
        // approvedCreatorDataJob.length + approvedCreators?.length + approvedQuestions?.length +
        //   approvedCreatorData.length +
        //   activeProposals <=
        //   3
        true
      );
    };
    const isProjectActive = projectDataIs && projectDataIs.status === 6;

    const pendingCreators = creatorData.filter((obj) => (obj.status === 0 || obj?.status===5 || obj?.status===6));
    const approvedCreators = creatorData.filter(
      (obj) => (obj?.status === 1 && obj?.proposal && obj?.proposal?.proposal?.status !== 4)
    );
    const rejectedCreators = creatorData.filter(
      (obj) => obj?.status === 2 || obj?.status === 4
    );
    const acceptedCreators = creatorData.filter((obj) => {
      // console.log("*********", obj.status === 1 && !obj.last_msg, obj)
      return (obj?.status === 1  &&  obj?.proposal?.proposal?.status == 4)
    });


    // console.log(acceptedCreators);
    return (
      <React.Fragment>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-6">
                <div className="card mb-0 card-new">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-auto rounded">
                        <a
                          href={`/backendusers/backendusersForm/:${projectDataIs?.manager_id}`}
                          className="avatar avatar-lg"
                        >
                          <img
                            src={
                              projectDataIs.manager_image
                                ? userProfileBaseUrl +
                                  projectDataIs.manager_image
                                : require("../../../Assets/img/avatars/profiles/images.jpeg")
                            }
                            alt="..."
                            className="avatar-img rounded"
                          />
                        </a>
                      </div>
                      <div className="col ml-n2">
                        <h4 className="card-title mb-1 name">
                          <a href={`#!`}>
                            {projectDataIs?.manager_name
                              ? projectDataIs?.manager_name
                              : "Not Found"}
                          </a>
                        </h4>

                        <p className="card-text small text-muted mb-1">
                          <i className="fe fe-user"></i> Manager
                        </p>
                        <div className="col-auto text-right">
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={this.handleEditManager}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {clientData ? (
                <div className="col-6">
                  <div className="card mb-0 card-new">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-auto rounded">
                          <a
                            href={`/clients/:edit/:${clientData._id}`}
                            className="avatar avatar-lg"
                          >
                            <img
                              src={
                                clientData.profile_image
                                  ? userProfileBaseUrl +
                                    clientData.profile_image
                                  : require("../../../Assets/img/avatars/profiles/images.jpeg")
                              }
                              alt="..."
                              className="avatar-img rounded"
                            />
                          </a>
                        </div>
                        <div className="col ml-n2">
                          <h4 className="card-title mb-1 name">
                            <a href={`/clients/:edit/:${clientData._id}`}>
                              {clientData.first_name +
                                " " +
                                clientData.last_name}
                            </a>
                          </h4>

                          <p className="card-text small text-muted mb-1">
                            <i className="fe fe-user"></i> Client
                          </p>
                          {transactionsStatus === 3 && (
                            <div className="col-auto text-right">
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={this.handleEditClient}
                              >
                                Edit
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                "Not found any Client..."
              )}
            </div>
            <hr className="my-5" />

            <div className="card">
              <div className="card-header">
                <h4 className="card-header-title">
                  Approved (Artists approved by Admin)
                </h4>
              </div>
              <div className="card-body">
                {this.getCreatorList(
                  approvedCreators,
                  isProjectActive,
                  allowApproval,
                  'approved'
                )}
                {this.state.acceptedCreatorData.length > 0 && (
                  <div className="col-12 col-md-12 text-right">
                    <button
                      className={`btn btn-primary ${
                        !this.state.acceptedCreatorData.length > 0
                          ? "disabled"
                          : ""
                      } `}
                      disable={`${
                        !this.state.acceptedCreatorData > 0 ? true : false
                      }`}
                      onClick={this.handleApprovedProposal}
                    >
                      {this.state.creatorLoader
                        ? "Approving..."
                        : `Approve Proposal`}
                    </button>
                  </div>
                )}
                {this.state.approveQuestions?.length > 0 &&  this.state.creatorListType==='approved' && (
                  <div className="col-12 col-md-12 mt-3 text-right">
                    <button className={`btn btn-primary`} onClick={this.handleApproveQuestions}>
                      {this.state.approveQuestionsLoader ? "Approving..." : `Approve Question`}
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="card">
              <div className="card-header">
                <h4 className="card-header-title">
                  Accepted (Artists who accepted brief and shared proposal)
                </h4>
              </div>
              <div className="card-body">
                {this.getCreatorList(
                  acceptedCreators,
                  isProjectActive,
                  allowApproval,
                  'accepted'
                )}
                {this.state.approvedCreatorData.length > 0 && (
                  <div className="col-12 col-md-12 text-right">
                    <button
                      className={`btn btn-primary ${
                        !this.state.approvedCreatorData.length > 0
                          ? "disabled"
                          : ""
                      } `}
                      disable={`${
                        !this.state.approvedCreatorData > 0 ? true : false
                      }`}
                      onClick={this.handleApprovedProposal}
                    >
                      {this.state.creatorLoader
                        ? "Approving..."
                        : `Approve Proposal`}
                    </button>
                  </div>
                )}
                {this.state.approveQuestions?.length > 0 &&  this.state.creatorListType==='accepted' && (
                  <div className="col-12 col-md-12 mt-3 text-right">
                    <button className={`btn btn-primary`} onClick={this.handleApproveQuestions}>
                      {this.state.approveQuestionsLoader ? "Approving..." : `Approve Question`}
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="card">
              <div className="card-header">
                <h4 className="card-header-title">
                  Pending (Artists who have not shared proposal yet)
                </h4>
              </div>
              <div className="card-body">
                {this.getCreatorList(
                  pendingCreators,
                  isProjectActive,
                  allowApproval,
                  'pending'
                )}
                {this.state.approveQuestions?.length > 0 &&  this.state.creatorListType==='pending' && (
                  <div className="col-12 col-md-12 mt-3 text-right">
                    <button className={`btn btn-primary`} onClick={this.handleApproveQuestions}>
                      {this.state.approveQuestionsLoader ? "Approving..." : `Approve Question`}
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h4 className="card-header-title">External Applications</h4>
              </div>
              <div className="card-body">
                {this.getCreatorList(
                  outSideUser,
                  isProjectActive,
                  allowApprovalJob,
                  'external',
                  true
                )}
                {this.state.approvedCreatorDataJob.length > 0 && (
                  <div className="col-12 col-md-12 text-right">
                    <button
                      className={`btn btn-primary ${
                        !this.state.approvedCreatorDataJob.length > 0
                          ? "disabled"
                          : ""
                      } `}
                      disable={`${
                        !this.state.approvedCreatorDataJob > 0 ? true : false
                      }`}
                      onClick={this.handleApprovedProposalJob}
                    >
                      {this.state.creatorLoaderJob
                        ? "Approving..."
                        : `Approve Proposal`}
                    </button>
                  </div>
                )}
                {this.state.approveQuestions?.length > 0 &&  this.state.creatorListType==='external' && (
                  <div className="col-12 col-md-12 mt-3 text-right">
                    <button className={`btn btn-primary`} onClick={this.handleApproveQuestions}>
                      {this.state.approveQuestionsLoader ? "Approving..." : `Approve Question`}
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h4 className="card-header-title">Rejected Artists</h4>
              </div>
              <div className="card-body">
                {this.getCreatorList(
                  rejectedCreators,
                  isProjectActive,
                  allowApproval,
                  'rejected'
                )}
                
              </div>
            </div>
          </div>
          <hr className="my-5" />
          {/*------------------------ add Creator ----------------------*/}

          {/* {(transactionsStatus === 3 || transactionsStatus === 5) &&
              this.state.collateralId &&
              !(creatorCountIs > 9) && (
                <AddMultipleCreator {...this.state} {...this.props} />
              )} */}

          {/*---------------------------add Creator----------------------*/}
          {/*---------------------------------------Proposal Modal -----------------------------*/}
          <ProposalModal
            {...this.state}
            {...this.props}
            handleFunc={func}
            // type={this.state.type}
          />

          {/*---------------------Reject Reason Modal -----------------------*/}
          <RejectReasonModal
            {...this.state}
            {...this.props}
            handleFunc={func}
          />

          <QuestionModal {...this.state} {...this.props} handleFunc={func} />


          {/*------------------Edit Client Modal -----------------------*/}
          <EditClient {...this.state} {...this.props} handleFunc={func} />

          {/*---------------------Manager Maodal ------------------------*/}
          <Manager
            admin={false}
            {...this.state}
            {...this.props}
            handleFunc={func}
          />

          {/* -----------------------------chat modal----------------------------- */}
          <ChatModal
            showChatModal={showChatModal}
            client_modal_id={client_modal_id}
            creator_modal_id={creator_modal_id}
            manager_modal_id = {manager_modal_id}
            post_project_modal_id={post_project_modal_id}
            setCloseModal={this.setCloseModal}
          />
        </section>
        {/* )} */}
      </React.Fragment>
    );
  }
}
const mapState = ({
  transactionGetData,
  artistDataList,
  deletePendingCreator,
  approvedProposal,
  approvedProposalJob,
}) => {
  return {
    _transactionGetData: transactionGetData,
    _artistDataList: artistDataList,
    _deletePendingCreator: deletePendingCreator,
    _approvedProposal: approvedProposal,
    _approvedProposalJob: approvedProposalJob,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $transactionGetData: (values, payload) =>
    dispatchEvent(transactionGetData(values, payload)),
  $artistDataList: (values) => dispatchEvent(artistDataList(values)),
  $deletePendingCreator: (values) =>
    dispatchEvent(deletePendingCreator(values)),
  $approvedProposal: (values) => dispatchEvent(approvedProposal(values)),
  $approvedProposalJob: (values) => dispatchEvent(approvedProposalJob(values)),
  $transactionGetDataSuccess:(values) => dispatchEvent(transactionGetDataSuccess(values))
});
export default connect(mapState, mapDispatch)(TransactionUserDetails);
