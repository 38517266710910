import {
    MILESTONE_LIST_SUCCESS,
    MILESTONE_LIST_ERR,
    TRANSITION_DASHBOARD_SUCCESS,
    TRANSITION_DASHBOARD_ERROR
  } from "../type.action";
  
  const initState = {
    milesListIs : '',
    milesErrIs: '',
    transitionDashboardIs: "",
    transitionDashboardErrorIs:""
  };
  
  const reducers = (state = initState, { type, values, error }) => {
    switch (type) {
      case MILESTONE_LIST_SUCCESS:
        return {
          ...state,
          milesListIs: values,
        };
      case MILESTONE_LIST_ERR:
        return {
          ...state,
          milesErrIs: error
        }; 
      case TRANSITION_DASHBOARD_SUCCESS:
        return {
          ...state,
          transitionDashboardIs: values
        };
      case TRANSITION_DASHBOARD_ERROR:
        return {
          ...state,
          transitionDashboardErrorIs: error
        };
      default:
        return state;
    }
  };
  export default reducers;
  