import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../../Common/Loader/Loader";
import { connect } from "react-redux";
import {
  closeProjectRequestList,
  closeProject,
} from "../../../../store/action/transaction.action";
import ReactPaginate from "react-paginate";
import ErrorApi from "../../Common/Error/ErrorApi";

class CloseProjectRequest extends Component {
  state = {
    LoadingIs: false,
    currentPage: 1,
    sort: -1,
    CreatorDataIs: [],
    totalPage: 0,
    clearBriefActive: false,
    sortFlagIs: false,
    closeProjectIs: false,
  };

  handleGetConfirmation = (id) => {
    let confirmIs = window.confirm(`Do you want to countinue ?`);
    if (confirmIs === true) {
      this.setState({ closeProjectIs: true });
      this.handleCloseProject(id);
      return true;
    } else {
      return false;
    }
  };

  handleCloseProject = (id) => {
    const closeProjectData = { _id: id };
    this.props.$closeProject(closeProjectData);
  };

  handleSorting = () => {
    this.setState({ sortFlagIs: !this.state.sortFlagIs }, () => {
      const { currentPage } = this.state;
      let currentData = null;
      if (this.state.sortFlagIs) {
        currentData = { page: currentPage, sortIs: 1 };
      } else {
        currentData = { page: currentPage, sortIs: -1 };
      }
      this.setState({ sort: this.state.sortFlagIs ? 1 : -1 });
      this.handleCreatorRejectsBrief(currentData);
    });
  };

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    const { sort } = this.state;
    const currentData = { page: selectedData, sortIs: sort };
    this.handleCreatorRejectsBrief(currentData);
  };

  handleCreatorRejectsBrief = (currentData) => {
    this.setState({
      LoadingIs: true,
    });
    this.props.$closeProjectRequestList(currentData);
  };

  componentDidMount() {
    const { currentPage, sort } = this.state;
    const currentData = { page: currentPage, sortIs: sort };
    this.handleCreatorRejectsBrief(currentData);
  }

  componentWillReceiveProps(nextProps) {
    const closeProjectListIs =
      nextProps._closeProjectRequestList.projectCloseReq;
    const closedProject = nextProps._closeProject.closeProjectIs;
    const { closeProjectIs } = this.state;
    if (closeProjectListIs) {
      this.setState({
        CreatorDataIs: closeProjectListIs.PostProjects
          ? closeProjectListIs.PostProjects
          : "",
        totalPage: closeProjectListIs.totalPages,
        LoadingIs: false,
      });
    }
    if (closedProject && closeProjectIs) {
      this.setState({ closeProjectIs: false });
      const { currentPage, sort } = this.state;
      const currentData = { page: currentPage, sortIs: sort };
      this.handleCreatorRejectsBrief(currentData);
    }
  }
  render() {
    const otherDbsArr = [
      { name: "Action" },
      { name: "Project" },
      { name: "Name (Client)" },
      { name: "Email (Client)" },
      { name: "Phone Number" },
      { name: "collateral" },
      { name: "Requested By" },
      { name: "Stage" },
      { name: "posted by" },
      { name: "Start Date" },
      { name: "Approved Date" },
      { name: "Close Date" },
      { name: "Client cost (Including gst)" },
      { name: "creator earnings" },
      { name: "total paid" },
      { name: "number of milestone" },
      { name: "milestone completed" },
      { name: "name (creator)" },
      { name: "email (creator)" },
      { name: "status" },      
    ];

    const {
      LoadingIs,
      CreatorDataIs,
    } = this.state;
    return (
      <React.Fragment>
        <section>
          {this.props._closeProjectRequestList.projectCloseReqErr ? (
            <ErrorApi {...this.props} />
          ) : (
            <React.Fragment>
              <section className="client mt-5">
                <div className="container-fluid mx-auto">
                  {LoadingIs ? (
                    <Loader />
                  ) : (
                    <div className="table-responsive">
                      <table className="table table-sm table-nowrap card-table">
                        {/*----------------- table head --------------------*/}
                        <thead>
                          <tr>
                            {otherDbsArr.map((obj, index) => (
                              <React.Fragment key={index}>
                                {obj.name !== "Start Date" && (
                                  <th>
                                    <Link to="#!" className="text-muted ">
                                      {obj.name}
                                    </Link>
                                  </th>
                                )}
                                {obj.name === "Start Date" && (
                                  <th key={index}>
                                    <Link
                                      to="#!"
                                      className="text-muted sort"
                                      data-sort="tables-row"
                                      onClick={this.handleSorting}
                                    >
                                      {obj.name}
                                    </Link>
                                  </th>
                                )}
                              </React.Fragment>
                            ))}
                          </tr>
                        </thead>
                        {/*----------------- table head --------------------*/}
                        <tbody className="list">
                          {CreatorDataIs.length > 0 ? (
                            CreatorDataIs.map((obj, index) => (
                              <tr key={index}>
                                <td className="text-center">
                                  <div
                                    className={`dropdown `}
                                  >
                                    <a
                                      href="#!"
                                      className="dropdown-ellipses dropdown-toggle text-secondary"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <i
                                        className="fe fe-more-vertical"
                                        
                                      ></i>
                                    </a>
                                    
                                      <div
                                        className={`ma-auto dropdown-menu dropdown-menu-right text-center`}
                                        
                                      >
                                        <span>
                                          <Link
                                            to="#!"
                                            className="dropdown-item mb-2"
                                            onClick={() =>
                                              this.handleGetConfirmation(
                                                obj._id
                                              )
                                            }
                                          >
                                            Close Project
                                          </Link>
                                        </span>
                                        <span>
                                          <Link
                                            to={`/transactions/transactionsDetails/:${obj._id}`}
                                            className="dropdown-item mb-2"
                                            datatoggle="modal"
                                            datatarget="#exampleModalCenter"
                                          >
                                            View/Edit
                                          </Link>
                                        </span>
                                      </div>
                                  </div>
                                </td>
                                <td className="orders-order">
                                  {obj.project_name}
                                </td>
                                <td className="orders-method">{`${
                                  obj.user && obj.user.first_name
                                } ${obj.user && obj.user.last_name}`}</td>
                                <td className="orders-method">
                                  {obj.user && obj.user.email}
                                </td>
                                <td className="orders-method">
                                  {obj.user && obj.user.phone_no}
                                </td>
                                <td className="orders-method">
                                  {obj.collateral && obj.collateral.name}
                                </td>
                                <td className="orders-method">csdvgsd</td>
                                <td className="orders-method">
                                  {obj.action_stag === 0
                                    ? "Scope of work"
                                    : obj.action_stag === 1
                                    ? "Profile selection"
                                    : obj.action_stag === 2
                                    ? "Project Details"
                                    : obj.action_stag === 4
                                    ? "Call Back Request"
                                    : obj.action_stag === 5
                                    ? "Active Brief"
                                    : obj.action_stag === 6
                                    ? "Active Project"
                                    : obj.action_stag === 7
                                    ? "Completed"
                                    : obj.action_stag === -1
                                    ? "Incomplete"
                                    : ""}
                                </td>
                                <td className="orders-method">
                                  {" "}
                                  <span
                                    className={`badge ${
                                    ( obj.type === "post_project_admin" || obj?.were_admin_project)
                                    ?`badge-soft-primary`
                                    :`badge-soft-info`
                                    }`}
                                      >
                                    {obj.type === "post_project_admin" || obj?.were_admin_project
                                      ? "Admin"
                                      : "Client"}
                                  </span>
                                </td>
                                <td className="orders-method">
                                  {obj.project_approved_date &&
                                    obj.project_approved_date
                                      .split("T")
                                      .slice(0)[0]}
                                </td>
                                <td className="orders-method">
                                  {obj.project_brief_approved_date &&
                                    obj.project_brief_approved_date
                                      .split("T")
                                      .slice(0)[0]}
                                </td>
                                <td className="orders-method">
                                  {obj.project_close_date &&
                                    obj.project_close_date
                                      .split("T")
                                      .slice(0)[0]}
                                </td>
                                <td className="orders-method">
                                  {obj.proposal
                                    ? obj.proposal.proposal &&
                                      obj.proposal.proposal.total_cost
                                    : ""}
                                </td>
                                <td className="orders-method">
                                  {obj.proposal
                                    ? obj.proposal.proposal &&
                                      obj.proposal.proposal.total_earning
                                    : ""}
                                </td>
                                <td className="orders-method">
                                  {obj.proposal ? obj.proposal.paid_amount : ""}
                                </td>
                                <td className="orders-method">
                                  {obj.proposal
                                    ? obj.proposal.total_milsetone
                                    : ""}
                                </td>
                                <td className="orders-method">
                                  {obj.proposal
                                    ? obj.proposal.complete_milestone
                                    : ""}
                                </td>
                                <td className="orders-method">
                                  {obj.proposal
                                    ? obj.proposal.creator &&
                                      obj.proposal.creator.first_name +
                                        " " +
                                        obj.proposal.creator.last_name
                                    : ""}
                                </td>
                                <td className="orders-method">
                                  {obj.proposal
                                    ? obj.proposal.creator &&
                                      obj.proposal.creator.email
                                    : ""}
                                </td>
                                <td className="orders-method">
                                  <span
                                    className={`mr-2 ${
                                      obj.status === 0 ||
                                      obj.status === 1 ||
                                      obj.status === 2
                                        ? `text-warning`
                                        : obj.status === 3
                                        ? `text-info`
                                        : obj.status === 4
                                        ? `text-secondary`
                                        : obj.status === 5
                                        ? `text-primary`
                                        : obj.status === 6
                                        ? `text-primary`
                                        : obj.status === 7
                                        ? `text-success`
                                        : obj.status === 8
                                        ? `text-dark`
                                        : obj.status === 9
                                        ? `text-danger`
                                        : obj.status === -1
                                        ? `text-warning`
                                        : obj.status === 10
                                        ? `text-danger`
                                        : ``
                                    }`}
                                  >
                                    ●
                                  </span>
                                  {`${
                                    obj.status === 0 ||
                                    obj.status === 1 ||
                                    obj.status === 2
                                      ? `Incomplete Brief`
                                      : obj.status === 3
                                      ? `Pending`
                                      : obj.status === 4
                                      ? `Call Back Request`
                                      : obj.status === 5
                                      ? `Negociation`
                                      : obj.status === 6
                                      ? `Active`
                                      : obj.status === 7
                                      ? `Completed`
                                      : obj.status === 8
                                      ? `Close`
                                      : obj.status === 9
                                      ? `Rejected`
                                      : obj.status === -1
                                      ? `Incomplete Brief`
                                      : obj.status === 10
                                      ? `Deleted`
                                      : ``
                                  }`}
                                </td>                                
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td>No data Found...</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {/****************** Pagination ********************/}

                  {this.state.totalPage > 0 && (
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.totalPage}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  )}
                </div>
              </section>
            </React.Fragment>
          )}
        </section>
      </React.Fragment>
    );
  }
}
const mapState = ({ closeProjectRequestList, closeProject }) => {
  return {
    _closeProjectRequestList: closeProjectRequestList,
    _closeProject: closeProject,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $closeProjectRequestList: (values) =>
    dispatchEvent(closeProjectRequestList(values)),
  $closeProject: (values) => dispatchEvent(closeProject(values)),
});
export default connect(mapState, mapDispatch)(CloseProjectRequest);
