import {
    GET_CUSTOM_INDUSTRY_LIST_SUCCESS,
    GET_CUSTOM_SOFTWARE_LIST_SUCCESS,
    GET_CUSTOM_SPECIALIZATION_LIST_SUCCESS,
  } from "../type.action";
  
  const initState = {
    customSoftwareList: "",
    customIndustryList: "",
    customSpecializationList:"",
  };
  
  const reducers = (state = initState, { type, values, error }) => {
    switch (type) {
      case GET_CUSTOM_INDUSTRY_LIST_SUCCESS:
        return {
          ...state,
          customIndustryList: values,
        };

      case GET_CUSTOM_SOFTWARE_LIST_SUCCESS:
        return {
          ...state,
          customSoftwareList: values,
        };

      case GET_CUSTOM_SPECIALIZATION_LIST_SUCCESS:
        return {
          ...state,
          customSpecializationList: values,
        };
      default:
        return state;
    }
  };
  export default reducers;