import {
  GET_CUSTOM_INDUSTRY_LIST,
  GET_CUSTOM_INDUSTRY_LIST_SUCCESS,
  GET_CUSTOM_SOFTWARE_LIST,
  GET_CUSTOM_SOFTWARE_LIST_SUCCESS,
  GET_CUSTOM_SPECIALIZATION_LIST,
  GET_CUSTOM_SPECIALIZATION_LIST_SUCCESS
  } from "../type.action";
  
  export const getCustomIndustryList = (values) => ({
    type: GET_CUSTOM_INDUSTRY_LIST,
    values,
  });
  export const getCustomIndustryListSuccess = (values) => ({
    type: GET_CUSTOM_INDUSTRY_LIST_SUCCESS,
    values,
  });

// -----------------------------------------------------------------------------

export const getCustomSoftwareList = (values) => ({
  type: GET_CUSTOM_SOFTWARE_LIST,
  values,
});
export const getCustomSoftwareListSuccess = (values) => ({
  type: GET_CUSTOM_SOFTWARE_LIST_SUCCESS,
  values,
});

// -----------------------------------------------------------------------------

  export const getCustomSpecializationList = (values) => ({
    type: GET_CUSTOM_SPECIALIZATION_LIST,
    values,
  });
  export const getCustomSpecializationListSuccess = (values) => ({
    type: GET_CUSTOM_SPECIALIZATION_LIST_SUCCESS,
    values,
  });

