import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../../Common/Loader/Loader";
import { connect } from "react-redux";
import { userDelete } from "../../../../store/action/clientList.action";

class Table extends Component {
  state = {
    loadingIs: false,
  };

  handleDeleteUserConfirmation(id) {
    let confirmIs = window.confirm(`Do you want to countinue ?`);
    if (confirmIs === true) {
      this.setState({
        loadingIs: true,
      });
      const data = { _id: id, status: 2 };
      this.props.$userDelete(data);
      return true;
    } else {
      return false;
    }
  }

  handleDeleteArtist = (id) => {
    this.handleDeleteUserConfirmation(id);
  };

  render() {
    const {
      artistIs,
      ListLoading,
      firstname,
      lastname,
      username,
      email,
      handleArtist,
      artistRejected,
    } = this.props;

    const searchTable = [
      { placeholder: "Search firstname", name: "firstname", value: firstname },
      { placeholder: "Search lastname", name: "lastname", value: lastname },
      { placeholder: "Search username", name: "username", value: username },
      { placeholder: "Search email", name: "email", value: email },
    ];

    const tableContentArr = [
      { name: "Action" },
      { name: "Name" },
      { name: "Username" },
      { name: "Email" },
      { name: "Phone no." },
      { name: "Location" },
      { name: "Review Status" },
      { name: "Admin user (Approved/Rejected)" },
      { name: "Last Active" },      
    ];
    return (
      <React.Fragment>
        {/*------------------Search ---------------------*/}
        <div className="row col-md-12">
          <div className="col">
            <form className="row align-items-center">
              <div className="col-auto pr-0">
                <span className="fe fe-search text-muted"></span>
              </div>
              {searchTable.map((obj, index) => (
                <div className="col" key={index}>
                  <input
                    name={obj.name}
                    type="search"
                    value={obj.value}
                    className="form-control form-control-flush search"
                    placeholder={obj.placeholder}
                    onChange={handleArtist.handleOnChange}
                    autoComplete="off"
                  />
                </div>
              ))}
            </form>
          </div>
        </div>
        {/*------------------Search ---------------------*/}

        {ListLoading ? (
          <Loader />
        ) : (
          <div className="table-responsive mb-5 pb-5">
            <table className="table table-sm table-nowrap card-table">
              {/*-----------------table head -------------------------*/}

              <thead>
                <tr>
                  {tableContentArr.map(
                    (obj, index) =>
                      !(artistRejected && obj.name === "Action") && (
                        <React.Fragment key={index}>
                        {obj.name !== "Last Active" && <th>
                          <Link to="#!" className="text-muted">
                            {obj.name}
                          </Link>
                        </th>}
                         {obj.name === "Last Active" && <th onClick={handleArtist.handleSorting}>
                         <Link to="#!" className="text-muted sort" data-sort="tables-row">
                           {obj.name}
                         </Link>
                       </th>}
                       </React.Fragment>
                      )
                  )}
                </tr>
              </thead>

              {/*-----------------table head -------------------------*/}
              <tbody
                className="list"
                // onMouseOut={this.handleHideAction}
              >
                {artistIs.length > 0 ? (
                  artistIs.map((obj, index) => (
                    <tr key={index}>
                      {!artistRejected && (
                        <td className="text-center">
                          <div className={`dropdown`}>
                            <a
                              href="#!"
                              className="dropdown-ellipses dropdown-toggle text-secondary"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="fe fe-more-vertical"></i>
                            </a>
                            
                              <div
                                className={`ma-auto dropdown-menu dropdown-menu-right text-center`}
                              >
                                {obj.review_status === 1 && (
                                  <span>
                                    <Link
                                      to="#!"
                                      className="dropdown-item"
                                      datatoggle="modal"
                                      datatarget="#exampleModalCenter"
                                      onClick={() =>
                                        handleArtist.handleReviewStatus(
                                          obj,
                                          "accepted"
                                        )
                                      }
                                    >
                                      Accept Profile
                                    </Link>
                                    <Link
                                      to="#!"
                                      className="dropdown-item mb-2"
                                      onClick={() =>
                                        handleArtist.handleReviewStatus(
                                          obj,
                                          "rejected"
                                        )
                                      }
                                    >
                                      Reject Profile
                                    </Link>
                                  </span>
                                )}
                                <span>
                                  <Link
                                    to={`/artist/:view/:${obj._id}`}
                                    className="dropdown-item mb-2"
                                    datatoggle="modal"
                                    datatarget="#exampleModalCenter"
                                  >
                                    View Creator
                                  </Link>
                                </span>                                
                              </div>
                          </div>
                        </td>
                      )}
                      <td className="orders-order">
                        <Link
                          to={`/artist/:view/:${obj._id}`}
                          style={{ color: "black" }}
                        >
                          {obj.first_name + " " + obj.last_name}
                        </Link>
                      </td>
                      <td className="orders-date">{obj.username}</td>
                      <td className="orders-total">{obj.email}</td>
                      <td className="orders-method">{obj.phone_no}</td>
                      <td className="orders-method">{obj.location}</td>
                      <td className="orders-status">
                        <span
                          className={`mr-2 ${
                            obj.review_status === 0
                              ? "text-warning"
                              : obj.review_status === 1
                              ? "text-info"
                              : obj.review_status === 2
                              ? "text-success"
                              : obj.review_status === 3
                              ? "text-danger"
                              : ""
                          } `}
                        >
                          ●
                        </span>
                        {obj.review_status === 0
                          ? "Awaiting"
                          : obj.review_status === 1
                          ? "Draft"
                          : obj.review_status === 2
                          ? "Approved"
                          : obj.review_status === 3
                          ? "Rejected"
                          : ""}
                      </td>
                      <td className="orders-method">{obj.admin_email}</td>
                      <td className="orders-method">
                        {obj.last_active.split("T").slice(0)[0]}
                      </td>                      
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No Data Found...</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapState = ({ userDelete }) => {
  return {
    _userDelete: userDelete,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $userDelete: (values) => dispatchEvent(userDelete(values)),
});
export default connect(mapState, mapDispatch)(Table);
