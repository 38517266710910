import React, { Component } from "react";

import CustomDatePicker2 from "./datePickerCustom/index2";
export default class Milestone2 extends Component {
  render() {
    const {
        Gst,
        milestoneInputs,
        clientBudget,
        note,
        creatorCost,
        errorIs,
        EditIs,
        selectedDate,
        handleFunc,
        statusIs
      } = this.props;

      if ((Gst || Gst === 0) && (clientBudget || clientBudget === 0)) {
        var totalCost = Number(Gst) + Number(clientBudget);
      }
  
      const disabledMile = milestoneInputs.filter((item) => item?.status === 1);      
      const disabledId = disabledMile[0];
  
    return (
      <React.Fragment>
        <form>
          <div className="form-group">
            <div className="row">
              <div className="col">
                <label htmlFor="exampleInputEmail1" className="text-secondary">
                  Client Budget
                </label>
                <input
                  type="number"
                  className={`form-control ${
                    errorIs.clientBudget ? "error-show" : ""
                  }`}
                  placeholder="Enter Client Budget"
                  name="clientBudget"
                  value={clientBudget}
                  onChange={handleFunc.handleChange}
                />
                {errorIs.clientBudget && (
                  <span className="error">{errorIs.clientBudget}</span>
                )}
              </div>
              <div className="col">
                <label htmlFor="exampleInputEmail1" className="text-secondary">
                  Gst
                </label>
                <input
                  type="number"
                  className={`form-control ${errorIs.Gst ? `error-show` : ``}`}
                  placeholder="Enter Gst"
                  name="Gst"
                  value={Gst}
                  onChange={handleFunc.handleChange}
                />
                {errorIs.Gst && <span className="error">{errorIs.Gst}</span>}
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col text-right">
                <small>
                  Total Project Cost for Client : {totalCost ? totalCost : 0}
                </small>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col">
                <label htmlFor="exampleInputEmail1" className="text-secondary">
                  Creator cost
                </label>
                <input
                  type="number"
                  className={`form-control ${
                    errorIs.creatorCost ? `error-show` : ``
                  }`}
                  placeholder="Enter Creator cost"
                  name="creatorCost"
                  value={creatorCost}
                  onChange={handleFunc.handleChange}
                />
                {errorIs.creatorCost && (
                  <span className="error">{errorIs.creatorCost}</span>
                )}
              </div>
            </div>
          </div>
          <hr className="my-5" />
          {milestoneInputs?.map((obj, index) => (
            <React.Fragment key={index}>
              <div className="row ">
                <div className="col">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="text-secondary"
                  >
                    {`Milestone (${index + 1})`}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      obj.nameError ? "error-show" : ""
                    } ${obj.status === 2 ? "disabled" : ""}`}
                    placeholder="Milestone Name"
                    name="name"
                    value={obj.name}
                    onChange={(e) => handleFunc.handleMilestoneChange(e, index)}
                    disabled={obj.status === 2 ? true : false}
                  />
                  {obj.nameError && (
                    <span className="error">Required Field</span>
                  )}
                </div>
                <div className="col">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="text-secondary"
                  >
                    Due Date
                  </label>
                  <CustomDatePicker2
                  handleFunc={handleFunc}
                  obj={obj}
                  index={index}
                  selectedDate={selectedDate}
                  />
                  {obj.dateError && (
                    <span className="error">
                      {obj.dateError && !obj
                        ? "Required Field"
                        : "Choose next date from previous milestone"}
                    </span>
                  )}
                </div>
                <div className="col">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="text-secondary"
                  >
                    {`Iteration ${
                     ( EditIs && obj.submitted_iteration)
                        ? `/ (Submit Iteration ${obj.submitted_iteration})`
                        : ""
                    }`}
                  </label>
                  <input
                    type="number"
                    className={`form-control ${
                      obj.iterationError ? "error-show" : ""
                    } ${obj.status === 2 ? "disabled" : ""}`}
                    placeholder="iteration"
                    name="iteration"
                    value={obj.iteration}
                    onChange={(e) => handleFunc.handleMilestoneChange(e, index)}
                    disabled={obj.status === 2 ? true : false}
                  />
                  {obj.iterationError && (
                    <span className="error">
                      {obj.iteration === 0
                        ? "Iteration will be grater from 0"
                        : "Required Field"}
                    </span>
                  )}
                </div>
                {index !== 0 &&
                  !obj.submitted_iteration > 0 &&
                  (obj.status !== 2)&&
                    ( disabledId?._id !== obj._id) && (
                    <span className="p-2 d-flex align-items-center">
                      <i
                        className="fe fe-trash"
                        onClick={() => handleFunc.handleRemoveMilestone(index, obj)}
                      ></i>
                    </span>
                  )}
                {obj.submitted_iteration > 0 && (
                  <span
                    className="p-2 d-flex align-items-center "
                    data-toggle="tooltip"
                    data-placement="top"
                    title={`Submit Milestone (${obj?.submitted_iteration})`}
                  >
                    <i
                      className="fe fe-eye"
                      onClick={() => handleFunc.handleViewcompletedMiles(obj, index)}
                    ></i>
                  </span>
                )}
                {obj.submitted_iteration > 0 && obj.status === 1 && (
                  <span className="p-2 d-flex align-items-center">
                    <span
                      className="badge badge-soft-info"
                      onClick={() => handleFunc.handleApprovedMiles(obj)}
                    >
                      Approved
                    </span>
                  </span>
                )}
              </div>
              <hr className="my-5" />
            </React.Fragment>
          ))}
          {(milestoneInputs.slice(-1).pop().due_date && (statusIs !== 7 && statusIs !== 8 && statusIs !== 10)) &&(
            <React.Fragment>
              <div className="col-2">
                <p
                  className="mt-3 "
                  style={{ color: "#0056b3", cursor: "pointer" }}
                  onClick={handleFunc.handleAppendMilestone}
                >
                  + Add Milestone
                </p>
              </div>
              <hr className="my-5" />
            </React.Fragment>
          )}
          <div className="form-group">
            <div className="row">
              <div className="col">
                <label htmlFor="exampleInputEmail1" className="text-secondary">
                  Note
                </label>
                <textarea
                  className="form-control"
                  placeholder="...."
                  name="note"
                  value={note}
                  onChange={handleFunc.handleChange}
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
