import {
  COLLATERAL_LIST,
  COLLATERAL_LIST_SUCCESS,
  COLLATERAL_LIST_ERROR,
  COLLATERAL_IMAGE,
  COLLATERAL_IMAGE_SUCCESS,
  COLLATERAL_PCF,
  COLLATERAL_PCF_SUCCESS,
  COLLATERAL_GET,
  COLLATERAL_GET_SUCCESS,
  COLLATERAL_STATUS,
  COLLATERAL_STATUS_SUCCESS,
  COLLATERAL_APPROVED_PROJECT,
  COLLATERAL_APPROVED_PROJECT_SUCCESS,
  COLLATERAL_PENDING_PROJECT,
  COLLATERAL_PENDING_PROJECT_SUCCESS,
  COLLATERAL_PENDING_CREATOR,
  COLLATERAL_PENDING_CREATOR_SUCCESS,
  COLLATERAL_APPROVED_CREATOR,
  COLLATERAL_APPROVED_CREATOR_SUCCESS,
  COLLATERAL_CHANGE_HISTORY,
  COLLATERAL_CHANGE_HISTORY_SUCCESS,
  COLLATERAL_ACTIVE_TRANSACTION,
  COLLATERAL_ACTIVE_TRANSACTION_SUCCESS,
  COLLATERAL_ACTIVE_NEGOCIATIONS,
  COLLATERAL_ACTIVE_NEGOCIATIONS_SUCCESS,
  COLLATERAL_GET_ERROR,
  PENDING_COLLATERAL,
  PENDING_COLLATERAL_SUCCESS,
  PENDING_COLLATERAL_LIST,
  PENDING_COLLATERAL_LIST_SUCCESS,
  HIDE_COLLATERAL,
  HIDE_COLLATERAL_SUCCESS,
  DELETE_COLLATERAL_LIST,
  DELETE_COLLATERAL_LIST_SUCCESS,
  PENDING_COLLATERAL_LIST_ERROR,
  DELETE_COLLATERAL_LIST_ERROR,
  APPROVED_CREATOR_LIST,
  APPROVED_CREATOR_LIST_SUCCESS,
  APPROVED_STARRED_LIST,
  APPROVED_STARRED_LIST_SUCCESS,
  FEATURED_COLLATERAL,
  FEATURED_COLLATERAL_SUCCESS,
  RESET_APPROVED_CREATOR_LIST
} from "../type.action";

// collateral listing & Pagination********

export const collateralList = (values) => ({
  type: COLLATERAL_LIST,
  values,
});
export const collateralListSuccess = (values) => ({
  type: COLLATERAL_LIST_SUCCESS,
  values,
});
export const collateralListError = (error) => ({
  type: COLLATERAL_LIST_ERROR,
  error,
});

// collateral upload photo ******

export const collateralUploadPhoto = (values) => ({
  type: COLLATERAL_IMAGE,
  values,
});
export const collateralUploadPhotoSuccess = (values) => ({
  type: COLLATERAL_IMAGE_SUCCESS,
  values,
});

// collateral pcf name******************

export const collateralPCFName = (values) => ({
  type: COLLATERAL_PCF,
  values,
});
export const collateralPCFNameSuccess = (values) => ({
  type: COLLATERAL_PCF_SUCCESS,
  values,
});

//get collateral based on id***************

export const getCollateral = (values) => ({
  type: COLLATERAL_GET,
  values,
});
export const getCollateralSuccess = (values) => ({
  type: COLLATERAL_GET_SUCCESS,
  values,
});
export const getCollateralError = (error) => ({
  type: COLLATERAL_GET_ERROR,
  error,
});

// collateral accept/reject***************

export const statusCollateral = (values) => ({
  type: COLLATERAL_STATUS,
  values,
});
export const statusCollateralSuccess = (values) => ({
  type: COLLATERAL_STATUS_SUCCESS,
  values,
});

// collateral approved Project**************

export const collaterlApprovedProject = (values) => ({
  type: COLLATERAL_APPROVED_PROJECT,
  values,
});
export const collateralApprovedProjectSuccess = (values) => ({
  type: COLLATERAL_APPROVED_PROJECT_SUCCESS,
  values,
});

// collateral Pending Project*****************

export const collaterlPendingProject = (values) => ({
  type: COLLATERAL_PENDING_PROJECT,
  values,
});
export const collateralPendingProjectSuccess = (values) => ({
  type: COLLATERAL_PENDING_PROJECT_SUCCESS,
  values,
});

// collateral pending creator tab**********/

export const collateralPendingCreator = (values) => ({
  type: COLLATERAL_PENDING_CREATOR,
  values,
});
export const collateralPendingCreatorSuccess = (values) => ({
  type: COLLATERAL_PENDING_CREATOR_SUCCESS,
  values,
});

// collatreal approved creator tab********************

export const collateralApprovedCreator = (values) => ({
  type: COLLATERAL_APPROVED_CREATOR,
  values,
});
export const collateralApprovedCreatorSuccess = (values) => ({
  type: COLLATERAL_APPROVED_CREATOR_SUCCESS,
  values,
});

// collateral change history tab********************

export const collateralChangeHistory = (values) => ({
  type: COLLATERAL_CHANGE_HISTORY,
  values,
});
export const collateralChangeHistorySuccess = (values) => ({
  type: COLLATERAL_CHANGE_HISTORY_SUCCESS,
  values,
});

// collatrel active transactions tab*********************

export const collateralActiveTransaction = (values) => ({
  type: COLLATERAL_ACTIVE_TRANSACTION,
  values,
});
export const collateralActiveTransactionSuccess = (values) => ({
  type: COLLATERAL_ACTIVE_TRANSACTION_SUCCESS,
  values,
});

// collateral active negociation tab******************

export const collateralActiveNegociation = (values) => ({
  type: COLLATERAL_ACTIVE_NEGOCIATIONS,
  values,
});
export const collateralActiveNegociationSuccess = (values) => ({
  type: COLLATERAL_ACTIVE_NEGOCIATIONS_SUCCESS,
  values,
});

//Pending Collateral (Approvals Tab)
export const pendingCollateral = (values) => ({
  type: PENDING_COLLATERAL,
  values,
});
export const pendingCollateralSuccess = (values) => ({
  type: PENDING_COLLATERAL_SUCCESS,
  values,
});

// get pending Collateral List (Approval tab)
export const pendingCollateralList = (values) => ({
  type: PENDING_COLLATERAL_LIST,
  values,
});
export const pendingCollateralListSuccess = (values) => ({
  type: PENDING_COLLATERAL_LIST_SUCCESS,
  values,
});
export const pendingCollaterlListError = (error) => ({
  type: PENDING_COLLATERAL_LIST_ERROR,
  error,
});

// Hide Collateral from Client (collateral)
export const hideCollateral = (values) => ({
  type: HIDE_COLLATERAL,
  values,
});
export const hideCollateralSuccess = (values) => ({
  type: HIDE_COLLATERAL_SUCCESS,
  values,
});

// collater deleted List (Approval tab)
export const deleteCollateralList = (values) => ({
  type: DELETE_COLLATERAL_LIST,
  values,
});
export const deleteCollateralListSuccess = (values) => ({
  type: DELETE_COLLATERAL_LIST_SUCCESS,
  values,
});
export const deleteCollateralListerror = (error) => ({
  type: DELETE_COLLATERAL_LIST_ERROR,
  error,
});

// Approved creator (collateral (creator tab))
export const approvedCreatorList = (values) => ({
  type: APPROVED_CREATOR_LIST,
  values,
});

export const approvedCreatorListSuccess = (values) => ({
  type: APPROVED_CREATOR_LIST_SUCCESS,
  values,
});

export const approvedStarredList = (values) => ({
  type: APPROVED_STARRED_LIST,
  values,
});

export const approvedStarredListSuccess = (values) => ({
  type: APPROVED_STARRED_LIST_SUCCESS,
  values,
});

// featured collateral (collateral tab)
export const featuredCollaterl = (values) => ({
  type: FEATURED_COLLATERAL,
  values,
});
export const featuredCollateralSuccess = (values) => ({
  type: FEATURED_COLLATERAL_SUCCESS,
  values,
});

// reset the state of approved creator list
export const resetApprovedCreator = (values) =>({
  type:RESET_APPROVED_CREATOR_LIST,
  values,
})