import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import Loader from "../Common/Loader/Loader";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
// import Select from "react-select";
import { deleteAPI } from "../../../store/api-interface";
import CustomAddForm from "./CustomAddForm";
import CustomIndustry from "./CustomIndustry";
import {getCustomSoftwareList } from "../../../store/action/cutom.action";
import CustomSpecialization from "./CustomSpecialization";

class Custom extends Component {
  state = {
    LoadingIs: false,
    currentPage: 1,
    // email: "",
    // sort: "",
    // userName: "",
    userData: [],
    totalPage: "",
    // DeleteLoadingIs: false,
    // arr: [],
    // uniqueArr:[]
  };

  // handleOnChange = (e) => {
  //   const { name, value } = e.target;


  //   this.setState(
  //     {
  //       [name]: value,
  //     },
  //     () => {
  //       const { currentPage, email, sort, userName, arr,uniqueArr } = this.state;

  //       const data = {
  //         page: currentPage,
  //         email: email,
  //         sortIs: sort,
  //         user: userName,
  //       };
  //       this.handleBackendUser(data);
  //     }
  //   );
  // };

  // handleSelect = (e, obj) => {
  //   // console.log(e, obj)
  //   if(e.value !== obj.manager){
  //     if(window.confirm(`Confirm to change the manager status of ${obj.first_name} ${obj.last_name}`)){
  //       putAPI('/backendUser/change-manger-role', {_id: obj._id, role: `${e.value}`})
  //         .then(res => {
  //           // console.log(res)
  //           const { currentPage, email, sort, userName } = this.state;
  //           const data = {
  //             page: currentPage,
  //             email: email,
  //             sortIs: sort,
  //             user: userName,
  //           };
  //           this.handleBackendUser(data);
  //         }).catch(err => console.log(err))
  //     }
  //   }
  // }

  handleGetConfirmation = (deleteData) => {
    let confirmIs = window.confirm(`Do you want to Delete this item?`);
    if (confirmIs === true) {
      this.setState({ DeleteLoadingIs: true });
      this.handleDeleteItem(deleteData)
      return true;
    } else {
      return false;
    }
  };

  handleDeleteItem = async(deleteData) =>{
    try{
      const response = await deleteAPI('/onboarding/delete-software-industry-specialization',deleteData);
      const data = response.data
      // console.log(data)
      if(data?.status===1){
        const data2 = {
          page:this.state.currentPage,
          name:"",
          type:"software",
          limit:10
        }
        this.props.$getCustomSoftwareList(data2);
      }
    }catch(err){
      console.log(err)
    }
  }

  handleDelete = (obj) => {
    const deleteData = { type: 'software',name:obj?.name };
    this.handleGetConfirmation(deleteData);
  };

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    // const { email, sort, userName } = this.state;
    const dataIs = {
      page: selectedData,
      name: '',
      type: 'software',
      limit: 10,
    };
    this.handleBackendUser(dataIs);
  };

  handleBackendUser = (currentData) => {
    this.setState({ LoadingIs: true });
    // this.props.$backendDeleteList(currentData);
    this.props.$getCustomSoftwareList(currentData);

  };

  componentDidMount() {
    this.props.handleClients.handlepathName("/custom");
    const { currentPage } = this.state;
    // const data = {
    //   page: currentPage,
    //   email: email,
    //   sortIs: sort,
    //   user: userName,
    // };
    // this.handleBackendUser(data);

    const data2 = {
      page:currentPage,
      name:"",
      type:"software",
      limit:10
    }
    this.handleBackendUser(data2);
  }

  componentWillReceiveProps(nextProps) {
    // console.log("-----------------custom list",nextProps)
    const listIs = nextProps._getCustomList.customSoftwareList;
    // const deletedRes = nextProps._deleteBackendUser.deleteUSerIs;
    // const { DeleteLoadingIs } = this.state;
    if (nextProps) {
      this.setState({
        userData: listIs.data,
        totalPage: listIs.totalPages,
        LoadingIs: false,
        currentPage:listIs.currentPage,

      });
    }

    // if (deletedRes && DeleteLoadingIs) {
    //   const data = {
    //     page: 1,
    //     email: "",
    //     sortIs: "",
    //     user: "",
    //   };
    //   this.handleBackendUser(data);
    //   this.setState({ DeleteLoadingIs: false });
    // }
  }
  
  callComponentDidMount = () => {
    this.componentDidMount()
  }

  render() {
    const { LoadingIs, userData } = this.state;

    const otherDbsArr = [
      { name: " " },     
      { name: "name" },     
      { name: "Active" },     
    ];

    // const searchTable = [
    //   { placeholder: "Search Email", name: "email", value: email },
    //   { placeholder: "Search Username", name: "userName", value: userName },
    // ];

    // const sortOptions = [
    //   { value: 0, label: 'No' },
    //   { value: 1, label: 'Yes' }
    // ]
    // const sortValue = obj => sortBy === '-1' ? return sortOptions[0] : return sortOptions[1]
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/custom/AddForm/:type/:id"
            render={(props) => <CustomAddForm {...props} mountListAgain={this.callComponentDidMount}/>}
          />
          <Route
            path="/custom/Industry"
            render={(props) => <CustomIndustry {...props} mountListAgain={this.callComponentDidMount}/>}
          />
          <Route
            path="/custom/Specialization"
            render={(props) => <CustomSpecialization {...props} mountListAgain={this.callComponentDidMount}/>}
          />
          <section className="client">
            <div className="container-fluid mx-auto">
              {/* <div className="row col-md-12 mb-5">
                <div className="col">
                  <form className="row align-items-center">
                    <div className="col-auto pr-0">
                      <span className="fe fe-search text-muted"></span>
                    </div>
                    {searchTable.map((obj, index) => (
                      <div className="col" key={index}>
                        <input
                          name={obj.name}
                          type="search"
                          value={obj.value}
                          className="form-control form-control-flush search"
                          placeholder={obj.placeholder}
                          onChange={this.handleOnChange}
                          autoComplete="off"
                        />
                      </div>
                    ))}
                  </form>
                </div>
              </div> */}
              {LoadingIs ? (
                <Loader />
              ) : (
                <div className="table-responsive">
                  <table className="table table-sm table-nowrap card-table">
                    {/*----------------- table head --------------------*/}
                    <thead>

                      <tr>
                        {otherDbsArr.map((obj, index) => (
                          <th key={index}>
                            <Link to="#!" className="text-muted sort">
                              {obj.name}
                            </Link>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    {/*----------------- table head --------------------*/}
                    <tbody className="list">
                      {userData?.length > 0 ? (
                        userData.map((obj, index) => (
                          <tr key={index}>
                          < td className="text-center">
 {                       obj.status===1 && <div className={`dropdown`}>
                          <a
                            href="#!"
                            className="dropdown-ellipses dropdown-toggle text-secondary"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fe fe-more-vertical"></i>
                          </a>
                            <div
                              className={`ma-auto dropdown-menu dropdown-menu-right text-center`}
                            >
                                <span>
                                  <li
                                    // href={`${WebsiteUrl}creator/${obj?.user?.username}`}
                                    target='_blank'
                                    className="dropdown-item mb-2"
                                    datatoggle="modal"
                                    datatarget="#exampleModalCenter"
                                    style={{cursor:"pointer"}}
                                    onClick={()=>this.handleDelete(obj)}
                                  >
                                    Delete
                                  </li>
                                </span>
                              
                            </div>
                        </div>}
                      
                          </td>
                            <td className="orders-total">{obj.name}</td>
                            <td>
                              <div
                                className={`badge ${
                                  obj.status===1
                                    ? `badge-soft-success`
                                    : `badge-soft-danger`
                                }`}
                              >
                                {obj.status===1?"Active":"Inactive"}
                              </div>
                              </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>No data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}

              {/****************** Pagination ********************/}

              {this.state.totalPage > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.totalPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </div>
          </section>
        </Switch>
      </React.Fragment>
    );
  }
}
const mapState = ({ customReducer }) => {
  return {
    _getCustomList:customReducer,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $getCustomSoftwareList : (values) => dispatchEvent(getCustomSoftwareList(values)),
});
export default connect(mapState, mapDispatch)(Custom);
