import React, { Component } from "react";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";
// import Loader from "../Common/Loader/Loader";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    maxWidth: "1000px",
    width: "100%",
    maxHeight:"40rem"
  },
};

class Popup extends Component {
  handleRedirect = (id) => {
    window.open(`/artist/:view/:${id}`, "_blank");
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <Modal isOpen={this.props?.showPopup} style={customStyles} contentLabel="Example Modal">
            <button
              type="button"
              className="close media-upload-modal"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.props?.handleTogglePopup}
            >
              <span aria-hidden="true" style={{ color: "black", fontSize: "32px" }}>
                &times;
              </span>
            </button>
            <h2 style={{ color: "#6e84a3" }}>{this.props?.isSubscribe ? "Subscribe Users List" : "Unsubscribe Users List"}</h2>
            <React.Fragment>
              <section className="creative-field creative_wrapper">
                <div className="container-custom mx-auto">
                  <div className="table-responsive mb-5">
                    <table className="table table-sm table-nowrap card-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                        </tr>
                      </thead>
                      <tbody className="list" style={{maxHeight:"35rem",overflowY:"auto"}}>
                        {this.props?.loading ? (
                          <span className="d-flex justify-content-start align-items-center ml-5 mt-5 mb-5">
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            <div className="spinner-grow" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </span>
                        ) : this.props?.isSubscribe ? (
                          this.props?.allUserSubscribe?.length > 0 ? (
                            this.props?.allUserSubscribe.map((item, index) => (
                              <tr key={index}>
                                <td
                                  style={{ cursor: "pointer", color: "#2c7be5" }}
                                  onClick={() => this.handleRedirect(item?._id)}
                                >
                                  {item?.first_name + " " + item?.last_name}
                                </td>
                                <td>{item?.email}</td>
                                <td>{item?.phone_no}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td>
                                <h3 className="mt-5 mb-4" style={{ color: "#6e84a3" }}>
                                  No Data Found...
                                </h3>
                              </td>
                            </tr>
                          )
                        ) : this.props?.allUserUnsubscribe?.length > 0 ? (
                          this.props?.allUserUnsubscribe.map((obj, i) => (
                            <tr key={i}>
                              <td style={{ cursor: "pointer", color: "#2c7be5" }} onClick={() => this.handleRedirect(obj?._id)}>
                                {obj?.first_name + " " + obj?.last_name}
                              </td>
                              <td>{obj?.email}</td>
                              <td>{obj?.phone_no}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>
                              <h3 className="mt-5 mb-4" style={{ color: "#6e84a3" }}>
                                No Data Found...
                              </h3>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>

              {/******** pagination ************* */}

              {(this.props?.isSubscribe ? this.props?.totalPagesSub > 0 : this.props?.totalPagesUnSub > 0) && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.props?.isSubscribe ? this.props?.totalPagesSub : this.props?.totalPagesUnSub}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.props.handlePageClickPopup}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </React.Fragment>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export default Popup;
