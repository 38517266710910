import React, { Component } from "react";
import Loader from "../../Common/Loader/Loader";
import Table from "./Table";
import { connect } from "react-redux";
import { pendingProjectList } from "../../../../store/action/projectList.action";
import { stausArtistProject } from "../../../../store/action/clientList.action";
import ReactPaginate from "react-paginate";
import ErrorApi from "../../Common/Error/ErrorApi";

class ProjectUpload extends Component {
  state = {
    LoadingIs: false,
    currentPage: 1,
    projectDataIs: [],
    showAction: false,
    showActionIndex: "",
    statusIs: false,
    totalPage: 0,
    sort: -1,
    sortFlagIs : false,
    projectIdIs : ''
  };

  setArtistState = (stateObj) => {
    this.setState(stateObj);
  };

  handleSorting = () => {
    this.setState({ sortFlagIs : !this.state.sortFlagIs },()=>{
      const { currentPage } = this.state;
      let data = null;
      if(this.state.sortFlagIs){
        data = { page: currentPage, sortIs: 1 };
      }else{
        data = { page: currentPage, sortIs: -1 };
      }
      this.setState({ sort : this.state.sortFlagIs ? 1 : -1 })
      this.props.$pendingProjectList(data);
    })
  };

  handleShowAction = (index) => {
    const { showAction } = this.state;
    this.setState({
      showAction: !showAction,
      showActionIndex: index,
    });
  };

  handleHideAction = () => {
    this.setState({
      showAction: true,
    });
  };

  handleGetConfirmation(id, obj) {
    let confirmIs = window.confirm(
      `Do you want to ${obj === 1 ? `accept` : `reject`} this Project ?`
    );
    if (confirmIs === true) {
      const projectStatus = { _id: id, status: obj };
      this.props.$stausArtistProject(projectStatus);
      this.setState({
        statusIs: true,
      });
      return true;
    } else {
      return false;
    }
  }

  handleStatusProject = (id, status) => {
    let currentStatus = 0;
    if (status === "accept") {
      currentStatus = 1;
    } else if (status === "reject") {
      currentStatus = 2;
    }
    this.handleGetConfirmation(id, currentStatus);
  };

  handlePageClick = (data) => {
    const { sort } = this.state;
    let selectedData = data.selected + 1;
    const dataIs = { page: selectedData, sortIs: sort };
    this.props.$pendingProjectList(dataIs);
  };

  handleProjectUpload = () => {
    const { currentPage, sort } = this.state;
    this.setState({
      LoadingIs: true,
    });
    const data = { page: currentPage, sortIs: sort };
    this.props.$pendingProjectList(data);
  };

  componentDidMount() {
    this.handleProjectUpload();
  }

  componentWillReceiveProps(nextProps) {
    const { statusIs } = this.state;
    const PendingProjectData = nextProps._pendingProjectList.pendingList;
    const projectStatus = nextProps._stausArtistProject.artistProStatus;
    if (PendingProjectData) {
      this.setState({
        projectDataIs: PendingProjectData.projectdata,
        totalPage: PendingProjectData.totalPages,
        LoadingIs: false,
      });
    }
    if (projectStatus && statusIs) {
      this.handleProjectUpload();
      this.setState({
        statusIs: false,
      });
    }
  }

  render() {
    const projectFunc = {
      handleShowAction: this.handleShowAction,
      handleStatusProject: this.handleStatusProject,
      handleSorting: this.handleSorting,
      setArtistState : this.setArtistState
    };

    const { LoadingIs } = this.state;
    return (
      <section>
        {this.props._pendingProjectList.pendingListErr ? (
          <ErrorApi {...this.props}/>
        ) : (
          <React.Fragment>
            {LoadingIs ? (
              <Loader />
            ) : (
              <section className="client mt-5">
                <div className="container-fluid mx-auto">
                  <Table
                    {...this.state}
                    {...this.props}
                    handleProject={projectFunc}
                  />
                  {/****************** Pagination ********************/}

                  {this.state.totalPage > 0 && (
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.totalPage}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  )}
                </div>
              </section>
            )}
          </React.Fragment>
        )}
      </section>
    );
  }
}
const mapState = ({ pendingProjectList, stausArtistProject }) => {
  return {
    _pendingProjectList: pendingProjectList,
    _stausArtistProject: stausArtistProject,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $pendingProjectList: (values) => dispatchEvent(pendingProjectList(values)),
  $stausArtistProject: (values) => dispatchEvent(stausArtistProject(values)),
});
export default connect(mapState, mapDispatch)(ProjectUpload);
