import React, { Component } from "react";

export default class LoaderSmall extends Component {
  render() {
    return (
      <React.Fragment>
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow spinner-grow-sm" role="status">
            <span className="sr-only">Loading...</span>
          </div>
      </React.Fragment>
    );
  }
}
