import { call, put } from "redux-saga/effects";
import { deleteAPI, getPublicAPI, postAPI, putAPI } from "../api-interface";

// import axios from "../api-interface";
import {
  projectListSuccess,
  pendingProjectListSuccess,
  getPendingProjectDataSuccess,
  projectReviewListSuccess,
  getProjectReviewDataSuccess,
  pendingProjectListError,
  getProjectReviewDataError,
  AllCollateralListSuccess,
  getUserListSuccess,
  addPostProjectSuccess,
  getPostProjectDataSuccess,
  addproposalSuccess,
  updateProjectSuccess,
  updateProposalSuccess,
  addCycleProposalSuccess,
  deleteCycleProposalSuccess,
  editCycleProposalSuccess,
} from "../action/projectList.action";
import { transactionGetDataSuccess } from "../action/transaction.action";

// Project Listing and pagination*********
export function* onProjectList(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        "/collateral/get-collateral-list?page=" + values + "&limit=15"
      );
      if (data.status === 200) {
        yield put(projectListSuccess(data.data));
      }
    }
  } catch (error) {
    alert(`ERROR : ${error.response.status}`);
  }
}

// Pending Project List (Approvals)
export function* onPendingProjectList(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/pending-user-projects?page=${values.page}&limit=10&sort=${values.sortIs}`
      );
      if (data.status === 200) {
        yield put(pendingProjectListSuccess(data.data));
      }
    }
  } catch (error) {
   if (error.response.status === 401) {
      yield put(pendingProjectListError(error.response.status));
    }
  }
}

// get pending project based on id(Approvals)

export function* onGetPendingData(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/pending-user-projectdata?_id=${values}`
      );
      if (data.status === 200) {
        yield put(getPendingProjectDataSuccess(data.data));
      }
    }
  } catch (error) {
    //alert(`ERROR : ${error.response.status}`);
  }
}

// Project Review List (Approval)

export function* onGetProjectReviewList(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/pending-projects-review?page=${values.pageIs}&limit=10&sort=${values.sortIs}`
      );
      if (data.status === 200) {
        yield put(projectReviewListSuccess(data.data));
      }
    }
  } catch (error) {
   if(error.response.status === 401){
     yield put(getProjectReviewDataError(error.response.status))
   }
  }
}

// project Review Data based on id (Approval)

export function* onProjectReviewDta(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/pending-projects-review-data?_id=${values}`
      );
      if (data.status === 200) {
        yield put(getProjectReviewDataSuccess(data.data));
      }
    }
  } catch (error) {
    //alert(`ERROR : ${error.response.status}`);
  }
}

// all Collateral List (transaction tab)
export function* onAllCollateralList(data) {
  let values = data.values;
  try {
      const data = yield call(
        getPublicAPI,
        `/collateral-visible?name=${values ? values : ''}`
      );
      if (data.status === 200) {
        yield put(AllCollateralListSuccess(data.data));
      }
  } catch (error) {
    //alert(`ERROR : ${error.response.status}`);
  }
}

// user List client, creator,representative (transaction tab)
export function* onGetUserList(data) {
  let values = data.values;
  try {
      const data = yield call(
        getPublicAPI,
        `/postProject/get-user-for-project?email=${values ? values : ''}`
      );
      if (data.status === 200) {
        yield put(getUserListSuccess(data.data));
      }
  } catch (error) {
    //alert(`ERROR : ${error.response.status}`);
  }
}

// all Collateral List (transaction tab)
export function* onAddPostProject(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        postAPI,
        `/postProject/add-post-project-admin`, values
      );
      if (data.status === 200) {
        yield put(addPostProjectSuccess(data.data));
      }
    }
  } catch (error) {
    //alert(`ERROR : ${error.response.status}`);
  }
}

// get post project Data (transcation tab)
export function* onGetPostProject(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/postProject/get-postproject-admin?_id=${values}`
      );
      if (data.status === 200) {
        yield put(getPostProjectDataSuccess(data.data));
          yield put(transactionGetDataSuccess(data.data));
      }
    }
  } catch (error) {
    //alert(`ERROR : ${error.response.status}`);
  }
}

// add Proposal (transaction tab)
export function* onAddProposal(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        postAPI,
        `/postProject/add-proposal-admin`, values
      );
      if (data.status === 200) {
        yield put(addproposalSuccess(data.data));
      }
    }
  } catch (error) {
    //alert(`ERROR : ${error.response.status}`);
  }
}

// update Project (transcation tab)
export function* onUpdateProject(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        putAPI,
        `/postProject/update-post-project-admin`, values
      );
      if (data.status === 200) {
        yield put(updateProjectSuccess(data.data));
      }
    }
  } catch (error) {
    //alert(`ERROR : ${error.response.status}`);
  }
}

// update proposal (transcation tab)

export function* onUpdateProposal(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        putAPI,
        `/postProject/update-proposal-admin`, values
      );
      if (data.status === 200) {
        yield put(updateProposalSuccess(data.data));
      }
    }
  } catch (error) {
    //alert(`ERROR : ${error.response.status}`);
  }
}

export function* onAddProposalCycle(data){
  let values = data.values;
  try{
    if(values){
      const data = yield call(
        postAPI,
        `/postProject/add-cycle-admin`,
        values
        );
      if(data.status === 200){
        yield put(addCycleProposalSuccess(data.data));
      }
    }
  }catch(err){
    console.log(err)
  }
}
export function* OnDeleteProposalCycle(data){
  let values = data.values;
  try{
    if(values){
      const data = yield call(
        deleteAPI,
        `/postProject/delete-cycle-admin`,
        values
        );
      if(data.status === 200){
        yield put(deleteCycleProposalSuccess(data.data));
      }
    }
  }catch(err){
    console.log(err)
  }
}
export function* OnEditProposalCycle(data){
  let values = data.values;
  try{
    if(values){
      const data = yield call(
        putAPI,
        `/postProject/edit-cycle-admin`,
        values
        );
      if(data.status === 200){
        yield put(editCycleProposalSuccess(data.data));
      }
    }
  }catch(err){
    console.log(err)
  }
}