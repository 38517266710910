import React, { Component } from "react";
import { connect } from "react-redux";
import { transitionDashboard } from "../../../store/action/Milestone.action";
import DatePicker from "react-datepicker";
import LoaderSmall from "../Common/Loader/LoaderSmall";
import Moment from "moment"
import Select from "react-select";
import { getPublicAPI } from '../../../store/api-interface';

class AMDashboard extends Component {
  state = {
    transDashboardData: [],
    from: "",
    to: "",
    Loading: false,
    collVal: { label: "All", value: "" },
    collatList: [],
    totalTrans:[],
    activeBrief: [],
    activeProj: [],
    compProj: [],
    id: "",
    clientOption: [],
    creatorOption: [],
    sizeTransOption: [],
    ratingOption: [],
    ratingOptionTotal: [],
    ratingOptionCreator:[],
    closedBrief: [],
    closedProj: [],
    approvePending: [],
    inactiveB: [],
    inactiveP: [],
    rejected: [],
    totalTransBudget: "",
    activeBriefBudget: "",
    activeProjBudget: "",
    compProjBudget: "",
    closedBriefBudget: "",
    closedProjBudget: "",
    rejectedBudget: "",
    approvePendingBudget: "",
    inactiveBBudget: "",
    inactivePBudget:""
    
  };

  handleTransDashboardData = () => {
    const { from, to , collVal ,id} = this.state;
    // console.log("from, to = ",from, to)
    this.setState({
      Loading: true,
    });
    let from_date = ""
    let to_date = ""
    if (from && to) {
      from_date = Moment(from).format("YYYY-MM-DD")
      to_date = Moment(to).format("YYYY-MM-DD")
    }
    const listData = { from_date,to_date,id,collId: collVal.value};
    this.props.$transitionDashboard(listData);
  };

  handleSubmitDates = () => {
    const { from, to } = this.state;
     let from_date = Moment(from).format("YYYY-MM-DD");
    let to_date = Moment(to).format("YYYY-MM-DD");
    let diff = Moment(to_date, "YYYY-MM-DD").diff(
      Moment(from_date, "YYYY-MM-DD")
    );
    // console.log("from_date to_date = ",from_date,to_date,diff)
    if (diff < 0 ) {
      alert("From date should be less than to date.")
    } else if (from && to) {
      this.handleTransDashboardData();
    } else {
      alert("Please provide both from date and to date.")
    }
  }

  componentDidMount() {
    let id = this.props.match.params?.id?.split(":")?.pop();
    this.setState({id}, () => {
      this.handleTransDashboardData();
      this.handleCollList()
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params?.id?.split(":")?.pop() !== prevProps.match.params?.id?.split(":")?.pop()) {
      let id = this.props.match.params?.id?.split(":")?.pop();
      this.setState({id, totalTransBudget: "",
        activeBriefBudget: "",
        activeProjBudget: "",
        compProjBudget: "",
        closedBriefBudget: "",
        closedProjBudget: "",
        rejectedBudget: "",
        approvePendingBudget: "",
        inactiveBBudget: "",
        inactivePBudget:""}, () => {
        this.handleTransDashboardData();
        this.handleCollList()
      })
    }
  }

  handleCollList = () => {
   getPublicAPI(`/backendUser/get-all-collateral`).then((res) => {
     if (res) {
      //  console.log("res = ", res)
       let data = res.data?.allCollateral
       const arrColl = [{label:"All",value:""}]

        data && data.forEach(item => {
            arrColl.push({value:item?._id,label:item?.name})
        });
        this.setState({ collatList:arrColl });
      }
    }).catch((err)=>{
      if(err.response.status === 401){
        this.handleLogout();
      }
    });
  }

  handleApi = (check,action) => {
    const { from, to, collVal,id } = this.state;
    let from_date = ""
    let to_date = ""
    if (from && to) {
      from_date = Moment(from).format("YYYY-MM-DD")
      to_date = Moment(to).format("YYYY-MM-DD")
    }
    // console.log("check == ", check)
    getPublicAPI(`/backendUser/manager-dashboard-data?status=${check}&from_date=${from_date}&till_date=${to_date}&manager_id=${id}&collateral_id=${collVal.value}&action=${action}`).then((res) => {
     if (res) {
      //  console.log("res = ", res)
       let data = res.data?.postProjectByStatus
       let minB = res.data?.avg_min_budget ? res.data.avg_min_budget : ""
       let maxB = res.data?.avg_max_budget ? res.data.avg_max_budget : ""
       let min_max = minB & maxB ? minB + "-" + maxB : ""
       let avg_sub_cost = res.data?.avg_sub_cost_total ? res.data.avg_sub_cost_total : ""
       let result = []
       data && data.forEach(item => {
         let budget = ""
         if (check == 6 || check == 7 || (check == 8 && action == 6)) {
           let val = item?.sub_total_cost ? this.separateNumber(Math.round(item?.sub_total_cost)): ""
           budget = val ? `(₹ ${val})` : "" 
         } else {
           let min = item?.min_budget ? this.separateNumber(Math.round(item?.min_budget)) : ""
           let max = item?.max_budget ? this.separateNumber(Math.round(item?.max_budget)) : ""
           budget = min && max ? `(₹ ${min} - ₹ ${max})` : ""
         }
          result.push({value:item?._id,label: `${item?.project_name} ${item?.userDetails ? ` (${item.userDetails})` : ""} ${budget}`})
       });
       if (check == 4) {
         this.setState({ totalTrans:result,totalTransBudget:min_max });
       } else if (check == 5 &&  action == 1) {
         this.setState({ activeBrief:result , activeBriefBudget:min_max});
       } else if (check == 6 &&  action == 1) {
         this.setState({ activeProj:result, activeProjBudget:avg_sub_cost });
       } else if(check == 7){
         this.setState({ compProj:result , compProjBudget:avg_sub_cost });
       } else if (check == 8 &&  action == 5) {
         this.setState({ closedBrief:result , closedBriefBudget:min_max });
       } else if (check == 8 && action == 6){
         this.setState({ closedProj:result , closedProjBudget:avg_sub_cost });
       }else if (check == 9) {
         this.setState({ rejected:result , rejectedBudget:min_max });
       } else if(check == 3){
         this.setState({ approvePending:result , approvePendingBudget:min_max });
       } else if (check == 5 &&  action == 0) {
         this.setState({ inactiveB:result , inactiveBBudget:min_max });
       } else if (check == 6 && action == 0){
         this.setState({ inactiveP:result , inactivePBudget:avg_sub_cost });
       }
        
      }
    }).catch((err)=>{
      if(err.response.status === 401){
        this.handleLogout();
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const transDashboard = nextProps._transitionDashboard.transitionDashboardIs;
    // console.log("transDashboard = ",transDashboard.manager_data)
    if (transDashboard) {
      let clientOption = []
      let creatorOption = []
      let sizeTransOption = []
      let ratingOption = []
      let ratingOptionTotal = []
      let ratingOptionCreator = []
      transDashboard?.manager_data && transDashboard?.manager_data?.totalActiveClientList && 
        transDashboard.manager_data.totalActiveClientList.forEach(item => {
          clientOption.push({label:item?.first_name+ " " + item?.last_name,value:item?._id})
        })
      transDashboard?.manager_data && transDashboard?.manager_data?.totalActiveCreatorList && 
        transDashboard.manager_data.totalActiveCreatorList.forEach(item => {
          creatorOption.push({label:item?.first_name+ " " + item?.last_name,value:item?._id})
        })
      transDashboard?.manager_data && transDashboard?.manager_data?.avg_min_max_budget_list && 
        transDashboard.manager_data.avg_min_max_budget_list.forEach(item => {
          sizeTransOption.push({label:`${item?.project_name} (${item?.min_budget} - ${item?.max_budget})`,value:item?._id})
        })
      transDashboard?.manager_data && transDashboard?.manager_data?.allProjectNameWithRating && 
        transDashboard.manager_data.allProjectNameWithRating.forEach(item => {
          ratingOption.push({ label: `${item?.project_name} (Client : ${item?.client_rating})`, value: item?._id })
          ratingOptionTotal.push({ label: item?.project_name, value: item?._id })
        })
      transDashboard?.manager_data && transDashboard?.manager_data?.allProjectNameWithRatingCreator && 
        transDashboard.manager_data.allProjectNameWithRatingCreator.forEach(item => {
          ratingOptionCreator.push({ label: `${item?.project_name} (Creator : ${item?.creator_rating})`, value: item?._id })
      })
      
      this.setState({
        transDashboardData: transDashboard?.manager_data,
        creatorOption,
        clientOption,
        sizeTransOption,
        ratingOption,
        ratingOptionTotal,
        ratingOptionCreator,
        Loading: false,
      });
    }
  }

  handleCardWithSelect = (name, icon, check, data, budget, value, action) => {

    // console.log("budget = ", budget)
    
    let budgetFinal = ""

    if (check == 6 || check == 7 || (check == 8 && action == 6)) {
      let bgt = budget ? this.separateNumber(Math.round(budget)) : ""
      budgetFinal =  bgt ?
        <span style={{ color: "#6e84a3", fontSize: "15px" }}> {" ("}
          {"₹ "}{bgt}{")"}
        </span> 
        : ""
    } else {
      let a1 = budget ? budget?.split("-")?.[0] : ""
      let a2 = budget ? budget?.split("-")?.[1] : ""

      let budget_min = a1 ? this.separateNumber(Math.round(a1)) : ""
      let budget_max = a2 ? this.separateNumber(Math.round(a2)) : ""

      budgetFinal = budget_min && budget_max ?
        <span style={{ color: "#6e84a3", fontSize: "15px" }}> {" ("}
          {"₹ "}{budget_min}
          {" - ₹ "}{budget_max} {")"}
        </span> 
        : ""
    }


    return (
      <div className="col-4" >
          <div className="card" style={{boxShadow: "0px 1px 6px 3px lightgrey"}}>
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-9 col-sm-12 col-md-12 col-lg-9">
                  <h6 className="card-title text-uppercase mb-2" style={{color:"#6e84a4"}}>
                    {name}
                </h6>
                {this.state.Loading ? <LoaderSmall /> : <span className="h2 mb-0">{value || value == 0 ? value : "0"} { budgetFinal}</span>}
              </div>
              {this.state.Loading ? "" :
                <div className="col-3 col-sm-12 col-md-12 col-lg-3">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleApi(check,action)}
                  >
                    Load Project
                  </button>
                </div>
              }
                <div className="col-7 col-sm-12 col-md-12 col-lg-7 mt-4">
                  <Select
                    options={data}
                    onChange={this.handleSelectProject}
                    placeholder={"Select Project"}
                    isDisabled={data && data?.length > 0 ? false : true}
                  />
                </div>
              </div>
            </div>
          </div>
      </div>
    )
  }

  handleSelectProject = (value) => {
    window.open(`/transactions/transactionsDetails/:${value?.value}`, "_blank");
  }

  handleClientCreator = (value,go) => {
    if (go === "client") {
      window.open(`/clients/:edit/:${value?.value}`, "_blank");
    } else if(go === "creator") {
      window.open(`/artist/:view/:${value?.value}`, "_blank");
    } else if (go = "sizeTrans") {
      window.open(`/transactions/transactionsDetails/:${value?.value}`, "_blank");
    } else if (go = "rating") {
      window.open(`/transactions/transactionsDetails/:${value?.value}`, "_blank");
    }
    
  }

  handleCard = (name, icon, extra, value, side, only, option, go) => {
    // console.log("option, go = ",option, go)
    let sides = side ? side : ""
    let values = value || value == 0 ? value : 0
    if (sides) {
      if (values <= 60) {
        values = values + (values > 1 ? " mins" : " min")
      } else {
        let x = values / 60
        let val =  Math.round(x) === x ? x : x?.toFixed(2)
        values = val + (values >= 120 ? "  hours" : " hour")
      }
    }

    let tpwr = ""

    if (go === "rating") {
      tpwr = this.state.transDashboardData?.total_project_with_rating ? this.state.transDashboardData.total_project_with_rating : ""
    }else if(go==='ratingCreator'){
      tpwr = this.state.transDashboardData?.total_project_with_rating_creator ? this.state.transDashboardData.total_project_with_rating_creator : ""

    }
    
    return (
      <div className="col-4">
          <div className="card" style={{boxShadow: "0px 1px 6px 3px lightgrey"}}>
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-10 col-sm-12 col-md-12 col-lg-10">
                  <h6 className="card-title text-uppercase mb-2" style={{color:"#6e84a4"}}>
                    {name}
                </h6>
                {this.state.Loading ? <LoaderSmall /> : <span className="h2 mb-0">{only && <i className=" fas fa-rupee-sign text-muted mb-0 mr-2" style={{ fontSize: "17px" }} />}{values || values == 0 ? values + extra : "0"} {tpwr ? <span style={{ color: "#6e84a3",fontSize:"15px" }}>{`(Total Projects : ${tpwr})`}</span> : ""}</span>}
                </div>
                {icon && (
                  <div className="col-2 col-sm-12 col-md-12 col-lg-2">
                    <span
                      className={`h2 text-muted mb-0 fe ${icon}`}
                    ></span>
                  </div>
              )}
              {option ?
                <div className="col-7 col-sm-12 col-md-12 col-lg-7 mt-4">
                  <Select
                    options={option}
                    onChange={(e) => this.handleClientCreator(e,go)}
                    placeholder={"Select Project"}
                  />
                </div>
                : ""
              }
              </div>
            </div>
          </div>
      </div>
    )
  }

  handleCardSelect = (name,icon,extra,value) => {
    return (
      <div className="col-4" >
          <div className="card" style={{boxShadow: "0px 1px 6px 3px lightgrey"}}>
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col">
                  <h6 className="card-title text-uppercase mb-2" style={{color:"#6e84a4"}}>
                    {name}
                </h6>
                {this.state.Loading ? <LoaderSmall /> : <span className="h2 mb-0">{value || value == 0 ? value + extra : "-"}</span>}
                </div>
                {icon && (
                  <div className="col-auto">
                    <span
                      className={`h2 text-muted mb-0 fe ${icon}`}
                    ></span>
                  </div>
                )}
              </div>
            </div>
          </div>
      </div>
    )
  }

  handleSelect = (value) => {
    this.setState({ collVal: value }, () => {
      this.handleTransDashboardData();
    })
  }

  separateNumber = (number) => {
    const num = parseInt(number, 10)
    let output = num.toLocaleString('en-IN');
    return output;
  }

  render() {

    const { from, to, transDashboardData, collVal, collatList, activeBrief, activeProj, compProj, totalTrans, creatorOption,
      ratingOption, ratingOptionTotal, ratingOptionCreator,sizeTransOption, clientOption, closedBrief, closedProj,  approvePending,
    inactiveB, inactiveP,rejected , totalTransBudget, activeBriefBudget, activeProjBudget, compProjBudget,closedBriefBudget,
    closedProjBudget, rejectedBudget, approvePendingBudget, inactiveBBudget,inactivePBudget } = this.state;
    
    return (
      <React.Fragment>
        <div className="row">
            <div className="col-12 mb-3">
              <h2 style={{color:"#6e84a3"}}>Dashboard</h2>
            </div>
        </div>
        
        <div className="row">
            <div className="col-12">
              <div className="card">
              <div className="row card-header">
                <div className="col-4">
                  <div className="form-group">
                    <label>From</label>
                    <DatePicker
                      className="form-control"
                      selected={from}
                      onChange={(date) => this.setState({from:date})}
                      name="from"
                      placeholderText="From"
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>To</label>
                    <DatePicker
                      className="form-control"
                      selected={to}
                      onChange={(date) => this.setState({to:date})}
                      name="to"
                      placeholderText="To"
                      dateFormat="dd-MM-yyyy"
                      minDate={from}
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <label style={{marginTop:"45px"}}></label>
                    <button
                      className="btn btn-primary"
                      onClick={this.handleSubmitDates}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              </div>
          </div>
          <div className="col-2 mt-3 mb-4">
              <h4 style={{color:"#6e84a4"}}>Break - up by collateral :</h4>
          </div>
          <div className="col-4 mt-3 mb-4">
            <Select
              options={collatList}
              onChange={this.handleSelect}
              placeholder={"Break - up by collateral"}
              value={collVal}
            />
          </div>
        </div>
        
          {/*-------------------------dashboard card info----------------------------*/}

        <div className="row">
          <div className="col-12">
            <div className="row mb-4" style={{border:"1px solid #95aac9", borderRadius:"5px",boxShadow: "-2px 0px 10px 6px lightgrey"}}>
              <div className="col-12 mb-4 mt-4"><h4 style={{color:"#6e84a4"}}>Transactions</h4></div>
                {this.handleCardWithSelect("Total Number Of Transactions", "fe-briefcase", 4, totalTrans,totalTransBudget, transDashboardData?.total_number_project, "")}
                {this.handleCardWithSelect("Approval Pending","fe-briefcase",3,approvePending,approvePendingBudget,transDashboardData?.currently_pending_brief,"")}
                {this.handleCardWithSelect("Active Brief", "fe-briefcase", 5, activeBrief,activeBriefBudget, transDashboardData?.currently_brief_project, 1)}
                {this.handleCardWithSelect("Active Project", "fe-briefcase", 6, activeProj,activeProjBudget, transDashboardData?.currently_active_project, 1)}
                {this.handleCardWithSelect("Inactive Briefs", "fe-briefcase", 5, inactiveB,inactiveBBudget, transDashboardData?.currently_inactive_brief, 0)}
                {this.handleCardWithSelect("Inactive Project","fe-briefcase",6,inactiveP,inactivePBudget,transDashboardData?.currently_inactive_project,0)}
                {this.handleCardWithSelect("Completed Project", "fe-briefcase", 7, compProj,compProjBudget, transDashboardData?.currently_complete_project,"")}
                {this.handleCardWithSelect("Rejected","fe-briefcase",9,rejected,rejectedBudget,transDashboardData?.currently_reject_brief,"")}
                {this.handleCardWithSelect("Closed Brief", "fe-briefcase", 8, closedBrief,closedBriefBudget, transDashboardData?.currently_closed_brief, 5)}
                {this.handleCardWithSelect("Closed Project","fe-briefcase",8,closedProj,closedProjBudget,transDashboardData?.currently_closed_project,6)}
            </div>
          </div>
          <div className="col-12">
            <div className="row mb-4" style={{border:"1px solid #95aac9", borderRadius:"5px",boxShadow: "-2px 0px 10px 6px lightgrey"}}>
              <div className="col-12 mb-4 mt-4"><h4 style={{color:"#6e84a4"}}>Ratings</h4></div>
                {this.handleCard("Average Rating - Clients", "fe-clipboard", "", Math.round(transDashboardData?.average_rating) === transDashboardData?.average_rating ? transDashboardData?.average_rating : transDashboardData?.average_rating?.toFixed(2), "", "", ratingOption, "rating")}
                {this.handleCard("Average Rating - Creator", "fe-clipboard", "", Math.round(transDashboardData?.average_rating_creator) === transDashboardData?.average_rating_creator ? transDashboardData?.average_rating_creator : transDashboardData?.average_rating_creator?.toFixed(2),"","",ratingOptionCreator,"ratingCreator")}
                {this.handleCard("Number Of Clients", "fe-clipboard", "", transDashboardData?.totalActiveClientLength, "", "", clientOption, "client")}
                {this.handleCard("Number Of Creators","fe-clipboard","",transDashboardData?.totalActiveCreatorLength,"","",creatorOption,"creator")}
            </div>
          </div>
          <div className="col-12">
            <div className="row mb-4" style={{border:"1px solid #95aac9", borderRadius:"5px",boxShadow: "-2px 0px 10px 6px lightgrey"}}>
              <div className="col-12 mb-4 mt-4"><h4 style={{color:"#6e84a4"}}>Other</h4></div>
                {this.handleCard("Brief > Project conversion ratio","fe-clipboard"," %",Math.round(transDashboardData?.active_brief_to_active_project) === transDashboardData?.active_brief_to_active_project ? transDashboardData?.active_brief_to_active_project : transDashboardData?.active_brief_to_active_project?.toFixed(2))}
                {this.handleCard("3 Proposal within 24 hours (client submission)","fe-clipboard"," %",Math.round(transDashboardData?.percent_brief_with_3_proposal_v1) === transDashboardData?.percent_brief_with_3_proposal_v1 ? transDashboardData?.percent_brief_with_3_proposal_v1 : transDashboardData?.percent_brief_with_3_proposal_v1?.toFixed(2))}
                {this.handleCard("3 Proposal within 24 hours (active brief onwards)","fe-clipboard"," %",Math.round(transDashboardData?.percent_brief_with_3_proposal_v2) === transDashboardData?.percent_brief_with_3_proposal_v2 ? transDashboardData?.percent_brief_with_3_proposal_v2 : transDashboardData?.percent_brief_with_3_proposal_v2?.toFixed(2))}
                {this.handleCard("Average Time To 1st Proposal", "fe-clipboard", "", Math.round(transDashboardData?.average_time_to_first_proposal) === transDashboardData?.average_time_to_first_proposal ? transDashboardData?.average_time_to_first_proposal : transDashboardData?.average_time_to_first_proposal?.toFixed(2), "check")}
            </div>
          </div>
          
            {/* {this.handleCardSelect("Break - up by collateral", "fe-clipboard","","-")} */}
            {/* {this.handleCard("# new talent joining","fe-briefcase","",transDashboardData?.new_Artist_length)}
            {this.handleCard("% briefs with 3 proposals within 24 hours",""," %",Math.round(transDashboardData?.percent_brief_with_3_proposal) === transDashboardData?.percent_brief_with_3_proposal ? transDashboardData?.percent_brief_with_3_proposal : transDashboardData?.percent_brief_with_3_proposal?.toFixed(2))}
            {this.handleCard("% talent which got at least one brief",""," %",Math.round(transDashboardData?.artist_with_one_brief) === transDashboardData?.artist_with_one_brief ? transDashboardData?.artist_with_one_brief : transDashboardData?.artist_with_one_brief?.toFixed(2))}
            {this.handleCard("% talent which got at least one project",""," %",Math.round(transDashboardData?.artist_with_one_Complete_active) === transDashboardData?.artist_with_one_Complete_active ? transDashboardData?.artist_with_one_Complete_active : transDashboardData?.artist_with_one_Complete_active?.toFixed(2))} */}                  
        </div>
        
      </React.Fragment>
    );
  }
}

const mapState = ({ transitionDashboard }) => {
  return {
    _transitionDashboard: transitionDashboard
  };
};
const mapDispatch = (dispatchEvent) => ({
  $transitionDashboard: (values) => dispatchEvent(transitionDashboard(values)),
});
export default connect(mapState, mapDispatch)(AMDashboard);