import { put, call } from "redux-saga/effects";
import { getPublicAPI } from "../api-interface";

import {
  reportProjectBriefSuccess,
  reportEnterpriseProjectSuccess,
  reportProjectSuccess,
  reportCollateralSuccess,
  reportAllartistSuccess,
  reportApprovedArtistSuccess,
  reportClientSuccess,
} from "../action/Reports.action";

// all Project Brief Report *****************

export function* onReportProject(data) {
  try {
    const data = yield call(getPublicAPI, `/report/all_time_project_report`);
    if (data.status === 200) {
      yield put(reportProjectBriefSuccess(data.data));
    }
  } catch (error) {
    //alert("Error: ", error.response.status);
  }
}

// enterPrise Project Report .................

export function* onReportsEnterPrise(data) {
  try {
    const data = yield call(
      getPublicAPI,
      `/report/all_time_project_report-enterprise`
    );
    if (data.status === 200) {
      yield put(reportEnterpriseProjectSuccess(data.data));
    }
  } catch (error) {
    //alert("Error: ", error.response.status);
  }
}

// All Time Brief Report ................
export function* onAllReportProject(data) {
  try {
    const data = yield call(getPublicAPI, `/report/all_time_brief`);
    if (data.status === 200) {
      yield put(reportProjectSuccess(data.data));
    }
  } catch (error) {
    //alert("Error: ", error.response.status);
  }
}

//  All Time Artist Report (All)...
export function* onAllReport(data) {
  try {
    const data = yield call(getPublicAPI, `/report/all-time-artist-report`);
    if (data.status === 200) {
      yield put(reportAllartistSuccess(data.data));
    }
  } catch (error) {
    //alert("Error: ", error.response.status);
  }
}

//  All Time Artist Report (collateral wise)...
export function* onCollateralReport(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/report/all-time-artist-report-collateral-wise?_id=${values}`
      );
      if (data.status === 200) {
        yield put(reportCollateralSuccess(data.data));
      }
    }
  } catch (error) {
    //alert("Error: ", error.response.status);
  }
}

// approved artist report
export function* onApprovedArtistReport(data) {
  try {
    const data = yield call(getPublicAPI, `/report/all-artist`);
    if (data.status === 200) {
      yield put(reportApprovedArtistSuccess(data.data));
    }
  } catch (error) {
    //alert("Error: ", error.response.status);
  }
}

// client report
export function* onClientReport(data) {
  try {
    const data = yield call(getPublicAPI, `/report/all-client`);
    if (data.status === 200) {
      yield put(reportClientSuccess(data.data));
    }
  } catch (error) {
    //alert("Error: ", error.response.status);
  }
}
