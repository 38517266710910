import React from "react";
import pdflogo from "../../../../Assets/img/pdflogo.png";
import doclogo from "../../../../Assets/img/doclogo.jpeg";
import ziplogo from "../../../../Assets/img/images.jpeg";
import linkLogo from "../../../../Assets/img/images.png";
import csvLogo from "../../../../Assets/img/csv.png";
import xlsLogo from "../../../../Assets/img/big-xlsx.jpg";
import { uploadFileUrl } from "../../../../Utils/BaseUrl";

export const getThumbnailImage = (value) => {
    const ImagePath =
      value.slice(value.lastIndexOf(".")) === ".pdf"
        ? pdflogo
        : value.slice(value.lastIndexOf(".")) === ".docx" ||
          value.slice(value.lastIndexOf(".")) === ".doc"
        ? doclogo
        : value.slice(value.lastIndexOf(".")) === ".zip"
        ? ziplogo
        : value.slice(value.lastIndexOf(".")) === ".csv"
        ? csvLogo
        : value.slice(value.lastIndexOf(".")) === ".xls" || value.slice(value.lastIndexOf(".")) === ".xlsx"
        ? xlsLogo
        : value.slice(value.lastIndexOf(".")) === ".mp3" ||
          value.slice(value.lastIndexOf(".")) === ".xlsx"
        ? linkLogo
        : value.includes('.com')?value:uploadFileUrl+value;
  
    return (
      <img
        src={ImagePath}
        className="avatar-img rounded"
        width="100"
        height="20"
        alt="..."
      />
    );
  };
  