import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { deleteArtist, disabledArtist } from '../../../../store/action/clientList.action';
import { getPublicAPI } from "../../../../store/api-interface";
// import { getThumbnails } from "video-metadata-thumbnails";

 class ArtistHeaderTab extends Component {

  state={
    headerCount:{}
  }

 getArtistHeaderCount = async() =>{
  try{
    const response = await getPublicAPI('/backendUser/creator-count-admin')
    if(response?.status===200){
      const data = response?.data 
      this.setState({headerCount:data})
    }
  }
  catch(err){
    console.log(err)
  }
 }

 componentDidMount(){
  this.getArtistHeaderCount();  
}

  render() {
    const {
      detailsUpdateInfo,
      collateralUpdateInfo,
      workUpdateInfo,
      projectUpdateInfo,
      educationUpdateInfo,
      allArtist,
      // profileUpdateAll,
      approvedArtistLength,
      rejectedArtistLength,
      _deleteArtist,
      _disabledArtist
    } = this.props;

    const { pathIs, location, approvalLength,busyLength,starredLength } = this.props;
    const {headerCount} = this.state
    const artistId = location.pathname.split("/:").pop();

    const artistCurrent = (path) => {
      const currentPath = location.pathname.split("/");
      return currentPath[2] === path;
    };

    const artistCurrentPath = (path) => {
      const currentPath = location.pathname;
      return currentPath === path;
    };

    const forSignPath = location.pathname.split("/");   

    const routeLocation = forSignPath?.length>2?forSignPath[2]:forSignPath[1]

    // console.log(routeLocation,'<----------> routeLocation <------------>')


    const tabArr = [
      {
        name: "Incomplete",
        link: "/artist",
        activeBorder: "/artist",
        counting: routeLocation==='artist'?allArtist:headerCount?.Incomplete,
      },
      {
        name: "Approval Pending",
        link: "/artist/artistDraft",
        activeBorder: "/artist/artistDraft",
        counting: routeLocation==='artistDraft'?approvalLength:headerCount?.approvalPending,
      },
      {
        name: "Available",
        link: "/artist/approvedArtist",
        activeBorder: "/artist/approvedArtist",
        counting: routeLocation==='approvedArtist'?approvedArtistLength:headerCount?.Available,
      },
       {
        name: "Busy",
        link: "/artist/busy",
        activeBorder: "/artist/busy",
        counting: routeLocation==='busy'?busyLength:headerCount?.busy,
      },
        {
        name: "Starred",
        link: "/artist/starred",
        activeBorder: "/artist/starred",
        counting: routeLocation==='starred'?starredLength:headerCount?.starred,
      },
      {
        name: "Blacklisted",
        link: "/artist/disabled",
        activeBorder: "/artist/disabled",
        counting:routeLocation==='disabled'? _disabledArtist.disabledArtistIs.totalUsers:headerCount?.blacklist,
      },
      {
        name: "Rejected",
        link: "/artist/artistRejected",
        activeBorder: "/artist/artistRejected",
        counting: routeLocation==='artistRejected'?rejectedArtistLength:headerCount?.rejected,
      },
      {
        name: "Deleted",
        link: "/artist/artistDeleted",
        activeBorder: "/artist/artistDeleted",
        counting: routeLocation==='artistDeleted'?_deleteArtist.DeleteArtistIs.totalUsers:headerCount?.deleted,
      },
      // {
      //   name: "Profile Updates",
      //   link: "/artist/artistProfileUpdates",
      //   activeBorder: "/artist/artistProfileUpdates",
      //   counting: profileUpdateAll,
      // },
    ];

    const tabDetailsArr = [
      {
        name: "Basic Details",
        link: `/artist/:edit/:${artistId}`,
        activeBorder: ":edit"       
      },
      {
        name: "Collateral",
        link: `/artist/artistCollateral/:${artistId}`,
        activeBorder: "artistCollateral",        
      },
      {
        name: "Education",
        link: `/artist/artistEducation/:${artistId}`,
        activeBorder: "artistEducation",        
      },
      {
        name: "Specialization",
        link: `/artist/artistSpeclization/:${artistId}`,
        activeBorder: "artistSpeclization",        
      },
      {
        name: "Work Experience",
        link: `/artist/artistWorkExperience/:${artistId}`,
        activeBorder: "artistWorkExperience",        
      },
      {
        name: "Project",
        link: `/artist/artistProject/:${artistId}`,
        activeBorder: "artistProject",        
      },
      {
        name: "Web Url",
        link: `/artist/artistUrl/:${artistId}`,
        activeBorder: "artistUrl",        
      },
      {name:"Project Review",
      link:`/artist/projectReview/:${artistId}`,
       activeBorder:"projectReview"
      },
      {name:"Transactions",
      link:`/artist/transactions/:${artistId}`,
      activeBorder:"transactions"
      },
      {
        name: "Notification",
        link: `/artist/artistNotification/:${artistId}`,
        activeBorder: "artistNotification",        
      },
      {
        name: "Other",
        link: `/artist/artistOther/:${artistId}`,
        activeBorder: "artistOther",        
      },
    ];

    return (
      <React.Fragment>
        {pathIs === "Artist" &&
          (artistId === "/artist" ||
            artistId === "/artist/artistDraft" ||
            // artistId === "/artist/artistProfileUpdates" ||
            artistId === "/artist/artistDeleted" ||
            artistId === "/artist/artistRejected" ||
          artistId === "/artist/disabled" ||
          artistId === "/artist/busy" ||
          artistId === "/artist/starred" ||
            artistId === "/artist/approvedArtist") && (
            <ul className="nav nav-tabs nav-overflow header-tabs">
              {tabArr.map((obj, index) => (
                <li className="nav-item" key={index}>
                  <Link
                    to={obj.link}
                    className={`nav-link ${
                      artistCurrentPath(`${obj.activeBorder}`) ? "active" : ""
                    }`}
                  >
                    {obj.name}
                    <span className="badge badge-pill badge-soft-secondary ml-2">
                      {obj.counting}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          )}
        {pathIs === "Artist" &&
          artistId !== "/artist" &&
          forSignPath[1] !== "signup" &&
          artistId !== "/artist/artistDraft" &&
          // artistId !== "/artist/artistProfileUpdates" &&
          artistId !== "/artist/artistDeleted" &&
           artistId !== "/artist/busy" &&
          artistId !== "/artist/starred" &&
          artistId !== "/artist/artistRejected" &&
          artistId !== "/artist/disabled" &&
          artistId !== "/artist/approvedArtist" &&(
            <ul className="nav nav-tabs nav-overflow header-tabs">
              {
                tabDetailsArr.map((obj, index)=>(
                  <li className="nav-item" key={index}>
                <Link
                  to={obj.link}
                  className={`nav-link ${
                    artistCurrent(`${obj.activeBorder}`) ? "active" : ""
                  }`}
                >
                  {((obj.name === "Basic Details" && detailsUpdateInfo === 1)||
                    (obj.name === "Collateral" && collateralUpdateInfo === 1) ||
                    (obj.name === "Education" && educationUpdateInfo === 1) ||
                    (obj.name === "Work Experience" && workUpdateInfo === 1) ||
                    (obj.name === "Project" && projectUpdateInfo === 1)) && (
                    <span className={`mr-2 text-danger `}>●</span>
                  )}
                  {obj.name}
                </Link>
              </li>
                ))
              }              
            </ul>
          )}
      </React.Fragment>
    );
  }
}
const mapState = ({ deleteArtist, disabledArtist }) => {
  return {
    _deleteArtist: deleteArtist,
    _disabledArtist: disabledArtist
  };
};
const mapDispatch = (dispatchEvent) => ({
  $deleteArtist: (values) => dispatchEvent(deleteArtist(values)),  
  $disabledArtist: (values) => dispatchEvent(disabledArtist(values)), 
});
export default connect(
  mapState,
  mapDispatch
)(ArtistHeaderTab);