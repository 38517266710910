import React, { Component } from "react";

export default class ProjectType extends Component {
  render() {
      const{project_type, handleFunc } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="text-dark h3">Project Type</label>

              <div className="row col">
                <div className="col ml-n2">
                  {/* <small className="text-muted"> */}
                    <input
                      type="radio"
                      name="fixed"
                      checked={project_type === 'fixed'}
                      value={'fixed'}
                      onChange={handleFunc.handleChangeProjectType}
                    />{" "}
                    Fixed
                  {/* </small> */}
                </div>
                <div className="col ml-n2">
                  {/* <small className="text-muted"> */}
                    <input
                      type="radio"
                      name="retainer"
                      checked={project_type === 'retainer'}
                      value={'retainer'}
                      onChange={handleFunc.handleChangeProjectType}
                    />{" "}
                    Retainer
                  {/* </small> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
