import React, { Component } from "react";
import { connect } from "react-redux";
import {
  completeProject,
  closeProject,
  DontLikeTranscationList,
} from "../../../store/action/transaction.action";
import Table from "./Table";
import ReactPaginate from "react-paginate";
import ErrorApi from "../Common/Error/ErrorApi";

class DontLikeTranscations extends Component {
  state = {
    transactionsList: 1,
    transactionActive: false,
    LoadingIs: false,
    transactionData: [],
    transactionIs: "",
    minBudget: "",
    maxBudget: "",
    deadline: "",
    totalPages: 0,
    closeProjectActive: false,
    callBackRequest : false
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        const {
          transactionsList,
          transactionIs,
          minBudget,
          maxBudget,
          deadline,
        } = this.state;

        const transactionData = {
          List: transactionsList,
          project: transactionIs,
          min_budget: minBudget,
          max_budget: maxBudget,
          deadline: deadline,
        };

        this.props.$DontLikeTranscationList(transactionData);
      }
    );
  };

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    const { transactionIs, minBudget, maxBudget, deadline } = this.state;
    const transactionData = {
      List: selectedData,
      project: transactionIs,
      min_budget: minBudget,
      max_budget: maxBudget,
      deadline: deadline,
    };
    this.props.$DontLikeTranscationList(transactionData);
  };

  handleCloseProject = (id) => {
    this.setState({
      closeProjectActive: true,
    });
    const closeProjectData = { _id: id };
    this.props.$closeProject(closeProjectData);
  };

  handleCompleteTransaction = () => {
    const { transactionsList, transactionIs } = this.state;

    this.setState({
      LoadingIs: true,
    });

    const transactionData = {
      List: transactionsList,
      project: transactionIs,
    };
    this.props.$DontLikeTranscationList(transactionData);
  };

  componentDidMount() {
    this.handleCompleteTransaction();
    this.setState({
      callBackRequest : true
    })
  }

  componentWillReceiveProps(nextProps) {
    const Completetransaction =
      nextProps._DontLikeTranscationList.dontLikeListIs;

    const closedProject = nextProps._closeProject.closeProjectIs;
    const { closeProjectActive } = this.state;

    if (Completetransaction) {
      this.setState({
        transactionData: Completetransaction.PostProjects,
        LoadingIs: false,
        totalPages: Completetransaction.totalPages,
      });
    }
    if (closedProject && closeProjectActive) {
      alert(closedProject.message);
      this.setState({
        closeProjectActive: false,
      });
      this.handleCompleteTransaction();
    }
  }

  render() {
    const { totalPages } = this.state;
    const transactionFunc = {
      handleOnChange: this.handleOnChange,
      handleCloseProject: this.handleCloseProject,
    };
    return (
      <React.Fragment>
        {this.props._DontLikeTranscationList.dontLikeError ? (
          <ErrorApi {...this.props} />
        ) : (
          <div>
            <Table
              {...this.state}
              {...this.props}
              handleTransaction={transactionFunc}
            />
            {/******** pagination ************* */}

            {totalPages > 0 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({
  completeProject,
  closeProject,
  DontLikeTranscationList,
}) => {
  return {
    _completeProject: completeProject,
    _closeProject: closeProject,
    _DontLikeTranscationList: DontLikeTranscationList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $completeProject: (values) => dispatchEvent(completeProject(values)),
  $closeProject: (values) => dispatchEvent(closeProject(values)),
  $DontLikeTranscationList: (values) =>
    dispatchEvent(DontLikeTranscationList(values)),
});
export default connect(mapState, mapDispatch)(DontLikeTranscations);
