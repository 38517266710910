import React, { Component } from "react";
import { connect } from "react-redux";
import { resetPwd } from "../../../../store/action/loginData.action";
import LoaderSmall from "../Loader/LoaderSmall";

class ResetPwd extends Component {
  state = {
    currentPwd: "",
    newPwd: "",
    confirmPwd: "",
    LoadingIs: false,
    errors: { currentPwd: "", newPwd: "", confirmPwd: "" },
  };

  setArtistState = (stateObj) =>{
    this.setState(stateObj)
  }

  handleOnchange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    this.validation(name, value);
  };

  validation(name, value) {
    const { errors } = this.state;
    switch (name) {
      case "currentPwd":
        errors.currentPwd = !value.length ? "* Required Field " : "";
        break;
      case "newPwd":
        errors.newPwd = !value.length ? "* Required Field " : "";
        break;
      case "confirmPwd":
        errors.confirmPwd = !value.length
          ? "* Required Field "
          : this.state.newPwd !== value
          ? "Password Not Matched"
          : "";
        break;
      default:
        break;
    }
  }

  validateForm() {
    const { currentPwd, newPwd, confirmPwd, errors } = this.state;

    errors.currentPwd = !currentPwd.length ? "* Required Field " : "";
    errors.newPwd = !newPwd.length ? "* Required Field " : "";
    errors.confirmPwd = !confirmPwd.length
      ? "* Required Field "
      : confirmPwd !== newPwd
      ? "Password Not Matched"
      : "";
    this.setState({
      errors,
    });
    return !errors.confirmPwd && !errors.newPwd;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      const { currentPwd, confirmPwd } = this.state;
      this.setState({
        LoadingIs: true,
      });
      let data = { current_pass: currentPwd, confirm_pass: confirmPwd };
      this.props.$resetPwd(data);
    }
  };

  resetField = () => {
    this.setState({
      currentPwd: "",
      newPwd: "",
      confirmPwd: "",
      LoadingIs: false,
    });
  };

  renderAlert = (message) =>{
    alert(message)
  }

  componentWillReceiveProps(nextProps) {
    const pwdStatus = nextProps._resetPwd;
    const dataIs = pwdStatus.resetPwdIs;
    if (dataIs.status === 1 && !pwdStatus.Loading) {
      alert(dataIs.message);
      this.props.history.push("/");
    }
    if (pwdStatus.resetPwderr && pwdStatus.Loading) {
      this.renderAlert(pwdStatus.resetPwderr.message)

      this.resetField();
    }
  }

  render() {
    const { currentPwd, confirmPwd, newPwd, errors, LoadingIs } = this.state;

    const ResetPwdArr = [
      {
        nameIs: "currentPwd",
        value: currentPwd,
        label: "Current Password",
      },
      {
        nameIs: "newPwd",
        value: newPwd,
        label: "New Password",
      },
      {
        nameIs: "confirmPwd",
        value: confirmPwd,
        label: "Confirm Password",
      },
    ];

    return (
      <React.Fragment>       
        <section
          className="d-flex align-items-center bg-auth "
          style={{ minHeight: "-webkit-fill-available" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-5 col-xl-6 my-5">
                <form onSubmit={this.handleSubmit}>
                  {ResetPwdArr.map((obj, index) => (
                    <div className="form-group" key={index}>
                      <label>{obj.label}</label>
                      <input
                        type="password"
                        className={`form-control ${((obj.nameIs ===
                          "currentPwd" &&
                          errors.currentPwd) ||
                          (obj.nameIs === "newPwd" && errors.newPwd) ||
                          (obj.nameIs === "confirmPwd" && errors.confirmPwd)) &&
                          `error-show`}`}
                        placeholder={obj.placeholder}
                        name={obj.nameIs}
                        value={obj.value}
                        onChange={this.handleOnchange}
                      />
                      {((obj.nameIs === "currentPwd" && errors.currentPwd) ||
                        (obj.nameIs === "newPwd" && errors.newPwd) ||
                        (obj.nameIs === "confirmPwd" && errors.confirmPwd)) && (
                        <span className="error">{`${errors.currentPwd ||
                          errors.newPwd ||
                          errors.confirmPwd}`}</span>
                      )}
                    </div>
                  ))}
                  <button
                    type="submit"
                    className="btn btn-lg btn-block btn-primary mb-3"
                  >
                    {LoadingIs ? <LoaderSmall /> : `Password Reset`}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
const mapState = ({ resetPwd }) => {
  return {
    _resetPwd: resetPwd,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $resetPwd: (values) => dispatchEvent(resetPwd(values)),
});
export default connect(
  mapState,
  mapDispatch
)(ResetPwd);
