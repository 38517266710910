import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import RootRouting from "./Routing/indexRouting";
import "../src/Assets/css/theme.min.css";
import "../src/Assets/libs/flatpickr/dist/flatpickr.min.css";
import "../src/Assets/fonts/feather/feather.min.css";
import "./Assets/css/style.css";
import { Provider } from "react-redux";
import makeStore from "./store/index.store";
const store = makeStore();

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Route render={(props) => <RootRouting {...props} />} />
        </Router>
      </Provider>
    );
  }
}
