import React, { Component } from "react";
import { connect } from "react-redux";
import { getArtistData } from "../../../store/action/clientList.action";
import { ImageBaseUrl, ResumeBaseUrl } from "../../../Utils/BaseUrl";
// import { Link } from "react-router-dom";
import ErrorApi from "../Common/Error/ErrorApi";

class ArtistUrl extends Component {
  state = {
    indiefolio: "",
    behance: "",
    dribble: "",
    vimeo: "",
    insta: "",
    personalWeb: "",
    custom: "",
    customUrl1: "",
    customUrl2: "",

    indiefolioImage: "",
    behanceImage: "",
    dribbleImage: "",
    vimeoImage: "",
    instaImage: "",
    personalImage: "",
    customImage: "",
    customImage1: "",
    customImage2: "",

    urlEditIS: true,
    resume: "",
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleEditUrl = () => {
    this.setState({
      urlEditIS: false,
    });
  };

  handleGetArtistWorkExp = (id) => {
    this.props.$getArtistData(id);
  };

  componentDidMount() {
    // this.props.handleClients.handlepathName("/artist");
    const artistParamsId = this.props.match.params.id.split(":").pop();
    this.handleGetArtistWorkExp(artistParamsId);
  }

  componentWillReceiveProps(nextProps) {
    const creatorData = nextProps._getArtistData.getCreatorData;
    const userdataIs = creatorData.userData;
    const urlData = creatorData.webLink;
    if (creatorData.status === "1" && userdataIs) {
      this.setState({
        webUrl:urlData,
        indiefolio: urlData?.indiefolio_url,
        behance: urlData?.behance_url,
        dribble: urlData?.dribbble_url,
        vimeo: urlData?.vimeo_url,
        insta: urlData?.instagram_url,
        personalWeb: urlData?.personal_url,
        custom: urlData?.custom_url,
        customUrl1: urlData?.custom_url1,
        customUrl2: urlData?.custom_url2,

        indiefolioImage: urlData?.indiefolio_image,
        behanceImage: urlData?.behance_image,
        dribbleImage: urlData?.dribbble_image,
        linkedImage: urlData?.linkedImage,
        vimeoImage: urlData?.vimeo_image,
        personalImage: urlData?.personal_image,
        instaImage: urlData?.instagram_image,
        customImage: urlData?.custom_image,
        customImage1: urlData?.custom_image1==='custom_link__new.png'?'custom_link_new.png':urlData?.custom_image1,
        customImage2: urlData?.custom_image2==='custom_link__new.png'?'custom_link_new.png':urlData?.custom_image1,

        resume: userdataIs.resume ? userdataIs.resume : "",
      });
    }
  }

  render() {
    const {
      indiefolio,
      behance,
      dribble,
      vimeo,
      insta,
      personalWeb,
      custom,
      indiefolioImage,
      behanceImage,
      dribbleImage,
      // linkedImage,
      vimeoImage,
      instaImage,
      personalImage,
      customImage,
      urlEditIS,
      customImage1,
      customImage2,
      customUrl1,
      customUrl2,
      resume,
      webUrl
    } = this.state;

    const urlArr = [
      {
        name: "indiefolio",
        value: indiefolio,
        imagePath: indiefolioImage,
        label: "Indiefolio",
      },
      {
        name: "behance",
        value: behance,
        imagePath: behanceImage,
        label: "Behance",
      },
      {
        name: "dribble",
        value: dribble,
        imagePath: dribbleImage,
        label: "Dribble",
      },
      { name: "vimeo", value: vimeo, imagePath: vimeoImage, label: "Vimeo" },
      {
        name: "insta",
        value: insta,
        imagePath: instaImage,
        label: "Instagram",
      },
      {
        name: "personalWeb",
        value: personalWeb,
        imagePath: personalImage,
        label: "Personal website",
      },
      {
        name: "custom",
        value: custom,
        imagePath: customImage,
        label: "Custom",
      },
      customUrl1 && {
        name: "custom1",
        value: customUrl1,
        imagePath: customImage1,
        label: "Custom",
      },
      customUrl2 && {
        name: "custom2",
        value: customUrl2,
        imagePath: customImage2,
        label: "Custom",
      },
    ];

    return (
      this.props._getArtistData.getArtistError ? 
        <ErrorApi {...this.props} />
        :
      <React.Fragment>
        <section className="container">
          <div className="mx-auto">
            {webUrl?<div className="questin-Listing col-md-10 mx-auto">
              <div className="row mt-3">
                {urlArr.map((obj, index) => (
                  <div className="col-12 col-md-12" key={index}>
                      <div
                        className="form-group text-secondary d-flex"
                        style={{ alignItems: "flex-end" }}
                      >
                        <div className="col-1">
                          <img
                            src={
                              obj?.imagePath
                                ? `${ImageBaseUrl + obj?.imagePath}`
                                : require("../../../Assets/img/avatars/profiles/images.jpeg")
                            }
                            alt="..."
                            width="100%"
                            style={{ borderRadius: "5px" }}
                          />
                        </div>
                        <div className="col-11">
                          <label>{obj?.label}</label>
                          <input
                            type="text"
                            className={`form-control text-secondary`}
                            disabled={urlEditIS ? "disabled" : ""}
                            name={obj?.name}
                            value={obj?.value}
                            onChange={this.handleOnChange}
                          />
                        </div>
                      </div>
                  </div>
                ))}                
              </div>
            </div>:
            <h1>No Url Found</h1>
            }
            <hr className="my-5" />

            {/*-------------------resume-----------------------*/}
            <div className="col-10 mx-auto">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="card bg-light border col-12">
                      <div className="card-body">
                        <h4 className="mb-2">Resume</h4>

                        <p className="small text-muted mb-0">
                          {resume ? (
                            // <img
                            //   src={require("../../../Assets/img/avatars/profiles/images.jpeg")}
                            //   alt=""
                            //   style={{ height: "100px" }}
                            // />
                            <a href={ResumeBaseUrl+resume} target='_blank'>{ResumeBaseUrl+resume}</a>
                          ) : (
                            "No Data found.."
                          )}
                        </p>

                        {/* <Link to={resume} download>
                          <button className="btn btn-sm btn-primary mt-3">
                            Download
                          </button>
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-10 mx-auto">
                  <div className="d-flex justify-content-end col-md-12">
                    <button
                      className="btn btn-primary"
                      onClick={urlEditIS ? this.handleEditUrl :this.handleUpdateUrl}
                    >
                      {urlEditIS ? "Edit" : "Update"}
                    </button>
                  </div>                 
                </div> */}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapState = ({ getArtistData }) => {
  return {
    _getArtistData: getArtistData,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $getArtistData: (values) => dispatchEvent(getArtistData(values)),
});
export default connect(mapState, mapDispatch)(ArtistUrl);
