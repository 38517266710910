import React, { Component } from "react";

export default class LastMsgBy extends Component {
  render() {
      const{ LastMsgIs } = this.props;
    return (
      <React.Fragment>
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col">
                  <h5 className="mb-0">
                    Last Message By :{" "}
                    {LastMsgIs.user_type === "group"
                      ? LastMsgIs.sender_id.first_name +
                        " " +
                        LastMsgIs.sender_id.last_name
                      : "Getcreator"}
                  </h5>
                </div>
                <div className="col-auto">
                  <time className="small text-muted">
                    {LastMsgIs.createdAt.split("T").slice(0)[0]}
                  </time>
                </div>
              </div>
            </div>
          </div>
      </React.Fragment>
    );
  }
}
