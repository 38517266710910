import React, { Component } from "react";
import { userProfileBaseUrl } from "../../../Utils/BaseUrl";
import { getPublicAPI } from "../../../store/api-interface";
import Loader from "../Common/Loader/Loader";

class ProfileSelectionArtist extends Component {
  state = {
    creatorData: [],
    loading:false
  };

  componentDidMount() {
    this.handleApi();
  }

  handleApi = () => {
    this.setState({loading:true})
    const transactionId = this.props.match.params.id.split(":").pop();
    getPublicAPI(`/project/get-postProject-profile-rank?id=${transactionId}`).then(res => {
      // console.log("res == ",res.data.data)
      this.setState({creatorData: res.data.data,loading:false})
    }).catch(err => console.log(err))
  }

  getCreatorList = (data) => {
    return (
      <div className="row">
        {data?.length > 0
          ? data.map((obj, index) => (
              <div className="col-6" key={index}>
                <div className="card card-new">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <a
                          href={`/artist/:edit/:${
                            obj?.user_id2 && obj?.user_id2._id
                          }`}
                          className="avatar avatar-lg"
                        >
                          <img
                            src={
                              obj?.user_id2?.profile_image
                                ? userProfileBaseUrl +
                                  obj?.user_id2?.profile_image
                                : require("../../../Assets/img/avatars/profiles/images.jpeg")
                            }
                            alt="..."
                            className="avatar-img rounded"
                          />
                      </a>
                       <p className="card-text small text-muted mb-1 mt-1">
                          <i className="fe fe-user"></i> Creator
                        </p>
                      </div>
                      <div className="col ml-n2">
                        <h4 className="card-title mb-1 name">
                          <a
                            href={`/artist/:edit/:${
                              obj?.user_id2 && obj?.user_id2?._id
                            }`}
                          >
                            {obj?.user_id2?.first_name +
                              " " +
                              obj?.user_id2?.last_name}
                          </a>
                        </h4>
                        <p>Hourly Pricing: <span style={{color:"#6e84a3"}}>{obj?.user_id2?.time_base_amount_month ? (obj.user_id2.time_base_amount_month / (20 * 5)).toFixed(2) : ""}</span> </p>
                        <p>Collateral Price: <span style={{color:"#6e84a3"}}>{obj?.userCollateralData ? obj.userCollateralData?.start_price+"-"+obj.userCollateralData?.end_price : ""}</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : "No Creators found.."}
      </div>
    )
  }

  render() {
    const { creatorData, loading } = this.state;
    
    return (
      <React.Fragment>
        <section>
          <div className="container">
            <div className="card">
              <div className="card-body">
                {
                  loading ?
                    <Loader/>
                    :
                     this.getCreatorList(creatorData) 
                }
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ProfileSelectionArtist;
