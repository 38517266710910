import React, { Component } from "react";

export default class ProposalType extends Component {
  render() {
    const {proposal_type,handleFunc,EditIs} = this.props;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="text-secondary">Proposal Type</label>

              <div className="row col">
                <div className="col ml-n2">
                    <input
                      type="radio"
                      name="fixed"
                      checked={proposal_type === 'fixed'}
                      value={'fixed'}
                      onChange={handleFunc.handleChangeProposalType}
                      disabled={EditIs}
                    />{" "}
                    Fixed
                </div>
                <div className="col ml-n2">
                    <input
                      type="radio"
                      name="retainer"
                      checked={proposal_type === 'retainer'}
                      value={'retainer'}
                      onChange={handleFunc.handleChangeProposalType}
                      disabled={EditIs}
                    />{" "}
                    Retainer
                </div>
                <div className="col ml-n2">
                    <input
                      type="radio"
                      name="onactuals"
                      checked={proposal_type === 'actual'}
                      value={'actual'}
                      onChange={handleFunc.handleChangeProposalType}
                      disabled={EditIs}
                    />{" "}
                    On Actuals
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
