import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../../Common/Loader/Loader";
import { connect } from "react-redux";
import { clearBriefList } from "../../../../store/action/transaction.action";
import { clientRejectsCreatorList } from "../../../../store/action/clientList.action";
import ReactPaginate from "react-paginate";
import ErrorApi from "../../Common/Error/ErrorApi";

class ClientRejectsCraetor extends Component {
  state = {
    LoadingIs: false,
    currentPage: 1,
    sort: -1,
    RejectList: [],
    totalPage: 0,
    showAction: false,
    showActionIndex: "",
    clearBriefActive: false,
    sortFlagIs: false
  };

  handleSorting = () => {
    this.setState({ sortFlagIs : !this.state.sortFlagIs },()=>{
      const { currentPage } = this.state;
      let currentData = null;
      if(this.state.sortFlagIs){
        currentData = { page: currentPage, sortIs: 1 }
      }else{
        currentData = { page: currentPage, sortIs: -1 }
      }
      this.setState({ sort : this.state.sortFlagIs ? 1 : -1 })
      this.handleClientRejectsCreator(currentData);
    })
  };

  handleClearBrief = (id) => {
    const briefData = { _id: id };
    this.setState({ clearBriefActive: true });
    this.props.$clearBriefList(briefData);
  };


  handlePageClick = (data) => {
    let selectedPage = data.selected + 1;
    const { sort } = this.state;
    const currentData = { page: selectedPage, sortIs: sort };
    this.handleClientRejectsCreator(currentData);
  };

  handleClientRejectsCreator = (currentData) => {
    this.setState({
      LoadingIs: true,
    });
    this.props.$clientRejectsCreatorList(currentData);
  };

  componentDidMount() {
    const { currentPage, sort } = this.state;
    const currentData = { page: currentPage, sortIs: sort };
    this.handleClientRejectsCreator(currentData);
  }

  componentWillReceiveProps(nextProps) {
    const { clearBriefActive } = this.state;
    const clientRejectList =
      nextProps._clientRejectsCreatorList.clientRejectsCreatorIs;
    const currentClearBrief = nextProps._clearBriefList.clearListIs;
    if (clientRejectList) {
      this.setState({
        RejectList: clientRejectList.briefProposalRejects
          ? clientRejectList.briefProposalRejects
          : "",
        totalPage: clientRejectList.totalPages
          ? clientRejectList.totalPages
          : "",
        LoadingIs: false,
      });
    }
    if (currentClearBrief && clearBriefActive) {
      const { currentPage, sort } = this.state;
      const currentData = { page: currentPage, sortIs: sort };
      this.handleClientRejectsCreator(currentData);
      this.setState({
        clearBriefActive: false,
      });
    }
  }

  render() {
    const dataArr = [
      { name: "Action" },
      { name: "Tranaction name" },
      { name: "collateral name" },
      { name: "creator name" },
      { name: "client name" },
      { name: "client Email" },
      { name: "Recieved Date" },
      { name: "Rejected Date" },     
    ];

    const { LoadingIs, RejectList} = this.state;
    return (
      <section>
        {this.props._clientRejectsCreatorList.clientRejectErrIs ? (
          <ErrorApi {...this.props} />
        ) : (
          <React.Fragment>
            <section className="client mt-5">
              <div className="container-fluid mx-auto">
                {LoadingIs ? (
                  <Loader />
                ) : (
                  <div className="table-responsive">
                    <table className="table table-sm table-nowrap card-table">
                      {/*----------------- table head --------------------*/}
                      <thead>
                        <tr>
                          {dataArr.map((obj, index) => (
                            <React.Fragment  key={index}>
                              {obj?.name !== "Rejected Date" && (
                                <th>
                                  <Link to="#!" className="text-muted">
                                    {obj?.name}
                                  </Link>
                                </th>
                              )}
                              {obj?.name === "Rejected Date" && (
                                <th key={index}>
                                  <Link
                                    to="#!"
                                    className="text-muted sort"
                                    data-sort="tables-row"
                                    onClick={this.handleSorting}
                                  >
                                    {obj?.name}
                                  </Link>
                                </th>
                              )}
                            </React.Fragment>
                          ))}
                        </tr>
                      </thead>
                      {/*----------------- table head --------------------*/}
                      <tbody className="list">
                        {RejectList.length > 0 ? (
                          RejectList.map((obj, index) => (
                            <tr key={index}>
                              <td className="text-center">
                                <div className={`dropdown`}>
                                  <a
                                    href="#!"
                                    className="dropdown-ellipses dropdown-toggle text-secondary"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="fe fe-more-vertical"></i>
                                  </a>
                                  
                                    <div className={`ma-auto dropdown-menu dropdown-menu-right text-center`}>
                                      {obj?.status === 0 && (
                                        <span>
                                          <Link
                                            to="#!"
                                            className="dropdown-item mb-2"
                                            onClick={() =>
                                              this.handleClearBrief(obj?._id)
                                            }
                                          >
                                            Clear
                                          </Link>
                                        </span>
                                      )}
                                      <span>
                                        <Link
                                          to={`/approvals/ViewCreatorReject/:${obj?._id}`}
                                          className="dropdown-item mb-2"
                                          datatoggle="modal"
                                          datatarget="#exampleModalCenter"
                                        >
                                          View Brief
                                        </Link>
                                      </span>
                                    </div>
                                </div>
                              </td>
                              <td className="orders-order">
                                {`${obj?.PostProject?.project_name.substring(0, 30)} ${obj?.PostProject?.project_name.substring(30) ? "..." : ''}`}
                              </td>
                              <td className="orders-product">
                                {obj?.collateral?.name}
                              </td>
                              <td className="orders-date">
                                {obj?.creator?.first_name +
                                  " " +
                                  obj?.creator?.last_name}
                              </td>
                              <td className="orders-total">
                                {obj?.client?.first_name +
                                  " " +
                                  obj?.client?.last_name}
                              </td>
                              <td className="orders-status">
                                {obj?.client?.email}
                              </td>
                              <td className="orders-method">{obj?.approvedData ? obj?.approvedData?.createdAt.split("T").slice(0)[0] : ''}</td>
                              <td className="orders-method">
                                {obj?.createdAt.split("T").slice(0)[0]}
                              </td>                              
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td> No data Found...</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                {/****************** Pagination ********************/}

                {this.state.totalPage > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                )}
              </div>
            </section>
          </React.Fragment>
        )}
      </section>
    );
  }
}
const mapState = ({ clientRejectsCreatorList, clearBriefList }) => {
  return {
    _clientRejectsCreatorList: clientRejectsCreatorList,
    _clearBriefList: clearBriefList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $clientRejectsCreatorList: (values) =>
    dispatchEvent(clientRejectsCreatorList(values)),
  $clearBriefList: (values) => dispatchEvent(clearBriefList(values)),
});
export default connect(mapState, mapDispatch)(ClientRejectsCraetor);
