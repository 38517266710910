

import React, { useState } from 'react'
import { postAPI } from '../../../store/api-interface';
import './modalReview.css'
export function ModalReview(props) {
    const [open,setOpen]=useState(false)
    const [creator1st, setCreator1st] = useState('');
    const [creator2nd, setCreator2nd] = useState('');
    const [creator3rd, setCreator3rd] = useState('');
    const [creator4th, setCreator4th] = useState('');

    const [creatorDescription, setCreatorDescription] = useState('');
    const [client1st, setClient1st] = useState('');
    const [client2nd, setClient2nd] = useState('');
    const [client3rd, setClient3rd] = useState('');
    const [client4th, setClient4th] = useState('');
    const [client5th,setClient5th]=useState('');
    const [clientDescription, setClientDescription] = useState('');



    async function handleSubmit(e) {
        if(creator1st===''||creator2nd===''||creator3rd===''||creator4th===''||client1st===''||client2nd===''||client3rd===''||client4th===''||client5th==='')
        {
            alert('Rate  all the required field')
                return 
        }
        // console.log('hit')

        e.preventDefault(); 
        
        try{
            
           await postAPI('/postProject/add-manager-postProject-rating', {
                _id: props.projectId, "rate": creator1st,
                "feedback": [ { qus: "How likely are you to choose the creator for another project (out of 5) ?" , ans: creator2nd }, { qus:'Rate the creator on their timeliness w.r.t. delivery (out of 5) ?', ans: creator3rd }, 
                { qus: "Rate the creator on their profossionalism (out of 5) ?", ans: creator4th }], description: creatorDescription, user_type: "creator"
            })
           await postAPI('/postProject/add-manager-postProject-rating', {
                _id: props.projectId, "rate": client1st,
                "feedback": [ { qus: "How likely is the client to work with GC again (out of 5) ?", ans: client2nd }, 
                { qus: "Rate the client on their response time (out of 5) ?", ans: client3rd }, 
                { qus: "Rate the client on their professionalism, i.e., attitude, communication (out of 5) ?", ans: client4th },
                {qus:"Rate the client on their payment processes & timelines (out of 5) ?",ans:client5th}], description: clientDescription, user_type: "client"
            })
        }catch(ex){
            console.log(ex)
            
        }
        window.location.reload();

    }
    function handleOpen(e){ 

        setOpen(true);
    }
    function handleClose(e){
        setOpen(false)

    }
    const {clientName,creatorName}=props;
  
    return (
        

        <div>
              <button type="button" onClick={handleOpen}className="btn btn-primary btn-lg" style={{marginBottom:'10px'}} data-toggle="modal" data-target="#exampleModalScrollable">Manager Review</button>

            <div  className={"modal fade" + (open ? " show d-block" : " d-none")}   data-backdrop="static" tabIndex="-1" role="dialog" id="exampleModalScrollable" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                       
                        <div className="modal-body">

                            <section>
                                <div className="rt-container">
                                    <div className="col-rt-12">
                                        <div className="Scriptcontent">                                    
                                            <div className="feedback">

                            <h3 className="modal-title" id="exampleModalScrollableTitle">For Creator Review - {creatorName}</h3>
                            <hr/>

                                                <label>Qs.1: Overall Rating (out of 5) ?   <i style={{
                                                        position: 'absolute',
                                                        fontSize: '9px',
                                                        color: 'red',
                                                        marginLeft: '5px',
                                                    }} className="fas fa-asterisk"></i></label><br />

                                                <span className="star-rating"   onChange={e => setCreator1st(e.target.value)}>

                                                    <input type="radio" name="rating1"   value="1" /><i></i>
                                                    <input type="radio" name="rating1" value="2" /><i></i>
                                                    <input type="radio" name="rating1" value="3" /><i></i>
                                                    <input type="radio" name="rating1" value="4" /><i></i>
                                                    <input type="radio" name="rating1" value="5" /><i></i>
                                                </span>

                                                <div className="clear"></div>
                                                <hr className="survey-hr" />
                                                <label>Qs.2: How likely are you to choose the creator for another project (out of 5) ?   <i style={{
                                                        position: 'absolute',
                                                        fontSize: '9px',
                                                        color: 'red',
                                                        marginLeft: '5px',
                                                    }} className="fas fa-asterisk"></i></label><br />
                                                <span className="star-rating" onChange={e => setCreator2nd(e.target.value)}>
                                                    <input type="radio" name="rating2"  value="1" /><i></i>
                                                    <input type="radio" name="rating2" value="2" /><i></i>
                                                    <input type="radio" name="rating2" value="3" /><i></i>
                                                    <input type="radio" name="rating2" value="4" /><i></i>
                                                    <input type="radio" name="rating2" value="5" /><i></i>
                                                </span>

                                                <div className="clear"></div>
                                                <hr className="survey-hr" />
                                                <label>Qs.3: Rate the creator on their timeliness w.r.t. delivery (out of 5) ?   <i style={{
                                                        position: 'absolute',
                                                        fontSize: '9px',
                                                        color: 'red',
                                                        marginLeft: '5px',
                                                    }} className="fas fa-asterisk"></i></label><br />
                                                <span className="star-rating" onChange={e => setCreator3rd(e.target.value)}>
                                                    <input type="radio" name="rating3"  value="1" /><i></i>
                                                    <input type="radio" name="rating3" value="2" /><i></i>
                                                    <input type="radio" name="rating3" value="3" /><i></i>
                                                    <input type="radio" name="rating3" value="4" /><i></i>
                                                    <input type="radio" name="rating3" value="5" /><i></i>
                                                </span>
                                                <div className="clear"></div>
                                                <hr className="survey-hr" />
                                                <label>Qs.4: Rate the creator on their profossionalism  (out of 5)?   <i style={{
                                                        position: 'absolute',
                                                        fontSize: '9px',
                                                        color: 'red',
                                                        marginLeft: '5px',
                                                    }} className="fas fa-asterisk"></i></label><br />
                                                <span className="star-rating" onChange={e => setCreator4th(e.target.value)}>
                                                    <input type="radio" name="rating4"  value="1" /><i></i>
                                                    <input type="radio" name="rating4" value="2" /><i></i>
                                                    <input type="radio" name="rating4" value="3" /><i></i>
                                                    <input type="radio" name="rating4" value="4" /><i></i>
                                                    <input type="radio" name="rating4" value="5" /><i></i>
                                                </span>
                                                <div className="clear"></div>
                                                <hr className="survey-hr" />

                                                <label >5: Description: </label><br /><br />
                                                <textarea onChange={e => setCreatorDescription(e.target.value)} value={creatorDescription} cols="75" name="commentText" rows="5" style={{ width: "100%" }}></textarea><br />
                                                <br />
                                                <div className="clear"></div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>


                            {/* client review */}
                            <div className="modal-header">
                                <h3 className="modal-title" id="exampleModalLong">For Client Review - {clientName}</h3>

                            </div>
                            <div className="modal-body">
                                <section>
                                    <div className="rt-container">
                                        <div className="col-rt-12">
                                            <div className="Scriptcontent">


                                                <div className="feedback">

                                    

                                                    <label>Qs.1: Overall Rating (out of 5) ?   <i style={{
                                                        position: 'absolute',
                                                        fontSize: '9px',
                                                        color: 'red',
                                                        marginLeft: '5px',
                                                    }} className="fas fa-asterisk"></i></label><br />

                                                    <span className="star-rating" onChange={e =>setClient1st(e.target.value)}>

                                                        <input type="radio" name="rating5" value="1" /><i></i>
                                                        <input type="radio" name="rating5" value="2" /><i></i>
                                                        <input type="radio" name="rating5" value="3" /><i></i>
                                                        <input type="radio" name="rating5" value="4" /><i></i>
                                                        <input type="radio" name="rating5" value="5" /><i></i>
                                                    </span>

                                                    <div className="clear"></div>
                                                    <hr className="survey-hr" />
                                                    <label>Qs.2: How likely is the client to work with GC again (out of 5) ?   <i style={{
                                                        position: 'absolute',
                                                        fontSize: '9px',
                                                        color: 'red',
                                                        marginLeft: '5px',
                                                    }} className="fas fa-asterisk"></i></label><br />
                                                    <span className="star-rating" onChange={e =>setClient2nd(e.target.value)}>
                                                        <input type="radio" name="rating6" value="1" /><i></i>
                                                        <input type="radio" name="rating6" value="2" /><i></i>
                                                        <input type="radio" name="rating6" value="3" /><i></i>
                                                        <input type="radio" name="rating6" value="4" /><i></i>
                                                        <input type="radio" name="rating6" value="5" /><i></i>
                                                    </span>

                                                    <div className="clear"></div>
                                                    <hr className="survey-hr" />
                                                    <label>Qs.3: Rate the client on their response time (out of 5) ?   <i style={{
                                                        position: 'absolute',
                                                        fontSize: '9px',
                                                        color: 'red',
                                                        marginLeft: '5px',
                                                    }} className="fas fa-asterisk"></i></label><br />
                                                    <span className="star-rating" onChange={e => setClient3rd(e.target.value)}>
                                                        <input type="radio" name="rating7"  value="1" /><i></i>
                                                        <input type="radio" name="rating7" value="2" /><i></i>
                                                        <input type="radio" name="rating7" value="3" /><i></i>
                                                        <input type="radio" name="rating7" value="4" /><i></i>
                                                        <input type="radio" name="rating7" value="5" /><i></i>
                                                    </span>
                                                    <div className="clear"></div>
                                                    <hr className="survey-hr" />
                                                    <label>Qs.4: Rate the client on their professionalism, i.e., attitude, communication (out of 5) ?   <i style={{
                                                        position: 'absolute',
                                                        fontSize: '9px',
                                                        color: 'red',
                                                        marginLeft: '5px',
                                                    }} className="fas fa-asterisk"></i></label><br />
                                                    <span className="star-rating" onChange={e => setClient4th(e.target.value)}>

                                                        <input type="radio" name="rating8"  value="1" /><i></i>
                                                        <input type="radio" name="rating8" value="2" /><i></i>
                                                        <input type="radio" name="rating8" value="3" /><i></i>
                                                        <input type="radio" name="rating8" value="4" /><i></i>
                                                        <input type="radio" name="rating8" value="5" /><i></i>
                                                    </span>
                                                    <div className="clear"></div>
                                                    <hr className="survey-hr" />
                                                    <label>Qs.5: Rate the client on their payment processes & timelines (out of 5) ?    <i style={{
                                                        position: 'absolute',
                                                        fontSize: '9px',
                                                        color: 'red',
                                                        marginLeft: '5px',
                                                    }} className="fas fa-asterisk"></i></label><br />
                                                    <span className="star-rating" onChange={e => setClient5th(e.target.value)}>

                                                        <input type="radio" name="rating9"  value="1" /><i></i>
                                                        <input type="radio" name="rating9" value="2" /><i></i>
                                                        <input type="radio" name="rating9" value="3" /><i></i>
                                                        <input type="radio" name="rating9" value="4" /><i></i>
                                                        <input type="radio" name="rating9" value="5" /><i></i>
                                                    </span>

                                                    <div className="clear"></div>
                                                    <hr className="survey-hr" />

                                                    <label > Description:</label><br /><br />
                                                    <textarea onChange={e => setClientDescription(e.target.value)} value={clientDescription} cols="75" name="commentText" rows="5" style={{ width: "100%" }}></textarea><br />
                                                    <br />
                                                    <div className="clear"></div>


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>

                        </div>

                        <div className="modal-footer">
                            <button type="button" onClick={handleClose} className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" onClick={handleSubmit} className="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
      
    )
}


export default ModalReview

