import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

class CustomHeader extends Component {

  render() {
  
      const { location , _getCustomList} = this.props;
      const artistId = location.pathname.split("/:").pop();
  
      const artistCurrent = (path) => {
        const currentPath = location.pathname.split("/");
        return currentPath[2] === path;
      };  

    //   const currentPath = location.pathname.split("/").pop();     
  
    //   const forSignPath = location.pathname.split("/");     

    return (
        <React.Fragment>
            {(artistId === "/custom" || artistId === "/custom/Industry" || artistId === "/custom/Specialization") &&
                <ul className="nav nav-tabs nav-overflow header-tabs">
                    <li className="nav-item">
                        <Link
                            to={`/custom`}
                            className={`nav-link ${artistId === "/custom" ? "active" : ""
                                }`}
                        >
                            Software
                            <span className="badge badge-pill badge-soft-secondary ml-2">
                                {_getCustomList?.customSoftwareList?.totalCount}                   
                            </span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to={`/custom/Industry`}
                            className={`nav-link ${artistCurrent("Industry") ? "active" : ""
                                }`}
                        >
                            Industry
                            <span className="badge badge-pill badge-soft-secondary ml-2">
                            {_getCustomList?.customIndustryList?.totalCount}                   
                            </span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to={`/custom/Specialization`}
                            className={`nav-link ${artistCurrent("Specialization") ? "active" : ""
                                }`}
                        >
                            Specialization
                            <span className="badge badge-pill badge-soft-secondary ml-2">
                            {_getCustomList?.customSpecializationList?.totalCount}                   
                            </span> 
                        </Link>
                    </li>
                </ul>
            }
      </React.Fragment>
    );
  }
}
const mapState = ({ customReducer }) => {
    return {
        _getCustomList:customReducer,
  };
};
const mapDispatch = () => ({

});
export default connect(mapState, mapDispatch)(CustomHeader);