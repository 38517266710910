import React, { Component } from "react";
import { connect } from "react-redux";
import {
  incompleteHighTransaction,
  closeProject,
  deleteProjectList,
} from "../../../store/action/transaction.action";
import Table from "./Table";
import ReactPaginate from "react-paginate";
import ErrorApi from "../Common/Error/ErrorApi";
import { deleteAPI } from "../../../store/api-interface";

class IncompleteHighScopeTrans extends Component {
  state = {
    transactionsList: 1,
    selectedPage: 1,
    transactionActive: false,
    LoadingIs: false,
    transactionData: [],
    transactionIs: "",
    minBudget: "",
    maxBudget: "",
    deadline: "",
    totalPages: 0,
    closeProjectActive: false,
    inCompleteTransaction: false,
    deleteActiveIs: false,
    headCheckbox: false,
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        const {
          transactionsList,
          transactionIs,
          minBudget,
          maxBudget,
          deadline,
        } = this.state;

        const transactionData = {
          List: transactionsList,
          project: transactionIs,
          min_budget: minBudget,
          max_budget: maxBudget,
          deadline: deadline,
        };
        this.props.$incompleteHighTransaction(transactionData);
      }
    );
  };

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    const { transactionIs, minBudget, maxBudget, deadline } = this.state;
    const transactionData = {
      List: selectedData,
      project: transactionIs,
      min_budget: minBudget,
      max_budget: maxBudget,
      deadline: deadline,
    };
    this.setState({ selectedPage: selectedData });
    this.props.$incompleteHighTransaction(transactionData);
  };

  handleCloseProject = (id) => {
    this.setState({
      closeProjectActive: true,
    });
    const closeProjectData = { _id: id };
    this.props.$closeProject(closeProjectData);
  };

  handleDeleteProject = (id) => {
    this.setState({ deleteActiveIs: true });
    const currentData = { _id: id };
    this.props.$deleteProjectList(currentData);
  };

  handleInCompleteTransaction = () => {
    const { transactionsList, transactionIs } = this.state;

    this.setState({
      LoadingIs: true,
    });

    const transactionData = {
      List: transactionsList,
      project: transactionIs,
    };
    this.props.$incompleteHighTransaction(transactionData);
  };

  componentDidMount() {
    this.handleInCompleteTransaction();
    this.setState({
      inCompleteTransaction: true,
    });
  }

  componentWillReceiveProps(nextProps) {
    const inCompletetransaction =
      nextProps._incompleteHighTransaction?.incompleteHighList;

    // console.log("123 = ", nextProps);

    const closedProject = nextProps._closeProject.closeProjectIs;
    const deleteProject = nextProps._deleteProjectList.deleteProjectIs;
    const { closeProjectActive, deleteActiveIs } = this.state;

    if (inCompletetransaction) {
      this.setState({
        transactionData: inCompletetransaction.PostProjects,
        LoadingIs: false,
        totalPages: inCompletetransaction.totalPages,
      });
    }
    if (closedProject && closeProjectActive) {
      alert(closedProject.message);
      this.setState({
        closeProjectActive: false,
      });
      this.handleInCompleteTransaction();
    }
    if (deleteProject && deleteActiveIs) {
      this.setState({ deleteActiveIs: false });
      this.handleInCompleteTransaction();
    }
  }

  onCheckboxClick = (id) => {
    const { headCheckbox, transactionData } = this.state;
    if (id === "all") {
      const newTransactionList = transactionData.map((tranx) => {
        headCheckbox ? (tranx.selected = false) : (tranx.selected = true);
        return tranx;
      });
      this.setState({
        transactionData: newTransactionList,
        headCheckbox: !this.state.headCheckbox,
      });
    } else {
      const newTransactionList = transactionData.map((tranx) => {
        if (tranx._id === id) {
          tranx.selected = !tranx.selected;
        }
        return tranx;
      });
      this.setState({ transactionData: newTransactionList });
    }
  };

  handleDeleteAll = () => {
    const { transactionData } = this.state;
    this.setState({ isDeleting: true });

    const deletedIds = [];
    transactionData.forEach((tranx) => {
      tranx.selected && deletedIds.push(tranx._id);
    });

    if (window.confirm("Are you sure you want to delete these transactions.")) {
      deleteAPI(`/postProject/delete-postproject-multiple`, { _id: deletedIds })
        .then((res) => {
          this.handlePageClick({ selected: this.state.selectedPage - 1 });
          this.setState({ isDeleting: false });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isDeleting: false });
        });
    } else {
      this.setState({ isDeleting: false });
    }
  };

  render() {
    const { totalPages, isDeleting } = this.state;
    const transactionFunc = {
      handleOnChange: this.handleOnChange,
      handleCloseProject: this.handleCloseProject,
      handleDeleteProject: this.handleDeleteProject,
      onCheckboxClick: this.onCheckboxClick,
    };

    const anySelectedTranx = this.state.transactionData.some(
      (val) => val.selected
    );

    return (
      <React.Fragment>
        {this.props._incompleteHighTransaction?.incmplteHighListErrIs ? (
          <ErrorApi {...this.props} />
        ) : (
          <div>
            <Table
              {...this.state}
              {...this.props}
              handleTransaction={transactionFunc}
            />

            <div className="">
              {anySelectedTranx && (
                <button
                  className="btn btn-danger"
                  onClick={this.handleDeleteAll}
                  disabled={isDeleting}
                >
                  Delete selected
                </button>
              )}

              {/******** pagination ************* */}

              {totalPages > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({
  incompleteHighTransaction,
  closeProject,
  deleteProjectList,
}) => {
  return {
    _incompleteHighTransaction: incompleteHighTransaction,
    _closeProject: closeProject,
    _deleteProjectList: deleteProjectList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $incompleteHighTransaction: (values) =>
    dispatchEvent(incompleteHighTransaction(values)),
  $closeProject: (values) => dispatchEvent(closeProject(values)),
  $deleteProjectList: (values) => dispatchEvent(deleteProjectList(values)),
});
export default connect(mapState, mapDispatch)(IncompleteHighScopeTrans);
