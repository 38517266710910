import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { backendList, backendDeleteList } from '../../../../store/action/BackendUser.action';

class BackendUserHeader extends Component {

  render() {
  
      const { location , _backendList, _backendDeleteList } = this.props;
      const artistId = location.pathname.split("/:").pop();
  
      const artistCurrent = (path) => {
        const currentPath = location.pathname.split("/");
        return currentPath[2] === path;
      };  

      const currentPath = location.pathname.split("/").pop();     
  
      const forSignPath = location.pathname.split("/");     

    return (
        <React.Fragment>
            {(artistId === "/backendusers" || artistId === "/backendusers/backendDeleted" ) &&
                <ul className="nav nav-tabs nav-overflow header-tabs">
                    <li className="nav-item">
                        <Link
                            to={`/backendusers`}
                            className={`nav-link ${artistId === "/backendusers" ? "active" : ""
                                }`}
                        >
                            All Backend User
                            <span className="badge badge-pill badge-soft-secondary ml-2">
                                {_backendList?.userListIs?.totalUsers}                   
                            </span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to={`/backendusers/backendDeleted`}
                            className={`nav-link ${artistCurrent("backendDeleted") ? "active" : ""
                                }`}
                        >
                            Deleted
                            <span className="badge badge-pill badge-soft-secondary ml-2">
                                {currentPath==='backendDeleted'?_backendDeleteList?.backendDeleteList?.totalUsers:_backendList?.userListIs?.deletuser}                   
                            </span>
                        </Link>
                    </li>
                </ul>
            }
      </React.Fragment>
    );
  }
}
const mapState = ({ backendList, backendDeleteList }) => {
    return {
    _backendList: backendList,
    _backendDeleteList:backendDeleteList
  };
};
const mapDispatch = (dispatchEvent) => ({
    $backendList: (values) => dispatchEvent(backendList(values)),
    $backendDeleteList: (values) => dispatchEvent(backendDeleteList(values)),
});
export default connect(mapState, mapDispatch)(BackendUserHeader);