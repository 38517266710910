import React, { Component } from "react";
import { connect } from "react-redux";
import { loginData } from "../../../store/action/loginData.action";
import LoaderSmall from "../../Autorized/Common/Loader/LoaderSmall";
// import GoogleLogin from 'react-google-login';
//  var QRCode = require('qrcode.react');

// const responseGoogle = (response) => {
//   console.log(response);
// }

class LogIn extends Component {
  state = {
    LoadingIs: false,
    loginEmail: "",
    loginPass: "",
    loginShowPass: false,
    loginToken: "",
    result: "no results",
    showBarcode: false,
    error: { loginPass: "", loginEmail: "" },
  };

  handleOnchange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    this.validation(name, value);
  };

  validation(name, value) {
    const { error } = this.state;
    switch (name) {
      case "loginEmail":
        error.loginEmail = !value.length ? "* Required Field " : "";
        break;
      case "loginPass":
        error.loginPass = !value.length ? "* Required Field " : "";
        break;
      default:
        break;
    }
    return !error.loginPass && !error.loginEmail;
  }

  validateForm() {
    const { loginPass, loginEmail, error } = this.state;

    error.loginEmail = !loginEmail.length ? "* Required Field " : "";
    error.loginPass = !loginPass.length ? "* Required Field " : "";

    this.setState({
      error,
    });

    return !error.loginPass && !error.loginEmail;
  }

  handleLogIn = (e) => {
    e.preventDefault();
    const { loginPass, loginEmail } = this.state;
    const loginData = { username: loginEmail, password: loginPass };
    if (this.validateForm()) {
      this.setState({
        LoadingIs: true,
      });
      this.props.$loginData(loginData);
    }
  };

  handleShowPass = () => {
    const { loginShowPass } = this.state;
    this.setState({ loginShowPass: !loginShowPass });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      const response = nextProps._loginData.tokenIs;
      const apiErrorIs = nextProps._loginData;
      const errStatus = apiErrorIs.loginErrStatus?.status;
      if (response.token && !apiErrorIs.Loading) {
        localStorage.setItem("adminLogin", response.token);
        localStorage.setItem("adminRole", response.role);
         window.location.reload();  
         setTimeout(function(){ 
          if(localStorage.hasOwnProperty('adminLogin')){
            localStorage.setItem("adminLogin", response.token);
            this.props.setIndexState({ adminLogin: true })
            this.setState({
              LoadingIs: false,
            });  
           }  
          }, 3000);                                               
      }
      if (errStatus && apiErrorIs.Loading) {
        alert(apiErrorIs.loginErrStatus.data.message);
        this.setState({
          LoadingIs: false,
          loginEmail: "",
          loginPass: "",
        });
      }
    }
  }

  renderLogin() {
    const { loginEmail, loginPass, LoadingIs, error } = this.state;

    const loginArr = [
      {
        type: "text",
        placeholder: "name@address.com",
        nameIs: "loginEmail",
        value: loginEmail,
        label: "Email Address",
      },
      {
        type: "password",
        placeholder: "Enter your password",
        nameIs: "loginPass",
        value: loginPass,
        label: "Password",
      },
    ];
    return (
      <section
        className="d-flex align-items-center bg-auth border-top border-top-2 border-primary"
        style={{ minHeight: "-webkit-fill-available" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-5 col-xl-4 my-5">
              <h1 className="display-4 text-center mb-3">Sign in</h1>
              <form onSubmit={this.handleLogIn}>
                {loginArr.map((obj, index) => (
                  <div className="form-group" key={index}>
                    <label>{obj.label}</label>
                    <input
                      type={obj.type}
                      className={`form-control ${((obj.nameIs ===
                        "loginEmail" &&
                        error.loginEmail) ||
                        (obj.nameIs === "loginPass" && error.loginPass)) &&
                        `error-show`} `}
                      placeholder={obj.placeholder}
                      name={obj.nameIs}
                      value={obj.value}
                      onChange={this.handleOnchange}
                      autoComplete="off"
                    />
                    {((obj.nameIs === "loginEmail" && error.loginEmail) ||
                      (obj.nameIs === "loginPass" && error.loginPass)) && (
                      <span className="error">
                        {error.loginEmail || error.loginPass}
                      </span>
                    )}
                  </div>
                ))}
                <button
                  type="submit"
                  className="btn btn-lg btn-block btn-primary mb-3"
                >
                  {LoadingIs ? <LoaderSmall /> : `Sign in`}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }

  // renderBarcode() {
  //   return (
  //     <div
  //       className="d-flex align-items-center bg-auth border-top border-top-2 border-primary"
  //       style={{ minHeight: "-webkit-fill-available" }}
  //     >
  //       <div className="container">
  //         <div className="card card-barcode mx-auto d-flex justify-content-center align-items-center  col-12 col-md-8 col-xl-6 my-5">
  //           <div className="card-header">
  //             <h4 className="card-header-title mb-0">Bar code </h4>
  //           </div>
  //           <div className="card-body">
  //             <QRCode value="12704L199131105" className="mx-auto border"/>
  //           </div>

  //           <style>
  //             {`.card-barcode{
  //                 box-shadow : 0px 0px 14px 0px silver;
  //               }`}
  //           </style>
  //         </div>
  //       </div>
  //     </div>                  
  //   );
  // }
  

  render() {       
    return (
      <React.Fragment>
        {this.renderLogin()}
        {/* <GoogleLogin
          clientId="972868849185-45tdgas19mfm83og3cus6ol3o1n9kcl6.apps.googleusercontent.com"
          buttonText="Login"
          onSuccess={responseGoogle}
          // onClick={this.responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
          scope ="https://www.googleapis.com/auth/calendar"
        /> */}
      </React.Fragment>
    );
  }
}
const mapState = ({ loginData }) => {
  return {
    _loginData: loginData,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $loginData: (values) => dispatchEvent(loginData(values)),
});
export default connect(
  mapState,
  mapDispatch
)(LogIn);
