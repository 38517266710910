import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import {
  checkStatus,
  clientList,
  tempResetPwd,
  userDelete
} from "../../../store/action/clientList.action";
import ReactPaginate from "react-paginate";
import ErrorApi from "../Common/Error/ErrorApi";
import Table from './Table';
import ClientDetailForm from '../../Autorized/Client/ClientDetailform';
import ClientCompanyDetails from '../../Autorized/Client/ClientCompanyDetail';
import ClientDeleted from '../../Autorized/Client/ClientDeleted';
import ClientAllBrief from '../../Autorized/Client/ClientAllBrief';
import ClientNotifications from "./ClientNotifications";

class Client extends Component {
  state = {
    ClientsIs: [],
    statusEmail: "",
    clientStatusIs: "",
    LoadingIs: false,
    createClientIs: false,
    paramNow: "",
    clientList: 1,
    clientAllContent: [],
    clientActive: false,
    LoaderIs: false,

    firstname: "",
    lastname: "",
    username: "",
    email: "",
    totalPages: 0,
    clientTempResetPwdActive: false,
    showAction: false,
    showActionIndex: "",
    DeleteUserIs : false
  };

  setClientState = (stateClient) => {
    this.setState(stateClient);
  };

  handlePaginationData = () => {
    const { clientList, firstname, lastname, username, email } = this.state;
    this.setState({
      LoaderIs: true,
    });
    const client = {
      page: clientList,
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
    };
    this.props.$clientList(client);
  };

 

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        const { clientList, firstname, lastname, username, email } = this.state;
        const client = {
          page: clientList,
          firstname: firstname,
          lastname: lastname,
          username: username,
          email: email,
        };
        this.props.$clientList(client);
      }
    );
  };

  /*--------------Reset temp Pwd ------------------*/
  handleGetConfirmation(id) {
    let confirmIs = window.confirm(
      `Do you want to reset password temporary (for 15 min) ?`
    );
    if (confirmIs === true) {
      this.setState({
        clientTempResetPwdActive: true,
      });
      const tempResetPwd = { _id: id };
      this.props.$tempResetPwd(tempResetPwd);
      return true;
    } else {
      return false;
    }
  }

  handleResetTemproryPassword = (id) => {
    this.handleGetConfirmation(id);
  };
  /*--------------Reset temp Pwd ------------------*/
  /* -------------Review dropdown show hide ------------*/

  handleShowAction = (index) => {
    const { showAction } = this.state;
    this.setState({
      showAction: !showAction,
      showActionIndex: index,
    });
  };

  handleHideAction = () => {
    this.setState({
      showAction: true,
    });
  };

  /* -------------Review dropdown show hide------------*/

  /* -------------Delete Client------------*/

  handleDeleteUserConfirmation(id) {
    let confirmIs = window.confirm(
      `Do you want to countinue ?`
    );
    if (confirmIs === true) {
       this.setState({
        DeleteUserIs : true
      })
      const data = { _id : id, status : 2 }
      this.props.$userDelete(data)
      return true;
    } else {
      return false;
    }
  }
  
    handleDeleteArtist = (id)=>{  

      this.handleDeleteUserConfirmation(id)
    }  

  /* -------------Delete Client------------*/

  handlePageClick = (data) => {
    let selectedPege = data.selected + 1;
    const { firstname, lastname, username, email } = this.state;
    const client = {
      page: selectedPege,
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
    };
    this.props.$clientList(client);
  };

  componentWillReceiveProps(nextProps) {
    const { clientTempResetPwdActive, DeleteUserIs } = this.state;
    const data = nextProps._clientList.clientData;
    const tempResetPwdResponse = nextProps._tempResetPwd.tempResetPwd;
    const deleteStatus = nextProps._userDelete.userDeletedIs;
    if (data) {
      this.setState({
        clientAllContent: data.users,
        LoaderIs: false,
        totalPages: data.totalPages,
      });
    }
    if (tempResetPwdResponse.status === 1 && clientTempResetPwdActive) {
      alert(tempResetPwdResponse.message);
      this.setState({
        clientTempResetPwdActive: false,
      });
    }
    if(deleteStatus && DeleteUserIs){
      this.setState({
        DeleteUserIs : false
      })
      alert(deleteStatus.message)    
      this.handlePaginationData();
    }
  }

  componentDidMount() {
    this.props.handleClients.handlepathName("/clients");
    this.handlePaginationData();
    this.setState({
      createClientIs: true,
      paramNow: "clients",
      clientActive: true,
    });
    this.props.handleClients.mainIndexstate({ clientActiveIs: true });
  }

  render() {
    const handleClientFunc = {
      handleShowAction : this.handleShowAction,
      handleResetTemproryPassword : this.handleResetTemproryPassword,
      handleDeleteArtist : this.handleDeleteArtist,
      handleOnChange : this.handleOnChange
    }


    return (
      <React.Fragment>
        {this.props._clientList.clientDataErrIs ? (
          <ErrorApi {...this.props} />
        ) : (
          <Switch>
            <Route path="/clients/allBrief/:id" render={(props)=> <ClientAllBrief {...props}/>}/> 
            <Route path="/clients/notifications/:id" render={(props)=> <ClientNotifications {...props}/>}/> 
            <Route path="/clients/clientDeleted" render={(props)=> <ClientDeleted {...props}/>}/>  
            <Route path="/clients/clientscompany/:id" render={(props) => <ClientCompanyDetails {...props}/>}/> 
            <Route path="/clients/:type/:id" render={(props)=> <ClientDetailForm {...props} {...this.state}/>}/>    
                     
            <section className="client">
              <div className="container-fluid mx-auto">

                <Table {...this.props} {...this.state} handleFunc={handleClientFunc}/>
              
                {/*------------------ Pagination--------------- */}

                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </section>              
          </Switch>
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({ checkStatus, clientList, tempResetPwd, userDelete }) => {
  return {
    _checkStatus: checkStatus,
    _clientList: clientList,
    _tempResetPwd: tempResetPwd,
    _userDelete : userDelete
  };
};
const mapDispatch = (dispatchEvent) => ({
  $checkStatus: (values) => dispatchEvent(checkStatus(values)),
  $clientList: (values) => dispatchEvent(clientList(values)),
  $tempResetPwd: (values) => dispatchEvent(tempResetPwd(values)),
  $userDelete: (values) => dispatchEvent(userDelete(values)),
});
export default connect(mapState, mapDispatch)(Client);
