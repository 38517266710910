import React, { Component } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
import { transactionGetData, transactionStatus, updateTransactions } from "../../../store/action/transaction.action";
import PostProjectTypeQuestionList from "./PostProjectTypeQuestionList";
import ProjectStyleQuestion from "./ProjectStyleQuestion";
import PostProjectCompanyQuestionList from "./PostProjectCompanyQuestionList";
import PostProjectQuestion from "./PostProjectQuestion";
import LoaderSmall from "../Common/Loader/LoaderSmall";
import StarRatings from "react-star-ratings";
import {userProfileBaseUrl, profileBaseUrl, WebsiteUrl } from "../../../Utils/BaseUrl";
import { postImage,postImageWithAuth,getPublicAPI } from "../../../store/api-interface";
import AskQuestion from "./AskQuestion";
import ModalReview from "./modalReview";
import Moment from "moment";
import Loader from "../Common/Loader/Loader";
import PdfImage from "../../../Assets/img/pdf1.png";
import ZipImage from "../../../Assets/img/zip.png";
import PPtImage from "../../../Assets/img/ppt.png";
import Exe from "../../../Assets/img/exe.jpg";
import Docx from "../../../Assets/img/docx.png";
import Mp4 from "../../../Assets/img/mp4.png";
import Xlsx from "../../../Assets/img/xlsx.jpg";
import Csv from "../../../Assets/img/csv.png";
import Wmv from "../../../Assets/img/wmv.png";
import Doc from "../../../Assets/img/doc.jpg";

const deadlineOptions = [
  { value: 7, label: 7 },
  { value: 15, label: 15 },
  { value: 30, label: 30 },
  { value: 0, label: "I dont have a deadline" },
];

const empOptions = [
  { value: "1-10", label: "1-10" },
  { value: "11-20", label: "11-20" },
  { value: "21-30", label: "21-30" },
  { value: "31-40", label: "31-40" },
  { value: "41-50", label: "41-50" },
  { value: "51-200", label: "51-200" },
  { value: "201-500", label: "201-500" },
  { value: "501-1000", label: "501-1000" },
  { value: "1000-5000", label: "1000-5000" },
  { value: "5001-10000", label: "5001-10000" },
  { value: "10000+", label: "10000+" },
];

const optionsWork = [{ label: "company" }, { label: "agency" }, { label: "individual" }];

class TransactionsDetailsForm extends Component {
  state = {
    managerName: "",
    clientName: "",
    creatorName: "",
    projectManagerReview: [],
    isComplete: false,
    isManager: false,
    project: "",
    pricingUnit: "",
    minBudget: "",
    maxBudget: "",
    deadline: null,
    unit: "",
    collateral: "",
    typeQuestion: [],
    styleQuestion: [],
    companyQuestion: [],
    projectDetailQuestion: [],
    typeQuesList: false,
    styleQuesList: false,
    projectQuesList: false,
    companyQuesList: false,
    questionIs: "",
    subtext: "",
    Qstype: "",
    indexIs: "",
    optionIs: [],
    answerIs: [],
    transactionsStatus: 0,
    projectId: "",
    projectSlug:"",
    statusLoader: false,
    projectDescription: "",
    companyWorkType: "",
    companyName: null,
    companyJobrole: "",
    companyAddLink: "",
    companyDescribes: "",
    companyLocation: null,
    companyIndustry: null,
    companyIndiviualLocation: "",
    companyEmp: null,
    companyLogo:"",
    reasonType: "",
    reasonExplain: "",
    ReasonIs: "",
    ReviewIs: [],
    closeReason: [],
    projectallData: [],
    unitAns: "",
    unitQues: "",
    collateralId: "",
    userId: "",
    refrenceLink: "",
    refrenceFiles: "",
    refLinkArr: [],
    refFilesArr: [],
    imageLoader: false,
    companyLogoLoader:false,
    updateLoader: false,
    optionData: [],
    optionInsustry: [],
    companyOptions: [],
    compErrMsg: "",
    compLocMsg: "",
    compIndMsg: "",
    compEmpMsg: "",
    compIndLocMsg: "",

    error: {
      project: "",
      minBudget: "",
      maxBudget: "",
      unitAns: "",
      companyJobrole: "",
      companyAddLink: "",
      companyDescribes: "",

      
    },
    reviewQuestn: [],
    budgetType: "",
    projectType: "",
    numMonth: "",
    numHours: "",
    meeting_end_time: "",
    meeting_schedule_date: "",
    meeting_user_email: "",
    meeting_user_name: "",
    posting_from: "",
    load: true,
    wrongLogoFormat:false
  };

  handleWorkChange = (companyWorkType) => {
    this.setState({ companyWorkType });
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    this.validation(name, value);
  };

  handleChange = (companyName) => {
    // console.log("***", companyName);
    this.setState({ companyName, compErrMsg: "" });
  };

  handleInputChange = (inputValue) => {
    getPublicAPI(`/company?name=${inputValue}`).then((res) => {
      const response = res.data.data;
      if (response) {
        this.setState({ companyOptions: response });
      }
    });
  };

  handleDeadlineChange = (deadline) => {
    this.setState({ deadline });
  };

  handleEmpChange = (companyEmp) => {
    this.setState({ companyEmp, compEmpMsg: "" });
  };

  handleLocationChange = (companyLocation) => {
    this.setState({ companyLocation, compLocMsg: "" });
  };

  handleIndiLocationChange = (companyIndiviualLocation) => {
    this.setState({ companyIndiviualLocation, compIndLocMsg: "" });
  };

  handleInputLocation = (inputValue) => {
    getPublicAPI(`/location?name=${inputValue}`).then((res) => {
      const response = res.data.data;
      if (response) {
        this.setState({ optionData: response });
      }
    });
  };

  handleIndustryChange = (companyIndustry) => {
    this.setState({ companyIndustry, compIndMsg: "" });
  };

  handleInputIndustry = (inputValue) => {
    getPublicAPI(`/industry?name=${inputValue}`).then((res) => {
      const response = res.data.data;
      if (response) {
        this.setState({ optionInsustry: response });
      }
    });
  };

  handleRefLink = (e) => {
    e.preventDefault();
    if (e.keyCode === 13) {
      const { refrenceLink, refLinkArr } = this.state;
      const isLink = /[(http(s)?):(www)?a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/.test(refrenceLink);
      if (isLink) {
        const currentLinks = refLinkArr.concat(refrenceLink);
        this.setState({ refLinkArr: currentLinks, refrenceLink: "" });
      } else {
        alert("Please Enter valid link..");
      }
    }
  };

  gethtmldata = (txt) => {
    var htmlObject = document.createElement('div');
    htmlObject.innerHTML = txt;
    let temp = htmlObject?.innerText.trim()
    return temp
  } 
  handleDeleteFiles = (index) => {
    const { refFilesArr } = this.state;
    if (index !== -1) {
      let refTemp = [];
      for (let i=0;i<refFilesArr?.length;i++){
        if(i!==index)
          refTemp.push(refFilesArr[i])
      }
      // var removeFiles = refFilesArr.splice(0, index);
      this.setState({
        refFilesArr: refTemp,
      });
    }
  };

  handleUploadFiles = (e) => {
    let refFile = e.target.files;
    if (refFile) {
      this.setState({ imageLoader: true });
      const formData = new FormData();
      for (var i = 0; i < refFile.length; i++) {
        formData.append("images", refFile[i]);
      }
      postImage(`/collateral/upload-project-ans-images`, formData).then((res) => {
        if (res) {
          const response = res.data.project_images;
          const filesIs = this.state.refFilesArr.concat(response);
          this.setState({
            refFilesArr: filesIs,
            imageLoader: false,
          });
        }
      });
    }
  };

  handleUploadCompanyLogo = (e) =>{
    let refLogo = e.target.files[0];
    
    if(refLogo){
      // console.log(refLogo?.name?.split('.'))
      const arr1 = ["png", "jpg", "jpeg","gif"];
      // const arr2=refLogo?.name?.split('.')
      const fileFormat = refLogo?.name?.split('.')?.pop();
      // let isFounded = arr1.some( ai => arr2.includes(ai) );
      let isFounded = arr1.includes(fileFormat)
      // console.log(refLogo,isFounded,"--------->")
      if(isFounded){
        this.setState({companyLogoLoader : true,wrongLogoFormat:false})
        const formData = new FormData();
        formData.append("userImages",refLogo)
        postImageWithAuth(`/backendUser/profile-image`, formData).then((res) => {
          if (res) {
            // console.log(res?.data?.name)
          const imageIs = res?.data?.name;
          this.setState({
            companyLogo:imageIs,
            companyLogoLoader:false,
            })
          }
        })
        .catch(()=>{
          this.setState({
            companyLogoLoader:false
          })
        });
      }
      else{
        this.setState({
          wrongLogoFormat:true
        })
      }
    }
  }

  handleTypeEditQues = (obj, index, quesType) => {
    if (quesType === "typeQues") {
      this.setState({
        styleQuesList: false,
        typeQuesList: true,
        projectQuesList: false,
        companyQuesList: false,
      });
    } else if (quesType === "styleQues") {
      this.setState({
        styleQuesList: true,
        typeQuesList: false,
        projectQuesList: false,
        companyQuesList: false,
      });
    } else if (quesType === "projectQues") {
      this.setState({
        projectQuesList: true,
        styleQuesList: false,
        typeQuesList: false,
        companyQuesList: false,
      });
    } else if (quesType === "companyQues") {
      this.setState({
        projectQuesList: false,
        styleQuesList: false,
        typeQuesList: false,
        companyQuesList: true,
      });
    }
    this.setState({
      indexIs: index,
      questionIs: obj.question,
      subtext: obj.subtext,
      Qstype: obj.type,
      optionIs: obj.option,
      answerIs: obj.answer,
    });
  };

  handleCancelForm = () => {
    this.setState({
      projectQuesList: false,
      styleQuesList: false,
      typeQuesList: false,
      companyQuesList: false,
    });
  };

  handleSaveForm = (index, answers) => {
    const {
      typeQuestion,
      typeQuesList,
      styleQuesList,
      styleQuestion,
      projectQuesList,
      companyQuesList,
      projectDetailQuestion,
      companyQuestion,
    } = this.state;
    if (answers.length > 0) {
      if (typeQuesList) {
        typeQuestion[index] = { ...typeQuestion[index], answer: answers };
        this.setState({
          typeQuestion,
          typeQuesList: false,
        });
      } else if (styleQuesList) {
        styleQuestion[index] = { ...styleQuestion[index], answer: answers };
        this.setState({
          styleQuestion,
          styleQuesList: false,
        });
      } else if (projectQuesList) {
        projectDetailQuestion[index] = {
          ...projectDetailQuestion[index],
          answer: answers,
        };
        this.setState({ projectDetailQuestion, projectQuesList: false });
      } else if (companyQuesList) {
        companyQuestion[index] = { ...companyQuestion[index], answer: answers };
        this.setState({ companyQuestion, companyQuesList: false });
      }
    } else {
      alert("Please choose answer..");
    }
  };

  validation(name, value) {
    const { error, minBudget, companyWorkType, pricingUnit } = this.state;
    switch (name) {
      case "project":
        error.project = !value ? "Required Field" : "";
        break;
      case "minBudget":
        error.minBudget = !value ? "Required Field" : "";
        break;
      case "maxBudget":
        error.maxBudget = !value
          ? "Required Field"
          : Number(minBudget) > Number(value)
          ? "Max Budget will be grater from min budget"
          : "";
        break;
      case "unitAns":
        error.unitAns = pricingUnit !== "per_project" && !value ? "Required Field" : "";
        break;
      case "companyJobrole":
        error.companyJobrole = companyWorkType?.label !== "individual" && !value.length > 0 ? "Required field" : "";
        break;
      case "companyAddLink":
        const isLink =
          value && /[(http(s)?):(www)?a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/.test(value);
        error.companyAddLink =
          companyWorkType?.label !== "individual" && !value.length > 0 ? "Required Field" : !isLink ? "Enter valid link" : "";
        break;
      case "companyDescribes":
        error.companyDescribes = companyWorkType?.label !== "individual" && !value.length > 0 ? "Required Field" : "";
        break;
      default:
        break;
    }
  }

  validateForm() {
    const {
      error,
      minBudget,
      maxBudget,
      unitAns,
      companyWorkType,
      companyJobrole,
      companyAddLink,
      companyDescribes,
      pricingUnit,
      project,
    } = this.state;
    error.project = !project ? "Required Field" : "";
    error.minBudget = !minBudget ? "Required Field" : "";
    error.maxBudget = !maxBudget
      ? "Required Field"
      : Number(minBudget) > Number(maxBudget)
      ? "Max Budget will be grater from min budget"
      : "";
    error.unitAns = pricingUnit !== "per_project" && !unitAns ? "Required Field" : "";
    const isLink =
      companyAddLink &&
      /[(http(s)?):(www)?a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/.test(companyAddLink);
    error.companyAddLink =
      companyWorkType?.label !== "individual" && !companyAddLink.length > 0
        ? "Required Field"
        : companyWorkType?.label !== "individual" && !isLink
        ? "Enter valid link"
        : "";
    error.companyJobrole = companyWorkType?.label !== "individual" && !companyJobrole.length > 0 ? "Required field" : "";
    error.companyDescribes = companyWorkType?.label !== "individual" && !companyDescribes.length > 0 ? "Required Field" : "";
    this.setState({ error });
    return (
      !error.minBudget &&
      !error.maxBudget &&
      !error.unitAns &&
      !error.companyAddLink &&
      !error.companyJobrole &&
      !error.companyDescribes
    );
  }

  handleUpdate = () => {
    const {
      minBudget,
      maxBudget,
      deadline,
      project,
      projectDescription,
      companyName,
      companyWorkType,
      companyJobrole,
      companyAddLink,
      companyDescribes,
      companyIndustry,
      companyLocation,
      companyEmp,
      companyLogo,
      companyIndiviualLocation,
      collateralId,
      userId,
      styleQuestion,
      typeQuestion,
      projectDetailQuestion,
      companyQuestion,
      refFilesArr,
      refLinkArr,
      unitAns,
      pricingUnit,
      wrongLogoFormat
    } = this.state;

    const transactionId = this.props.match.params.id.split(":")[1];

    const currentData = {
      _id: transactionId,
      min_budget: minBudget,
      max_budget: maxBudget,
      dealline: deadline?.value,
      project_name: project,
      project_description: projectDescription,
      project_add_reference_link: refLinkArr,
      project_add_reference_files: refFilesArr,
      company_name: companyName?.value,
      company_work_type: companyWorkType.label,
      company_job_role: companyJobrole,
      company_add_link: companyAddLink,
      company_describes: companyDescribes,
      company_location: companyLocation?.label,
      company_industry: companyIndustry?.label,
      company_employees: companyEmp?.value,
      company_logo:companyLogo,
      company_individual_location: companyIndiviualLocation?.label,
      collateral_id: collateralId,
      style_question: styleQuestion,
      type_question: typeQuestion,
      project_question: projectDetailQuestion,
      company_question: companyQuestion,
      user_id: userId,
      unit: pricingUnit !== "per_project" ? unitAns : 0,
    };
    if (this.validateForm()) {
      if (companyWorkType?.label === "individual" && companyIndiviualLocation?.label.length > 0) {
        this.setState({ updateLoader: true,wrongLogoFormat:false });
        this.props.$updateTransactions(currentData);
      } else if (
        companyWorkType?.label !== "individual" &&
        companyName?.value.length > 0 &&
        companyLocation?.label.length > 0 &&
        companyIndustry?.label.length > 0 &&
        companyEmp?.value.length > 0 &&
        wrongLogoFormat===false
      ) {
        this.setState({ updateLoader: true, compErrMsg: "" });
        this.props.$updateTransactions(currentData);
      } else {
        this.setState({
          compErrMsg: companyWorkType?.label !== "individual" && companyName?.value?.length === 0 ? "Required Field" : "",
          compLocMsg: companyWorkType?.label !== "individual" && companyLocation?.label?.length === 0 ? "Required Field" : "",
          compIndMsg: companyWorkType?.label !== "individual" && companyIndustry?.label?.length === 0 ? "Required Field" : "",
          compEmpMsg: companyWorkType?.label !== "individual" && companyEmp?.label?.length === 0 ? "Required Field" : "",
          compIndLocMsg:
            companyWorkType?.label === "individual" && companyIndiviualLocation?.label.length === 0 ? "Required Field" : "",
        });
      }
    }
  };

  getProjectstatus = (statusIs) => {
    const data = [
      {
        status: [0, 1, 2, 4],
        textColor: "badge-soft-secondary",
        text: "Incomplete Brief",
      },
      {
        status: [5, 6],
        textColor: "badge-soft-success",
        text: "Accepted",
      },
      {
        status: [7],
        textColor: "badge-soft-primary",
        text: "Completed",
      },
      {
        status: [8],
        textColor: "badge-soft-dark",
        text: "Closed",
      },
      {
        status: [9],
        textColor: "badge-soft-danger",
        text: "Rejected",
      },
    ];

    const currentdata = data.find((el) => el.status.find((item) => item === statusIs));

    return <div className={`mr-2 badge ${currentdata?.textColor}`}>{currentdata?.text}</div>;
  };

  handleDeleteLink = (index) => {
    const { refLinkArr } = this.state;

    if (index !== -1) {
      let refTemp = [];
      for (let i=0;i<refLinkArr?.length;i++){
        if(i!==index)
          refTemp.push(refLinkArr[i])
      }
      // var removeLink = refLinkArr?.splice(0,index)
      // console.log(refTemp)
      this.setState({
        refLinkArr: refTemp,
      });
    }
  };

  getReferenceLink = (obj, index) => {
    const validUrl = obj.split("/").slice(0)[0];
    var currentUrl = "";
    if (validUrl === "http:" || validUrl === "https:") {
      currentUrl = obj;
    } else {
      currentUrl = "http://" + obj;
    }
    return (
      <span className="badge badge-soft-light" key={index}>
        <a href={currentUrl} target="_blank" rel="noopener noreferrer">
          <span className="mt-2">Link {index + 1} </span>
        </a>
        <i className="fe fe-trash ml-3" style={{ fontSize: "12px" }} onClick={() => this.handleDeleteLink(index)}></i>
      </span>
    );
  };

  handleGetConfirmation(obj, id) {
    let confirmIs = window.confirm(`Do you want to ${obj === 5 ? `accept` : `reject`} this Transaction ?`);
    if (confirmIs === true) {
      this.setState({
        statusLoader: true,
      });
      const statusData = { _id: id, status: obj };
      this.props.$transactionStatus(statusData);
      return true;
    } else {
      return false;
    }
  }

  handleTransactonStatus = (status, id) => {
    const { managerName } = this.state;
    if(managerName){
      let currentStatus = 0;
      if (status === "accept") {
        currentStatus = 5;
      } else {
        currentStatus = 9;
      }
      this.handleGetConfirmation(currentStatus, id);
    }else{
      alert("You must first assign the Manager")
      this.props.history.push(`/transactions/transactionUserDetails/:${id}`)
    }  
  };

  handleTransactionData = (obj) => {
    this.props.$transactionGetData(obj);
  };

  componentDidMount() {
    const transactionId = this.props.match.params.id.split(":").pop();
    this.handleTransactionData(transactionId);
  }

  componentWillReceiveProps(nextProps) {
    const { statusLoader, updateLoader } = this.state;
    const postProjectData = nextProps._transactionGetData?.transactionData;
    const dataIs = postProjectData?.project_data;

    const dataNow = postProjectData?.collateral;
    const callBackData = postProjectData?.requestCallbackReason;
    const feedbackIs = postProjectData?.completeProjectReview;
    const closeReason = postProjectData?.closeProjectReview;
    const statusData = nextProps._transactionStatus?.postProjectStatus;

    const clientData = postProjectData?.clientUser;
    const UpdateRes = nextProps._updateTransactions?.updateDetailsIs;

    if (this.props?._transactionGetData?.transactionData !== nextProps?._transactionGetData?.transactionData) {
      this.setState({ load: false });
    }

    if (postProjectData && !updateLoader) {
      if (postProjectData.project_data) {
        // console.log("postProjectData = ", postProjectData)
        let creatorName = "";
        postProjectData &&
          postProjectData?.creatorUser?.length > 0 &&
          postProjectData.creatorUser.forEach((item) => {
            if (item?.proposal?.proposal?.status == 2) {
              creatorName = item?.user_id2?.first_name + " " + (item?.user_id2?.last_name ? item?.user_id2?.last_name : "");
            }
          });

        // const creatorName = postProjectData?.creatorUser.length > 0 ? postProjectData?.creatorUser[0]?.user_id2?.first_name + " " + ((postProjectData?.creatorUser?.[0]?.user_id2?.last_name) ? postProjectData?.creatorUser?.[0]?.user_id2?.last_name : "") : "";
        const lastname =
          " " + postProjectData?.clientUser?.user_id1?.last_name ? postProjectData?.clientUser?.user_id1?.last_name : "";
        this.setState({
          clientName: postProjectData?.clientUser?.user_id1?.first_name + " " + lastname,
          creatorName,
          managerName: dataIs?.manager_name,
          isComplete: dataIs?.status === 7 ? true : false,
          isManager: postProjectData?.checkManager === "No" ? false : true,
          project: dataIs?.project_name,

          meeting_end_time: dataIs?.meeting_end_time? Moment(dataIs?.meeting_end_time).format("YYYY-MM-DD h:mm a"):"",
          meeting_schedule_date: dataIs?.meeting_schedule_date ? Moment(dataIs?.meeting_schedule_date).format("YYYY-MM-DD h:mm a") :"",
          meeting_user_email: dataIs?.meeting_user_email,
          meeting_user_name: dataIs?.meeting_user_name,

          pricingUnit: dataNow?.pricing_unit,
          unit: dataNow?.unit,
          collateral: dataNow?.name,
          collateralId: dataNow?._id,
          userId: clientData?.user_id1?._id,
          minBudget: dataIs?.min_budget,
          maxBudget: dataIs?.max_budget,
          deadline: { value: dataIs?.dealline, label: dataIs?.dealline === 0 ? "I dont have a deadline" : dataIs?.dealline },
          transactionsStatus: dataIs?.status,
          projectId: dataIs?._id,
          projectSlug:dataIs?.project_slug,
          styleQuestion: postProjectData?.projectStyleQuestion,
          typeQuestion: postProjectData?.ProjectTypeQuestion,
          companyQuestion: postProjectData?.projectCompanyQuestion,
          projectDetailQuestion: postProjectData?.projectProjectQuestion,
          projectManagerReview: postProjectData?.ProjectManagerReview,
          companyWorkType: { label: dataIs?.company_work_type.toLowerCase() },
          companyName: { label: dataIs?.company_name, value: dataIs?.company_name },
          companyJobrole: dataIs?.company_job_role ? dataIs?.company_job_role : "",
          companyAddLink: dataIs?.company_add_link ? dataIs?.company_add_link : "",
          companyLogo : dataIs?.company_logo ? dataIs?.company_logo : "",
          companyDescribes: dataIs?.company_describes,
          companyLocation: { label: dataIs?.company_location },
          companyIndustry: { label: dataIs?.company_industry },
          companyIndiviualLocation: { label: dataIs?.company_individual_location },
          companyEmp: { value: dataIs?.company_employees, label: dataIs?.company_employees },
          projectDescription: this.gethtmldata(dataIs?.project_description),
          reasonType: callBackData ? callBackData?.type : "",
          reasonExplain: callBackData ? callBackData?.explain : "",
          ReasonIs: callBackData ? callBackData?.reason : "",
          ReviewIs:
            dataIs.status === 7 && feedbackIs
              ? feedbackIs
              : (dataIs.status === 8 && closeReason) ||
                (dataIs.status === 5 && closeReason) ||
                (dataIs.status === 6 && closeReason) ||
                (dataIs.status === 3 && closeReason)
              ? closeReason
              : [],
          reviewQuestn: postProjectData?.completeProjectQuestion,
          projectallData: dataIs,
          refLinkArr: dataIs?.project_add_reference_link? dataIs?.project_add_reference_link:[],
          refFilesArr: dataIs?.project_add_reference_files? dataIs?.project_add_reference_files:[],
          unitAns: dataIs?.unit,
          unitQues: dataNow?.unit_question,
          budgetType: postProjectData?.project_data?.budget_scope,
          posting_from: postProjectData?.project_data?.posting_from,
          projectType: postProjectData?.project_data?.sub_type,
          numMonth: postProjectData?.project_data?.number_month,
          numHours: postProjectData?.project_data?.number_hour,
        });
      }
    }

    if (statusData?.status === "1" && statusLoader) {
      const transactionId = this.props.match.params.id.split(":").pop();
      this.handleTransactionData(transactionId);
      this.setState({
        statusLoader: false,
      });
    }
    if (UpdateRes && updateLoader) {
      alert("Successfully updated...");
      this.setState({ updateLoader: false });
      const transactionId = this.props.match.params.id.split(":").pop();
      this.handleTransactionData(transactionId);
    }
  }

  render() {
    const {
      project,
      pricingUnit,
      minBudget,
      maxBudget,
      deadline,
      // unit,
      typeQuestion,
      styleQuestion,
      companyQuestion,
      projectDetailQuestion,
      transactionsStatus,
      projectId,
      projectSlug,
      statusLoader,
      collateral,
      companyWorkType,
      companyName,
      companyJobrole,
      companyAddLink,
      companyLocation,
      companyIndustry,
      companyIndiviualLocation,
      companyEmp,
      projectDescription,
      reasonType,
      reasonExplain,
      ReasonIs,
      ReviewIs,
      projectallData,
      unitAns,
      unitQues,
      refrenceLink,
      refLinkArr,
      refFilesArr,
      imageLoader,
      updateLoader,
      companyDescribes,
      optionData,
      optionInsustry,
      companyOptions,
      error,
      budgetType,
      posting_from,
      projectType,
      numMonth,
      numHours,
      meeting_end_time,
      meeting_schedule_date,
      meeting_user_email,
      meeting_user_name,
      load,
      companyLogo,
      companyLogoLoader,
    } = this.state;

    const postProject = [
      { name: "project", type: "text", value: project, label: "Project", disabledIs: false, show: true },
      {
        name: "collateral",
        value: collateral,
        label: "Collateral",
        disabledIs: true,
        type: "text",
        show: true,
      },
      {
        name: "minBudget",
        type: "number",
        value: minBudget,
        label: "Min Budget",
        disabledIs: false,
        show: true,
      },
      {
        name: "maxBudget",
        value: maxBudget,
        type: "number",
        label: "Max Budget",
        disabledIs: false,
        show: true,
      },
      {
        name: "pricingUnit",
        value: pricingUnit,
        type: "text",
        label: "Pricing Unit",
        disabledIs: true,
        show: true,
      },
      { name: "unitAns", type: "number", value: unitAns, label: unitQues ? unitQues : "unit", show: true },
    ];

    const companyDataArr = [
      {
        name: "companyJobrole",
        value: companyJobrole,
        label: "Company Job Role",
        disabledIs: false,
        show: companyWorkType.label !== "individual" ? true : false,
      },
      {
        name: "companyAddLink",
        value: companyAddLink,
        label: "Company Add Link",
        disabledIs: false,
        show: companyWorkType.label !== "individual" ? true : false,
      },
    ];

    const optionsLocation = optionData?.map((obj) => {
      let data = { label: obj.name, ...obj };
      return data;
    });

    const optionsIndustryIs = optionInsustry?.map((obj) => {
      let data = { label: obj.name, ...obj };
      return data;
    });

    const CompanyOptions = companyOptions?.map((obj) => {
      let data = { label: obj.name, value: obj.name, ...obj };
      return data;
    });

    const transactionFunc = {
      handleTypeEditQues: this.handleTypeEditQues,
      handleCancelForm: this.handleCancelForm,
      handleCreatorSubmit: this.handleCreatorSubmit,
      handleSaveForm: this.handleSaveForm,
    };
    // console.log([...ReviewIs])
    return (
      <React.Fragment>
        {load ? (
          <Loader />
        ) : (
          <section className="container">
            <div className="row">
              {postProject.map((obj, index) => (
                <React.Fragment key={index}>
                  {obj.show && (
                    <div className="col-12 col-md-6">
                      {obj.line === "hr" && <hr />}

                      <div className="form-group cu-67">
                        <label>{obj.label}</label>
                        <input
                          type="text"
                          className={`form-control ${obj.disabledIs ? "disable-input" : ""} ${
                            (error.minBudget && obj.name === "minBudget") ||
                            (error.maxBudget && obj.name === "maxBudget") ||
                            (error.project && obj.name === "project") ||
                            (error.unitAns && obj.name === "unitAns")
                              ? "error-show"
                              : ""
                          }`}
                          name={obj.name}
                          value={obj.value}
                          onChange={this.handleOnChange}
                          disabled={obj.disabledIs}
                        />
                        {obj.name === "unitAns" && <p>{this.state.unit}</p>}
                        {((error.minBudget && obj.name === "minBudget") ||
                          (error.maxBudget && obj.name === "maxBudget") ||
                          (error.project && obj.name === "project") ||
                          (error.unitAns && obj.name === "unitAns")) && (
                          <span className="error">{error.minBudget || error.maxBudget || error.project || error.unitAns}</span>
                        )}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}

              {projectType == "retainer" ? (
                <>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>Total Project Duration</label>
                      <input
                        type="text"
                        className="form-control"
                        style={{ backgroundColor: "#e9ecef" }}
                        name="mon"
                        value={numMonth  + " months"}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>Total Weekly Commitment</label>
                      <input
                        type="text"
                        className="form-control"
                        style={{ backgroundColor: "#e9ecef" }}
                        name="hours"
                        value={numHours + " hours"}
                        disabled={true}
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>Project Type</label>
                  <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: "#e9ecef" }}
                    name="proj"
                    value={projectType}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>Budget Type</label>
                  <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: "#e9ecef" }}
                    name="bud"
                    value={budgetType}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>Source Of Brief</label>
                  <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: "#e9ecef" }}
                    name="bud"
                    value={posting_from}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>Deadline</label>
                  <Select value={deadline} onChange={this.handleDeadlineChange} options={deadlineOptions} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>Project Description</label>
                  <textarea
                    type="text"
                    className="form-control"
                    name="projectDescription"
                    value={projectDescription}
                    onChange={this.handleOnChange}
                  ></textarea>
                </div>
              </div>
            </div>
          {   projectId && <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label >&#8599; URL</label>
                  <div>

                 <label>Brief</label> <a className='mx-2' href= {WebsiteUrl+'dashboard/jobs/:'+projectId+'/'+projectSlug} target='_blank'>{WebsiteUrl+'dashboard/jobs/:'+projectId+'/'+projectSlug}</a>
                </div>
                <div className="form-group">
                 <label>Talent connect</label><a className='mx-2' href= {`${WebsiteUrl}projects/${projectId}/overview`} target='_blank'>{`${WebsiteUrl}projects/${projectId}/overview`}</a>
                </div>
                  </div>
              </div>
            </div> }
            {/* projectId &&
            <> <div className="row">
                <div className="form-group">
                  <a className='mx-2' href= {WebsiteUrl+'dashboard/jobs/:'+projectId+'/'+projectSlug} target='blank'>&#8599; Go to the Brief</a>
                </div>
            </div>
             <div className="row">
                <div className="form-group">
                 {console.log(projectId)}
                  <a className='mx-2' href= {WebsiteUrl+'talentconnect/'+projectId} target='blank'>&#8599; Talent Connect</a>
                </div>
            </div>
            </> */}
            <hr className="my-5" />
            {/*--------------------------reason ---------------------------*/}
            {reasonType && (
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Call Back type</label>
                    <input
                      type="text"
                      className="form-control"
                      name="reasonType"
                      value={reasonType}
                      onChange={this.handleOnChange}
                      disabled="disabled"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Explain</label>
                    <input
                      type="text"
                      className="form-control"
                      name="reasonExplain"
                      value={reasonExplain}
                      onChange={this.handleOnChange}
                      disabled="disabled"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              {Array.isArray(ReasonIs) && <label className="col-12 ">Reason</label>}
              {Array.isArray(ReasonIs)
                ? ReasonIs.map((obj, index) => (
                    <div className="col-4 mb-2 p-2" key={index}>
                      <div className="questin-Listing">
                        <h5 className="mb-0">{obj.option}</h5>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
            {reasonType && <hr className="my-5" />}
            {/*--------------------------reason ---------------------------*/}

            {/*--------------------------review---------------------------*/}
            {this.state.isComplete && this.state.isManager && this.state.projectManagerReview.length === 0 ? (
              <ModalReview
                clientName={this.state.clientName}
                creatorName={this.state.creatorName}
                projectId={this.state.projectId}
              />
            ) : (
              <></>
            )}
            {ReviewIs.length > 0 && (
              <div className="row">
                <label className="col-12">
                  {transactionsStatus === 7
                    ? "Feedback"
                    : transactionsStatus === 8
                    ? "Close Reason"
                    : transactionsStatus === 5 || transactionsStatus === 3
                    ? "Request to close brief"
                    : transactionsStatus === 6
                    ? "Request to close Project"
                    : ""}
                </label>

                {ReviewIs.map((obj, index) => (
                  <div className="col-6" key={index}>
                    <div className="card card-new">
                      <div className="card-body">
                        <div className="mb-3">
                          <div className="row align-items-center">
                            <div className="col ml-n2">
                              <h4 className="card-title mb-1">{obj.type}</h4>
                              <p className="card-text small text-muted mb-0">
                                <span className="fe fe-user"></span>
                                <time>{obj.user_type}</time>
                              </p>
                              {/*-----------------rating -----------------------*/}
                              {obj.rating > 0 && (
                                <StarRatings
                                  rating={obj.rating}
                                  starRatedColor="orange"
                                  changeRating={this.changeRating}
                                  numberOfStars={5}
                                  name="rating"
                                  starDimension="15px"
                                  starSpacing="2px"
                                />
                              )}
                              {/*-----------------rating -----------------------*/}
                            </div>
                          </div>
                        </div>
                        {obj.description && (
                          <pre className="mb-3">
                            Description : <br /> {obj.description}
                          </pre>
                        )}
                        <ol className="mb-3">
                          {obj.feedback && obj.feedback.map((object, index) => <li key={index}>{object.text}</li>)}
                        </ol>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {this.state.projectManagerReview.length > 0 && (
              <div className="row">
                <div className="col-12">
                  <h4 class="card-title mb-3"> {transactionsStatus === 7 ? `Manager Review - ${this.state.managerName}` : ""}</h4>
                </div>
                {this.state.projectManagerReview.map((obj, index) => (
                  <div className="col-6" key={index}>
                    <div className="card card-new">
                      <div className="card-body">
                        <div className="mb-3">
                          <div className="row align-items-center">
                            <div className="col ml-n2">
                              <p className="card-text small text-muted mb-0">
                                <span className="fe fe-user"></span>
                                <time>{obj.user_type}</time>
                              </p>
                              {/*-----------------rating -----------------------*/}
                              {obj.rating > 0 && (
                                <StarRatings
                                  rating={obj.rating}
                                  starRatedColor="orange"
                                  changeRating={this.changeRating}
                                  numberOfStars={5}
                                  name="rating"
                                  starDimension="15px"
                                  starSpacing="2px"
                                />
                              )}
                              {/*-----------------rating -----------------------*/}
                            </div>
                          </div>
                        </div>

                        {obj.feedback.map((m) => {
                          return (
                            <div>
                              <p className="mb-2">Q: {m.qus}</p>
                              <p className="mb-4">A: {m.ans}</p>
                            </div>
                          );
                        })}
                        {obj.description && (
                          <pre className="mb-3">
                            Description:
                            <br /> {obj.description}
                          </pre>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {ReviewIs.length > 0 &&
              (transactionsStatus === 7 || transactionsStatus === 8 || transactionsStatus === 5 || transactionsStatus === 6) && (
                <hr className="my-5" />
              )}
            {/*--------------------------review---------------------------*/}

            {/*--------------------review Questions -------------------------*/}

            {transactionsStatus === 7 && (
              <AskQuestion {...this.state} {...this.props} managerReview={this.state.projectManagerReview} />
            )}
            {transactionsStatus === 7 && <hr className="my-5" />}

            {/*--------------------review Questions -------------------------*/}

            {/*-------------------type Question ---------------------------*/}
            {typeQuestion.length ? (
              <span>
                <label className="mb-4">Type Question</label>
                <PostProjectTypeQuestionList {...this.props} {...this.state} handlefunc={transactionFunc} />
                <hr className="my-5" />
              </span>
            ) : (
              ""
            )}

            {/*-------------------style Question ---------------------------*/}
            {styleQuestion.length ? (
              <span>
                <label className="mb-4">Style Question</label>
                <ProjectStyleQuestion {...this.state} {...this.props} handlefunc={transactionFunc} />
                <hr className="my-5" />
              </span>
            ) : (
              ""
            )}

            {/* -------------------------calendary detaols ----------------------------- */}

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label>Meeting User Name</label>
                  <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: "#e9ecef" }}
                    name="mon"
                    value={meeting_user_name}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Meeting User Email</label>
                  <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: "#e9ecef" }}
                    name="mon"
                    value={meeting_user_email}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Meeting Schedule Date</label>
                  <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: "#e9ecef" }}
                    name="mon"
                    value={meeting_schedule_date}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Meeting End Time</label>
                  <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: "#e9ecef" }}
                    name="mon"
                    value={meeting_end_time}
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            {/*-------------------------------Company static Question------------------ */}
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>Company Work Type</label>
                  <Select value={companyWorkType} onChange={this.handleWorkChange} options={optionsWork} />
                </div>
              </div>
              {companyDataArr.map((obj, index) => (
                <React.Fragment key={index}>
                  {obj?.show && (
                    <div className="col-12 col-md-6">
                      {obj.line === "hr" && <hr />}
                      <div className="form-group">
                        <label>{obj.label}</label>
                        <input
                          type="text"
                          className={`form-control ${obj.disabledIs ? "disable-input" : ""} ${
                            (error.companyJobrole && obj.name === "companyJobrole") ||
                            (error.companyAddLink && obj.name === "companyAddLink")
                              ? "error-show"
                              : ""
                          }`}
                          name={obj.name}
                          value={obj.value}
                          onChange={this.handleOnChange}
                          disabled={obj.disabledIs}
                        />
                        {((error.companyJobrole && obj.name === "companyJobrole") ||
                          (error.companyAddLink && obj.name === "companyAddLink")) && (
                          <span className="error">{error.companyJobrole || error.companyAddLink}</span>
                        )}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
              {(companyWorkType?.label === "company" || companyWorkType?.label === "agency") && ( 
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Company name</label>
                    <CreatableSelect
                      value={companyName}
                      isClearable
                      onChange={this.handleChange}
                      onInputChange={this.handleInputChange}
                      options={CompanyOptions}
                      formatCreateLabel={(el) => {
                        return el;
                      }}
                      className={this.state.compErrMsg ? "error-show" : ""}
                    />
                    {this.state.compErrMsg && <span className="error">{this.state.compErrMsg}</span>}
                  </div>
                </div>
              )}
              {(companyWorkType?.label === "company" || companyWorkType?.label === "agency") && (
                <React.Fragment>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>Company Location</label>
                      <Select
                        value={companyLocation}
                        onChange={this.handleLocationChange}
                        onInputChange={this.handleInputLocation}
                        options={optionsLocation}
                        className={this.state.compLocMsg ? "error-show" : ""}
                      />
                      {this.state.compLocMsg && <span className="error">{this.state.compLocMsg}</span>}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>Company Industry</label>
                      <Select
                        value={companyIndustry}
                        onInputChange={this.handleInputIndustry}
                        onChange={this.handleIndustryChange}
                        options={optionsIndustryIs}
                        className={this.state.compIndMsg ? "error-show" : ""}
                      />
                      {this.state.compIndMsg && <span className="error">{this.state.compIndMsg}</span>}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>Company Employee</label>
                      <Select
                        value={companyEmp}
                        onChange={this.handleEmpChange}
                        options={empOptions}
                        className={this.state.compEmpMsg ? "error-show" : ""}
                      />
                      {this.state.compEmpMsg && <span className="error">{this.state.compEmpMsg}</span>}
                    </div>
                  </div>
                 
                </React.Fragment>
              )}

              {(companyWorkType?.label === "company" || companyWorkType?.label === "agency") && (
                 <div className="col-12 col-md-6">
                 <label>Company Logo</label>
               <div className="row justify-content-between align-items-center">
               <div className="col">
                 <div className="row align-items-center">
                   <div className="col-auto">
                     <div className="avatar">
                      {
                        companyLogo ? <a href={userProfileBaseUrl+companyLogo} target='blank'> 
                        <img
                         className="avatar-img rounded-circle"
                         src={
                              companyLogo?
                              userProfileBaseUrl+companyLogo:
                              require("../../../Assets/img/avatars/profiles/images.jpeg")
                            }
                         alt="companyLogo"
                       />
                       </a>
                       :
                       <img
                         className="avatar-img rounded-circle"
                         src={
                              companyLogo?
                              userProfileBaseUrl+companyLogo:
                              require("../../../Assets/img/avatars/profiles/images.jpeg")
                            }
                         alt="companyLogo"
                       />
                      }
                      
                     </div>
                   </div>
                   <div className="col ml-n2">
                     {/* <h4 className="mb-1">Company Logo</h4> */}
                     <small className="text-muted">PNG, JPG, GIF or JPEG only.</small>
                   </div>
                 </div>
               </div>
               <div className="col-auto">
                 <input
                   type="file"
                   ref={(ref) => (this.uploadLogo = ref)}
                   accept='image/png, image/jpeg, image/jpg, image/gif'
                   style={{ display: "none" }}
                   onChange={this.handleUploadCompanyLogo}
                 />
                 {(this.state.companyLogo || this.state.wrongLogoFormat) && 
                <i
                className="fe fe-trash mx-3 "
                style={{ fontSize: "15px", cursor: "pointer" }}
                 onClick={()=>this.setState({companyLogo:"",wrongLogoFormat:false})}
                 />
                 
                 }
                 <button
                   className="btn btn-sm btn-primary"
                   onClick={() => this.uploadLogo.click()}
                 >
                   {companyLogoLoader ? "Uploading....." : "Upload"}
                 </button>
               </div>
               {this.state.wrongLogoFormat && <div className='text-danger'>Invalid Format</div>}
             </div>
                 </div>
              )}

                
              {companyWorkType?.label === "individual" && (
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Company Individual Location</label>
                    <Select
                      value={companyIndiviualLocation}
                      onChange={this.handleIndiLocationChange}
                      onInputChange={this.handleInputLocation}
                      options={optionsLocation}
                      className={this.state.compIndLocMsg ? "error-show" : ""}
                    />
                    {this.state.compIndLocMsg && <span className="error">{this.state.compIndLocMsg}</span>}
                  </div>
                </div>
              )}
            </div>
            {(companyWorkType?.label === "company" || companyWorkType?.label === "agency") && (
              <div className="row">
                <div className="col-12 col-12">
                  <div className="form-group">
                    <label>Company Describes</label>
                    <textarea
                      type="text"
                      className={`form-control ${error.companyDescribes ? "error-show" : ""}`}
                      name="companyDescribes"
                      value={companyDescribes}
                      onChange={this.handleOnChange}
                    ></textarea>
                    <span className="error">{error.companyDescribes}</span>
                  </div>
                </div>
              </div>
            )}

            <hr className="my-5" />

            {/*---------------------------Company Question --------------------------*/}
            {companyQuestion.length ? (
              <span>
                <label className="mb-4">Company Detail Question</label>
                <PostProjectCompanyQuestionList {...this.props} {...this.state} handlefunc={transactionFunc} />
                <hr className="my-5" />
              </span>
            ) : (
              ""
            )}

            {/*---------------------------Project Question --------------------------*/}
            {projectDetailQuestion.length ? (
              <span>
                <label className="mb-4">Project Detail Question</label>
                <PostProjectQuestion {...this.props} {...this.state} handlefunc={transactionFunc} />
                <hr />
              </span>
            ) : (
              ""
            )}

            {/*---------------------project refrence link-----------------------------*/}

            {projectallData.project_add_reference_files_heading && (
              <>
                <div className="tag">
                  <h4 className="tag-title mb-2">
                    {/* {console.log(projectallData.project_add_reference_link_heading)} */}
                    {projectallData.project_add_reference_link_heading}
                  </h4>
                  <small className="text-muted mb-2">{projectallData.project_add_reference_link_subtext}</small>
                  <br />
                  {Array.isArray(refLinkArr) && refLinkArr?.map((obj, index) => this.getReferenceLink(obj, index))}
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="form-group" onKeyUp={this.handleRefLink}>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Press Enter After Link"
                        name="refrenceLink"
                        value={refrenceLink}
                        onChange={this.handleOnChange}
                      />
                    </div>
                  </div>
                </div>
                <hr className="my-5" />
              </>
            )}

            {/*---------------------project refrence link-----------------------------*/}

            {/*---------------------project refrence files-----------------------------*/}

            {projectallData.project_add_reference_files_heading && (
              <>
                <div className="tag">
                  <h4 className="tag-title mb-2">{projectallData.project_add_reference_files_heading}</h4>
                  <small className="text-muted ">{projectallData.project_add_reference_files_subtext}</small>
                  <br />
                  <div className="d-flex flex-direction-row flex-wrap">
                    {Array.isArray(refFilesArr) &&
                      refFilesArr?.map((obj, index) => (
                        <span className="mt-2 mr-4 text-center" key={index}>
                          {/* <img
                          src={
                            obj?.name
                              ? obj?.name?.includes(".pdf") ||
                                obj?.name?.includes(".zip") ||
                                obj?.name?.includes(".ppt") ||
                                obj?.name?.includes(".exe") ||
                                obj?.name?.includes(".docx") ||
                                obj?.name?.includes(".mp4") ||
                                obj?.name?.includes(".xlsx") ||
                                obj?.name?.includes(".csv") ||
                                obj?.name?.includes(".wmv")
                                ? obj?.name?.includes(".pdf")
                                  ? PdfImage
                                  : obj?.name?.includes(".zip")
                                  ? ZipImage
                                  : obj?.name?.includes(".ppt")
                                  ? PPtImage
                                  : obj?.name?.includes(".exe")
                                  ? Exe
                                  : obj?.name?.includes(".docx")
                                  ? Docx
                                  : obj?.name?.includes(".mp4")
                                  ? Mp4
                                  : obj?.name?.includes(".xlsx")
                                  ? Xlsx
                                  : obj?.name?.includes(".csv")
                                  ? Csv
                                  : obj?.name?.includes(".wmv")
                                  ? Wmv
                                  : require("../../../Assets/img/avatars/profiles/images.jpeg")
                                : profileBaseUrl + obj.name
                              : require("../../../Assets/img/avatars/profiles/images.jpeg")
                          }
                          height="100"
                          width="100"
                          style={{ borderRadius: "5px" }}
                          alt="..."
                        /> */}
                          <a href={profileBaseUrl + obj?.name} target="blank" key={index} style={{ display: "contents" }}>
                            <span
                              className="upload_image_thumbnail mb-3"
                              style={{
                                height: "5rem",
                                width: "5rem",
                                backgroundImage: `url("${
                                  obj?.name
                                    ? obj?.name?.includes(".pdf") ||
                                      obj?.name?.includes(".zip") ||
                                      obj?.name?.includes(".ppt") ||
                                      obj?.name?.includes(".exe") ||
                                      obj?.name?.includes(".docx") ||
                                      obj?.name?.includes(".mp4") ||
                                      obj?.name?.includes(".xlsx") ||
                                      obj?.name?.includes(".csv") ||
                                      obj?.name?.includes(".wmv") ||
                                      obj?.name?.includes(".doc")
                                      ? obj?.name?.includes(".pdf")
                                        ? PdfImage
                                        : obj?.name?.includes(".zip")
                                        ? ZipImage
                                        : obj?.name?.includes(".ppt")
                                        ? PPtImage
                                        : obj?.name?.includes(".exe")
                                        ? Exe
                                        : obj?.name?.includes(".docx")
                                        ? Docx
                                        : obj?.name?.includes(".mp4")
                                        ? Mp4
                                        : obj?.name?.includes(".xlsx")
                                        ? Xlsx
                                        : obj?.name?.includes(".csv")
                                        ? Csv
                                        : obj?.name?.includes(".wmv")
                                        ? Wmv
                                        : obj?.name?.includes(".doc")
                                        ? Doc
                                        : require("../../../Assets/img/avatars/profiles/images.jpeg")
                                      : profileBaseUrl + obj.name
                                    : require("../../../Assets/img/avatars/profiles/images.jpeg")
                                }")`,
                              }}
                            />
                          </a>
                          <i
                            className="fe fe-trash ml-3 "
                            style={{ fontSize: "12px", cursor: "pointer" }}
                            onClick={() => this.handleDeleteFiles(index)}
                          />
                        </span>
                      ))}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="file"
                        onChange={this.handleUploadFiles}
                        ref={(ref) => (this.upload = ref)}
                        accept="jpg,jpeg.png"
                        style={{ display: "none" }}
                        multiple={true}
                      />
                    </div>
                    <button className="btn btn-sm btn-primary" onClick={() => this.upload.click()}>
                      {imageLoader ? "Uploading Files....." : "Upload Files"}
                    </button>
                  </div>
                </div>
                <hr className="my-5" />
              </>
            )}

            {/*---------------------project refrence files-----------------------------*/}

            {/*---------------------------Status --------------------------*/}

            <div className="card-body">
              <div className="row align-items-center">
                <div className="col">
                  <h5>Brief Status</h5>
                </div>
                {statusLoader ? (
                  <div className="col-auto">
                    <LoaderSmall />
                  </div>
                ) : (
                  transactionsStatus === 3 && (
                    <div className="col-auto">
                      <div
                        className={`badge mr-2 ${transactionsStatus === 3 ? `badge-soft-info` : "badge-soft-secondary"}`}
                        style={{ cursor: "pointer" }}
                        onClick={transactionsStatus === 3 ? () => this.handleTransactonStatus("accept", projectId) : undefined}
                      >
                        Accept
                      </div>
                      <div
                        className={`badge mr-2 ${transactionsStatus === 3 ? `badge-soft-info` : `badge-soft-secondary`}`}
                        style={{ cursor: "pointer" }}
                        onClick={transactionsStatus === 3 ? () => this.handleTransactonStatus("reject", projectId) : undefined}
                      >
                        Reject
                      </div>
                    </div>
                  )
                )}
                {transactionsStatus !== 3 && (
                  <div className="col-auto">{transactionsStatus && this.getProjectstatus(transactionsStatus)}</div>
                )}
              </div>
            </div>

            <hr />
            {(transactionsStatus === 11 || transactionsStatus === 1 || transactionsStatus === 2 || transactionsStatus === 12 || transactionsStatus === 3 || transactionsStatus === 5 || transactionsStatus === 6) && (
              <div className="col-12 col-md-12 text-right">
                <button className="btn btn-primary" onClick={this.handleUpdate}>
                  {updateLoader ? <LoaderSmall /> : "Update"}
                </button>
              </div>
            )}
          </section>
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({ transactionGetData, transactionStatus, updateTransactions }) => {
  return {
    _transactionGetData: transactionGetData,
    _transactionStatus: transactionStatus,
    _updateTransactions: updateTransactions,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $transactionGetData: (values) => dispatchEvent(transactionGetData(values)),
  $transactionStatus: (values) => dispatchEvent(transactionStatus(values)),
  $updateTransactions: (values) => dispatchEvent(updateTransactions(values)),
});
export default connect(mapState, mapDispatch)(TransactionsDetailsForm);

// <span className="mt-2 mr-2" key={index}>
//   <img
//     src={obj.name ? profileBaseUrl + obj.name : require("../../../Assets/img/avatars/profiles/images.jpeg")}
//     height="100"
//     width="100"
//     style={{ borderRadius: "5px" }}
//     alt="..."
//   />
//   <i
//     className="fe fe-trash ml-3"
//     style={{ fontSize: "12px" }}
//     onClick={() => this.handleDeleteFiles(index)}
//   ></i>
// </span>;
