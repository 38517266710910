import { call, put } from "redux-saga/effects";
import { getPublicAPI, postImage, putAPI } from "../api-interface";

import axios from "../api-interface";
import {
  collateralListSuccess,
  collateralListError,
  collateralUploadPhotoSuccess,
  collateralPCFNameSuccess,
  getCollateralSuccess,
  statusCollateralSuccess,
  collateralApprovedProjectSuccess,
  collateralPendingProjectSuccess,
  collateralPendingCreatorSuccess,
  collateralApprovedCreatorSuccess,
  collateralChangeHistorySuccess,
  collateralActiveTransactionSuccess,
  collateralActiveNegociationSuccess,
  getCollateralError,
  pendingCollateralSuccess,
  pendingCollateralListSuccess,
  hideCollateralSuccess,
  deleteCollateralListSuccess,
  pendingCollaterlListError,
  deleteCollateralListerror,
  approvedCreatorListSuccess,
  approvedStarredListSuccess,
  featuredCollateralSuccess,
} from "../action/collateralList.action";

// collateral Listing and pagination*********
export function* oncollateralList(data) {
  let values = data.values;
  try {
    if (values) {
      let pageIs = values.page;
      let name = values.name;
      const data = yield call(
        getPublicAPI,
        `/collateral/get-collateral-list?page=${pageIs}&limit=10&name=${name}`
      );
      if (data.status === 200) {
        yield put(collateralListSuccess(data.data));
      }
    }
  } catch (error) {
    let errIs = error.response;
    if (error.response.status === 401) {
      yield put(collateralListError(errIs.status));
    } else if (errIs.data.message) {
      alert(`${errIs.data.message}`);
    }
  }
}

// collateral upload photo *******

export function* onCollateralUploadPic(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(postImage, "/collateral/upload-images", values);
      if (data.status === 200) {
        yield put(collateralUploadPhotoSuccess(data));
      }
    }
  } catch (error) {
    //alert(`Error: ${error.response.status}`);
  }
}

// collateral pcf name

export function* onCollateralPcf(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield axios.get("/pcf?name=" + values);
      if (data.status === 200) {
        yield put(collateralPCFNameSuccess(data.data));
      }
    }
  } catch (error) {
    //alert(`Error: ${error.response.status}`);
  }
}

// get collateral based on id
export function* onGetCollateral(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield axios.get("/collateral/get-collateral?_id=" + values);
      if (data.status === 200) {
        yield put(getCollateralSuccess(data.data));
      }
    }
  } catch (error) {
    // console.log("collateral saga", error.response.status);
    if (error.response.status === 401) {
      yield put(getCollateralError(error.response.status));
    }
  }
}

// accept/reject collateral
export function* onStatusCollateral(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(putAPI, "/collateral/change-status", values);
      if (data.status === 200) {
        yield put(statusCollateralSuccess(data.data));
      }
    }
  } catch (error) {
    // alert(`Error: ${error.response.status}`);
  }
}

// collateral approved Project
export function* onCollateralApprovedProject(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/artist-user-list?page=${values}&limit=10`
      );
      if (data.status === 200) {
        yield put(collateralApprovedProjectSuccess(data.data));
      }
    }
  } catch (error) {
    //alert(`Error: ${error.response.status}`);
  }
}

// collateral Pending Project
export function* onCollateralPendingProject(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(putAPI, "/collateral/change-status", values);
      if (data.status === 200) {
        yield put(collateralPendingProjectSuccess(data.data));
      }
    }
  } catch (error) {
    //alert(`Error: ${error.response.status}`);
  }
}

//collateral Pending creator
export function* onPendingCreator(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/artist-user-list?page=${values}&limit=10`
      );
      if (data.status === 200) {
        yield put(collateralPendingCreatorSuccess(data.data));
      }
    }
  } catch (error) {
    //alert(`Error: ${error.response.status}`);
  }
}

// collateral Approved creator
export function* onApprovedCreator(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/artist-user-list?page=${values}&limit=5`
      );
      if (data.status === 200) {
        yield put(collateralApprovedCreatorSuccess(data.data));
      }
    }
  } catch (error) {
    // alert(`Error : ${error.response.status}`);
  }
}

// collateral change History
export function* onCollateralHistory(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/artist-user-list?page=${values}&limit=5`
      );
      if (data.status === 200) {
        yield put(collateralChangeHistorySuccess(data.data));
      }
    }
  } catch (error) {
    // alert(`Error : ${error.response.status}`);
  }
}

// collateral active Transaction

export function* onCollateralActiveTransactions(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/artist-user-list?page=${values}&limit=5`
      );
      if (data.status === 200) {
        yield put(collateralActiveTransactionSuccess(data.data));
      }
    }
  } catch (error) {
    // alert(`Error : ${error.response.status}`);
  }
}

// collateral active negociation

export function* onCollateralActiveNegociation(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/artist-user-list?page=${values}&limit=5`
      );
      if (data.status === 200) {
        yield put(collateralActiveNegociationSuccess(data.data));
      }
    }
  } catch (error) {
    alert(`Error : ${error.response.status}`);
  }
}

// pending collateral (approvals tab)

export function* onPendingCollateral(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/pending-user-collateral?page=${values.page}&limit=10&sort=${values.sortData}`
      );
      if (data.status === 200) {
        yield put(pendingCollateralSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(pendingCollaterlListError(error.response.status));
    }
  }
}

// get pending collateral List (approvals tab)

export function* onPendingCollateralList(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/pending-user-collateral-data?_id=${values}`
      );
      if (data.status === 200) {
        yield put(pendingCollateralListSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(pendingCollaterlListError(error.response.status));
    // }
  }
}

// hide Collateral from client (Collateral)
export function* onHideCollateral(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(putAPI, `/collateral/visible-status`, values);
      if (data.status === 200) {
        yield put(hideCollateralSuccess(data.data));
      }
    }
  } catch (error) {
    // alert(`Error : ${error.response.status}`);
  }
}

// delete collateral list (approval tab)

export function* ondeleteCollateralList(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/delete-user-collateral-list?page=${values.page}&limit=10&sort=${values.sortIs}`
      );
      if (data.status === 200) {
        yield put(deleteCollateralListSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(deleteCollateralListerror(error.response.status));
    }
  }
}

// approved creator List(Collateral (creator))
export function* onApprovedCreatorList(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/collateral/approved-user?page=${values.pageIs}&limit=${values.Limit}&sort=${values.sortIs}&_id=${values.id}`
      );
      if (data.status === 200) {
        yield put(approvedCreatorListSuccess(data.data));
      }
    }
  } catch (error) {
    // if(error.response.status === 401){
    //   yield put(deleteCollateralListerror(error.response.status));
    // }
  }
}

// approved Starred List(Collateral (Starred))
export function* onApprovedStarredList(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/collateral/get-star-and-check-user?page=${values.pageIs}&limit=${values.Limit}&sort=${values.sortIs}&_id=${values.id}`
      );
      if (data.status === 200) {
        yield put(approvedStarredListSuccess(data.data));
      }
    }
  } catch (error) {
    // if(error.response.status === 401){
    //   yield put(deleteCollateralListerror(error.response.status));
    // }
  }
}

// featured Collateral
export function* onfeaturedCollateral(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(putAPI, `/collateral/features`, values);
      if (data.status === 200) {
        yield put(featuredCollateralSuccess(data.data));
      }
    }
  } catch (error) {
    // if(error.response.status === 401){
    //   yield put(deleteCollateralListerror(error.response.status));
    // }
  }
}
