import { put, call } from "redux-saga/effects";
import { putAPI,getPublicAPI } from '../api-interface'

import axios from "../api-interface";
import { loginDataSuccess, loginDataError, resetPwdSuccess, resetPwdError, getAdminUserDataSuccess, getAdminUserDataError } from "../action/loginData.action";

export function* onloginData(data) {
  let values = data.values;
  try {
      if(values){
        const data = yield axios.post("/backendUser/login", values);              
        if(data.status === 200){
            yield put(loginDataSuccess(data.data));
        }
      }
  } catch (error) {
     yield put(loginDataError(error.response))        
  }
}

// reset Password **********
export function* onResetPwd(data) {
  let values = data.values;
  try {
      if(values){
        const data = yield call(putAPI, "/backendUser/change-password", values);               
        if(data.status === 200){
            yield put(resetPwdSuccess(data.data));
        }
      }
  } catch (error) {
     yield put(resetPwdError(error.response.data))         
  }
}

/////////////////  Get Admin User Data  ********
export function* getAdminData(){
 
  try{
      const data = yield call(getPublicAPI,`/backendUser/get-admin-user-data`);
      if(data.status===200){
        yield put(getAdminUserDataSuccess(data?.data));      
    }
  } catch (error){
    yield put(getAdminUserDataError(error.response.data,"this is saga"))
  }
}