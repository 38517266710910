import React, { Component } from "react";
import { profileBaseUrl } from "../../../../Utils/BaseUrl";

export default class AllQuestionForm extends Component {
  render() {
    const {
      questionIs,
      subtext,
      Qstype,
      optionIs,
      handlefunc,
      answerIs,
    } = this.props;

    const contentIs = [
      { name: "type", value: Qstype, label: "Type" },
      { name: "questionIs", value: questionIs, label: "Question" },
      { name: "subtext", value: subtext, label: "Subtext" },
    ];
    return (
      <React.Fragment>
        <div
          className="questin-Listing"
          style={{ borderTop: "none", borderRadius: "0 0 5px 5px" }}
        >
          {/* <form> */}
          <div className="row">
            {contentIs.map((obj, index) => (
              <div className="col-12 col-md-6" key={index}>
                <div className="form-group">
                  <label>{obj.label}</label>
                  <input
                    type="text"
                    className="form-control"
                    name={obj.name}
                    value={obj.value}
                    onChange={this.handleOnChange}
                    disabled="disabled"
                  />
                </div>
              </div>
            ))}
            {optionIs && <label className="col-12">Options</label>}
            {optionIs && optionIs.length
              ? optionIs.map((val, index) => (
                  <div className="col-4 mb-3" key={index}>
                    <div className="questin-Listing">
                      <div className="row">
                        {val.item && (
                          <div className="col-10">
                            <p className="mb-0">{val.item}</p>
                          </div>
                        )}
                        {(val.start_range || val.end_range) && (
                          <div className="col-10">
                            <p className="mb-0">
                              {/* {console.log("::::option--->", val)} */}
                              {val.start_range}-{val.end_range}
                            </p>
                          </div>
                        )}
                        {val.image && (
                          <div className="col-2">
                            <img
                              src={`${profileBaseUrl + val.image}`}
                              alt="..."
                              height="30"
                              width="30"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              : ""}
            {answerIs.length && <label className="col-12">Answer</label>}
            {answerIs.length &&
              answerIs.map((obj, index) => (
                <div className="col-4 mb-3" key={index}>
                  <div className="questin-Listing">
                    <div className="row">
                      {(obj.start_range || obj.end_range) && (
                        <div className="col-10">
                          <p className="mb-0">
                            {obj.start_range}-{obj.end_range}
                          </p>
                        </div>
                      )}
                      {Qstype !== "file_upload" && (
                        <div className="col-10">
                          <p className="mb-0">{obj.item}</p>
                        </div>
                      )}

                      {(Qstype === "checkbox_with_images_with_text" ||
                        Qstype === "radio_button_with_images_with_text") && (
                        <div className="col-2">
                          <img
                            src={`${profileBaseUrl + obj.image}`}
                            alt="..."
                            height="30"
                            width="30"
                          />
                        </div>
                      )}

                      {Qstype === "file_upload" && (
                        <div className="col-2">
                          <img
                            src={`${profileBaseUrl + obj.item}`}
                            alt="..."
                            height="30"
                            width="30"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {/* </form> */}
          <div className="d-flex justify-content-end mt-2 mb-2">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handlefunc.handleCancelForm}
            >
              Cancel
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
