import React,{Component} from "react";
import { connect } from "react-redux";
import { paymentOffline, transactionList, activeTransactions, inactiveBrief,inactiveProject,inActBriefForAm } from "../../../../store/action/transaction.action";

 class OfflinePayment extends Component{

    state={
        paymentIs : false
    }



    handleGetConfirmation = (currentStatus, id) => {
        let confirmIs = window.confirm(
            `Do you want to countinue ?`
        );
        if (confirmIs === true) {
            this.setState({ paymentIs: true });
            const statusData = { status: currentStatus, _id: id };
            this.props.$paymentOffline(statusData);
            return true;
        } else {
            return false;
        }
    };
    
    handlePayment = (status, id) => {
        var currentStatus = null;
        if (status === 1) {
            currentStatus = 0;
        } else {
            currentStatus = 1;
        }
        this.handleGetConfirmation(currentStatus, id);
    };

    componentWillReceiveProps(nextProps){
        const paymentData = nextProps._paymentOffline.paymentIs;
        const{paymentIs} = this.state;
        if(paymentData && paymentIs){
            this.setState({paymentIs : false})
            const transactionData = {
                List: 1,
                project: "",
              };
            if (this.props?.actionMil) {
                this.props.handleCall()
            } else {
                const {type} = this.props;

                if(this.props.tabname){this.props.parentState(this.props.tabname,true)}

                switch(type){
                    case 'active_brief':
                        this.props.$transactionList(transactionData);
                        break;
                    case 'active_brief_am':
                        this.props.$transactionList();
                        break;
                    case 'active_project':
                       this.props.$activeTransactions(transactionData)
                        break;
                    case 'active_project_am':
                       this.props.$activeTransactions()
                        break;
                    case 'inactive_brief':
                        this.props.$inactiveBrief(transactionData)
                        break;
                    case 'inactive_brief_am':
                        this.props.$inActBriefForAm()
                        break;
                    case 'inactive_project':
                        this.props.$inactiveProject(transactionData)
                        break;
                    case 'inactive_project_am':
                        this.props.$inactiveProject()
                        break;
                    default:
                        alert('something went wrong');
                        break;
                }
            }
        }
    }
    
    getPaymentOffline = (obj) => {
        // return <td className="text-center"><span>
        //     <div 
        //         className="badge badge-soft-secondary mr-2"
        //         style={{ cursor: "pointer" }}
        //         onClick={() => this.handlePayment(obj.offline_payment, obj._id)}
        //     >
        //         {obj.offline_payment === 0 ? "yes" : "no"}
        //     </div>
        //     <div className="badge badge-soft-primary mr-2 disable">
        //         {obj.offline_payment === 1 ? "yes" : "no"}
        //     </div>
        // </span>
        // </td>

     return   <div className="dropdown" style={{cursor:`${this.props.disabled?'default':'pointer'}`}}>
        <button className="btn btn-secondary btn-sm dropdown-toggle" style={{cursor:`${this.props.disabled?'default':'pointer'}`}} disabled={this.props.disabled} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {obj.offline_payment?'BANK TRANSFER':'RAZORPAY'}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{maxHeight: 300, overflowY: 'auto'}}>
        
              <span onClick={()=> this.handlePayment(obj.offline_payment, obj._id)} className="dropdown-item" >{obj.offline_payment?'RAZORPAY':'BANK TRANSFER'}</span>              
        </div>
      </div>
    // return     <td><select name="bank" id="bank" value= {obj.offline_payment} onChange={() => this.handlePayment(obj.offline_payment, obj._id)}>
    //     <option value="1">RAZORPAY</option>
    //     <option value='0' >BANK TRANSFER </option></select></td>
    }   

    render(){
        const{obj} = this.props;
        return(
            <React.Fragment>
                {this.getPaymentOffline(obj)}
            </React.Fragment>
        )
    }
}
const mapState = ({ paymentOffline,transactionList }) => {
    return {
      _paymentOffline: paymentOffline,
    };
  };
  const mapDispatch = (dispatchEvent) => ({
    $paymentOffline: (values) => dispatchEvent(paymentOffline(values)),
    $transactionList: (values) => dispatchEvent(transactionList(values)),
    $activeTransactions:(values)=>dispatchEvent(activeTransactions(values)),
    $inactiveBrief : (values) => dispatchEvent(inactiveBrief(values)),
    $inactiveProject : (values) =>dispatchEvent(inactiveProject(values)),
    $inActBriefForAm : (values) => dispatchEvent(inActBriefForAm(values)),
  });
  export default connect(mapState, mapDispatch)(OfflinePayment);