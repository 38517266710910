import React, { Component } from "react";
import Modal from "react-modal";
import { getPublicAPI } from "../../../store/api-interface";
import Loader from "../Common/Loader/Loader";
import Moment from "moment"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    maxWidth: "700px",
    width: "700px",
  },
};

class chatModal extends Component {
  constructor(props) {
    super(props);
      this.state = {
      chatData: [],
      clientName: "",
      creatorName: "",
      c_id: "",
      creator_id: "",
      manager_modal_id:'',
      load:false
  };
    this.textInput = React.createRef();
  }

    
  handleChatApi = (client_modal_id, creator_modal_id, post_project_modal_id) => {
    getPublicAPI(`/chat/get-chat-message-admin?client_id=${client_modal_id}&creator_id=${creator_modal_id}&post_project_id=${post_project_modal_id}`).then(
      (res) => {
        let data = res?.data?.user_chat
        // console.log("data = = ", data)
        this.setState({ chatData: data, load: false }, () => {
          this.scrollToBottom() 
        })
      }
    ).catch(err => {
      console.log(err)
    })
  }

  scrollToBottom = () => {
    if (this.textInput.current) {
       this.textInput.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  componentWillReceiveProps = (props) => {
    const { client_modal_id, creator_modal_id, post_project_modal_id,manager_modal_id } = props;
    // console.log("123 = = ",client_modal_id, creator_modal_id, post_project_modal_id)
    if (client_modal_id && creator_modal_id && post_project_modal_id) {
      this.setState({load:true,c_id:client_modal_id?._id,creator_id:creator_modal_id?._id, clientName: client_modal_id?.first_name + " " + client_modal_id?.last_name, creatorName: creator_modal_id?.first_name + " " + creator_modal_id?.last_name,manager_modal_id:manager_modal_id }, () => { 
        this.handleChatApi(this.state.c_id, this.state.creator_id, post_project_modal_id)
      })
    }
  }

  chatList = (obj,i) => {
    const { clientName, creatorName, c_id, creator_id, manager_modal_id } = this.state;
    // console.log("100 == ", obj)
    let result = {name:"",tag:""}
    if (obj?.user_type === "user") {
      if (obj?.sender_id === c_id) {
        result = { name: clientName, tag: "(client)" }
      } else if(obj?.sender_id === creator_id){
        result = { name: creatorName, tag: "(creator)" }
      }
    }
    else {
      if(obj?.sender_id === manager_modal_id?.manager_id){
        result = {name : manager_modal_id?.manager_name, tag:"(Account Manager)"}
      }
      else
      result = { name: "GetCreator", tag: "" }
    }
    return <li key={i}>
      <div style={{display:"flex",flexDirection:"column"}}>
        <div>
          <span style={{ color: result.tag === "(client)" ? "#10c89b" : result.tag === "(creator)" ? "#3454d1" : "" }}>{result.name}</span> <span style={{ color: "#4a4a4a" }}>{result.tag}</span> :
          <div className="d-flex">
                    <div
                        style={{ color: "#787878" }}
                        dangerouslySetInnerHTML={{
                          __html: obj?.text,
                        }}
            />
            <span style={{ color: "#787878",marginLeft:"5px" }}>{obj?.sub_text ? obj.sub_text : ""}</span>
          </div>
        </div>
        {obj?.files && obj?.files.length > 0 &&
            <div>
            {obj.files.map(ele => {
                if (ele.name.includes("https://")) {
                    return <img style={{maxWidth:"59px",maxHeight:"33px" ,margin:"6px"}} src={`${ele.name}`} alt='files'></img>
                } else {
                  return <img style={{maxWidth:"59px",maxHeight:"33px",margin:"6px"}} src={`https://ifn-images.s3.ap-south-1.amazonaws.com/upload-files/${ele.name}`} alt='files'></img>
                }
              })}
          </div>
        }
        <span style={{fontSize:"10px",color:"#6e84a3",marginTop:"5px"}}><span style={{color:"#000"}}>Date : </span>{ Moment(obj?.createdAt).format("YYYY-MM-DD h:mm a")}</span>
      </div>
    </li>
  }

  handleCloseModal = () => {
    this.props.setCloseModal(false);
  };

  render() {
    const { chatData,load } = this.state;
    const { showChatModal } = this.props;
    return (
      <React.Fragment>
        <div>
          <Modal
            isOpen={showChatModal}
            style={customStyles}
            contentLabel="Example Modal"
          > <button
              type="button"
              className="close media-upload-modal"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleCloseModal}
            >
              <span
                aria-hidden="true"
                style={{ color: "black", fontSize: "32px" }}
              >
                &times;
              </span>
            </button>
            <h3 className={`text-center mb-5`}>Chats</h3>
            <hr/>

            <div style={{maxHeight:"430px",overflowY:"auto"}}>
              <ul style={{ listStyle: "none" }}>
                {load ? <Loader /> :
                    chatData.length > 0 ?
                      chatData.map((obj, i) => {
                        return (
                          <div key={i + "j"}>
                            {this.chatList(obj, i)}
                            <hr key={i + "o"} />
                          </div>
                        )
                      })
                      : "No chats Found ..."
                }
              </ul>
              <div ref={this.textInput} />
            </div>

            {/* <div className="mx-auto text-center">
              <button
                type="button"
                className="btn btn-secondary ml-2"
                data-dismiss="modal"
                onClick={this.handleCloseModal}
              >
                close
              </button>
            </div> */}
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}
export default chatModal;
