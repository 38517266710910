import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import PackageUploadImage from "./packageUploadImage";

const animatedComponents = makeAnimated();

export default class AddPackages extends Component {
  state={
    name: "",
    selectedCollateral :'',
    desc: ''
  }

  handleOnChange = (e) =>{
    const{name, value} = e.target;
    this.setState({
      [name] : value
    })
  }

  render() {
    const options = [
      { value: "chocolate", label: "Chocolate" },
      { value: "strawberry", label: "Strawberry" },
      { value: "vanilla", label: "Vanilla" },
    ];
    const{ name, desc } = this.state;
    return (
      <React.Fragment>
        <section>
          <div className="container">
            <div className="card">
              <div className="card-body">
                {/*-------------------upload image ----------------------*/}

                 <PackageUploadImage {...this.state} {...this.props}/>
                 
                {/*-------------------upload image ----------------------*/}
                <hr className="my-5"/>
                <form>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter name"
                      name ="name"
                      value = {name}
                      onChnage={this.handleOnChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Collateral</label>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={options}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Description</label>
                    <textarea
                      type="text"
                      name="desc"
                      value={desc}
                      className="form-control"
                      placeholder="...."
                      onChnage={this.handleOnChange}
                    ></textarea>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
