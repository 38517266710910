import loginData from "./loginData.reducer";
import clientList from "./clientList.reducer";
import checkStatus from "./clientList.reducer";
import userSignup from "./clientList.reducer";
import creativeUsersData from "./clientList.reducer";
import addUsersData from "./clientList.reducer";
import updateUsersData from "./clientList.reducer";
import viewUsersData from "./clientList.reducer";
import collateralList from "./collateralList.reducer";
import collateralUploadPhoto from "./collateralList.reducer";
import collateralPCFName from "./collateralList.reducer";
import getCollateral from "./collateralList.reducer";
import projectList from "./projectList.reducer";
import getClientData from "./clientList.reducer";
import artistDataList from "./clientList.reducer";
import getArtistData from "./clientList.reducer";
import stausArtistCollateral from "./clientList.reducer";
import stausArtistProject from "./clientList.reducer";
import artistDetails from "./clientList.reducer";
import clientsDetail from "./clientList.reducer";
import clientsDetailsProfile from "./clientList.reducer";
import artistProfile from "./clientList.reducer";
import statusCollateral from "./collateralList.reducer";
import artistUpdateInfo from "./clientList.reducer";
import artistApprovalList from "./clientList.reducer";
import artistBusyList from "./clientList.reducer";
import artistStarredList from "./clientList.reducer";
import artistCleanUpdates from "./clientList.reducer";
import artistReviewStatus from "./clientList.reducer";
import transactionList from "./transaction.reducer";
import collaterlApprovedProject from "./collateralList.reducer";
import collaterlPendingProject from "./collateralList.reducer";
import transactionGetData from "./transaction.reducer";
import transactionStatus from "./transaction.reducer";
import transactionListError from "./transaction.reducer";
import activeTransactions from "./transaction.reducer";
import pendingTransactions from "./transaction.reducer";
import incompleteTransactions from "./transaction.reducer";
import incompleteHighTransaction from "./transaction.reducer";
import collateralPendingCreator from "./collateralList.reducer";
import collateralApprovedCreator from "./collateralList.reducer";
import collateralChangeHistory from "./collateralList.reducer";
import collateralActiveTransaction from "./collateralList.reducer";
import collateralActiveNegociation from "./collateralList.reducer";
import resetPwd from "./loginData.reducer";
import tempResetPwd from "./clientList.reducer";
import backendList from "./BackendUser.reducer";
import backendDeleteList from "./BackendUser.reducer";
import rejectedArtist from "./clientList.reducer";
import userDelete from "./clientList.reducer";
import approvedArtist from "./clientList.reducer";
import closeProject from "./transaction.reducer";
import deleteArtist from "./clientList.reducer";
import deleteClient from "./clientList.reducer";
import locationUser from "./clientList.reducer";
import completeProject from "./transaction.reducer";
import closeTransactionList from "./transaction.reducer";
import DontLikeTranscationList from "./transaction.reducer";
import chooseCreator from "./transaction.reducer";
import pendingCollateral from "./collateralList.reducer";
import pendingCollateralList from "./collateralList.reducer";
import pendingProjectList from "./projectList.reducer";
import getPendingProjectData from "./projectList.reducer";
import hideCollateral from "./collateralList.reducer";
import projectReviewList from "./projectList.reducer";
import getProjcetReviewData from "./projectList.reducer";
import transactionApprovalList from "./transaction.reducer";
import callBackRequestList from "./transaction.reducer";
import callBackData from "./transaction.reducer";
import callBackSearchCreator from "./transaction.reducer";
import recommendedCreator from "./transaction.reducer";
import pendingCreatorApproval from "./clientList.reducer";
import getBackendUser from "./BackendUser.reducer";
import getTabList from "./BackendUser.reducer";
import addtabList from "./BackendUser.reducer";
import deleteCollateralList from "./collateralList.reducer";
import inCompleteBriefList from "./transaction.reducer";
import creatorRejectsBriefList from "./transaction.reducer";
import rejectCreatorData from "./transaction.reducer";
import clearBriefList from "./transaction.reducer";
import clientRejectsCreatorList from "./clientList.reducer";
import approvedCreatorList from "./collateralList.reducer";
import approvedStarredList from "./collateralList.reducer";
import addBackendUser from "./BackendUser.reducer";
import closedBriefProject from "./transaction.reducer";
import rejectedBriefList from "./transaction.reducer";
import AllCollateralList from "./projectList.reducer";
import getUserList from "./projectList.reducer";
import addPostProject from "./projectList.reducer";
import getPostProjectData from "./projectList.reducer";
import addProposal from "./projectList.reducer";
import updateProject from "./projectList.reducer";
import updateProposal from "./projectList.reducer";
import deleteProjectList from "./transaction.reducer";
import deleteBriefList from "./transaction.reducer";
import deleteProjectAllList from "./transaction.reducer";
import closedBriefAllList from "./transaction.reducer";
import closeBriefRequestList from "./transaction.reducer";
import closeProjectRequestList from "./transaction.reducer";
import clientBriefList from "./clientList.reducer";
import editEmbeddedLink from "./clientList.reducer";
import milesList from "./Milestone.reducer";
import transitionDashboard from "./Milestone.reducer";
import completedMiles from "./transaction.reducer";
import milesComment from "./transaction.reducer";
import milestoneApproved from "./transaction.reducer";
import artistBusyOrAvailable from "./clientList.reducer";
import reportProjectBrief from "./Reports.reducer";
import reportEnterpriseProject from "./Reports.reducer";
import reportProject from "./Reports.reducer";
import reportCollateral from "./Reports.reducer";
import reportAllArtist from "./Reports.reducer";
import addMultipleCreator from "./transaction.reducer";
// import addMultipleCreator2 from "./transaction.reducer";
import deleteBackendUser from "./BackendUser.reducer";
import deletePendingCreator from "./transaction.reducer";
import featuredCollaterl from "./collateralList.reducer";
import paymentOffline from "./transaction.reducer";
import updateTransactions from "./transaction.reducer";
import disabledArtist from "./clientList.reducer";
import disableEnableArtist from "./clientList.reducer";
import reportApprovedArtist from "./Reports.reducer";
import reportClient from "./Reports.reducer";
import approvedProposal from "./transaction.reducer";
import approvedProposalJob from "./transaction.reducer";
import requestChangeProposal from "./transaction.reducer";
import editClient from "./transaction.reducer";
import managerDetails from "./transaction.reducer";
import inactiveBrief from "./transaction.reducer";
import inactiveProject from "./transaction.reducer";
import inActBriefForAm from "./transaction.reducer"
import transactionsCount from "./transaction.reducer"
import customReducer from './custom.reducer';

export default {
  loginData,
  clientList,
  checkStatus,
  userSignup,
  creativeUsersData,
  addUsersData,
  updateUsersData,
  viewUsersData,
  collateralList,
  collateralUploadPhoto,
  collateralPCFName,
  getCollateral,
  projectList,
  getClientData,
  artistDataList,
  getArtistData,
  stausArtistCollateral,
  stausArtistProject,
  artistDetails,
  clientsDetail,
  clientsDetailsProfile,
  artistProfile,
  statusCollateral,
  artistUpdateInfo,
  artistApprovalList,
  artistBusyList,
  artistStarredList,
  artistCleanUpdates,
  artistReviewStatus,
  transactionList,
  collaterlApprovedProject,
  collaterlPendingProject,
  transactionGetData,
  transactionStatus,
  transactionListError,
  activeTransactions,
  pendingTransactions,
  incompleteTransactions,
  incompleteHighTransaction,
  collateralPendingCreator,
  collateralApprovedCreator,
  collateralChangeHistory,
  collateralActiveTransaction,
  collateralActiveNegociation,
  resetPwd,
  tempResetPwd,
  backendList,
  backendDeleteList,
  rejectedArtist,
  userDelete,
  approvedArtist,
  closeProject,
  deleteArtist,
  deleteClient,
  locationUser,
  completeProject,
  closeTransactionList,
  DontLikeTranscationList,
  chooseCreator,
  pendingCollateral,
  pendingCollateralList,
  pendingProjectList,
  getPendingProjectData,
  hideCollateral,
  projectReviewList,
  getProjcetReviewData,
  transactionApprovalList,
  callBackRequestList,
  callBackData,
  callBackSearchCreator,
  recommendedCreator,
  pendingCreatorApproval,
  getBackendUser,
  getTabList,
  addtabList,
  deleteCollateralList,
  inCompleteBriefList,
  creatorRejectsBriefList,
  rejectCreatorData,
  clearBriefList,
  clientRejectsCreatorList,
  approvedCreatorList,
  approvedStarredList,
  addBackendUser,
  closedBriefProject,
  rejectedBriefList,
  AllCollateralList,
  getUserList,
  addPostProject,
  getPostProjectData,
  addProposal,
  updateProject,
  updateProposal,
  deleteProjectList,
  deleteBriefList,
  deleteProjectAllList,
  closedBriefAllList,
  closeBriefRequestList,
  closeProjectRequestList,
  clientBriefList,
  editEmbeddedLink,
  milesList,
  transitionDashboard,
  completedMiles,
  milesComment,
  milestoneApproved,
  artistBusyOrAvailable,
  reportProjectBrief,
  reportEnterpriseProject,
  reportProject,
  reportCollateral,
  reportAllArtist,
  addMultipleCreator,
  // addMultipleCreator2,
  deleteBackendUser,
  deletePendingCreator,
  featuredCollaterl,
  paymentOffline,
  updateTransactions,
  disabledArtist,
  disableEnableArtist,
  reportApprovedArtist,
  reportClient,
  approvedProposal,
  approvedProposalJob,
  requestChangeProposal,
  editClient,
  managerDetails,
  inactiveBrief,
  inactiveProject,
  inActBriefForAm,
  transactionsCount,
  customReducer,
};
