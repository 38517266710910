import React, { Component } from "react";
import { connect } from "react-redux";
import Reports from "./Reports";
import {
  reportProjectBrief,
  reportEnterpriseProject,
  reportProject,
  reportCollateral,
  reportAllArtist,
  reportApprovedArtist,
  reportClient
} from "../../../store/action/Reports.action";

class ReportsDetails extends Component {
  state = {
    projectReport: false,
    enterpriseReport: false,
    allProjectReport: false,
    collateralReport: false,
    allArtistReport: false,
    CollateralForReport: [],
    approvedArtistReport: false,
    clientReportIs : false
  };

  reportState = (reports) => {
    this.setState(reports);
  };

  reqForReport = (status) => {
    if (status === "projectReport") {
      this.setState({ projectReport: true });
      this.props.$reportProjectBrief();
    } else if (status === "enterPrise") {
      this.setState({ enterpriseReport: true });
      this.props.$reportEnterpriseProject();
    } else if (status === "collateral") {
      this.setState({ collateralReport: true });
      const { CollateralForReport } = this.state;
      this.props.$reportCollateral(CollateralForReport._id);
    } else if (status === "all") {
      this.setState({ allArtistReport: true });
      this.props.$reportAllArtist();
    }else if(status === "approvedArtist"){
      this.setState({approvedArtistReport : true})
      this.props.$reportApprovedArtist();
    }else if(status === "client"){
      this.setState({clientReportIs : true})
      this.props.$reportClient();
    }else {
      this.setState({ allProjectReport: true });
      this.props.$reportProject();
    }
  };

  handleGetConfirmation = (status) => {
    let confirmIs = window.confirm(`Do you want to countinue ?`);
    if (confirmIs === true) {
      this.reqForReport(status);
      return true;
    } else {
      return false;
    }
  };

  getAlert = () => {
    alert("You will get report on your email very soon....");
  };

  getProjectBriefReport = (projectData, projectReport) => {
    if (projectData.status === 1 && projectReport) {
      this.setState({ projectReport: false });
      this.getAlert();
    }
  };

  getEnterPriseProjectReport = (enterpriseData, enterpriseReport) => {
    if (enterpriseData.status === 1 && enterpriseReport) {
      this.setState({ enterpriseReport: false });
      this.getAlert();
    }
  };

  getProjectReport = (allProjectData, allProjectReport) => {
    if (allProjectData.status === 1 && allProjectReport) {
      this.setState({ allProjectReport: false });
      this.getAlert();
    }
  };

  getAllArtistReport = (allArtist, allArtistReport) => {
    if (allArtist.status === 1 && allArtistReport) {
      this.setState({ allArtistReport: false });
      this.getAlert();
    }
  };

  getCollateralReport = (collateralData, collateralReport) => {
    if (collateralData.status === 1 && collateralReport) {
      this.setState({ collateralReport: false });
      this.getAlert();
    }
  };

  getApprovedArtistReport = (approveArtistData, approvedArtistReport) =>{
    if(approveArtistData.status === 1 && approvedArtistReport){
      this.setState({approvedArtistReport : false})
      this.getAlert();
    }
  }

  getClientReport = (reportClientData, clientReportIs) => {
    if(reportClientData.status === 1 && clientReportIs){
      this.setState({clientReportIs : false})
      this.getAlert();
    }
  }

  componentDidMount() {
    this.props.handleClients.handlepathName("/reports");
  }

  componentWillReceiveProps(nextProps) {
    const projectData = nextProps._reportProjectBrief.reportForProjectIs;
    const enterpriseData =
      nextProps._reportEnterpriseProject.reportForEnterprise;
    const allProjectData = nextProps._reportProject.reportForProject;
    const collateralData = nextProps._reportCollateral.reportForCollateral;
    const allArtist = nextProps._reportAllArtist.reportforall;
    const approveArtistData = nextProps._reportApprovedArtist.approvedArtistReport;
    const reportClientData = nextProps._reportClient.clientReport;

    const {
      projectReport,
      enterpriseReport,
      allProjectReport,
      collateralReport,
      allArtistReport,
      approvedArtistReport,
      clientReportIs
    } = this.state;
    projectReport && this.getProjectBriefReport(projectData, projectReport);
    enterpriseReport &&
      this.getEnterPriseProjectReport(enterpriseData, enterpriseReport);
    allProjectReport && this.getProjectReport(allProjectData, allProjectReport);
    collateralReport &&
      this.getCollateralReport(collateralData, collateralReport);
    allArtistReport && this.getAllArtistReport(allArtist, allArtistReport);
    approvedArtistReport && this.getApprovedArtistReport(approveArtistData, approvedArtistReport);
    clientReportIs && this.getClientReport(reportClientData, clientReportIs)
  }

  render() {

    const funcIs = {
      reportState: this.reportState,
    };

    const reportArr = [
      {
        reportFor: "All Time Project Report",
        func: () => this.handleGetConfirmation("projectReport"),
        anyComponent: false,
      },
      {
        reportFor: "All Time Project Report (Enterprise)",
        func: () => this.handleGetConfirmation("enterPrise"),
        anyComponent: false,
      },
      {
        reportFor: "All Time Brief Report",
        func: () => this.handleGetConfirmation(),
        anyComponent: false,
      },
      {
        reportFor: "All Time Artist Report - All",
        func: () => this.handleGetConfirmation("all"),
        anyComponent: false,
      },
      {
        reportFor: "All Time Artist Report - Collateral Wise",
        func: () => this.handleGetConfirmation("collateral"),
        anyComponent: true,
      },
      {
        reportFor: "All Time Approved Artist Report",
        func: () => this.handleGetConfirmation("approvedArtist"),
        anyComponent: false,
      },
      {
        reportFor: "All Time Client Report",
        func: () => this.handleGetConfirmation("client"),
        anyComponent: false,
      },
    ];
    const { CollateralForReport } = this.state;

    return (
      <React.Fragment>
        <section>
          <div className="container">
            <div className="card">
              <div className="card-body">
                {reportArr?.map((obj, index) => (
                  <div className="card" key={index}>
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col ml-n2">
                          <h4 className="card-title mb-1 name">
                            <a href="#!">{obj.reportFor}</a>
                          </h4>
                          <div className="row">
                            <div className="col-6 mt-3">
                              {obj.anyComponent && (
                                <Reports handlefunc={funcIs} />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <a
                            href="#!"
                            className={`btn btn-sm btn-primary d-none d-md-inline-block ${
                              obj.anyComponent &&
                              CollateralForReport.length === 0
                                ? "disabled"
                                : ""
                            }`}
                            onClick={obj.func}
                          >
                            Report
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
const mapState = ({
  reportProjectBrief,
  reportEnterpriseProject,
  reportProject,
  reportCollateral,
  reportAllArtist,
  reportApprovedArtist,
  reportClient
}) => {
  return {
    _reportProjectBrief: reportProjectBrief,
    _reportEnterpriseProject: reportEnterpriseProject,
    _reportProject: reportProject,
    _reportCollateral: reportCollateral,
    _reportAllArtist: reportAllArtist,
    _reportApprovedArtist:reportApprovedArtist,
    _reportClient: reportClient
  };
};
const mapDispatch = (dispatchEvent) => ({
  $reportProjectBrief: (values) => dispatchEvent(reportProjectBrief(values)),
  $reportEnterpriseProject: (values) =>
    dispatchEvent(reportEnterpriseProject(values)),
  $reportProject: (values) => dispatchEvent(reportProject(values)),
  $reportCollateral: (values) => dispatchEvent(reportCollateral(values)),
  $reportAllArtist: (values) => dispatchEvent(reportAllArtist(values)),
  $reportApprovedArtist: (values) => dispatchEvent(reportApprovedArtist(values)),
  $reportClient: (values) => dispatchEvent(reportClient(values)),
});
export default connect(mapState, mapDispatch)(ReportsDetails);
