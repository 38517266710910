import React, { Component } from "react";
import StarRatings from "react-star-ratings";

export default class Review extends Component {
  render() {
      const{ StatusIs, ReviewIs } = this.props;
    return (
      <React.Fragment>
         {ReviewIs.length > 0 &&
          (StatusIs === 7 ||
            StatusIs === 8 ||
            StatusIs === 5 ||
            StatusIs === 6) && <hr className="my-5" />}
        {ReviewIs.length > 0 && (
          <div className="row">
            <label className="col-12">
              {StatusIs === 7
                ? "Feedback"
                : StatusIs === 8
                ? "Close Reason"
                : StatusIs === 5
                ? "Request to close brief"
                : StatusIs === 6
                ? "Request to close Project"
                : ""}
            </label>
            {ReviewIs.map((obj, index) => (
              <div className="col-6" key={index}>
                <div className="card card-new">
                  <div className="card-body">
                    <div className="mb-3">
                      <div className="row align-items-center">
                        <div className="col ml-n2">
                          <h4 className="card-title mb-1">{obj.type}</h4>
                          <p className="card-text small text-muted mb-0">
                            <span className="fe fe-user"></span>
                            <time >{obj.user_type}</time>
                          </p>
                          {/*-----------------rating -----------------------*/}
                          {(obj.rating > 0) && (
                            <StarRatings
                              rating={obj.rating}
                              starRatedColor="orange"
                              changeRating={this.changeRating}
                              numberOfStars={5}
                              name="rating"
                              starDimension="15px"
                              starSpacing="2px"
                            />
                          )}
                          {/*-----------------rating -----------------------*/}
                        </div>
                      </div>
                    </div>
                    {obj.description && (
                      <p className="mb-3">{`Description : ${obj.description}`}</p>
                    )}
                    <ol className="mb-3">
                      {obj.feedback &&
                        obj.feedback.map((object, index) => (
                          <li key={index}>{object.text}</li>
                        ))}
                    </ol>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {ReviewIs.length > 0 &&
          (StatusIs === 7 ||
            StatusIs === 8 ||
            StatusIs === 5 ||
            StatusIs === 6) && <hr className="my-5" />}
      </React.Fragment>
    );
  }
}