import {
  COLLATERAL_LIST_SUCCESS,
  COLLATERAL_LIST_ERROR,
  COLLATERAL_IMAGE_SUCCESS,
  COLLATERAL_PCF_SUCCESS,
  COLLATERAL_GET_SUCCESS,
  COLLATERAL_STATUS_SUCCESS,
  COLLATERAL_APPROVED_PROJECT_SUCCESS,
  COLLATERAL_PENDING_PROJECT_SUCCESS,
  COLLATERAL_PENDING_CREATOR_SUCCESS,
  COLLATERAL_APPROVED_CREATOR_SUCCESS,
  COLLATERAL_CHANGE_HISTORY_SUCCESS,
  COLLATERAL_ACTIVE_TRANSACTION_SUCCESS,
  COLLATERAL_ACTIVE_NEGOCIATIONS_SUCCESS,
  COLLATERAL_GET_ERROR,
  PENDING_COLLATERAL_SUCCESS,
  PENDING_COLLATERAL_LIST_SUCCESS,
  PENDING_COLLATERAL_LIST_ERROR,
  HIDE_COLLATERAL_SUCCESS,
  DELETE_COLLATERAL_LIST_SUCCESS,
  DELETE_COLLATERAL_LIST_ERROR,
  APPROVED_CREATOR_LIST_SUCCESS,
  APPROVED_STARRED_LIST_SUCCESS,
  FEATURED_COLLATERAL_SUCCESS,
  RESET_APPROVED_CREATOR_LIST
} from "../type.action";

const initState = {
  collateralLodingIs: false,
  collateralList: "",
  collateralListErrorIs: "",
  collateralPic: "",
  pcfName: "",
  collateralDataIs: "",
  collStatus: "",
  collApprovedProject: "",
  collPendingProject: "",
  collPendingCreator: "",
  collApprovedCreator: "",
  collActiveNegociation: "",
  collupdateDetails: "",
  collaterlaDataGetErrIs: "",
  pendingCollateral: "",
  pendingCollateralListIs: "",
  hideCollateralIs: "",
  deleteCollListIs: "",
  pendingCollateralListErrIs: "",
  deleteCollateralListErrIs: "",
  approvedCreatorIs: "",
  featuredCollateralIs: "",
  approvedStarredIs:"",
  
};

const reducers = (state = initState, { type, values, error }) => {
  switch (type) {
    case COLLATERAL_LIST_SUCCESS:
      return {
        ...state,
        collateralList: values,
        collateralLodingIs: false,
      };
    case COLLATERAL_LIST_ERROR:
      return {
        ...state,
        collateralListErrorIs: error,
        collateralLodingIs: true,
      };
    case COLLATERAL_IMAGE_SUCCESS:
      return {
        ...state,
        collateralPic: values,
      };
    case COLLATERAL_PCF_SUCCESS:
      return {
        ...state,
        pcfName: values,
      };
    case COLLATERAL_GET_SUCCESS:
      return {
        ...state,
        collateralDataIs: values,
      };
    case COLLATERAL_GET_ERROR:
      return {
        ...state,
        collaterlaDataGetErrIs: values,
      };
    case COLLATERAL_STATUS_SUCCESS:
      return {
        ...state,
        collStatus: values,
      };
    case COLLATERAL_APPROVED_PROJECT_SUCCESS:
      return {
        ...state,
        collApprovedProject: values,
      };
    case COLLATERAL_PENDING_PROJECT_SUCCESS:
      return {
        ...state,
        collPendingProject: values,
      };
    case COLLATERAL_PENDING_CREATOR_SUCCESS:
      return {
        ...state,
        collPendingCreator: values,
      };
    case COLLATERAL_APPROVED_CREATOR_SUCCESS:
      return {
        ...state,
        collApprovedCreator: values,
      };
    case COLLATERAL_CHANGE_HISTORY_SUCCESS:
      return {
        ...state,
        collChangeHistory: values,
      };
    case COLLATERAL_ACTIVE_TRANSACTION_SUCCESS:
      return {
        ...state,
        collActiveTransactions: values,
      };
    case COLLATERAL_ACTIVE_NEGOCIATIONS_SUCCESS:
      return {
        ...state,
        collActiveNegociation: values,
      };
    case PENDING_COLLATERAL_SUCCESS:
      return {
        ...state,
        pendingCollateral: values,
      };
    case PENDING_COLLATERAL_LIST_SUCCESS:
      return {
        ...state,
        pendingCollateralListIs: values,
      };
    case PENDING_COLLATERAL_LIST_ERROR:
      return {
        ...state,
        pendingCollateralListErrIs: error,
      };
    case HIDE_COLLATERAL_SUCCESS:
      return {
        ...state,
        hideCollateralIs: values,
      };
    case DELETE_COLLATERAL_LIST_SUCCESS:
      return {
        ...state,
        deleteCollListIs: values,
      };
    case DELETE_COLLATERAL_LIST_ERROR:
      return {
        ...state,
        deleteCollateralListErrIs: error,
      };
    case APPROVED_CREATOR_LIST_SUCCESS:
      return {
        ...state,
        approvedCreatorIs: values,
      };
    case APPROVED_STARRED_LIST_SUCCESS:
      return {
        ...state,
        approvedStarredIs: values,
      };
    case FEATURED_COLLATERAL_SUCCESS:
      return {
        ...state,
        featuredCollateralIs: values,
      };
      case RESET_APPROVED_CREATOR_LIST:
        return {
          state,
          approvedCreatorIs:""
        }
    default:
      return state;
  }
};

export default reducers;
