import React, { Component } from "react";
import { connect } from "react-redux";
import {closeProject, transactionStatus, inactiveBrief, deleteProjectList } from "../../../store/action/transaction.action";
import Table from "./Table";
import ReactPaginate from "react-paginate";
import ErrorApi from "../Common/Error/ErrorApi";
import { deleteAPI } from "../../../store/api-interface";

class InactiveBrief extends Component {

  state = {
    transactionsList: 1,
    selectedPage: 1,
    transactionActive: false,
    LoadingIs: false,
    transactionData: [],
    transactionIs: "",
    minBudget: "",
    maxBudget: "",
    deadline: "",
    totalPages: 0,
    closeProjectActive: false,
    statusLoader : false,
    headCheckbox: false,
    deleteActiveIs : false
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        const {
          transactionsList,
          transactionIs,
          minBudget,
          maxBudget,
          deadline,
        } = this.state;
        const artistId=this.props.location.pathname.split(':');


        const transactionData = {
          List: transactionsList,
          project: transactionIs,
          min_budget: minBudget,
          max_budget: maxBudget,
          deadline: deadline,
          id:artistId[1]
        };
        this.props.$inactiveBrief(transactionData);
      }
    );
  };

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    const { transactionIs, minBudget, maxBudget, deadline } = this.state;
    const artistId=this.props.location.pathname.split(':');

    const transactionData = {
      List: selectedData,
      project: transactionIs,
      min_budget: minBudget,
      max_budget: maxBudget,
      deadline: deadline,
      id:artistId[1],
    };
    this.setState({selectedPage: selectedData});
    this.props.$inactiveBrief(transactionData);
  };

  handleCloseProject = (id) => {
    this.setState({
      closeProjectActive: true,
    });
    const closeProjectData = { _id: id };
    this.props.$closeProject(closeProjectData);
  };

  handleInactiveTransaction = () => {
    const { transactionsList, transactionIs } = this.state;
    const artistId=this.props.location.pathname.split(':');

    this.setState({
      LoadingIs: true,
    });

    const transactionData = {
      List: transactionsList,
      project: transactionIs,
      id:artistId[1]
    };
    this.props.$inactiveBrief(transactionData);
  };

  handleGetConfirmation(obj, id) {
    let confirmIs = window.confirm(
      `Do you want to ${obj === 5 ? `accept` : `reject`} this Transaction ?`
    );
    if (confirmIs === true) {
      this.setState({
        statusLoader: true,
      });
      const statusData = { _id: id, status: obj };
      this.props.$transactionStatus(statusData);
      return true;
    } else {
      return false;
    }
  }

  handleStatusChanged = (id , status) =>{
    let currentStatus = 0;
    if (status === "accept") {
      currentStatus = 5;
    } else {
      currentStatus = 9;
    }
    this.handleGetConfirmation(currentStatus, id);
  }
  
  handleDeleteProject = (id) => {
    this.setState({deleteActiveIs : true})
    const currentData = { _id : id}
    this.props.$deleteProjectList(currentData)
  }

  componentDidMount() {
    this.handleInactiveTransaction();
  }

  componentWillReceiveProps(nextProps) {
    const inActivetransaction = nextProps._inactiveBrief.inactiveBriefData;
    const closedProject = nextProps._closeProject.closeProjectIs;
    const statusData = nextProps._transactionStatus.postProjectStatus;
    const deleteProject = nextProps._deleteProjectList.deleteProjectIs;
    const { closeProjectActive, statusLoader, deleteActiveIs } = this.state;

    if (inActivetransaction) {
      this.setState({
        transactionData: inActivetransaction.PostProjects,
        LoadingIs: false,
        totalPages: inActivetransaction.totalPages,
      });
    }
    if (closedProject && closeProjectActive) {
      alert(closedProject.message);
      this.setState({
        closeProjectActive: false,
      });
      this.handleInactiveTransaction();
    }
    if (statusData?.status === "1" && statusLoader) {
       this.handleInactiveTransaction()
       this.setState({
        statusLoader : false
       })
    }
    if(deleteProject && deleteActiveIs){
      this.setState({ deleteActiveIs : false })
      this.handleInactiveTransaction();
    }
  }

  onCheckboxClick = (id) => {
    const {headCheckbox, transactionData} = this.state;
    if(id === 'all'){
      const newTransactionList = transactionData.map(tranx => {
        headCheckbox ? tranx.selected = false : tranx.selected = true;
        return tranx
      });
      this.setState({transactionData: newTransactionList, headCheckbox: !this.state.headCheckbox})
    } else {
      const newTransactionList = transactionData.map(tranx => {
        if(tranx._id === id){tranx.selected = !tranx.selected}
        return tranx
      });
      this.setState({transactionData: newTransactionList});
    }
  }

  handleDeleteAll = () => {
    const {transactionData} = this.state;
    this.setState({isDeleting: true});

    const deletedIds = []
    transactionData.forEach(tranx => {
      tranx.selected && deletedIds.push(tranx._id)
    });

    if(window.confirm('Are you sure you want to delete these transactions.')){
      deleteAPI(`/postProject/delete-postproject-multiple`, {_id: deletedIds}).then(res => {
        this.handlePageClick({selected: this.state.selectedPage - 1});
        this.setState({isDeleting: false});
      }).catch(err => {
        console.log(err);
        this.setState({isDeleting: false});
      })
    } else {
      this.setState({isDeleting: false});
    }
  }

  handleApiCall = () => {
    this.handleInactiveTransaction();
  }

  render() {
    const { totalPages, isDeleting } = this.state;
    const transactionFunc = {
      handleOnChange: this.handleOnChange,
      handleCloseProject: this.handleCloseProject,
      handleStatusChanged: this.handleStatusChanged,
      handleDeleteProject: this.handleDeleteProject,
      onCheckboxClick: this.onCheckboxClick,
      handleApiCall: this.handleApiCall
    };

    const anySelectedTranx = this.state.transactionData.some(val => val.selected);    
    return (
      <React.Fragment>
        {this.props._inactiveBrief.inactiveBriefErr ? (
          <ErrorApi {...this.props} />
        ) : (
          <section className="client">
            <div className="container-fluid mx-auto">
              <Table
                {...this.state}
                {...this.props}
                handleTransaction={transactionFunc}
              />

              <div className=''>
                {anySelectedTranx && 
                  <button className="btn btn-danger"
                        onClick={this.handleDeleteAll}
                        disabled={isDeleting}
                  >Delete selected</button>}

                {/******** pagination ************* */}

                {totalPages > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                )}
              </div>
            </div>
          </section>
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({ pendingTransactions, closeProject, transactionStatus, inactiveBrief, deleteProjectList }) => {
  return {
    _pendingTransactions: pendingTransactions,
    _closeProject: closeProject,
    _transactionStatus: transactionStatus,
    _inactiveBrief : inactiveBrief,
    _deleteProjectList : deleteProjectList
  };
};
const mapDispatch = (dispatchEvent) => ({
  // $pendingTransactions: (values) => dispatchEvent(pendingTransactions(values)),
  $closeProject: (values) => dispatchEvent(closeProject(values)),
  $transactionStatus: (values) => dispatchEvent(transactionStatus(values)),
  $inactiveBrief: (values) => dispatchEvent(inactiveBrief(values)),
  $deleteProjectList: (values) => dispatchEvent(deleteProjectList(values))
});
export default connect(mapState, mapDispatch)(InactiveBrief);

