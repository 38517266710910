import React, { useEffect, useState } from "react";
import Select from 'react-select'
import NotesModal from "../../../Common/ModalCommon/NotesModal";
import SubmitMilestone from "../../../Common/ModalCommon/SubmitMilestone";
import moment from "moment";
import DatePicker from "react-date-picker";
import { addDays, addHrMinDate } from "../../../../../Utils/HelperFunction";
import { addCycleProposal, deleteCycleProposal, editCycleProposal, getPostProjectData } from "../../../../../store/action/projectList.action";
import { useDispatch, useSelector } from "react-redux";

const MilestoneActual = (props) => {

  const preferredBillingCycleOption = [{ label: 'Cycle End', value: "Monthly" }, { label: "Month End", value: "Month End" }]
  const { handleFunc, statusIs, EditIs, deliverablesActual, proposed_start_date, proposed_duration, preferred_billing_cycle } = props

  const [ActualCycleArr, setActualCycleArr] = useState([])
  const [preActualCycleArr, setPreActualCycleArr] = useState([])
  const [proposalIs, setProposalIs] = useState({})
  const [submitMilesOpen, setSubmitMilesOpen] = useState(false)
  const [submitMilesData, setSubmitMilesData] = useState({})
  const [compMiles, setCompMiles] = useState([])
  const [submitMilesName, setSubmitMilesName] = useState("")
  const [showSubmitBtn, setShowSubmitBtn] = useState('')
  const [showEditCycleWarning, setShowEditCycleWarning] = useState(false)
  const [editObj, setEditObj] = useState({})
  const [editIndex, setEditIndex] = useState(null)
  const [showLastSubmitDeliverable,setShowLastSubmitDeliverable] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (EditIs) {
      const MilesProposal = props._getPostProjectData.postProjectDataIs?.newProposalList;
      let ActualCycleArr = MilesProposal?.[0]?.milestoneActual
      ActualCycleArr[ActualCycleArr.length-1].show = showLastSubmitDeliverable
      setActualCycleArr([...ActualCycleArr])
      setPreActualCycleArr(JSON.parse(JSON.stringify([...ActualCycleArr])))
      setProposalIs(MilesProposal?.[0])
      setShowLastSubmitDeliverable(false)
    }
  }, [props._getPostProjectData.postProjectDataIs])


  const handleViewMiles = (e, obj, index) => {
    e.preventDefault();
    setSubmitMilesOpen(true)
    setSubmitMilesData(obj)
    // deliverableMiles: String.fromCharCode(index + this.state.milestoneIdx),
    setCompMiles(obj?.milestonecomplete)
    setSubmitMilesName(obj?.name)
    // this.props.$completedMiles(obj._id);
  };

  const handleCloseMilesModal = () => {
    setSubmitMilesOpen(false)
  }

  const handleAppendCycle = () => {
    setShowSubmitBtn("add")
    setEditIndex(ActualCycleArr.length)
    let temp = [...ActualCycleArr[0]?.deliverable]
    temp.forEach(el=>{
     el.milestonecomplete = []
     el.AllCount = 0
     el.ApprovedCount = 0
     el.PendingCount = 0
     el.RejectCount = 0
     el.tax = 0
    })

    
    setActualCycleArr([...ActualCycleArr, { end_date: "", price: 0, price_without_tax: 0, start_date: "", tax: 0, total_earning: 0, deliverable: [...temp], type: "milestone", edit: "add" }])
  }

  const handleEdit = (obj, index, type) => {
    // console.log(obj,'the obj')
    if (!type) {
      // let tempActualCycleArr = [...ActualCycleArr]
      setEditIndex(null)
      setEditObj("")
      setShowSubmitBtn("")
      // handleGetPostProjectData()
      preActualCycleArr[index].edit = null
      preActualCycleArr[index].show = obj?.show
      setActualCycleArr([...preActualCycleArr])
    } else {
      let tempActualCycleArr = [...ActualCycleArr]
      setEditIndex(index)
      setEditObj(obj)
      setShowSubmitBtn("edit")
      tempActualCycleArr[index].edit = "edit"
      tempActualCycleArr[index].show = true
      setActualCycleArr(tempActualCycleArr)
    }
  }

  const handleEditNewCycleDate = (value, type) => {
    let tempActualCycleArr = [...ActualCycleArr]

    let temp = tempActualCycleArr[tempActualCycleArr.length - 1]

    // if (e.target) {

    //   if (e.target.name) {
    //     const { name, value } = e.target
    //     temp[name] = value
    //   }
    //   else {
    //     if (e.target.checked) {
    //       temp['tax'] = 18
    //     }
    //     else {
    //       temp['tax'] = 0
    //     }
    //   }
    // } else {
    if (type == 'start') {
      temp['start_date'] = new Date(value)
    }
    else {
      temp['end_date'] = new Date(value)
    }
    // }

    tempActualCycleArr[tempActualCycleArr.length - 1] = temp
    setActualCycleArr(tempActualCycleArr)

  }

  const showDeliverable = (index) =>{
    let temp = [...ActualCycleArr]
    temp[index].show = !temp[index]?.show
    setActualCycleArr(temp)
  }

  const handleEditDeliverable = (e, index, CycleIdx) => {
    const { name, value } = e.target
    let temp = ActualCycleArr[CycleIdx].deliverable[index]
    if (e.target.name) {
      temp[name] = value
    } else {
      if (e.target.checked) {
        temp['tax'] = 18
      } else {
        temp['tax'] = 0
      }
    }
    ActualCycleArr[CycleIdx].deliverable[index] = { ...temp }
    setActualCycleArr([...ActualCycleArr])
  }

  const handleAppendDeliverables = (obj, edit, index) => {
    let i = 0
    if(obj.deliverable.length){
      let tmp = [...obj.deliverable].pop()
      i = (tmp.serial_no) +1
    }
    obj.deliverable = [...obj.deliverable, {
      AllCount: 0, ApprovedCount: 0, PendingCount: 0, RejectCount: 0, milestonecomplete: [], name: "", price: 0, price_creator: 0, tax: 0, type: "milestone",proposal_id:obj.proposal_id,post_project_id:obj?.post_project_id,payment_reference:obj?._id,user_id:proposalIs?.user_id,serial_no:i
    }]
    ActualCycleArr[index].deliverable = obj.deliverable
    setActualCycleArr([...ActualCycleArr])
  }

  const handleDeleteDeliverable = (object,index,CycleIdx) =>{
    let confirmIs = window.confirm("Do You want to Continue?")
    if(confirmIs){

      let temp = object
      temp.splice(index, 1)
      
      ActualCycleArr[CycleIdx].deliverable = temp
      setActualCycleArr([...ActualCycleArr])
      if(showSubmitBtn != 'add'){
      if(handleValidate()){
        handleSubmitEditCycle()
      }}
    }
  }

  const getMinDate = (type,obj,index)=>{
    if(type=='start'){
      let mindate = ""
      if(index == 0){
        mindate = proposed_start_date
      }
      else{ 
      if(ActualCycleArr[index-1]){
        mindate = ActualCycleArr[index-1].end_date
      }
    }
    return new Date(addDays(mindate,1));

    }
    else{
      let mindate = ""
      if(index == 0){
        if(obj.start_date){
          mindate = obj.start_date
        }
          else {
            mindate = proposed_start_date
          }
      }
      else{
        mindate = obj.start_date
      }
    return new Date(addDays(mindate,1));
    }
  }

  const handleValidate = () => {
    const editCycle = ActualCycleArr.filter(el => el.edit ? el : false)
    let valid = true
    for (let x of editCycle) {
      if (!x.start_date || !x.end_date) {
        valid = false
        setShowEditCycleWarning(true)
      }

      else {
        setShowEditCycleWarning(false)
        valid = true
      }
    }

    for (let x of editCycle) {
      for (let y of x.deliverable) {
        if (!y.name) {
          valid = false
          setShowEditCycleWarning(true)
        }
      }
    }
    return valid
  }

  const handleSubmitAddCycle = () => {

    setShowSubmitBtn('')
    setEditIndex(null)

    let cycle = ActualCycleArr[ActualCycleArr.length - 1]
    
    setShowLastSubmitDeliverable(cycle?.show)

    const data = {
      proposal_id: proposalIs._id,
      post_project_id: proposalIs.post_project_id,
      retainer_fees: cycle.price_without_tax,
      retainer_fees_creator: cycle.total_earning,
      retainer_fees_type: proposalIs.retainer_fees_type,
      tax: cycle.tax ? (cycle.price_without_tax * 0.18) : 0,
      start_date: addHrMinDate(cycle.start_date, 5, 30),
      end_date: addHrMinDate(cycle.end_date, 5, 30),
      milestones: cycle.deliverable
    }
    // console.log(data, 'add cycle submit')
    dispatch(addCycleProposal(data))

    handleGetPostProjectData()
  }

  const handleSubmitEditCycle = () => {
    setShowSubmitBtn("")
    setEditIndex(null)
    editObj.deliverable.forEach(el => {
      el.tax = el.tax ? Number(el.price) * 0.18 : 0
    });
    const data = {
      _id: editObj._id,
      proposal_id: proposalIs._id,
      proposal_type: proposalIs.proposal_type,
      post_project_id: proposalIs.post_project_id,
      retainer_fees: editObj.price_without_tax,
      retainer_fees_creator: editObj.total_earning,
      retainer_fees_type: proposalIs.retainer_fees_type,
      tax: editObj.tax ? (editObj.price_without_tax * 0.18) : 0,
      // start_date:addHrMinDate(cycle.start_date,5,30),
      // end_date:addHrMinDate(cycle.end_date,5,30),
      milestones: editObj.deliverable
    }
    // console.log(data)
    dispatch(editCycleProposal(data))
    handleGetPostProjectData()

  }

  const handleGetPostProjectData = () => {
    const projectId = proposalIs.post_project_id
    dispatch(getPostProjectData(projectId))

  }

  const handleDeleteCycle = (obj) => {
    let confirmIs = window.confirm('Do You want to Continue?')
    if (confirmIs) {
      dispatch(deleteCycleProposal({ _id: obj._id }))
      handleGetPostProjectData()
    }
  }

  const renderMilestoneTableHeader = () => {
    let tableHeader = [
      { name: "Name", col: 1 },
      { name: "Price Client", col: 1 },
      { name: "Price Creator", col: 1 },
      { name: "Tax", col: 1 },
      { name: "Pending", col: 1 },
      { name: "Approved", col: 1 },
      { name: "Rejected", col: 1 },
      { name: "View Milestone", col: 1 },
    ]

    return (
      <tr>
        {tableHeader.map((obj, index) => {
          return (
            <th key={index}>
              <a href="#!" className="text-muted sort">
                {obj.name}
              </a>
            </th>
          );
        })}
      </tr>
    );
  };

  const renderDeliverables = (object, edit, CycleIdx) => {

    if (!object.length > 0)
      return (
        <tr>
          <td className="text-center">No Data Found</td>
        </tr>
      );
    return object.map((obj, index) => {
      return (
        <tr key={index}>
          <td className="orders-order" >{edit ? <input value={obj?.name} name='name' onChange={(e) => handleEditDeliverable(e, index, CycleIdx)} /> : obj?.name}</td>
          <td className="orders-order" >{edit ? <input value={obj?.price} name='price' onChange={(e) => handleEditDeliverable(e, index, CycleIdx)} /> : obj?.price ? '₹ ' + obj?.price : 'N/A'}</td>
          <td className="orders-order" >{edit ? <input value={obj?.price_creator} name='price_creator' onChange={(e) => handleEditDeliverable(e, index, CycleIdx)} /> : obj?.price_creator ? '₹ ' + obj?.price_creator : 'N/A'}</td>
          <td className="orders-order" >
            <input type='checkbox' disabled={obj?.restrict || !edit} onChange={(e) => handleEditDeliverable(e, index, CycleIdx)} checked={obj?.tax} />
            <label className="mx-1">GST</label>
          </td>
          <td className="orders-order" >{obj?.PendingCount > 0 ? obj?.PendingCount : '-'}</td>
          <td className="orders-order text-success">
            {obj?.ApprovedCount > 0 ? obj?.ApprovedCount : '-'}
          </td>
          <td className="orders-order text-danger">{obj?.RejectCount > 0 ? obj?.RejectCount : '-'}</td>
          <td className="orders-order" >
            {obj?.milestonecomplete?.filter(el => el.allFiles.length > 0 ? el : false)?.length > 0 ? (
              <div
                className="btn btn-outline-success btn-sm"
                // disabled
                onClick={(e) => handleViewMiles(e, obj)}
              >
                <i className="fe fe-eye"></i>
              </div>
            ) : '-'}
          </td>

          {edit && object.length!=1 && <td className="orders-order position-sticky" style={{cursor:'pointer',right:'0',background:'#FFF' }} 
          onClick={()=>handleDeleteDeliverable(object,index,CycleIdx)}
          ><span 
          // onClick={() => {
          //   let temp = object
          //   temp.splice(index, 1)

          //   ActualCycleArr[CycleIdx].deliverable = temp
          //   setActualCycleArr([...ActualCycleArr])

          // }}
          ><i className="fe fe-trash" /></span></td>}
        </tr>
      );

    });
  };

  return (
    <>
      {!EditIs ? <React.Fragment>
        <label className="text-secondary text-capitalize h3">Time Commitment</label>

        <div className="col-lg-12 px-0 py-3">
          <div className="row pb-4 mx-0 justify-content-between align-items-center">
            <div className="col-xl-7 px-0">
              <p className="font_semi_bold_w_600 mb-0">Proposed Duration</p>
              {/* <p className="text_light_gray_xs mb-0">
      Indicate how long you will be able to work on this project.{" "}
    </p> */}
            </div>
            <div className="d-flex col-xl-5 px-0 mob-pt-3 justify-content-end mob-justify-content-unset">
              <div className="btn_field number_btn">
                <div className="btn-group" role="group" aria-label="Basic example">
                  <button type="button border-0" className="btn" onClick={(e) => handleFunc.handleChangeProposedDuration(e, 'dec')}>
                    -
                  </button>
                  <input
                    type="number"
                    className="form-control text-center input_sm"
                    value={proposed_duration}
                    onChange={handleFunc.handleChangeProposedDurationInput}

                  />
                  <button type="button border-0" className="btn" onClick={(e) => handleFunc.handleChangeProposedDuration(e, 'inc')}>
                    +
                  </button>
                  <span className='d-flex justify-content-center align-items-center ml-2'>Month</span>
                </div>

              </div>
            </div>
          </div>
          <div className="row pb-4 mx-0 justify-content-between align-items-center">
            <div className="col-xl-7 px-0">
              <p className="font_semi_bold_w_600 mb-0">preferred Billing Cycle</p>
              {/* <p className="text_light_gray_xs mb-0">
              Indicate how long you will be able to work on this project.{" "}
            </p> */}
          </div>
          <div className="d-flex col-xl-5 px-0 mob-pt-3 justify-content-end mob-justify-content-unset">
            <div className="col-xl-7 px-0">
              <Select
                options={preferredBillingCycleOption}
                onChange={(value) => handleFunc.handleChangePreferredBillingCycle(value, 'actual')}
                value={{ label: `${preferred_billing_cycle == 'Monthly' ? "Cycle End" : preferred_billing_cycle}`, value:  preferred_billing_cycle }}
              />
              </div>
            </div>
          </div>
        </div>

        <hr className="my-5" />
        <label className="text-secondary text-capitalize h3">Deliverables</label>
        <div className="row">

          <div className="col">
            <label
              htmlFor="exampleInputEmail1"
              className="text-secondary"
            >
              Item
            </label>
          </div>
          <div className="col">
            <label
              htmlFor="exampleInputEmail1"
              className="text-secondary ml-5"
            >
              Client Cost
            </label>
          </div>
          <div className="col">
            <label
              htmlFor="exampleInputEmail1"
              className="text-secondary"
            >
              Creator Cost
            </label>
          </div>
          <div className="col">
            <label
              htmlFor="exampleInputEmail1"
              className="text-secondary"
            >
            </label>
          </div>
        </div>
        {deliverablesActual?.map((obj, index) => (
          <React.Fragment key={index}>
            <div className="row my-3">
              <div className="row col-11">
                <div className="col-1 d-flex align-items-center"><label className="">{index + 1}</label></div>
                <div className="col">
                  {/* <label
                htmlFor="exampleInputEmail1"
                className="text-secondary"
              >
                {`Item (${index + 1})`}
              </label> */}
                  <input
                    type="text"
                    className={`form-control ${obj.nameError ? "error-show" : ""
                      }`}
                    placeholder="Item Name"
                    name="name"
                    value={obj.name}
                    onChange={(e) => handleFunc.handleChangeActualDeliverables(e, index)}
                  />
                  {obj.nameError && (
                    <span className="error">Required Field</span>
                  )}
                </div>

                <div className="col">
                  {/* <label
                htmlFor="exampleInputEmail1"
                className="text-secondary"
              >
                Client Cost
              </label> */}
                  <input
                    type="number"
                    className={`form-control ${obj.priceError ? "error-show" : ""
                      }`}
                    placeholder="Client Cost"
                    name="price"
                    value={obj.price}
                    onChange={(e) => handleFunc.handleChangeActualDeliverables(e, index)}
                  />
                  {obj.priceError && (
                    <span className="error">
                      Required Field
                    </span>
                  )}
                </div>

                <div className="col">
                  {/* <label
                htmlFor="exampleInputEmail1"
                className="text-secondary"
              >
                Creator Cost
              </label> */}
                  <input
                    type="number"
                    className={`form-control ${obj.priceCreatorError ? "error-show" : ""
                      }`}
                    placeholder="Creator Cost"
                    name="price_creator"
                    value={obj.price_creator}
                    onChange={(e) => handleFunc.handleChangeActualDeliverables(e, index)}
                  />
                  {obj.priceCreatorError && (
                    <span className="error">
                      Required Field
                    </span>
                  )}
                </div>



                {/* <div className="col">
              <label
                htmlFor="exampleInputEmail1"
                className="text-secondary"
              >
                {`Iteration ${(EditIs && obj.submitted_iteration)
                  ? `/ (Submit Iteration ${obj.submitted_iteration})`
                  : ""
                  }`}
              </label>
              <input
                type="number"
                className={`form-control ${obj.iterationError ? "error-show" : ""
                  }`}
                placeholder="iteration"
                name="iteration"
                value={obj.iteration}
                onChange={(e) => handleFunc.handleChangeActualDeliverables(e, index)}
                disabled={true}
              />

            </div> */}

                <div className="col">
                  <div className="col pt-3">
                    <input
                      type="checkbox"
                      // className={`form-control`}
                      placeholder="tax"
                      name="tax"
                      value={obj.tax}
                      checked={obj.tax}
                      onChange={(e) => handleFunc.handleChangeActualDeliverables(e, index)}
                    // disabled={obj.status === 2 ? true : false}
                    />
                    <label
                      htmlFor="exampleInputEmail1"
                      className="text-secondary ml-2"
                    >
                      GST
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-1 d-flex align-items-center">
                {index !== 0 &&
                  (
                    <span className=" d-flex align-items-center">
                      <i
                        className="fe fe-trash"
                        onClick={() => handleFunc.handleRemoveActualDeliverables(index, obj)}
                      ></i>
                    </span>
                  )}
              </div>
            </div>
          </React.Fragment>
        ))}
        {((deliverablesActual.slice(-1).pop().price != 0) && (deliverablesActual.slice(-1).pop().price_creator != 0) && (deliverablesActual.slice(-1).pop().name) && (statusIs !== 7 && statusIs !== 8 && statusIs !== 10)) && (
          <React.Fragment>
            <div className="col-2">
              <p
                className="mt-3 "
                style={{ color: "#0056b3", cursor: "pointer" }}
                onClick={handleFunc.handleAppendActualDeliverables}
              >
                + Add Milestone
              </p>
            </div>
          </React.Fragment>
        )}
        <hr className="my-5" />

      </React.Fragment>
        :
        <React.Fragment>

          <div className="col-12 col-sm-12 dflex mb-3">
            <div className="w-50">
              <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">
                Terms
              </span>
            </div>
          </div>
          <div className="card" style={{ background: "#f9fbfd" }}>
            {/* <div
    className="card-header"
    id="headingOne"
    >
    Payment Terms                                     
  </div> */}
            <div className='card-header'>
              <table className="table table-sm table-nowrap card-table ">
                <tbody className="list">
                  <tr style={{ borderTop: "hidden" }}>
                    <th className='btn btn-link sort '>
                      Proposed Duration
                    </th>
                    <td className='text-right'>
                      {proposalIs && proposalIs?.proposed_duration} {proposalIs?.proposed_duration > 1 ? ' Months' : ' Month'}
                    </td>
                  </tr>
                  <tr style={{ borderTop: "hidden" }}>
                    <th className='btn btn-link sort'>
                      Preferred Billing Cycle
                    </th>
                    <td className='text-right'>
                      {proposalIs && proposalIs?.preferred_billing_cycle}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr className="my-3" />

          <div className="col-12 col-sm-12 dflex">
            <div className="w-50">
              <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">
                Milestones
              </span>
            </div>
          </div>
          <div className="mb-2">
            <div
              className=" mt-3"
              id="accordionExample"
              style={{ minWidth: "50vw" }}
            >
              {ActualCycleArr && ActualCycleArr.length > 0
                ? ActualCycleArr?.map((obj, index) => {
                  return (
                    <div className="row" key={index}>
                      <div className="card col-11" style={{ background: "#f9fbfd" }}>

                        <div
                          className="card-header"
                          id="headingOne"
                        >

                          <h2 className="mb-0">

                            <button
                              className="btn btn-link btn-block text-left"
                              type="button"
                              style={{ cursor: "default" }}
                            >
                              {/* Cycle #{index + 1} */}

                              Cycle #{index + 1} ({obj?.edit == 'add' ?
                                <DatePicker name="project_deadline_date"
                                  onChange={value => handleEditNewCycleDate(value, 'start')}
                                  minDate={getMinDate('start', obj, index)}
                                  value={obj?.start_date ? new Date(obj?.start_date) : ""} />
                                : obj?.start_date ? moment(obj?.start_date).format("D MMM") : "start date"} -
                              {obj?.edit == 'add' ?
                                <DatePicker onChange={value => handleEditNewCycleDate(value, 'end')}
                                  disabled={!obj.start_date}
                                  value={obj?.end_date ? new Date(obj?.end_date) : ""} 
                                  minDate={getMinDate('end',obj,index)} />
                                : obj?.end_date ? moment(obj?.end_date).format("D MMM") : "end date"})
                              <span
                                className={`badge badge-soft-${obj?.status === 0
                                  ? "secondary"
                                  : obj?.status === 1
                                    ? "warning"
                                    : obj?.status === 2
                                      ? "success"
                                      : ""
                                  } mx-2`}
                              >
                                {obj?.status === 0
                                  ? "Not Started"
                                  : obj?.status === 1
                                    ? "In Progress"
                                    : obj?.status === 2
                                      ? "Paid"
                                      : ""}
                              </span>
                              {
                                <div className="d-flex justify-content-between">
                                  <small className="text-dark orders-order">
                                    Client Pays :
                                    {obj?.price_without_tax
                                      ? "₹ " + obj?.price_without_tax
                                      : " N/A"}
                                  </small>
                                  <small className="text-dark orders-order">
                                    Creator Earning :
                                    {obj?.total_earning
                                      ? "₹ " +
                                      obj?.total_earning
                                      : " N/A"}
                                  </small>

                                  {/* {(obj?.price_without_tax && this.state.projectStatus == 6) ? <div><input type="checkbox" 
                                                checked={obj?.admin_approved_client || obj?.type=='token' || obj?.payment_type==1}
                                                disabled={ obj?.admin_approved_client || obj?.type=='token' || obj?.payment_type==1}
                                                onChange={()=>this.handleUpdatePaymentStatus(obj?._id,index,1)}
                                                />&nbsp;<h5 className="fs-6 d-inline"> MARK AS PAID FROM CLIENT</h5>
                                                </div> : ""}
                                                        {(obj?.price_without_tax && this.state.projectStatus == 6) ? <div><input type="checkbox" 
                                                        checked={obj?.admin_approved || obj?.type=='token'}
                                                disabled={ obj?.status==0 || obj?.admin_approved || obj?.type=='token' }
                                                onChange={()=>this.handleUpdatePaymentStatus(obj?._id,index,2)}
                                                />&nbsp;<h5 className="fs-6 d-inline"> MARK AS PAID TO CREATOR</h5>
                                                </div> : ""}
                                                {(obj?.price_without_tax && this.state.projectStatus == 6 ) &&  <small className="text-dark orders-order">
                                                       { `Paid On: ${
                                                         obj?.payment_date?moment(obj?.payment_date).format("DD/MM/YYYY"):"-"}`}
                                                 </small>} */}
                                </div>
                              }
                            </button>

                          </h2>
                          {/* <button className="btn btn-sm btn-outline-success" onClick={()=>this.handleUpdatePaymentStatus(obj?._id)}>MARK AS PAID TO CREATOR</button> */}
                        </div>
                        <span
                          // style={{ fontSize: "20px", cursor: "pointer", padding:"8px 24px" }}
                          data-toggle="collapse"
                          data-target={`#collapse${index}`}
                          aria-expanded="true"
                          aria-controls={`collapse${index}`}
                          onClick = {()=>{
                            showDeliverable(index)
                          }
                          }
                        >
                         <p className="btn btn-link btn-block text-left m-0  px-5">{obj.show?<><i className="fe fe-chevron-up" />Hide Deliverable</>:<><i className="fe fe-chevron-down" />Show Deliverable</> } </p> 

                        </span>
                        <div
                          id={`collapse${index}`}
                          // className={`collapse ${index === 0 ? "show" : ""}`}
                          // className={`collapse ${obj?.show ? "show" : ""}`}
                          className="collapse"
                          aria-labelledby="headingOne"
                          // data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <div className="table-responsive">
                            <table className="table table-sm table-nowrap card-table">
                              <thead className="mb-2">
                                {renderMilestoneTableHeader()}
                              </thead>
                              <tbody className="list">
                                {obj?.deliverable && renderDeliverables(obj?.deliverable, obj?.edit, index)}
                                {obj?.edit &&
                                  <p
                                    className="mt-3 "
                                    style={{ color: "#0056b3", cursor: "pointer" }}
                                    // onClick={()=>setShowExtendCyclePopup(true)}
                                    onClick={() => handleAppendDeliverables(obj, obj?.edit, index)}
                                  >
                                    + Add Deliverables
                                  </p>
                                }
                              </tbody>
                            </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-1">
                        {!obj.restrict && <>
                          {showSubmitBtn != 'add' && (!editIndex || editIndex == index) && <div className="row">
                            {(editIndex == 0 || editIndex) && editIndex == index ?
                              <span className="col-12" style={{ cursor: "pointer" }} onClick={() => handleEdit(obj, index)}>
                                <i className="fe fe-x-circle" />
                              </span> :
                              <>
                                <span className="col-12" style={{ cursor: "pointer" }}         
                                data-toggle={`${!obj?.show?"collapse":""}`}
                                data-target={`${!obj?.show?`#collapse${index}`:""}`}
                                // aria-expanded="true"
                                aria-controls={`${!obj?.show?`#collapse${index}`:""}`}   
                                               
                                onClick={() => handleEdit(obj, index, 'edit')}

                                >
                                  <i className="fe fe-edit" />
                                </span>
                                <br />
                                {ActualCycleArr.length!==1 &&  <span className="col-12 mt-5" style={{ cursor: "pointer" }} onClick={() => handleDeleteCycle(obj)}>
                                  <i className="fe fe-trash" />
                                </span>}
                              </>}
                          </div>}
                          {showSubmitBtn == 'add' && ActualCycleArr.length - 1 == index && <div className="row">
                            <span className="col-12 mt-5" style={{ cursor: "pointer" }}
                              onClick={() => {
                                let temp = ActualCycleArr.filter(el => el._id !== obj._id)
                                setActualCycleArr(temp)
                                setShowSubmitBtn("")
                                setEditIndex(null)

                              }}
                            >
                              <i className="fe fe-trash" />
                            </span>
                          </div>}
                        </>}
                      </div>
                    </div>
                  );
                })
                : "No Data Found"}
            </div>
          </div>
          <div className="text-center">

            {showEditCycleWarning && <span className="text-center text-danger">
              Please Fill all Details
            </span>}
          </div>

          <React.Fragment>
            {showSubmitBtn ? <div className="col-2">
              <p
                className="btn btn-primary "
                onClick={() => handleValidate() ? showSubmitBtn == 'add' ? handleSubmitAddCycle() : handleSubmitEditCycle() : ""}
              >
                Submit
              </p>
            </div> :
              <div className="col-2">

                <p
                  className="mt-3 "
                  style={{ color: "#0056b3", cursor: "pointer" }}
                  // onClick={()=>setShowExtendCyclePopup(true)}
                  onClick={() => handleAppendCycle()}
                >
                  + Add Cycle
                </p>
              </div>}
          </React.Fragment>

        </React.Fragment>}

      {submitMilesOpen && compMiles && (
        <SubmitMilestone
          submitMilesOpen={submitMilesOpen}
          submitMilesData={submitMilesData}
          compMiles={compMiles}
          submitMilesName={submitMilesName}
          handleMiles={{ handleCloseMilesModal: handleCloseMilesModal }}
          {...props}
        />
      )}

    </>
  )
}
export default MilestoneActual;