import React, { useState } from "react";
import DatePicker from "react-date-picker";
import "react-calendar/dist/Calendar.css";
const CustomDatePicker2 = ({ handleFunc, obj, index, selectedDate }) => {
  const [statusPicker, setStatusPicker] = useState(false);
  // console.log(selectedDate)
  let getMonth = '' 
  if(obj.selectedDate){ 
    getMonth = new Date(obj.selectedDate).getMonth() + 1;
    getMonth = getMonth.toString().padStart(2, "0")
  } else {getMonth = '--'} 

  return (
    <React.Fragment>
      {statusPicker ? (
        <DatePicker
          onChange={(e) => {
            handleFunc.handleMilestoneChange(e, index);
            setStatusPicker(false);
          }}
          onCalendarClose={() => setStatusPicker(false)}
          isOpen={obj.status === 2 ? false :true}
          defaultValue={
            new Date(obj.selectedDate ? obj.selectedDate : selectedDate)
          }
          value={obj.due_date ? new Date(obj.due_date) : ""}
          name="due_date"
          className={`form-control ${obj.dateError ? "error-show" : ""} ${
            obj.status === 2 ? "disabled" : ""
          }`}
          format="d-MM-y"
        //   minDate={new Date((obj.selectedDate)  ? obj.selectedDate : selectedDate)}
          minDate={new Date((obj.selectedDate)  ? obj.selectedDate : selectedDate)}
          disabled={obj.status === 2 ? true : false}
        />
      ) : (
        <div
          className={`react-date-picker react-date-picker--closed react-date-picker--enabled form-control ${obj.status === 2 ? "disabled" : ""}`}
          onClick={() => setStatusPicker(true)}
          disabled={obj.status === 2 ? true : false}
        >
          <div className="react-date-picker__wrapper">
            <div className="react-date-picker__inputGroup">
              <input
                autocomplete="off"
                className="react-date-picker__inputGroup__input react-date-picker__inputGroup__day"
                dataInput="true"
                inputmode="numeric"
                max="30"
                min="3"
                name="day"
                placeholder="--"
                type="text"                
                value={
                  obj.selectedDate
                    ? new Date(obj.selectedDate)
                        .getDate()
                        .toString()
                        .padStart(2, "0")
                    : "--"
                }
                style={{ width: "18px"}}
              />
              <span className="react-date-picker__inputGroup__divider">-</span>
              <span className="react-date-picker__inputGroup__leadingZero"></span>
              <input
                autocomplete="off"
                className="react-date-picker__inputGroup__input react-date-picker__inputGroup__month react-date-picker__inputGroup__input--hasLeadingZero"
                data-input="true"
                inputmode="numeric"
                max="12"
                min="4"
                name="month"
                placeholder="--"
                type="text"
                disabled={obj.status === 2 ? true : false}
                value={getMonth}
                style={{ width: "18px"}}
              />
              <span className="react-date-picker__inputGroup__divider">-</span>
              <input
                autocomplete="off"
                className="react-date-picker__inputGroup__input react-date-picker__inputGroup__year"
                data-input="true"
                inputmode="numeric"
                max="275760"
                min="2021"
                name="year"
                placeholder="----"
                step="1"
                type="text"
                value={
                  obj.selectedDate
                    ? new Date(obj.selectedDate).getFullYear()
                    : "----"
                }
                style={{ width: "40px" }}
              />
            </div>
            <button
              className="react-date-picker__clear-button react-date-picker__button"
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                stroke="black"
                strokeWidth="2"
                className="react-date-picker__clear-button__icon react-date-picker__button__icon"
              >
                <line x1="4" x2="15" y1="4" y2="15"></line>
                <line x1="15" x2="4" y1="4" y2="15"></line>
              </svg>
            </button>

            <button
              className="react-date-picker__calendar-button react-date-picker__button"
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                stroke="black"
                strokeWidth="2"
                className="react-date-picker__calendar-button__icon react-date-picker__button__icon"
              >
                <rect fill="none" height="15" width="15" x="2" y="2"></rect>
                <line x1="6" x2="6" y1="0" y2="4"></line>
                <line x1="13" x2="13" y1="0" y2="4"></line>
              </svg>
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CustomDatePicker2;
