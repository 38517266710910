import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../Common/Loader/Loader";
import { connect } from "react-redux";
import {
  backendDeleteList
} from "../../../store/action/BackendUser.action";
import ReactPaginate from "react-paginate";
import { putAPI } from "../../../store/api-interface";

class BackendDeleted extends Component {
  state = {
    LoadingIs: false,
    currentPage: 1,
    email: "",
    sort: "",
    userName: "",
    userData: [],
    totalPage: "",
    DeleteLoadingIs: false,
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        const { currentPage, email, sort, userName } = this.state;
        const data = {
          page: currentPage,
          email: email,
          sortIs: sort,
          user: userName,
        };
        this.handleBackendUser(data);
      }
    );
  };

  handleSelect = (e, obj) => {
    // console.log(e, obj)
    if(e.value !== obj.manager){
      if(window.confirm(`Confirm to change the manager status of ${obj.first_name} ${obj.last_name}`)){
        putAPI('/backendUser/change-manger-role', {_id: obj._id, role: `${e.value}`})
          .then(res => {
            console.log(res)
            const { currentPage, email, sort, userName } = this.state;
            const data = {
              page: currentPage,
              email: email,
              sortIs: sort,
              user: userName,
            };
            this.handleBackendUser(data);
          }).catch(err => console.log(err))
      }
    }
  }

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    const { email, sort, userName } = this.state;
    const dataIs = {
      page: selectedData,
      email: email,
      sortIs: sort,
      user: userName,
    };
    this.handleBackendUser(dataIs);
  };

  handleBackendUser = (currentData) => {
    this.setState({ LoadingIs: true });
    this.props.$backendDeleteList(currentData);
  };

  componentDidMount() {
    const { currentPage, email, sort, userName } = this.state;
    const data = {
      page: currentPage,
      email: email,
      sortIs: sort,
      user: userName,
    };
    this.handleBackendUser(data);
  }

  componentWillReceiveProps(nextProps) {
    const listIs = nextProps?._backendDeleteList?.backendDeleteList;
    if (listIs) {
      this.setState({
        userData: listIs.users,
        totalPage: listIs.totalPages,
        LoadingIs: false,
      });
    }
  }
  
  callComponentDidMount = () => {
    this.componentDidMount()
  }

  render() {
    const { LoadingIs, userData, email, userName } = this.state;

    const otherDbsArr = [
      { name: "email" },
      { name: "first Name" },
      { name: "last Name" },
      { name: "Username" },
      { name: "Role" },     
    ];

    const searchTable = [
      { placeholder: "Search Email", name: "email", value: email },
      { placeholder: "Search Username", name: "userName", value: userName },
    ];

    // const sortOptions = [
    //   { value: 0, label: 'No' },
    //   { value: 1, label: 'Yes' }
    // ]
    // const sortValue = obj => sortBy === '-1' ? return sortOptions[0] : return sortOptions[1]
    return (
      <React.Fragment>
          <section className="client">
            <div className="container-fluid mx-auto">
              <div className="row col-md-12 mb-5">
                <div className="col">
                  <form className="row align-items-center">
                    <div className="col-auto pr-0">
                      <span className="fe fe-search text-muted"></span>
                    </div>
                    {searchTable.map((obj, index) => (
                      <div className="col" key={index}>
                        <input
                          name={obj.name}
                          type="search"
                          value={obj.value}
                          className="form-control form-control-flush search"
                          placeholder={obj.placeholder}
                          onChange={this.handleOnChange}
                          autoComplete="off"
                        />
                      </div>
                    ))}
                  </form>
                </div>
              </div>
              {LoadingIs ? (
                <Loader />
              ) : (
                <div className="table-responsive">
                  <table className="table table-sm table-nowrap card-table">
                    {/*----------------- table head --------------------*/}
                    <thead>
                      <tr>
                        {otherDbsArr.map((obj, index) => (
                          <th key={index}>
                            <Link to="#!" className="text-muted sort">
                              {obj.name}
                            </Link>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    {/*----------------- table head --------------------*/}
                    <tbody className="list">
                      {userData.length > 0 ? (
                        userData.map((obj, index) => (
                          <tr key={index}>
                            <td className="orders-total">{obj.email}</td>
                            <td className="orders-product">{obj.first_name}</td>
                            <td className="orders-date">{obj.last_name}</td>
                            <td className="orders-total">{obj.username}</td>
                            <td className="orders-status">
                              <div
                                className={`badge ${
                                  obj.role === 0
                                    ? `badge-soft-info`
                                    : `badge-soft-primary`
                                }`}
                              >
                                {obj.role === 0 ? "User" : "Admin"}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>No data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}

              {/****************** Pagination ********************/}

              {this.state.totalPage > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.totalPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </div>
          </section>
      </React.Fragment>
    );
  }
}
const mapState = ({ backendDeleteList }) => {
  return {
    _backendDeleteList:backendDeleteList
  };
};
const mapDispatch = (dispatchEvent) => ({
  $backendDeleteList: (values) => dispatchEvent(backendDeleteList(values)),
});
export default connect(mapState, mapDispatch)(BackendDeleted);
