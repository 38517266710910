import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import {
  // artistApprovalList,
  artistDataList,
  tempResetPwd,
} from "../../../store/action/clientList.action";
import ReactPaginate from "react-paginate";
import ReviewStatusModal from "./ReviewStatusModal";
import ArtistListingTable from "./ArtistListingTable";
import ErrorApi from "../Common/Error/ErrorApi";
import ArtistDetailsForm from "../../Autorized/Artist/ArtistDetailsForm";
import ArtistCollateral from "../../Autorized/Artist/ArtistCollateral";
import ArtistEducation from "../../Autorized/Artist/ArtistEducation";
import ArtistSpeclization from "../../Autorized/Artist/ArtistSpecilization";
import ArtistWorkExperience from "../../Autorized/Artist/ArtistWorkExperience";
import ArtistProject from "../../Autorized/Artist/ArtistProject";
import ArtistUrl from "../../Autorized/Artist/ArtistUrl";
import ArtistOther from "../../Autorized/Artist/ArtistOther";

import Busy from "../../Autorized/Artist/Busy";
import Starred from "./Starred.js";

import ArtistApprovals from "../../Autorized/Artist/ArtistApprovals";
import ApprovedArtist from "../../Autorized/Artist/ApprovedArtist";
import ArtistRejected from "../../Autorized/Artist/ArtistRejected";
import ArtistDeleted from "../../Autorized/Artist/ArtistDeleted";
// import ArtistProfileUpdates from "../../Autorized/Artist/ArtistProfileUpdates";
import ArtistDisabled from "../../Autorized/Artist/ArtistDisabled/ArtistDisabled";
// import { 
//   artistApprovalList,
//   artistBusyList,
//   artistStarredList,
//   approvedArtist, 
//   rejectedArtist,
//   deleteArtist, 
//   disabledArtist 
// } from "../../../store/action/clientList.action";
import ProfileReview from "./ProfileReview";
import ArtistTransaction from "./ArtistTransaction";
import ArtistNotification from "./ArtistNotification";


class Artist extends Component {
  state = {
    statusEmailIs: "",
    createArtistIs: false,
    artistIs: [],
    artistList: 1,
    ListLoading: false,
    artistActive: false,
    showAction: false,
    showActionIndex: "",
    showReviewPopUp: false,
    artistId: "",
    profileReviewStatus: "",
    reviewStatus: false,
    tabCounting: false,
    artistApproval: false,
    resetPwdActive: false,

    firstname: "",
    lastname: "",
    username: "",
    email: "",
    totalPages: 0,
    sortBy: '-1'
  };

  /* -------------set state ------------*/

  setArtistState = (stateObj) => {
    this.setState(stateObj);
  };

  /* -------------set state ------------*/

  /* ----------------------onChange -----------------------*/

  handleOnChange = (e) => {
    const { name, value } = e.target;
    
    this.setState(
      {
        [name]: value,
      },
      () => {
        const { artistList, firstname, lastname, username, email, sortBy } = this.state;
        let artist = {
          page: artistList,
          firstname: firstname,
          lastname: lastname,
          username: username,
          email: email,
          sort: sortBy
        };
        
        this.props.$artistDataList(artist);
      }
    );
  };

  /* ----------------------onChange -----------------------*/

  /*---------------------Pagination ---------------------------*/

  handlePageClick = (data) => {
    let pageSelecetd = data.selected + 1;
    const { firstname, lastname, username, email } = this.state;

    let artist = {
      page: pageSelecetd,
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
    };
    this.props.$artistDataList(artist);
  };

  /*---------------------Pagination ---------------------------*/
  /* -------------Review dropdown show hide ------------*/

  handleShowAction = (index) => {
    const { showAction } = this.state;
    this.setState({
      showAction: !showAction,
      showActionIndex: index,
    });
  };

  handleHideAction = () => {
    this.setState({
      showAction: true,
    });
  };

  /* -------------Review dropdown show hide------------*/

  /* -------------Review accept/reject selection------------*/

  handleReviewStatus = (obj, pStatus) => {
    if (pStatus === "accepted") {
      this.setState({
        profileReviewStatus: 2,
        reviewStatus: true,
      });
    } else if (pStatus === "rejected") {
      this.setState({
        profileReviewStatus: 3,
        reviewStatus: false,
      });
    } else if (pStatus === "onBoarding") {
      this.setState({
        profileReviewStatus: 5,
        reviewStatus: false,
      });
    }
    this.setState({
      showReviewPopUp: true,
      artistId: obj._id,
    });
  };

  /* -------------Review accept/reject selection------------*/

  /*--------------Reset temp Pwd ------------------*/
  handleGetConfirmation(id) {
    let confirmIs = window.confirm(
      `Do you want to reset password temporary (for 15 min) ?`
    );
    if (confirmIs === true) {
      this.setState({
        resetPwdActive: true,
      });
      const tempResetPwd = { _id: id };
      this.props.$tempResetPwd(tempResetPwd);
      return true;
    } else {
      return false;
    }
  }

  handleResetTemproryPassword = (id) => {
    this.handleGetConfirmation(id);
  };
  /*--------------Reset temp Pwd ------------------*/

  /* ------------------get Artist Data -----------------------*/

  handleArtistPagination = () => {
    this.setState({
      ListLoading: true,
    });
    const { artistList, firstname, lastname, username, email } = this.state;
    let artist = {
      page: artistList,
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
    };
    this.props.$artistDataList(artist);
  };

  handleAllApiList = () => {
    let artist = {
      page: 1,
      firstname: "",
      lastname: "",
      username: "",
      email: "",
      sort : ''
    };
    this.props.$artistApprovalList(artist);

    this.props.$artistBusyList(artist);
    this.props.$artistStarredList(artist);

    this.props.$approvedArtist(artist);
    this.props.$rejectedArtist(artist);

    // this.props.$artistUpdateInfo(artist);

    this.props.$deleteArtist(artist);
    this.props.$disabledArtist(artist);
  }

  /* ------------------get Artist Data -----------------------*/

  componentDidMount() {
    this.props.handleClients.handlepathName("/artist");
    this.setState({
      createArtistIs: true,
      artistActive: true,
      artistApproval: false,
    });
    this.handleArtistPagination();
    this.props.handleClients.mainIndexstate({
      profileUpdates: false,
      artistActiveIs: true,
      artistRejected: false,
    });

    // this.handleAllApiList()
  }

  componentWillReceiveProps(nextProps) {
    const { resetPwdActive } = this.state;
    const artistListData = nextProps._artistDataList.artistDataList;
    const tempResetPwdResponse = nextProps._tempResetPwd.tempResetPwd;
    
    if (artistListData) {
      this.setState({
        artistIs: artistListData.users,
        ListLoading: false,
        tabCounting: true,
        totalPages: artistListData.totalPages,
      });
    }
    if (tempResetPwdResponse.status === 1 && resetPwdActive) {
      alert(tempResetPwdResponse.message);
      this.setState({
        resetPwdActive: false,
      });
    }
  }

  render() {
    const { showReviewPopUp } = this.state;

    const artistFunc = {
      handleOnChange: this.handleOnChange,
      handleShowAction: this.handleShowAction,
      handleReviewStatus: this.handleReviewStatus,
      handleResetTemproryPassword: this.handleResetTemproryPassword,
    };
    
    return (
      <React.Fragment>
        {this.props._artistDataList.artistDataErrIs ? (
          <ErrorApi {...this.props} />
        ) : (
          <Switch>
            <Route
              path="/artist/disabled"
              render={(props) => <ArtistDisabled {...props} />}
            />
            {/* <Route
              path="/artist/artistProfileUpdates"
              render={(props) => <ArtistProfileUpdates {...props} />}
            /> */}
            <Route
              path="/artist/artistDeleted"
              render={(props) => <ArtistDeleted {...props} />}
            />
            <Route
              path="/artist/artistRejected"
              // render={(props) => <ArtistRejected {...props} {...this.state} />}
              render={(props) => <ArtistRejected {...props} />}
            />
            <Route
              path="/artist/approvedArtist"
              render={(props) => <ApprovedArtist {...props} />}
              />
            <Route
              path="/artist/busy"
              render={(props) => <Busy {...props} />}
              />
            <Route
              path="/artist/starred"
              render={(props) => <Starred {...props} />}
            />
            <Route
              path="/artist/artistDraft"
              render={(props) => <ArtistApprovals {...props} />}
            />
            <Route
              path="/artist/artistNotification/:id"
              render={(props) => <ArtistNotification {...props} />}
            />
            <Route
              path="/artist/artistOther/:id"
              render={(props) => <ArtistOther {...props} />}
            />
            <Route
              path="/artist/artistUrl/:id"
              render={(props) => <ArtistUrl {...props} />}
            />
            <Route
              path="/artist/artistProject/:id"
              render={(props) => <ArtistProject {...props} />}
            />
            <Route
              path="/artist/artistWorkExperience/:id"
              render={(props) => <ArtistWorkExperience {...props} />}
            />
            <Route
              path="/artist/artistSpeclization/:id"
              render={(props) => <ArtistSpeclization {...props} />}
            />
            <Route
              path="/artist/artistEducation/:id"
              render={(props) => <ArtistEducation {...props} />}
            />
            <Route
              path="/artist/projectReview/:id"
              render={(props) => <ProfileReview {...props}/>}
            />
            <Route
              path="/artist/transactions/:id"
              render={(props) => <ArtistTransaction {...props}/>}
            />
            <Route
              path="/artist/artistCollateral/:id"
              render={(props) => <ArtistCollateral {...props} />}
            />
            <Route
              path="/artist/:type/:id"
              render={(props) => <ArtistDetailsForm {...props} />}
            />
            
            {/*----------------------------artist --------------------------*/}
            <section className="client">
              <div className="container-fluid mx-auto">
                {/*--------------------- Artist data Table ----------------------*/}
                <ArtistListingTable
                  {...this.state}
                  {...this.props}
                  handleArtist={artistFunc}
                />

                {/*------------------------- Pagination -----------------------*/}

                {this.state.totalPages > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                )}
              </div>
            </section>
            {/*----------------------------artist --------------------------*/}
          </Switch>
        )}

        {/*-------------------------------Review Popup ---------------------------------*/}

        {showReviewPopUp && (
          <ReviewStatusModal
            setArtistState={this.setArtistState}
            {...this.state}
          />
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({ artistDataList, tempResetPwd}) => {
  return {
    _artistDataList: artistDataList,
    _tempResetPwd: tempResetPwd,

    // _artistApprovalList: artistApprovalList,
    
    // _artistBusyList: artistBusyList,
    // _artistStarredList:artistStarredList,

    // _approvedArtist : approvedArtist,
    // _rejectedArtist : rejectedArtist,
    // _deleteArtist : deleteArtist,

    // _artistUpdateInfo :artistUpdateInfo,

    // _disabledArtist: disabledArtist
  };
};
const mapDispatch = (dispatchEvent) => ({
  $artistDataList: (values) => dispatchEvent(artistDataList(values)),
  $tempResetPwd: (values) => dispatchEvent(tempResetPwd(values)),

  // $artistApprovalList: (values) => dispatchEvent(artistApprovalList(values)),

  // $artistBusyList: (values) => dispatchEvent(artistBusyList(values)),
  // $artistStarredList: (values) => dispatchEvent(artistStarredList(values)),

  // $approvedArtist: (values) => dispatchEvent(approvedArtist(values)),
  // $rejectedArtist: (values) => dispatchEvent(rejectedArtist(values)),
  // $deleteArtist: (values) => dispatchEvent(deleteArtist(values)),

  // $artistUpdateInfo: (values) => dispatchEvent(artistUpdateInfo(values)),

  // $disabledArtist: (values) => dispatchEvent(disabledArtist(values)),

});
export default connect(mapState, mapDispatch)(Artist);
