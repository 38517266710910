import React, { Component } from "react";

export default class NotFound extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="d-flex align-items-center justify-content-center">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
              <div className="text-center">
                <img
                  src={require("../Assets/img/illustrations/lost.svg")}
                  alt="..."
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
              <div className="text-center">
                <h6 className="text-uppercase text-muted mb-4">404 error</h6>

                <h1 className="display-4 mb-3">
                  There’s no page here{" "}
                  <span role="img" aria-label="">
                    😭
                  </span>
                </h1>

                <p className="text-muted mb-4">
                  Looks like you ended up here by accident?
                </p>

                <a href="/" className="btn btn-lg btn-primary">
                  Return to your dashboard
                </a>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
