import React, { Component } from "react";

export default class DashboardCardInfo extends Component {
  render() {
    const dashboardArr = [
      {
        name: "Budget",
        price: "$24,500",
        offer: "+3.5%",
        icon: "fe-dollar-sign",
      },
      {
        name: "Total Hours",
        price: "763.5",
        icon: "fe-briefcase",
      },
      {
        name: "Progress",
        price: "84.5%",
        icon: "fe-clipboard",
      },
      {
        name: "Cost/Unit",
        price: "$5.50",
      },
    ];

    return (
      <React.Fragment>
        {/*-------------------dashboard card upper info-----------------------------*/}
        {dashboardArr.map((obj, index) => (
          <div className="col-12 col-lg-6 col-xl" key={index}>
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="card-title text-uppercase text-muted mb-2">
                      {obj.name}
                    </h6>
                    {obj.name !== "Progress" && (
                      <span className="h2 mb-0">{obj.price}</span>
                    )}
                    {obj.offer && (
                      <span className="badge badge-soft-success mt-n1">
                        {obj.offer}
                      </span>
                    )}
                    {obj.name === "Progress" && (
                      <div className="row align-items-center no-gutters">
                        <div className="col-auto">
                          <span className="h2 mr-2 mb-0">{obj.price}</span>
                        </div>
                        <div className="col">
                          <div className="progress progress-sm">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "85%" }}
                              aria-valuenow="85"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {obj.icon && (
                    <div className="col-auto">
                      <span
                        className={`h2 text-muted mb-0 fe ${obj.icon}`}
                      ></span>
                    </div>
                  )}
                </div>
                {/* <div className="col-auto">
                  <div className="chart chart-sparkline">
                    <canvas
                      className="chart-canvas"
                      id="sparklineChart"
                    ></canvas>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        ))}
        {/*-------------------dashboard card upper info-----------------------------*/}
      </React.Fragment>
    );
  }
}
