import React, { useEffect, useState } from "react";
import moment from "moment";
import Select from "react-select";
import SubmitMilestone from "../../../Common/ModalCommon/SubmitMilestone";
import NotesModal from "../../../Common/ModalCommon/NotesModal";
import DatePicker from "react-date-picker";
import { addHrMinDate,addDays } from "../../../../../Utils/HelperFunction";
import { addCycleProposal,deleteCycleProposal, getPostProjectData, editCycleProposal } from "../../../../../store/action/projectList.action";
import {useDispatch  } from "react-redux";

const MilestoneRetainer = (props) => {
  const { Gst, EditIs, handleFunc, workingDays, proposed_start_date, proposed_duration, number_of_hours, number_of_hours_type, retainer_fees, retainer_fees_creator, retainer_fees_type, preferred_billing_cycle, errorRetainer } = props
  const workDaysArr = [
    { name: "sunday", label: "S", isActive: false, index: 1, sort: "Sun" },
    { name: "monday", label: "M", isActive: false, index: 2, sort: "Mon" },
    { name: "tuesday", label: "T", isActive: false, index: 3, sort: "Tue" },
    { name: "wednesday", label: "W", isActive: false, index: 4, sort: "Wed" },
    { name: "thursday", label: "T", isActive: false, index: 5, sort: "Thu" },
    { name: "friday", label: "F", isActive: false, index: 6, sort: "Fri" },
    { name: "saturday", label: "S", isActive: false, index: 7, sort: "Sat" },
  ]
  const [workDays, setWorkDays] = useState(workDaysArr);
  const [milestoneRetainer, setMilestoneRetainer] = useState([])
  const [milestoneRetainerAdvance, setMilestoneRetainerAdvance] = useState({})
  const [proposalIs, setProposalIs] = useState({})
  const [submitMilesOpen, setSubmitMilesOpen] = useState(false)
  const [submitMilesData, setSubmitMilesData] = useState({})
  const [compMiles, setCompMiles] = useState([])
  const [submitMilesName, setSubmitMilesName] = useState("")
  const [notesModalOpen, setNotesModalOpen] = useState(false)
  const [notesModalContent, setNotesModalContent] = useState("")
  const [showSubmitBtn,setShowSubmitBtn] = useState("")
  const [showEditCycleWarning, setShowEditCycleWarning] = useState(false)
  const [editObj,setEditObj] = useState({})
  const [editIndex,setEditIndex] = useState(null)
 
  const dispatch = useDispatch();

  const RetainerFeesType = [{ label: "Monthly", value: "Monthly" }, { label: "Hourly", value: "Hourly" }]
  const numberofHoursOption = [{ label: 'Day', value: "Day" }, { label: "Week", value: "Week" }]
  const preferredBillingCycleOption = [{ label: 'Cycle End', value: "Monthly" }, { label: "Month End", value: "Month End" }]

  useEffect(() => {
    if (workingDays.length > 0)
      setWorkDays(workingDays)
  }, [workingDays])

  useEffect(() => {
    if (EditIs) {
      const MilesProposal = props._getPostProjectData.postProjectDataIs?.newProposalList;

      let milestoneRetainer = MilesProposal?.[0]?.milestoneRetainer?.filter((el) => el?.type !== "token")
      let milestoneRetainerAdvance = MilesProposal?.[0]?.milestoneRetainer?.filter((el) => el?.type === "token")

      setMilestoneRetainer(milestoneRetainer)
      setMilestoneRetainerAdvance(milestoneRetainerAdvance)
      setProposalIs(MilesProposal?.[0])
    }
  }, [props._getPostProjectData.postProjectDataIs])

  const handleChooseDays = (index) => {
    workDays[index].isActive = !workDays[index].isActive
    setWorkDays(workDays)
    handleFunc.setWorkingDays(workDays)
  }

  const handleViewMiles = (e, obj, index) => {
    e.preventDefault();
    setSubmitMilesOpen(true)
    setSubmitMilesData(obj)
    // deliverableMiles: String.fromCharCode(index + this.state.milestoneIdx),
    setCompMiles(obj?.milestonecomplete)
    setSubmitMilesName(obj?.name)
    // this.props.$completedMiles(obj._id);
  };

  const handleCloseMilesModal = () => {
    setSubmitMilesOpen(false)
  }

  const closeNotesModal = () => {
    setNotesModalOpen(false)
  }

  const handleAppendCycle = () => {
    setShowSubmitBtn('add')
    setEditIndex(milestoneRetainer.length)
    setMilestoneRetainer([...milestoneRetainer, { end_date: "", price: 0, price_without_tax: 0, start_date: "", tax: 0, total_earning: 0, type: "milestone", edit: "add" }])
  }

  const handleEditNewCycle = (e, value, type) => {
    let tempMilestoneRetainer = [...milestoneRetainer]

    // console.log(e.target, type, tempMilestoneRetainer[tempMilestoneRetainer.length - 1])
    console.log(e.target, type, tempMilestoneRetainer[editIndex])

    // let temp = tempMilestoneRetainer[tempMilestoneRetainer.length - 1]
    let temp = tempMilestoneRetainer[editIndex]

    if (e.target) {

      if (e.target.name) {
        const { name, value } = e.target
        temp[name] = value
      }
      else {
        if (e.target.checked) {
          temp['tax'] = 18
        }
        else {
          temp['tax'] = 0
        }
      }
    } else {
      if (type == 'start')
        temp['start_date'] = new Date(value)
      else {
        temp['end_date'] = new Date(value)
      }
    }

    // tempMilestoneRetainer[tempMilestoneRetainer.length - 1] = temp
    tempMilestoneRetainer[editIndex] = temp
    setMilestoneRetainer(tempMilestoneRetainer)

  }

  const showDeliverable = (index) =>{
    let temp = [...milestoneRetainer]
    temp[index].show = !temp[index]?.show
    setMilestoneRetainer(temp)
  }

  const getMinDate = (type,obj,index)=>{
    if(type=='start'){
      let mindate = ""
      if(index == 0){
        mindate = proposed_start_date
      }
      else{ 
      if(milestoneRetainer[index-1]){
        mindate = milestoneRetainer[index-1].end_date
      }
    }
    return new Date(addDays(mindate,1));

    }
    else{
      let mindate = ""
      if(index == 0){
        if(obj.start_date){
          mindate = obj.start_date
        }
          else {
            mindate = proposed_start_date
          }
      }
      else{
        mindate = obj.start_date
      }
    return new Date(addDays(mindate,1));
    }
  }

  // const getMaxDate = (type,obj,index)=>{
  //   if(type=='start'){

  //   }
  //   else{
      
  //   }
  // }

  const handleEdit = (obj, index,type) => {
    let tempMilestoneRetainer = [...milestoneRetainer]
    if(!type){
      setShowSubmitBtn('')
      setEditIndex(null)
      setEditObj("")
      tempMilestoneRetainer[index].edit = ""
    }else{

      setShowSubmitBtn('edit')
      setEditIndex(index)
      setEditObj(obj)
      tempMilestoneRetainer[index].show = true
      tempMilestoneRetainer[index].edit = 'edit'
    }
    setMilestoneRetainer(tempMilestoneRetainer)
  }

  const handleValidate = () => {
    const editCycle = milestoneRetainer.filter(el => el.edit ? el : false)
    let valid = true
    for (let x of editCycle) {
      if (!x.price_without_tax || !x.total_earning || !x.start_date || !x.end_date) {
        valid = false
        setShowEditCycleWarning(true)
      }
      else {
        setShowEditCycleWarning(false)
        valid = true
      }
    }
    return valid
  }

  const handleSubmitAddCycle = ()=>{

    setShowSubmitBtn('')
    setEditIndex(null)
      let cycle = milestoneRetainer[milestoneRetainer.length-1]

      const data = {
        proposal_id:proposalIs._id,
        post_project_id:proposalIs.post_project_id,
        retainer_fees:cycle.price_without_tax,
        retainer_fees_creator:cycle.total_earning,
        retainer_fees_type:proposalIs.retainer_fees_type,
        tax:cycle.tax?(cycle.price_without_tax*0.18):0,
        amount_client:cycle.price_without_tax,
        amount_creator:cycle.total_earning,
        tax_amount_client:cycle.tax?(cycle.price_without_tax*0.18):0,
        start_date:addHrMinDate(cycle.start_date,5,30),
        end_date:addHrMinDate(cycle.end_date,5,30),
        milestones:[]
      }
      // console.log(data,'add cycle submit')
      dispatch(addCycleProposal(data))
      handleGetPostProjectData()
  } 

  const handleSubmitEditCycle = () =>{
    setShowSubmitBtn("")
    setEditIndex(null)
    const data = {
      _id:editObj._id,
      proposal_id:proposalIs._id,
      proposal_type:proposalIs.proposal_type,
      post_project_id:proposalIs.post_project_id,
      retainer_fees:editObj.price_without_tax,
      retainer_fees_creator:editObj.total_earning,
      retainer_fees_type:proposalIs.retainer_fees_type,
      tax:editObj.tax?(editObj.price_without_tax*0.18):0,
      amount_client:editObj.price_without_tax,
      amount_creator:editObj.total_earning,
      tax_amount_client:editObj.tax?(editObj.price_without_tax*0.18):0,
      // start_date:addHrMinDate(cycle.start_date,5,30),
      // end_date:addHrMinDate(cycle.end_date,5,30),
      milestones:[]
    }
    // console.log(data)
    dispatch(editCycleProposal(data))
    handleGetPostProjectData()

  }

  const handleGetPostProjectData = () =>{
    const projectId = proposalIs.post_project_id
    dispatch(getPostProjectData(projectId))

  }
  const handleDeleteCycle = (obj) =>{
    setShowSubmitBtn("")
    let confirmIs = window.confirm('Do You want to Continue')
    if(confirmIs){
    dispatch(deleteCycleProposal({_id:obj._id}))
    handleGetPostProjectData()
    }
  }

  const renderMilestoneTableHeader = () => {
    let tableHeader = [
      { name: "Duration" },
      { name: "Hours" },
      { name: "Task List" },
      { name: " " },
    ]

    return (
      <tr>
        {tableHeader.map((obj, index) => {
          return (
            <th key={index}>
              <a href="#!" className="text-muted sort">
                {obj.name}
              </a>
            </th>
          );
        })}
      </tr>
    );
  };

  const renderDeliverables = (object) => {
    if (!object.length > 0)
      return (
        <tr>
          <td className="text-center">No Data Found</td>
        </tr>
      );
    return object.map((obj, index) => {
      return (
        <tr key={index}>
          <td className="orders-order">
            {obj?.latestWorkLog?.start_duration &&
              obj?.latestWorkLog?.end_duration &&
              moment(obj?.latestWorkLog?.start_duration).format("DD/MM/YYYY") +
              " - " +
              moment(obj?.latestWorkLog?.end_duration).format("DD/MM/YYYY")}
          </td>
          <td className="orders-order">
            {obj?.latestWorkLog?.number_of_hour_worked_on &&
              obj?.latestWorkLog?.number_of_hour_worked_on + " Hours"}
          </td>
          {/* <td className="orders-order" type="button" data-toggle="tooltip" data-placement="right" title={
         obj?.task_list?.map(item=>item.name)
        }>
          { obj?.task_list?.length>0 && obj?.task_list[0]?.name+`${obj?.task_list?.length>1?"...":""}`}
        
        </td> */}

          <td>
            {obj?.latestWorkLog?.task_list?.length > 0 && (
              <span
                style={
                  (obj?.latestWorkLog?.task_list?.length > 1 || obj?.latestWorkLog?.task_list?.[0]?.name?.length > 30)
                    ? { cursor: "pointer" }
                    : { cursor: "default" }
                }
                onClick={
                  (obj?.latestWorkLog?.task_list?.length > 1 || obj?.latestWorkLog?.task_list?.[0]?.name?.length > 30)
                    ? () => {
                      let temp = obj?.latestWorkLog?.task_list?.map((obj) => obj?.name).join("\n")
                      setNotesModalOpen(true)
                      setNotesModalContent(temp)
                    }
                    : () => setNotesModalOpen(false)
                }
              >
                {obj?.latestWorkLog?.task_list[0]?.name?.length > 30 ? obj?.latestWorkLog?.task_list[0]?.name?.slice(0, 20) + "..." : obj?.latestWorkLog?.task_list[0]?.name + " "}
                {(obj?.latestWorkLog?.task_list?.length > 1 || obj?.latestWorkLog?.task_list?.[0]?.length > 30) && (
                  <span className="fe fe-alert-circle"></span>
                )}
              </span>
            )}
          </td>
          <td className="orders-order">
            {obj?.milestonecomplete?.filter(el => el.allFiles.length > 0 ? el : false)?.length > 0 && (
              <button
                className="btn btn-outline-success btn-sm"
                // disabled
                onClick={(e) => handleViewMiles(e, obj)}
              >
                <i className="fe fe-eye"></i>
              </button>
            )}
          </td>
        </tr>
      );

    });
  };
  return (
    <>
      {!EditIs ? <React.Fragment>
        <label className="text-secondary text-capitalize h3">Time Commitment</label>

        <div className="col-lg-12 px-0 py-3">
          <div className="row pb-4 mx-0 justify-content-between align-items-center">
            <div className="col-xl-7 px-0">
              <p className="font_semi_bold_w_600 mb-0">Proposed Duration</p>
              {/* <p className="text_light_gray_xs mb-0">
                              Indicate how long you will be able to work on this project.{" "}
                            </p> */}
            </div>
            <div className="d-flex col-xl-5 px-0 mob-pt-3 justify-content-end mob-justify-content-unset">
              <div className="btn_field number_btn">
                <div className="btn-group" role="group" aria-label="Basic example">
                  <button type="button border-0" className="btn" onClick={(e) => handleFunc.handleChangeProposedDuration(e, 'dec')}>
                    -
                  </button>
                  <input
                    type="number"
                    className={`form-control text-center input_sm ${errorRetainer.proposed_duration ? "border-danger" : ""}`}
                    value={proposed_duration}
                    onChange={handleFunc.handleChangeProposedDurationInput}
                    disabled
                  />
                  <button type="button border-0" className="btn" onClick={(e) => handleFunc.handleChangeProposedDuration(e, 'inc')}>
                    +
                  </button>
                </div>
                <span>Month</span>
                {errorRetainer.proposed_duration && (
                  <span className="d-block text-center error">Required Field</span>
                )}
              </div>
            </div>

          </div>


          <div className="row pb-4 mx-0 justify-content-between align-items-center">
            <div className="col-xl-7 px-0">
              <p className="font_semi_bold_w_600 mb-0">Number of Hours</p>
              {/* <p className="text_light_gray_xs mb-0">
                              Indicate how long you will be able to work on this project.{" "}
                            </p> */}
            </div>
            <div className="d-flex col-xl-5 px-0 mob-pt-3 justify-content-end mob-justify-content-unset">
              <div className="col-xl-7 px-0">
                <Select
                  options={numberofHoursOption}
                  onChange={handleFunc.handleChangeNumberHoursDropdown}
                  value={{ label: number_of_hours_type, value: number_of_hours_type }}
                />
              </div>
              <div className="btn_field number_btn">
                <div className="btn-group" role="group" aria-label="Basic example">
                  <button type="button border-0" className="btn" onClick={(e) => handleFunc.handleChangeNumberHours(e, 'dec')}>
                    -
                  </button>
                  <input
                    type="number"
                    className={`form-control text-center input_sm ${errorRetainer.number_of_hours ? "border-danger" : ""}`}
                    value={number_of_hours}
                    onChange={handleFunc.handleChangeNumberHoursInput}
                  />
                  <button type="button border-0" className="btn" onClick={(e) => handleFunc.handleChangeNumberHours(e, 'inc')}>
                    +
                  </button>
                </div>
                {errorRetainer.number_of_hours && (
                  <span className="d-block text-center error">Required Field</span>
                )}
              </div>
            </div>
          </div>

          <div className="row pb-4 mx-0 justify-content-between align-items-center">
            <div className="col-xl-7 px-0">
              <p className="font_semi_bold_w_600 mb-0">Working Days in a Week</p>
              {/* <p className="text_light_gray_xs mb-0">
                              Indicate how long you will be able to work on this project.{" "}
                            </p> */}
            </div>
            <div className="col-xl-5">
              <div className="d-flex px-0 mob-pt-3 justify-content-end mob-justify-content-unset">
                {workDays.map((item, i) => {
                  return (
                    <div
                      className={`mr-2 cursor-pointer d-flex justify-content-center align-items-center ${item.isActive ? "text-light" : ""} border ${errorRetainer.workingDays ? "border-danger" : "border-dark"} rounded-circle ${item.isActive ? 'bg-primary' : ""}`}
                      style={{
                        width: "45px",
                        height: "45px",
                        cursor: "pointer",

                      }}
                      onClick={() => handleChooseDays(i)}
                      key={i}
                    >
                      <div
                      >
                        <p className="mb-0">{item.label}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="ml-5 pl-5 mt-2">{errorRetainer.workingDays && (
                <span className="d-block error">Required Field</span>
              )}</div>

            </div>
          </div>
        </div>

        <hr className="my-5" />

        <label className="text-secondary text-capitalize h3">Payment Terms</label>

        <div className="col-lg-12 px-0 py-3">
          <div className="row pb-4 mx-0 justify-content-between align-items-center">
            <div className="col-xl-4 px-0">
              <p className="font_semi_bold_w_600 mb-0">Retainer Fees</p>
              {/* <p className="text_light_gray_xs mb-0">
                              Indicate how long you will be able to work on this project.{" "}
                            </p> */}
            </div>
            <div className="col-xl-8 px-0 mob-pt-3">
              <div className="row">
                <div className="col-3">
                  <div className="btn_field number_btn">
                    <div className="text-center">Client</div>
                    <div className="btn-group" role="group" aria-label="Basic example">
                      <span className="d-flex align-items-center mr-1">₹ {" "}</span>
                      <input
                        type="number"
                        className={`form-control text-center input_sm ${errorRetainer.retainer_fees ? "border-danger" : ""}`}
                        value={retainer_fees}
                        onChange={handleFunc.handleChangeRetainerFees}
                        placeholder='Client'
                      />

                    </div>

                  </div>
                </div>


                <div className="col-2 pt-4">

                  {/* <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={Gst} onChange={handleFunc.handleOnCheckGST} />
                  </div> */}

                  <input
                    type="checkbox"
                    // className={`form-control ${errorIs.Gst ? `error-show` : ``}`}
                    placeholder="Enter Gst"
                    name="Gst"
                    value={Gst}
                    checked={Gst}
                    onChange={handleFunc.handleOnCheckGST}
                  />
                  <label className="mx-3">GST</label>
                  {/* {errorIs.Gst && <span className="error">{errorIs.Gst}</span>} */}
                </div>

                <div className="col-3">
                  <div className="btn_field number_btn">
                    <div className="text-center">Creator</div>
                    <div className="btn-group" role="group" aria-label="Basic example">
                      <span className="d-flex align-items-center mr-1">₹ {" "}</span>
                      <input
                        type="number"
                        className={`form-control text-center input_sm ${errorRetainer.retainer_fees_creator ? "border-danger" : ""}`}
                        value={retainer_fees_creator}
                        onChange={handleFunc.handleChangeRetainerFeesCreator}
                        placeholder='Creator'

                      />

                    </div>
                    {/* {states.validation.prop_dur_num ? <p className="error_message">Field Required</p> : ""} */}
                  </div>
                </div>
                <div className="col-3">
                  <div className="mt-4">
                    <Select
                      options={RetainerFeesType}
                      onChange={handleFunc.handleChangeRetainerFeesType}
                      value={{ label: retainer_fees_type, value: retainer_fees_type }}
                    />
                  </div>
                </div>
              </div>
              <div className="row mx-0 mt-1">
                {(errorRetainer.retainer_fees || errorRetainer.retainer_fees_creator) && (
                  <span className="error">Required Field</span>
                )}
              </div>

            </div>



          </div>

          <div className="row pb-4 mx-0 justify-content-between align-items-center">
            <div className="col-xl-6 px-0">
              <p className="font_semi_bold_w_600 mb-0">preferred Billing Cycle</p>
              {/* <p className="text_light_gray_xs mb-0">
                              Indicate how long you will be able to work on this project.{" "}
                            </p> */}
          </div>
          <div className="d-flex col-xl-6 px-0 mob-pt-3 justify-content-end mob-justify-content-unset">
            <div className="col-xl-5 px-0">
              <Select
                options={preferredBillingCycleOption}
                isDisabled={EditIs}
                onChange={(value) => handleFunc.handleChangePreferredBillingCycle(value, 'retainer')}
                value={{ label: `${preferred_billing_cycle == 'Monthly' ? "Cycle End" : preferred_billing_cycle}`, value:  preferred_billing_cycle }}
              />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
        :
        <React.Fragment>

          <div className="col-12 col-sm-12 dflex mb-3">
            <div className="w-50">
              <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">
                Payment Terms
              </span>
            </div>
          </div>
          <div className="card" style={{ background: "#f9fbfd" }}>
            {/* <div
                                  className="card-header"
                                  id="headingOne"
                                >
                                            Payment Terms                                     
                                </div> */}
            <div className='card-header'>
              <table className="table table-sm table-nowrap card-table">
                <tbody className="list" >
                  <tr>
                    <th className='btn btn-link sort '>
                      Retainer Fees
                    </th>
                    <td className='text-right'> &#x20B9;{" "}
                      {proposalIs && proposalIs?.retainer_fees}
                    </td>
                  </tr>
                  <tr>
                    <th className='btn btn-link sort '>
                      Retainer Fees Type
                    </th>
                    <td className='text-right'>
                      {proposalIs && proposalIs?.retainer_fees_type}
                    </td>
                  </tr>
                  <tr>
                    {proposalIs?.advance_amount ? <th className='btn btn-link sort'>
                      Project Advance - Fees
                    </th> : null}
                    {proposalIs?.advance_amount ? <td className='text-right'> &#x20B9;{" "}
                      {proposalIs?.advance_amount}
                    </td> : null}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr className="my-3" />
          <div className="col-12 col-sm-12 dflex">
            <div className="w-50">
              <span className="d-block font-12 ipad-only-font-11 text-sub font-weight-500">
                Work Log
              </span>
            </div>
          </div>

          <div className="mb-2">
            <div
              className="accordion mt-3"
              id="accordionExample"
              style={{ minWidth: "50vw" }}
            >
              {milestoneRetainerAdvance &&
                milestoneRetainerAdvance.length > 0 &&
                milestoneRetainerAdvance?.map(
                  (obj, index) => {
                    return (
                      <div className="card" key={index} style={{ background: "#f9fbfd" }}>
                        <div
                          className="card-header"
                          id="headingOne"
                        >
                          <h2 className="mb-0">
                            <div
                              className="btn btn-link btn-block text-left"
                              style={{ cursor: "default" }}
                            >
                              Advance Fee
                              <span
                                className={`badge badge-soft-${obj?.status === 0
                                  ? "secondary"
                                  : obj?.status === 1
                                    ? "warning"
                                    : obj?.status === 2
                                      ? "success"
                                      : ""
                                  } mx-2`}
                              >
                                {obj?.status === 0
                                  ? "Not Started"
                                  : obj?.status === 1
                                    ? "In Progress"
                                    : obj?.status === 2
                                      ? "Paid"
                                      : ""}
                              </span>
                              {
                                <div className="d-flex justify-content-between">
                                  <small className="text-dark orders-order">
                                    Client Pays :
                                    {obj?.price_without_tax
                                      ? " ₹ " + obj?.price_without_tax
                                      : " N/A"}
                                  </small>
                                  <small className="text-dark orders-order">
                                    Creator Earning :
                                    {obj?.total_earning
                                      ? " ₹ " +
                                      obj?.total_earning
                                      : " N/A"}
                                  </small>
                                  {/* <div><input type="checkbox"
                                    checked={true}
                                    disabled={true}
                                  />&nbsp;<h5 className="fs-6 d-inline"> MARK AS PAID TO CREATOR</h5></div>
                                  <div><input type="checkbox"
                                    checked={true}
                                    disabled={true}
                                  />&nbsp;<h5 className="fs-6 d-inline"> MARK AS PAID FROM CLIENT</h5></div>
                                  <small className="text-dark orders-order">
                                    Paid On:
                                    {obj?.payment_date ? moment(obj?.payment_date).format("DD/MM/YYYY") : "-"}
                                  </small> */}
                                </div>
                              }
                            </div>
                          </h2>
                        </div>
                      </div>
                    );
                  }
                )}
            </div>

            <div
              className="accordion mt-3"
              id="accordionExample2"
              style={{ minWidth: "50vw" }}
            >
              {milestoneRetainer &&
                milestoneRetainer.length > 0
                ? milestoneRetainer?.map((obj, index) => {
                  return (
                    <div className="row" key={index}>
                      <div className="card col-11"  style={{ background: "#f9fbfd" }}>
                        <div
                          className="card-header"
                          id={`heading${index}`}
                        >
                          <h2 className="mb-0">
                            <button
                              className="btn btn-link btn-block text-left"
                              type="button"
                              style={{ cursor: "default" }}

                              // data-toggle="collapse"
                              // data-target={`#collapse${index}`}
                              // aria-expanded="true"
                              // aria-controls={`collapse${index}`}
                            >
                              {/* Cycle #{index + 1} ({obj?.edit? <DatePicker name="project_deadline_date" onChange={value=>handleEditNewCycle(false,value,'start')} value={obj?.start_date}  minDate={new Date(milestoneRetainer?.[index-1]?.end_date?milestoneRetainer[index-1].end_date:proposed_start_date)}/> :obj?.start_date ? moment(obj?.start_date).format("D MMM"):"start date"} - {obj?.edit? <DatePicker onChange={value=>handleEditNewCycle(false,value,'end')} value={obj?.end_date} minDate={new Date(obj?.start_date)}/> :obj?.end_date?moment(obj?.end_date).format("D MMM"):"end date"}) */}
                              Cycle #{index + 1} ({obj?.edit=='add' ? 
                              <DatePicker name="project_deadline_date" minDate={getMinDate('start',obj,index)} 
                              onChange={value => handleEditNewCycle(false, value, 'start')} 
                              value={obj?.start_date?new Date(obj?.start_date):""} /> 
                              : obj?.start_date ? moment(obj?.start_date).format("D MMM") : "start date"} - 
                              {obj?.edit=='add'? 
                              <DatePicker onChange={value => handleEditNewCycle(false, value, 'end')}
                              disabled={!obj.start_date}
                               value={obj?.end_date?new Date(obj?.end_date):""} 
                               minDate={getMinDate('end',obj,index)}
                               /> 
                               : obj?.end_date ? moment(obj?.end_date).format("D MMM") : "end date"})

                              <span
                                className={`badge badge-soft-${obj?.status === 0
                                  ? "secondary"
                                  : obj?.status === 1
                                    ? "warning"
                                    : obj?.status === 2
                                      ? "success"
                                      : ""
                                  } mx-2`}
                              >
                                {obj?.status === 0
                                  ? "Not Started"
                                  : obj?.status === 1
                                    ? "In Progress"
                                    : obj?.status === 2
                                      ? "Paid"
                                      : ""}
                              </span>
                              {
                                <div className="d-flex justify-content-between">
                                  <small className="text-dark orders-order">
                                    Client Pays :
                                    {(obj?.edit=='add' || obj?.edit=='edit') ? <input type='number' name='price_without_tax' onChange={e => handleEditNewCycle(e)} value={obj?.price_without_tax} /> : obj?.price_without_tax
                                      ? "₹ " + obj?.price_without_tax
                                      : " N/A"}
                                    <span className="mx-4">
                                      <input type='checkbox' disabled={obj?.restrict || !obj?.edit} onChange={e => handleEditNewCycle(e)} checked={obj?.tax} />
                                      <label className="mx-1">GST</label>
                                    </span>
                                  </small>
                                  <small className="text-dark orders-order">
                                    Creator Earning :
                                    {(obj?.edit=='add' || obj?.edit=='edit') ? <input type='number' name='total_earning' onChange={e => handleEditNewCycle(e)} value={obj?.total_earning} /> : obj?.total_earning
                                      ? "₹ " +
                                      obj?.total_earning
                                      : " N/A"}
                                  </small>
                                  {/* {(obj?.price_without_tax && this.state.projectStatus == 6 ) ? 
                                                      <div><input type="checkbox" 
                                                      checked={obj?.payment_type==1 || obj?.type=='token' || obj?.admin_approved_client}
                                                      disabled={ obj?.status==0 || obj?.admin_approved_client || obj?.type=='token' || obj?.payment_type==1}
                                                      onChange={()=>this.handleUpdatePaymentStatus(obj?._id,index,1)}
                                                      />&nbsp;<h5 className="fs-6 d-inline"> MARK AS PAID FROM CLIENT</h5></div> : ""}
                                                      {(obj?.price_without_tax && this.state.projectStatus == 6 ) ? 
                                                      <div><input type="checkbox" 
                                                      checked={obj?.type=='token' ||  obj?.admin_approved}
                                                      disabled={obj?.status==0 || obj?.type=='token' || obj?.admin_approved}
                                                      onChange={()=>this.handleUpdatePaymentStatus(obj?._id,index,2)}
                                                      />&nbsp;<h5 className="fs-6 d-inline"> MARK AS PAID TO CREATOR</h5></div> : ""}
                                                      {(obj?.price_without_tax && this.state.projectStatus == 6 ) &&  <small className="text-dark orders-order">
                                                       { `Paid On: ${
                                                        obj?.payment_date?moment(obj?.payment_date).format("DD/MM/YYYY"):"-"}`}
                                                      </small>} */}
                                </div>
                              }
                            </button>
                            {/* <button className="btn btn-sm btn-outline-success" onClick={()=>this.handleUpdatePaymentStatus(obj?._id)}>MARK AS PAID TO CREATOR</button> */}
                          </h2>
                        </div>
                        <span
                          // style={{ fontSize: "20px", cursor: "pointer", padding:"8px 24px" }}
                      
                          
                          onClick = {()=>{
                            showDeliverable(index)
                          }
                        }
                          data-toggle="collapse"
                          data-target={`#collapse${index}`}
                          aria-expanded="true"
                          aria-controls={`collapse${index}`}
                        >
                        <p className="btn btn-link btn-block text-left m-0  px-5">{obj.show?<><i className="fe fe-chevron-up" /> Hide Deliverable</>:<><i className="fe fe-chevron-down" /> Show Deliverable</> } </p> 

                        </span>
                        <div
                          id={`collapse${index}`}
                          // className={`collapse ${index === 0 && "show" }`}
                          // className={`collapse ${obj?.show ? "show" : ""}`}
                          className="collapse"
                          aria-labelledby={`heading${index}`}
                          // data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <table className="table table-sm table-nowrap card-table">
                              <thead className="mb-2">
                                {renderMilestoneTableHeader()}
                              </thead>
                              <tbody className="list">
                                {obj?.deliverable && renderDeliverables(obj?.deliverable)}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-1">
                        {!obj.restrict && <>
                        {/* {!obj.restrict && retainer_fees_type.toLowerCase()=='monthly' && showSubmitBtn!='add' && (!editIndex || editIndex == index) && <div className="row">                       */}
                        {!obj.restrict  && showSubmitBtn!='add' && (!editIndex || editIndex == index) && <div className="row">                      
                          {(editIndex==0 || editIndex) && editIndex == index ?
                           <span className="col-12" style={{ cursor: "pointer" }} onClick={() => handleEdit(obj, index)}>
                           <i className="fe fe-x-circle" />
                         </span> : 
                            <>
                          <span className="col-12" style={{ cursor: "pointer" }} onClick={() => handleEdit(obj, index,'edit')}>
                            <i className="fe fe-edit" />
                          </span>
                          <br />
                          {milestoneRetainer.length!==1 && <span className="col-12 mt-5" style={{ cursor: "pointer" }} onClick={() => handleDeleteCycle(obj)}>
                            <i className="fe fe-trash" />
                          </span>}
                          </>}
                        </div>}
                        {showSubmitBtn=='add' && milestoneRetainer.length-1 == index && <div className="row">
                          <span className="col-12 mt-5" style={{ cursor: "pointer" }} 
                          onClick={() => {
                            let temp = milestoneRetainer.filter(el=>el._id!==obj._id)
                            setMilestoneRetainer(temp)
                            setShowSubmitBtn("")
                            setEditIndex(null)
                          }}
                          >
                            <i className="fe fe-trash" />
                          </span>
                        </div>}
                      </>}
                      </div>
                    </div>
                  );
                })
                : "No Data Found"}
            </div>
          </div>
          <div className="text-center">

            {showEditCycleWarning && <span className="text-center text-danger">
              Please Fill all Details
            </span>}
          </div>

          {/* {retainer_fees_type.toLowerCase()=='monthly' &&  */}
          <React.Fragment>
            {showSubmitBtn ? 
            <div className="col-2">
            <p
              className="btn btn-primary "
              onClick={() => handleValidate() ? showSubmitBtn=='add'? handleSubmitAddCycle() : handleSubmitEditCycle() : ""}
            >
              Submit
            </p>
          </div>
            :
            <div className="col-2">
              <p
              
                className="mt-3 "
                style={{ color: "#0056b3", cursor: "pointer" }}
                // onClick={()=>setShowExtendCyclePopup(true)}
                onClick={() => handleAppendCycle()}
              >
                + Add Cycle
              </p>
            </div> 
              
              }
          </React.Fragment>
          {/* } */}

        </React.Fragment>}

      {notesModalContent && (
        <NotesModal
          notesModalOpen={notesModalOpen}
          closeNotesModal={closeNotesModal}
          notesModalContent={notesModalContent}
        />
      )}

      {submitMilesOpen && compMiles && (
        <SubmitMilestone
          submitMilesOpen={submitMilesOpen}
          submitMilesData={submitMilesData}
          compMiles={compMiles}
          submitMilesName={submitMilesName}
          handleMiles={{ handleCloseMilesModal: handleCloseMilesModal }}
          {...props}
        // submitMilesOpen={this.state.submitMilesOpen}
        />
      )}
    </>
  )
}
export default MilestoneRetainer;