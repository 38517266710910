import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../Common/Loader/Loader";
import { connect } from "react-redux";
import { userDelete } from "../../../store/action/clientList.action";
import Select from "react-select";
import DeleteArtistModal from "./DeleteArtistModal";
import StarredModal from "./StarredModal";
import { postAPI } from "../../../store/api-interface"; 
import Tooltip from "react-tooltip-lite";
import NotesModal from "../Common/ModalCommon/NotesModal";
import moment from "moment";
import { WebsiteUrl } from "../../../Utils/BaseUrl";

class ArtistListingTable extends Component {
  state = {
    loadingIs: false,
    id: "",
    UnOrblacklistdelModal: false,
    types: "",
    starredModIs: false,
    collateralCheckList: [],
    starId: "",
    star_description:"",
    notesModalOpen:false,
    notesModalContent:""
  };

  closeNotesModal = () =>{
    this.setState({
      notesModalOpen: false,
    })
  }

  handleUnOrBlacklistDelArtist = async (reasonIs) => {
    if (reasonIs || this.state.types === "unblacklist") {
      this.setState({
        loadingIs: true,
      });
      if (this.state.types === "delete") {
        const data = { _id: this.state.id, status: 2 };
        const dataDel = { user_id: this.state.id,type:"user",reason:reasonIs, status: 2 };
        this.props.$userDelete(data);
        this.handleReasonForDisable(dataDel)
      } else if (this.state.types === "blacklist") {
        const currentData = { _id: this.state.id, status: 3 };
        const blacklistData = { user_id: this.state.id, status: 3,type:"user",reason:reasonIs, };
        this.props.handleArtist.handleDisabledEnableArtist(currentData);
        this.handleReasonForDisable(blacklistData)
      } else if (this.state.types === "unblacklist") {
        const curData = {
          _id: this.state.id,
          status: 1,
        };
        this.props.handleArtist.handleDisabledEnableArtist(curData);
         this.setState({
          loadingIs: false,
          UnOrblacklistdelModal:false
        })
      }
      return true;
    } else {
      return false;
    }
  }

  handleReasonForDisable = (data) => {
    postAPI("/users/add-reason-for-disable", data)
      .then((res) => {
        this.setState({
          loadingIs: false,
          UnOrblacklistdelModal:false
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  handleUnOrblacklistdelStatus = () => {
    this.setState({UnOrblacklistdelModal:false})
  }

  // getReviewStatus = (reviewStatus) => {
  //   const reviewArr = [
  //     { status: 0, textColor: "text-warning", text: "Incomplete" },
  //     { status: 1, textColor: "text-info", text: "Approval Pending" },
  //     { status: 2, textColor: "text-success", text: "Available" },
  //     { status: 3, textColor: "text-danger", text: "Rejected" },
  //   ];

  //   const currentArr = reviewArr?.find((el) => el.status === reviewStatus);
  //   return (
  //     <React.Fragment>
  //       <span className={`mr-2 ${currentArr.textColor} `}>●</span>
  //       {currentArr.text}
  //     </React.Fragment>
  //   );
  // };

  getConfirmationStatus = (obj, status) => {
    let confirmIs = window.confirm(`Do you want to countinue ?`);
    if (confirmIs === true) {
      const currentData = { _id: obj._id, status: status, available_date: "" };
      this.props.handleArtist.handleArtistBusyOrNot(currentData);
      return true;
    } else {
      return false;
    }
  };

  // getArtistStatus = (obj) => {
  //   return (
  //     <React.Fragment>
  //       <span>
  //         <div
  //           className="badge badge-soft-secondary mr-2"
  //           style={{ cursor: "pointer" }}
  //           onClick={() =>
  //             this.getConfirmationStatus(obj, obj.available === 1 ? 0 : 1)
  //           }
  //         >
  //           {obj.available === 1 ? "Busy" : "Available"}
  //         </div>
  //         <div className="badge badge-soft-primary mr-2 disable">{`${
  //           obj.available === 1 ? `Available` : `Busy`
  //         }`}</div>
  //       </span>
  //       <br />
  //       {obj.available === 0 && obj?.available_date && (
  //         <span className="text-center">
  //           {`Available on : ${obj?.available_date.split("T").slice(0)[0]}`}
  //         </span>
  //       )}
  //     </React.Fragment>
  //   );
  // };

  // getDisableEnableConfirmation = (obj, status) => {

  //   let confirmIs = window.confirm(`Do you want to countinue ?`);
  //   if (confirmIs === true) {
  //     const currentData = {
  //       _id: obj._id,
  //       status: status === "Disable" ? 3 : 1,
  //     };
  //     this.props.handleArtist.handleDisabledEnableArtist(currentData);
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // getArtistDisabled = (obj, status) => {
  //   // console.log("123344",obj)
  //   return (
  //     <div
  //       className="badge badge-soft-info mr-2"
  //       style={{ cursor: "pointer" }}
  //       onClick={() => this.getDisableEnableConfirmation(obj, status)}
  //     >
  //      {status}
  //     </div>
  //   );
  // };

  componentWillReceiveProps(nextProps) {
    const deleteStatus = nextProps._userDelete.userDeletedIs;
    const { loadingIs } = this.state;
    if (deleteStatus && loadingIs) {
      alert(deleteStatus.message);
      this.setState({
        loadingIs: false,
      });
    }
  }

  handleCallApi = () => {
     this.props.handleArtist.handlePageClickNew()
  }

  renderCollforApprovedArtist = (unique) => {
    let filter = []
    if (unique) {
       filter = unique.filter(item => item.status==1||item.status==0)
    }
    return (
      filter?.length > 0 ?
        this.renderDropdownforApprovedArtist('See Colleterals', filter)
        : <p>None</p>
    )
  }

  renderDropdownforApprovedArtist = (title, listArr) => {
    return (
      <div className="dropdown">
        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {title}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{maxHeight: 300, overflowY: 'auto'}}>
          {listArr.map((value, idx) => {
            return (
              value.status==0?<span key={idx} className="dropdown-item" style={{color:'red'}}>{title === "See Colleterals" || title === "See Colleterals Starred" ? value.collateral_name : value.name}</span>    
              :<>
              {value?.star_description === "" ?
              <>
              <span key={idx} className="dropdown-item" >{title === "See Colleterals" || title === "See Colleterals Starred" ? value.collateral_name : value.name}</span>
              </>:
              <Tooltip                
                content={(
                    <div  >
                      <div>{`${value?.starByData ? `By ` : ""}`}{value?.starByData ? `(${value?.starByData?.first_name + " " + value?.starByData?.last_name})` : ""}</div>
                      {value?.starByData ? <hr/> : ""}
                      <div>{value?.star_description}</div>
                    </div>
                )}
                direction="down"
                tagName="span"
                className="target"
                tipContentClassName=""
                styles={{cursor : "pointer"}}
              >
              <span key={idx} className="dropdown-item" >{title === "See Colleterals" || title === "See Colleterals Starred" ? value.collateral_name : value.name}</span>
            </Tooltip>}
              </>
              )
          })}
        </div>
      </div>
    )
  }

  renderColl = (unique) => {
    let filter = []
    if (unique) {
       filter = unique.filter(item => item.status==1||item.status==0)
    }
    return (
      filter?.length > 0 ?
        this.renderDropdown('See Colleterals', filter)
        : <p>None</p>
    )
  }

  renderCollStarred = (unique, starredData) => {
    let filter = []
    if (unique) {
       filter = unique.filter(item => item.status == 1)
    }
    return (
      filter?.length > 0 ?
        this.renderStarredDropdown('See Colleterals Starred', filter, starredData)
        : <p>None</p>
    )
  }

  renderStarredDropdown = (title, listArr, starredData) => {
    return (
      <div className="dropdown">
        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {title}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{maxHeight: 300, overflowY: 'auto'}}>
          {listArr.map((value, idx) => {
            return (
              value.status==0
              ?
              <span key={idx} className="dropdown-item" style={{color:'red'}}>{title === "See Colleterals" || title === "See Colleterals Starred" ? value.collateral_name : value.name}</span>    
              :<>
              {value?.star_description === "" ?
              <>
                <span key={idx} className="dropdown-item" >{title === "See Colleterals" || title === "See Colleterals Starred" ? value.collateral_name : value.name}</span>
                </>:
                <Tooltip                
                    content={(
                        <div  >
                          <div>{`${value?.starByData ? `By ` : ""}`}{value?.starByData ? `(${value?.starByData?.first_name + " " + value?.starByData?.last_name})` : ""}</div>
                          {value?.starByData ? <hr/> : ""}
                          <div>{value?.star_description}</div>
                        </div>
                    )}
                    direction="down"
                    tagName="span"
                    className="target"
                    tipContentClassName=""
                    styles={{cursor : "pointer"}}
                    >
                    <span key={idx} className="dropdown-item" >{title === "See Colleterals" || title === "See Colleterals Starred" ? value.collateral_name : value.name}</span>
                </Tooltip>
                }
              </>  
              )
          })}
        </div>
      </div>
    )
  }

  renderSpec = (unique) => {
    let uniqueArr = []
    if (unique) {
      let arr = [...unique?.specialization, { name: unique?.primary_specialization }]
      const key = 'name';
      uniqueArr = [...new Map(arr.map(item => [item[key], item])).values()];
      // console.log("uniqueArr",uniqueArr);
    }
    return (
      uniqueArr.length > 0 ?
        this.renderDropdown('See Specialization', uniqueArr)
        : <p>None</p>
    )
  }

  renderDropdown = (title, listArr) => {
    return (
      <div className="dropdown">
        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {title}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{maxHeight: 300, overflowY: 'auto'}}>
          {listArr.map((value, idx) => {
            return (
              value.status==0?<span key={idx} className="dropdown-item" style={{color:'red'}}>{title === "See Colleterals" || title === "See Colleterals Starred" ? value.collateral_name : value.name}</span>    
              :<span key={idx} className="dropdown-item" >{title === "See Colleterals" || title === "See Colleterals Starred" ? value.collateral_name : value.name}</span>    
              )
          })}
        </div>
      </div>
    )
  }

  getDateTime = (end, start,i) => {
    let result = ""
    var diff = ""
    if (!end || !start) {
      result = "No Brief"
    } else {
      let createAt = new Date()
      let approveAt = new Date(start)
      // console.log("2 = ",createAt,approveAt)
      diff = (createAt.getTime() - approveAt.getTime()) / 1000;
      diff /= (60 * 60);
      if (diff < 0) {
        result = "No Brief"
      } else{
        let rest = Math.abs(Math.round(diff))
        let res = Math.round(rest / 24)
        if (res == 0) {
          result = "today"
        } else if (res > 0 && res <= 1) {
          result = res + " day"
        } else {
          result = res + " days"
        }
      }
    }
    return result;
  }

  handleCloseModal = () => {
    this.setState({starredModIs:false})
  }

  handleWordSize = (value) => {
    return value ? value?.length > 30 ? value?.slice(0,30) + "..." : value : ""
  }

  handleStarModal = (id, list) => {
    let filter = list.filter(item => item.status == 1)
    this.setState({
      starId:id,
      collateralCheckList: filter,
      starredModIs: true,
    })
  }

  getStage = (obj) => {
    const valueIs = obj?.email_status === 0 ?
    "Sign up" :
    obj?.phone_status === 0 ?
    "Sign up" :
    obj?.review_status === 0 ?
    "Not apply for onboarding":
    obj?.review_status === 4 ?
    "Waiting for onboarding approval" :
    ((obj?.review_status === 5) && (obj?.onboarding_status === 0)) ?
    "Onboarding Approved" :
    obj?.onboarding_status === 0 ?
    "On onboarding" :
    obj?.onboarding_status === 1?
    "Basic Details" : 
    obj?.onboarding_status === 2 ?
    "Speclization" :
    obj?.onboarding_status === 3 ?
    "Collateral" :
    obj?.onboarding_status === 4 ?
    "Work experience":
    obj?.onboarding_status === 5 ?
    "Education" :
    obj?.onboarding_status === 6 ? 
    "Web links":
    ""
     
    return valueIs
  }

  render() {
    const {
      artistIs,
      ListLoading,
      firstname,
      lastname,
      username,
      email,
      handleArtist,
      profileUpdates,
      // artistRejected,
      sortBy,
      // artistIsNew
    } = this.props;

    const searchTable = [
      { placeholder: "Search firstname", name: "firstname", value: firstname },
      { placeholder: "Search lastname", name: "lastname", value: lastname },
      { placeholder: "Search username", name: "username", value: username },
      { placeholder: "Search email", name: "email", value: email },
    ];

    const pathIs = this.props.location.pathname;

    const tableContentArr = [
      { name: "Action", show: true},
      { name: "Name", show: true },
      { name: "Email ID", show: true },
      { name: "Onboarding Access Provided", show: pathIs === "/artist" ? true : false },
      { name: "Phone no.", show: true },

      { name: "Rejected By", show: pathIs === "/artist/artistRejected" ? true : false },
      { name: "Rejection Note", show: pathIs === "/artist/artistRejected" ? true : false },

      { name: "Busy Till", show: pathIs === "/artist/busy" ? true : false },
      { name: "Marked Busy By", show: pathIs === "/artist/busy" ? true : false },

      { name: "Blacklist Reason", show: pathIs === "/artist/disabled" ? true : false },
      { name: "Blacklisted By", show: pathIs === "/artist/disabled" ? true : false },

      { name: "Deleted By", show: pathIs === "/artist/artistDeleted" ? true : false },
      { name: "Deletion Note", show: pathIs === "/artist/artistDeleted" ? true : false },
      { name: "Pre-Deletion Stage", show: pathIs === "/artist/artistDeleted" ? true : false },

      { name: "Username", show: true },
      { name: "Collaterals-Starred", show: pathIs === "/artist/starred" ? true : false },
      { name: "Collaterals (All Offered)", show: pathIs === "/artist/starred" ? true : false },


      { name: "Collaterals (provide list)", show: pathIs === "/artist/artistDeleted" || pathIs === "/artist/artistRejected" || pathIs === "/artist/artistDraft" || pathIs === "/artist/busy" || pathIs === "/artist/approvedArtist" || pathIs === "/artist/disabled" ? true : false },
      { name: "Specialization (provide list)", show: pathIs === "/artist/artistDeleted" || pathIs === "/artist/artistRejected" || pathIs === "/artist/starred" || pathIs === "/artist/artistDraft" || pathIs === "/artist/busy" || pathIs === "/artist/approvedArtist" || pathIs === "/artist/disabled" ? true : false },
      { name: "Location", show: pathIs !== "/artist" ? true : false },
      { name: "Email verified", show: pathIs === "/artist" ? true : false },
   
      { name: "Number verified", show: pathIs === "/artist" ? true : false },
      { name: "Stage", show: pathIs === "/artist" ? true : false },
      { name: "Work Status", show: pathIs !== "/artist" ? true : false },

      { name: "Brief Response Time (Overall)", show: pathIs === "/artist/artistDeleted" || pathIs === "/artist/starred" || pathIs === "/artist/approvedArtist" || pathIs === "/artist/busy" || pathIs === "/artist/disabled" ? true : false }, 
      { name: "Brief Response Time (3 Months)", show: pathIs === "/artist/artistDeleted" || pathIs === "/artist/starred" || pathIs === "/artist/approvedArtist" || pathIs === "/artist/busy" || pathIs === "/artist/disabled" ? true : false }, 
      { name: "Time Since Last Brief(In Days)", show: pathIs === "/artist/approvedArtist" || pathIs === "/artist/busy" ? true : false },
      { name: "Total Briefs Received", show: pathIs === "/artist/artistDeleted" || pathIs === "/artist/starred" || pathIs === "/artist/approvedArtist" || pathIs === "/artist/busy" || pathIs === "/artist/disabled" ? true : false },
      { name: "Total Active Briefs", show: pathIs === "/artist/artistDeleted" || pathIs === "/artist/starred" || pathIs === "/artist/approvedArtist" || pathIs === "/artist/busy" || pathIs === "/artist/disabled" ? true : false },
      { name: "Total Active Projects", show: pathIs === "/artist/artistDeleted" || pathIs === "/artist/starred" || pathIs === "/artist/approvedArtist" || pathIs === "/artist/busy" || pathIs === "/artist/disabled" ? true : false },
      { name: "Total Completed Projects", show: pathIs === "/artist/artistDeleted" || pathIs === "/artist/starred" || pathIs === "/artist/approvedArtist" || pathIs === "/artist/busy" || pathIs === "/artist/disabled" ? true : false },
      { name: "Approved By", show: pathIs === "/artist/starred" || pathIs === "/artist/approvedArtist" || pathIs === "/artist/busy" || pathIs === "/artist/disabled" ? true : false },

      { name: "Deleted On", show: pathIs === "/artist/artistDeleted" ? true : false },
      { name: "Signed Up On", show: true },
      { name: "Last Active On", show: pathIs !== "/artist/artistDeleted" ? true : false },

      { name: "Created from", show: true },
      { name: "Starred On", show: pathIs === "/artist/starred" ? true : false },
  
      // { name: "Review Status", show: true },
      // {
      //   name: "Artist Status",
      //   show: pathIs === "/artist/approvedArtist" ? true : false,
      // },
      // {
      //   name: "Disable Artist",
      //   show: pathIs === "/artist/approvedArtist" ? true : false,
      // },
      // {
      //   name: "Enable Artist",
      //   show: pathIs === "/artist/disabled" ? true : false,
      // },
    
    ];

    const sortOptions = [
      { value: '-1', label: 'Sorting by latest first' },
      { value: '1', label: 'Sorting by oldest first' }
    ]
    const sortValue = sortBy === '-1' ? sortOptions[0] : sortOptions[1]
    
    const showSorting = pathIs === "/artist/approvedArtist" || pathIs === '/artist/artistDraft'

    return (
      <React.Fragment>
        {/*------------------Search ---------------------*/}
        <div className="row col-md-12">
          <div className="col">
            <form className="row align-items-center">
              <div className="col-auto pr-0">
                <span className="fe fe-search text-muted"></span>
              </div>
              {searchTable.map((obj, index) => (
                <div className="col" key={index}>
                  <input
                    name={obj.name}
                    type="search"
                    value={obj.value}
                    className="form-control form-control-flush search"
                    placeholder={obj.placeholder}
                    onChange={handleArtist.handleOnChange}
                    autoComplete="off"
                  />
                </div>
              ))}
              {showSorting && <div className="col">
                <Select 
                  options={sortOptions} 
                  onChange={obj => handleArtist.handleOnChange({target: {name: 'sortBy', value: obj.value}})}
                  value={sortValue}
                />
              </div>}
            </form>
          </div>
        </div>
        {/*------------------Search ---------------------*/}

        {ListLoading ? (
          <Loader />
        ) : (
          <div className="table-responsive mb-5 pb-5">
            <table className="table table-sm table-nowrap card-table" style={{minHeight:"350px"}}>
              {/*-----------------table head -------------------------*/}

              <thead>
                <tr>
                  {tableContentArr.map((obj, index) => {
                    return (
                      obj.show && (
                          <th key={index}>
                            <Link to="#!" className="text-muted sort">
                              {obj.name}
                            </Link>
                          </th>
                      )
                    );
                  })}
                </tr>
              </thead>

              {/*-----------------table head -------------------------*/}
              <tbody className="list">
                {artistIs.map((obj, index) => (
                  <tr key={index}>
                    {/*----------------------------------dropdown ------------------------------*/}
                    {pathIs !== "/artist/starred" && (
                      <td className="text-center">
                        <div className="d-flex align-items-center">
                          {(pathIs === "/artist/approvedArtist" || pathIs === "/artist/starred") &&
                            <div className={`stars-rating ${obj?.star ? obj.star == "0" ? "" : "active" : ""}`} style={{ lineHeight: "0" }} >
                              <input type="radio" id={`${index + "star"}`} name="rating" onClick={() => this.handleStarModal(obj?._id,obj?.userCollateral)} />
                              <label htmlFor={`${index + "star"}`} className="mb-0 star">&#9733;</label>
                            </div>
                          }
                          <div className={`dropdown`}>
                            <a
                              href="#!"
                              className="dropdown-ellipses dropdown-toggle text-secondary"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="fe fe-more-vertical"></i>
                            </a>
                              <div
                                className={`ma-auto dropdown-menu dropdown-menu-right text-center`}
                              >
                                <span>
                                  {pathIs === "/artist/artistRejected" &&
                                    <Link
                                      to="#!"
                                      className="dropdown-item"
                                      datatoggle="modal"
                                      datatarget="#exampleModalCenter"
                                      onClick={() =>
                                        handleArtist.handleReviewStatus(
                                          obj,
                                          "appPending"
                                        )
                                      }
                                    >
                                      Move to "Approval Pending"
                                    </Link>
                                }
                                  {pathIs === "/artist/artistDraft" &&
                                    <Link
                                      to="#!"
                                      className="dropdown-item"
                                      datatoggle="modal"
                                      datatarget="#exampleModalCenter"
                                      onClick={() =>
                                        handleArtist.handleReviewStatus(
                                          obj,
                                          "accepted"
                                        )
                                      }
                                    >
                                      Accept
                                    </Link>
                                  }
                                  {pathIs === "/artist/artistDraft" &&
                                    <Link
                                      to="#!"
                                      className="dropdown-item mb-2"
                                      onClick={() =>
                                        handleArtist.handleReviewStatus(
                                          obj,
                                          "rejected"
                                        )
                                      }
                                    >
                                      Reject
                                    </Link>
                                  }
                                  </span>
                              
                                {(pathIs === "/artist" && (obj?.review_status == 4 || obj?.review_status == 0) )&& (
                                  <Link
                                    to="#!"
                                    className="dropdown-item"
                                    onClick={() =>
                                        handleArtist.handleReviewStatus(
                                          obj,
                                          "onBoarding"
                                        )
                                      }
                                  >
                                    Give Access to Onboarding
                                  </Link>
                              )}
                              {(pathIs === "/artist/busy" || pathIs === "/artist/approvedArtist") && (
                                  <Link
                                    to="#!"
                                    className="dropdown-item"
                                    datatoggle="modal"
                                    datatarget="#exampleModalCenter"
                                    onClick={() =>this.getConfirmationStatus(obj, obj.available === 1 ? 0 : 1) }
                                  >
                                    {obj.available === 1 ? "Mark As Busy" : "Mark As Available"}
                                  </Link>
                              )}
                                {(pathIs === "/artist/disabled" || pathIs === "/artist/approvedArtist" || pathIs === "/artist/busy") && (
                                  <Link
                                    to="#!"
                                    className="dropdown-item"
                                    datatoggle="modal"
                                    datatarget="#exampleModalCenter"
                                    onClick={() => this.setState({ id: obj._id ,types: pathIs === "/artist/disabled" ? "unblacklist" : "blacklist", UnOrblacklistdelModal:true}) }
                                  >
                                    {pathIs === "/artist/disabled" ? "Remove From Blacklist" : "Blacklist"}
                                  </Link>
                              )}
                                {/******************Reset Temp Password ******************** */}
                                <span>
                                  <Link
                                    to="#!"
                                    className="dropdown-item mb-2"
                                    datatoggle="modal"
                                    datatarget="#exampleModalCenter"
                                    onClick={() =>
                                      handleArtist.handleResetTemproryPassword(
                                        obj._id
                                      )
                                    }
                                  >
                                    Reset Password
                                  </Link>
                                </span>
                                {/******************Reset Temp Password ******************** */}
                                <Link to={`/artist/:view/:${obj._id}`}>
                                  <button className="btn btn-outline-success btn-sm mr-2">
                                    <i className="fe fe-eye"></i>
                                  </button>
                                </Link>
                                <Link
                                  to={`${
                                    profileUpdates
                                      ? `/artist/:updatesProfile/:${obj._id}`
                                      : `/artist/:edit/:${obj._id}`
                                  }`}
                                >
                                  <button className="btn btn-outline-info btn-sm mr-2">
                                    <i className="fe fe-edit"></i>
                                  </button>
                                </Link>
                                {this.props.location.pathname!=='/artist/artistDeleted' && <Link to="#!">
                                  <button
                                    className="btn btn-outline-danger btn-sm"
                                    onClick={() => this.setState({ id: obj._id ,types: "delete", UnOrblacklistdelModal:true}) }
                                  >
                                    <i className="fe fe-trash"></i>
                                  </button>
                                </Link>}
                                
                              </div>
                            {/* )} */}
                          </div>
                        </div>
                      </td>
                    )}
                    {pathIs === "/artist/starred" && (
                      <td className="">
                        <div className={`stars-rating ${obj?.star ? obj.star == "0" ? "" : "active" : ""}`} style={{ lineHeight: ".9" }}>
                          <input type="radio" id={`${index + "star"}`} name="rating" onClick={() => this.handleStarModal(obj?._id, obj?.userCollateral)} />
                          <label htmlFor={`${index + "star"}`} className="star">&#9733;</label>
                        </div>
                      </td>
                    )}
                    {/*----------------------------------dropdown ------------------------------*/}
                    <td className="orders-order">
                      <Link
                        to={`/artist/:view/:${obj._id}`}
                        // style={{ color: "black" }}
                      >
                         {this.handleWordSize((obj?.first_name+" "+obj?.last_name))}</Link>
                    </td>
                    <td className="orders-total">{this.handleWordSize((obj?.email))}</td>
                     { (pathIs === "/artist") && (
                      <td className="orders-method">
                        {obj?.review_status == 5 ? "Yes" : "No"}
                      </td>
                    )}
                    <td className="orders-method">{obj.phone_no}</td>
                   { (pathIs === "/artist/artistRejected") && (
                      <td className="orders-method">
                        {this.handleWordSize((obj?.admin_email ))}
                      </td>
                    )}
                     { (pathIs === "/artist/artistRejected") && (
                      <td className="orders-method">
                         
                        <span style={obj?.rejected_text?.length>30?{cursor:"pointer"}:{cursor:'default'}} onClick={obj?.rejected_text?.length>30 ? ()=>this.setState({notesModalOpen: true,notesModalContent:obj?.rejected_text }):()=>this.setState({notesModalOpen: false })}>
                          {this.handleWordSize((obj?.rejected_text))}{obj?.rejected_text?.length>30 && <span className="fe fe-alert-circle"></span>}
                        </span>
                      </td>
                    )}
                    { (pathIs === "/artist/busy") && (
                      <td className="orders-method">
                        {obj?.available_date}
                      </td>
                    )}
                    { (pathIs === "/artist/busy") && (
                      <td className="orders-method">
                        {this.handleWordSize((obj?.busy_by))}<span style={{color:"#6e84a3"}}>{obj?.busy_by_type ? ` (${obj.busy_by_type})` : ""}</span>
                      </td>
                    )}
                    { (pathIs === "/artist/disabled") && (
                      <td className="orders-method" >
                        {/* <span style={{cursor:"pointer"}} data-toggle="tooltip" title={obj?.disable_reason?.reason}>
                          {this.handleWordSize((obj?.disable_reason?.reason))}
                        </span> */}
                        <span style={obj?.disable_reason?.reason?.length>30?{cursor:"pointer"}:{cursor:'default'}} onClick={obj?.disable_reason?.reason?.length>30 ? ()=>this.setState({notesModalOpen: true,notesModalContent:obj?.disable_reason?.reason }):()=>this.setState({notesModalOpen: false })}>
                          {this.handleWordSize((obj?.disable_reason?.reason))}{obj?.disable_reason?.reason?.length>30 && <span className="fe fe-alert-circle"></span>}
                        </span>
                      </td>
                    )}
                     { (pathIs === "/artist/disabled") && (
                      <td className="orders-method">
                        {this.handleWordSize((obj?.disable_reason?.action_by))}
                      </td>
                    )}
                    { (pathIs === "/artist/artistDeleted") && (
                      <td className="orders-method">
                        {this.handleWordSize((obj?.delete_reason?.action_by))}
                      </td>
                    )}
                    { (pathIs === "/artist/artistDeleted") && (
                      <td className="orders-method">
                        {/* <span style={{cursor:"pointer"}} data-toggle="tooltip" title={obj?.delete_reason?.reason}>
                          {this.handleWordSize((obj?.delete_reason?.reason))}
                        </span> */}
                        
                        <span style={obj?.delete_reason?.reason?.length>30?{cursor:"pointer"}:{cursor:'default'}} onClick={obj?.delete_reason?.reason?.length>30 ? ()=>this.setState({notesModalOpen: true,notesModalContent:obj?.delete_reason?.reason }):()=>this.setState({notesModalOpen: false })}>
                          {this.handleWordSize((obj?.delete_reason?.reason))}{obj?.delete_reason?.reason?.length>30 && <span className="fe fe-alert-circle"></span>}
                        </span>
                      </td>
                    )}
                    { (pathIs === "/artist/artistDeleted") && (
                      <td className="orders-method">
                       {(obj?.review_status===4||obj?.review_status===5||obj?.review_status===0)?"Incomplete":(obj.review_status===1)?"Approval Pending":(obj.available===0)?"Busy":"Active"}
                      </td>
                    )}
                    <td className="orders-date">{(obj?.review_status == 2 || obj?.review_status==3) && (obj?.status == 1 || obj?.status == 3)?<a href={`${WebsiteUrl}creator/${obj?.username}`} target='_blank'>{this.handleWordSize((obj?.username))}</a>:this.handleWordSize((obj?.username))}</td>
                    {(pathIs === "/artist/starred") && (
                      <td className="orders-method">
                         {this.renderCollStarred(obj?.total_star_collateral, obj?.userCollateral)}
                      </td>
                    )}
                     {(pathIs === "/artist/starred") && (
                      <td className="orders-method">
                          {this.renderColl(obj?.userCollateral)}
                      </td>
                    )}
                    { ( pathIs === "/artist/approvedArtist")  && (
                      <td className="orders-method">
                        {this.renderCollforApprovedArtist(obj?.userCollateral)}
                      </td>
                    )}
                    { (pathIs === "/artist/artistDeleted" || pathIs === "/artist/artistRejected" || pathIs === "/artist/artistDraft" || pathIs === "/artist/busy"|| pathIs === "/artist/disabled")  && (
                      <td className="orders-method">
                        {this.renderColl(obj?.userCollateral)}
                      </td>
                    )}
                     { (pathIs === "/artist/artistDeleted" || pathIs === "/artist/artistRejected" || pathIs === "/artist/starred" || pathIs === "/artist/artistDraft" || pathIs === "/artist/busy" || pathIs === "/artist/approvedArtist" || pathIs === "/artist/disabled")  && (
                      <td className="orders-method">
                        {this.renderSpec(obj?.userSpecialization)}
                      </td>
                    )}
                     {(pathIs !== "/artist") && (
                     <td className="orders-method">{this.handleWordSize((obj?.location))}</td>
                    )}
                      
                    { (pathIs === "/artist") && (
                    <td className="orders-total text-center">
                      {obj.email_status === 0 ? (
                        <div className="badge badge-soft-danger">No</div>
                      ) : (
                        <div className="badge badge-soft-success">yes</div>
                      )}
                      </td>
                      )}
                   
                    { (pathIs === "/artist") && (
                    <td className="orders-total text-center">
                      {obj.phone_status === 0 ? (
                        <div className="badge badge-soft-danger">No</div>
                      ) : (
                        <div className="badge badge-soft-success">yes</div>
                      )}
                      </td>
                      )}

                     {pathIs === "/artist" && (
                      <td className="orders-method">
                        {this.getStage(obj)}
                        {/* {obj.review_status === 0 || obj.review_status === 4 ? "Submit Your Application" : obj.review_status === 5 ? "Application Approved" : obj.review_status === 1 ? "Profile Under Review" : ""} */}
                      </td>
                    )}
                     { (pathIs !== "/artist") && (
                      <td className="orders-method">
                       {obj?.current_work_status}
                      </td>
                    )}
                  
                    { (pathIs === "/artist/artistDeleted" || pathIs === "/artist/starred" || pathIs === "/artist/approvedArtist" || pathIs === "/artist/busy" || pathIs === "/artist/disabled") && (
                      <td className="orders-method">
                        {obj?.avg_brief_response_time + " mins"}
                      </td>
                    )}
                    { (pathIs === "/artist/artistDeleted" || pathIs === "/artist/starred" || pathIs === "/artist/approvedArtist" || pathIs === "/artist/busy" || pathIs === "/artist/disabled") && (
                      <td className="orders-method">
                        {obj?.avg_brief_response_timev2 + " mins"}
                      </td>
                    )}
                    { (pathIs === "/artist/approvedArtist" || pathIs === "/artist/busy") && (
                      <td className="orders-method">
                        {this.getDateTime(new Date() ,obj?.creatorLastBrief?.project_brief_approved_date,index)}
                      </td>
                    )}
                     { (pathIs === "/artist/artistDeleted" || pathIs === "/artist/starred" || pathIs === "/artist/approvedArtist" || pathIs === "/artist/busy" || pathIs === "/artist/disabled") && (
                      <td className="orders-method">
                        {obj?.creatorTotalBrief}
                      </td>
                    )}
                     { (pathIs === "/artist/artistDeleted" || pathIs === "/artist/starred" || pathIs === "/artist/approvedArtist" || pathIs === "/artist/busy" || pathIs === "/artist/disabled") && (
                      <td className="orders-method">
                      {obj?.totalActiveBrief}
                      </td>
                    )}
                     { (pathIs === "/artist/artistDeleted" || pathIs === "/artist/starred" || pathIs === "/artist/approvedArtist" || pathIs === "/artist/busy" || pathIs === "/artist/disabled") && (
                      <td className="orders-method">
                        {obj?.totalActiveProject}
                      </td>
                    )}
                     { (pathIs === "/artist/artistDeleted" || pathIs === "/artist/starred" || pathIs === "/artist/approvedArtist" || pathIs === "/artist/busy" || pathIs === "/artist/disabled") && (
                      <td className="orders-method">
                      {obj?.totalCompletedProject}
                      </td>
                    )}
                     { (pathIs === "/artist/starred" || pathIs === "/artist/approvedArtist" || pathIs === "/artist/busy" || pathIs === "/artist/disabled") && (
                      <td className="orders-method">
                        {this.handleWordSize((obj?.admin_email))}
                      </td>
                    )}
                     {pathIs === "/artist/artistDeleted" &&
                      <td className="orders-method">
                        {obj?.updatedAt && moment(obj?.updatedAt).format("DD/MM/YYYY")}
                      </td>
                    }
                    <td className="orders-method">
                      {obj?.createdAt && moment(obj?.createdAt).format("DD/MM/YYYY")}
                    </td>
                    {pathIs !== "/artist/artistDeleted" &&
                      <td className="orders-method">
                        {obj?.last_active && moment(obj?.last_active).format("DD/MM/YYYY")}
                      </td>
                    }
                    <td className="orders-method text-center">
                      {obj?.created_status === 0 ? 'Admin' : 'Website'}
                    </td> 
                    { (pathIs === "/artist/starred") && (
                      <td className="orders-method">
                        {obj?.star_date && moment(obj?.star_date).format('DD/MM/YYYY')}
                      </td>
                    )}
                                    
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {this.state.UnOrblacklistdelModal && (
          <DeleteArtistModal
            handleUnOrblacklistdelStatus={this.handleUnOrblacklistdelStatus}
            handleUnOrBlacklistDelArtist={this.handleUnOrBlacklistDelArtist}
            types={this.state.types}
            {...this.state}
          />
        )}

        <StarredModal
          handleCloseModal={this.handleCloseModal}
          handleCallApi = {this.handleCallApi}
          {...this.state}
        />

        {this.state.notesModalContent &&
          <NotesModal 
          notesModalOpen={this.state.notesModalOpen} 
          closeNotesModal={this.closeNotesModal} 
          notesModalContent={this.state.notesModalContent}/>}
        
      </React.Fragment>
    );
  }
}

const mapState = ({ userDelete }) => {
  return {
    _userDelete: userDelete,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $userDelete: (values) => dispatchEvent(userDelete(values)),
});
export default connect(mapState, mapDispatch)(ArtistListingTable);
