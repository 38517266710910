import React, { Component } from "react";
import Transactions from "./Transactions";
import { Switch, Route } from "react-router";
import PendingTransactions from "../../Autorized/Transactions/PendingTransactions";
import IncompleteTransactions from "../../Autorized/Transactions/IncompleteTransactions";
import IncompleteHighScopeTrans from "../../Autorized/Transactions/IncompleteHighScopeTrans";
import RejectedTransactionList from "../../Autorized/Transactions/RejectedTransactionList";
import ActiveTransactions from "../../Autorized/Transactions/ActiveTransactions";
import CompleteTransactions from "../../Autorized/Transactions/CompleteTransactions";
import ClosedTransactions from "../../Autorized/Transactions/ClosedTransactions";
import CloseBriefProject from "../../Autorized/Transactions/ClosedBriefProject";
import DontLikeTranscations from "../../Autorized/Transactions/DontLikeTransactions";
import TransactionsDetailsForm from "../../Autorized/Transactions/TransactionDetailsForm";
import TransactionUserDetails from "../../Autorized/Transactions/TransactionUserDetails"; 
import ProfileSelectionArtist from "./ProfileSelectionArtist";
import AddMultipleCreator from "../../Autorized/Transactions/AddCreator/AddMultipleCreator";
import AddMultipleCreator2 from "../../Autorized/Transactions/AddCreator/AddMultipleCreator2";
import Clone from "../../Autorized/Transactions/Clone";
import AddProject from "./AddProject/AddProject";
import ProjectMilestone from "./AddProject/ProjectMileStone";
import DeletedBrief from "../../Autorized/Transactions/DeletedBrief";
import DeletedProjects from "../../Autorized/Transactions/DeletedProjects";
import CompleteProject from './AddProject/CompleteProject';
import InactiveBrief from "./InactiveBrief";
import InactiveProject from "./InactiveProject"

export default class AllPageTranscations extends Component {
  componentDidMount() {
    this.props.handleClients.handlepathName("/transactions");
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/transactions/InactiveProjects"  render={(props)=> <InactiveProject {...props} />}/>
          <Route path="/transactions/InactiveBriefs"  render={(props)=> <InactiveBrief {...props} />}/>
          <Route path="/transactions/completeProject" exact render={(props)=> <CompleteProject {...props} />}/>
          <Route
            path="/transactions/DeletedBrief"
            render={(props) => <DeletedBrief {...props} />}
          />
          <Route
            path="/transactions/DeletedProjects"
            render={(props) => <DeletedProjects {...props} />}
          />
          <Route
            path="/transactions/ProjectMilestone/:id"
            render={(props) => <ProjectMilestone {...props} />}
          />
          <Route
            path="/transactions/addProject/:id"
            render={(props) => <AddProject {...props} />}
          />
          <Route
            path="/transactions/transactionUserDetails/:id"
            render={(props) => <TransactionUserDetails {...props} />}
          />
          <Route
            path="/transactions/profileSelectionArtist/:id"
            render={(props) => <ProfileSelectionArtist {...props} />}
          />
          <Route
            path="/transactions/addMultipleCreator/:id"
            render={(props) => <AddMultipleCreator {...props} />}
          />
          <Route
            path="/transactions/addMultipleCreator2/:id"
            render={(props) => <AddMultipleCreator2 {...props} />}
          />
          <Route
            path="/transactions/clone/:id"
            render={(props) => <Clone {...props} />}
          />
          <Route
            path="/transactions/transactionsDetails/:id"
            render={(props) => <TransactionsDetailsForm {...props} />}
          />
          <Route
            path="/transactions/DontLikeTransactions"
            render={(props) => <DontLikeTranscations {...props} />}
          />
          <Route
            path="/transactions/closedBrief"
            render={(props) => <CloseBriefProject {...props} />}
          />
          <Route
            path="/transactions/closedTransactions"
            render={(props) => <ClosedTransactions {...props} />}
          />
          <Route
            path="/transactions/completeTransactions"
            render={(props) => <CompleteTransactions {...props} />}
          />
          <Route
            path="/transactions/ActiveTransactions"
            render={(props) => <ActiveTransactions {...props} />}
          />
          <Route
            path="/transactions/RejectedTransactionList"
            render={(props) => <RejectedTransactionList {...props} />}
          />
          <Route
            path="/transactions/incompleteTransactions"
            render={(props) => <IncompleteTransactions {...props} />}
          />
          <Route
            path="/transactions/IncompleteHighScopeTrans"
            render={(props) => <IncompleteHighScopeTrans {...props} />}
          />
          <Route
            path="/transactions/PendingTransactions"
            render={(props) => <PendingTransactions {...props} />}
          />
          <Transactions {...this.props} />
        </Switch>
      </React.Fragment>
    );
  }
}
