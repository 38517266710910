import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  // transactionList,
  // activeTransactions,
  // incompleteTransactions,
  // incompleteHighTransaction,
  // completeProject,
  // closeTransactionList,

  // DontLikeTranscationList,

  // pendingTransactions,
  // rejectedBriefList,
  // deleteBriefList,
  // deleteProjectAllList,
  // closedBriefAllList,
  // inactiveBrief,
  // inactiveProject,
  // transactionGetData,
  transactionsCount
} from "../../../../store/action/transaction.action";

class TransactionHeaderTab extends Component {


  
  handleAllTransactionApi = () => {
    // const transactionData = {
    //   List: 1,
    //   project: "",
    //   min_budget: "",
    //   max_budget: "",
    //   deadline: "",
    // };
    // this.props.$activeTransactions(transactionData);
    // this.props.$incompleteTransactions(transactionData);

    // this.props.$incompleteHighTransaction(transactionData);

    // this.props.$completeProject(transactionData);
    // this.props.$closeTransactionList(transactionData);

    // this.props.$DontLikeTranscationList(transactionData);

    // this.props.$pendingTransactions(transactionData);
    // this.props.$transactionList(transactionData);
    // this.props.$rejectedBriefList(transactionData);
    // this.props.$deleteBriefList(transactionData);
    // this.props.$deleteProjectAllList(transactionData);
    // this.props.$closedBriefAllList(transactionData);
    // this.props.$inactiveBrief(transactionData)
    // this.props.$inactiveProject(transactionData)
    
    this.props.$transactionsCount()
  };

  componentDidMount() {
    // console.log(this.props?.location.pathname)
    // if(this.props?.location.pathname==='/transactions')
    this.handleAllTransactionApi();
  }



  render() {
    // console.log(this.props?._transactionsCount?.transactionsCount, 80000)
    // console.log(this.props?._transactionsCount?.transactionDetails,this.props?.location?.pathname)
    const {
      pathIs,
      location,
      // _transactionList,
      // _activeTransactions,
      // _incompleteTransactions,
      // _incompleteHighTransaction,
      // _completeProject,
      // _closeTransactionList,

      // _DontLikeTranscationList,

      // _pendingTransactions,
      // _rejectedBriefList,
      // _deleteBriefList,
      // _deleteProjectAllList,
      // _closedBriefAllList,
      // _inactiveBrief,
      // _inactiveProject,
      _transactionGetData,
      _transactionsCount
    } = this.props;

    // console.log(_transactionsCount,location)

    const artistId = location.pathname.split("/:").pop();
    
    const newPath = location.pathname.split("/:")[0]

    // console.log("newPath = ", newPath)

    const artistCurrent = (path) => {
      const currentPath = location.pathname;
      return currentPath === path;
    };

    const artistCurrentPath = (path) => {
      const currentPathIs = location.pathname.split("/:").slice(0)[0];
      return currentPathIs === path;
    };

    const transactionArr = [
      {
        name: "Incomplete",
        path: "/transactions/incompleteTransactions",
        activeBorder: "/transactions/incompleteTransactions",
        // transactionTotal: _incompleteTransactions?.incompleteList?.totalUsers,
        transactionTotal:location?.pathname==='/transactions/incompleteTransactions'?_transactionsCount?.incompleteList?.totalUsers:_transactionsCount?.transactionsCount?.incomplete_brief,
      },
      {
        name: "Approval Pending",
        path: "/transactions/PendingTransactions",
        activeBorder: "/transactions/PendingTransactions",
        transactionTotal:location?.pathname==='/transactions/PendingTransactions'?_transactionsCount?.pendingList?.totalUsers:_transactionsCount?.transactionsCount?.pending_brief,
      }, 
      // {
      //   name: "Incompleted High Scope",
      //   path: "/transactions/IncompleteHighScopeTrans",
      //   activeBorder: "/transactions/IncompleteHighScopeTrans",
      //   transactionTotal:
      //     _incompleteHighTransaction?.incompleteHighList?.totalUsers,
      // },
      {
        name: "Active Brief",
        path: "/transactions",
        activeBorder: "/transactions",
        transactionTotal:location?.pathname==='/transactions'?_transactionsCount?.transactionDetails?.totalUsers:_transactionsCount?.transactionsCount?.active_brief,
      },
      {
        name: "Active Project",
        path: "/transactions/ActiveTransactions",
        activeBorder: "/transactions/ActiveTransactions",
        transactionTotal:location?.pathname==='/transactions/ActiveTransactions'?_transactionsCount?.activeList?.totalUsers:_transactionsCount?.transactionsCount?.active_project,
      },
      {
        name: "Inactive Briefs",
        path: "/transactions/InactiveBriefs",
        activeBorder: "/transactions/InactiveBriefs",
        transactionTotal:location?.pathname==='/transactions/InactiveBriefs'?_transactionsCount?.inactiveBriefData?.totalUsers:_transactionsCount?.transactionsCount?.active_brief_inactive,
      },
      {
        name: "Inactive Project",
        path: "/transactions/InactiveProjects",
        activeBorder: "/transactions/InactiveProjects",
        transactionTotal:location?.pathname==='/transactions/InactiveProjects'?_transactionsCount?.inactiveProjectData?.totalUsers:_transactionsCount?.transactionsCount?.inactive_project,
      },
      {
        name: "Completed Project",
        path: "/transactions/completeTransactions",
        activeBorder: "/transactions/completeTransactions",
        transactionTotal:location?.pathname==='/transactions/completeTransactions'?_transactionsCount?.completeTransactionsIs?.totalUsers:_transactionsCount?.transactionsCount?.complete_project,
      },
      {
        name: "Rejected",
        path: "/transactions/RejectedTransactionList",
        activeBorder: "/transactions/RejectedTransactionList",
        transactionTotal: location?.pathname==='/transactions/RejectedTransactionList'?_transactionsCount?.rejectedBriefListIs?.totalUsers:_transactionsCount?.transactionsCount?.rejected_brief,
      },
      {
        name: "Closed Brief",
        path: "/transactions/ClosedBrief",
        activeBorder: "/transactions/ClosedBrief",
        transactionTotal: location?.pathname==='/transactions/ClosedBrief'?_transactionsCount?.closedBriefAll?.totalUsers:_transactionsCount?.transactionsCount?.closed_brief,
      },
      {
        name: "Closed Project",
        path: "/transactions/closedTransactions",
        activeBorder: "/transactions/closedTransactions",
        transactionTotal:location?.pathname==='/transactions/closedTransactions'?_transactionsCount?.closedTransactionIs?.totalUsers:_transactionsCount?.transactionsCount?.closed_project,
      },    
      {
        name: "Deleted Brief",
        path: "/transactions/DeletedBrief",
        activeBorder: "/transactions/DeletedBrief",
        transactionTotal:location?.pathname==='/transactions/DeletedBrief'?_transactionsCount?.deletedBriefsIs?.totalUsers:_transactionsCount?.transactionsCount?.deleted_brief,
      },
      {
        name: "Deleted Project",
        path: "/transactions/DeletedProjects",
        activeBorder: "/transactions/DeletedProjects",
        transactionTotal:location?.pathname==='/transactions/DeletedProjects'?_transactionsCount?.deleteProjectsIs?.totalUsers: _transactionsCount?.transactionsCount?.deleted_project,
      },
      // {
      //   name: "Remove Call Back Request",
      //   path: "/transactions/DontLikeTransactions",
      //   activeBorder: "/transactions/DontLikeTransactions",
      //   transactionTotal: _DontLikeTranscationList?.dontLikeListIs?.totalUsers,
      // },
    ];

    const otherTabData = [
      {
        name: "Post Project",
        path: `/transactions/transactionsDetails/:${artistId}`,
        activeBorder: "/transactions/transactionsDetails",
      },
      {
        name: "Client/Creator",
        path: `/transactions/transactionUserDetails/:${artistId}`,
        activeBorder: "/transactions/transactionUserDetails",
      },
      {
        name: "Recommended Artist",
        path: `/transactions/profileSelectionArtist/:${artistId}`,
        activeBorder: "/transactions/profileSelectionArtist",
      }
    ];

    if (_transactionGetData && _transactionGetData?.transactionData && (_transactionGetData?.transactionData?.project_data?.status === 5 || _transactionGetData?.transactionData?.project_data?.status === 3)) {
      otherTabData.push({
        name: "Add Creator",
        path: `/transactions/addMultipleCreator/:${artistId}`,
        activeBorder: "/transactions/addMultipleCreator",
      })
    }
    if (_transactionGetData && _transactionGetData?.transactionData && (_transactionGetData?.transactionData?.project_data?.status === 5 || _transactionGetData?.transactionData?.project_data?.status === 3)) {
      otherTabData.push({
        name: "Add Creator 2",
        path: `/transactions/addMultipleCreator2/:${artistId}`,
        activeBorder: "/transactions/addMultipleCreator2",
      })
    }

    const projectData = [
      {
        name: "Project Details",
        path: `/transactions/addProject/:${artistId}`,
        activeBorder: `/transactions/addProject/:${artistId}`,
      },
      {
        name: "Proposal",
        path: `/transactions/ProjectMilestone/:${artistId}`,
        activeBorder: `/transactions/ProjectMilestone/:${artistId}`,
      },
    ];

    const projectPath = this.props.location.pathname;

    return (
      <React.Fragment>
        {pathIs === "Transactions" &&
          (artistId === "/transactions/ActiveTransactions" ||
            artistId === "/transactions" ||
            artistId === "/transactions/PendingTransactions" ||
            artistId === "/transactions/incompleteTransactions" ||
            // artistId === "/transactions/IncompleteHighScopeTrans" ||
            artistId === "/transactions/completeTransactions" ||
            artistId === "/transactions/closedTransactions" ||
            artistId === "/transactions/DontLikeTransactions" ||
            artistId === "/transactions/ClosedBrief" ||
            artistId === "/transactions/RejectedTransactionList" ||
            artistId === "/transactions/DeletedBrief" ||
            artistId === "/transactions/InactiveBriefs" ||
            artistId === "/transactions/InactiveProjects" || 
            artistId === "/transactions/DeletedProjects") && (
          <ul className="nav nav-tabs nav-overflow header-tabs">
              {transactionArr.map((obj, index) => (
                <li className="nav-item" key={index}>
                  <Link
                    to={obj.path}
                    className={`nav-link ${
                      artistCurrent(`${obj.activeBorder}`) ? "active" : ""
                    }`}
                  >
                    {obj.name}
                    <span className="badge badge-pill badge-soft-secondary ml-2">
                      {obj.transactionTotal}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          )}

        {pathIs === "Transactions" &&
          artistId !== "/transactions" &&
          artistId !== "/transactions/ActiveTransactions" &&
          newPath !== "/transactions/Clone" &&
          artistId !== "/transactions/incompleteTransactions" &&
          // artistId !== "/transactions/IncompleteHighScopeTrans" &&
          artistId !== "/transactions/PendingTransactions" &&
          artistId !== "/transactions/completeTransactions" &&
          artistId !== "/transactions/DontLikeTransactions" &&
          artistId !== "/transactions/closedTransactions" &&
          artistId !== "/transactions/ClosedBrief" &&
          artistId !== "/transactions/RejectedTransactionList" &&
          artistId !== "/transactions/DeletedBrief" &&
          artistId !== "/transactions/DeletedProjects" &&
          projectPath !== "/transactions/addProject/:" &&
          projectPath !== `/transactions/addProject/:${artistId}` &&
          projectPath !== `/transactions/ProjectMilestone/:${artistId}` &&
          projectPath !== "/transactions/InactiveBriefs" &&
          projectPath !== "/transactions/InactiveProjects" &&
          projectPath !== `/transactions/completeProject`&& (
            <ul className="nav nav-tabs nav-overflow header-tabs">
              {otherTabData.map((obj, index) => (
                <li className="nav-item" key={index}>
                  <Link
                    to={obj.path}
                    className={`nav-link ${
                      artistCurrentPath(`${obj.activeBorder}`) ? "active" : ""
                    }`}
                  >
                    {obj.name}
                  </Link>
                </li>
              ))}
            </ul>
          )}

        {pathIs === "Transactions" &&
          projectPath !== "/transactions/addProject/:" &&
          (projectPath === `/transactions/ProjectMilestone/:${artistId}` ||
            projectPath === `/transactions/addProject/:${artistId}`) && (
            <ul className="nav nav-tabs nav-overflow header-tabs">
              {projectData.map((obj, index) => (
                <li className="nav-item" key={index}>
                  <Link
                    to={obj.path}
                    className={`nav-link ${
                      artistCurrent(`${obj.activeBorder}`) ? "active" : ""
                    }`}
                  >
                    {obj.name}
                  </Link>
                </li>
              ))}
            </ul>
          )}
      </React.Fragment>
    );
  }
}
const mapState = ({
  // transactionList,
  // activeTransactions,
  // incompleteTransactions,
  // incompleteHighTransaction,
  // completeProject,
  // closeTransactionList,
  // // DontLikeTranscationList,
  // pendingTransactions,
  // rejectedBriefList,
  // deleteBriefList,
  // deleteProjectAllList,
  // closedBriefAllList,
  // inactiveBrief,
  // inactiveProject,
  transactionGetData,
  transactionsCount,

}) => {
  return {
    // _transactionList: transactionList,
    // _activeTransactions: activeTransactions,
    // _incompleteTransactions: incompleteTransactions,
    // _incompleteHighTransaction: incompleteHighTransaction,
    // _completeProject: completeProject,
    // _closeTransactionList: closeTransactionList,

    // _DontLikeTranscationList: DontLikeTranscationList,

    // _pendingTransactions: pendingTransactions,
    // _rejectedBriefList: rejectedBriefList,
    // _deleteBriefList: deleteBriefList,
    // _deleteProjectAllList: deleteProjectAllList,
    // _closedBriefAllList: closedBriefAllList,
    // _inactiveBrief : inactiveBrief,
    // _inactiveProject: inactiveProject,
    _transactionGetData: transactionGetData,
    _transactionsCount:transactionsCount,
  };
};
const mapDispatch = (dispatchEvent) => ({
  // $transactionList: (values) => dispatchEvent(transactionList(values)),
  // $activeTransactions: (values) => dispatchEvent(activeTransactions(values)),
  // $incompleteTransactions: (values) =>
  //   dispatchEvent(incompleteTransactions(values)),
  // $incompleteHighTransaction: (values) =>
  //   dispatchEvent(incompleteHighTransaction(values)),
  // $completeProject: (values) => dispatchEvent(completeProject(values)),
  // $closeTransactionList: (values) =>
  //   dispatchEvent(closeTransactionList(values)),
    
  // $DontLikeTranscationList: (values) =>
  //   dispatchEvent(DontLikeTranscationList(values)),

  // $pendingTransactions: (values) => dispatchEvent(pendingTransactions(values)),
  // $rejectedBriefList: (values) => dispatchEvent(rejectedBriefList(values)),
  // $deleteBriefList: (values) => dispatchEvent(deleteBriefList(values)),
  // $deleteProjectAllList: (values) => dispatchEvent(deleteProjectAllList(values)),
  // $closedBriefAllList: (values) => dispatchEvent(closedBriefAllList(values)),
  // $inactiveBrief: (values) => dispatchEvent(inactiveBrief(values)),
  // $inactiveProject: (values) => dispatchEvent(inactiveProject(values)),
  $transactionsCount:(values) => dispatchEvent(transactionsCount(values))
});
export default connect(mapState, mapDispatch)(TransactionHeaderTab);
