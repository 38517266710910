import React, { Component } from "react";
import { postAPI } from "../../../store/api-interface";
import LoaderSmall from "../Common/Loader/LoaderSmall";

class CustomAddForm extends Component {

  state = {
    name: "",
    errorIs: { name:"" },
    loader:false,
  };


  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    this.validateForm(name, value)
  };

  handleAddCustom = async(type) => {
    const {name}=this.state;
    const payload={name:name,type:type}
    this.setState({loader:true})
    try{
      const response =await  postAPI('/onboarding/add-software-industry-specialization',payload)
      if(response?.status===200){
        this.setState({
          loader:false,
          name:""
        });
        alert("Successfully Added");
        switch(type){
          case 'software':
            this.props.mountListAgain();
            this.props.history.push('/custom')
            break;
          case 'industry':
            this.props.history.push('/custom/Industry')
            break;
          case 'specialization':
            this.props.history.push('/custom/Specialization')
            break;
          default:
            alert('Something went wrong');
            break;
        }
      }
      else{
        this.setState({loader:false})
        alert("Something went wrong")
      }
    }
    catch(err){
      this.setState({loader:false})
      console.log(err)
    }
  }

  handleSubmit = (type) => {
    this.validation()
    if(!this.state.errorIs.name){
      this.handleAddCustom(type)
      
    }
  };

  validateForm(name, value){
    const{ errorIs } = this.state;
    switch(name){
      case "name":
        errorIs.name = !value.length > 0 ? "Required Field" : "";
        break;
      case "email":
        errorIs.email = !value.length > 0 ? "Required Field" : "";
        break;
      default:
        break;
    }
  }

  validation = () => {
    const { errorIs, name } = this.state;
    errorIs.name = !name.length > 0 ? "Required Field" : "";

    this.setState({ errorIs });
    return !errorIs.name ;
  };

 

  render() {
    const { name,errorIs,loader } = this.state;
    const dataArr = [
      { name: "name", value: name, label: "Name", type: "text" },
    ];

    const type = this.props.match.params?.type.split(":").pop();

    return (
      <React.Fragment>
        {type === "Software" ? (
          <div>
            <h2 className="header-title text-secondary">Software</h2>
          {console.log(this.props,'props hai ye to ')}
          <div className="container">
            <div className="questin-Listing p-5">
              <div className="row">
                {dataArr.map((obj, index) => (
                  <div className="col-12" key={index}>
                    <div className="form-group">
                      <label>{obj.label}</label>
                      <input
                        // type="text"
                        className={`form-control ${
                          (errorIs.name && obj.name === "name") ||
                          (errorIs.pwd && obj.name === "pwd") ||
                          (errorIs.userName && obj.name === "userName") ||
                          (errorIs.first_name && obj.name === "first_name") ||
                          (errorIs.last_name && obj.name === "last_name") ||
                          (errorIs.phone && obj.name === "phone") ?
                          `error-show` : ''
                        }`}
                        name={obj.name}
                        value={obj.value}
                        onChange={this.handleOnChange}
                        // disabled={!addUSerIs ? "disabled" : ""}
                        type={obj.type}
                      />

                        {((errorIs.name && obj.name === "name") ||
                          (errorIs.pwd && obj.name === "pwd") ||
                          (errorIs.userName && obj.name === "userName") ||
                          (errorIs.first_name && obj.name === "first_name") || 
                          (errorIs.last_name && obj.name === "last_name") ||
                          (errorIs.phone && obj.name === "phone")) && (
                          <span className="error">
                            {errorIs.name || errorIs.pwd || errorIs.userName || errorIs.first_name || errorIs.last_name || errorIs.phone}
                          </span>
                        )}
                    </div>
                  </div>
                ))}
              </div>

              <div className="d-flex flex-row-reverse">
                <button
                  className="btn btn-primary"
                  disabled={loader}
                  onClick={() => this.handleSubmit('software')}

                >
                  {loader ? <LoaderSmall /> : "Submit"}
                </button>
              </div>
              {/* )} */}
            </div>
          </div>
          </div>

        ) : type === "Industry" ?(
          <div>
            <h2 className="header-title text-secondary">Industry</h2>
          <div className="container">
            <div className="questin-Listing p-5">
              <div className="row">
                {dataArr.map((obj, index) => (
                  <div className="col-12" key={index}>
                    <div className="form-group">
                      <label>{obj.label}</label>
                      <input
                        // type="text"
                        className={`form-control ${
                          (errorIs.name && obj.name === "name") ||
                          (errorIs.pwd && obj.name === "pwd") ||
                          (errorIs.userName && obj.name === "userName") ||
                          (errorIs.first_name && obj.name === "first_name") ||
                          (errorIs.last_name && obj.name === "last_name") ||
                          (errorIs.phone && obj.name === "phone") ?
                          `error-show` : ''
                        }`}
                        name={obj.name}
                        value={obj.value}
                        onChange={this.handleOnChange}
                        // disabled={!addUSerIs ? "disabled" : ""}
                        type={obj.type}
                      />
                      {((errorIs.name && obj.name === "name") ||
                          (errorIs.pwd && obj.name === "pwd") ||
                          (errorIs.userName && obj.name === "userName") ||
                          (errorIs.first_name && obj.name === "first_name") || 
                          (errorIs.last_name && obj.name === "last_name") ||
                          (errorIs.phone && obj.name === "phone")) && (
                          <span className="error">
                            {errorIs.name || errorIs.pwd || errorIs.userName || errorIs.first_name || errorIs.last_name || errorIs.phone}
                          </span>
                        )}
                    </div>
                  </div>
                ))}
              </div>

              <div className="d-flex flex-row-reverse">
                <button
                  className="btn btn-primary"
                  disabled={loader}
                  onClick={() => this.handleSubmit('industry')}
                >
                  {loader ? <LoaderSmall /> : "Submit"}
                </button>
              </div>
              {/* )} */}
            </div>
          </div>
          </div>
        ):(
          <div>
            <h2 className="header-title text-secondary">Specialization</h2>
          <div className="container">
            <div className="questin-Listing p-5">
              <div className="row">
                {dataArr.map((obj, index) => (
                  <div className="col-12" key={index}>
                    <div className="form-group">
                      <label>{obj.label}</label>
                      <input
                        // type="text"
                        className={`form-control ${
                          (errorIs.name && obj.name === "name") ||
                          (errorIs.pwd && obj.name === "pwd") ||
                          (errorIs.userName && obj.name === "userName") ||
                          (errorIs.first_name && obj.name === "first_name") ||
                          (errorIs.last_name && obj.name === "last_name") ||
                          (errorIs.phone && obj.name === "phone") ?
                          `error-show` : ''
                        }`}
                        name={obj.name}
                        value={obj.value}
                        onChange={this.handleOnChange}
                        // disabled={!addUSerIs ? "disabled" : ""}
                        type={obj.type}
                      />
                      {((errorIs.name && obj.name === "name") ||
                          (errorIs.pwd && obj.name === "pwd") ||
                          (errorIs.userName && obj.name === "userName") ||
                          (errorIs.first_name && obj.name === "first_name") || 
                          (errorIs.last_name && obj.name === "last_name") ||
                          (errorIs.phone && obj.name === "phone")) && (
                          <span className="error">
                            {errorIs.name || errorIs.pwd || errorIs.userName || errorIs.first_name || errorIs.last_name || errorIs.phone}
                          </span>
                        )}
                    </div>
                  </div>
                ))}
              </div>

              <div className="d-flex flex-row-reverse">
                <button
                  className="btn btn-primary"
                  disabled={loader}
                  onClick={() => this.handleSubmit('specialization')}
                >
                  {loader ? <LoaderSmall /> : "Submit"}
                </button>
              </div>
              {/* )} */}
            </div>
          </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default CustomAddForm;
