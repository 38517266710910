import React, { Component } from "react";
import { getPublicAPI } from "../../../store/api-interface";
import Loader from "../Common/Loader/Loader";
import ReactPaginate from "react-paginate";
import Moment from "moment";
// import Select from "react-select";
import Popup from "./Popup";

class Messages extends Component {
  state = {
    loadingIs: false,
    dataList: [],
    totalPagesIs: 0,
    pageNumber: 1,
    allUserUnsubscribe: [],
    allUserSubscribe: [],
    showPopup: false,
    isSubscribe: true,
    totalPagesSub: 0,
    pageNumberSub: 1,
    totalPagesUnSub: 0,
    pageNumberUnSub: 1,
    loading: false,
  };

  componentDidMount = () => {
    this.handleCollSearchList(this.state.pageNumber);
    this.handleSubList(this.state.pageNumberSub);
    this.handleUnSubList(this.state.pageNumberUnSub);
  };

  handleCollSearchList = (pageNumber) => {
    this.setState({ loadingIs: true });
    try {
      getPublicAPI(`/gupshup/getAllMessage?page=${pageNumber}&limit=50`).then((res) => {
        if (res) {
          let data = res?.data?.AllMessage;
          // console.log("res = ", data);
          this.setState({ dataList: data, totalPagesIs: res?.data?.totalPages, loadingIs: false });
        }
      });
    } catch (err) {
      console.log("error");
      this.setState({ loadingIs: false });
    }
  };

  handleSubList = (pageNumberSub) => {
    this.setState({ loading: true });
    try {
      getPublicAPI(`/gupshup/getAll-subscribed-user?page=${pageNumberSub}&limit=50`).then((res) => {
        if (res) {
          let allUserSubscribe = res?.data?.AllUsers;
          this.setState({ allUserSubscribe, totalPagesSub: res?.data?.totalPages, loading: false });
        }
      });
    } catch (err) {
      console.log("error");
      this.setState({ loading: false });
    }
  };

  handleUnSubList = (pageNumberUnSub) => {
    this.setState({ loading: true });
    try {
      getPublicAPI(`/gupshup/getAll-unsubscribed-user?page=${pageNumberUnSub}&limit=50`).then((res) => {
        if (res) {
          let allUserUnsubscribe = res?.data?.AllUsers;
          this.setState({ allUserUnsubscribe, totalPagesUnSub: res?.data?.totalPagesUnsubscribe, loading: false });
        }
      });
    } catch (err) {
      console.log("error");
      this.setState({ loading: false });
    }
  };

  handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    this.setState({ pageNumber: selectedPage }, () => this.handleCollSearchList(this.state.pageNumber));
  };

  handlePageClickPopup = (data) => {
    const selectedPage = data.selected + 1;
    if (this.state.isSubscribe) {
      this.setState({ pageNumberSub: selectedPage }, () => {
        this.handleSubList(this.state.pageNumberSub);
      });
    } else {
      this.setState({ pageNumberUnSub: selectedPage }, () => {
        this.handleUnSubList(this.state.pageNumberUnSub);
      });
    }
  };

  handleWordSize = (value) => {
    return value ? (value?.length > 80 ? value?.slice(0, 80) + "..." : value) : "";
  };

  handleTogglePopup = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  render() {
    const { loadingIs, totalPagesIs, dataList, showPopup } = this.state;

    return (
      <React.Fragment>
        <section className="creative-field creative_wrapper">
          <div className="row mb-2 mx-auto d-flex justify-content-end">
            <button className="btn btn-primary mr-4" onClick={() => this.setState({ showPopup: !showPopup, isSubscribe: true })}>
              Subscribe List
            </button>
            <button className="btn btn-primary" onClick={() => this.setState({ showPopup: !showPopup, isSubscribe: false })}>
              Unsubscribe List
            </button>
          </div>

          <div className="container-custom mx-auto">
            {loadingIs ? (
              <Loader />
            ) : (
              <div className="table-responsive mb-5">
                <table className="table table-sm table-nowrap card-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Message</th>
                      <th>Sender</th>
                      <th>Receiver</th>
                      <th>Sent By</th>
                      <th>Phone</th>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {dataList.map((item, index) => (
                      <tr key={index}>
                        <td>{item?.name}</td>

                        <td>
                          <span style={{ cursor: "pointer" }} data-toggle="tooltip" title={item?.message}>
                            {this.handleWordSize(item?.message)}
                          </span>
                        </td>
                        <td>{item?.sender_phone_no}</td>
                        <td>{item?.receiver_phone_no}</td>
                        <td>{item?.type}</td>
                        <td>{item?.phone_no}</td>
                        <td>{item?.status}</td>
                        <td>{item?.createdAt && Moment(item?.createdAt).format("MMM Do YYYY, h:mm:ss a")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </section>

        {/******** pagination ************* */}

        {totalPagesIs > 0 && (
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPagesIs}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        )}

        {showPopup ? (
          <Popup {...this.state} handleTogglePopup={this.handleTogglePopup} handlePageClickPopup={this.handlePageClickPopup} />
        ) : null}
      </React.Fragment>
    );
  }
}

export default Messages;
