import React, { Component } from "react";
import { connect } from "react-redux";
import {
  artistDataList,
  artistStarredList,
} from "../../../store/action/clientList.action";
import ArtistListingTable from "./ArtistListingTable";
import ReviewStatusModal from "./ReviewStatusModal";
import ReactPaginate from "react-paginate";
import ErrorApi from "../Common/Error/ErrorApi";

class Starred extends Component {
  state = {
    statusEmailIs: "",
    createArtistIs: false,
    artistIs: [],
    artistList: 1,
    ListLoading: false,
    showAction: false,
    showActionIndex: "",
    showReviewPopUp: false,
    artistId: "",
    profileReviewStatus: "",
    totalArtistIs: "",
    reviewStatus: false,
    artistStarred: false,
    totalPages: 0,
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    sortBy: '-1',
    page:1
  };

  setArtistState = (stateObj) => {
    this.setState(stateObj);
  };

  handleShowAction = (index) => {
    const { showAction } = this.state;
    this.setState({
      showAction: !showAction,
      showActionIndex: index,
    });
  };

  handleHideAction = () => {
    this.setState({
      showAction: true,
    });
  };

  handleReviewStatus = (obj, pStatus) => {
    if (pStatus === "accepted") {
      this.setState({
        profileReviewStatus: 2,
        reviewStatus: true,
      });
    } else if (pStatus === "rejected") {
      this.setState({
        profileReviewStatus: 3,
        reviewStatus: false,
      });
    }
    this.setState({
      showReviewPopUp: true,
      artistId: obj._id,
    });
  };

  handleArtistPagination = () => {
    const { artistList, firstname, lastname, username, email, sortBy } = this.state;
    this.setState({
      ListLoading: true,
    });
    let artist = {
      page: artistList,
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
      sort: sortBy
    };
    this.props.$artistStarredList(artist);
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        const { artistList, firstname, lastname, username, email, sortBy } = this.state;
        let artist = {
          page: artistList,
          firstname: firstname,
          lastname: lastname,
          username: username,
          email: email,
          sort: sortBy
        };
        this.props.$artistStarredList(artist);
      }
    );
  };

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    const { firstname, lastname, username, email, sortBy } = this.state;
    let artist = {
      page: selectedData,
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
      sort: sortBy
    };
    this.setState({artistList: selectedData,page:selectedData})
    this.props.$artistStarredList(artist);
  };

  handlePageClickNew = () => {
    const { firstname, lastname, username, email, sortBy,page } = this.state;
    let artist = {
      page: page,
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
      sort: sortBy
    };
    this.setState({artistList: page})
    this.props.$artistStarredList(artist);
  };

  componentDidMount() {
    this.setState({
      createArtistIs: true,
      artistActive: false,
      artistStarred: true,
    });
    this.handleArtistPagination();
    // this.props.handleClients.mainIndexstate({
    //   profileUpdates: false,
    //   artistStarredIs: true,
    // });
  }

  componentWillReceiveProps(nextProps) {
    const artistListStarred = nextProps._artistStarredList?.starredList;
    if (artistListStarred) {
      this.setState({
        artistIs: artistListStarred.users,
        ListLoading: false,
        totalPages: artistListStarred.totalPages,
      });
    }
  }

  render() {
    const { showReviewPopUp } = this.state;

    const artistFunc = {
      handleOnChange: this.handleOnChange,
      handleShowAction: this.handleShowAction,
      handleReviewStatus: this.handleReviewStatus,
      handlePageClickNew: this.handlePageClickNew
    };

    return (
      <React.Fragment>
        {this.props._artistStarredList?.artistStarredListErrIs ? (
          <ErrorApi {...this.props} />
        ) : (
          <section className="client">
            <div className="container-fluid mx-auto">
              {/************Artist data Table ***************/}
              <ArtistListingTable
                {...this.state}
                {...this.props}
                handleArtist={artistFunc}
              />

              {/****************** Pagination ********************/}

              {this.state.totalPages > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </div>
          </section>
        )}
        {/*----------------------Review Popup -------------------------*/}
        {showReviewPopUp && (
          <ReviewStatusModal
            setArtistState={this.setArtistState}
            {...this.state}
          />
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({ artistDataList, artistStarredList }) => {
  return {
    _artistDataList: artistDataList,
    _artistStarredList: artistStarredList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $artistDataList: (values) => dispatchEvent(artistDataList(values)),
  $artistStarredList: (values) => dispatchEvent(artistStarredList(values)),
});
export default connect(
  mapState,
  mapDispatch
)(Starred);
