import axios from "axios";
import { Baseurl } from "../Utils/BaseUrl";
const token = localStorage.getItem("adminLogin");

const apiInterface = axios.create({
  baseURL: Baseurl,
  headers: {
    "content-type": "application/json",
    Authorization: "Bearer " + token,
  },
});

export const getPublicAPI = (path) => {
  const token = localStorage.getItem("adminLogin");
  return axios({
    method: "get",
    url: Baseurl + path,
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const postAPI = (path, data) => {
  const token = localStorage.getItem("adminLogin");
  return axios({
    method: "post",
    url: Baseurl + path,
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    data: data,
  });
};

export const postImage = (path, data) => {
  return axios.post(Baseurl + path, data, {
    "content-type": "multipart/form-data",
    Authorization: "Bearer",
  });
  // axios({
  //   method: "post",
  //   url: Baseurl + path,
  //   headers: { "content-type": "multipart/form-data", Authorization: "Bearer" },
  //   data: data,
  // });
};

export const postImageWithAuth = (path, data) => {
  const token = localStorage.getItem("adminLogin");

  return axios.post(Baseurl + path, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`
    }
  });
};


export const putAPI = (path, data) => {
  const token = localStorage.getItem("adminLogin");
  return axios({
    method: "put",
    url: Baseurl + path,
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    data: data,
  });
};

export const deleteAPI = (path, data) => {
  const token = localStorage.getItem("adminLogin");
  return axios({
    method: "delete",
    url: Baseurl + path,
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    data: data,
  });
};

export default apiInterface;
