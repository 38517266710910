import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { userProfileBaseUrl } from "../../../../Utils/BaseUrl";
import {
  completedMiles,
  milesComment,
} from "../../../../store/action/transaction.action";
import AsyncImage from "../../Common/asyncImage";
import checkUrl from "../../Common/CheckUrl/CheckMediaUrl";
import {getMedia} from '../../Common/CheckUrl/MediaImage';
import {getThumbnailImage} from "../../Common/CheckUrl/ThumbnailImage";
import moment from "moment";
import Loader from "../Loader/Loader";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    maxWidth: "1000px",
    width: "100%",
    maxHeight:'800px',
  },
};

class SubmitMilestone extends Component {
  state = {
    submittedData: {},
    submittedList: [],
    mainMediaFiles: [],
    mainMediaLinks: [],

    showVersionHistory: false,
    commentIs: [],
    commentActive: false,
    versionData: "",
    
    submittedFiles:[],
    submittedLinks:[],
    submitMilesName:'',
    creatorInfo:'',

    artistProfile:"",
   
  };

  handleViewVersion = () => {
    const { showVersionHistory } = this.state;
    this.setState({
      showVersionHistory: !showVersionHistory,
    });
  };

  handlePreviousData = () => {
    
    // const currentData = this.props._completedMiles.compMilesIs;
    // const data = currentData.submitted;
   
    // this.setState({
      // showVersionHistory: false,
      // submittedData: data,
      // submittedList: currentData.submittedList,
    //   mainMediaFiles: data.files && data.files.length > 0 ? data.files[0] : "",
    //   mainMediaLinks: data.links && data.links.length > 0 ? data.links[0] : "",
    // });

    const submittedFilesData=this.props?.compMiles?.[0]?.allFiles?.filter(el=>el.type==='file');
    const submittedFilesLink=this.props?.compMiles?.[0]?.allFiles?.filter(el=>el.type!=='file');

    this.setState({
      showVersionHistory: false,
      submittedFiles:this.props?.compMiles?.[0]?.allFiles?.filter(el=>el.type==='file'),
      submittedLinks:this.props?.compMiles?.[0]?.allFiles?.filter(el=>el.type!=='file'),
      submittedData:this.props?.compMiles?.[0],
      submittedList:this.props?.compMiles,
      mainMediaFiles:submittedFilesData ? submittedFilesData?.[0] : "" ,
      mainMediaLinks: submittedFilesLink ? submittedFilesLink?.[0] : "",
      versionData: this.props?.compMiles?.[0]?.number,
    })
  };

  handleShowVersionData = (obj) => {

    const submittedFilesData=obj?.allFiles?.filter(el=>el.type==='file');
    const submittedFilesLink=obj?.allFiles?.filter(el=>el.type!=='file');

    // this.setState({
    //   submittedData: obj,
    //   mainMediaFiles: obj.files && obj.files.length > 0 ? obj.files[0] : "",
    //   mainMediaLinks: obj.links && obj.links.length > 0 ? obj.links[0] : "",
    //   showVersionHistory: false,
    //   versionData: obj.number,
    // });

    this.setState({
      submittedFiles:submittedFilesData?.length>0 ? submittedFilesData?.filter(el=>el.type==='file') : [],
      submittedLinks:submittedFilesLink?.length>0 ? submittedFilesLink?.filter(el=>el.type!=='file'): [],
      // submittedData:obj,
      mainMediaFiles:submittedFilesData ? submittedFilesData?.[0] : "" ,
      mainMediaLinks: submittedFilesLink ? submittedFilesLink?.[0] : "",
      versionData: obj?.number ? obj?.number : obj?.serial_no,
    })

    this.handleGetComment(obj._id);
  };

  handleShowThumnailData = (status, obj) => {

    if (status === "files") {
      this.setState({
        mainMediaFiles: obj,
        mainMediaLinks: "",
      });
    } else {
      this.setState({
        mainMediaLinks: obj,
        mainMediaFiles: "",
      });
    }
  };

  handleGetComment = (id) => {
    this.setState({ commentActive: true });
    this.props.$milesComment(id);
  };

  // componentWillReceiveProps(nextProps) {
  //   console.log(nextProps,"--------------------> nextprops of submitmilestone")
 
    
    // const submittedFilesData=nextProps?.compMiles?.[0]?.allFiles?.filter(el=>el.type==='file');
    // const submittedFilesLink=nextProps?.compMiles?.[0]?.allFiles?.filter(el=>el.type!=='file');
    

    // const commentData = nextProps?.MilesData?.newProposalList[0].deliverables.filter(data => data.type != 'token');


    // const { commentActive } = this.state;
    // if (commentActive) {
    //   this.setState({
    //     commentIs: nextProps.compMiles,
    //     commentActive: false,
    //     creatorInfo : nextProps?.MilesData?.user_id2
    //   });
    // }


    // this.setState({
    //   submittedFiles:nextProps?.compMiles?.[0]?.allFiles?.filter(el=>el.type==='file'),
    //   submittedLinks:nextProps?.compMiles?.[0]?.allFiles?.filter(el=>el.type!=='file'),
    //   submittedData:nextProps?.compMiles?.[0],
    //   submittedList:nextProps?.compMiles,
    //   mainMediaFiles:submittedFilesData ? submittedFilesData?.[0] : "" ,
    //   mainMediaLinks: submittedFilesLink ? submittedFilesLink?.[0] : "",
    //   versionData: nextProps?.compMiles?.[0]?.number?nextProps?.compMiles?.[0]?.number:nextProps?.compMiles?.[0]?.serial_no,
    //   submitMilesName:nextProps?.submitMilesName,
    // })
  // }

  componentDidMount() {
    setTimeout(() => {
      const milestoneId=this.props?.compMiles?.[0]?._id
      // const currentData = this.props._completedMiles.compMilesIs;
      // const data = currentData.submitted;
      // const data2=data?.filter(el=>el.files!==[])
      // this.setState({
        // submittedData: data,
        // submittedList: currentData.submittedList,
        // mainMediaFiles:
        //   data?.files && data?.files.length > 0 ? data?.files[0] : "",
        // mainMediaLinks:
        //   data?.links && data?.links.length > 0 ? data?.links[0] : "",
        // versionData: data?.number,
      // });

      // console.log(this.props,"--------------------> props of submitmilestone")
 
    
      const submittedFilesData=this.props?.compMiles?.[0]?.allFiles?.filter(el=>el.type==='file');
      const submittedFilesLink=this.props?.compMiles?.[0]?.allFiles?.filter(el=>el.type!=='file');
      
  
      // console.log(submittedFilesData,submittedFilesLin

      this.setState({
        submittedFiles:this.props?.compMiles?.[0]?.allFiles?.filter(el=>el.type==='file'),
        submittedLinks:this.props?.compMiles?.[0]?.allFiles?.filter(el=>el.type!=='file'),
        submittedData:this.props?.compMiles?.[0],
        submittedList:this.props?.compMiles,
        mainMediaFiles:submittedFilesData ? submittedFilesData?.[0] : "" ,
        mainMediaLinks: submittedFilesLink ? submittedFilesLink?.[0] : "",
        versionData: this.props?.compMiles?.[0]?.number?this.props?.compMiles?.[0]?.number:this.props?.compMiles?.[0]?.serial_no,
        submitMilesName:this.props?.submitMilesName,
        commentIs: this.props?.compMiles,
        commentActive: false,
        creatorInfo : this.props?.MilesData?.user_id2
      })

      this.handleGetComment(milestoneId);
    }, 200);
  }

 

  render() {
    const {
      submitMilesOpen,
      handleMiles,
      deliverableMiles,
    } = this.props;
    const {
      submittedData,
      submittedList,
      mainMediaFiles,
      mainMediaLinks,
      showVersionHistory,
      commentIs,
      versionData,
      submittedFiles,
      submittedLinks,
      submitMilesName,
      creatorInfo,
      artistProfile
    } = this.state;
    return (
      <React.Fragment>
        <div>
          <Modal
            isOpen={submitMilesOpen}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <button
              type="button"
              className="close media-upload-modal"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleMiles.handleCloseMilesModal}
            >
              <span
                aria-hidden="true"
                style={{ color: "black", fontSize: "32px" }}
              >
                &times;
              </span>
            </button>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-8">
                    <h3 className="mb-0">
                      Milestone {deliverableMiles} Deliverables
                    </h3>
                    <small>Version {versionData}</small>
                  </div>
                  <div className="col-4">
                    {!showVersionHistory && (
                      <h3 className="text-left">Feedback</h3>
                    )}
                    {submittedList?.length > 1 && (
                      <div className="text-right mr-4">
                        <div
                          className="badge badge-soft-secondary"
                          onClick={this.handleViewVersion}
                        >
                          <div style={{ cursor: "pointer" }}>
                            {showVersionHistory ? (
                              <i
                                className="fe fe-arrow-left "
                                onClick={this.handlePreviousData}
                              >
                                Version history
                              </i>
                            ) : (
                              "View Version history"
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col">
                <p>{submitMilesName}</p>
                <hr className="my-5" />
                <div
                  className="carasoul-image"
                >
                  
                  {(mainMediaFiles?.name || mainMediaLinks?.name)
                  ?
                  mainMediaFiles
                    ?mainMediaFiles.name && getMedia(mainMediaFiles.name)
                    : mainMediaLinks &&
                      mainMediaLinks.name && (
                        <div>
                          {mainMediaLinks.name && checkUrl(mainMediaLinks.name)}
                        </div>
                      )
                    :<div className="project_big_image align-middle">
                    <Loader/>
                    </div>
                    }
                </div>
                {/*-------------------------thumbnail ------------------------*/}
                <div className="row mt-4">
                  
                  {submittedFiles &&
                  
                    submittedFiles?.map((obj, index) => (
                          <div
                        className="thumbnail-div col-3 mb-3"
                        key={index}
                        onClick={() =>
                          {this.handleShowThumnailData("files", obj);
                        }
                        }
                      >
                          {getThumbnailImage(obj?.name)}
                          </div>         
                    ))
                    }
                  {/* {submittedData &&
                  
                    submittedData?.map((obj, index) => (
                      
                        obj?.allFiles?.map((item)=>(
                           <div
                        className="thumbnail-div col-3 mb-3"
                        key={index}
                        onClick={() =>
                          item?.type==='file'?
                          this.handleShowThumnailData("files", item):
                          item?.type==='links'?
                          this.handleShowThumnailData("links",item):""
                        }
                      >
                          {(item?.type==='file')?getThumbnailImage(item.name):(item?.type==='links')?<AsyncImage imageurl={obj.link}/>:""}
                          </div>                       
                          ))
                      
                    ))
                    } */}
                  {submittedLinks &&
                    submittedLinks?.map((obj, index) => (
                      <div
                        className="thumbnail-div col-3 mb-3"
                        key={index}
                        onClick={() =>
                          this.handleShowThumnailData("links", obj)
                        }
                      >
                        {obj.name && <AsyncImage imageurl={obj.name} />}
                      </div>
                    ))}
                </div>
                {/*-------------------------thumbnail ------------------------*/}
              </div>
              {/*-------------------------version data && comment------------------------*/}
              <div className="col-4 overflow-content user-work-content">
                <hr className="my-3" />
                {showVersionHistory
                  ? submittedList &&
                    submittedList?.length > 1 &&
                    submittedList?.map((obj, index) => (
                      <React.Fragment key={index}>
                        <div
                          className="row align-items-center"
                          onClick={() => this.handleShowVersionData(obj)}
                        >
                          {/* <div className="col-auto">
                          <a href="#!" className="avatar avatar-4by3">
                            <img
                              src="assets/img/avatars/projects/project-1.jpg"
                              alt="..."
                              className="avatar-img rounded"
                            />
                          </a>
                        </div> */}
                          <div className="col ml-n2" style={{cursor:"pointer"}}> 
                            <h4 className="card-title mb-1">
                              <a >
                                {obj.number ? `Version ${obj.number} >` : ""}
                              </a>
                            </h4>
                            <p className="card-text small text-muted">
                              <time>
                                Submitted on{" "}
                                {obj?.createdAt && moment(obj?.createdAt).format("DD/MM/YYYY")}
                              </time>
                            </p>
                          </div>
                        </div>
                        <hr />
                      </React.Fragment>
                    ))
                  : commentIs.length > 0
                  ? commentIs.map((obj, index) => (
                      <React.Fragment key={index}>
                        {obj.description ? 
                        <>
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <a href="#!" className="avatar avatar-4by3">
                              <img
                                src={`${userProfileBaseUrl}${creatorInfo?.profile_image?creatorInfo?.profile_image:artistProfile}`}
                                alt="..."
                                className="avatar-img rounded"
                              />
                            </a>
                          </div>
                          <div className="col ml-n2">
                            <h4 className="card-title mb-1">
                              {creatorInfo &&
                                creatorInfo ?.first_name +
                                  " " +
                                  creatorInfo ?.last_name}
                            </h4>
                            <p className="card-text small text-muted">
                              {obj.description
                                ? 
                                obj.description
                                    .replace(/<[^>]+>/g, "")
                                    .replace(/&nbsp;/g, "")
                                : ""}
                            </p>
                          </div>
                        </div> 
                          <hr />
                          </>
                        :""}
                      </React.Fragment>
                    ))
                  : "No data found.."}
              </div>
              {/*-------------------------version data && comment------------------------*/}
            </div>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}
const mapState = ({ completedMiles, milesComment }) => {
  return {
    _completedMiles: completedMiles,
    _milesComment: milesComment,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $completedMiles: (values) => dispatchEvent(completedMiles(values)),
  $milesComment: (values) => dispatchEvent(milesComment(values)),
});
export default connect(mapState, mapDispatch)(SubmitMilestone);
