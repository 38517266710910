import axios from "axios";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import {
    getArtistData,
    artistDetails,
    artistProfile,
    locationUser,
} from "../../../store/action/clientList.action";
import { getPublicAPI, putAPI } from "../../../store/api-interface";
import Loader from "../Common/Loader/Loader";


class ArtistNotification extends Component {

    state = {
        loading: true,
        gupshup_status: 0,
        gupshup_recieve_brief_status: 0,
        gupshup_due_milestone_status: 0,
        gupshup_client_receive_proposal: 0,

    }

    fetchWhatsappNotification() {
        getPublicAPI('/gupshup/get-current-whatsapp-notification?_id=' + this.props.match.params.id.substring(1)).then(res => {
            const gupshup_status = res.data.userNotification.gupshup_status
            const gupshup_client_receive_proposal = res.data.userNotification.gupshup_client_receive_proposal
            const gupshup_due_milestone_status = res.data.userNotification.gupshup_due_milestone_status
            const gupshup_recieve_brief_status = res.data.userNotification.gupshup_recieve_brief_status
            this.setState({ gupshup_client_receive_proposal, gupshup_due_milestone_status, gupshup_recieve_brief_status, gupshup_status, loading: false })
        })
    }
    handleChange(name, status) {
        // this.setState({[name]:status})
        console.log([name], 'hit', status)
        putAPI('/gupshup/toggle-notification-admin', { disable_key: name, istrue: status === 1 ? true : false, user_id: this.props.match.params.id.substring(1) }).then(e => {
            // this.setState({[name]:status})
            this.fetchWhatsappNotification()
        })

    }
    componentDidMount() {
        this.fetchWhatsappNotification();

    }

    render() {

        return (
            <React.Fragment>
                {this.state.loading ? <Loader />
                    : <>
                        <div class="card-header">
                            <p className="mb-0">WhatsApp Notification</p>
                            <div class="form-check form-switch" onClick={e => this.handleChange('gupshup_status', (this.state.gupshup_status == 2 || this.state.gupshup_status == 0) ? 1 : 2)}>
                                <input class="cm-toggle" type="checkbox" checked={this.state.gupshup_status == 1 ? true : false} />
                            </div>


                        </div>
                        <div class="card-header pl-5">
                            <p className="mb-0 text_sm">Incoming Brief</p>
                            <div class="form-check form-switch pl-0" onClick={e => this.handleChange('gupshup_recieve_brief_status', (this.state.gupshup_recieve_brief_status == 1 || this.state.gupshup_status == 2 || this.state.gupshup_status == 0) ? 0 : 1)}>
                                <input class="cm-toggle cm-toggle-sm" type="checkbox" checked={this.state.gupshup_recieve_brief_status ? true : false} />
                            </div>


                        </div>
                        <div class="card-header pl-5">
                            <p className="mb-0 text_sm">Milestone Due</p>
                            <div class="form-check form-switch pl-0" onClick={e => this.handleChange('gupshup_due_milestone_status', this.state.gupshup_due_milestone_status || this.state.gupshup_status == 2 || this.state.gupshup_status == 0 ? 0 : 1)}>
                                <input class="cm-toggle cm-toggle-sm" type="checkbox" checked={this.state.gupshup_due_milestone_status ? true : false} />
                            </div>


                        </div></>}
            </React.Fragment>
        );
    }
}
const mapState = ({
    getArtistData,
    artistDetails,
    artistProfile,
    locationUser,
}) => {
    return {
        _getArtistData: getArtistData,
        _artistDetails: artistDetails,
        _artistProfile: artistProfile,
        _locationUser: locationUser,
    };
};
const mapDispatch = (dispatchEvent) => ({
    $getArtistData: (values) => dispatchEvent(getArtistData(values)),
    $artistDetails: (values) => dispatchEvent(artistDetails(values)),
    $artistProfile: (values) => dispatchEvent(artistProfile(values)),
    $locationUser: (values) => dispatchEvent(locationUser(values)),
});
export default connect(mapState, mapDispatch)(ArtistNotification);
