import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { rejectCreatorData } from "../../../../store/action/transaction.action";

class CreatorRejectsBriefForm extends Component {
  state = {
    transactionName: "",
    collateralName: "",
    creatorName: "",
    creatorEmail: "",
    creatorNumber: "",
    clientName: "",
    startBudget: "",
    endBudget: "",
    recievedDate: "",
    rejectedDate: "",
    clientCompany: "",
    clientEmail : '',
    clientNumber : '',
    reasonIs: [],
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleCreatorData = (id) => {
    this.props.$rejectCreatorData(id);
  };

  componentDidMount() {
    let creatorId = this.props.match.params.id.split(":").slice(0)[1];
    this.handleCreatorData(creatorId);
  }

  componentWillReceiveProps(nextProps) {
    const DataIs = nextProps._rejectCreatorData.rejectionDataIs;
    if (typeof DataIs === "object") {
      this.setState({
        transactionName: DataIs.project ? DataIs.project.project_name : "",
        collateralName: DataIs.collateral ? DataIs.collateral.name : "",
        creatorName: DataIs.creator
          ? DataIs.creator.first_name + " " + DataIs.creator.last_name
          : "",
        creatorEmail: DataIs.creator ? DataIs.creator.email : "",
        creatorNumber: DataIs.creator ? DataIs.creator.phone_no : "",
        clientName: DataIs.client
          ? DataIs.client.first_name + " " + DataIs.client.last_name
          : "",
        startBudget: DataIs.project ? DataIs.project.min_budget : "",
        endBudget: DataIs.project ? DataIs.project.max_budget : "",
        recievedDate: DataIs.rejectBreif && DataIs?.rejectBrief?.createdAt
          ? moment(DataIs.rejectBreif.createdAt).format("DD/MM/YYYY")
          : "",
        rejectedDate: DataIs.rejectBreif && DataIs?.rejectBreif?.updatedAt
          ? moment(DataIs.rejectBreif.updatedAt).format("DD/MM/YYYY")
          : "",
        clientCompany: DataIs.userCompany ? DataIs.userCompany.company_name : "",
        reasonIs: DataIs.rejectBreif ? DataIs.rejectBreif.reason : "",
        clientEmail : DataIs.client ? DataIs.client.email : '',
        clientNumber : DataIs.client ? DataIs.client.phone_no :''
      });
    }
  }

  render() {
    const {
      transactionName,
      collateralName,
      creatorName,
      creatorEmail,
      creatorNumber,
      clientName,
      startBudget,
      endBudget,
      // recievedDate,
      rejectedDate,
      clientCompany,
      reasonIs,
      clientEmail,
      clientNumber
    } = this.state;
    const dataArr = [
      {
        name: "transactionName",
        value: transactionName,
        label: "Transaction Name",
      },
      {
        name: "collateralName",
        value: collateralName,
        label: "Collateral Name",
      },
      {
        name: "creatorName",
        value: creatorName,
        label: "Creator Name",
      },
      { name: "creatorEmail", value: creatorEmail, label: "Creator email" },
      {
        name: "creatorNumber",
        value: creatorNumber,
        label: "Creator Phone Number",
      },
      { name: "clientName", value: clientName, label: "Client Name" },
      { name: "clientEmail", value: clientEmail, label: "Client Email" },
      { name: "clientNumber", value: clientNumber, label: "Client Phone Number" },
      {
        name: "startBudget",
        value: startBudget,
        label: "Min Budget",
      },
      { name: "endBudget", value: endBudget, label: "Max Budget" },
      // {
      //   name: "recievedDate",
      //   value: recievedDate,
      //   label: "Brief Recieved Date",
      // },
      {
        name: "rejectedDate",
        value: rejectedDate,
        label: "Brief Rejected Date",
      },
      { name: "clientCompany", value: clientCompany, label: "Client Company" },
    ];
    return (
      <React.Fragment>
        <section className="container">
          <div className="row">
            {dataArr.map((obj, index) => (
              <div className="col-12 col-md-6" key={index}>
                <div className="form-group">
                  <label>{obj.label}</label>
                  <input
                    type="text"
                    className="form-control"
                    name={obj.name}
                    value={obj.value}
                    onChange={this.handleOnChange}
                    disabled="disabled"
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            {Array.isArray(reasonIs) && (
              <label className="col-12 ">Reason</label>
            )}
            {Array.isArray(reasonIs)
              ? reasonIs.map((obj, index) => (
                  <div className="col-4 mb-2" key={index}>
                    <div className="questin-Listing">
                      <h5>{obj.text}</h5>
                    </div>
                  </div>
                ))
              : ""}
          </div>
        </section>
      </React.Fragment>
    );
  }
}
const mapState = ({ rejectCreatorData }) => {
  return {
    _rejectCreatorData: rejectCreatorData,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $rejectCreatorData: (values) => dispatchEvent(rejectCreatorData(values)),
});
export default connect(mapState, mapDispatch)(CreatorRejectsBriefForm);
