import moment from "moment";

export const separateNumber = (number, obj) => {

    if (obj && obj.isWords && number >= 1000) {
        let val = Math.abs(number)

        if (val >= 10000000) {
            val = (val / 10000000).toFixed(2) + 'Cr';
        } else if (val >= 100000) {
            val = (val / 100000).toFixed(2) + 'L';
        } else if (val >= 1000) {
            val = (val / 1000).toFixed(2) + 'K';
        }
        return val;
    }

    const num = parseInt(number, 10)
    let output = num.toLocaleString('en-IN');
    return output;
}

export const addDays = (date, day) => {
    var result = new Date(date);
    result.setDate(result.getDate() + day);
    return result;
  };

  export const   addHrMinDate = (value, hr, min) => {
    let date = new Date(value);
    date.setHours(date.getHours() + hr);
    date.setMinutes(date.getMinutes() + min);
    return date;
  };

  export const setTimeToZero = (value) => {
    var time = moment(value).toDate();
    time.setHours(0);
    time.setMinutes(0);
    time.setSeconds(0);
    time.setMilliseconds(0);
  
    return time;
  };

export const removeHttps = (url) => {
    if (!url && !url.trim()) return
    return url.replace(/^https?:\/\//i, "")
}

export const sanitiseSummernoteText = (txt) => {
    if (!txt) return
    // remove br tag from begin and end
    var htmlObject = document.createElement('div');
    htmlObject.innerHTML = txt;
    let temp = htmlObject?.innerText
    // let text = temp
    let text = txt.replace(/(?:^<p[^>]*>)|(?:<\/p>$)/g, '')
    text = text.replace(/^(\s*<br( \/)?>)*|(<br( \/)?>\s*)*$/gm, '')
    text = text.replace(/<span[^>]*>|<\/span>/gm, '')
    text = `<p>${text}</p>`
    if (text.includes('target="_blank"')) return text;

    // if there is url but no anchor tag then make it
    // eslint-disable-next-line
    // var urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9+&@:%._\+~#=]{2,256}\.[a-z]{2,100}\b([-a-zA-Z0-9+&@:%_\+.~#?&//=]*)/;

    var urlRegex = /(?:(?:https?|ftp|file):\/\/|(www)?([-A-Z0-9+&@#\/])*?\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.;]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.;])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.;]*\)|[A-Z0-9+&@#\/%=~_|$])/igm;

    const finalText = text.replace(urlRegex, function (url) {
        // console.log(url,'-------------url---------------')
        const urlWithoutHTTP = removeHttps(url)
        return '<a target="_blank" href="http://' + urlWithoutHTTP + '">' + url + '</a>';
    })

    // console.log(finalText)
    return finalText
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
}
