import React, { Component, setTimeOut } from "react";
import { Link } from "react-router-dom";
import Loader from "../Common/Loader/Loader";
import { connect } from "react-redux";
import { milesList } from "../../../store/action/Milestone.action";
import {
  closeProject,
  deleteProjectList,
} from "../../../store/action/transaction.action";
import CloseProjectReasonModal from "../Transactions/CloseProjectReasonModal";
import ErrorApi from "../Common/Error/ErrorApi";
import ReactPaginate from "react-paginate";
import * as moment from "moment";
import { getPublicAPI } from "../../../store/api-interface";
import ProposalModal from "../Transactions/ProposalModal";
import Select from "react-select";
import _ from "lodash";
import {
  inActBriefForAm,
  inactiveProject,
  activeTransactions,
  transactionList,
  completeProject,
  closedBriefAllList,
  closeTransactionList,
  deleteProjectAllList,
  deleteBriefList,
} from "../../../store/action/transaction.action";
// import OfflinePayment from "../../TableMethod/PaymentOffline";
import OfflinePayment from "../Transactions/TableMethod/PaymentOffline";
// import NotesModal from "../Common/ModalCommon/NotesModal";

class Milestones extends Component {
  state = {
    LoadingIs: false,
    milesName: "",
    pageNumber: 1,
    pageSize: 10,
    mileDataIs: [],
    listToShow: [],
    filteredList: [],
    totalPagesIs: 0,
    dateStatus: null,
    showProposalModal: false,
    MilesData: "",
    projectDataIs: {},
    managerValue: { value: "all", label: "All Managers" },
    clientValue: { value: "all", label: "All Clients" },
    creatorValue: { value: "all", label: "All Creators" },
    collateralValue: { value: "all", label: "All Collaterals" },
    statusValue: { value: "all", label: "All Status" },
    projectValue: { value: 2, label: "Active Projects" },
    modalIsOpen: false,
    // inActiveBrief : true,
    inActiveLoader: false,
    inActiveProLoader: false,
    activeProLoader: false,
    activeBriefLoader: false,
    completeProLoader: false,
    closedBriefLoader: false,
    closedProLoader: false,
    deletedProLoader: false,
    deleteBriefLoader: false,
    keyCheck: true,
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.handleFilter(1);
    });
  };

  handlePageClick = (data) => {
    const selectedPage = data.selected + 1;

    this.setState({ pageNumber: selectedPage }, () => this.handleFilter());
  };

  getStatus = (a) => {
    if (a) {
      const x = moment(a.split("T").slice(0)[0]);
      const y = moment(new Date().toDateString());
      const r = x.diff(y, "days");
      return r;
    }
  };

  getDateTime = (end, start) => {
    let result = "";
    var diff = "";
    if (!end || !start) {
      result = "NA";
    } else {
      let createAt = new Date(end);
      let approveAt = new Date(start);
      diff = (createAt.getTime() - approveAt.getTime()) / 1000;
      diff /= 60 * 60;
      if (diff < 0) {
        result = "NA";
      } else {
        result = Math.round(diff * 60) + " min";
      }
    }
    return result;
  };

  handleMileStoneList = () => {
    this.setState({
      LoadingIs: true,
      activeProLoader: true,
      totalPagesIs: 0,
      pageNumber: 1,
    });
    const { pageNumber, milesName, projectValue } = this.state;
    const dataIs = {
      name: milesName,
      pageIs: pageNumber,
      type: projectValue.value,
    };
    this.props.$activeTransactions();
  };

  // handleViewProposal = (e, obj) => {
  //   e.preventDefault();
  //   const user_id2 = {
  //     _id: obj.creator._id,
  //     first_name: obj.creator.first_name,
  //     last_name: obj.creator.last_name,
  //     profile_image: obj.creator.profile_image,
  //   };
  //   obj.user_id2 = user_id2;

  //   this.setState({ showProposalModal: true, MilesData: obj });
  // };

  getTotalBudgetApproved = (arr) => {
    const paidarr = arr.filter((m) => m.status == 2);
    // console.log(paidarr)
    // userjson.avg_brief_response_time= AvgBriefResponseTime.reduce(function(acc, val) { return acc + val; }, 0)
    const result = paidarr.reduce(function (acc, val) {
      return acc + val.price;
    }, 0);
    // console.log(result)
    return result;
  };

  handleViewProposal = (obj) => {
    // console.log("obj = ",obj)
    this.setState({
      showProposalModal: true,
      MilesData: obj,
      projectDataIs: obj,
    });
  };

  handleCloseProposalModal = () => {
    this.setState({ showProposalModal: false });
  };

  paginate = (array, pageNumber) => {
    const { pageSize } = this.state;
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  };

  handleCloseModal = () => {
    this.setState({ modalIsOpen: false });
  };

  componentDidMount() {
    this.handleMileStoneList();
  }

  handleFilter = (page) => {
    const {
      mileDataIs,
      managerValue,
      clientValue,
      creatorValue,
      collateralValue,
      statusValue,
      milesName,
      projectValue,
    } = this.state;

    let newFilter = [];

    newFilter = mileDataIs.filter((obj) =>
      obj?.manager_name && managerValue?.value !== "all"
        ? obj?.manager_name === managerValue?.value
        : obj
    );

    newFilter = newFilter.filter((obj) =>
      clientValue?.value !== "all"
        ? obj?.user
          ? obj?.user?.first_name?.toLowerCase() ===
            clientValue?.value?.toLowerCase()
          : obj?.client?.first_name?.toLowerCase() ===
            clientValue?.value?.toLowerCase()
        : obj
    );
    if (
      projectValue.value !== 1 &&
      projectValue.value !== 4 &&
      projectValue.value !== 7
    ) {
      newFilter = newFilter.filter((obj) =>
        creatorValue?.value !== "all"
          ? // ? obj?.creator?.first_name?.toLowerCase() ===
            //   creatorValue?.value?.toLowerCase()
            obj?.proposal?.creator?.first_name?.toLowerCase() ===
            creatorValue?.value?.toLowerCase()
          : obj
      );
    }

    newFilter = newFilter.filter((obj) =>
      collateralValue?.value !== "all"
        ? obj?.collateral?.name?.toLowerCase() ===
          collateralValue?.value.toLowerCase()
        : obj
    );
    newFilter = newFilter.filter((obj) =>
      statusValue?.value !== "all"
        ? statusValue?.value === "delayed"
          ? this.getStatus(obj?.due_date) < 0
          : this.getStatus(obj?.due_date) >= 0
        : obj
    );

    newFilter = newFilter.filter((obj) =>
      milesName
        ? obj?.project?.manager_name?.toLowerCase()?.startsWith(milesName)
        : obj
    );

    let pageNumber = page ? page : this.state.pageNumber;
    this.setState({
      listToShow: this.paginate(newFilter, pageNumber),
      pageNumber,
      totalPagesIs: Math.ceil(newFilter.length / this.state.pageSize),
    });
  };

  handleSelect = (obj, state) => {
    this.setState({ [state]: obj }, () => {
      this.handleFilter(1);
    });
  };

  sortAndUnique = (arr, label) => {
    arr = arr.sort((a, b) => (a !== b ? (a < b ? -1 : 1) : 0));
    arr = [...new Set(arr)];
    const newArr = [{ value: "all", label }];
    arr.forEach((str) =>
      newArr.push({
        value: str,
        label: str?.charAt(0)?.toUpperCase() + str?.slice(1),
      })
    );
    return newArr;
  };

  getAllOptions = () => {
    const { mileDataIs, projectValue } = this.state;
    let clients = [],
      creators = [],
      managers = [],
      collaterals = [];

    mileDataIs.forEach((obj) => {
      if (obj?.manager_name) managers.push(obj?.manager_name);

      clients.push(
        obj.user
          ? obj?.user.first_name?.toLowerCase()
          : obj?.client?.first_name?.toLowerCase()
      );
      if (
        projectValue.value !== 1 &&
        projectValue.value !== 4 &&
        projectValue.value !== 7
      ) {
        // creators.push(obj?.creator?.first_name?.toLowerCase());
        obj?.proposal &&
          creators.push(obj?.proposal?.creator?.first_name?.toLowerCase());
      }

      collaterals.push(obj?.collateral?.name);
    });

    clients = this.sortAndUnique(clients, "All Clients");
    creators = this.sortAndUnique(creators, "All Creators");
    managers = this.sortAndUnique(managers, "All Managers");
    collaterals = this.sortAndUnique(collaterals, "All Collaterals");
    return { clients, creators, managers, collaterals };
  };

  getInactiveBriefList = () => {
    this.setState({
      inActiveLoader: true,
    });
    this.props.$inActBriefForAm();
  };

  getInActiveProjectList = () => {
    this.setState({
      inActiveProLoader: true,
    });
    this.props.$inactiveProject();
  };

  activeProList = () => {
    this.setState({
      activeProLoader: true,
    });
    this.props.$activeTransactions();
  };

  transactionsList = () => {
    this.setState({
      activeBriefLoader: true,
    });
    this.props.$transactionList();
  };

  completeProList = () => {
    this.setState({
      completeProLoader: true,
    });
    this.props.$completeProject();
  };

  closedBriefList = () => {
    this.setState({
      closedBriefLoader: true,
    });
    this.props.$closedBriefAllList();
  };

  closedProList = () => {
    this.setState({
      closedProLoader: true,
    });
    this.props.$closeTransactionList();
  };

  deleteProList = () => {
    this.setState({
      deletedProLoader: true,
    });
    this.props.$deleteProjectAllList();
  };

  deleteBriefList = () => {
    this.setState({
      deleteBriefLoader: true,
    });
    this.props.$deleteBriefList();
  };

  handleProjectSelect = (e) => {
    // console.log("project value = ", e);
    let obj = {
      projectValue: e,
    };

    if (e.value == 1 || e.value == 4 || e.value == 7) {
      obj.creatorValue = { value: "all", label: "All Creators" };
      obj.statusValue = { value: "all", label: "All Status" };
    } else if (e.value == 3 || e.value == 5 || e.value == 6) {
      obj.statusValue = { value: "all", label: "All Status" };
    }

    this.setState(obj);
    if (e.value === 9) {
      this.getInActiveProjectList();
    } else if (e.value === 8) {
      this.getInactiveBriefList();
    } else if (e.value === 2) {
      this.activeProList();
    } else if (e.value === 1) {
      this.transactionsList();
    } else if (e.value === 3) {
      this.completeProList();
    } else if (e.value === 4) {
      this.closedBriefList();
    } else if (e.value === 5) {
      this.closedProList();
    } else if (e.value === 6) {
      this.deleteProList();
    } else if (e.value === 7) {
      this.deleteBriefList();
    }
    this.setState({
      LoadingIs: true,
      listToShow: [],
    });
  };

  parentState=(state)=>{this.setState({[state]:true});}

  handleDeleteConfirm = async (id) => {
    let confirmIs = window.confirm(`Do you want to delete this project ?`);
    if (confirmIs === true) {
      // console.log("handleDeleteConfirm = ", id);
      let res = await this.handleDeleteProject(id);
      if (res) {
        // this.handleMileStoneList();
      }
      return true;
    } else {
      return false;
    }
  };

  handleDeleteProject = async (id) => {
    this.setState({ deleteActiveIs: true });
    const currentData = { _id: id };
    let res = await this.props.$deleteProjectList(currentData);
    return res;
  };

  currentMilestone = (data) =>{
    for ( let x = 0; x<data?.length;x++){
      if(data[x]?.status==1){
        return x+1
      }
    }
    return 0;
  }

  handleCloseStatus = async (id) =>
    getPublicAPI(`/postProject/check-close-review?_id=${id}`).then((res) => {
      const response = res.data.status;
      if (response === "0") {
        this.setState({ modalIsOpen: true });
      } else {
        let confirmIs = window.confirm(`Do you want to countinue ?`);
        if (confirmIs === true) {
          this.handleApis(id);
          return true;
        } else {
          return false;
        }
      }
    });

  handleApis = async (id) => {
    let res = await this.handleCloseProject(id);
    if (res) {
      // this.handleMileStoneList();
    }
  };

  handleCloseProject = async (id) => {
    this.setState({
      closeProjectActive: true,
    });
    const closeProjectData = { _id: id };
    let res = await this.props.$closeProject(closeProjectData);
    return res;
  };

  handleCloseProjectConfirmation(id) {
    this.setState({
      transctionId: id,
    });
    this.handleCloseStatus(id);
  }

  getProject = (project_name) => {
    // console.log("*******", project_name)
    const pNameIs = `${project_name?.substring(0, 30)}${
      project_name?.substring(30) ? "..." : ""
    }`;
    return pNameIs;
  };

  getProjectUrl = (obj) => {
    const url =
      (obj.type === "post_project_admin" || obj.were_admin_project)
      ?`/transactions/addProject/:${obj._id}`
      : `/transactions/transactionsDetails/:${obj._id}`
    return url;
  };

  setAllData = (data) => {
    this.setState(
      {
        listToShow: this.paginate(data.PostProjects, this.state.pageNumber),
        LoadingIs: false,
        totalPagesIs: Math.ceil(
          data.PostProjects?.length / this.state.pageSize
        ),
      },
      () => {
        this.handleFilter(1);
      }
    );
  };

  getInActiveBrief = (inActiveBrief) => {
    this.setState(
      {
        inActiveLoader: false,
        LoadingIs: false,
        mileDataIs: inActiveBrief.PostProjects,
      },
      () => {
        this.setAllData(inActiveBrief);
      }
    );
  };

  getInActiveProject = (inactiveProjectIs) => {
    this.setState(
      {
        inActiveProLoader: false,
        LoadingIs: false,
        mileDataIs: inactiveProjectIs.PostProjects,
      },
      () => {
        this.setAllData(inactiveProjectIs);
      }
    );
  };

  getInActiveProject = (activeTransactions) => {
    this.setState(
      {
        activeProLoader: false,
        LoadingIs: false,
        mileDataIs: activeTransactions.PostProjects,
      },
      () => {
        this.setAllData(activeTransactions);
      }
    );
  };

  getCompleteProject = (completeProject) => {
    this.setState(
      {
        completeProLoader: false,
        LoadingIs: false,
        mileDataIs: completeProject.PostProjects,
      },
      () => {
        this.setAllData(completeProject);
      }
    );
  };

  getClosedBrief = (closedBriefIs) => {
    this.setState(
      {
        closedBrieLoader: false,
        LoadingIs: false,
        mileDataIs: closedBriefIs.PostProjects,
      },
      () => {
        this.setAllData(closedBriefIs);
      }
    );
  };

  getClosedProject = (closedProject) => {
    this.setState(
      {
        closedProLoader: false,
        LoadingIs: false,
        mileDataIs: closedProject.PostProjects,
      },
      () => {
        this.setAllData(closedProject);
      }
    );
  };

  getdeletedProject = (deletedProIs) => {
    this.setState(
      {
        deletedProLoader: false,
        LoadingIs: false,
        mileDataIs: deletedProIs.PostProjects,
      },
      () => {
        this.setAllData(deletedProIs);
      }
    );
  };

  getdeleteBrief = (deleteBriefIs) => {
    this.setState(
      {
        deleteBriefLoader: false,
        LoadingIs: false,
        mileDataIs: deleteBriefIs.PostProjects,
      },
      () => {
        this.setAllData(deleteBriefIs);
      }
    );
  };

  getActiveBrief = (activeBriefIs) => {
    this.setState(
      {
        activeBriefLoader: false,
        LoadingIs: false,
        mileDataIs: activeBriefIs.PostProjects,
      },
      () => {
        this.setAllData(activeBriefIs);
      }
    );
  };

  handleCloseProjectMilestone = (obj) => {
    let milestone = obj?.filter((el) => el?.status == 1);
    return milestone?.[0]?.serial_no;
  };

  componentWillReceiveProps(nextProps) {
    const inActiveBrief = nextProps._inActBriefForAm.inActBriefData;
    const inactiveProjectIs = nextProps._inactiveProject.inactiveProjectData;
    const activeTransactions = nextProps._activeTransactions.activeList;
    const completeProject = nextProps._completeProject.completeTransactionsIs;
    const closedBriefIs = nextProps._closedBriefAllList.closedBriefAll;
    const closedProject = nextProps._closeTransactionList.closedTransactionIs;
    const deletedProIs = nextProps._deleteProjectAllList.deleteProjectsIs;
    const deleteBriefIs = nextProps._deleteBriefList.deletedBriefsIs;
    const activeBriefIs = nextProps._transactionList.transactionDetails;
    const {
      inActiveLoader,
      activeBriefLoader,
      inActiveProLoader,
      activeProLoader,
      completeProLoader,
      closedBriefLoader,
      closedProLoader,
      deletedProLoader,
      deleteBriefLoader,
    } = this.state;

    if (inActiveBrief && inActiveLoader) {
      this.getInActiveBrief(inActiveBrief);
    }
    if (inactiveProjectIs && inActiveProLoader) {
      this.getInActiveProject(inactiveProjectIs);
    }
    if (activeTransactions && activeProLoader) {
      this.getInActiveProject(activeTransactions);
    }
    if (completeProject && completeProLoader) {
      this.getCompleteProject(completeProject);
    }
    if (closedBriefIs && closedBriefLoader) {
      this.getClosedBrief(closedBriefIs);
    }
    if (closedProject && closedProLoader) {
      this.getClosedProject(closedProject);
    }
    if (deletedProIs && deletedProLoader) {
      this.getdeletedProject(deletedProIs);
    }
    if (deleteBriefIs && deleteBriefLoader) {
      this.getdeleteBrief(deleteBriefIs);
    }
    if (activeBriefIs && activeBriefLoader) {
      this.getActiveBrief(activeBriefIs);
    }
  }

  getDateTime = (end, start) => {
    // console.log(end,start,'nthuth')
    let result = "";
    var diff = "";
    // console.log("1 = ",end,start)
    if (!end || !start) {
      result = "NA";
    } else {
      let createAt = new Date(end);
      let approveAt = new Date(start);
      // console.log("1 = ",createAt,approveAt)
      diff = (createAt.getTime() - approveAt.getTime()) / 1000;
      diff /= 60 * 60;
      if (diff < 0) {
        result = "NA";
      } else {
        result = Math.round(diff * 60) + " min";
      }
      // else if (diff >= 1) {
      //   let rest = Math.abs(Math.round(diff))
      //   if (rest <= 72) {
      //     result = Math.round(rest) + (rest == 1 ? " hour" : " hours")
      //   } else if (rest <= 365 ) {
      //     result = Math.round(rest / 24) + " days"
      //   } else {
      //     result = Math.round((rest / 24) / 365) + " years"
      //   }
      // }
    }
    return result;
  };

  getTotalBudgetApproved = (arr) => {
    const paidarr = arr.filter((m) => m.status == 2);
    // console.log(paidarr)
    // userjson.avg_brief_response_time= AvgBriefResponseTime.reduce(function(acc, val) { return acc + val; }, 0)
    const result = paidarr.reduce(function (acc, val) {
      return acc + val.price;
    }, 0);
    // console.log(result)
    return result;
  };

  handleWordSize = (value) => {
    return value
      ? value?.length > 30
        ? value?.slice(0, 30) + "..."
        : value
      : "";
  };

  getTotalBudgetApproved = (arr) => {
    const paidarr = arr.filter((m) => m.status == 2);
    // console.log(paidarr)
    // userjson.avg_brief_response_time= AvgBriefResponseTime.reduce(function(acc, val) { return acc + val; }, 0)
    const result = paidarr.reduce(function (acc, val) {
      return acc + val.price;
    }, 0);
    // console.log(result)
    return result;
  };

  render() {
    // console.log(this.props)
    const {
      LoadingIs,
      milesName,
      managerValue,
      clientValue,
      creatorValue,
      collateralValue,
      statusValue,
      listToShow,
      projectValue,
    } = this.state;

    const func = {
      handleCloseProposalModal: this.handleCloseProposalModal,
    };

    const tableFunc = {
      handleCloseModal: this.handleCloseModal,
    };

    const allOptions = this.getAllOptions();
    const managerOptions = allOptions.managers;
    const clientOptions = allOptions.clients;
    const creatorOptions = allOptions.creators;
    const collateralOptions = allOptions.collaterals;

    const statusOptions = [
      { value: "all", label: "All Status" },
      { value: "ongoing", label: "Ongoing" },
      { value: "delayed", label: "Delayed" },
    ];

    const projectOptions = [
      { value: 8, label: "Inactive Brief" },
      { value: 9, label: "Inactive Projects" },
      { value: 2, label: "Active Projects" },
      { value: 1, label: "Active Briefs" },
      { value: 3, label: "Completed Projects" },
      { value: 4, label: "Closed Brief" },
      { value: 5, label: "Closed Project" },
      { value: 6, label: "Deleted Project" },
      { value: 7, label: "Deleted Brief" },
    ];

    const inActiveBrief = [
      { field: "name" },
      { field: "Last updated on" },
      { field: "Client name" },
      { field: "Company name" },
      { field: "AM" },
      { field: "Collateral" },
      { field: "type(Fixed/Retainer)" },
      { field: "Payment mode" },
      { field: "#proposal" },
      { field: "#Creators Invited" },
      { field: "Time to 1st proposal (Client Submission)" },
      { field: "Time to 1st proposal (Active Brief Onwards) " },
      { field: "3 Proposals within 24 hours (Client Submission)" },
      { field: "3 Proposals within 24 hours (Active Brief Onwards)" },
      { field: "Scope" },
      { field: "Min budget" },
      { field: "Max budget" },
      { field: "Start date" },
      { field: "Approved date" },
    ];

    const inActiveProject = [
      { field: "View Proposal" },
      { field: "name" },
      { field: "Last updated on" },
      { field: "Client name" },
      { field: "Company name" },
      { field: "Creator name" },
      { field: "AM" }, 
      { field: "Status" }, 
      { field: "Collateral" },
      { field: "type(fixed/retainer)" },
      { field: "Budget" },
      { field: "Creators earnings" },
      { field: "Posted by" },
      { field: "Current Milesones" },
      { field: "Total Milestones" },
      { field: "Next Milestones due on" },
      { field: "Payment mode" },
      { field: "#Proposal" },
      { field: "#Creators Invited" },
      { field: "Time to 1st proposal (Client Submission)" },
      { field: "Time to 1st proposal (Active Brief Onwards) " },
      { field: "3 Proposals within 24 hours (Client Submission)" },
      { field: "3 Proposals within 24 hours (Active Brief Onwards)" },
      { field: "Start date" },
      { field: "Project approved date" },
      { field: "Project completion date" },
    ];

    const activeProject = [
      { field: "view proposal" },
      { field: "name" },
      { field: "client name" },
      { field: "company name" },
      { field: "creator name" },
      { field: "collateral" },
      { field: "type (fixed/retainer)" },
      { field: "AM" },
      { field: "status" },
      { field: "budget" },
      { field: "creator earnings" },
      { field: "posted by (client/admin)" },
      { field: "current milestone" },
      { field: "total milestone" },
      { field: "next milestone due on" },
      { field: "payment mode" },
      { field: "#proposal" },
      { field: "#Creators Invited" },
      { field: "time to 1st proposal (Client Submission)" },
      { field: "Time to 1st proposal (Active Brief Onwards) " },
      { field: "3 Proposals within 24 hours (Client Submission)" },
      { field: "3 Proposals within 24 hours (Active Brief Onwards)" },
      { field: "start date" },
      { field: "project approved date" },
      { field: "last updated on" },
      { field: "project completion date" },
    ];

    const completedProject = [
      { field: "view proposal" },
      { field: "name" },
      { field: "client name" },
      { field: "company name" },
      { field: "creator name" },
      { field: "client rating" },
      { field: "creator rating" },
      { field: "am review" },
      { field: "am" },
      { field: "collateral" },
      { field: "type (fixed/ retainer)" },
      { field: "budget" },
      { field: "creator earnings" },
      { field: "posted by (client/admin)" },
      { field: "payment mode" },
      { field: "#proposal" },
      { field: "#Creators Invited" },
      { field: "time to 1st proposal (Client Submission)" },
      { field: "Time to 1st proposal (Active Brief Onwards) " },
      { field: "3 Proposals within 24 hours (Client Submission)" },
      { field: "3 Proposals within 24 hours (Active Brief Onwards)" },
      { field: "start date" },
      { field: "project approved date" },
      { field: "project completion date" },
    ];

    const closeBrief = [
      { field: "name" },
      { field: "client name" },
      { field: "company name" },
      { field: "closed by" },
      { field: "closed note" },
      { field: "collateral" },
      { field: "type (fixed/retainer)" },
      { field: "AM" },
      { field: "payment mode" },
      { field: "#proposal" },
      { field: "#Creators Invited" },
      { field: "time to 1st proposal (Client Submission)" },
      { field: "Time to 1st proposal (Active Brief Onwards) " },
      { field: "3 Proposals within 24 hours (Client Submission)" },
      { field: "3 Proposals within 24 hours (Active Brief Onwards)" },
      { field: "scope" },
      { field: "min budget" },
      { field: "max budget" },
      { field: "start date" },
      { field: "approved date" },
      { field: "closed on" },
    ];

    const closedProject = [
      { field: "view proposal" },
      { field: "name" },
      { field: "client name" },
      { field: "company name" },
      { field: "creator name" },
      { field: "closed by" },
      { field: "closed note" },
      { field: "total budget" },
      { field: "budget approved" },
      { field: "creator earnings" },
      { field: "actual creator earnings" },
      { field: "collateral" },
      { field: "type (fixed/retainer)" },
      { field: "AM" },
      { field: "posted by" },
      { field: "current milestone" },
      { field: "total milestone" },
      { field: "payment mode" },
      { field: "#proposals" },
      { field: "#Creators Invited" },
      { field: "time to 1st proposal (Client Submission)" },
      { field: "Time to 1st proposal (Active Brief Onwards) " },
      { field: "3 Proposals within 24 hours (Client Submission)" },
      { field: "3 Proposals within 24 hours (Active Brief Onwards)" },
      { field: "start date" },
      { field: "project approved date" },
      { field: "project closed on" },
    ];

    const deletedBrief = [
      { field: "name" },
      { field: "client name" },
      { field: "company name" },
      { field: "deleted by" },
      { field: "deletion note" },
      { field: "collateral" },
      { field: "type (fixed/retainer)" },
      { field: "AM" },
      { field: "payment mode" },
      { field: "#proposals" },
      { field: "#Creators Invited" },
      { field: "time to 1st proposal (Client Submission)" },
      { field: "Time to 1st proposal (Active Brief Onwards) " },
      { field: "3 Proposals within 24 hours (Client Submission)" },
      { field: "3 Proposals within 24 hours (Active Brief Onwards)" },
      { field: "scope" },
      { field: "min budget" },
      { field: "max budget" },
      { field: "start date" },
      { field: "approved date" },
      { field: "deleted on" },
    ];

    const deletdProject = [
      { field: "view proposal" },
      { field: "name" },
      { field: "client name" },
      { field: "company name" },
      { field: "creator name" },
      { field: "deleted by" },
      { field: "deletion note" },
      { field: "total budget" },
      { field: "budget approved" },
      { field: "creator earnings" },
      { field: "actual creator earnings" },
      { field: "collateral" },
      { field: "type (fixed/retainer)" },
      { field: "AM" },
      { field: "posted by" },
      { field: "current milestone" },
      { field: "total milestone" },
      { field: "payment mode" },
      { field: "#proposals" },
      { field: "#Creators Invited" },
      { field: "time to 1st proposal (Client Submission)" },
      { field: "Time to 1st proposal (Active Brief Onwards) " },
      { field: "3 Proposals within 24 hours (Client Submission)" },
      { field: "3 Proposals within 24 hours (Active Brief Onwards)" },
      { field: "start date" },
      { field: "project approved date" },
      { field: "project deleted on" },
    ];

    const activeBrief = [
      { field: "name" },
      { field: "client name" },
      { field: "company name" },
      { field: "collateral" },
      { field: "type (fixed/retainer)" },
      { field: "AM" },
      { field: "payment mode" },
      { field: "#proposal" },
      { field: "#Creators Invited" },
      { field: "time to 1st proposal (Client Submission)" },
      { field: "Time to 1st proposal (Active Brief Onwards) " },
      { field: "3 Proposals within 24 hours (Client Submission)" },
      { field: "3 Proposals within 24 hours (Active Brief Onwards)" },
      { field: "scope" },
      { field: "Min budget" },
      { field: "Max budget" },
      { field: "Start date" },
      { field: "Approval date" },
      { field: "Last updated on" },
    ];

    return (
      <React.Fragment>
        {this.props._milesList.milesErrIs ? (
          <ErrorApi {...this.props} />
        ) : (
          <section className="client">
            <div className="container-fluid mx-auto">
              <div className="row mb-2 mx-auto">
                <Select
                  className="col-sm-2"
                  options={managerOptions}
                  onChange={(e) => this.handleSelect(e, "managerValue")}
                  value={managerValue}
                />
                <Select
                  className="col-sm-2"
                  options={clientOptions}
                  onChange={(e) => this.handleSelect(e, "clientValue")}
                  value={clientValue}
                />
                {projectValue.value === 1 ||
                projectValue.value === 4 ||
                projectValue.value === 7 ||
                projectValue.value === 8 ? (
                  ""
                ) : (
                  <Select
                    className="col-sm-2"
                    options={creatorOptions}
                    onChange={(e) => this.handleSelect(e, "creatorValue")}
                    value={creatorValue}
                  />
                )}
                <Select
                  className="col-sm-2"
                  options={collateralOptions}
                  onChange={(e) => this.handleSelect(e, "collateralValue")}
                  value={collateralValue}
                />

                <Select
                  className="col-sm-2"
                  options={projectOptions}
                  onChange={(e) => this.handleProjectSelect(e)}
                  value={projectValue}
                  isDisabled={LoadingIs}
                />
              </div>
              {LoadingIs ? (
                <Loader />
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-sm table-nowrap card-table">
                      {/*----------------- table head --------------------*/}
                      <thead>
                        <tr>
                        {/* Inactive Project ************************************************* */}

                        {projectValue?.value === 9 &&
                          inActiveProject.map((obj, index) => {
                            return (
                              <th key={index}>
                                <a href="#!" className="text-muted sort">
                                  {obj.field}
                                </a>
                              </th>
                            );
                          })}

                        {/* Inactive Brief ************************************************* */}

                        {projectValue?.value === 8 &&
                          inActiveBrief.map((obj, index) => {
                            return (
                              <th key={index}>
                                <a href="#!" className="text-muted sort">
                                  {obj.field}
                                </a>
                              </th>
                            );
                          })}

                        {/* Active Project ****************************************************** */}

                        {projectValue?.value === 2 &&
                          activeProject.map((obj, index) => {
                            return (
                              <th key={index}>
                                <a href="#!" className="text-muted sort">
                                  {obj.field}
                                </a>
                              </th>
                            );
                          })}
                        {/* completed project ***************************************************** */}

                        {projectValue?.value === 3 &&
                          completedProject.map((obj, index) => {
                            return (
                              <th key={index}>
                                <a href="#!" className="text-muted sort">
                                  {obj.field}
                                </a>
                              </th>
                            );
                          })}

                        {/* close brief ****************************************************************** */}

                        {projectValue?.value === 4 &&
                          closeBrief.map((obj, index) => {
                            return (
                              <th key={index}>
                                <a href="#!" className="text-muted sort">
                                  {obj.field}
                                </a>
                              </th>
                            );
                          })}

                        {/* close project **************************************************************** */}

                        {projectValue?.value === 5 &&
                          closedProject.map((obj, index) => {
                            return (
                              <th key={index}>
                                <a href="#!" className="text-muted sort">
                                  {obj.field}
                                </a>
                              </th>
                            );
                          })}

                        {/* deleted brief ****************************************************************** */}

                        {projectValue?.value === 7 &&
                          deletedBrief.map((obj, index) => {
                            return (
                              <th key={index}>
                                <a href="#!" className="text-muted sort">
                                  {obj.field}
                                </a>
                              </th>
                            );
                          })}

                        {/* deleted project ***************************************************************** */}

                        {projectValue?.value === 6 &&
                          deletdProject.map((obj, index) => {
                            return (
                              <th key={index}>
                                <a href="#!" className="text-muted sort">
                                  {obj.field}
                                </a>
                              </th>
                            );
                          })}

                        {/* Active brief ********************************************************* */}

                        {projectValue?.value === 1 &&
                          activeBrief.map((obj, index) => {
                            return (
                              <th key={index}>
                                <a href="#!" className="text-muted sort">
                                  {obj.field}
                                </a>
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      {/*----------------- table head --------------------*/}
                      <tbody className="list">
                        {listToShow?.length > 0 ? (
                          listToShow?.map((obj, index) => (
                            <tr key={index}>
                              {/* Inactive project ***************************************** */}
                              {projectValue.value === 9 ? (
                                <>

                                  <td>
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        color: "#2c7be5",
                                      }}
                                      onClick={() =>
                                        this.handleViewProposal(obj)
                                      }
                                    >
                                      view Proposal
                                    </span>
                                  </td>
                                  <td className="orders-order">
                                    <Link
                                      target="_blank"
                                      to={this.getProjectUrl(obj)}
                                    >
                                      {this.getProject(obj?.project_name)}
                                    </Link>
                                  </td>
                                  <td>{obj?.updatedAt && moment(obj?.updatedAt).format("DD/MM/YYYY")}</td>
                                  <td className="orders-order">
                                    <Link
                                      to={`/clients/:edit/:${
                                        obj.user ? obj.user._id : obj.client._id
                                      }`}
                                      style={{ color: "#12263f" }}
                                    >
                                      {obj?.user
                                        ? this.handleWordSize(
                                            obj?.user?.first_name +
                                              " " +
                                              obj?.user?.last_name
                                          )
                                        : this.handleWordSize(
                                            obj?.client?.first_name +
                                              " " +
                                              obj?.client?.last_name
                                          )}
                                    </Link>
                                  </td>
                                  <td>
                                    {this.handleWordSize(obj?.company_name)}
                                  </td>
                                  <td>
                                    {obj?.proposal?.creator
                                      ? this.handleWordSize(
                                          obj?.proposal?.creator?.first_name +
                                            " " +
                                            obj?.proposal?.creator?.last_name
                                        )
                                      : ""}
                                  </td>
                                  <td>
                                    {this.handleWordSize(obj?.manager_name)}
                                  </td>
                                  <td>
                                    {!obj?.proposal?.current_milestone
                                      ?.iteration
                                      ? "Client to review the milestone submitted"
                                      : `Creator to submit milestone ${obj?.proposal?.current_milestone?.serial_no}`}
                                  </td>
                                  <td>{obj?.sub_type}</td>
                                  <td className="orders-order">
                                    <Link
                                      to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                                      style={{ color: "#12263f" }}
                                    >
                                      {this.handleWordSize(
                                        obj?.collateral?.name
                                      )}
                                    </Link>
                                  </td>
                                  <td>
                                    {obj?.proposal?.proposal?.sub_total_cost.toFixed(
                                      2
                                    )}
                                  </td>
                                  <td>
                                    {obj?.proposal?.proposal.total_earning.toFixed(
                                      2
                                    )}
                                  </td>
                                  <td>
                                  <span
                                    className={`badge ${
                                    ( obj.type === "post_project_admin" || obj?.were_admin_project)
                                    ?`badge-soft-primary`
                                    :`badge-soft-info`
                                    }`}
                                  >
                                    {obj.type === "post_project_admin" || obj?.were_admin_project
                                      ? "Admin"
                                      : "Client"}
                                  </span>
                                  </td>
                                  <td>
                                  {obj?.newProposalList?.[0]?.payment_terms.length>0 ? "Milestone " +  this.currentMilestone(obj?.newProposalList?.[0]?.payment_terms):""}
                                  </td>
                                  <td>{obj?.proposal?.total_milsetone}</td>
                                  <td>
                                    {obj?.proposal?.current_milestone?.due_date && 
                                          moment(obj?.proposal?.current_milestone?.due_date).format("DD/MM/YYYY")
                                      }
                                  </td>
                                  {/* <td>{obj.offline_payment === 1 ? "BANK TRANSFER" : "RAZORPAY"}</td> */}
                                  <td>
                                    <OfflinePayment obj={obj}  type={'inactive_project_am'} tabname={'inActiveProLoader'} parentState={this.parentState}/>
                                  </td>
                                  <td>{obj.total_receivedProposal}</td>
                                  <td>{obj.total_creators}</td>
                                  {/* <td>{obj?.first_proposal?.createdAt ? `${obj.first_proposal.createdAt?.split("T").slice(0)[0]} ${obj.first_proposal.createdAt?.split("T")[1].slice(0,5)}` : ""}</td> */}
                                  <td>
                                    {" "}
                                    {this.getDateTime(obj?.first_shared_proposal_time,
                                      obj?.project_brief_completion_date
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {this.getDateTime(obj?.first_shared_proposal_time,
                                      obj?.project_brief_approved_date
                                    )}
                                  </td>

                                  <td>
                                    {obj?.proposal3hour24
                                      ? obj.proposal3hour24V1
                                      : "NA"}
                                  </td>
                                  <td>
                                    {obj?.proposal3hour24
                                      ? obj.proposal3hour24
                                      : "NA"}
                                  </td>
                                  <td>{obj?.createdAt && moment(obj?.createdAt).format("DD/MM/YYYY")}</td>
                                  <td>
                                    {obj?.project_approved_date && moment(obj?.project_approved_date).format("DD/MM/YYYY")}
                                  </td>
                                  <td>
                                    { obj?.proposal1?.milestone?.[
                                          obj?.proposal1?.milestone?.length - 1
                                        ]?.due_date && moment(obj?.proposal1?.milestone?.[
                                          obj?.proposal1?.milestone?.length - 1
                                        ]?.due_date).format("DD/MM/YYYY")}
                                  </td>
                                </>
                              ) : (
                                <></>
                              )}
                              {/* Inactive Brief ***************************************** */}
                              {projectValue.value === 8 && (
                                <>
                                  <td className="orders-order">
                                    <Link
                                      target="_blank"
                                      to={this.getProjectUrl(obj)}
                                    >
                                      {this.getProject(obj?.project_name)}
                                    </Link>
                                  </td>
                                  <td>{obj?.updatedAt && moment(obj?.updatedAt).format("DD/MM/YYYY")}</td>
                                  <td className="orders-order">
                                    <Link
                                      to={`/clients/:edit/:${
                                        obj.user ? obj.user._id : obj.client._id
                                      }`}
                                      style={{ color: "#12263f" }}
                                    >
                                      {obj?.user
                                        ? this.handleWordSize(
                                            obj?.user?.first_name +
                                              " " +
                                              obj?.user?.last_name
                                          )
                                        : this.handleWordSize(
                                            obj?.client?.first_name +
                                              " " +
                                              obj?.client?.last_name
                                          )}
                                    </Link>
                                  </td>
                                  <td>
                                    {this.handleWordSize(obj?.company_name)}
                                  </td>
                                  <td>
                                    {this.handleWordSize(obj?.manager_name)}
                                  </td>
                                  <td className="orders-order">
                                    <Link
                                      to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                                      style={{ color: "#12263f" }}
                                    >
                                      {this.handleWordSize(
                                        obj?.collateral?.name
                                      )}
                                    </Link>
                                  </td>
                                  <td>{obj?.sub_type}</td>
                                  {/* <td>{obj.offline_payment === 1 ? "BANK TRANSFER" : "RAZORPAY"}</td> */}
                                  <td>
                                    <OfflinePayment obj={obj} type={'inactive_brief_am'} tabname={'inActiveLoader'} parentState={this.parentState}/>
                                  </td>
                                  <td>{obj.total_receivedProposal}</td>
                                  <td>{obj.total_creators}</td>
                                  {/* <td>{obj?.first_proposal?.createdAt ? `${obj.first_proposal.createdAt?.split("T").slice(0)[0]} ${obj.first_proposal.createdAt?.split("T")[1].slice(0,5)}` : ""}</td> */}
                                  <td>
                                    {" "}
                                    {this.getDateTime(obj?.first_shared_proposal_time,
                                      obj?.project_brief_completion_date
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {this.getDateTime(obj?.first_shared_proposal_time,
                                      obj?.project_brief_approved_date
                                    )}
                                  </td>

                                  <td>
                                    {obj?.proposal3hour24V1
                                      ? obj.proposal3hour24V1
                                      : "NA"}
                                  </td>
                                  <td>
                                    {obj?.proposal3hour24
                                      ? obj.proposal3hour24
                                      : "NA"}
                                  </td>
                                  <td className="orders-date">
                                    {obj?.budget_scope ? obj.budget_scope : ""}{" "}
                                  </td>
                                  <td>{obj.min_budget}</td>
                                  <td>{obj.max_budget}</td>
                                  <td>{obj?.createdAt && moment(obj?.createdAt).format("DD/MM/YYYY")}</td>
                                  <td>
                                    {
                                      obj?.project_brief_approved_date && moment(obj?.project_brief_approved_date).format("DD/MM/YYYY")
                                    }
                                  </td>
                                </>
                              )}

                              {/* Active project ***************************************** */}
                              {projectValue.value === 2 && (
                                <>
                                  <td>
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        color: "#2c7be5",
                                      }}
                                      onClick={() =>
                                        this.handleViewProposal(obj)
                                      }
                                    >
                                      view Proposal
                                    </span>
                                  </td>
                                  <td
                                    className="orders-order"
                                    style={{
                                      backgroundColor:
                                        this.getStatus(obj?.due_date) < 0
                                          ? "#ff7070"
                                          : "",
                                    }}
                                  >
                                    <Link
                                      target="_blank"
                                      to={this.getProjectUrl(obj)}
                                    >
                                      {this.getProject(obj?.project_name)}
                                    </Link>
                                  </td>
                                  <td className="orders-order">
                                    <Link
                                      to={`/clients/:edit/:${
                                        obj.user ? obj.user._id : obj.client._id
                                      }`}
                                      style={{ color: "#12263f" }}
                                    >
                                      {obj?.user
                                        ? this.handleWordSize(
                                            obj?.user?.first_name +
                                              " " +
                                              obj?.user?.last_name
                                          )
                                        : this.handleWordSize(
                                            obj?.client?.first_name +
                                              " " +
                                              obj?.client?.last_name
                                          )}
                                    </Link>
                                  </td>
                                  <td>
                                    {this.handleWordSize(obj?.company_name)}
                                  </td>
                                  <td>
                                    {" "}
                                    {obj?.proposal?.creator
                                      ? this.handleWordSize(
                                          obj?.proposal?.creator?.first_name +
                                            " " +
                                            obj?.proposal?.creator?.last_name
                                        )
                                      : ""}
                                  </td>
                                  <td className="orders-order">
                                    <Link
                                      to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                                      style={{ color: "#12263f" }}
                                    >
                                      {this.handleWordSize(
                                        obj?.collateral?.name
                                      )}
                                    </Link>
                                  </td>
                                  <td>{obj?.sub_type}</td>
                                  <td>
                                    {this.handleWordSize(obj?.manager_name)}
                                  </td>
                                  <td>
                                    {!obj?.proposal?.current_milestone
                                      ?.iteration
                                      ? "Client to review the milestone submitted"
                                      : `Creator to submit milestone ${obj?.proposal?.current_milestone?.serial_no}`}
                                  </td>
                                  <td>
                                    {obj?.proposal?.proposal?.sub_total_cost.toFixed(
                                      2
                                    )}
                                  </td>
                                  <td>
                                    {obj?.proposal?.proposal.total_earning.toFixed(
                                      2
                                    )}
                                  </td>
                                  <td>
                                  <span
                                    className={`badge ${
                                    ( obj.type === "post_project_admin" || obj?.were_admin_project)
                                    ?`badge-soft-primary`
                                    :`badge-soft-info`
                                    }`}
                                  >
                                    {obj.type === "post_project_admin" || obj?.were_admin_project
                                      ? "Admin"
                                      : "Client"}
                                  </span>
                                  </td>
                                  <td>
                                  {obj?.newProposalList?.[0]?.payment_terms.length>0 ? "Milestone " +  this.currentMilestone(obj?.newProposalList?.[0]?.payment_terms):""}
                                  </td>
                                  <td>{obj?.proposal?.total_milsetone}</td>
                                  <td>
                                    {obj.proposal
                                      && obj?.proposal?.current_milestone?.due_date && moment(obj?.proposal?.current_milestone?.due_date).format("DD/MM/YYYY")
                                          }
                                  </td>
                                  {/* <td>{obj.offline_payment === 1 ? "BANK TRANSFER" : "RAZORPAY"}</td> */}
                                  <td>
                                    <OfflinePayment obj={obj} type={'active_project_am'} tabname={'activeProLoader'} parentState={this.parentState}/>
                                  </td>
                                  <td>{obj.total_receivedProposal}</td>
                                  <td>{obj.total_creators}</td>
                                  {/* <td>{obj?.first_proposal?.createdAt ? `${obj.first_proposal.createdAt?.split("T").slice(0)[0]} ${obj.first_proposal.createdAt?.split("T")[1].slice(0,5)}` : ""}</td> */}
                                  <td>
                                    {" "}
                                    {this.getDateTime(obj?.first_shared_proposal_time,
                                      obj?.project_brief_completion_date
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {this.getDateTime(obj?.first_shared_proposal_time,
                                      obj?.project_brief_approved_date
                                    )}
                                  </td>

                                  <td>
                                    {obj?.proposal3hour24
                                      ? obj.proposal3hour24V1
                                      : "NA"}
                                  </td>
                                  <td>
                                    {obj?.proposal3hour24
                                      ? obj.proposal3hour24
                                      : "NA"}
                                  </td>
                                  <td>{obj?.createdAt && moment(obj?.createdAt).format("DD/MM/YYYY")}</td>
                                  <td>
                                    {obj?.project_approved_date && moment(obj?.project_approved_date).format("DD/MM/YYYY")}
                                  </td>
                                  <td>{obj?.updatedAt && moment(obj?.updatedAt).format("DD/MM/YYYY")}</td>
                                  <td>
                                    {obj?.proposal1
                                      && obj.proposal1?.milestone?.[
                                          obj?.proposal1?.milestone?.length - 1
                                        ]?.due_date && moment(obj.proposal1?.milestone?.[obj?.proposal1?.milestone?.length - 1]).format("DD/MM/YYYY")}
                                  </td>
                                </>
                              )}
                              {/* completed project **************************************** */}

                              {projectValue.value === 3 && (
                                <>
                                  <td>
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        color: "#2c7be5",
                                      }}
                                      onClick={() =>
                                        this.handleViewProposal(obj)
                                      }
                                    >
                                      view Proposal
                                    </span>
                                  </td>
                                  <td
                                    className="orders-order"
                                    style={{
                                      backgroundColor:
                                        obj?.creatorReview &&
                                        obj?.clientReview &&
                                        obj?.hasmanagerReviewed === "Yes"
                                          ? ""
                                          : "#d1d1d1",
                                    }}
                                  >
                                    <Link
                                      target="_blank"
                                      to={this.getProjectUrl(obj)}
                                    >
                                      {this.getProject(obj?.project_name)}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/clients/:edit/:${
                                        obj.user ? obj.user._id : obj.client._id
                                      }`}
                                      style={{ color: "#12263f" }}
                                    >
                                      {obj?.user
                                        ? this.handleWordSize(
                                            obj?.user?.first_name +
                                              " " +
                                              obj?.user?.last_name
                                          )
                                        : this.handleWordSize(
                                            obj?.client?.first_name +
                                              " " +
                                              obj?.client?.last_name
                                          )}
                                    </Link>
                                  </td>
                                  <td>
                                    {this.handleWordSize(obj?.company_name)}
                                  </td>
                                  <td>
                                    {" "}
                                    {obj?.proposal?.creator
                                      ? this.handleWordSize(
                                          obj?.proposal?.creator?.first_name +
                                            " " +
                                            obj?.proposal?.creator?.last_name
                                        )
                                      : ""}
                                  </td>
                                  <td>{obj?.client_rating?.rating}</td>
                                  <td>{obj?.creator_rating?.rating}</td>
                                  <td>{obj?.hasmanagerReviewed}</td>
                                  <td>{obj?.manager_name}</td>
                                  <td className="orders-order">
                                    <Link
                                      to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                                      style={{ color: "#12263f" }}
                                    >
                                      {this.handleWordSize(
                                        obj?.collateral?.name
                                      )}
                                    </Link>
                                  </td>
                                  <td>{obj?.sub_type}</td>
                                  <td>
                                    {obj?.proposal?.proposal?.sub_total_cost.toFixed(
                                      2
                                    )}
                                  </td>
                                  <td>
                                    {obj?.proposal?.proposal.total_earning.toFixed(
                                      2
                                    )}
                                  </td>
                                  <td>
                                  <span
                                    className={`badge ${
                                    ( obj.type === "post_project_admin" || obj?.were_admin_project)
                                    ?`badge-soft-primary`
                                    :`badge-soft-info`
                                    }`}
                                  >
                                    {obj.type === "post_project_admin" || obj?.were_admin_project
                                      ? "Admin"
                                      : "Client"}
                                  </span>
                                  </td>
                                  <td>
                                    <OfflinePayment
                                      obj={obj}
                                      actionMil={true}
                                      handleCall={() => this.transactionsList()}
                                      disabled={true}
                                    />
                                  </td>
                                  <td>{obj.total_receivedProposal}</td>
                                  <td>{obj.total_creators}</td>
                                  {/* <td>{obj?.first_proposal?.createdAt ? `${obj.first_proposal.createdAt?.split("T").slice(0)[0]} ${obj.first_proposal.createdAt?.split("T")[1].slice(0,5)}` : ""}</td> */}
                                  <td>
                                    {" "}
                                    {this.getDateTime(obj?.first_shared_proposal_time,
                                      obj?.project_brief_completion_date
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {this.getDateTime(obj?.first_shared_proposal_time,
                                      obj?.project_brief_approved_date
                                    )}
                                  </td>

                                  <td>
                                    {obj?.proposal3hour24
                                      ? obj.proposal3hour24V1
                                      : "NA"}
                                  </td>
                                  <td>
                                    {obj?.proposal3hour24
                                      ? obj.proposal3hour24
                                      : "NA"}
                                  </td>
                                  <td>{obj?.createdAt && moment(obj?.createdAt).format("DD/MM/YYYY")}</td>
                                  <td>
                                    {obj?.project_approved_date && moment(obj?.project_approved_date).format("DD/MM/YYYY")}
                                  </td>
                                  <td>
                                    {obj?.project_completed_date && moment(obj?.project_completed_date).format("DD/MM/YYYY")}
                                  </td>
                                </>
                              )}

                              {/* close brief ************************************************ */}

                              {projectValue.value === 4 && (
                                <>
                                  <td>
                                    <Link
                                      target="_blank"
                                      to={this.getProjectUrl(obj)}
                                    >
                                      {this.getProject(obj?.project_name)}
                                    </Link>
                                  </td>
                                  <td className="orders-order">
                                    <Link
                                      to={`/clients/:edit/:${
                                        obj.user ? obj.user._id : obj.client._id
                                      }`}
                                      style={{ color: "#12263f" }}
                                    >
                                      {obj?.user
                                        ? this.handleWordSize(
                                            obj?.user?.first_name +
                                              " " +
                                              obj?.user?.last_name
                                          )
                                        : this.handleWordSize(
                                            obj?.client?.first_name +
                                              " " +
                                              obj?.client?.last_name
                                          )}
                                    </Link>
                                  </td>
                                  <td>
                                    {this.handleWordSize(obj?.company_name)}
                                  </td>
                                  <td>
                                    {obj?.action_by === "admin"
                                      ? `${obj?.action_by} ${
                                          obj?.admin_email
                                            ? `(${this.handleWordSize(
                                                obj?.admin_email
                                              )})`
                                            : ""
                                        }`
                                      : obj?.action_by}
                                  </td>
                                  <td>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      data-toggle="tooltip"
                                      title={obj?.reason?.description}
                                    >
                                      {this.handleWordSize(
                                        obj?.reason?.description
                                      )}
                                    </span>
                                  </td>
                                  <td className="orders-order">
                                    <Link
                                      to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                                      style={{ color: "#12263f" }}
                                    >
                                      {this.handleWordSize(
                                        obj?.collateral?.name
                                      )}
                                    </Link>
                                  </td>
                                  <td>{obj?.sub_type}</td>
                                  <td>
                                    {this.handleWordSize(obj?.manager_name)}
                                  </td>
                                  {/* <td>{obj.offline_payment === 1 ? "BANK TRANSFER" : "RAZORPAY"}</td> */}
                                  <td>
                                    <OfflinePayment obj={obj} disabled={true}/>
                                  </td>
                                  <td>{obj.total_receivedProposal}</td>
                                  <td>{obj?.total_creators}</td>
                                  {/* <td>{obj?.first_proposal?.createdAt ? `${obj.first_proposal.createdAt?.split("T").slice(0)[0]} ${obj.first_proposal.createdAt?.split("T")[1].slice(0,5)}` : ""}</td> */}
                                  <td>
                                    {" "}
                                    {this.getDateTime(obj?.first_shared_proposal_time,
                                      obj?.project_brief_completion_date
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {this.getDateTime(obj?.first_shared_proposal_time,
                                      obj?.project_brief_approved_date
                                    )}
                                  </td>

                                  <td>
                                    {obj?.proposal3hour24
                                      ? obj.proposal3hour24V1
                                      : "NA"}
                                  </td>
                                  <td>
                                    {obj?.proposal3hour24
                                      ? obj.proposal3hour24
                                      : "NA"}
                                  </td>
                                  <td>
                                    {obj?.budget_scope ? obj.budget_scope : ""}
                                  </td>
                                  <td>{obj.min_budget}</td>
                                  <td>{obj.max_budget}</td>
                                  <td>{obj?.createdAt && moment(obj?.createdAt).format("DD/MM/YYYY")}</td>
                                  <td>
                                    {
                                      obj?.project_brief_approved_date && moment(obj?.project_brief_approved_date).format("DD/MM/YYYY")}
                                  </td>
                                  <td>
                                    {obj?.project_close_date && moment(obj?.project_close_date).format("DD/MM/YYYY")}
                                  </td>
                                </>
                              )}

                              {/* close project *************************************************** */}

                              {projectValue.value === 5 && (
                                <>
                                  <td>
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        color: "#2c7be5",
                                      }}
                                      onClick={() =>
                                        this.handleViewProposal(obj)
                                      }
                                    >
                                      view Proposal
                                    </span>
                                  </td>
                                  <td>
                                    <Link
                                      target="_blank"
                                      to={this.getProjectUrl(obj)}
                                    >
                                      {this.getProject(obj?.project_name)}
                                    </Link>
                                  </td>
                                  <td className="orders-order">
                                    <Link
                                      to={`/clients/:edit/:${
                                        obj.user ? obj.user._id : obj.client._id
                                      }`}
                                      style={{ color: "#12263f" }}
                                    >
                                      {obj?.user
                                        ? this.handleWordSize(
                                            obj?.user?.first_name +
                                              " " +
                                              obj?.user?.last_name
                                          )
                                        : this.handleWordSize(
                                            obj?.client?.first_name +
                                              " " +
                                              obj?.client?.last_name
                                          )}
                                    </Link>
                                  </td>
                                  <td>
                                    {this.handleWordSize(obj?.company_name)}
                                  </td>
                                  <td>
                                    {" "}
                                    {obj?.proposal?.creator
                                      ? this.handleWordSize(
                                          obj?.proposal?.creator?.first_name +
                                            " " +
                                            obj?.proposal?.creator?.last_name
                                        )
                                      : ""}
                                  </td>
                                  <td>
                                    {obj?.action_by === "admin"
                                      ? `${obj?.action_by} ${
                                          obj?.admin_email
                                            ? `(${this.handleWordSize(
                                                obj?.admin_email
                                              )})`
                                            : ""
                                        }`
                                      : obj?.action_by}
                                  </td>
                                  <td>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      data-toggle="tooltip"
                                      title={obj?.reason?.description}
                                    >
                                      {this.handleWordSize(
                                        obj?.reason?.description
                                      )}
                                    </span>
                                  </td>
                                  <td>
                                    {obj?.proposal?.proposal?.sub_total_cost.toFixed(
                                      2
                                    )}
                                  </td>
                                  <td>
                                    {obj?.proposal1?.milestone
                                      ? this.getTotalBudgetApproved(
                                          obj?.proposal1?.milestone
                                        )
                                      : "none"}
                                  </td>
                                  <td>
                                    {obj?.proposal?.proposal.total_earning.toFixed(
                                      2
                                    )}
                                  </td>
                                  <td>
                                    {obj?.proposal?.paid_amount.toFixed(2)}
                                  </td>
                                  <td className="orders-order">
                                    <Link
                                      to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                                      style={{ color: "#12263f" }}
                                    >
                                      {this.handleWordSize(
                                        obj?.collateral?.name
                                      )}
                                    </Link>
                                  </td>
                                  <td>{obj?.sub_type}</td>
                                  <td>
                                    {this.handleWordSize(obj?.manager_name)}
                                  </td>
                                  <td>
                                  <span
                                    className={`badge ${
                                    ( obj.type === "post_project_admin" || obj?.were_admin_project)
                                    ?`badge-soft-primary`
                                    :`badge-soft-info`
                                    }`}
                                  >
                                    {obj.type === "post_project_admin" || obj?.were_admin_project
                                      ? "Admin"
                                      : "Client"}
                                  </span>
                                  </td>
                                  <td>
                                    {this.handleCloseProjectMilestone(
                                      obj?.proposal1?.milestone
                                    )
                                      ? "Milestone " +
                                        this.handleCloseProjectMilestone(
                                          obj?.proposal1?.milestone
                                        )
                                      : ""}
                                  </td>
                                  <td>{obj?.proposal?.total_milsetone}</td>
                                  <td>
                                    <OfflinePayment obj={obj} disabled={true}/>
                                  </td>
                                  <td>{obj.total_receivedProposal}</td>
                                  <td>{obj.total_creators}</td>
                                  <td>
                                    {" "}
                                    {this.getDateTime(obj?.first_shared_proposal_time,
                                      obj?.project_brief_completion_date
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {this.getDateTime(obj?.first_shared_proposal_time,
                                      obj?.project_brief_approved_date
                                    )}
                                  </td>
                                  <td>
                                    {obj?.proposal3hour24
                                      ? obj.proposal3hour24V1
                                      : "NA"}
                                  </td>
                                  <td>
                                    {obj?.proposal3hour24
                                      ? obj.proposal3hour24
                                      : "NA"}
                                  </td>
                                  <td>{obj?.createdAt && moment(obj?.createdAt).format("DD/MM/YYYY")}</td>
                                  <td>
                                    {obj?.project_approved_date && moment(obj?.project_approved_date).format("DD/MM/YYYY")}
                                  </td>
                                  <td>
                                    {obj?.project_close_date && moment(obj?.project_close_date).format("DD/MM/YYYY")}
                                  </td>
                                </>
                              )}

                              {/* deletd brief ************************************************** */}
                              {projectValue.value === 7 && (
                                <>
                                  <td>
                                    <Link
                                      target="_blank"
                                      to={this.getProjectUrl(obj)}
                                    >
                                      {this.getProject(obj?.project_name)}
                                    </Link>
                                  </td>
                                  <td className="orders-order">
                                    <Link
                                      to={`/clients/:edit/:${
                                        obj.user ? obj.user._id : obj.client._id
                                      }`}
                                      style={{ color: "#12263f" }}
                                    >
                                      {obj?.user
                                        ? this.handleWordSize(
                                            obj?.user?.first_name +
                                              " " +
                                              obj?.user?.last_name
                                          )
                                        : this.handleWordSize(
                                            obj?.client?.first_name +
                                              " " +
                                              obj?.client?.last_name
                                          )}
                                    </Link>
                                  </td>
                                  <td>
                                    {this.handleWordSize(obj?.company_name)}
                                  </td>
                                  <td>
                                    {obj?.action_by === "admin"
                                      ? `${obj?.action_by} ${
                                          obj?.admin_email
                                            ? `(${this.handleWordSize(
                                                obj?.admin_email
                                              )})`
                                            : ""
                                        }`
                                      : obj?.action_by}
                                  </td>
                                  <td>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      data-toggle="tooltip"
                                      title={obj?.delete_reason?.reason}
                                    >
                                      {this.handleWordSize(
                                        obj?.delete_reason?.reason
                                      )}
                                    </span>
                                  </td>
                                  <td className="orders-order">
                                    <Link
                                      to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                                      style={{ color: "#12263f" }}
                                    >
                                      {this.handleWordSize(
                                        obj?.collateral?.name
                                      )}
                                    </Link>
                                  </td>
                                  <td>{obj?.sub_type}</td>
                                  <td>
                                    {this.handleWordSize(obj?.manager_name)}
                                  </td>
                                  <td>
                                    <OfflinePayment obj={obj} disabled={true}/>
                                  </td>
                                  <td>{obj.total_receivedProposal}</td>
                                  <td>{obj.total_creators}</td>
                                  <td>
                                    {" "}
                                    {this.getDateTime(obj?.first_shared_proposal_time,
                                      obj?.project_brief_completion_date
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {this.getDateTime(obj?.first_shared_proposal_time,
                                      obj?.project_brief_approved_date
                                    )}
                                  </td>
                                  <td>
                                    {obj?.proposal3hour24
                                      ? obj.proposal3hour24V1
                                      : "NA"}
                                  </td>
                                  <td>
                                    {obj?.proposal3hour24
                                      ? obj.proposal3hour24
                                      : "NA"}
                                  </td>
                                  <td>
                                    {obj?.budget_scope ? obj.budget_scope : ""}
                                  </td>
                                  <td>{obj.min_budget}</td>
                                  <td>{obj.max_budget}</td>
                                  <td>{obj?.createdAt && moment(obj?.createdAt).format("DD/MM/YYYY")}</td>
                                  <td>
                                    {
                                      obj?.project_brief_approved_date && moment(obj?.project_brief_approved_date).format("DD/MM/YY")
                                    }
                                  </td>
                                  <td>
                                    {obj?.project_delete_date && moment(obj?.project_delete_date).format("DD/MM/YYYY")}
                                  </td>
                                </>
                              )}

                              {/* deletd projects ********************************************** */}

                              {projectValue.value === 6 && (
                                <>
                                  <td>
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        color: "#2c7be5",
                                      }}
                                      onClick={() =>
                                        this.handleViewProposal(obj)
                                      }
                                    >
                                      view Proposal
                                    </span>
                                  </td>
                                  <td>
                                    <Link
                                      target="_blank"
                                      to={this.getProjectUrl(obj)}
                                    >
                                      {this.getProject(obj?.project_name)}
                                    </Link>
                                  </td>
                                  <td className="orders-order">
                                    <Link
                                      to={`/clients/:edit/:${
                                        obj.user ? obj.user._id : obj.client._id
                                      }`}
                                      style={{ color: "#12263f" }}
                                    >
                                      {obj?.user
                                        ? this.handleWordSize(
                                            obj?.user?.first_name +
                                              " " +
                                              obj?.user?.last_name
                                          )
                                        : this.handleWordSize(
                                            obj?.client?.first_name +
                                              " " +
                                              obj?.client?.last_name
                                          )}
                                    </Link>
                                  </td>
                                  <td>
                                    {this.handleWordSize(obj?.company_name)}
                                  </td>
                                  <td>
                                    {" "}
                                    {obj?.proposal?.creator
                                      ? this.handleWordSize(
                                          obj?.proposal?.creator?.first_name +
                                            " " +
                                            obj?.proposal?.creator?.last_name
                                        )
                                      : ""}
                                  </td>
                                  <td>
                                    {obj?.action_by === "admin"
                                      ? `${obj?.action_by} ${
                                          obj?.admin_email
                                            ? `(${this.handleWordSize(
                                                obj?.admin_email
                                              )})`
                                            : ""
                                        }`
                                      : obj?.action_by}
                                  </td>
                                  <td>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      data-toggle="tooltip"
                                      title={obj?.delete_reason?.reason}
                                    >
                                      {this.handleWordSize(
                                        obj?.delete_reason?.reason
                                      )}
                                    </span>
                                  </td>
                                  <td>
                                    {obj?.proposal?.proposal?.sub_total_cost.toFixed(
                                      2
                                    )}
                                  </td>
                                  <td>
                                    {obj?.proposal1?.milestone
                                      ? this.getTotalBudgetApproved(
                                          obj?.proposal1?.milestone
                                        )
                                      : ""}
                                  </td>
                                  <td>
                                    {obj?.proposal?.proposal.total_earning.toFixed(
                                      2
                                    )}
                                  </td>
                                  <td>
                                    {obj?.proposal?.paid_amount.toFixed(2)}
                                  </td>
                                  <td className="orders-order">
                                    <Link
                                      to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                                      style={{ color: "#12263f" }}
                                    >
                                      {this.handleWordSize(
                                        obj?.collateral?.name
                                      )}
                                    </Link>
                                  </td>
                                  <td>{obj?.sub_type}</td>
                                  <td>
                                    {this.handleWordSize(obj?.manager_name)}
                                  </td>
                                  <td>
                                  <span
                                    className={`badge ${
                                    ( obj.type === "post_project_admin" || obj?.were_admin_project)
                                    ?`badge-soft-primary`
                                    :`badge-soft-info`
                                    }`}
                                  >
                                    {obj.type === "post_project_admin" || obj?.were_admin_project
                                      ? "Admin"
                                      : "Client"}
                                  </span>
                                  </td>
                                  <td>
                                    {obj?.proposal?.complete_milestone ===
                                    obj?.proposal?.total_milsetone
                                      ? "Milestone Completed"
                                      : obj?.proposal?.complete_milestone
                                      ? "Milestone " +
                                        (obj?.proposal?.complete_milestone + 1)
                                      : ""}
                                  </td>
                                  <td>{obj?.proposal?.total_milsetone}</td>
                                  {/* <td>{obj.offline_payment === 1 ? "BANK TRANSFER" : "RAZORPAY"}</td> */}
                                  <td>
                                    <OfflinePayment obj={obj} disabled={true}/>
                                  </td>
                                  <td>{obj.total_receivedProposal}</td>
                                  <td>{obj.total_creators}</td>
                                  <td>
                                    {" "}
                                    {this.getDateTime(obj?.first_shared_proposal_time,
                                      obj?.project_brief_completion_date
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {this.getDateTime(obj?.first_shared_proposal_time,
                                      obj?.project_brief_approved_date
                                    )}
                                  </td>
                                  <td>
                                    {obj?.proposal3hour24
                                      ? obj.proposal3hour24V1
                                      : "NA"}
                                  </td>
                                  <td>
                                    {obj?.proposal3hour24
                                      ? obj.proposal3hour24
                                      : "NA"}
                                  </td>
                                  <td>{obj?.createdAt && moment(obj?.createdAt).format("DD/MM/YYYY")}</td>
                                  <td>
                                    {
                                      obj?.project_brief_approved_date && moment(obj?.project_brief_approved_date).format("DD/MM/YYYY")
                                    }
                                  </td>
                                  <td>
                                    {obj?.project_delete_date && moment(obj?.project_delete_date).format("DD/MM/YYYY")}
                                  </td>
                                </>
                              )}

                              {/* Active brief ****************************************************** */}

                              {projectValue.value === 1 && (
                                <>
                                  <td className="orders-order">
                                    <Link
                                      target="_blank"
                                      to={this.getProjectUrl(obj)}
                                    >
                                      {this.getProject(obj?.project_name)}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/clients/:edit/:${
                                        obj.user ? obj.user._id : obj.client._id
                                      }`}
                                      style={{ color: "#12263f" }}
                                    >
                                      {obj?.user
                                        ? this.handleWordSize(
                                            obj?.user?.first_name +
                                              " " +
                                              obj?.user?.last_name
                                          )
                                        : this.handleWordSize(
                                            obj?.client?.first_name +
                                              " " +
                                              obj?.client?.last_name
                                          )}
                                    </Link>
                                  </td>
                                  <td>
                                    {this.handleWordSize(obj?.company_name)}
                                  </td>
                                  <td className="orders-order">
                                    <Link
                                      to={`/collateralsQuestions/:edit/:${obj.collateral._id}`}
                                      style={{ color: "#12263f" }}
                                    >
                                      {this.handleWordSize(
                                        obj?.collateral?.name
                                      )}
                                    </Link>
                                  </td>
                                  <td>{obj?.sub_type}</td>
                                  <td>
                                    {this.handleWordSize(obj?.manager_name)}
                                  </td>
                                  <td>
                                    <OfflinePayment obj={obj} type={'active_brief_am'} tabname={'activeBriefLoader'} parentState={this.parentState}/>
                                  </td>
                                  <td>{obj.total_receivedProposal}</td>
                                  <td>{obj.total_creators}</td>
                                  <td>
                                    {" "}
                                    {this.getDateTime(obj?.first_shared_proposal_time,
                                      obj?.project_brief_completion_date
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {this.getDateTime(obj?.first_shared_proposal_time,
                                      obj?.project_brief_approved_date
                                    )}
                                  </td>
                                  <td>
                                    {obj?.proposal3hour24V1
                                      ? obj?.proposal3hour24V1
                                      : "NA"}
                                  </td>
                                  <td>
                                    {obj?.proposal3hour24
                                      ? obj.proposal3hour24
                                      : "NA"}
                                  </td>
                                  <td className="orders-date">
                                    {obj?.budget_scope ? obj.budget_scope : ""}
                                  </td>
                                  <td>{obj.min_budget}</td>
                                  <td>{obj.max_budget}</td>
                                  <td>{obj?.createdAt && moment(obj?.createdAt).format("DD/MM/YYYY")}</td>
                                  <td>
                                    {
                                      obj?.project_brief_approved_date && moment(obj?.project_brief_approved_date).format("DD/MM/YYYY")
                                    }
                                  </td>
                                  <td>{obj?.updatedAt && moment(obj?.updatedAt).format("DD/MM/YYYY")}</td>
                                </>
                              )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>No data found...</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/******** pagination ************* */}

                  {this.state.totalPagesIs > 0 && (
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.totalPagesIs}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  )}
                </>
              )}
            </div>

            <ProposalModal {...this.state} {...this.props} handleFunc={func} />
          </section>
        )}
        <CloseProjectReasonModal
          {...this.state}
          {...this.props}
          funcIs={tableFunc}
          timeOutIs="yes"
          // handleTimeOut={this.handleMileStoneList}
        />
      </React.Fragment>
    );
  }
}
const mapState = ({
  milesList,
  inActBriefForAm,
  inactiveProject,
  activeTransactions,
  transactionList,
  completeProject,
  closedBriefAllList,
  closeTransactionList,
  deleteProjectAllList,
  deleteBriefList,
}) => {
  return {
    _milesList: milesList,
    _closeProject: closeProject,
    _deleteProjectList: deleteProjectList,
    _inActBriefForAm: inActBriefForAm,
    _inactiveProject: inactiveProject,
    _activeTransactions: activeTransactions,
    _transactionList: transactionList,
    _completeProject: completeProject,
    _closedBriefAllList: closedBriefAllList,
    _closeTransactionList: closeTransactionList,
    _deleteProjectAllList: deleteProjectAllList,
    _deleteBriefList: deleteBriefList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $milesList: (values) => dispatchEvent(milesList(values)),
  $closeProject: (values) => dispatchEvent(closeProject(values)),
  $deleteProjectList: (values) => dispatchEvent(deleteProjectList(values)),
  $inActBriefForAm: (values) => dispatchEvent(inActBriefForAm(values)),
  $inactiveProject: (values) => dispatchEvent(inactiveProject(values)),
  $activeTransactions: (values) => dispatchEvent(activeTransactions(values)),
  $transactionList: (values) => dispatchEvent(transactionList(values)),
  $completeProject: (values) => dispatchEvent(completeProject(values)),
  $closedBriefAllList: (values) => dispatchEvent(closedBriefAllList(values)),
  $closeTransactionList: (values) =>
    dispatchEvent(closeTransactionList(values)),
  $deleteProjectAllList: (values) =>
    dispatchEvent(deleteProjectAllList(values)),
  $deleteBriefList: (values) => dispatchEvent(deleteBriefList(values)),
});
export default connect(mapState, mapDispatch)(Milestones);
