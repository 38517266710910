import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { AllCollateralList } from "../../../store/action/projectList.action";

class Reports extends Component {
  state = {
    selectedOption: null,
    collateralName : "",
    optionsIs: []
  };

  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    this.setState({collateralName : inputValue },()=>{
      this.getCollateralList()
    })
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption },()=>{
      this.props.handlefunc.reportState({CollateralForReport :selectedOption})
    });
  };

  getCollateralList = () => {
    const{ collateralName } = this.state;
    this.props.$AllCollateralList(collateralName)
  }

  componentDidMount() {
     this.getCollateralList()
  }

  componentWillReceiveProps(nextProps){
    const collateralData = nextProps._AllCollateralList.allCollateralIs;
    if(collateralData){
      this.setState({optionsIs : collateralData?.data})
    }
  }

  render() {
    const { selectedOption, optionsIs } = this.state;

    const options = optionsIs?.map((obj)=>{
      let data = { label : obj.name, ...obj }
      return data
    })

    return (
      <React.Fragment>
        <Select
          value={selectedOption}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          options={options}
          placeholder = "Search Collateral"
        />
      </React.Fragment>
    );
  }
}
const mapState = ({
  AllCollateralList
}) => {
  return {
    _AllCollateralList: AllCollateralList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $AllCollateralList: (values) => dispatchEvent(AllCollateralList(values)),
});
export default connect(mapState, mapDispatch)(Reports);