import { put, call } from "redux-saga/effects";
import { getPublicAPI } from "../api-interface";

import { milesListSuccess,transitionDashboardSuccess } from "../action/Milestone.action";

// backend user list *****************

export function* onMilesList(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/postProject/get-active-milestone-list?page=${values.pageIs}&limit=10&name=${values.name}&type=${values.type}`,
        values
      );
      if (data.status === 200) {
        yield put(milesListSuccess(data.data));
      }
    }
  } catch (error) {
    //alert("Error: ", error.response.status);
  }
}

export function* onTransitionDasboard(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/manager-dashboard?from_date=${values.from_date}&till_date=${values.to_date}&manager_id=${values.id}&collateral_id=${values.collId}`,
        values
      );
      if (data.status === 200) {
        yield put(transitionDashboardSuccess(data.data));
      }
    }
  } catch (error) {
    //alert("Error: ", error.response.status);
  }
}