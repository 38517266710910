import React, { Component } from "react";
import ArtistReview from "./ArtistReview";

export default class ArtistProjectReview extends Component {
  render() {
    const {
      handleProject,
      reviewerFeedback,
      reviewerName,
      reviewerCompany,
      reviewerWebsite,
      reviewerEmail,
      reqReviewName
    } = this.props;

    const reviewArr = [
      { name: "reviewerName", value: reviewerName, label: "Name" },
      {
        name: "reviewerCompany",
        value: reviewerCompany,
        label: "Company name",
      },
      {
        name: "reviewerEmail",
        value: reviewerEmail,
        label: "Reviewer Email",
      },
      { name: "reqReviewName", value: reqReviewName, label: "Request Reviewer Name" },
      { name: "reviewerWebsite", value: reviewerWebsite, label: "Website url" },
      { name: "reviewerFeedback", value: reviewerFeedback, label: "Feedback" },
    ];

    return (
      <React.Fragment>
        <div
          className="questin-Listing"
          style={{ borderTop: "none", borderRadius: "0 0 5px 5px" }}
        >
          <div className="row mt-3">
            {reviewArr.map((obj, index) => (
              <React.Fragment key={index}>               
              {  obj.name !== "reviewerFeedback" &&
                <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>{obj.label}</label>
                  <input
                    type="text"
                    className="form-control"
                    name={obj.name}
                    value={obj.value}
                    onChange={handleProject.handleOnChange}
                    disabled="disabled"
                  />
                </div>
              </div>}
              {  obj.name === "reviewerFeedback" &&
                <div className="col-12 col-md-12">
                <div className="form-group">
                  <label>Feedback</label>
                  <textarea
                    type="text"
                    className="form-control"
                    name="reviewerFeedback"
                    value={reviewerFeedback}
                    onChange={handleProject.handleOnChange}
                    disabled="disabled"
                  ></textarea>
                </div>
              </div>
              }
              </React.Fragment>
            ))}            

            {/*--------------------Review -------------------*/}
            <ArtistReview {...this.state} {...this.props} />

            {/* <div className="col-12 d-flex justify-content-end">
              <button className="btn btn-primary">Update</button>
            </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
