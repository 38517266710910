import React, { Component } from "react";

export default class Loader extends Component {
  render() {
    return (
      <React.Fragment>
        <span className="d-flex justify-content-center align-items-center mt-5 mb-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </span>
      </React.Fragment>
    );
  }
}
