import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getArtistData,
  stausArtistCollateral,
} from "../../../store/action/clientList.action";
import ErrorApi from "../Common/Error/ErrorApi";
import { profileBaseUrl } from "../../../Utils/BaseUrl";
import CollateralUpdate from "./CollateralUpdate";
import StarredModal from "./StarredModal";

class ArtistCollateral extends Component {
  state = {
    artistCollateral: [],
    formVisibility: false,
    artistCollateralName: "",
    artitColExp: "",
    startPrice: "",
    EndPrice: "",
    averageTime: "",
    type: "",
    collateralIndex: "",
    acceptRejectIs: false,
    collateralId: "",
    collateralList: false,
    editIs: true,
    styleQs: "",
    styleAns: [],
    typeQs : '',
    typeAns: [],
    starredModIs:false
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    const { collateralId } = this.state;
    this.setState({
      [name]: value,
    });
    if (name === "artistCollateralName" && collateralId) {
      this.handleCollateralName();
    }
  };

  handleCollateralName = () => {
    const { collateralList } = this.state;
    this.setState({
      collateralList: !collateralList,
    });
  };

  handleCollateralSelection = (obj) => {
    this.setState({
      artistCollateralName: obj,
      collateralList: false,
    });
  };

  handleGetConfirmation(obj, id) {
    let confirmIs = window.confirm(
      `Do you want to ${obj === 1 ? `accept` : `reject`} this Collateral ?`
    );
    if (confirmIs === true) {
      const statusData = { _id: id, status: obj };
      this.props.$stausArtistCollateral(statusData);
      this.setState({
        acceptRejectIs: true,
      });
      return true;
    } else {
      return false;
    }
  }

  handleArtistCollateralStatus = (status, id) => {
    let currentStatus = 0;
    if (status === "accept") {
      currentStatus = 1;
    } else if (status === "reject") {
      currentStatus = 2;
    } else if (status === "delete_accept") {
      currentStatus = 3;
    } else if (status === "delete_reject") {
      currentStatus = 1;
    }

    this.handleGetConfirmation(currentStatus, id);
  };

  handleEditOnClick = () => {
    this.setState({
      editIs: false,
    });
  };

  handleShowArtistCollateral = (obj, index) => {
    const { formVisibility } = this.state;
    const styleQuestion = obj.style_question[0];
    const typeQuestion = obj.type_question[0];
    // const collateralId = obj.collateral_id;
    this.setState(
      {
        formVisibility: !formVisibility,
        artitColExp: obj.year,
        startPrice: obj.start_price,
        EndPrice: obj.end_price,
        averageTime: obj.time,
        type: obj.type,
        collateralIndex: index,
        collateralId: obj.collateral_id,
        artistCollateralName: obj.collateral_name,
        styleQs: styleQuestion ? styleQuestion.question : "",
        styleAns: styleQuestion ? styleQuestion.answer : [],
        typeQs : typeQuestion ? typeQuestion.question :'',
        typeAns: typeQuestion ? typeQuestion.answer : []
      },
      () => {
        console.log("DSFSDFSDGDSG", this.state.styleQuestion);
      }
    );
    // if (!collateralId) {
    //   this.setState({
    //     artistCollateralName: obj.collateral_name,
    //   });
    // }
  };

  handleGetArtistCollateral = (id) => {
    this.props.$getArtistData(id);
  };

  handleCloseModal = () => {
    this.setState({starredModIs:false})
  }

  handleStarModal = (id, list) => {
    this.setState({
      starId:id,
      collateralCheckList: [list],
      starredModIs: true,
    })
  }

  handleCallApi = () => {
    this.handleGetArtistCollateral(this.state.userId);
  }

  componentDidMount() {
    const artistParamsId = this.props.match.params.id.split(":").pop();
    this.setState({userId:artistParamsId})
    this.handleGetArtistCollateral(artistParamsId);
  }

  componentWillReceiveProps(nextProps) {
    const { acceptRejectIs } = this.state;
    const creatorData = nextProps._getArtistData.getCreatorData;
    const artiststatusDta = nextProps._stausArtistCollateral.artistColstatus;
    if (artiststatusDta.status === "1" && acceptRejectIs) {
      const artistParamsId = this.props.match.params.id.split(":").pop();
      this.handleGetArtistCollateral(artistParamsId);
      this.setState({
        acceptRejectIs: false,
      });
    }
    if (creatorData) {
      this.setState({
        artistCollateral: creatorData.userCollateral,
      });
    }
  }

  renderCollateralForm() {
    const {
      artistCollateralName,
      artitColExp,
      startPrice,
      EndPrice,
      averageTime,
      type,
      collateralList,
      editIs,
      styleQs,
      styleAns,
      typeQs,
      typeAns
    } = this.state;

    return (
      <div
        className="questin-Listing"
        style={{ borderTop: "none", borderRadius: "0 0 5px 5px" }}
      >
        <div className="row mt-3">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label>Collateral name</label>
              <input
                type="text"
                className="form-control"
                name="artistCollateralName"
                value={artistCollateralName}
                onChange={this.handleOnChange}
                disabled={editIs ? "disabled" : ""}
              />
              {collateralList && (
                <ul
                  id="dlist12"
                  className="list-group list-group-sm pcf-list "
                  // className={`list-group list-group-sm pcf-list
                  // ${
                  //   pcfDropdownData.length ? "scroll-list" : ""
                  // }
                  // `}
                  style={{ boxShadow: "0px 0px 3px 0px silver" }}
                >
                  <li
                    className="list-group-item"
                    onClick={() =>
                      this.handleCollateralSelection("Collateral 1")
                    }
                  >
                    Collateral 1
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label>Experience</label>
              <input
                type="text"
                className="form-control"
                name="artitColExp"
                value={`${artitColExp} year`}
                onChange={this.handleOnChange}
                disabled={editIs ? "disabled" : ""}
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="mb-1">Start Price</label>
              <input
                type="text"
                className="form-control"
                name="startPrice"
                value={startPrice}
                onChange={this.handleOnChange}
                disabled={editIs ? "disabled" : ""}
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="mb-1">End Price</label>
              <input
                type="text"
                className="form-control"
                name="EndPrice"
                value={EndPrice}
                onChange={this.handleOnChange}
                disabled={editIs ? "disabled" : ""}
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="mb-1">Average Taken Time</label>
              <input
                type="text"
                className="form-control"
                name="averageTime"
                value={averageTime}
                onChange={this.handleOnChange}
                disabled={editIs ? "disabled" : ""}
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="mb-1">Type</label>
              <input
                type="email"
                className="form-control"
                name="type"
                value={type}
                onChange={this.handleOnChange}
                disabled={editIs ? "disabled" : ""}
              />
            </div>
          </div>
          <hr className="my-5" />
          {/*-------------------------style Question ----------------------*/}
         {styleQs &&
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="mb-1">Style Question</label>
              <input
                type="email"
                className="form-control"
                name="styleQs"
                value={styleQs}
                onChange={this.handleOnChange}
                disabled={editIs ? "disabled" : ""}
              />
            </div>
          </div>}
          {styleAns.length > 0 &&
          <div className="col-12">
            <div className="form-group">
              <label className="mb-1">Answer</label>
              <div className="row">
              {styleAns.map((obj, index) => ( 
                <div className="col-4 mb-2">              
                <div className="questin-Listing" key={index}>
                  <div className="row">
                    <div className="col-9">
                      <p className="mb-0">{obj.item}</p>
                    </div>
                    <div className="col-3">
                      <img
                        src={`${obj.image ? profileBaseUrl + obj.image : ''}`}
                        height="30px"
                        width="30px"
                        alt=".."
                      />
                    </div>
                  </div>
                </div>      
                </div>          
              ))}
              </div>
            </div>
          </div>}
          {/*-------------------------style Question ----------------------*/}
          {/*-------------------------type Question ----------------------*/}
          {typeQs &&
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="mb-1">Type Question</label>
              <input
                type="email"
                className="form-control"
                name="typeQs"
                value={typeQs}
                onChange={this.handleOnChange}
                disabled={editIs ? "disabled" : ""}
              />
            </div>
          </div>}
          {typeAns &&
          <div className="col-12">
            <div className="form-group">
              <label className="mb-1">Answer</label>
              <div className="row">
              {typeAns.map((obj, index) => ( 
                <div className="col-4 mb-2">              
                <div className="questin-Listing" key={index}>
                  <div className="row">
                    <div className="col-9">
                      <p className="mb-0">{obj.item}</p>
                    </div>
                    <div className="col-3">
                      <img
                        src={`${obj.image ? profileBaseUrl + obj.image : ''}`}
                        height="30px"
                        width="30px"
                        alt=".."
                      />
                    </div>
                  </div>
                </div>      
                </div>          
              ))}
              </div>
            </div>
          </div>}
          {/*-------------------------type Question ----------------------*/}

          {/* <div className="col-12">
            <button
              className="btn btn-primary"
              onClick={this.handleEditOnClick}
            >
              {editIs ? "Edit" : "Update"}
            </button>
          </div> */}
        </div>
      </div>
    );
  }

  render() {
    const { artistCollateral, formVisibility, collateralIndex } = this.state;
    const {_getArtistData} = this.props;
    return this.props._getArtistData.getArtistError ? (
      <ErrorApi {...this.props} />
    ) : (
      <React.Fragment>
        {_getArtistData.getCreatorData && <CollateralUpdate getData={this.handleGetArtistCollateral} {...this.props}/>}
        {artistCollateral.length ? (
            <section className="container-fluid">
            {artistCollateral.map((obj, index) => (
              <div className="mx-auto col-md-12 col-lg-10" key={index}>
                <div className="questin-Listing p-4">
                  <div className="row">
                    <div className="col-2 d-flex align-items-center">
                      <h5 className="mb-0 text-secondary">
                        {obj.collateral_name}
                      </h5>
                    </div>
                    <div className="col-4 col-md-3 d-flex align-items-center">
                      <ul className="mb-0 show_option text-secondary artist-col">
                        {/* <li className="mr-3">{obj.experience} Years</li> */}
                        <li className="mr-3">
                          {obj.start_price}-{obj.end_price}
                        </li>
                        <li className="mr-3">
                          {obj.time}
                          {obj.type}
                        </li>
                      </ul>
                    </div>
                    <div className="col-6 col-md-6">
                      <div className="row">
                        <div className="col-5">
                          {(obj.status === 0 || obj.status === 4) && (
                            <span>
                              <div
                                className="badge badge-soft-info"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.handleArtistCollateralStatus(
                                    `${
                                      obj.status === 4
                                        ? "delete_accept"
                                        : "accept"
                                    }`,
                                    obj._id
                                  )
                                }
                              >
                                {obj.status === 4 ? "Delete_accept" : "Accept"}
                              </div>
                              <div
                                className="badge badge-soft-info ml-4"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.handleArtistCollateralStatus(
                                    `${
                                      obj.status === 4
                                        ? "delete_reject"
                                        : "reject"
                                    }`,
                                    obj._id
                                  )
                                }
                              >
                                {obj.status === 4 ? "Delete_Reject" : "Reject"}
                              </div>
                            </span>
                          )}
                          {obj.status === 1 && (
                            <div className="badge badge-soft-success">
                              Accepted
                            </div>
                          )}
                          {obj.status === 2 && (
                            <div className="badge badge-soft-danger">
                              Rejected
                            </div>
                          )}
                          {obj.status === 3 && (
                            <div className="badge badge-soft-dark">Deleted</div>
                          )}
                        </div>
                        <div className="col-5 b-0 show_option text-secondary artist-col">{obj.admin_email}
                          {/* <ul className="mb-0 show_option text-secondary artist-col">
                            <li className="mr-3">{obj.admin_email}</li>
                          </ul> */}
                        </div>
                        <div className=" col-1 ">
                          <p
                            className="mb-0"
                            onClick={() =>
                              this.handleShowArtistCollateral(obj, index)
                            }
                          >
                            <i
                              className={`${
                                formVisibility && collateralIndex === index
                                  ? "fe fe-chevron-up"
                                  : "fe fe-chevron-down"
                              }`}
                            ></i>
                          </p>
                        </div>
                      </div>
                    </div>
                     
                  </div>
                  <div className="row mt-4">
                    
                      {obj?.status == 1 &&
                        <div className={`col-1 stars-rating ${obj?.star ? obj.star == "0" ? "" : "active" : ""}`} style={{ lineHeight: ".8" }} >
                          <input type="radio" id={`${index + "star"}`} name="rating" checked={obj?.star == 1 ? true : false} onClick={() => this.handleStarModal(obj?.user_id,obj)} />
                          <label for={`${index + "star"}`} className="mb-0 star">&#9733;</label>
                        </div>
                      } 
                      {obj?.star_by && (obj?.status == 1 && obj?.star == "1" ?
                        <div className={`col`} >
                         <span style={{color:"#6e84a3"}}>By : </span>{ obj?.star_by?.first_name + " " + obj?.star_by?.last_name}
                        </div> : "")
                      }
                      {obj?.status == 1 && obj?.star == "1" ?
                        <div className={`col-7`} >
                         <span style={{color:"#6e84a3"}}>Note : </span>{ obj?.star_description}
                        </div> : ""
                      }
                  </div>
                </div>
                {formVisibility &&
                  collateralIndex === index &&
                  this.renderCollateralForm()}
                <hr className="my-5" />
              </div>
            ))}
          </section>
        ) : (
          <h3 className="text-center text-secondary">
            Artist dont have any Collateral..
          </h3>
          )}
          
        <StarredModal
          handleCloseModal={this.handleCloseModal}
          handleCallApi = {this.handleCallApi}
          {...this.state}
        />
      </React.Fragment>
    );
  }
}
const mapState = ({ getArtistData, stausArtistCollateral }) => {
  return {
    _getArtistData: getArtistData,
    _stausArtistCollateral: stausArtistCollateral,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $getArtistData: (values) => dispatchEvent(getArtistData(values)),
  $stausArtistCollateral: (values) =>
    dispatchEvent(stausArtistCollateral(values)),
});
export default connect(mapState, mapDispatch)(ArtistCollateral);
