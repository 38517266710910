import React, { Component } from 'react'

import ActiveTransactions from '../Transactions/ActiveTransactions';
import { connect } from 'react-redux';

import { activeTransactions, closedBriefAllList, closeTransactionList, completeProject, deleteBriefList, deleteProjectAllList, DontLikeTranscationList, incompleteTransactions, pendingTransactions, rejectedBriefList, transactionList } from '../../../store/action/transaction.action';
import { Link } from 'react-router-dom';
import PendingTransactions from '../Transactions/PendingTransactions';
import CompleteTransactions from '../Transactions/CompleteTransactions';
import RejectedTransactionList from '../Transactions/RejectedTransactionList';
import Transactions from '../Transactions/Transactions';



class ArtistTransaction extends Component {
    state = {
        tab: 0
    }




    render() {
        const {
            _transactionList,
            _activeTransactions,
            _completeProject,
            _pendingTransactions,
            _rejectedBriefList,

        } = this.props;


        const transactionArr = [
            {
                name: "Approval Pending",
                tab:1,

                transactionTotal: _pendingTransactions?.pendingList?.totalUsers,
            },
       
            {
                name: "Active Project",
                tab:2,

                transactionTotal: _activeTransactions?.activeList?.totalUsers,
            },
            {
                name: "Active Brief",
                tab:0,

                transactionTotal: _transactionList?.transactionDetails?.totalUsers,
            },
            {
                name: "Completed",
                tab:3,

                transactionTotal: _completeProject?.completeTransactionsIs?.totalUsers,
            },
            {
                name: "Rejected",
                tab:4,
   
                transactionTotal: _rejectedBriefList?.rejectedBriefListIs?.totalUsers
            },
        ];
        return (<React.Fragment>

<ul className="nav nav-tabs nav-overflow header-tabs" style={{marginTop:'-20px'}}>
              {transactionArr.map((obj, index) => (
                <li className="nav-item" key={index}>
                  <Link
                    onClick={(e)=>this.setState({tab:obj.tab})}
                    className={`nav-link ${
                        this.state.tab===obj.tab ? "active" : ""
                    }`}
                  >
                    {obj.name}
                    <span className="badge badge-pill badge-soft-secondary ml-2">
                      {obj.transactionTotal}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
     
            <div style={{marginTop:'24px'}}></div>
            <hr></hr>

           { this.state.tab===2&&<ActiveTransactions {...this.props}/>}
           { this.state.tab===1&&<PendingTransactions {...this.props}/>}
           { this.state.tab===3&&<CompleteTransactions {...this.props}/>}
           { this.state.tab===4&&<RejectedTransactionList {...this.props}/>}
           { this.state.tab===0&&<Transactions {...this.props}/>}

     


        </React.Fragment>)

    }
}

const mapState = ({
    transactionList,
    activeTransactions,
    incompleteTransactions,
    completeProject,
    closeTransactionList,
    DontLikeTranscationList,
    pendingTransactions,
    rejectedBriefList,
    deleteBriefList,
    deleteProjectAllList,
    closedBriefAllList
}) => {
    return {
        _transactionList: transactionList,
        _activeTransactions: activeTransactions,
        _incompleteTransactions: incompleteTransactions,
        _completeProject: completeProject,
        _closeTransactionList: closeTransactionList,
        _DontLikeTranscationList: DontLikeTranscationList,
        _pendingTransactions: pendingTransactions,
        _rejectedBriefList: rejectedBriefList,
        _deleteBriefList: deleteBriefList,
        _deleteProjectAllList: deleteProjectAllList,
        _closedBriefAllList: closedBriefAllList
    };
};
const mapDispatch = (dispatchEvent) => ({
    $transactionList: (values) => dispatchEvent(transactionList(values)),
    $activeTransactions: (values) => dispatchEvent(activeTransactions(values)),
    $incompleteTransactions: (values) =>
        dispatchEvent(incompleteTransactions(values)),
    $completeProject: (values) => dispatchEvent(completeProject(values)),
    $closeTransactionList: (values) =>
        dispatchEvent(closeTransactionList(values)),
    $DontLikeTranscationList: (values) =>
        dispatchEvent(DontLikeTranscationList(values)),
    $pendingTransactions: (values) => dispatchEvent(pendingTransactions(values)),
    $rejectedBriefList: (values) => dispatchEvent(rejectedBriefList(values)),
    $deleteBriefList: (values) => dispatchEvent(deleteBriefList(values)),
    $deleteProjectAllList: (values) => dispatchEvent(deleteProjectAllList(values)),
    $closedBriefAllList: (values) => dispatchEvent(closedBriefAllList(values)),
});
export default connect(mapState, mapDispatch)(ArtistTransaction);

