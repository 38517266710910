import React, { Component } from "react";
import { postAPI } from "../../../store/api-interface";
import { connect } from "react-redux";
import { updateUsersData , viewUsersData } from "../../../store/action/clientList.action";

 class CreativeFieldForms extends Component {
  state = {
    fieldId: "",
    nameIs: "",
    fieldNameIs: "",
    LoadingIs : false
  };

  handleOnchange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    const { fieldId, fieldNameIs } = this.state;
    const updateData = { name: fieldNameIs, _id: fieldId };
    this.setState({
        LoadingIs : true
    })
    this.props.$updateUsersData(updateData);
  };

  handleView = (obj) => {
    this.props.$viewUsersData(obj);
  };

  validateForm() {
    const { nameIs } = this.state;
    return nameIs.length;
  }

  handleAddCreativeField = (e) => {
    e.preventDefault();
    const { nameIs } = this.state;
    const data = { name: nameIs };
    this.setState({ LoadingIs :true})
    if (this.validateForm()) {
      postAPI("/parentCreativeField/add-field", data)
        .then((res) => {
          console.log("add api review---", res);
          this.props.history.push("/parentcreative");
        })
        .catch((error) => {
          console.log("add api error ---->", error);
        });
    }
  };

  componentWillReceiveProps(nextProps){
    const updatedata = nextProps._updateUsersData.updateUserContent;
    const viewData = nextProps._viewUsersData.viewUserContent;
    if(updatedata){
      this.props.history.push("/parentcreative");
    }
    if(viewData.status === "1"){
      this.setState({
        fieldNameIs : viewData.data.name
      })
    }
  }

  componentDidMount() {
    const idParam = this.props.match.params.id.split(":").pop();
    const nameParam = this.props.match.params.name.split(":").pop();
    const typeParam = this.props.match.params.type.split(":").pop();
    this.setState({
      fieldId: idParam,
      fieldNameIs: nameParam,
    });
    if(typeParam === 'view'){
      this.handleView(idParam);
    }
  }

  renderAddForm() {
    const { nameIs, LoadingIs } = this.state;
    return (
      <React.Fragment>
        <section className="creative-field">
          <div className="container mx-auto">
            <div className="create-collateral mb-4 col-md-8">
              <form
                className="my-2 my-lg-0"
                onSubmit={this.handleAddCreativeField}
              >
                <div className="form-group ">
                  <input
                    className="form-control mr-sm-2"
                    type="text"
                    placeholder="Enter name"
                    name="nameIs"
                    value={nameIs}
                    onChange={this.handleOnchange}
                  />
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-outline-primary my-2 my-sm-0 mt-0 col-md-3"
                    type="submit"
                  >
                    {
                        LoadingIs ? "Saving..." : "Save"
                    }
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }

  render() {
    const typeParam = this.props.match.params.type.split(":").pop();
    const { fieldNameIs, fieldId, LoadingIs } = this.state;

    return (
      <React.Fragment>
        {typeParam === "add" ? (
          this.renderAddForm()
        ) : (
          <section className="creative-field">
            <div className="container mx-auto">
              <div className="create-collateral mb-4 col-md-8">
                <form
                  className="my-2 my-lg-0"
                  onSubmit={
                    typeParam === "edit"
                      ? this.handleUpdate
                      : typeParam === "view"
                      ? this.handleView
                      : ''
                  }
                >
                  <input
                    type="hidden"
                    name="fieldId"
                    value={fieldId}
                    onChange={this.handleOnchange}
                  />
                  <div className="form-group ">
                    <input
                      className="form-control mr-sm-2"
                      type="text"
                      placeholder="Enter name"
                      name="fieldNameIs"
                      value={fieldNameIs}
                      onChange={this.handleOnchange}
                    />
                  </div>
                  { (typeParam !== 'view') &&
                  <div className="form-group">
                    <button
                      className="btn btn-outline-primary my-2 my-sm-0 mt-0 col-md-3"
                      type="submit"
                    >
                      {
                          typeParam === "edit" &&
                          LoadingIs? "Updating..." : "Update"
                      }
                    </button>
                  </div>
                 }
                </form>
              </div>
            </div>
          </section>
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({ updateUsersData, viewUsersData }) => {
  return {
    _updateUsersData: updateUsersData,
    _viewUsersData : viewUsersData
  };
};
const mapDispatch = (dispatchEvent) => ({
  $updateUsersData: (values) => dispatchEvent(updateUsersData(values)),
  $viewUsersData: (values) => dispatchEvent(viewUsersData(values)),
});
export default connect(mapState, mapDispatch)(CreativeFieldForms);
