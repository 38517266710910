import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getBackendUser,
  getTabList,
  addtabList,
  addBackendUser,
} from "../../../store/action/BackendUser.action";
import Select from "react-select";
import { postAPI, getPublicAPI, postImage, putAPI } from "../../../store/api-interface";
import LoaderSmall from "../Common/Loader/LoaderSmall";
import { userProfileBaseUrl } from '../../../Utils/BaseUrl';

class BackendUserForm extends Component {
  state = {
    email: "",
    first_name: "",
    last_name: "",
    userName: "",
    optionsIs: [],
    roleIs: "",
    pwd: "",
    selectedTab: "",
    tabListIs: [],
    tabAddLoading: false,
    adminIs: "0",
    addUSerIs: false,
    makeUserIs: false,
    LoadingIs: false,
    phone: '',
    profileImage: '',
    errorIs: { email: "", userName: "", pwd: "", first_name: "", last_name: "", phone: "" },
  };

  handleUploadImage = async (e) => {
    let artistProfile = e.target.files[0];
    if (artistProfile) {
      this.setState({
        imageLoader: true,
      });
      const formData = new FormData();
      formData.append("userImages", artistProfile);
      // this.props.$artistProfile(formData);
      const imageData = await postImage("/backendUser/profile-image", formData);
      this.setState({imageLoader: false, profileImage: imageData.data.name})
    }
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    this.validateForm(name, value)
  };

  handleChange = (selectedTab) => {
    this.setState({ selectedTab: selectedTab });
  };

  handleTabSubmit = () => {
    const { selectedTab } = this.state;
    const userId = this.props.match.params.id.split(":").slice(0)[1];
    if (selectedTab) {
      this.setState({ tabAddLoading: true });
      const dataIs = { _id: userId, tab: selectedTab.label };
      this.handleAddTabList(dataIs);
    } else {
      alert("Please select any tab...");
    }
  };

  handleAddTabList = (dataIs) => {
    postAPI(`/backendUser/add-tab-admin-user`, dataIs)
      .then((res) => {
        if (res) {
          const userId = this.props.match.params.id.split(":").slice(0)[1];
          this.setState({LoadingIs : true})
          this.handleUserData(userId);
          this.handleGetAuthTab();
          alert("Succesfully added..")
        }
      })
      .catch((err) => {
        err.response.data.message && alert(err.response.data.message);
      });
  };

  handleGetAuthTab = () => {
    getPublicAPI(`/backendUser/get-admin-user-data`)
  }

  validateForm(name, value){
    const{ errorIs } = this.state;
    switch(name){
      case "email":
        errorIs.email = !value.length > 0 ? "Required Field" : "";
        break;
      case "pwd":
        errorIs.pwd = !value.length > 0 ? "Required Field" : "";
        break;
      case "userName":
        errorIs.userName = !value.length > 0 ? "Required Field" : ""; 
        break;
      case "first_name":
        errorIs.first_name = !value.length > 0 ? "Required Field" : ""; 
        break;
      case "last_name":
        errorIs.last_name = !value.length > 0 ? "Required Field" : ""; 
        break;
      case "phone":
        errorIs.phone = !value.length > 0 ? "Required Field" : ""; 
        break;
      default:
        break;  
    }
  }

  validation = () => {
    const userId = this.props.match.params.id.split(":").slice(0)[1];
    const { errorIs, email, userName, pwd, first_name, last_name, phone } = this.state;
    errorIs.email = !email.length > 0 ? "Required Field" : "";
    errorIs.userName = !userName.length > 0 ? "Required Field" : "";
    errorIs.pwd = !pwd.length > 0 ? "Required Field" : "";
    errorIs.first_name = !first_name.length > 0 ? "Required Field" : "";
    errorIs.last_name = !last_name.length > 0 ? "Required Field" : "";
    errorIs.phone = !phone.length > 0 ? "Required Field" : "";

    this.setState({ errorIs });
    return !errorIs.email && !errorIs.userName && (userId || !errorIs.pwd) && !errorIs.first_name && !errorIs.last_name;
  };

  handleUser = () => {
    const userId = this.props.match.params.id.split(":").slice(0)[1];
    if (this.validation()) {
      this.setState({
        makeUserIs: true,
      });
      if(userId){
        this.updateUser(userId)
      } else {
        this.addNewUser()
      }
    } else {
      console.log('Validation incomplete')
    }
  };

  addNewUser = () => {
    const { email, userName, adminIs, pwd, first_name, last_name, phone, profileImage } = this.state;
    const currentData = {
      email: email,
      username: userName,
      password: pwd,
      role: adminIs,
      first_name, last_name, 
      phone_number: phone,
      profile_img: profileImage,
      manager: 0
    };

    this.props.$addBackendUser(currentData);
  }

  updateUser = (userId) => {
    const { email, userName, adminIs, first_name, last_name, phone, profileImage, manager } = this.state;
    const currentData = {
      _id: userId,
      email: email,
      username: userName,
      role: adminIs,
      first_name, last_name, 
      phone_number: phone,
      profile_img: profileImage,
      manager: manager ? manager : 0
    };

    putAPI('/backendUser/update-admin-user', currentData).then(() => {
      this.setState({makeUserIs: false});
      this.props.mountListAgain();
    }).catch(err => console.log(err))
  }

  handleUserData = (id) => {
    this.props.$getBackendUser(id);
  };

  handleTabList = () => {
    this.props.$getTabList();
  };

  componentDidMount() {
    const userId = this.props.match.params.id.split(":").slice(0)[1];
    if (userId) {
      this.setState({ LoadingIs: true });
      this.handleUserData(userId);
    } else {
      this.setState({
        addUSerIs: true,
      });
    }
    this.handleTabList();
  }

  componentWillReceiveProps(nextProps) {
    const DataIs = nextProps._getBackendUser.backendUserIs;
    const tabIs = nextProps._getTabList.tabListIs;
    const createdUser = nextProps._addBackendUser.addbackendUserIs;
    const createdUserErr = nextProps._addBackendUser.addBackendUserErrIs;
    const { makeUserIs, addUSerIs, LoadingIs } = this.state;
    if (DataIs && !addUSerIs && LoadingIs) {
      let response = DataIs.user;
      
      this.setState({
        email: response.email,
        userName: response.username,
        roleIs: response.role,
        tabListIs: DataIs.tabList,
        adminIs: response.role.toString(),
        LoadingIs: false,

        first_name: response.first_name,
        last_name: response.last_name,
        profileImage: response.profile_img,
        phone: response.phone_number,
        manager: response?.manager,
        userObj: response
      });
    }
    if (tabIs) {
      this.setState({
        optionsIs: tabIs.tabList,
      });
    }
    if (createdUser && makeUserIs) {
      let id = createdUser.data._id
      alert("user created successfully...")
      this.props.history.push(`/backendusers/backendusersForm/:${id}`);
      this.setState({ makeUserIs: false, addUSerIs : false });
    } else if (createdUserErr === 500) {
      alert("Email and username Alreday exist");
      this.setState({ makeUserIs: false });
    }
  }

  render() {
    const {
      email,
      userName,
      optionsIs,
      selectedTab,
      tabListIs,
      addUSerIs,
      adminIs,
      makeUserIs,
      pwd,
      errorIs,
      first_name,
      last_name,
      phone,
      profileImage, imageLoader
    } = this.state;
    const dataArr = [
      { name: "email", value: email, label: "Email", type :"email"},
      { name: "pwd", value: pwd, label: "Password", type : "password" },
      { name: "first_name", value: first_name, label: "First name", type : "text" },
      { name: "last_name", value: last_name, label: "Last name", type : "text" },
      {
        name: "userName",
        value: userName,
        label: "User Name",
        type : "text"
      },
      { name: "phone", value: phone, label: "Phone number", type : "text" },
    ];

    return (
      <React.Fragment>
        <div className="container">
          <div className="questin-Listing p-5">
            <div className="row">
              {dataArr.map(
                (obj, index) =>
                  ((addUSerIs && obj.name === "pwd") ||
                    obj.name === "email" ||
                    obj.name === "userName" ||
                    obj.name === "first_name" ||
                    obj.name === "last_name" ||
                    obj.name === "phone") && (
                    <div className="col-12 col-md-6" key={index}>
                      <div className="form-group">
                        <label>{obj.label}</label>
                        <input
                          // type="text"
                          className={`form-control ${
                            (errorIs.email && obj.name === "email") ||
                            (errorIs.pwd && obj.name === "pwd") ||
                            (errorIs.userName && obj.name === "userName") ||
                            (errorIs.first_name && obj.name === "first_name") ||
                            (errorIs.last_name && obj.name === "last_name") ||
                            (errorIs.phone && obj.name === "phone") ?
                            `error-show` : ''
                          }`}
                          name={obj.name}
                          value={obj.value}
                          onChange={this.handleOnChange}
                          // disabled={!addUSerIs ? "disabled" : ""}
                          type = {obj.type}
                        />
                        {((errorIs.email && obj.name === "email") ||
                          (errorIs.pwd && obj.name === "pwd") ||
                          (errorIs.userName && obj.name === "userName") ||
                          (errorIs.first_name && obj.name === "first_name") || 
                          (errorIs.last_name && obj.name === "last_name") ||
                          (errorIs.phone && obj.name === "phone")) && (
                          <span className="error">
                            {errorIs.email || errorIs.pwd || errorIs.userName || errorIs.first_name || errorIs.last_name || errorIs.phone}
                          </span>
                        )}
                      </div>
                    </div>
                  )
              )}
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label className="mb-3">Choose Admin/user</label>

                  <div className="row col">
                    <div className="col ml-n2">
                      <small className="text-muted">
                        <input
                          type="radio"
                          checked={adminIs === "1"}
                          name="adminIs"
                          value="1"
                          onChange={this.handleOnChange}
                          // disabled={!addUSerIs ? "disabled" : ""}
                        />{" "}
                        Admin
                      </small>
                    </div>
                    <div className="col ml-n2">
                      <small className="text-muted">
                        <input
                          type="radio"
                          name="adminIs"
                          checked={adminIs === "0"}
                          value="0"
                          onChange={this.handleOnChange}
                          // disabled={!addUSerIs ? "disabled" : ""}
                        />{" "}
                        User
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row justify-content-between align-items-center">
                  <div className="col">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <div className="avatar">
                          <img
                            className="avatar-img rounded-circle"
                            src={
                              profileImage
                                ? userProfileBaseUrl + profileImage
                                : require("../../../Assets/img/avatars/profiles/avatar-1.jpg")
                            }
                            alt="..."
                          />
                        </div>
                      </div>
                      <div className="col ml-n2">
                        <h4 className="mb-1">Profile picture</h4>
                        <small className="text-muted">PNG, JPG or JPEG only.</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <input
                      type="file"
                      ref={(ref) => (this.upload = ref)}
                      accept="jpg,jpeg.png"
                      style={{ display: "none" }}
                      onChange={this.handleUploadImage}
                    />
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => this.upload.click()}
                    >
                      {imageLoader ? "Uploading....." : "Upload Image"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* {addUSerIs && ( */}
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn btn-primary"
                  disabled={makeUserIs}
                  onClick={this.handleUser}
                >
                  {makeUserIs ? <LoaderSmall /> : "Submit"}
                </button>
              </div>
            {/* )} */}
          </div>
          {/*-------------------------Add Tab ----------------------------*/}
          <hr className="my-5" />
          {!addUSerIs && (
            <div className="questin-Listing p-5 ">
              <div className="row">
                <div className="col-7">
                  {Array.isArray(this.state.optionsIs) ? (
                    <Select
                      options={optionsIs}
                      onChange={this.handleChange}
                      displayValue="name"
                      value={selectedTab}
                    />
                  ) : (
                    <p>No data found...</p>
                  )}
                </div>
                <div className="col-5">
                  <button
                    className="btn btn-primary"
                    onClick={this.handleTabSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}
          {!addUSerIs && <hr className="my-5" />}
          {!addUSerIs && tabListIs.length > 0 ? (
            <div className="col-12">
              <label>Tabs</label>
              <div className="row">
                {tabListIs.map((obj, index) => (
                  <div className="questin-Listing col-4 mr-2 mb-3" key={index}>
                    <p className="mb-0">{obj.tab}</p>
                  </div>
                ))}
              </div>
              <hr className="my-5" />
            </div>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}
const mapState = ({
  getBackendUser,
  getTabList,
  addtabList,
  addBackendUser,
}) => {
  return {
    _getBackendUser: getBackendUser,
    _getTabList: getTabList,
    _addtabList: addtabList,
    _addBackendUser: addBackendUser,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $getBackendUser: (values) => dispatchEvent(getBackendUser(values)),
  $getTabList: (values) => dispatchEvent(getTabList(values)),
  $addtabList: (values) => dispatchEvent(addtabList(values)),
  $addBackendUser: (values) => dispatchEvent(addBackendUser(values)),
  
});
export default connect(mapState, mapDispatch)(BackendUserForm);
