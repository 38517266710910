import React, { Component } from "react";
import { connect } from "react-redux";
import { collateralActiveNegociation } from "../../../store/action/collateralList.action";
import Loader from "../Common/Loader/Loader";
// import ReactPaginate from "react-paginate";

class ActiveNegociations extends Component {
  state = {
    LoadingIs: false,
    negociationName: "",
    negociationList: 1,
    negociationDataIs: [],
    totalPagesIs: "",
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handlePageClick = (data) => {
    let currentData = data.selected + 1;
    // console.log("--->", currentData);
    this.props.$collateralActiveNegociation(currentData);
  };

  handleActiveNegociations = () => {
    this.setState({
      LoadingIs: true,
    });
    const { negociationList } = this.state;
    this.props.$collateralActiveNegociation(negociationList);
  };

  componentDidMount() {
    this.handleActiveNegociations();
  }

  componentWillReceiveProps(nextProps) {
    const negociationData =
      nextProps._collateralActiveNegociation.collActiveNegociation;
    if (negociationData) {
      this.setState({
        negociationDataIs: negociationData.users,
        totalPagesIs: negociationData.totalPages,
        LoadingIs: false,
      });
    }
  }

  render() {
    const { LoadingIs, negociationName } = this.state;

    const negociationArr = [
      { name: "Project" },
      { name: "Client" },
      { name: "Approved Creator" },
      { name: "Contacted Creator" },
      { name: "Project Size" },
      { name: "Status" },
    ];

    return (
      <React.Fragment>
        <section className="client">
          <div className="container-fluid mx-auto">
            {/*------------------search ---------------------*/}
            <div className="row col-md-12">
              <div className="col">
                <form className="row align-items-center">
                  <div className="col-auto pr-0">
                    <span className="fe fe-search text-muted"></span>
                  </div>
                  <div className="col">
                    <input
                      type="search"
                      className="form-control form-control-flush search"
                      autoComplete="off"
                      placeholder="Search"
                      name="negociationName"
                      value={negociationName}
                      onChange={this.handleOnChange}
                    />
                  </div>
                </form>
              </div>
            </div>
            {/*------------------search ---------------------*/}

            {LoadingIs ? (
              <Loader />
            ) : (
              <div className="table-responsive mb-5 mx-auto">
                <table className="table table-sm table-nowrap card-table">
                  {/*------------------table head --------------------*/}
                  <thead>
                    <tr>
                      {negociationArr.map((obj, index) => (
                        <th key={index}>
                          <a href="!#" className="text-muted sort">
                            {obj.name}
                          </a>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {/*------------------table head --------------------*/}s
                  <tbody className="list">
                    <tr>
                      <td className="orders-order">#6520</td>
                      <td className="orders-product">5' x 3' Wall Poster</td>
                      <td className="orders-total">$55.25</td>
                      <td className="orders-total">$55.25</td>
                      <td className="orders-total">$55.25</td>
                      <td className="orders-status">
                        <div className="badge badge-soft-success">Shipped</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {/**** Pagination****** */}

            {/* {this.state.totalPagesIs > 0 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.totalPagesIs}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )} */}
          </div>
        </section>
      </React.Fragment>
    );
  }
}
const mapState = ({ collateralActiveNegociation }) => {
  return {
    _collateralActiveNegociation: collateralActiveNegociation,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $collateralActiveNegociation: (values) =>
    dispatchEvent(collateralActiveNegociation(values)),
});
export default connect(
  mapState,
  mapDispatch
)(ActiveNegociations);
