import React, { Component } from "react";
import { profileBaseUrl } from "../../../Utils/BaseUrl";

export default class QuestionForm extends Component {
  state = {
    questionIs: "",
    subtext: "",
    optionIs: [],
    answerIs: [],
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleOptions = (val) => {
    const { Qstype } = this.props;
    const { answerIs } = this.state;
    const checkAnswer = answerIs?.some((el) => el.item === val.item);
    if (Qstype === "checkbox_with_images_with_text") {
      if (!checkAnswer && val.item !== "other") {
        const hasOtherData = answerIs?.some((el) => el.item === "other");
        if (hasOtherData) {
          this.setState({ answerIs: [val] });
        } else {
          var answerData = answerIs.concat(val);
          this.setState({ answerIs: answerData });
        }
      } else if (val.item === "other") {
        this.setState({ answerIs: [val] });
      }
    }else{
      this.setState({ answerIs: [val] });
    }
  };

  handleDeleteAnswer = (index) =>{
    const { answerIs } = this.state;
    if (index !== -1) {
      var removedata = answerIs.splice(0, index);
      this.setState({
        answerIs: removedata,
      });
    }
  }

  componentDidMount() {
    const { questionIs, subtext, optionIs, answerIs } = this.props;
    this.setState({ questionIs, subtext, optionIs, answerIs });
  }

  render() {
    const { Qstype, handlefunc, indexIs } = this.props;

    const { questionIs, subtext, optionIs, answerIs } = this.state;

    const contentIs = [
      { name: "Qstype", value: Qstype, label: "Type", disabledIs: true },
      {
        name: "questionIs",
        value: questionIs,
        label: "Question",
        disabledIs: true,
      },
      { name: "subtext", value: subtext, label: "Subtext", disabledIs: true },
    ];

    return (
      <React.Fragment>
        <div
          className="questin-Listing"
          style={{ borderTop: "none", borderRadius: "0 0 5px 5px" }}
        >
          {/* <form> */}
          <div className="row">
            {contentIs.map((obj, index) => (
              <div className="col-12 col-md-6" key={index}>
                <div className="form-group">
                  <label>{obj.label}</label>
                  <input
                    type="text"
                    className={`form-control ${obj.disabledIs ? "disable-input" :""}`}
                    name={obj.name}
                    value={obj.value}
                    onChange={this.handleOnChange}
                    disabled={obj.disabledIs}
                  />
                </div>
              </div>
            ))}
            {optionIs && <label className="col-12">Options</label>}
            {optionIs && optionIs?.length>0
              ? optionIs?.map((val, index) => (
                  <div
                    className="col-4 mb-3"
                    key={index}
                    onClick={() => this.handleOptions(val)}
                  >
                    <div
                      className={`questin-Listing ${
                        answerIs?.some((el) => el?.item === val?.item)
                          ? "selected-option"
                          : ""
                      }`}
                    >
                      <div className="row">
                        {val.item && (
                          <div className="col-10">
                            <p className="mb-0">{val?.item}</p>
                          </div>
                        )}
                        {(val.start_range || val?.end_range) && (
                          <div className="col-10">
                            <p className="mb-0">
                              {/* {console.log("::::option--->", val)} */}
                              {val.start_range}-{val?.end_range}
                            </p>
                          </div>
                        )}
                        {val.image && (
                          <div className="col-2">
                            <img
                              src={`${profileBaseUrl + val?.image}`}
                              alt="..."
                              height="30"
                              width="30"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              : ""}
            {answerIs?.length > 0 && <label className="col-12">Answer</label>}
            {
              answerIs?.map((obj, index) => (
                <div className="col-4 mb-3" key={index} >
                  <div className="questin-Listing">
                    <div className="row">
                      {(obj.start_range || obj.end_range) && (
                        <div className="col-8">
                          <p className="mb-0">
                            {obj.start_range}-{obj.end_range}
                          </p>
                        </div>
                      )}
                      {Qstype !== "file_upload" && (
                        <div className="col-8">
                          <p className="mb-0">{obj.item}</p>
                        </div>
                      )}

                      {(Qstype === "checkbox_with_images_with_text" ||
                        Qstype === "radio_button_with_images_with_text") && (
                        <div className="col-2">
                          <img
                            src={`${profileBaseUrl + obj.image}`}
                            alt="..."
                            height="30"
                            width="30"
                          />
                        </div>
                      )}

                      {Qstype === "file_upload" && (
                        <div className="col-2">
                          <img
                            src={`${profileBaseUrl + obj.item}`}
                            alt="..."
                            height="30"
                            width="30"onClick={() => this.handleDeleteFiles(index)}
                          />
                        </div>
                      )}
                       <div className="col-1"> 
                        <i
                        className="fe fe-trash ml-3"
                        style={{ fontSize: "12px" }}
                        onClick={() => this.handleDeleteAnswer(index)}
                      ></i>
                      </div>
                    </div>                    
                  </div>
                </div>
              ))}
          </div>
          {/* </form> */}
          <div className="d-flex justify-content-end mt-2 mb-2">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handlefunc.handleCancelForm}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-success ml-3"
              onClick={() =>
                handlefunc.handleSaveForm(indexIs, this.state.answerIs)
              }
            >
              Save
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
