import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { getPublicAPI } from "../../../../store/api-interface";
export default class HeaderRightSection extends Component {

  state = {
    amDashboard:[]
  }

  handleSelect = (value) => {
    this.props.history.push(`/milestones/dashboard/:${value?.value}`)
  };

  handleMangerDahboardList = () => {
    getPublicAPI(`/backendUser/transaction-dashboard`).then((res) => {
      if (res) {
        let data = res?.data?.data;
        let arr = []
        data?.length > 0 && res?.data?.role == 1 && arr.push({label: "All",value:""})
        data?.length > 0 && data.forEach(item => {
          arr.push({label: item?.first_name + " " + item?.last_name,value:item?._id})
        })
        this.setState({ amDashboard: arr });
      }
    }).catch((err)=>{
      if(err?.response?.status === 401){
        this.handleLogout();
      }
    });
  };

  componentDidMount(){
    this.handleMangerDahboardList()
  }

  render() {


    const {
      detailsUpdateInfo,
      collateralUpdateInfo,
      workUpdateInfo,
      projectUpdateInfo,
      educationUpdateInfo,
      LoadingIs,
      handlefunc,
      // amDashboard
    } = this.props;

    const {amDashboard} = this.state;

    const { pathIs, location } = this.props;
    const artistId = location.pathname.split("/:").pop();
    const amDash = location.pathname.split("/:")[0]

    const addCollateral = this.props.location.pathname.split("/");

    // console.log("artistId = ", artistId,pathIs)

    return (
      <React.Fragment>
        {
          artistId !== "/artist" &&
          // artistId !== "/artist/artistProfileUpdates" &&
          artistId !== "/artist/artistDraft" &&
          artistId !== "/artist/busy" &&
          artistId !== "/artist/starred" &&
          artistId !== "/artist/approvedArtist" &&
          artistId !== "/artist/artistRejected" &&
          artistId !== "/artist/artistDeleted" &&
          artistId !== "/artist/disabled" &&
          pathIs === "Artist" &&
          (detailsUpdateInfo === 1 ||
            collateralUpdateInfo === 1 ||
            workUpdateInfo === 1 ||
            projectUpdateInfo === 1 ||
            educationUpdateInfo === 1) && (
            <div className="col-auto">
              <Link
                to="#!"
                className="btn btn-primary lift"
                onClick={handlefunc.handleClearUpdates}
              >
                {LoadingIs ? "Cleaning..." : "Clear"}
              </Link>
            </div>
          )}

        {/* {pathIs === "Collaterals" &&
          artistId !== "/collaterals" &&
          addCollateral[2] === "creator" && (
            <span className="row">
              <div className="col-auto">
                <Link
                  to={`/collateralsQuestions/creator/:${artistId}`}
                  className={`lift ${
                    addCollateral[3] !== `approved`
                      ? `btn btn-primary`
                      : `btn btn-secondary`
                  }`}
                >
                  Pending
                </Link>
              </div>
              <div className="col-auto">
                <Link
                  to={`/collateralsQuestions/creator/approved/:${artistId}`}
                  className={`lift ${
                    addCollateral[3] === `approved`
                      ? `btn btn-primary`
                      : `btn btn-secondary`
                  }`}
                >
                  Approved
                </Link>
              </div>
            </span>
          )} */}

        {/*---------------------------Project -----------------------*/}
        {pathIs === "Collaterals" &&
          artistId !== "/collaterals" &&
          addCollateral[2] === "projects" && (
            <span className="row">
              <div className="col-auto">
                <Link
                  to={`/collateralsQuestions/projects/:${artistId}`}
                  className={`lift ${
                    addCollateral[3] !== `approved`
                      ? `btn btn-primary`
                      : `btn btn-secondary`
                  }`}
                >
                  Pending
                </Link>
              </div>
              <div className="col-auto">
                <Link
                  to={`/collateralsQuestions/projects/approved/:${artistId}`}
                  className={`lift ${
                    addCollateral[3] === `approved`
                      ? `btn btn-primary`
                      : `btn btn-secondary`
                  }`}
                >
                  Approved
                </Link>
              </div>
            </span>
          )}

        {/*---------------------Transactions --------------------------*/}

        {pathIs === "Collaterals" &&
          artistId !== "/collaterals" &&
          addCollateral[2] === "transactions" && (
            <span className="row">
              <div className="col-auto">
                <Link
                  to={`/collateralsQuestions/transactions/:${artistId}`}
                  className={`lift ${
                    addCollateral[3] !== `negociations`
                      ? `btn btn-primary`
                      : `btn btn-secondary`
                  }`}
                >
                  Active Transactions
                </Link>
              </div>
              <div className="col-auto">
                <Link
                  to={`/collateralsQuestions/transactions/negociations/:${artistId}`}
                  className={`lift ${
                    addCollateral[3] === `negociations`
                      ? `btn btn-primary`
                      : `btn btn-secondary`
                  }`}
                >
                  Active Negociations
                </Link>
              </div>
              {/* <div className="col-auto">
                        <Link
                          to={`/collateralsQuestions/transactions/negociations/:${artistId}`}
                          className={`lift ${
                            addCollateral[3] === `negociations`
                              ? `btn btn-primary`
                              : `btn btn-secondary`
                          }`}
                        >
                         Completed Transactions
                        </Link>
                      </div>
                      <div className="col-auto">
                        <Link
                          to={`/collateralsQuestions/transactions/negociations/:${artistId}`}
                          className={`lift ${
                            addCollateral[3] === `negociations`
                              ? `btn btn-primary`
                              : `btn btn-secondary`
                          }`}
                        >
                         Incomplete Transactions
                        </Link>
                      </div> */}
            </span>
          )}
        
        {/* -----------------------------------------AM transaction dashboard--------------------------------------? */}
          {
           ( artistId === "/milestones" || amDash === "/milestones/dashboard") && 
            pathIs === "AM Transactions"&& amDashboard?.length > 0 &&
              <div className="col-2">
               <Select
                  className=""
                  options={amDashboard}
                  onChange={this.handleSelect}
                  placeholder="select manager dashboard"
                />
              </div>
          }
      </React.Fragment>
    );
  }
}
