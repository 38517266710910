import React, { Component } from "react";
import { connect } from "react-redux";
import {
  artistDataList,
  artistBusyList,
  artistBusyOrAvailable,
  disableEnableArtist,
  tempResetPwd,
} from "../../../store/action/clientList.action";
import ArtistListingTable from "./ArtistListingTable";
import ReviewStatusModal from "./ReviewStatusModal";
import ReactPaginate from "react-paginate";
import ErrorApi from "../Common/Error/ErrorApi";

class Busy extends Component {
  state = {
    statusEmailIs: "",
    createArtistIs: false,
    artistIs: [],
    artistList: 1,
    ListLoading: false,
    showAction: false,
    showActionIndex: "",
    showReviewPopUp: false,
    artistId: "",
    profileReviewStatus: "",
    totalArtistIs: "",
    reviewStatus: false,
    artistBusy: false,
    totalPages: 0,
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    sortBy: '-1',
    breakIs: false,
    artistDisable: false,
    resetPwdActive: false,
  };

  setArtistState = (stateObj) => {
    this.setState(stateObj);
  };

  handleShowAction = (index) => {
    const { showAction } = this.state;
    this.setState({
      showAction: !showAction,
      showActionIndex: index,
    });
  };

  handleHideAction = () => {
    this.setState({
      showAction: true,
    });
  };

  handleReviewStatus = (obj, pStatus) => {
    if (pStatus === "accepted") {
      this.setState({
        profileReviewStatus: 2,
        reviewStatus: true,
      });
    } else if (pStatus === "rejected") {
      this.setState({
        profileReviewStatus: 3,
        reviewStatus: false,
      });
    }
    this.setState({
      showReviewPopUp: true,
      artistId: obj._id,
    });
  };

  handleArtistPagination = () => {
    const { artistList, firstname, lastname, username, email, sortBy } = this.state;
    this.setState({
      ListLoading: true,
    });
    let artist = {
      page: artistList,
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
      sort: sortBy
    };
    this.props.$artistBusyList(artist);
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        const { artistList, firstname, lastname, username, email, sortBy } = this.state;
        let artist = {
          page: artistList,
          firstname: firstname,
          lastname: lastname,
          username: username,
          email: email,
          sort: sortBy
        };
        this.props.$artistBusyList(artist);
      }
    );
  };

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    const { firstname, lastname, username, email, sortBy } = this.state;
    let artist = {
      page: selectedData,
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
      sort: sortBy
    };
    this.props.$artistBusyList(artist);
  };

  componentDidMount() {
    this.setState({
      createArtistIs: true,
      artistActive: false,
      artistBusy: true,
    });
    this.handleArtistPagination();
    // this.props.handleClients.mainIndexstate({
    //   profileUpdates: false,
    //   artistBusyIs: true,
    // });
  }

  componentWillReceiveProps(nextProps) {
    const artistListBusy = nextProps._artistBusyList?.busyList;
    const artistIsFree = nextProps._artistBusyOrAvailable.artistAvailableIs;
    const disableArtistData = nextProps._disableEnableArtist.currentDisableIs;
    const tempResetPwdResponse = nextProps._tempResetPwd.tempResetPwd;
    if (artistListBusy) {
      this.setState({
        artistIs: artistListBusy.users,
        ListLoading: false,
        totalPages: artistListBusy.totalPages,
      });
    }
    if(artistIsFree && this.state.breakIs){
      this.setState({breakIs : false})
      this.handleArtistPagination();
    }
     if(disableArtistData && this.state.artistDisable){
      this.setState({artistDisable : false})
      this.handleArtistPagination();
     }
    if (tempResetPwdResponse.status === 1 && this.state.resetPwdActive) {
      alert(tempResetPwdResponse.message);
      this.setState({
        resetPwdActive: false,
      });
    }
  }

  handleArtistBusyOrNot = (data) => {
    this.setState({breakIs : true})
    this.props.$artistBusyOrAvailable(data)
  }

  handleDisabledEnableArtist = (data) =>{
    this.setState({artistDisable: true})
    this.props.$disableEnableArtist(data)
  }

  handleGetConfirmation(id) {
    let confirmIs = window.confirm(
      `Do you want to reset password temporary (for 15 min) ?`
    );
    if (confirmIs === true) {
      this.setState({
        resetPwdActive: true,
      });
      const tempResetPwd = { _id: id };
      this.props.$tempResetPwd(tempResetPwd);
      return true;
    } else {
      return false;
    }
  }

  handleResetTemproryPassword = (id) => {
    this.handleGetConfirmation(id);
  };

  render() {
    const { showReviewPopUp } = this.state;

    const artistFunc = {
      handleOnChange: this.handleOnChange,
      handleShowAction: this.handleShowAction,
      handleReviewStatus: this.handleReviewStatus,
      handleArtistBusyOrNot: this.handleArtistBusyOrNot,
      handleDisabledEnableArtist: this.handleDisabledEnableArtist,
      handleResetTemproryPassword: this.handleResetTemproryPassword,
    };

    return (
      <React.Fragment>
        {this.props._artistBusyList?.artistBusyListErrIs ? (
          <ErrorApi {...this.props} />
        ) : (
          <section className="client">
            <div className="container-fluid mx-auto">
              {/************Artist data Table ***************/}
              <ArtistListingTable
                {...this.state}
                {...this.props}
                handleArtist={artistFunc}
              />

              {/****************** Pagination ********************/}

              {this.state.totalPages > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </div>
          </section>
        )}
        {/*----------------------Review Popup -------------------------*/}
        {showReviewPopUp && (
          <ReviewStatusModal
            setArtistState={this.setArtistState}
            {...this.state}
          />
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({artistDataList, artistBusyList,artistBusyOrAvailable,disableEnableArtist,tempResetPwd }) => {
  return {
    _artistDataList: artistDataList,
    _artistBusyList: artistBusyList,
    _artistBusyOrAvailable: artistBusyOrAvailable,
    _disableEnableArtist: disableEnableArtist,
    _tempResetPwd: tempResetPwd,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $artistDataList: (values) => dispatchEvent(artistDataList(values)),
  $tempResetPwd: (values) => dispatchEvent(tempResetPwd(values)),
  $artistBusyList: (values) => dispatchEvent(artistBusyList(values)),
  $artistBusyOrAvailable: (values) => dispatchEvent(artistBusyOrAvailable(values)),
  $disableEnableArtist: (values) => dispatchEvent(disableEnableArtist(values)),
});
export default connect(
  mapState,
  mapDispatch
)(Busy);
