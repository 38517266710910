import React, { Component } from "react";
import Select from "react-select";
import { getPublicAPI } from "../../../../store/api-interface";
// import { userProfileBaseUrl } from "../../../../Utils/BaseUrl";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import { addMultipleCreator, transactionGetData } from "../../../../store/action/transaction.action";
import ReactPaginate from "react-paginate";
import  {WebsiteUrl}  from "../../../../Utils/BaseUrl"

class AddMultipleCreator extends Component {
  state = {
    email: "",
    options: [],
    selectedOption: null,
    selectedList: [],
    LoadingIs : false,
    allCreatorList : [],
    allCreators : [],
    LoaderIs : false,
    checked : false,
    totalPages : 0,
    limit : 15,
    currentPage : 1,
    arrFirstIndex : 0,
    arrLastIndex : 10,
    first_name : "",
    last_name:"",
    location : "",
    current_work_status : "",
    time_base_amount_month: "",
    total_project: "",
    language: "",
    industry: "",
    typeAll:"",
    styleAll: "",
    collOffer:"",
    collOfferOption:[],
    indOption: [],
    lanOption: [],
    typeOtion: [],
    styleOption: [],
    activebriefcollaterallength1 : "0", activeProject1 : "0",
    time_base_amount_month1: "0", total_project1: "0",
    activeBriefLength1: "0", totalAverageReview1: "0",
    collateralAverageReview1: "0", activeprojectcollaterallength1: "0",
    start_price: "0", completeProject1: "0", completeProjectColl1: "0",
    creatorCountIs: 0,
    transactionsStatus: "",
    collateralId: "",
    projectId: "",
    clientId:"",
    avgBriefResponseTime1:"0",
    avgBriefResponseTimeMonth1:"0",
    starArr:[],
    star:"",
    selectedCreator:[]
  };

  getSearchRes = () => {
    const { allCreators, limit, location, current_work_status, first_name, last_name, language, industry, typeAll, styleAll,
      activebriefcollaterallength1, activebriefcollaterallength2, activeProject1, activeProject2,starArr,
      time_base_amount_month1, time_base_amount_month2, total_project1, total_project2, activeBriefLength1,collOffer,
      activeBriefLength2, totalAverageReview1, totalAverageReview2, collateralAverageReview1,avgBriefResponseTimeMonth1,avgBriefResponseTimeMonth2,
      collateralAverageReview2, activeprojectcollaterallength1,start_price,end_price,avgBriefResponseTime1,avgBriefResponseTime2,
      activeprojectcollaterallength2, completeProject1, completeProject2,completeProjectColl1,completeProjectColl2,star
    } = this.state;

    if(location || current_work_status || first_name || last_name || language || industry || typeAll || styleAll ||
      activebriefcollaterallength1 || activebriefcollaterallength2 || activeProject1 || activeProject2 || starArr ||
      time_base_amount_month1 || time_base_amount_month2 || start_price || end_price || total_project1 || avgBriefResponseTimeMonth1 | avgBriefResponseTimeMonth2 ||
      total_project2 || activeBriefLength1 || collOffer || activeBriefLength2 || totalAverageReview1 || avgBriefResponseTime1 || avgBriefResponseTime2 ||
      totalAverageReview2 || collateralAverageReview1 || collateralAverageReview2 || activeprojectcollaterallength1 ||
      completeProjectColl1 || completeProjectColl2 || activeprojectcollaterallength2 || completeProject1 || completeProject2){
      
      let fn = allCreators?.filter(el => el?.first_name?.toLowerCase().startsWith(first_name?.toLowerCase()))
      let ln = fn?.filter(el => el?.last_name?.toLowerCase().startsWith(last_name?.toLowerCase()))  
      let cws = current_work_status?.value ? ln?.filter(el => el?.current_work_status?.toLowerCase().startsWith(current_work_status?.value?.toLowerCase()))  : ln
      let loc = cws?.filter(el => el?.location?.toLowerCase().startsWith(location?.toLowerCase())) 

      let tbam = time_base_amount_month1 && time_base_amount_month2 ? loc?.filter(el => el.time_base_amount_month >= Number(time_base_amount_month1) && el.time_base_amount_month <= Number(time_base_amount_month2)) : loc
      
      let spep = start_price && end_price ? tbam?.filter(el => !(Number(end_price) < el.start_price) && !(Number(start_price) > el.end_price)) : tbam;

      let tp = total_project1 && total_project2 ? spep?.filter(el => el.total_project >= Number(total_project1) && el.total_project <= Number(total_project2)) : spep

      let ab = activeBriefLength1 && activeBriefLength2 ? tp?.filter(el => el.activeBriefLength >= Number(activeBriefLength1) && el.activeBriefLength <= Number(activeBriefLength2)) : tp

      let abc = activebriefcollaterallength1 && activebriefcollaterallength2 ? ab?.filter(el => el.activebriefcollaterallength >= Number(activebriefcollaterallength1) && el.activebriefcollaterallength <= Number(activebriefcollaterallength2)) : ab

      let ap = activeProject1 && activeProject2 ? abc?.filter(el => el.activeProject >= Number(activeProject1) && el.activeProject <= Number(activeProject2)) : abc

      let apc = activeprojectcollaterallength1 && activeprojectcollaterallength2 ? ap?.filter(el => el.activeprojectcollaterallength >= Number(activeprojectcollaterallength1) && el.activeprojectcollaterallength <= Number(activeprojectcollaterallength2)) : ap

      let cpo = completeProject1 && completeProject2 ? apc?.filter(el => el.completeProject >= Number(completeProject1) && el.completeProject <= Number(completeProject2)) : apc

      let cpc = completeProjectColl1 && completeProjectColl2 ? cpo?.filter(el => el.completeProjectusercollateral >= Number(completeProjectColl1) && el.completeProjectusercollateral <= Number(completeProjectColl2)) : cpo

      let aro = totalAverageReview1 && totalAverageReview2 ? cpc?.filter(el => el.totalAverageReview >= Number(totalAverageReview1) && el.totalAverageReview <= Number(totalAverageReview2)) : cpc 

      let arc = collateralAverageReview1 && collateralAverageReview2 ? aro?.filter(el => el.collateralAverageReview >= Number(collateralAverageReview1) && el.collateralAverageReview <= Number(collateralAverageReview2)) : aro 
  
      let abrt = avgBriefResponseTime1 && avgBriefResponseTime2  ? arc?.filter(el => el.avg_brief_response_time >= Number(avgBriefResponseTime1) && el.avg_brief_response_time <= Number(avgBriefResponseTime2)) : arc 
      
      let abrtm = avgBriefResponseTimeMonth1 && avgBriefResponseTimeMonth2  ? abrt?.filter(el => el.avg_brief_response_timev2 >= Number(avgBriefResponseTimeMonth1) && el.avg_brief_response_timev2 <= Number(avgBriefResponseTimeMonth2)) : abrt 

      let co = collOffer?.value ? abrtm?.filter(el => el?.userCollateralOffer?.includes(collOffer.value)) : abrtm

      let lang = language?.value ? co?.filter(el => el?.uniqueL?.includes(language.value)) : co

      let indust = industry?.value ? lang?.filter(el => el?.uniqueI?.includes(industry.value)) : lang

      let tyAll = typeAll?.value ? indust?.filter(el => el?.typArr?.includes(typeAll.value)) : indust

      let styAll = styleAll?.value ? tyAll?.filter(el => el?.styArr?.includes(styleAll.value)) : tyAll

      let StarCheck = star?.value ? styAll?.filter(el => el?.userCollateralStar == star.value) : styAll

      // console.log("StarCheck = ",StarCheck,star.value)

      this.setState({ allCreatorList: StarCheck,totalPages: Math.ceil(StarCheck?.length / limit),  currentPage : 1,arrFirstIndex : 0,arrLastIndex : 10, })
    } else {
      this.setState({ allCreatorList : allCreators,totalPages: Math.ceil(allCreators?.length / limit),  currentPage : 1, arrFirstIndex : 0, arrLastIndex : 10,  })
    }
  }

  handleSearch = (e) => {
    const { name, value } = e.target;
    this.setState({ [name] : value },() =>{
      this.getSearchRes()
    })       
  }

  handleSelect = (selectedOption,name) => {
    // console.log("selectedOption = ", selectedOption, name)
    this.setState({ [name]: selectedOption }, () => {
      this.getSearchRes()
    })
  };

  handlePageClick = (data) => {
    const { limit } = this.state;
    const selectedData = data.selected + 1;
    const LastIndex = selectedData * limit;
    const firstIndex = LastIndex - limit;
    this.setState(
      {
        currentPage: selectedData,
        arrFirstIndex: firstIndex,
        arrLastIndex: LastIndex,
      }
    );
  };

  // handleCheckbox = (e, obj, index) => {
  //   const { allCreatorList } = this.state;
  //     this.setState({ allCreatorList : allCreatorList.map((item) =>{
  //          if(item?._id === obj?._id){
  //            item.selected  = !item.selected
  //          }
  //          return item
  //     }) })
  // }
  handleCheckbox = (e, obj, index) => {
    const { allCreatorList,selectedCreator } = this.state;
    let tempSelectedCreator = [...selectedCreator]

    if(e.target.checked){
      tempSelectedCreator.push(obj)
    }
    else{
      tempSelectedCreator = tempSelectedCreator.filter(item=>item?._id!==obj?._id)
    }
      this.setState({ allCreatorList : allCreatorList.map((item) =>{
           if(item?._id === obj?._id){
             item.selected  = !item.selected
           }
           return item
      }),selectedCreator:tempSelectedCreator })
      
  }



  handleCreatorSubmit = () => {
    const { allCreatorList,creatorCountIs,projectId, clientId,selectedCreator } = this.state;
    const isSelected = selectedCreator
    const validCount = this.state.limit
    const countIs = validCount - creatorCountIs 
    if(isSelected?.length > 0){
      if (countIs >= (isSelected.length)) {
        let arr = []
        isSelected.forEach(item => {
          arr.push({_id:item._id})
        })
        const data = { client_id: clientId, post_project_id: projectId, user_ids: arr };
        if(data){
          this.setState({LoadingIs : true})
          this.props.$addMultipleCreator(data)
          this.setState({selectedList: [],selectedCreator:[]})
        }
      }else{
        alert(`you can choose only ${countIs} creator`)
      }   
    }else{
      alert("please choose atleast 1 creator")
    }
  };

  handleCreatorList = () => {
    // const { collateralId } = this.props;
    const { limit } = this.state;
    const transactionId = this.props.match.params.id.split(":").pop();
    this.props.$transactionGetData(transactionId,{admin:false});
    if (transactionId) {
      this.setState({ LoaderIs : true })
      getPublicAPI(
        `/postProject/get-creators-for-project?post_project_id=${transactionId}`
      ).then((res) => {
        const response = res.data;
        if (response) {
          const allCreatorList = response?.allcreatordata?.map((obj) => {
            let uniqueS = [], uniqueT = [];
            
            let tArr = [...obj?.userCollateralType,...obj?.projectType]
            tArr.forEach(ele => {
              // uniqueT.push(...ele?.answer)
              ele?.answer?.length > 0 &&
                ele.answer.forEach(elem => {
                  uniqueT.push(elem?.item)
                })
            })
            let sArr = [...obj?.userCollateralStyle,...obj?.projectStyle]
            sArr.forEach(ele => {
              // uniqueS.push(...ele?.answer)
               ele?.answer?.length > 0 &&
                ele.answer.forEach(elem => {
                  uniqueS.push(elem?.item)
                })
            })
            let typArr = [...new Set(uniqueT)];
            let styArr = [...new Set(uniqueS)];

            let lang = []
            let languagess = obj?.languages ? obj.languages : []
            let lang2 = obj?.allProjectLanguage ? obj.allProjectLanguage : []
            lang2.forEach(item => {
                item?.languages?.length > 0 && item.languages.forEach(item => {
                  lang.push(item?.name)
                })
            })
            let arrL = [...languagess,...lang] 
            let uniqueL = [...new Set(arrL)];

            let ind = []
            let industrys = obj?.industry ? obj.industry : []
            let ind2 = obj?.allProjectIndustry ? obj.allProjectIndustry : []
            ind2.forEach(item => {
              ind.push(item?.industry)
            })
            let arrI = [...industrys,...ind] 
            let uniqueI = [...new Set(arrI)];

            let data = {
              ...obj,styArr,typArr,uniqueL,uniqueI,
              selected : false
            };
            return data;
          });

          let allStyle = [{label:"All",value:""}];
          let allType = [{label:"All",value:""}];
          let allLanguage = [{label:"All",value:""}];
          let allIndustry = [{ label: "All", value: "" }];
          let allCollaterals = [{label:"All",value:""}];

          response.type.forEach(ele => {
            ele?.option?.length > 0 && ele.option.forEach(obj => {
              allType.push({...obj,label:obj.item,value:obj.item})
            })
          })

          response.style.forEach(ele => {
            ele?.option?.length > 0 && ele.option.forEach(obj => {
              allStyle.push({...obj,label:obj.item,value:obj.item})
            })
          })

          response?.language?.length > 0 && response.language.forEach(ele => {
              allLanguage.push({...ele,label:ele.name,value:ele.name})
           })
          
          response?.industry?.length > 0 && response.industry.forEach(ele => {
              allIndustry.push({...ele,label:ele.name,value:ele.name})
          })

          response?.allCollateral?.length > 0 && response.allCollateral.forEach(ele => {
              allCollaterals.push({label:ele,value:ele})
          })

          // console.log("123 = > ",allType,allStyle,allLanguage,allIndustry)
          this.setState({ 
            allCreatorList, LoaderIs : false ,
            allCreators : allCreatorList,
            totalPages: Math.ceil(allCreatorList?.length / limit),
            lanOption: allLanguage,
            indOption: allIndustry,
            typeOtion: allType,
            styleOption: allStyle,
            starArr:[{label:"Star",value:1},{label:"Non-Star",value:0}],
            collOfferOption:allCollaterals
          }, () => {
            this.getSearchRes()
          })
        }
      });
    }
  };

  renderDropdown = (title, listArr) => {
    return (
      <div className="dropdown">
        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {title}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{maxHeight: 300, overflowY: 'auto'}}>
          {listArr.map((value, idx) => {
            return (
              <span key={idx} className="dropdown-item" >{value}</span>    
            )
          })}
        </div>
      </div>
    )
  }

  renderCollOffer = (unique) => {
    return (
      unique?.length > 0 ?
        this.renderDropdown('See Colleteral Offered', unique)
        : <p>None</p>
    )
  }

  renderLanguages = (unique) => {
    return (
      unique?.length > 0 ?
        this.renderDropdown('See languages', unique)
        : <p>None</p>
    )
  }

  renderindustry = (unique) => {
    return (
      unique?.length > 0 ?
        this.renderDropdown('See industries', unique)
        : <p>None</p>
    )
  }


  renderTypeStyle = (unique,check) => {
    let name = check === "T" ? "See type" : "See style"
    return (
      unique?.length > 0 ?
        this.renderDropdownStyleType(name, unique)
        : <p>None</p>
    )
  }

  renderDropdownStyleType = (title, listArr) => {
    return (
      <div className="dropdown">
        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {title}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{maxHeight: 300, overflowY: 'auto'}}>
          {listArr?.map((value, idx) => {
            return (
              <span key={idx} className="dropdown-item" >{value}</span>    
            )
          })}
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.handleCreatorList();
  }

  getCreatorResponse = (creatorIs , LoadingIs) =>{
    if(creatorIs.status === "1" && LoadingIs){
      alert("Creator added succesfully.")
      this.setState({ LoadingIs: false }, () => {
        const transactionId = this.props.match.params.id.split(":").pop();
        this.props.history.push(`/transactions/transactionUserDetails/:${transactionId}`)
      })
    }
  }

  componentWillReceiveProps(nextProps){
    const creatorIs = nextProps._addMultipleCreator.allCreatorIs;
    const postProjectData = nextProps._transactionGetData.transactionData;
    const{ LoadingIs } = this.state;
    LoadingIs && this.getCreatorResponse(creatorIs, LoadingIs)
    if (postProjectData && postProjectData.project_data) {
      this.setState({
        creatorCountIs: postProjectData?.creatorCount,
        transactionsStatus: postProjectData?.project_data.status,
        collateralId: postProjectData?.collateral?._id,
        projectId: postProjectData?.project_data?._id,
        clientId: postProjectData?.projectClientData?._id,
      });
    }
  }

  getDateTime = (end, start,i) => {
    let result = ""
    var diff = ""
    if (!end || !start) {
      result = "No Brief"
    } else {
      let createAt = new Date()
      let approveAt = new Date(start)
      // console.log("2 = ",createAt,approveAt)
      diff = (createAt.getTime() - approveAt.getTime()) / 1000;
      diff /= (60 * 60);
      if (diff < 0) {
        result = "No Brief"
      } else{
        let rest = Math.abs(Math.round(diff))
        let res = Math.round(rest / 24)
        if (res == 0) {
          result = "today"
        } else if (res > 0 && res <= 1) {
          result = res + " day"
        } else {
          result = res + " days"
        }
      }
    }
    return result;
  }

  render() {
    const { LoaderIs, allCreatorList, arrFirstIndex, arrLastIndex, LoadingIs, first_name, location,activebriefcollaterallength1,activebriefcollaterallength2,activeProject1,activeProject2,
      current_work_status, last_name, time_base_amount_month1, time_base_amount_month2, total_project1, total_project2, activeBriefLength1, activeBriefLength2, totalAverageReview1,
      totalAverageReview2, collateralAverageReview1, collateralAverageReview2, activeprojectcollaterallength1, start_price, end_price,avgBriefResponseTimeMonth1,avgBriefResponseTimeMonth2,
      activeprojectcollaterallength2, completeProject1, completeProject2, language, industry, typeAll, styleAll, lanOption, indOption, typeOtion, styleOption,starArr,star,
      completeProjectColl1,completeProjectColl2,collOfferOption,collOffer,transactionsStatus,collateralId,creatorCountIs,avgBriefResponseTime1,avgBriefResponseTime2} = this.state;
    
    const searchArr = [
      {
        type: "text",
        name: "first_name",
        value: first_name,
        placeholder: "Search firstName",
      },
      {
        type: "text",
        name: "last_name",
        value: last_name,
        placeholder: "Search lastName",
      },
      {
        type: "text",
        name: "location",
        value: location,
        placeholder: "Search city",
      },
    ];

    const rangeFilter = [
      {
        type: "text",
        name1: "time_base_amount_month1",
        name2: "time_base_amount_month2",
        value1: time_base_amount_month1,
        value2: time_base_amount_month2,
        placeholder: "Search pricing/monthly",
      },
       {
        type: "text",
        name1: "start_price",
        name2: "end_price",
        value1: start_price,
        value2: end_price,
        placeholder: "Pricing/collateral-wise",
      },
       {
          type: "text",
          name1: "total_project1",
          name2: "total_project2",
          value1: total_project1,
          value2: total_project2,
          placeholder: "Uploaded project (collateral)",
      },
        {
          type: "text",
          name1: "activeBriefLength1",
          name2: "activeBriefLength2",
          value1: activeBriefLength1,
          value2: activeBriefLength2,
          placeholder: "Active brief (overall)",
      },
        {
          type: "text",
          name1: "activebriefcollaterallength1",
          name2: "activebriefcollaterallength2",
          value1: activebriefcollaterallength1,
          value2: activebriefcollaterallength2,
          placeholder: "Active brief (collateral)",
      },
        {
          type: "text",
          name1: "activeProject1",
          name2: "activeProject2",
          value1: activeProject1,
          value2: activeProject2,
          placeholder: "Active project (overall)",
      },
        {
          type: "text",
          name1: "activeprojectcollaterallength1",
          name2: "activeprojectcollaterallength2",
          value1: activeprojectcollaterallength1,
          value2: activeprojectcollaterallength2,
          placeholder: "Active project (collateral)",
      },
        {
          type: "text",
          name1: "completeProject1",
          name2: "completeProject2",
          value1: completeProject1,
          value2: completeProject2,
          placeholder: "completed project (overall)",
      },
        {
          type: "text",
          name1: "completeProjectColl1",
          name2: "completeProjectColl2",
          value1: completeProjectColl1,
          value2: completeProjectColl2,
          placeholder: "completed project (collateral)",
      },
        {
          type: "text",
          name1: "totalAverageReview1",
          name2: "totalAverageReview2",
          value1: totalAverageReview1,
          value2: totalAverageReview2,
          placeholder: "Average review (overall)",
      },
        {
          type: "text",
          name1: "collateralAverageReview1",
          name2: "collateralAverageReview2",
          value1: collateralAverageReview1,
          value2: collateralAverageReview2,
          placeholder: "Average review (collateral)",
      },
      {
        type: "text",
        name1: "avgBriefResponseTime1",
        name2: "avgBriefResponseTime2",
        value1: avgBriefResponseTime1,
        value2: avgBriefResponseTime2,
        placeholder: "Avg brief Response Time (Overall)",
    },  
     {
      type: "text",
      name1: "avgBriefResponseTimeMonth1",
      name2: "avgBriefResponseTimeMonth2",
      value1: avgBriefResponseTimeMonth1,
      value2: avgBriefResponseTimeMonth2,
      placeholder: "Avg brief Response Time (3 Months)",
    },
    ]

    const searchFilter = [
       {
        name: "current_work_status",
        value: current_work_status,
        placeholder: "Search part-time/full-time",
        options:[{label:"All",value:""},{label:"Full-Time Freelancer",value:"Full-Time Freelancer"},{label:"Part-Time Freelancer",value:"Part-Time Freelancer"}]
      },
       {
        name: "collOffer",
        value: collOffer,
        placeholder: "collateral offered",
        options:collOfferOption
      },
      {
        name: "language",
        value: language,
        placeholder: "Language",
        options:lanOption
      },
      {
        name: "industry",
        value: industry,
        placeholder: "Industry",
        options:indOption
      },
      {
        name: "typeAll",
        value: typeAll,
        placeholder: "Type",
        options:typeOtion
      },
      {
        name: "styleAll",
        value: styleAll,
        placeholder: "Style",
        options:styleOption
      },
      {
        name: "star",
        value: star,
        placeholder: "Select Star",
        options:starArr
      },
    ];

    return (
      <React.Fragment>
        <div className="row">  
          <div className="col">
            {allCreatorList?.length > 0 && (transactionsStatus === 5 || transactionsStatus === 3) &&
              collateralId &&
              <div className="mr-auto d-flex justify-content-end">
                {!(creatorCountIs > this.state.limit-1) ?
                  <button
                    className="btn btn-primary"
                    onClick={this.handleCreatorSubmit}
                  >
                    {LoadingIs ? "Saving.." : "Submit"}
                  </button> :
                  <p style={{fontWeight:"bold",color:"red"}}>{this.state.limit} Creators Already Added</p>
                }
          </div>}
          </div>   
           {/*------------------Search ---------------------*/}
            <div className="row col-md-12 mb-2">
              <div className="col-12" style={{borderBottom:"1px solid #6e84a3"}}>
                <form className="row align-items-center mt-2 mb-2">
                  <div className="col-auto pr-0 mt-2">
                    <span className="fe fe-search text-muted"></span>
                  </div>
                  {searchArr?.map((obj, index) => {
                      return (
                        <div className="col-3 mb-2" key={index}>
                          <input
                            type={obj.type}
                            className="form-control form-control-flush search"
                            autoComplete="off"
                            placeholder={obj.placeholder}
                            name={obj.name}
                            value={obj.value}
                            onChange={this.handleSearch}
                          />
                        </div>
                      );
                  })}
                </form>
            </div>
              <div className="col-12 mb-2" style={{borderBottom:"1px solid #6e84a3"}}>
               <form className="row align-items-center mt-2 mb-2">
                    {rangeFilter?.map((obj, index) => {
                      return (
                        <div className="col-3 mt-2" key={index}>
                          <label style={{ color: "#95aac9" }}>{obj.placeholder}</label>
                          <div style={{display:"flex",flexDirection:"row", border:"1px solid #95aac9",padding:"4px",borderRadius:"5px"}}>
                              <input
                                type={obj.type}
                                className="form-control search"
                                style={{border:"1px solid #95aac9",borderRadius:"5px"}}
                                autoComplete="off"
                                placeholder="min"
                                name={obj.name1}
                                value={obj.value1}
                                onChange={this.handleSearch}
                              />
                              <input
                              type={obj.type}
                                style={{border:"1px solid #95aac9",borderRadius:"5px",marginLeft:"3px"}}
                                className="form-control search"
                                autoComplete="off"
                                placeholder="max"
                                name={obj.name2}
                                value={obj.value2}
                                onChange={this.handleSearch}
                             />
                          </div>
                        </div>
                      );
                    })}
                </form>
            </div>
            <div className="col-12 mb-2" style={{borderBottom:"1px solid #6e84a3"}}>
               <form className="row align-items-center mt-2 mb-2">
                    {searchFilter?.map((obj, index) => {
                      return (
                        <div className="col-2 mt-2" key={index}>
                          <Select
                            options={obj.options}
                            onChange={(e) => this.handleSelect(e,obj.name)}
                            placeholder={obj.placeholder}
                            value={obj.value}
                          />
                        </div>
                      );
                    })}
                </form>
            </div>
            </div>
        {/*------------------Search ---------------------*/}                       
        </div>        
          <section className="creative-field creative_wrapper">
            <div className="container-custom mx-auto">
              {
              LoaderIs ? 
              <div className="col-md-12">
                <div className="d-flex justify-content-center align-items-center">
                  <h3>Loading......</h3>
                </div>
              </div>:
              <div className="table-responsive mb-5">
                <table className="table table-sm table-nowrap card-table">
                  <thead>
                    <tr>
                      <th>
                        Action
                      </th>                    
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        first Name
                      </a>
                    </th>  
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        last Name
                      </a>
                    </th>  
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        City
                      </a>
                    </th> 
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        Part-time/full-time
                      </a>
                    </th>
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        Pricing/monthly
                      </a>
                    </th>
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        Pricing/collateral-wise
                      </a>
                    </th>
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        Uploaded projects (collateral)
                      </a>
                    </th>                           
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        active brief (overall)
                      </a>
                    </th>  
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        active brief (collateral)
                      </a>
                    </th>    
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        active project (overall)
                      </a>
                    </th> 
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        active project (collateral)
                      </a>
                    </th> 
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        completed project (overall)
                      </a>
                    </th>
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        completed project (collateral)
                      </a>
                    </th>
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        Average review (overall)
                      </a>
                    </th>
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        Average review (collateral)
                      </a>
                        </th>
                         <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        Avg Brief Response Time (Overall)
                      </a>
                        </th>
                             <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        Avg Brief Response Time (3 Months)
                      </a>
                        </th>
                         {/* <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        Time Since Last Brief (In Days)
                      </a>
                    </th>
                     <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        Time to 1st Proposal V1 & V2
                      </a>
                        </th> */}
                         <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        Time Since Last Brief
                      </a>
                    </th>
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        Collateral offered
                      </a>
                    </th>  
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        language
                      </a>
                    </th> 
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        industry
                      </a>
                    </th> 
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        type
                      </a>
                    </th> 
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        style
                      </a>
                    </th> 
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                      >
                        project
                      </a>
                    </th> 
                  </tr>
                  </thead>
                  <tbody className="list">
                    {allCreatorList?.slice(arrFirstIndex, arrLastIndex)
                    .map((obj, index) => (
                      <tr key={index}>
                        <td><input type="checkbox" name ={`checkbox${index}`} checked={obj?.selected} onChange={(e) => this.handleCheckbox(e, obj, index)}/></td>
                        <td className="orders-order">   
                          <a href={`${WebsiteUrl}creator/${obj?.username}`} target="_blank" className="text-muted">
                          <span style={{color:"#2c7be5"}}>{ obj?.first_name }</span>
                          </a>
                        </td>
                        <td className="orders-order">{ obj?.last_name }</td>
                        <td className="orders-order">{ obj?.location }</td>    
                        <td className="orders-order">{ obj?.current_work_status }</td>    
                        <td className="orders-order">{ obj?.time_base_amount_month ? obj?.time_base_amount_month : "NA" }</td>    
                        <td className="orders-order">{ obj?.start_price + "-" + obj?.end_price}</td>    
                        <td className="orders-order">{ obj?.total_project }</td> 
                        <td className="orders-order">{ obj?.activeBriefLength }</td>    
                        <td className="orders-order">{ obj?.activebriefcollaterallength }</td>    
                        <td className="orders-order">{ obj?.activeProject }</td> 
                        <td className="orders-order">{ obj?.activeprojectcollaterallength }</td> 
                        <td className="orders-order">{obj?.completeProject}</td> 
                        <td className="orders-order">{obj?.completeProjectusercollateral}</td> 
                        <td className="orders-order">{obj?.totalAverageReview ? obj?.totalAverageReview.toFixed(2) : "No Review"}</td> 
                        <td className="orders-order">{obj?.collateralAverageReview ? obj?.collateralAverageReview.toFixed(2) : "No Review"}</td>
                        <td className="orders-order">{obj?.avg_brief_response_time ? obj?.avg_brief_response_time + " min" : "0 min"}</td> 
                        <td className="orders-order">{obj?.avg_brief_response_timev2 ? obj?.avg_brief_response_timev2 + " min" : "0 min"}</td> 
                        <td className="orders-order">{this.getDateTime(new Date(), obj?.creatorLastBrief?.project_brief_approved_date, index)}</td>  
                        {/* <td className="orders-order">-</td>  
                        <td className="orders-order">-</td> */}
                        <td className="orders-method">{this.renderCollOffer(obj?.userCollateralOffer)}</td>
                        <td className="orders-method">{this.renderLanguages(obj?.uniqueL)}</td>
                        <td className="orders-method">{this.renderindustry(obj?.uniqueI)}</td>
                        <td className="orders-method">{this.renderTypeStyle(obj?.typArr,"T")}</td>
                        <td className="orders-method">{this.renderTypeStyle(obj?.styArr ,"S")}</td>
                        <td className="orders-method">
                          <a href={`${WebsiteUrl}creator/${obj?.username}`} target="_blank" className="text-muted">
                            <i className="fe fe-eye"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              }
              {/**** Pagination****** */}

              { (this.state.totalPages > 0) && 
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />}
              
            </div>
        </section>  
      </React.Fragment>
    );
  }
}
const mapState = ({ addMultipleCreator, transactionGetData }) => {
  return {
    _addMultipleCreator: addMultipleCreator,
    _transactionGetData: transactionGetData
  };
};
const mapDispatch = (dispatchEvent) => ({
  $addMultipleCreator: (values) => dispatchEvent(addMultipleCreator(values)),
  $transactionGetData: (values) => dispatchEvent(transactionGetData(values)),
});
export default connect(mapState, mapDispatch)(AddMultipleCreator);
