import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { projectList } from "../../../store/action/projectList.action";
import ReactPaginate from "react-paginate";
import Loader from "../Common/Loader/Loader";

class Project extends Component {
  state = {
    projectActive: false,
    projectList: 1,
    projectData: [],
    LoadingIs: false,
    totalPages: 0,
  };

  handlePageClick = (data) => {
    let selectedPage = data.selected + 1;
    this.props.$projectList(selectedPage);
  };

  handleProjectDetails = () => {
    this.setState({
      LoadingIs: true,
    });
    const { projectList } = this.state;
    this.props.$projectList(projectList);
  };

  componentDidMount() {
    // this.handleProjectDetails();
    this.setState({
      projectActive: true,
    });
    this.props.handleClients.handlepathName("/projects");
  }

  componentWillReceiveProps(nextProps) {
    const projectData = nextProps._projectList.projectDetails;
    if (projectData) {
      this.setState({
        projectData: projectData.CollateralS,
        LoadingIs: false,
        totalPages: projectData.totalPages,
      });
    }
  }

  render() {

    const { LoadingIs } = this.state;

    const contentTableArr = [
      { name: "Project Name" },
      { name: "Name of Atist" },
      { name: "collateral" },
      { name: "Softwares" },
      { name: "About" },
      { name: "Review Rating" },
      { name: "Company Name" },
      { name: "created for" },
      { name: "Completion Date" },
      { name: "Status" },
      { name: "Action" },
    ];

    return (
      <React.Fragment>
        <section className="client mt-5">
          <div className="container-fluid mx-auto">
            {LoadingIs ? (
              <Loader />
            ) : (
              <div>
                <div className="table-responsive mb-5">
                  <table className="table table-sm table-nowrap card-table">
                    {/*----------------table head -----------------------------*/}
                    <thead>
                      <tr>
                        {contentTableArr.map((obj, index) => (
                          <th key={index}>
                            <Link to="#!" className="text-muted sort">
                              {obj.name}
                            </Link>
                          </th>
                        ))}                        
                      </tr>
                    </thead>
                    {/*----------------table head -----------------------------*/}
                    <tbody className="list">
                      {/* {projectData.map((obj, index) => ( */}
                        <tr >
                          <td className="orders-order">test</td>
                          <td className="orders-product">Alternative name</td>
                          <td className="orders-date">Parent cs</td>
                          <td className="orders-total">Artist</td>
                          <td className="orders-method">Project</td>
                          <td className="orders-method">Transactions</td>
                          <td className="orders-method">Negociations</td>
                          <td className="orders-method">
                            Avg client/ Artist rating
                          </td>
                          <td className="orders-method">Total Transactions</td>
                          <td className="orders-status">
                            <div className="badge badge-soft-success">
                              Shipped
                            </div>
                          </td>
                          <td className="orders-method">
                            {/* <Link to="#!">
                              <button className="btn btn-outline-info btn-sm mr-2">
                                <i className="fe fe-edit"></i>
                              </button>
                            </Link>
                            <Link to="#!">
                              <button className="btn btn-outline-danger btn-sm">
                                <i className="fe fe-trash"></i>
                              </button>
                            </Link> */}
                          </td>
                        </tr>
                      {/* ))} */}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {/******** pagination ************* */}

            {this.state.totalPages > 0 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapState = ({ projectList }) => {
  return {
    _projectList: projectList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $projectList: (values) => dispatchEvent(projectList(values)),
});
export default connect(
  mapState,
  mapDispatch
)(Project);
