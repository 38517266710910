import React,{ Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { milesList } from '../../../../store/action/Milestone.action';

class MilestoneHeaderTab extends Component{
    render(){
        const{pathIs, location, _milesList} = this.props;
        const artistId = location.pathname.split("/:").pop();
        const tabArr = [
            {
              name: "All AM Transactions",
              link: "/milestones",
              activeBorder: "/milestones",
              counting: _milesList.milesListIs.totalUsers,
            }
          ];
          const artistCurrentPath = (path) => {
            return  path;
          };
        return(
            <React.Fragment>
               {pathIs === "Milestones" && artistId === "/milestones" && 
               <ul className="nav nav-tabs nav-overflow header-tabs">
              { tabArr?.map((obj, index) => (
                <li className="nav-item" key={index}>
                  <Link
                    to={obj.link}
                    className={`nav-link ${
                      artistCurrentPath(`${obj.activeBorder}`) ? "active" : ""
                    }`}
                  >
                    {obj.name}
                    <span className="badge badge-pill badge-soft-secondary ml-2">
                      {obj.counting}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>}
            </React.Fragment>
        )
    }
}
const mapState = ({ milesList }) => {
    return {
      _milesList: milesList,
    };
  };
  const mapDispatch = (dispatchEvent) => ({
    $milesList: (values) => dispatchEvent(milesList(values)),  
  });
  export default connect(
    mapState,
    mapDispatch
  )(MilestoneHeaderTab);