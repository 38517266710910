import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../../Common/Loader/Loader";
import { connect } from "react-redux";
import { inCompleteBriefList } from "../../../../store/action/transaction.action";
import ReactPaginate from "react-paginate";
import ErrorApi from "../../Common/Error/ErrorApi";
import moment from "moment";

class InCompleteBrief extends Component {
  state = {
    LoadingIs: false,
    currentPage: 1,
    sort: -1,
    totalPage: 0,
    BriefDataIs: [],
    sortFlag : false
  };

  handleSorting = () => {
    const{ sortFlag } = this.state;
    this.setState({ sortFlag : !sortFlag},()=>{
      let currentData = null;
      const { currentPage } = this.state;
      if(sortFlag){
        currentData = { page: currentPage, sortIs: 1 }
      }else{
        currentData = { page: currentPage, sortIs: -1 }
      }
      this.handleIncompleteBrief(currentData);
    })
  };

  handlePageClick = (data) => {
    let currentSelected = data.selected + 1;
    const { sort } = this.state;
    const currentData = { page: currentSelected, sortIs: sort };
    this.handleIncompleteBrief(currentData);
  };

  handleIncompleteBrief = (currentData) => {
    this.setState({
      LoadingIs: true,
    });
    this.props.$inCompleteBriefList(currentData);
  };

  componentDidMount() {
    const { currentPage, sort } = this.state;
    const currentData = { page: currentPage, sortIs: sort };
    this.handleIncompleteBrief(currentData);
  }

  componentWillReceiveProps(nextProps) {
    const ListIs = nextProps._inCompleteBriefList.inCompleteBriefIs;
    if (ListIs) {
      this.setState({
        BriefDataIs: ListIs.postProject ? ListIs.postProject : "",
        totalPage: ListIs.totalPages ? ListIs.totalPages : 0,
        LoadingIs: false,
      });
    }
  }

  render() {
    const otherDbsArr = [
      { name: "Action" },
      { name: "collateral name" },
      { name: "client name" },
      { name: "client email" },
      { name: "client phone number" },
      { name: "created Date" },
      { name: "Stage" },      
    ];

    const { LoadingIs, BriefDataIs } = this.state;
    return (
      <section>
        {this.props._inCompleteBriefList.incompleteBriefListErrIs ? (
          <ErrorApi {...this.props} />
        ) : (
          <React.Fragment>
            <section className="client mt-5">
              <div className="container-fluid mx-auto">
                {LoadingIs ? (
                  <Loader />
                ) : (
                  <div className="table-responsive">
                    <table className="table table-sm table-nowrap card-table">
                      {/*----------------- table head --------------------*/}
                      <thead>
                        <tr>
                          {otherDbsArr.map((obj, index) => (
                            <React.Fragment key={index}>
                              {obj.name !== "created Date" && (
                                <th >
                                  <Link to="#!" className="text-muted">
                                    {obj.name}
                                  </Link>
                                </th>
                              )}
                              {obj.name === "created Date" && (
                                <th key={index}>
                                  <Link
                                    to="#!"
                                    className="text-muted sort"
                                    data-sort="tables-row"
                                    onClick={this.handleSorting}
                                  >
                                    {obj.name}
                                  </Link>
                                </th>
                              )}
                            </React.Fragment>
                          ))}
                        </tr>
                      </thead>
                      {/*----------------- table head --------------------*/}
                      <tbody className="list">
                        {BriefDataIs.length > 0 ? (
                          BriefDataIs.map((obj, index) => (
                            <tr key={index}>
                              <td className="text-method">
                                <Link to={`/transactions/transactionsDetails/:${obj._id}`}>
                                  <button className="btn btn-outline-success btn-sm mr-2">
                                    <i className="fe fe-eye"></i>
                                  </button>
                                </Link>
                              </td>
                              <td className="orders-order">
                                {`${obj.collateral.name}`}
                              </td>
                              <td className="orders-product">
                                { obj.client ? obj.client.first_name : ""}
                                {" "}
                                  { obj.client ? obj.client.last_name : ""}
                              </td>
                              <td className="orders-date">
                                {obj.client ? obj.client.email : ""}
                              </td>
                              <td className="orders-total">
                                {obj.client ? obj.client.phone_no :''}
                              </td>
                              <td className="orders-total">
                                {obj?.createdAt && moment(obj.createdAt).format("DD/MM/YYYY")}
                              </td>
                              <td className="orders-status">
                                <div
                                  className={`badge ${
                                    obj.status === 0
                                      ? `badge-soft-info`
                                      : obj.status === 1
                                      ? `badge-soft-primary`
                                      : obj.status === 2
                                      ? `badge-soft-secondary`
                                      : ``
                                  }`}
                                >
                                  {obj.status === 0
                                    ? "Scope Of Work"
                                    : obj.status === 1
                                    ? "Profile Selection"
                                    : obj.status === 2
                                    ? "Project Details"
                                    : ""}
                                </div>
                              </td>                              
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>No data Found .....</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                {/****************** Pagination ********************/}

                {this.state.totalPage > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                )}
              </div>
            </section>
          </React.Fragment>
        )}
      </section>
    );
  }
}
const mapState = ({ inCompleteBriefList }) => {
  return {
    _inCompleteBriefList: inCompleteBriefList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $inCompleteBriefList: (values) => dispatchEvent(inCompleteBriefList(values)),
});
export default connect(mapState, mapDispatch)(InCompleteBrief);
