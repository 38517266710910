import React, { Component } from "react";
import { connect } from "react-redux";
import { completeProject, closeProject, deleteProjectList } from "../../../store/action/transaction.action";
// import Table from "./Table";
import ReactPaginate from "react-paginate";
import ErrorApi from "../Common/Error/ErrorApi";
import TableArtistTrans from "./TableArtistTrans";

class CompleteTransactions extends Component {
  state = {
    transactionsList: 1,
    transactionActive: false,
    LoadingIs: false,
    transactionData: [],
    transactionIs: "",
    minBudget: "",
    maxBudget: "",
    deadline: "",
    totalPages: 0,
    closeProjectActive: false,
    deleteActiveIs : false
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        const {
          transactionsList,
          transactionIs,
          minBudget,
          maxBudget,
          deadline,
        } = this.state;
        const artistId=this.props.location.pathname.split(':');

        const transactionData = {
          List: transactionsList,
          project: transactionIs,
          min_budget: minBudget,
          max_budget: maxBudget,
          deadline: deadline,
          id:artistId[1]
        };

        this.props.$completeProject(transactionData);
      }
    );
  };

  handlePageClick = (data) => {
    const artistId=this.props.location.pathname.split(':');

    let selectedData = data.selected + 1;
    const { transactionIs, minBudget, maxBudget, deadline } = this.state;
    const transactionData = {
      List: selectedData,
      project: transactionIs,
      min_budget: minBudget,
      max_budget: maxBudget,
      deadline: deadline,
      id:artistId[1]
    };
    this.props.$completeProject(transactionData);
  };

  handleCloseProject = (id) => {
    this.setState({
      closeProjectActive: true,
    });
    const closeProjectData = { _id: id };
    this.props.$closeProject(closeProjectData);
  };

  handleDeleteProject = (id) => {
    this.setState({deleteActiveIs : true})
    const currentData = { _id : id}
    this.props.$deleteProjectList(currentData)
  }

  handleCompleteTransaction = () => {
    const { transactionsList, transactionIs } = this.state;
    const artistId=this.props.location.pathname.split(':');

    this.setState({
      LoadingIs: true,
    });

    const transactionData = {
      List: transactionsList,
      project: transactionIs,
      id:artistId[1]
    };
    this.props.$completeProject(transactionData);
  };

  componentDidMount() {
    this.handleCompleteTransaction();
  }

  handleApiCall = () => {
   this.handleCompleteTransaction();
  }

  componentWillReceiveProps(nextProps) {
    const Completetransaction =
      nextProps._completeProject.completeTransactionsIs;

    const closedProject = nextProps._closeProject.closeProjectIs;
    const deleteProject = nextProps._deleteProjectList.deleteProjectIs;
    const { closeProjectActive, deleteActiveIs } = this.state;

    if (Completetransaction) {
      this.setState({
        transactionData: Completetransaction.PostProjects,
        LoadingIs: false,
        totalPages: Completetransaction.totalPages,
      });
    }
    if (closedProject && closeProjectActive) {
      alert(closedProject.message);
      this.setState({
        closeProjectActive: false,
      });
      this.handleCompleteTransaction();
    }
    if(deleteProject && deleteActiveIs){
      this.setState({ deleteActiveIs : false })
      this.handleCompleteTransaction();
    }
  }

  render() {
    const { totalPages } = this.state;
    const transactionFunc = {
      handleOnChange: this.handleOnChange,
      handleCloseProject: this.handleCloseProject,
      handleDeleteProject: this.handleDeleteProject,
      handleApiCall: this.handleApiCall
    };
    return (
      <React.Fragment>
        {this.props._completeProject.completeProjectErrIs ? 
          <ErrorApi {...this.props} />
          :
        <div>
        <TableArtistTrans
          {...this.state}
          {...this.props}
          handleTransaction={transactionFunc}
          artistId = {"/transactions/completeTransactions"}
        />
        {/******** pagination ************* */}

        {totalPages > 0 && (
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        )}
        </div>}
      </React.Fragment>
    );
  }
}

const mapState = ({ completeProject, closeProject, deleteProjectList }) => {
  return {
    _completeProject: completeProject,
    _closeProject: closeProject,
    _deleteProjectList: deleteProjectList
  };
};
const mapDispatch = (dispatchEvent) => ({
  $completeProject: (values) => dispatchEvent(completeProject(values)),
  $closeProject: (values) => dispatchEvent(closeProject(values)),
  $deleteProjectList: (values) => dispatchEvent(deleteProjectList(values)),
});
export default connect(mapState, mapDispatch)(CompleteTransactions);
