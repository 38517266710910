import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../../Common/Loader/Loader";
import { connect } from "react-redux";
import { callBackRequestList } from "../../../../store/action/transaction.action";
import ReactPaginate from "react-paginate";
import ErrorApi from "../../Common/Error/ErrorApi";

class CallBackRequest extends Component {
  state = {
    LoadingIs: false,
    currentPage: 1,
    callBackData: [],
    totalPage: "",
    sort: -1,
    sortFlag : false
  };

  handleSorting = () => {
    this.setState({sortFlag : !this.state.sortFlag},()=>{
      const { currentPage } = this.state;
      let currentData = null;
      if(this.state.sortFlag){
        currentData = { pageIs: currentPage, sortIs: 1 };
      }else{
        currentData = { pageIs: currentPage, sortIs: -1 };
      }
      this.setState({sort : this.state.sortFlag ? 1 : -1})
      this.handleCallBackRequest(currentData);
    })
  };

  handlePageClick = (data) => {
    const selectedData = data.selected + 1;
    const { sort } = this.state;
    const currentData = { pageIs: selectedData, sortIs: sort };
    this.handleCallBackRequest(currentData);
  };

  handleCallBackRequest = (currentData) => {
    this.setState({
      LoadingIs: true,
    });
    this.props.$callBackRequestList(currentData);
  };

  componentDidMount() {
    const { currentPage, sort } = this.state;
    const currentData = { pageIs: currentPage, sortIs: sort };
    this.handleCallBackRequest(currentData);
  }

  componentWillReceiveProps(nextProps) {
    const dataIs = nextProps._callBackRequestList.callBackRequestIs;
    if (dataIs) {
      this.setState({
        callBackData: dataIs ? dataIs.postProject : "",
        totalPage: dataIs.totalPages,
        LoadingIs: false,
      });
    }
  }

  render() {
    const { LoadingIs, callBackData } = this.state;

    const callBackArr = [
      { name: "Action" },
      { name: "Client Name" },
      { name: "Collateral" },
      { name: "client email " },
      { name: "client Phone number" },
      { name: "Request Date" },
      { name: "Reason" },      
    ];

    return (
      <section>
        {this.props._callBackRequestList.callBackErrIs ? (
          <ErrorApi {...this.props} />
        ) : (
          <React.Fragment>
            <section className="client mt-5">
              <div className="container-fluid mx-auto">
                {LoadingIs ? (
                  <Loader />
                ) : (
                  <div className="table-responsive">
                    <table className="table table-sm table-nowrap card-table">
                      {/*----------------- table head --------------------*/}
                      <thead>
                        <tr>
                          {callBackArr.map((obj, index) => (
                            <React.Fragment key={index}>
                              {obj.name !== "Request Date" && (
                                <th>
                                  <Link to="#!" className="text-muted">
                                    {obj.name}
                                  </Link>
                                </th>
                              )}
                              {obj.name === "Request Date" && (
                                <th>
                                  <Link
                                    to="#!"
                                    className="text-muted sort"
                                    data-sort="tables-row"
                                    onClick={this.handleSorting}
                                  >
                                    {obj.name}
                                  </Link>
                                </th>
                              )}
                            </React.Fragment>
                          ))}
                        </tr>
                      </thead>
                      {/*----------------- table head --------------------*/}
                      <tbody className="list">
                        {callBackData.map((obj, index) => (
                          <tr key={index}>
                            <td className="text-method">
                              <Link to={`/approvals/ViewCallBackRequest/:${obj._id}`}>
                                <button className="btn btn-outline-success btn-sm mr-2">
                                  <i className="fe fe-eye"></i>
                                </button>
                              </Link>
                            </td>
                            <td className="orders-order">
                              {obj.client.first_name +
                                " " +
                                obj.client.last_name}
                            </td>
                            <td className="orders-product">
                              {obj.collateral.name}
                            </td>
                            <td className="orders-date">{obj.client.email}</td>
                            <td className="orders-total">
                              {obj.client.phone_no}
                            </td>
                            <td className="orders-status">
                              {obj.reason.createdAt.split("T").slice(0)[0]}
                            </td>
                            <td className="orders-method">{obj.reason.type}</td>                            
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {/****************** Pagination ********************/}

                {this.state.totalPage > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                )}
              </div>
            </section>
          </React.Fragment>
        )}
      </section>
    );
  }
}
const mapState = ({ callBackRequestList }) => {
  return {
    _callBackRequestList: callBackRequestList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $callBackRequestList: (values) => dispatchEvent(callBackRequestList(values)),
});
export default connect(mapState, mapDispatch)(CallBackRequest);
