import React, { Component } from "react";
import Moment from "moment";
import { connect } from "react-redux";
import {
  collateralPendingCreator,
  approvedCreatorList,
} from "../../../store/action/collateralList.action";
// import ApprovedCreator from "./ApprovedCreator";
import ReactPaginate from "react-paginate";
import { profileBaseUrl,WebsiteUrl } from "../../../Utils/BaseUrl";
import Loader from "../Common/Loader/Loader";
import Modal from "react-modal";
import Select from "react-select";
import { customStyles } from "../../../Utils/ModalProperty";
import CsvDownloader from "react-csv-downloader";
import StarredModal from "../Artist/StarredModal";
// import Tooltip from "react-tooltip-lite";
import NotesModal from "../Common/ModalCommon/NotesModal"
import { Link } from "react-router-dom";
import moment from "moment";
// import { getPublicAPI } from "../../../store/api-interface";

// import SearchCollateralCreator from "./SearchCollateralCreator";

Modal.setAppElement("#root");
Modal.defaultStyles.overlay.backgroundColor = "rgb(0 0 0 / 50%)";
Modal.defaultStyles.overlay.zIndex = "9999";

class CollateralCreator extends Component {
  state = {
    LoadingIs: true,
    totalPagesIs: 0,
    currentPage: 1,
    sort: -1,
    approvedCreatorList: [],
    showAction: false,
    showActionIndex: "",
    creatorQuestionIs: [],
    QuestionHasActive: false,
    LimitIs: "100",
    today: new Date(),
    csvData: [],
    selectedLimit: { value: "100", label: "100" },
    sortFlag: false,
    starredModIs:false,

    notesModalOpen:false,
    notesModalContent:""
  };

  closeNotesModal = () =>{
    this.setState({
      notesModalOpen: false,
    })
  }


  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  handleCreatorQuestion = (obj) => {
    const { QuestionHasActive } = this.state;
    // console.log("1",obj)
    this.setState({
      creatorQuestionIs: obj,
      QuestionHasActive: !QuestionHasActive,
    });
  };
  handleCloseModal = () => {
    this.setState({starredModIs:false})
  }
  handleCallApi = () => {
    const { currentPage, sort, LimitIs } = this.state;
    const currentId = this.props.location.pathname.split(":").slice(0)[1];
    const currentDataIs = {
      pageIs: currentPage,
      sortIs: sort,
      id: currentId,
      Limit: LimitIs,
    };
    this.handleApprovedCreatorList(currentDataIs)
  }

  closeModal = () => {
    this.setState({
      QuestionHasActive: false,
    });
  };

  handleSorting = () => {
    this.setState({ sortFlag: !this.state.sortFlag }, () => {
      const { currentPage, LimitIs } = this.state;
      const currentId = this.props.location.pathname.split(":").slice(0)[1];
      let currentDataIs = null;
      if (this.state.sortFlag) {
        currentDataIs = {
          pageIs: currentPage,
          sortIs: 1,
          id: currentId,
          Limit: LimitIs,
        };
      } else {
        currentDataIs = {
          pageIs: currentPage,
          sortIs: -1,
          id: currentId,
          Limit: LimitIs,
        };
      }
      this.setState({ sort: !this.state.sortFlag ? 1 : -1 });
      this.handleApprovedCreatorList(currentDataIs);
    });
  };

  handleSelect = (selectedLimit) => {
    const currentLimitIs = selectedLimit.value;
    this.setState({ LimitIs: currentLimitIs, selectedLimit }, () => {
      const currentId = this.props.location.pathname.split(":").slice(0)[1];
      const { currentPage, sort, LimitIs } = this.state;
      const currentDataIs = {
        pageIs: currentPage,
        sortIs: sort,
        id: currentId,
        Limit: LimitIs,
      };
      this.handleApprovedCreatorList(currentDataIs);
    });
  };

  handlePageClick = (data) => {
    let currentData = data.selected + 1;
    const { sort, LimitIs } = this.state;
    const currentId = this.props.location.pathname.split(":").slice(0)[1];
    const currentDataIs = {
      pageIs: currentData,
      sortIs: sort,
      id: currentId,
      Limit: LimitIs,
    };
    this.handleApprovedCreatorList(currentDataIs);
  };

  handleApprovedCreatorList = async(currentDataIs) => {
    this.setState({
      LoadingIs: true,
    });
    // const response = await getPublicAPI(`/collateral/approved-user?page=${currentDataIs?.pageIs}&limit=${currentDataIs?.Limit}&sort=${currentDataIs?.sortIs}&_id=${currentDataIs.id}`)
    // const data = response?.data
    // console.log(data)
    // this.setState({
    //   LoadingIs:false,
    //   approvedCreatorList:data?.userCollateral,
    //   totalPagesIs:data?.totalPages,
    // })
    this.props.$approvedCreatorList(currentDataIs);
  };

   componentDidMount() {
    const currentId = this.props.location.pathname.split(":").slice(0)[1];
    const { currentPage, sort, LimitIs } = this.state;
    const currentDataIs = {
      pageIs: currentPage,
      sortIs: sort,
      id: currentId,
      Limit: LimitIs,
    };
  //  this.props.$resetApprovedCreator()
   this.handleApprovedCreatorList(currentDataIs);
  }

  componentWillReceiveProps(nextProps) {
    const creatorListIs = nextProps?._approvedCreatorList?.approvedCreatorIs;
    if (creatorListIs) {
      this.setState({
        approvedCreatorList: creatorListIs?.userCollateral
          ? creatorListIs?.userCollateral
          : "",
        csvData: creatorListIs?.userCollateral
          ? creatorListIs?.userCollateral
          : "",
        totalPagesIs: creatorListIs?.totalPages ? creatorListIs?.totalPages : "",
        LoadingIs: false,
      });
    }
  }


  renderDropdown = (title, listArr) => {
    return (

      <div className="dropdown">
        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {title}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{maxHeight: 300, overflowY: 'auto'}}>
          {listArr.map((value, idx) => {
            return (
              <span key={idx} className="dropdown-item" >{value}</span>    
            )
          })}
        </div>
      </div>
    )
  }

  renderIndustry = (obj) => {
    const projects = obj.userUploadProject;
    const hasProjects = projects.length > 0;
    let industryText = [];
    if(hasProjects){
      projects.forEach(project => {
        project.industry && project.industry.length && industryText.push(project.industry)
      });
    } 
    
    return (      
      industryText.length > 0 ?
        this.renderDropdown('See Industry', industryText)
      : <p>None</p>
    )
  }

  renderLanguages = (obj) => {
    const projects = obj.userUploadProject;
    const hasProjects = projects.length > 0;
    let projectLanguages = [];
    let profileLanguages = obj.user.languages;
    profileLanguages = profileLanguages ? profileLanguages : [];
    if(hasProjects){
      let languagesFromEachProject = []
      projects.forEach(project => {
        project.languages && project.languages.length > 0 && languagesFromEachProject.push(...project.languages)
      });
      languagesFromEachProject.forEach(value => {
        value.name.length && projectLanguages.push(value.name);
      })
    }
    const allLanguages = [...profileLanguages, ...projectLanguages]
    return (
      allLanguages.length > 0 ?
        this.renderDropdown('See languages', allLanguages)
        : <p>None</p>
    )
  }

  renderTypeStyle = (obj,check) => {
    const arr = [...obj?.UserCollateralQuestionAnswer, ...obj?.uploadProjectQuestionAnswer]
    let typeArr = []
    let styleArr = []
    // console.log("arr = ", arr)
    arr.forEach(ele => {
      if (ele?.related_type === "style") {
        styleArr.push(...ele?.answer)
      } else {
        typeArr.push(...ele?.answer)
      }
    })
    // console.log("styleArr typeArr = ", styleArr, typeArr)
    let result = check === "T" ? typeArr : styleArr
    let name = check === "T" ? "See type" : "See style"
    return (
      result.length > 0 ?
        this.renderDropdownStyleType(name, result)
        : <p>None</p>
    )
  }
  handleStarModal = (id, list) => {
    this.setState({
      starId:id,
      collateralCheckList: [list],
      starredModIs: true,
    })
  }

  renderDropdownStyleType = (title, listArr) => {
    return (

      <div className="dropdown">
        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {title}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{maxHeight: 300, overflowY: 'auto'}}>
          {listArr.map((value, idx) => {
            return (
              <span key={idx} className="dropdown-item" >{value.item}</span>    
            )
          })}
        </div>
      </div>
    )
  }

  handleWordSize = (value) => {
    return value ? value?.length > 30 ? value?.slice(0,30) + "..." : value : ""
  }


  render() {
    const creatorArr = [
      { name: "Action"},
      { name: "Profile"},
      { name: "Name" },
      { name: "email" },
      { name: "username" },
      { name: "phone number" },
      { name: "publish date" },
      { name: "start price" },
      { name: "end price" },
      { name: "pricing Unit" },
      { name: "Unit" },

      { name: 'Projects Uploaded' },
      { name: 'Collateral Briefs Received' },
      { name: 'Total Briefs Received' },
      { name: 'Total Active Briefs'},
      { name: 'Total Active Projects'},
      { name: 'Total Completed Projects'},
      { name: 'Collateral Completed Projects'},
      { name: 'Brief Response Time (Overall)' },
      { name: 'Brief Response Time (3 Months)'},
      { name: 'Avg Project Rating'},
      { name: 'Avg Collateral Project Rating' },
      { name: 'Starred On' },
      { name: 'Starred By' },
      { name: 'Starred Notes' },
      // { name: "project (Approved)" },
      // { name: 'Live project'},
      { name: 'Availability'},
      { name: 'Full/Part-time' },
      { name: "Experience" },
      { name: "location" },
      // { name: "Question" },
      { name: 'Hourly Pricing'},
      { name: 'Industry' },
      { name: 'Languages' },
      { name: 'Type' },
      { name: 'Style'},
    ];

    const optionsIs = [
      // { value: "10", label: "10" },
      // { value: "50", label: "50" },
      { value: "100", label: "100" },
      { value: "200", label: "200" },
    ];

    const {
      LoadingIs,
      approvedCreatorList,
      QuestionHasActive,
      creatorQuestionIs,
      selectedLimit,
      today,
      csvData,
    } = this.state;

    const csvTest = JSON.stringify(csvData);

    // console.log("QuestionHasActive = ",QuestionHasActive)

    const csvList = JSON.parse(csvTest).map((obj) => {

      let arr = [...obj?.UserCollateralQuestionAnswer, ...obj?.uploadProjectQuestionAnswer]
      let typeArr = []
      let styleArr = []
      // console.log("arr = ", arr)
      arr && arr.forEach(ele => {
        if (ele?.related_type === "style") {
           ele?.answer && ele.answer.forEach((item,i) => {
               styleArr.push(item.item)
          })
        } else {
          ele?.answer && ele.answer.forEach((item, i) => {
            typeArr.push(item.item)
          })
        }
      }) 

      let industryText = [];
      let projectLanguages = [];
      let profileLanguages = obj.user.languages;
      const projects = obj.userUploadProject;
      profileLanguages = profileLanguages ? profileLanguages : [];
      if (projects?.length > 0) {
        let languagesFromEachProject = []
        projects.forEach(project => {
          project.industry && project.industry.length && industryText.push(project.industry)
          project.languages && project.languages.length > 0 && languagesFromEachProject.push(...project.languages)
        });
        languagesFromEachProject.forEach(value => {
          value.name.length && projectLanguages.push(value.name);
        })
      }
      let allLanguages = [...profileLanguages, ...projectLanguages]
      // console.log("allLanguages = ================", allLanguages,industryText)
      let newObj = {
        Name: obj.user.first_name + " " + obj.user.last_name,
        Email: obj.user.email,
        Username: obj.user.username,
        PhoneNumber: obj.user.phone_no,
        PublishDate: obj?.updatedAt ? moment(obj.updatedAt).format("DD/MM/YYYY"):"",
        StartPrice: obj.start_price,
        EndPrice: obj.end_price,
        PricingUnit:obj?.collateral?.pricing_unit,
        Unit:obj?.collateral?.unit,
        ApprovedProject: obj.UserProjectApproved,
        CollateralBriefsReceived:obj.collateral_brief_received,
        TotalBriefsReceived:obj?.brief_received,
        TotalActiveBriefs:obj?.active_brief,
        TotalActiveProjects:obj?.active_project,
        TotalCompletedProjects:obj?.complete_project,
        CollateralCompletedProjects:obj?.complete_project_user_collateral,
        'BriefResponseTime(Overall)':obj?.avg_brief_response_time + " mins",
        'BriefResponseTime(3Months)':obj?.avg_brief_response_timev2 + " mins",
        AvgProjectRating:Math.round(obj?.totalAverageReview) === obj?.totalAverageReview ? obj?.totalAverageReview : obj?.totalAverageReview.toFixed(2),
        AvgCollateralProjectRating:Math.round(obj?.average_collateral_project_rating) === obj?.average_collateral_project_rating ? obj?.average_collateral_project_rating : obj?.average_collateral_project_rating.toFixed(2),
        StarredOn:obj?.star_date && moment(obj?.star_date).format("DD/MM/YYYY"),
        StarredBy:obj?.userCollateralAlldetailList ? (obj?.userCollateralAlldetailList?.first_name + " " + obj?.userCollateralAlldetailList?.last_name): "",
        StarredNotes:obj?.star_description.replace(/,/g, ' '),
        Availability:`${obj.user.available === 1 ? `Available` : `Busy`}`,
        'FullTime/PartTime':obj.user.current_work_status.split(' ')[0],
        Experience:obj?.year ? `${obj?.year}${obj?.month ? `.${obj.month}` : ""} year` : `${obj.month} month`,
        Location: obj.user.location.replace(/,/g, ' '),
        HourlyPricing: obj?.user?.time_base_amount_month ? (obj.user.time_base_amount_month / (24 * 5)).toFixed(2) : "",
        industry:industryText ? industryText.filter((v, i, a) => a.indexOf(v) === i).join(" | ").replace(/,/g, ' | '):"",
        language:allLanguages ? allLanguages.filter((v, i, a) => a.indexOf(v) === i).join(" | ").replace(/,/g, ' | '):"",
        Type: typeArr? typeArr.join(" | ").replace(/,/g, ' | '):"",
        Style: styleArr ? styleArr.join(" | "):"",
      };
 
      return newObj;
    }); 

    const todays = Moment(today).format("DD/MM/YY");
    // console.log(approvedCreatorList)
    return (
      <React.Fragment>
        {/* <Switch>
          <Route
            path="/collateralsQuestions/creator/approved/:id/"
            render={(props) => <ApprovedCreator {...props} {...this.state} />}
          /> */}

        <section className="client">
          {approvedCreatorList.length > 0 && (
            <div className="row mb-5">
              {/* <SearchCollateralCreator /> */}
              <div className="col-1 ml-auto">
                <Select
                  options={optionsIs}
                  onChange={this.handleSelect}
                  displayValue="name"
                  value={selectedLimit}
                />
              </div>
              <div className="">
                {
                  <CsvDownloader
                    filename={`GetCreator_${todays}.csv`}
                    datas={csvList}
                    text="DOWNLOAD"
                    
                  >
                    <button className="btn btn-primary">Export</button>
                  </CsvDownloader>
                }
              </div>
            </div>
          )}
          <div className="container-fluid mx-auto">
            {LoadingIs ? (
              <Loader />
            ) : (
              <div className="table-responsive mb-5 mx-auto">
                
                <table className="table table-sm table-nowrap card-table">
                  {/*--------------------table head--------------------*/}
                  <thead>
                    <tr>
                      {creatorArr.map((obj, index) => (
                        <React.Fragment key={index}>
                          {obj.name !== "publish date" && (
                            <th key={index}>
                              {/* <a href="!#" className="text-muted"> */}
                              {obj.name}
                              {/* </a> */}
                            </th>
                          )}
                          {obj.name === "publish date" && (
                            <th key={index}>
                              <a
                                href="#!"
                                className="text-muted sort"
                                data-sort="tables-row"
                                onClick={this.handleSorting}
                              >
                                {obj.name}
                              </a>
                            </th>
                          )}
                        </React.Fragment>
                      ))}
                    </tr>
                  </thead>

                  {/*--------------------table head--------------------*/}
                  <tbody className="list">
                    {approvedCreatorList.length > 0 ? (
                      approvedCreatorList.map((obj, index) => (
                        <tr key={index}>
                           <td className="text-center">
                        <div className="d-flex align-items-center">
                          <div className={`col-1 stars-rating ${obj?.star ? obj.star == "0" ? "" : "active" : ""}`} style={{ lineHeight: "" }} >
                            <input type="radio" id={`${index + "star"}`} name="rating" defaultChecked={obj?.star == 1 ? true : false} onClick={() => this.handleStarModal(obj?.user_id,obj)} />
                            <label htmlFor={`${index + "star"}`} className="mb-0 star">&#9733;</label>
                          </div></div></td>
                          <td className="text-center">
                        <div className={`dropdown`}>
                          <a
                            href="#!"
                            className="dropdown-ellipses dropdown-toggle text-secondary"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fe fe-more-vertical"></i>
                          </a>
                            <div
                              className={`ma-auto dropdown-menu dropdown-menu-right text-center`}
                            >
                                <span>
                                  {console.log(obj?.user)}
                                  <a
                                    href={`${WebsiteUrl}creator/${obj?.user?.username}`}
                                    target='_blank'
                                    className="dropdown-item mb-2"
                                    datatoggle="modal"
                                    datatarget="#exampleModalCenter"
                                  >
                                    View Public Profile
                                  </a>
                                </span>
                              <span>
                                <Link
                                  to={`/artist/:view/:${obj?.user?._id}`}
                                  className="dropdown-item mb-2"
                                  datatoggle="modal"
                                  datatarget="#exampleModalCenter"
                                >
                                  View Profile
                                </Link>
                            </span>
                            </div>
                        </div>
                      </td>

                          <td className="orders-order">
                            {this.handleWordSize((obj?.user?.first_name+" "+obj?.user?.last_name))}
                          </td>
                          <td className="orders-product">{this.handleWordSize((obj?.user?.email))}</td>
                          <td className="orders-date">{this.handleWordSize((obj?.user?.username))}</td>
                          <td className="orders-total">{obj.user.phone_no}</td>                          
                          <td className="orders-method">
                            {obj?.updatedAt && moment(obj?.updatedAt).format("DD/MM/YYYY")}
                          </td>
                          <td>{obj.start_price}</td>
                          <td>{obj.end_price}</td>
                          <td className="orders-method">
                            {obj.collateral.pricing_unit}
                          </td>
                          <td className="orders-method">
                            {obj.collateral.unit}
                          </td>

                          <td className="orders-method">
                            {obj?.total_project}
                          </td>
                           <td className="orders-method">
                            {obj?.collateral_brief_received}
                          </td>
                           <td className="orders-method">
                            {obj?.brief_received}
                          </td>
                           <td className="orders-method">
                            {obj?.active_brief}
                          </td>
                           <td className="orders-method">
                            {obj?.active_project}
                          </td>
                           <td className="orders-method">
                            {obj?.complete_project}
                          </td>
                           <td className="orders-method">
                            {obj?.complete_project_user_collateral}
                          </td>
                           <td className="orders-method">
                            {obj?.avg_brief_response_time + " mins"}
                          </td>
                          <td className="orders-method">
                            {obj?.avg_brief_response_timev2 + " mins"}
                          </td>
                           <td className="orders-method">
                            {Math.round(obj?.totalAverageReview) === obj?.totalAverageReview ? obj?.totalAverageReview : obj?.totalAverageReview.toFixed(2)}
                          </td>
                           <td className="orders-method">
                            {Math.round(obj?.average_collateral_project_rating) === obj?.average_collateral_project_rating ? obj?.average_collateral_project_rating : obj?.average_collateral_project_rating.toFixed(2)}
                          </td>
                           <td className="orders-method">
                            {obj?.star_date && moment(obj?.star_date).format("DD/MM/YYYY")}
                          </td>
                          <td>{obj?.userCollateralAlldetailList ? (obj?.userCollateralAlldetailList?.first_name + " " + obj?.userCollateralAlldetailList?.last_name): ""}</td>
                          <td>
                            {/* <Tooltip               
                                content={(
                                    <div  >
                                      <div>{obj?.star_description}</div>
                                    </div>
                                )}
                                direction="down"
                                tagName="span"
                                className="target"
                                tipContentClassName=""
                                styles={{cursor : "pointer"}}
                                >
                                {obj?.star_description?.trim()?.substring(0, 15)}{obj?.star_description?.trim()?.length > 15 ? "..." : ""}
                            </Tooltip> */}

                          <span style={obj?.star_description.length>15?{cursor:"pointer"}:{cursor:'default'}} onClick={obj?.star_description?.length>15 ? ()=>this.setState({notesModalOpen: true,notesModalContent:obj?.star_description }):()=>this.setState({notesModalOpen: false })}>
                            {obj?.star_description?.trim()?.substring(0, 15)}{obj?.star_description?.trim()?.length > 15 ? "..." : ""}{obj?.star_description?.length>15 && <span className="fe fe-alert-circle"></span>}
                          </span>

                          </td>
                          {/* <td className="orders-method text-center">
                            {obj.UserProjectApproved}
                          </td>
                          <td className="orders-method text-center">
                            {obj.UserLiveProject}
                          </td> */}
                          <td className="orders-method text-center">
                            <div className={`badge badge-soft-${obj.user.available === 1 ? 'primary' : 'secondary'} mr-2 disable`}>
                              {`${obj.user.available === 1 ? `Available` : `Busy`}`}</div>
                          </td>
                          <td className="orders-method text-center">
                            {obj.user.current_work_status.split(' ')[0]}
                          </td>
                          {/* <td className="orders-total">{`month: ${obj?.month} , year: ${obj?.year}`}</td> */}
                          <td className="orders-total">{obj?.year ? `${obj?.year}${obj?.month ? `.${obj.month}` : ""} year` : `${obj.month} month`}</td>                          
                          <td className="orders-total">{this.handleWordSize((obj?.user?.location))}</td>
                          {/* <td className="text-center">
                            <button
                              className={`btn btn-outline-${obj.UserCollateralQuestionAnswer ? 'success' : 'danger'} btn-sm mr-2`}
                              // disabled={true}
                              onClick={() =>
                                this.handleCreatorQuestion(
                                  obj.UserCollateralQuestionAnswer
                                )
                              }
                            >
                              <i className="fe fe-eye"></i>
                            </button>                            
                          </td> */}
                          <td className="orders-method">{obj?.user?.time_base_amount_month ? (obj.user.time_base_amount_month / (20 * 5)).toFixed(2) : ""}</td>
                          <td className="orders-method">{this.renderIndustry(obj)}</td>
                          <td className="orders-method">{this.renderLanguages(obj)}</td>
                          <td className="orders-method">{this.renderTypeStyle(obj,"T")}</td>
                          <td className="orders-method">{this.renderTypeStyle(obj,"S")}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>No data found..</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {/**** Pagination****** */}

            {this.state.totalPagesIs > 0 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.totalPagesIs}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
          {/*-------------------Collateral creator Question Modal-----------------------*/}
          <div>
            <Modal
              isOpen={QuestionHasActive}
              style={customStyles}
              contentLabel="Example Modal"
            >
              {creatorQuestionIs?.length > 0
                ? creatorQuestionIs.map((obj, index) => (
                    <div className="form-group" key={index}>
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Question ({obj?.related_type})
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={obj?.question}
                        disabled="disabled"
                      />
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Answer
                      </label>
                      <div className="col-12">
                        <div className="row">
                          {obj?.answer?.length > 0
                            ? obj?.answer.map((object, index) => (
                                <div
                                  className="questin-Listing col-5 mr-3 mb-2"
                                  key={index}
                                >
                                  <div className="row">
                                    <div className="col-9">
                                      <p className="mb-0">{object?.item}</p>
                                    </div>
                                    {object?.image ? (
                                      <div className="col-3">
                                        <img
                                          src={`${
                                            profileBaseUrl + object?.image
                                          }`}
                                          height="30px"
                                          width="30px"
                                          alt=".."
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              ))
                            : "No data Found.."}
                        </div>
                      </div>
                      <hr className="my-5" />
                    </div>
                  ))
                : "No data Found"}
              <div className="col-12 text-right">
                <button className="btn btn-secondary" onClick={this.closeModal}>
                  Close
                </button>
              </div>
            </Modal>
          </div>
        </section>
        {/* </Switch> */}
        <StarredModal
          handleCloseModal={this.handleCloseModal}
          handleCallApi = {this.handleCallApi}
          {...this.state}
        />

        {this.state.notesModalContent &&
          <NotesModal 
          notesModalOpen={this.state.notesModalOpen} 
          closeNotesModal={this.closeNotesModal} 
          notesModalContent={this.state.notesModalContent}/>} 

      </React.Fragment>
    );
  }
}
const mapState = ({ collateralPendingCreator, approvedCreatorList }) => {
  return {
    _collateralPendingCreator: collateralPendingCreator,
    _approvedCreatorList: approvedCreatorList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $collateralPendingCreator: (values) =>
    dispatchEvent(collateralPendingCreator(values)),
  $approvedCreatorList: (values) => dispatchEvent(approvedCreatorList(values)),
});
export default connect(mapState, mapDispatch)(CollateralCreator);
