import { call, put } from "redux-saga/effects";
import { getPublicAPI, putAPI, postAPI, deleteAPI } from "../api-interface";

// import axios from "../api-interface";
import {
  transactionListSuccess,
  transactionGetDataSuccess,
  transactionStatusSuccess,
  transactionListError,
  activeTransactionsSuccess,
  activeTransactionsError,
  pendingTransactionsSuccess,
  pendingTransactionsError,
  incompleteTransactionsSuccess,
  incompleteTransactionError,
  incompleteHighTransactionSuccess,
  incompleteHighTransactionError,
  closeProjectSucces,
  completeProjectSuccess,
  closeTransactionListSuccess,
  completeProjectError,
  closeTransactionListError,
  DontLikeTransactionListSuccess,
  DontLikeTransactionError,
  chooseCreatorSuccess,
  transactionApprovalListSuccess,
  callBackRequestListSuccess,
  callBackDataSuccess,
  callBackSearchCreatorSuccess,
  recommendedCreatorSuccess,
  inCompleteBriefListSuccess,
  creatorRejectsBriefListSuccess,
  rejectsCreatorDataSuccess,
  clearBriefListSuccess,
  callBackRequestListError,
  transactionApprovalListError,
  inCompleteBriefListError,
  creatorRejectsBriefListError,
  closedBriefProjectSuccess,
  closedBriefProjectError,
  rejectedBriefListSuccess,
  rejectedBriefListError,
  deleteProjectListSuccess,
  deleteBriefListSuccess,
  deleteBriefListError,
  deleteProjectAllListSuccess,
  deleteProjectAllListError,
  closeBriefAllListSuccess,
  closeBriefAllListError,
  closeBriefRequestListSuccess,
  closeBriefRequestListErr,
  closeProjectRequestSuccess,
  closeProjectReqListErr,
  completedMilesSuccess,
  milesCommentSuccess,
  milestoneApprovedSuccess,
  addMultipleCreatorSuccess,
  addMultipleCreator2Success,
  deletePendingCreatorSuccess,
  paymentOfflineSuccess,
  updateTransactionsSuccess,
  approvedProposalSuccess,
  approvedProposalJobSuccess,
  requestChangeProposalSuccess,
  editClientSuccess,
  managerDetailsSuccess,
  inactiveBriefSuccess,
  inactiveBriefErr,
  inactiveProjectSuccess,
  inactiveProjectErr,
  inActBriefForAmSuccess,
  inActBriefForErr,
  transactionsCountSuccess,
} from "../action/transaction.action";

//  Transaction / post Project Listing and pagination*********

export function* onTransactionsList(data) {
  let values = data.values;
  
  try {
    if (values) {
      let page = values.List;
      let minbudget = values.min_budget;
      let maxbudget = values.max_budget;
      let project = values.project;
      let deadline = values.deadline;
      let budget_scope = values?.budget_scope ? values.budget_scope : "";
      let id=values.id
      let data
      if(!id){
        data = yield call(
          getPublicAPI,
          `/postProject/get-postproject-negotiation-list?limit=10&page=${page}${
            minbudget ? `&min_budget=${Number(minbudget)}` : ""
          }${maxbudget ? `&max_budget=${Number(maxbudget)}` : ""}${
            deadline ? `&deadline=${Number(deadline)}` : ""
          }&project_name=${project}&budget_scope=${budget_scope}`
        );
      }else{
        data= yield call(
          getPublicAPI,
          `/postProject/get-postproject-active-breif-list-creator?id=${id}&limit=10&page=${page}${
            minbudget ? `&min_budget=${Number(minbudget)}` : ""
          }${maxbudget ? `&max_budget=${Number(maxbudget)}` : ""}${
            deadline ? `&deadline=${Number(deadline)}` : ""
          }&project_name=${project}`)
  
      }
      if (data.status === 200) {
        yield put(transactionListSuccess(data.data));
      }
    }else{
      const data= yield call(
        getPublicAPI,
        `/postProject/get-postproject-negotiation-list?limit=&page=`)
        if (data.status === 200) {
          yield put(transactionListSuccess(data.data));
        }
      }
      // const data= yield call(
      //   getPublicAPI,
      //   `/postProject/get-postproject-active-breif-list-creator?limit=&page=`)
      //   if (data.status === 200) {
      //     yield put(transactionListSuccess(data.data));
      //   }
      // }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(transactionListError(error.response.status));
    } else {
      alert(`Error: ${error.response.status}`);
    }
  }
}

// transaction Get data  ***********************

export function* onTransactionsGetData(data) {
  
  const obj= data.values
  const admin =data.payload?.admin
  try {
    let data
    if (obj) {
      if(admin){
         data = yield call(
          getPublicAPI,
          `/postProject/get-postproject-admin?_id=${obj}`
          );

      }else {

        data = yield call(
              getPublicAPI,
              `/postProject/get-postproject-data?_id=${obj}`
              );
      } 
      if (data.status === 200) {
        yield put(transactionGetDataSuccess(data.data));
      }
    }
  } catch (error) {
    alert("Error:", error.response.status);
  }
}

// transaction status update ***********************

export function* onTransactionStatus(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        putAPI,
        `/postProject/postproject-status`,
        values
      );
      if (data.status === 200) {
        yield put(transactionStatusSuccess(data.data));
      }
    }
  } catch (error) {
    //alert("Error: ", error.response.status);
  }
}

// transactions count ********************

export function* onTransactionCount(){
  try{
      const data = yield call(
        getPublicAPI,
        '/postProject/get-postproject-count-admin'
      );
      if(data.status===200){
        yield put(transactionsCountSuccess(data.data));
      }
    
  }catch(error){

  }
}

// transactions active  List***************

export function* onActiveTransactions(data) {

  let values = data.values;
  try {
    if (values) {
      let page = values.List;
      let minbudget = values.min_budget;
      let maxbudget = values.max_budget;
      let project = values.project;
      let deadline = values.deadline;
      let id=values.id

      let data
      if(!id){
          

        data= yield call(
          getPublicAPI,
          `/postProject/get-postproject-active-list?limit=10&page=${page}${
            minbudget ? `&min_budget=${Number(minbudget)}` : ""
          }${maxbudget ? `&max_budget=${Number(maxbudget)}` : ""}${
            deadline ? `&deadline=${Number(deadline)}` : ""
          }&project_name=${project}`
        );
      }else{

        data= yield call(
          getPublicAPI,
          `/postProject/get-postproject-active-project-list-creator?id=${id}&limit=10&page=${page}${
            minbudget ? `&min_budget=${Number(minbudget)}` : ""
          }${maxbudget ? `&max_budget=${Number(maxbudget)}` : ""}${
            deadline ? `&deadline=${Number(deadline)}` : ""
          }&project_name=${project}&milstone_sort=1`)

      }
      if (data.status === 200) {
        yield put(activeTransactionsSuccess(data.data));
      }
    }else{
      const data= yield call(
        getPublicAPI,
        `/postProject/get-postproject-active-list?limit=&page=&milstone_sort=1`
      );
      if (data.status === 200) {
        yield put(activeTransactionsSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(activeTransactionsError(error.response.status));
    } else {
      alert(`Error: ${error.response.status}`);
    }
  }
}

// transactions Pending List********************

export function* onPendingTransactions(data) {
  let values = data.values;

  try {
    if (values) {
      let page = values.List;
      let minbudget = values.min_budget;
      let maxbudget = values.max_budget;
      let project = values.project;
      let deadline = values.deadline;
      let id = values.id;
      let budget_scope = values?.budget_scope ? values.budget_scope : "";
      let data;
      if(!id){
        data = yield call(
          getPublicAPI,
          `/postProject/get-postproject-pending-list?limit=10&page=${page}${
            minbudget ? `&min_budget=${Number(minbudget)}` : ""
          }${maxbudget ? `&max_budget=${Number(maxbudget)}` : ""}${
            deadline ? `&deadline=${Number(deadline)}` : ""
          }&project_name=${project}&budget_scope=${budget_scope}`
        );
      }else{

        data= yield call(
          getPublicAPI,
          `/postProject/get-postproject-pending-breif-list-creator?id=${id}&limit=10&page=${page}${
            minbudget ? `&min_budget=${Number(minbudget)}` : ""
          }${maxbudget ? `&max_budget=${Number(maxbudget)}` : ""}${
            deadline ? `&deadline=${Number(deadline)}` : ""
          }&project_name=${project}`)

      }
      if (data.status === 200) {
        yield put(pendingTransactionsSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(pendingTransactionsError(error.response.status));
    } else {
      alert(`Error: ${error.response.status}`);
    }
  }
}

// transactions Incomplete List***********************

export function* onIncompleteTransactions(data) {
  let values = data.values;
  try {
    if (values) {
      let page = values.List;
      let minbudget = values.min_budget;
      let maxbudget = values.max_budget;
      let project = values.project;
      let deadline = values.deadline;
      let budget_scope = values?.budget_scope ? values.budget_scope : "";

      const data = yield call(
        getPublicAPI,
        `/postProject/get-postproject-incomplete-list?limit=10&page=${page}${
          minbudget ? `&min_budget=${Number(minbudget)}` : ""
        }${maxbudget ? `&max_budget=${Number(maxbudget)}` : ""}${
          deadline ? `&deadline=${Number(deadline)}` : ""
        }&project_name=${project}&budget_scope=${budget_scope}`
      );
      if (data.status === 200) {
        yield put(incompleteTransactionsSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(incompleteTransactionError(error.response.status));
    } else {
      alert("Error:", error.response.status);
    }
  }
}

//=================onIncompleteHighTransactions-------------------

export function* onIncompleteHighTransactions(data) {
  let values = data.values;
  try {
    if (values) {
      let page = values.List;
      let minbudget = values.min_budget;
      let maxbudget = values.max_budget;
      let project = values.project;
      let deadline = values.deadline;
      let budget_scope = values?.budget_scope ? values.budget_scope : "";

      const data = yield call(
        getPublicAPI,
        `/postProject/get-postproject-incomplete-list-high?limit=10&page=${page}${
          minbudget ? `&min_budget=${Number(minbudget)}` : ""
        }${maxbudget ? `&max_budget=${Number(maxbudget)}` : ""}${
          deadline ? `&deadline=${Number(deadline)}` : ""
        }&project_name=${project}&budget_scope=${budget_scope}`
      );
      if (data.status === 200) {
        yield put(incompleteHighTransactionSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(incompleteHighTransactionError(error.response.status));
    } else {
      alert("Error:", error.response.status);
    }
  }
}

// transaction close Project  *****************************
export function* onCloseProject(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(putAPI, `/postProject/close-postproject`, values);
      if (data.status === 200) {
        yield put(closeProjectSucces(data.data));
      }
    }
  } catch (error) {
    alert(`Error: ${error.response.status}`);
  }
}

// complete Transaction ***************************************

export function* onCompleteProject(data) {
  let values = data.values;

  try {
    if (values) {
      let page = values.List;
      let minbudget = values.min_budget;
      let maxbudget = values.max_budget;
      let project = values.project;
      let deadline = values.deadline;
      let id = values.id;
      let data
      if(!id){

         data = yield call(
          getPublicAPI,
          `/postProject/get-postproject-complete-list?limit=10&page=${page}${
            minbudget ? `&min_budget=${Number(minbudget)}` : ""
          }${maxbudget ? `&max_budget=${Number(maxbudget)}` : ""}${
            deadline ? `&deadline=${Number(deadline)}` : ""
          }&project_name=${project}`
        );
      }else{
   
        data= yield call(
          getPublicAPI,
          `/postProject/get-postproject-complete-project-list-creator?id=${id}&limit=10&page=${page}${
            minbudget ? `&min_budget=${Number(minbudget)}` : ""
          }${maxbudget ? `&max_budget=${Number(maxbudget)}` : ""}${
            deadline ? `&deadline=${Number(deadline)}` : ""
          }&project_name=${project}`)
   
      
      }
      if (data.status === 200) {
        yield put(completeProjectSuccess(data.data));
      }
    }else{
      const data= yield call(
        getPublicAPI,
        `/postProject/get-postproject-complete-list`)

        if (data.status === 200) {
          yield put(completeProjectSuccess(data.data));
        }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(completeProjectError(error.response.status));
    } else {
      alert("Error:", error.response.status);
    }
  }
}

// closed Transaction List *************************************

export function* onClosedTransactionList(data) {
  let values = data.values;
  try {
    if (values) {
      let page = values.List;
      let minbudget = values.min_budget;
      let maxbudget = values.max_budget;
      let project = values.project;
      let deadline = values.deadline;

      const data = yield call(
        getPublicAPI,
        `/postProject/get-postproject-closed-list?limit=10&page=${page}${
          minbudget ? `&min_budget=${Number(minbudget)}` : ""
        }${maxbudget ? `&max_budget=${Number(maxbudget)}` : ""}${
          deadline ? `&deadline=${Number(deadline)}` : ""
        }&project_name=${project}`
      );
      if (data.status === 200) {
        yield put(closeTransactionListSuccess(data.data));
      }
    }else{
      const data = yield call(
        getPublicAPI,
        `/postProject/get-postproject-closed-list?limit=&page=`
      );
      if (data.status === 200) {
        yield put(closeTransactionListSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(closeTransactionListError(error.response.status));
    } else {
      alert("Error:", error.response.status);
    }
  }
}

// dont like transaction List ******************************

export function* onDontLikeTransaction(data) {
  let values = data.values;
  try {
    if (values) {
      let page = values.List;
      let minbudget = values.min_budget;
      let maxbudget = values.max_budget;
      let project = values.project;
      let deadline = values.deadline;

      const data = yield call(
        getPublicAPI,
        `/postProject/get-postproject-dnotlike-list?limit=10&page=${page}${
          minbudget ? `&min_budget=${Number(minbudget)}` : ""
        }${maxbudget ? `&max_budget=${Number(maxbudget)}` : ""}${
          deadline ? `&deadline=${Number(deadline)}` : ""
        }&project_name=${project}`
      );
      if (data.status === 200) {
        yield put(DontLikeTransactionListSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(DontLikeTransactionError(error.response.status));
    } else {
      alert("Error:", error.response.status);
    }
  }
}

// choose creator in dont Like case(transaction) *******************************

export function* onChooseCreator(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        postAPI,
        `/postProject/post-project-add-recommended-artist`,
        values
      );
      if (data.status === 200) {
        yield put(chooseCreatorSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(DontLikeTransactionError(error.response.status));
    // } else {
    //   alert("Error:", error.response.status);
    // }
  }
}

// transtion List (Approval tab)
export function* onTransactionAppRList(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/pending-transaction?page=${values.pageIs}&limit=10&sort=${values.sortIs}`
      );
      if (data.status === 200) {
        yield put(transactionApprovalListSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(transactionApprovalListError(error.response.status));
    }
  }
}

// callBack Request
export function* onCallBackRequest(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/request-call-client?page=${values.pageIs}&limit=10&sort=${values.sortIs}`
      );
      if (data.status === 200) {
        yield put(callBackRequestListSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(callBackRequestListError(error.response.status));
    }
  }
}

// call back data based on id (approval tab)
export function* onCallBackData(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/request-call-client-data?_id=${values}`
      );
      if (data.status === 200) {
        yield put(callBackDataSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(DontLikeTransactionError(error.response.status));
    // } else {
    //   alert("Error:", error.response.status);
    // }
  }
}

// call back data search creator (approval tab)

export function* onSearchCallBack(data) {
  let values = data.values;
  try {
    if (values) {
      let email = values.email;
      let collateralId = values.id;
      const data = yield call(
        getPublicAPI,
        `/postProject/get-creator-for-project-new?email=${email}&collateral_id=${collateralId}&post_project_id=${values.projectId}`
      );
      if (data.status === 200) {
        yield put(callBackSearchCreatorSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(DontLikeTransactionError(error.response.status));
    // } else {
    //   alert("Error:", error.response.status);
    // }
  }
}

// recommend creator (Approaval tab)
export function* onRecommendCreator(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        postAPI,
        `/postProject/post-project-add-recommended-artist`,
        values
      );
      if (data.status === 200) {
        yield put(recommendedCreatorSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(DontLikeTransactionError(error.response.status));
    // } else {
    //   alert("Error:", error.response.status);
    // }
  }
}

// Incomplete Brief (Approval tab)
export function* onInCompleteBrief(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/incomplete-transaction-data?page=${values.page}&limit=10&sort=${values.sortIs}`
      );
      if (data.status === 200) {
        yield put(inCompleteBriefListSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(inCompleteBriefListError(error.response.status));
    }
  }
}

// creator Rejects List (Approaval tab)
export function* onCraetorRejectsBrief(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/creator-rejects-brief-list?page=${values.page}&limit=10&sort=${values.sortIs}`
      );
      if (data.status === 200) {
        yield put(creatorRejectsBriefListSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(creatorRejectsBriefListError(error.response.status));
    }
  }
}

// creator rejects data based on id (Approval tab)
export function* onCraetorRejectsBriefData(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/backendUser/rejects-brief-data?_id=${values}`
      );
      if (data.status === 200) {
        yield put(rejectsCreatorDataSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(DontLikeTransactionError(error.response.status));
    // } else {
    //   alert("Error:", error.response.status);
    // }
  }
}

// clear Brief (Approval tab)
export function* onClearBrief(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        putAPI,
        `/backendUser/rejects-brief-clear`,
        values
      );
      // if (data.status === 200) {
      yield put(clearBriefListSuccess(data.data));
      // }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(DontLikeTransactionError(error.response.status));
    // } else {
    //   alert("Error:", error.response.status);
    // }
  }
}

// closed brief project (Transaction tab)
export function* onClosedBriefProject(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        putAPI,
        `/backendUser/rejects-brief-clear`,
        values
      );
      if (data.status === 200) {
        yield put(closedBriefProjectSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(closedBriefProjectError(error.response.status));
    }
  }
}

// Rejected Brief Project (Transction Tab)

export function* onRejectBriefProject(data) {
  let values = data.values;

  try {
    if (values) {
      let page = values.List;
      let minbudget = values.min_budget;
      let maxbudget = values.max_budget;
      let project = values.project;
      let deadline = values.deadline;
      let id = values.id;
      let data
       if(!id){
          data = yield call(
           getPublicAPI,
           `/postProject/get-postproject-rejected-list-admin?limit=10&page=${page}${
             minbudget ? `&min_budget=${Number(minbudget)}` : ""
           }${maxbudget ? `&max_budget=${Number(maxbudget)}` : ""}${
             deadline ? `&deadline=${Number(deadline)}` : ""
           }&project_name=${project}`
         );
       }else{

        data= yield call(
          getPublicAPI,
          `/postProject/get-postproject-brief-project-rejcted-list-creator?id=${id}&limit=10&page=${page}${
            minbudget ? `&min_budget=${Number(minbudget)}` : ""
          }${maxbudget ? `&max_budget=${Number(maxbudget)}` : ""}${
            deadline ? `&deadline=${Number(deadline)}` : ""
          }&project_name=${project}`)
     
       }
      if (data.status === 200) {
        yield put(rejectedBriefListSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(rejectedBriefListError(error.response.status));
    } else {
      alert("Error:", error.response.status);
    }
  }
}

// export function* onRejectBriefProject(data) {
//   let values = data.values;
//   try {
//     if (values) {
//       const data = yield call(
//         getPublicAPI,
//         `/backendUser/get-postproject-rejected-list-admin`,
//         values
//       );
//       if (data.status === 200) {
//         yield put(rejectedBriefListSuccess(data.data));
//       }
//     }
//   } catch (error) {
//     if (error.response.status === 401) {
//       yield put(rejectedBriefListError(error.response.status));
//     }
//   }
// }

// Delete Project (Transaction tab)
export function* onDeleteProjectIs(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        deleteAPI,
        `/postProject/delete-postproject`,
        values
      );
      if (data.status === 200) {
        yield put(deleteProjectListSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(closedBriefProjectError(error.response.status));
    // }
  }
}

// Deleted Briefs List (transaction tab)
export function* onCloesdBriefs(data) {
  let values = data.values;
  try {
    if (values) {
      let page = values.List;
      let minbudget = values.min_budget;
      let maxbudget = values.max_budget;
      let project = values.project;
      let deadline = values.deadline;

      const data = yield call(
        getPublicAPI,
        `/postProject/get-postproject-incomplete-delete-list?limit=10&page=${page}${
          minbudget ? `&min_budget=${Number(minbudget)}` : ""
        }${maxbudget ? `&max_budget=${Number(maxbudget)}` : ""}${
          deadline ? `&deadline=${Number(deadline)}` : ""
        }&project_name=${project}`
      );
      if (data.status === 200) {
        yield put(deleteBriefListSuccess(data.data));
      }
    }else{
      const data = yield call(
        getPublicAPI,
        `/postProject/get-postproject-incomplete-delete-list?limit=&page=&project_name=`
        );
        if (data.status === 200) {
        yield put(deleteBriefListSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(deleteBriefListError(error.response.status));
    } else {
      alert("Error:", error.response.status);
    }
  }
}

// Deleted Projects List
export function* onDeleteProjectList(data) {
  let values = data.values;
  try {
    if (values) {
      let page = values.List;
      let minbudget = values.min_budget;
      let maxbudget = values.max_budget;
      let project = values.project;
      let deadline = values.deadline;

      const data = yield call(
        getPublicAPI,
        `/postProject/get-postproject-active-delete-list?limit=10&page=${page}${
          minbudget ? `&min_budget=${Number(minbudget)}` : ""
        }${maxbudget ? `&max_budget=${Number(maxbudget)}` : ""}${
          deadline ? `&deadline=${Number(deadline)}` : ""
        }&project_name=${project}`
      );
      if (data.status === 200) {
        yield put(deleteProjectAllListSuccess(data.data));
      }
    }else{
      const data = yield call(
        getPublicAPI,
        `/postProject/get-postproject-active-delete-list?limit=&page=`
      );
      if (data.status === 200) {
        yield put(deleteProjectAllListSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(deleteProjectAllListError(error.response.status));
    } else {
      alert("Error:", error.response.status);
    }
  }
}

// closed Brief All List (transcation tab)

export function* onClosedAllList(data) {
  let values = data.values;
  try {
    if (values) {
      let page = values.List;
      let minbudget = values.min_budget;
      let maxbudget = values.max_budget;
      let project = values.project;
      let deadline = values.deadline;

      const data = yield call(
        getPublicAPI,
        `/postProject/get-postproject-brief-closed-list?limit=10&page=${page}${
          minbudget ? `&min_budget=${Number(minbudget)}` : ""
        }${maxbudget ? `&max_budget=${Number(maxbudget)}` : ""}${
          deadline ? `&deadline=${Number(deadline)}` : ""
        }&project_name=${project}`
      );
      if (data.status === 200) {
        yield put(closeBriefAllListSuccess(data.data));
      }
    }else{
      const data = yield call(
        getPublicAPI,
        `/postProject/get-postproject-brief-closed-list?limit=&page=`
      );
      if (data.status === 200) {
        yield put(closeBriefAllListSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(closeBriefAllListError(error.response.status));
    } else {
      alert("Error:", error.response.status);
    }
  }
}

// close brief request (Approval tab)
export function* onClosedBriefRequest(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/postProject/get-postproject-brief-closed-request-list?page=${values.page}&limit=10&sort=${values.sortIs}`
      );
      if (data.status === 200) {
        yield put(closeBriefRequestListSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(closeBriefRequestListErr(error.response.status));
    }
  }
}

// close Project request (Approval tab)
export function* onCloseProjectReq(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/postProject/get-postproject-closed-request-list?page=${values.page}&limit=10&sort=${values.sortIs}`
      );
      if (data.status === 200) {
        yield put(closeProjectRequestSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(closeProjectReqListErr(error.response.status));
    }
  }
}

// completed Miles data
export function* onCompMiles(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/postProject/get-submitted-milestone?_id=${values}`
      );
      if (data.status === 200) {
        yield put(completedMilesSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(closeProjectReqListErr(error.response.status));
    // }
  }
}

// get Milestone Comment
export function* onMilesComment(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/postProject/get-submitted-milestone-comment?_id=${values}`
      );
      if (data.status === 200) {
        yield put(milesCommentSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(closeProjectReqListErr(error.response.status));
    // }
  }
}

//enterPrise project Milestone Approved
export function* onMilesApproved(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(postAPI, `/payment/admin-direct-payment`, values);
      if (data.status === 200) {
        yield put(milestoneApprovedSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(closeProjectReqListErr(error.response.status));
    // }
  }
}

//add multiple Creator
export function* onMultipleCreator(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        postAPI,
        `/postProject/add-creator-postproject`,
        values
      );
      if (data.status === 200) {
        yield put(addMultipleCreatorSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(closeProjectReqListErr(error.response.status));
    // }
  }
}
// export function* onMultipleCreator2(data) {
//   let values = data.values;
//   try {
//     if (values) {
//       const data = yield call(
//         postAPI,
//         `/postProject/add-creator-postproject2`,
//         values
//       );
//       if (data.status === 200) {
//         yield put(addMultipleCreator2Success(data.data));
//       }
//     }
//   } catch (error) {
//     // if (error.response.status === 401) {
//     //   yield put(closeProjectReqListErr(error.response.status));
//     // }
//   }
// }

// delete pending creator

export function* ondeletePendingCreator(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        deleteAPI,
        `/postProject/delete-creator-post-project`,
        values
      );
      if (data.status === 200) {
        yield put(deletePendingCreatorSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(closeProjectReqListErr(error.response.status));
    // }
  }
}

// payment offline
export function* onPaymentOffline(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(putAPI, `/postProject/offline-payment`, values);
      if (data.status === 200) {
        yield put(paymentOfflineSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(closeProjectReqListErr(error.response.status));
    // }
  }
}

// update transactions details

export function* onDetailsTransaction(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        putAPI,
        `/postProject/admin-update-postProject`,
        values
      );
      if (data.status === 200) {
        yield put(updateTransactionsSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(closeProjectReqListErr(error.response.status));
    // }
  }
}

// update proposal
export function* onApprovedProposal(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        postAPI,
        `/milestone/admin-approved-proposal`,
        values
      );
      if (data.status === 200) {
        yield put(approvedProposalSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(closeProjectReqListErr(error.response.status));
    // }
  }
}

export function* onApprovedProposalJob(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        postAPI,
        `/postProject/add-outside-creator-into-normal-flow`,
        values
      );
      if (data.status === 200) {
        yield put(approvedProposalJobSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(closeProjectReqListErr(error.response.status));
    // }
  }
}

// request change
export function* onReqChangeProposal(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        putAPI,
        `/milestone/request-change-proposal`,
        values
      );
      if (data.status === 200) {
        yield put(requestChangeProposalSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(closeProjectReqListErr(error.response.status));
    // }
  }
}

// edit Client
export function* onEditClient(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        putAPI,
        `/postProject/client-update-postProject`,
        values
      );
      if (data.status === 200) {
        yield put(editClientSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(closeProjectReqListErr(error.response.status));
    // }
  }
}

// manager Details
export function* onManagerDetails(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        putAPI,
        `/postProject/manager-postProject`,
        values
      );
      if (data.status === 200) {
        yield put(managerDetailsSuccess(data.data));
      }
    }
  } catch (error) {
    // if (error.response.status === 401) {
    //   yield put(closeProjectReqListErr(error.response.status));
    // }
  }
}


// Inactive brief **************************************************

export function* onInActiveBrief(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,        
        `/postProject/get-postproject-inactivebrief-list?limit=10&page=${values.List}${
          values.min_budget ? `&min_budget=${Number(values.min_budget)}` : ""
        }${values.max_budget ? `&max_budget=${Number(values.max_budget)}` : ""}${
          values.deadline ? `&deadline=${Number(values.deadline)}` : ""
        }&project_name=${values.project}`
      );
      if (data.status === 200) {
        yield put(inactiveBriefSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(inactiveBriefErr(error.response.status));
    }
  }
}

// Inactive Project ***************************************************

export function* onInactiveProject(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,        
        `/postProject/get-postproject-inactiveproject-list?limit=10&page=${values.List}${
          values.min_budget ? `&min_budget=${Number(values.min_budget)}` : ""
        }${values.max_budget ? `&max_budget=${Number(values.max_budget)}` : ""}${
          values.deadline ? `&deadline=${Number(values.deadline)}` : ""
        }&project_name=${values.project}`
      );
      if (data.status === 200) {

        yield put(inactiveProjectSuccess(data.data));
      }
    }else{
      const data = yield call(
        getPublicAPI,        
        `/postProject/get-postproject-inactiveproject-list?limit=&page=`
      );
      if (data.status === 200) {
        yield put(inactiveProjectSuccess(data.data));
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(inactiveProjectErr(error.response.status));
    }
  }
}


// inactive Brief for Am Transaction ***************************************

export function* onInActBrief(data) {
  // let values = data.values;
  try {
    // if (values) {
      const data = yield call(
        getPublicAPI,        
        `/postProject/get-postproject-inactivebrief-list?limit=&page=&project_name=`
      );
      if (data.status === 200) {
        yield put(inActBriefForAmSuccess(data.data));
      }
    // }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(inActBriefForErr(error.response.status));
    }
  }
}
