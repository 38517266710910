import React, { Component } from "react";
import { connect } from "react-redux";
import {
  closeProject,
  deleteBriefList
} from "../../../store/action/transaction.action";
import Table from "./Table";
import ReactPaginate from "react-paginate";
import ErrorApi from "../Common/Error/ErrorApi";

class DeletedBrief extends Component {
  state = {
    transactionsList: 1,
    transactionActive: false,
    LoadingIs: false,
    transactionData: [],
    transactionIs: "",
    minBudget: "",
    maxBudget: "",
    deadline: "",
    totalPages: 0,
    closeProjectActive: false,
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        const {
          transactionsList,
          transactionIs,
          minBudget,
          maxBudget,
          deadline,
        } = this.state;

        const transactionData = {
          List: transactionsList,
          project: transactionIs,
          min_budget: minBudget,
          max_budget: maxBudget,
          deadline: deadline,
        };
        this.props.$deleteBriefList(transactionData);
      }
    );
  };

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    const { transactionIs, minBudget, maxBudget, deadline } = this.state;
    const transactionData = {
      List: selectedData,
      project: transactionIs,
      min_budget: minBudget,
      max_budget: maxBudget,
      deadline: deadline,
    };
    this.props.$deleteBriefList(transactionData);
  };

  handleCloseProject = (id) => {
    this.setState({
      closeProjectActive: true,
    });
    const closeProjectData = { _id: id };
    this.props.$closeProject(closeProjectData);
  };

  handleDeleteTransaction = () => {
    const { transactionsList, transactionIs } = this.state;

    this.setState({
      LoadingIs: true,
    });

    const transactionData = {
      List: transactionsList,
      project: transactionIs,
    };
    this.props.$deleteBriefList(transactionData);
  };

  componentDidMount() {
    this.handleDeleteTransaction()
  }

  handleApiCall = () => {
   this.handleDeleteTransaction();
  }

  componentWillReceiveProps(nextProps) {
    const deletedBriefData = nextProps._deleteBriefList.deletedBriefsIs;
    const closedProject = nextProps._closeProject.closeProjectIs;
    const { closeProjectActive } = this.state;
    if (deletedBriefData) {
      this.setState({
        transactionData: deletedBriefData.PostProjects,
        LoadingIs: false,
        totalPages: deletedBriefData.totalPages,
      });
    }
    if (closedProject && closeProjectActive) {
      alert(closedProject.message);
      this.setState({
        closeProjectActive: false,
      });
      this.handleActiveTransaction();
    }
  }
  render() {
    const { totalPages } = this.state;
    const transactionFunc = {
      handleOnChange: this.handleOnChange,
      handleCloseProject: this.handleCloseProject,
      handleApiCall: this.handleApiCall
    };
    return (
      <React.Fragment>
        {this.props._deleteBriefList.deleteBriefsErrIs ? (
          <ErrorApi {...this.props} />
        ) : (
          <section className="client">
            <div className="container-fluid mx-auto">
              <Table
                {...this.state}
                {...this.props}
                handleTransaction={transactionFunc}
              />

              {/******** pagination ************* */}

              {totalPages > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </div>
          </section>
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({ closeProject, deleteBriefList }) => {
  return {
    _closeProject: closeProject,
    _deleteBriefList : deleteBriefList
  };
};
const mapDispatch = (dispatchEvent) => ({
  $closeProject: (values) => dispatchEvent(closeProject(values)),
  $deleteBriefList: (values) => dispatchEvent(deleteBriefList(values)),
});
export default connect(mapState, mapDispatch)(DeletedBrief);
