import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { getPublicAPI } from '../../store/api-interface';
import Sidebar from "./Sidebar/Sidebar";
import Dashboard from "./Home/Dashboard";
import Client from "../Autorized/Client/Client";
import Artist from "../Autorized/Artist/Artist";
import Collaterals from "../Autorized/Collaterals/Collaterals";
import BackendUsers from "../Autorized/BackendUsers/BackendUsers";
import OtherDbs from "../Autorized/OtherDbs/OtherDbs";
import Header from '../Autorized/Common/Header/header';
import ReportsDetails from '../Autorized/Reports&Alerts/ReportsDetails';
import UserSignup from '../Autorized/Common/UserSignup/UserSignup';
import CollateralBasicDetails from '../Autorized/Collaterals/CollateralBasicDetails';
import ResetPwd from '../Autorized/Common/ResetPassword/ResetPwd';
import NotFound from '../../NotFound/NotFound';
import ApprovalsRoute from '../Autorized/Approvals/ApprovalsRoute';
import ChildRoute from '../Autorized/ParentCreativeField/ChildRoute';
import AllPageTranscations from '../Autorized/Transactions/AllPageTransactions';
import ProjectChildRoute from '../Autorized/Project/ProjectChildRoute';
import MilestoneRoute from "../Autorized/Milestones/MilestoneRoute";
import PackagesRoute from "../Autorized/Packages/PackagesRoute";
import CollateralSearches from "../Autorized/CollateralSearches/CollateralSearches";
import Messages from "../Autorized/Messages/Messages";
import Custom from "../Autorized/Custom/Custom";

export default class MainRoute extends Component {
  state = {
    clientsIs: [],
    pathIs : '',
    fireBaseUserIs : false,
    signupEmail : '',
    userClient : false,
    userArtist : false ,
    totalArtist : '',

    profileUpdates : false,
    artistActiveIs : false,
    creatorIs: false,
    artistApprovalIs: false,
    clientActiveIs: false,
    transactionActiveIs: false,
    transactionAllFieldActive : false,
    approvedArtistIs : false,
    artistRejected : false,
    currentCollateralActiveIs : false,
    userTabs : [],
    RoleIs: 1,
  };

  mainIndexstate = (stateObject) => {
    this.setState(stateObject);
  };

  handlepathName = (obj) =>{
    const dpathData = [
      { currentPath : "/", name : "Dashboard"},
      { currentPath : "/clients", name : "Clients"},
      { currentPath : "/artist", name : "Artist"},
      { currentPath : "/collaterals", name : "Collaterals"},
      { currentPath : "/projects", name : "Projects"},
      { currentPath : "/transactions", name : "Transactions"},
      { currentPath : "/backendusers", name : "Backend users"},
      { currentPath : "/reports", name : "Reports/Alerts"},
      { currentPath : "/otherdbs", name : "Other Dbs"},
      { currentPath : "/parentcreative", name : "Parent Creative Section"},
      { currentPath : "/resetPassword", name : "Password Reset"},
      { currentPath : "/approvals", name : "Approvals"},
      { currentPath : "/milestones", name : "AM Transactions"},
      { currentPath: "/packages", name: "Packages" },
      { currentPath : "/collateralSearches", name : "Collateral Searches"},
      { currentPath : "/messages", name : "Messages"},
      { currentPath : "/custom", name : "Custom"},
    ]

    const currentData = dpathData.find(el => el.currentPath === obj)
    if(currentData){
      this.setState({pathIs : currentData.name})
    }
  }

  handleLogout = () => {
    localStorage.removeItem("adminLogin");
    this.props.setIndexState({"adminLogin":false});
    this.props.history.push("/");
    // alert("Successfully Logout....");
  };

  handleDynamicLink = () => {
    getPublicAPI(`/backendUser/get-admin-user-data`).then((res) => {
      if (res) {
        let tabIs = res.data.tabList;
        res.data.role && localStorage.setItem("adminRole", res.data.role);
        this.setState({ userTabs: tabIs, RoleIs : res.data.role });
      }
    }).catch((err)=>{
      if(err?.response?.status === 401){
        this.handleLogout();
      }
    });
  };


  componentDidMount() {
    const{location} = this.props
    this.handlepathName(location.pathname);
    const token = localStorage.getItem("adminLogin");
    token && this.handleDynamicLink()
  }

  render() {  
    const allFunc = {
      mainIndexstate: this.mainIndexstate,
      handlepathName : this.handlepathName,
      handleLogout : this.handleLogout
    };

    const{ userTabs, RoleIs } = this.state;
    
    const mainRouteArr = [
      { path : "/clients", Component : Client , name: "Clients"},
      { path : "/artist", Component : Artist, name: "Artist" },
      { path : "/collaterals", Component : Collaterals, name: "Collaterals" },
      { path : "/projects", Component : ProjectChildRoute, name: "Projects" },
      { path : "/transactions", Component : AllPageTranscations, name: "Transactions" },
      { path : "/backendusers", Component : BackendUsers, name: "Backend users" },
      { path : "/reports", Component : ReportsDetails, name: "Reports/alerts" },
      { path : "/otherdbs", Component : OtherDbs, name: "Other DBs" },
      { path : "/parentcreative", Component : ChildRoute, name: "Parent Creative Section" },
      { path : "/approvals", Component : ApprovalsRoute, name: "Approvals" },
      { path : "/milestones", Component : MilestoneRoute, name: "AM Transactions" },
      { path: "/packages", Component: PackagesRoute, name: "Packages" },
      { path : "/collateralSearches", Component: CollateralSearches, name : "Collateral Searches"},
      { path : "/messages", Component: Messages, name : "Messages"},
      { path : "/custom", Component: Custom, name : "Custom"},
    ]


    return (
      <React.Fragment>
        <div className="container-fluid">
        <Sidebar handleClients={allFunc} {...this.props} />
        <div className="main-content">
         <Header {...this.props} {...this.state} handleClients={allFunc}/>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <Switch>                                    
                  <Route exact path="/" render={(props) => <Dashboard {...props} />}/>   
                  <Route path="/collateralsQuestions/:type/:id" render={(props) => <CollateralBasicDetails  {...props} handleClients={allFunc}/>}/>                                                                                                        
                    {
                      mainRouteArr.map((obj,index) => (
                        RoleIs === 1 ?
                        <Route path={obj.path} render={(props => {
                          const Component = obj.Component;
                          return <Component {...props} {...this.state } handleClients={allFunc} key={index}/>
                        })} key={index}/> :
                        userTabs.length > 0 && userTabs.map(item => item.tab).includes(obj.name) ? 
                        <Route path={obj.path} render={(props => {                           
                            const Component = obj.Component;
                            return <Component {...props} { ...this.state } handleClients={allFunc} key={index}/>
                          })} key={index}/> :
                        <Route exact path="/" render={(props) => <Dashboard {...props} />}/>
                      ))
                    }                  
                  <Route path="/signup/:param1/:email" render={(props) => <UserSignup {...props} {...this.state} handleClients={allFunc}/>}/>                  
                  <Route path="/resetPassword" render={(props)=> <ResetPwd {...props} handleClients={allFunc}/>}/>
                  <Route exact path="*/" render={(props) => <NotFound {...props}/>} />          
                </Switch>
              </div>
            </div>
          </div>
        </div>
        </div>
      </React.Fragment>
    );
  }
}

