import React, { Component } from "react";

export default class DashboardProject extends Component {
  render() {
    const arrIs = [
      { name: "Homepage Redesign", updateTime: "5hr", path: "project-1.jpg" },
      { name: "Travels & Time", updateTime: "3hr", path: "project-2.jpg" },
      { name: "Safari Exploration", updateTime: "10hr", path: "project-3.jpg" },
      { name: "Personal Site", updateTime: "4hr", path: "project-5.jpg" },
    ];

    return (
      <React.Fragment>
        {/*-----------------------Project card---------------------------*/}
        <div className="card-body">
          {arrIs.map((obj, index) => (
            <span key={index}>
              <div className="row align-items-center">
                <div className="col-auto">
                  <a
                    href="project-overview.html"
                    className="avatar avatar-4by3"
                  >
                    <img
                      src={require(`../../../Assets/img/avatars/projects/${obj.path}`)}
                      alt="..."
                      className="avatar-img rounded"
                    />
                  </a>
                </div>
                <div className="col ml-n2">
                  <h4 className="card-title mb-1">
                    <a href="project-overview.html">{obj.name}</a>
                  </h4>
                  <p className="card-text small text-muted">{`Updated ${obj.updateTime} ago`}</p>
                </div>
                <div className="col-auto">
                  <div className="dropdown">
                    <a
                      href="#!"
                      className="dropdown-ellipses dropdown-toggle"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fe fe-more-vertical"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a href="#!" className="dropdown-item">
                        Action
                      </a>
                      <a href="#!" className="dropdown-item">
                        Another action
                      </a>
                      <a href="#!" className="dropdown-item">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
            </span>
          ))}
        </div>
        {/*-----------------------Project card ---------------------------*/}
      </React.Fragment>
    );
  }
}
