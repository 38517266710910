import {
  LOGIN_DATA,
  LOGIN_DATA_SUCCESS,
  LOGIN_DATA_ERROR,
  RESET_PWD,
  RESET_PWD_SUCCESS,
  RESET_PWD_ERROR,
  GET_ADMIN_USER_DATA,
  GET_ADMIN_USER_DATA_SUCCESS,
  GET_ADMIN_USER_DATA_ERR,
} from "../type.action";

export const loginData = (values) => ({
  type: LOGIN_DATA,
  values,
});
export const loginDataSuccess = (values) => ({
  type: LOGIN_DATA_SUCCESS,
  values,
});
export const loginDataError = (error) => ({
  type: LOGIN_DATA_ERROR,
  error,
});

// reset password **************

export const resetPwd = (values) => ({
  type: RESET_PWD,
  values,
});
export const resetPwdSuccess = (values) => ({
  type: RESET_PWD_SUCCESS,
  values,
});
export const resetPwdError = (error) => ({
  type: RESET_PWD_ERROR,
  error,
});


// get admin_user_data  ********************

export const getAdminUserData = (values) => ({
  type:GET_ADMIN_USER_DATA,
  values,
});
export const getAdminUserDataSuccess = (values) =>({
  type:GET_ADMIN_USER_DATA_SUCCESS,
  values,
});
export const getAdminUserDataError = (values) =>({
  type:GET_ADMIN_USER_DATA_ERR,
  values,
})
