import { put, call } from "redux-saga/effects";
import { getPublicAPI, postAPI, deleteAPI } from "../api-interface";

import {
    getCustomIndustryListSuccess,
    getCustomSoftwareListSuccess,
    getCustomSpecializationListSuccess
} from "../action/cutom.action";

// Custom Software List   *****************

export function* onCustomSoftwareList(data) {

  let values = data.values;
    try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/onboarding/get-software-industry-specialization?page=${values.page}&limit=${values.limit}&name=${values.name}&type=${values.type}`,
        values
      );
      if (data.status === 200) {
        yield put(getCustomSoftwareListSuccess(data.data));
      }
    }
  } catch (error) {
    //alert("Error: ", error.response.status);
  }
}

// Custom Industry List *****************

export function* onCustomIndustryList(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/onboarding/get-software-industry-specialization?page=${values.page}&limit=10&name=${values.name}&type=${values.type}`,
        values
      );
      if (data.status === 200) {
        yield put(getCustomIndustryListSuccess(data.data));
      }
    }
  } catch (error) {
    //alert("Error: ", error.response.status);
  }
}

// Custom Specialization List *****************

export function* onCustomSpecializationList(data) {
  let values = data.values;
  try {
    if (values) {
      const data = yield call(
        getPublicAPI,
        `/onboarding/get-software-industry-specialization?page=${values.page}&limit=10&name=${values.name}&type=${values.type}`,
        values
      );
      if (data.status === 200) {
        yield put(getCustomSpecializationListSuccess(data.data));
      }
    }
  } catch (error) {
    //alert("Error: ", error.response.status);
  }
}
