import React, { Component } from "react";

import Modal from "react-modal";
import { putAPI } from "../../../store/api-interface";

const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#fff",
      borderRadius: "8px",
      maxWidth: "1200px",
      maxheight: "800px",
      border: "none",
    },
};

const divStyle = {
    width: 550,
    height: 350
}

export default class RejectReasonModal extends Component {
    state = {
        isLoading: false,
        desc: ''
    }
    
    handleOnChange = e => {
        if (e.target.value.length < 500) {
            this.setState({ desc: e.target.value })
        }
    }

    

    rejectCreator = (obj) => {

        const {first_name, last_name} = obj.user_id2;
        // alert('Feature in progress...')
        if(this.state.desc.trim().length < 1){
            alert(`Please explain why you want to reject ${first_name} ${last_name}.`)
            return
        }
        if (window.confirm(`Sure to reject ${first_name} ${last_name}.?`)) {
            this.setState({ isLoading: true });
            const projectId = this.props.match.params.id.split(":").slice(0)[1];
            const currentData = { _id: projectId, user_id: obj?.user_id2?._id, description: this.state.desc, reason: '',external:this.props?.type?true:false };
            
            // console.log(currentData)
            
          putAPI('/postProject/reject-creator-by-admin', currentData).then(() => {
            this.setState({ isLoading: false,desc:'' });
            this.props.handleFunc.handleCloseRejectModal();
          }).catch(err => {console.log(err); this.setState({ isLoading: false,desc:'' });})
        }
    }

    closeModal = () => {
        this.setState({desc:''})
        this.props.handleFunc.handleCloseRejectModal();
    }

    render(){
        const {isLoading, desc} = this.state;
        const {showRejectModal, rejectData} = this.props;    
        let {first_name, last_name} = rejectData?.user_id2;
        
        return (
            <Modal isOpen={showRejectModal} style={customStyles}>

                <div className="col-md-12" style={divStyle}> 
                    <div className="col-md-12">
                        <h1>Reason to reject {first_name} {last_name}</h1>
                    </div>
                    <div className="col-md-12 pt-2">
                        <textarea 
                            className="form-control "
                            style={{height: 220}}
                            placeholder={`Enter reason to reject ${first_name} ${last_name}`}
                            value={desc}
                            onChange={this.handleOnChange}
                        />
                    </div>
                    <div className="col-md-12 pt-4 ">
                        <button type="button" className="btn btn-danger mr-3"
                            onClick={() => this.rejectCreator(rejectData)}
                            disabled={isLoading}
                        >
                            Submit
                        </button>
                        <button type="button" className="btn btn-secondary"
                            onClick={this.closeModal}
                            disabled={isLoading}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
                
            </Modal>
        )
    }
}