import React, { Component } from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    borderRadius: "8px",
    maxWidth: "1200px",
    maxheight: "800px",
    height: "460px",
    width: "805px",
    border: "none",
  },
};

class QuestionModal extends Component {
  render() {
    const { showQuestionsModal, QuestionsModalData, handleFunc, projectDataIs } = this.props;

    const tableArr = [{ name: "#" }, { name: "Questions" }];

    return (
      <React.Fragment>
        <div>
          <Modal isOpen={showQuestionsModal} style={customStyles} contentLabel="Example Modal">
            <button
              type="button"
              className="close media-upload-modal"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleFunc.handleCloseQuestionModal}
            >
              <span aria-hidden="true" style={{ color: "black", fontSize: "32px" }}>
                &times;
              </span>
            </button>
            <div className="row">
              <div className="col">
                <div className="modal-head">
                  <p>{projectDataIs?.project_name}</p>
                  <div className="row">
                    <div className="col">
                      <h3>Ask Questions</h3>
                      <div className="mt-3 text-left">
                          <div className={`badge ${this.props.questionStatus?'badge-soft-info':'badge-warning'}`}>
                            <div>{this.props?.questionStatus?'Questions Approved':'Approval Pending'}</div>
                          </div>
                        </div>
                      
                    </div>
                  </div>
                  <hr className="my-3" />
                  <div className="row">
                    <div className="col custom-modal">
                      <div className="container-fluid mx-auto">
                        <div className="table-responsive overflow-content user-work-content">
                          <table className="table table-sm card-table">
                            {/*----------------- table head --------------------*/}
                            <thead>
                              <tr>
                                {tableArr.map((obj, index) => (
                                  <th className="text-muted sort" key={index}>
                                    {obj?.name}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            {/*----------------- table head --------------------*/}
                            <tbody className="list">
                              {QuestionsModalData && QuestionsModalData.length > 0 ? (
                                QuestionsModalData.map((item, index) => (
                                  <tr key={index}>
                                    <td className="orders-order">{index+1}</td>
                                    <td className="orders-order">{item}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td>No data Found..</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export default QuestionModal;
