import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import {
//   pendingCreatorApproval,
//   artistUpdateInfo,
// } from "../../../../store/action/clientList.action";
// import {
//   pendingCollateral,
//   deleteCollateralList,
// } from "../../../../store/action/collateralList.action";
// import {
//   pendingProjectList,
//   projectReviewList,
// } from "../../../../store/action/projectList.action";
// import {
//   callBackRequestList,
//   transactionApprovalList,
//   inCompleteBriefList,
//   creatorRejectsBriefList,
//   closeBriefRequestList,
//   closeProjectRequestList,
// } from "../../../../store/action/transaction.action";
// import { clientRejectsCreatorList } from "../../../../store/action/clientList.action";
import ErrorApi from "../../Common/Error/ErrorApi";
import { getPublicAPI } from "../../../../store/api-interface";

class ApprovalHeaderTab extends Component {
  
  state = {
    headerCount:{},
    totalCreatorIs: '',
    totalPendingCollateral: '',
    totalCraetorProfile: '',
    totalProjectUpload: '',
    totalProjectReview: '',
    totalCallBack: '',
    totalTransaction: '',
    totalCollateralDelete: '',
    totalIncompleteBrief: '',
    totalCreatorRejectsBrief: '',
    totalRejectsCreator: '',
  };

  // handleAppPendingCreator = () => {
  //   let artist = {
  //     page: 1,
  //     firstname: "",
  //     lastname: "",
  //     username: "",
  //     email: "",
  //     sortData: 1,
  //   };
  //   this.props.$pendingCreatorApproval(artist);
  // };

  // handlePendingCollateral = () => {
  //   const Data = { page: 1, sortData: 1 };
  //   this.props.$pendingCollateral(Data);
  // };

  // handleCreatorProfile = () => {
  //   let artist = {
  //     page: 1,
  //     firstname: "",
  //     lastname: "",
  //     username: "",
  //     email: "",
  //     sort: 1,
  //   };
  //   this.props.$artistUpdateInfo(artist);
  // };

  // handleProjectUpload = () => {
  //   const data = { page: 1, sortIs: 1 };
  //   this.props.$pendingProjectList(data);
  // };

  // handleProjectReview = () => {
  //   const dataIs = { pageIs: 1, sortIs: 1 };
  //   this.props.$projectReviewList(dataIs);
  // };

  // handleCallBackRequest = () => {
  //   const currentData = { pageIs: 1, sortIs: 1 };
  //   this.props.$callBackRequestList(currentData);
  // };

  // handlePendingTransaction = () => {
  //   const currentData = { pageIs: 1, sortIs: 1 };
  //   this.props.$transactionApprovalList(currentData);
  // };

  // handleCollateralDeletion = () => {
  //   const dataIs = { page: 1, sortIs: 1 };
  //   this.props.$deleteCollateralList(dataIs);
  // };

  // handleInCompleteBrief = () => {
  //   const currentData = { page: 1, sortIs: 1 };
  //   this.props.$inCompleteBriefList(currentData);
  // };

  // handleCreatorRejectsBrief = () => {
  //   const currentData = { page: 1, sortIs: 1 };
  //   this.props.$creatorRejectsBriefList(currentData);
  // };

  // handleClientRejectsCreator = () => {
  //   const currentData = { page: 1, sortIs: 1 };
  //   this.props.$clientRejectsCreatorList(currentData);
  // };

  // handleBriefCloseRequest = () => {
  //   const currentData = { page: 1, sortIs: 1 };
  //   this.props.$closeBriefRequestList(currentData);
  // };

  // handleProjectCloseReq = () => {
  //   const currentData = { page: 1, sortIs: 1 };
  //   this.props.$closeProjectRequestList(currentData);
  // };

  handleCountApi =async () => {
      // this.handleAppPendingCreator();
      // this.handlePendingCollateral();
      // this.handleCreatorProfile();
      // this.handleProjectUpload();
      // this.handleProjectReview();
      // this.handleCallBackRequest();
      // this.handlePendingTransaction();
      // this.handleCollateralDeletion();
      // this.handleInCompleteBrief();
      // this.handleCreatorRejectsBrief();
      // this.handleClientRejectsCreator();
      // this.handleBriefCloseRequest();
      // this.handleProjectCloseReq();
      try{
        const data = await getPublicAPI('/postProject/get-approval-count-admin');
        // const response = data?.data
        this.setState({headerCount:data?.data})
      }catch(err){
        console.log(err)
      }
  };

  componentDidMount() {
    this.handleCountApi();
  }

  componentWillReceiveProps(nextProps) {
    const artistListApproval =
      nextProps._pendingCreatorApproval?.pendingListApprIs;
    const pendingCollateral = nextProps?._pendingCollateral?.pendingCollateral;
    const artistListData = nextProps?._artistUpdateInfo?.updateInfo;
    const PendingProjectData = nextProps?._pendingProjectList?.pendingList;
    const ProjectReviewIs = nextProps?._projectReviewList?.projectReviewApproval;
    const callBackRequestIs = nextProps?._callBackRequestList?.callBackRequestIs;
    const pendingTransaction =
      nextProps._transactionApprovalList?.transactionApprIs;
    const deletedDataIs = nextProps?._deleteCollateralList?.deleteCollListIs;
    const ListIs = nextProps?._inCompleteBriefList?.inCompleteBriefIs;
    const RejectionListIs =
      nextProps._creatorRejectsBriefList?.rejectsCreatorListIs;
    const clientRejectList =
      nextProps._clientRejectsCreatorList?.clientRejectsCreatorIs;
    const closeBriefRequest =
      nextProps._closeBriefRequestList?.closedBriefRequestIs;
    const closeProjectReq = nextProps?._closeProjectRequestList?.projectCloseReq;
    
    if (artistListApproval) {
      this.setState({
        totalCreatorIs: artistListApproval?.totalUsers,
      });
    }
    if (pendingCollateral) {
      this.setState({
        totalPendingCollateral: pendingCollateral?.totalUsers,
      });
    }
    if (artistListData) {
      this.setState({
        totalCraetorProfile: artistListData?.totalUsers,
      });
    }
    if (PendingProjectData) {
      this.setState({ totalProjectUpload: PendingProjectData?.totalUsers });
    }
    if (ProjectReviewIs) {
      this.setState({ totalProjectReview: ProjectReviewIs?.totalUsers });
    }
    if (callBackRequestIs) {
      this.setState({ totalCallBack: callBackRequestIs?.totalUsers });
    }
    if (pendingTransaction) {
      this.setState({ totalTransaction: pendingTransaction?.totalUsers });
    }
    if (deletedDataIs) {
      this.setState({
        totalCollateralDelete: deletedDataIs?.totalUsers,
      });
    }
    if (ListIs) {
      this.setState({
        totalIncompleteBrief: ListIs?.totalUsers,
      });
    }
    if (RejectionListIs) {
      this.setState({
        totalCreatorRejectsBrief: RejectionListIs?.totalUsers,
      });
    }
    if (clientRejectList) {
      this.setState({
        totalRejectsCreator: clientRejectList?.totalUsers,
      });
    }
    if (closeBriefRequest) {
      this.setState({
        totalCloseBriefRequest: closeBriefRequest?.totalUsers,
      });
    }
    if (closeProjectReq) {
      this.setState({
        totalcloseProjectReq: closeProjectReq?.totalUsers,
      });
    }
  }

  render() {
    const { pathIs, location } = this.props;
    const artistId = location.pathname.split("/:").pop();

    const currentCollateralTab = (path) => {
      const currentPath = location.pathname;
      return currentPath === path;
    };


    const {
      totalCreatorIs,
      totalPendingCollateral,
      totalCraetorProfile,
      totalProjectUpload,
      totalProjectReview,
      totalCallBack,
      totalTransaction,
      totalCollateralDelete,
      totalIncompleteBrief,
      totalCreatorRejectsBrief,
      totalRejectsCreator,
      totalCloseBriefRequest,
      totalcloseProjectReq,
      headerCount
    } = this.state;

    const approvaltabArr = [
      {
        name: "Creator",
        path: `/approvals`,
        activeBorder: "/approvals",
        totalUser:this.props?.location.pathname==='/approvals'?totalCreatorIs:headerCount?.artistPendingUserList,
      },
      {
        name: "Collateral",
        path: `/approvals/PendingCollateral`,
        activeBorder: "/approvals/PendingCollateral",
        totalUser:this.props?.location.pathname==='/approvals/PendingCollateral'?totalPendingCollateral:headerCount?.pendingUserCollateral,
      },
      {
        name: "Creator Profile",
        path: `/approvals/PendingCreatorProfile`,
        activeBorder: "/approvals/PendingCreatorProfile",
        totalUser:this.props?.location.pathname==='/approvals/PendingCreatorProfile'? totalCraetorProfile:headerCount?.artistUserList,
      },
      {
        name: "Project Upload",
        path: `/approvals/ProjectUpload`,
        activeBorder: "/approvals/ProjectUpload",
        totalUser: this.props?.location.pathname==='/approvals/ProjectUpload'?totalProjectUpload:headerCount?.pendingUserProject,
      },
      {
        name: "Project Review",
        path: `/approvals/ProjectReview`,
        activeBorder: "/approvals/ProjectReview",
        totalUser: this.props?.location.pathname==='/approvals/ProjectReview'?totalProjectReview:headerCount?.projectReview,
      },
      {
        name: "Call Back Request",
        path: `/approvals/CallBackRequest`,
        activeBorder: "/approvals/CallBackRequest",
        totalUser:this.props?.location.pathname==='/approvals/CallBackRequest'?totalCallBack:headerCount?.callBackeRequest,
      },
      {
        name: "Transaction/Brief",
        path: `/approvals/TransactionApproval`,
        activeBorder: "/approvals/TransactionApproval",
        totalUser:this.props?.location.pathname==='/approvals/TransactionApproval'?totalTransaction:headerCount?.transactionBrief,
      },
      {
        name: "Collateral Deletion",
        path: `/approvals/CollateralDeletion`,
        activeBorder: "/approvals/CollateralDeletion",
        totalUser:this.props?.location.pathname==='/approvals/CollateralDeletion'?totalCollateralDelete:headerCount?.deletedusercollateral,
      },
      {
        name: "Incomplete Brief",
        path: `/approvals/IncompleteBrief`,
        activeBorder: "/approvals/IncompleteBrief",
        totalUser:this.props?.location.pathname==='/approvals/IncompleteBrief'? totalIncompleteBrief:headerCount?.incompleteTranctionData,
      },
      {
        name: "Creator Rejects Brief",
        path: `/approvals/CreatorRejectsBrief`,
        activeBorder: "/approvals/CreatorRejectsBrief",
        totalUser:this.props?.location.pathname==='/approvals/CreatorRejectsBrief'? totalCreatorRejectsBrief:headerCount?.creatorRejectBrief,
      },
      {
        name: "Client Rejects Creator",
        path: `/approvals/ClientRejectsCreator`,
        activeBorder: "/approvals/ClientRejectsCreator",
        totalUser:this.props?.location.pathname==='/approvals/ClientRejectsCreator' ?totalRejectsCreator:headerCount?.clientRejectBrief,
      },
      {
        name: "Brief close Request",
        path: `/approvals/briefCloseRequest`,
        activeBorder: "/approvals/briefCloseRequest",
        totalUser:this.props?.location.pathname==='/approvals/briefCloseRequest'? totalCloseBriefRequest:headerCount?.briefCloseRequest,
      },
      {
        name: "Project Close Request",
        path: `/approvals/projectCloseRequest`,
        activeBorder: "/approvals/projectCloseRequest",
        totalUser:this.props?.location.pathname==='/approvals/projectCloseRequest'? totalcloseProjectReq:headerCount?.projectCloseRequest,
      },
    ];

    return (
      <React.Fragment>
        {this.props?._pendingCreatorApproval?.pendingListAppErrIs ||
        this.props?._pendingCollateral?.pendingCollateralListErrIs ||
        this.props?._pendingProjectList?.pendingListErr ||
        this.props?._projectReviewList?.projectReviewErrIs ||
        this.props?._callBackRequestList?.callBackErrIs ||
        this.props?._transactionApprovalList?.transactionAppListErrIs ? (
          <ErrorApi {...this.props} />
        ) : (
          <React.Fragment>
            {pathIs === "Approvals" &&
              (artistId === "/approvals" ||
                artistId === "/approvals/PendingCollateral" ||
                artistId === "/approvals/PendingCreatorProfile" ||
                artistId === "/approvals/ProjectUpload" ||
                artistId === "/approvals/ProjectReview" ||
                artistId === "/approvals/TransactionApproval" ||
                artistId === "/approvals/CallBackRequest" ||
                artistId === "/approvals/CollateralDeletion" ||
                artistId === "/approvals/IncompleteBrief" ||
                artistId === "/approvals/CreatorRejectsBrief" ||
                artistId === "/approvals/ClientRejectsCreator" ||
                artistId === "/approvals/briefCloseRequest" ||
                artistId === "/approvals/projectCloseRequest") && (
                <ul className="nav nav-tabs nav-overflow header-tabs custom-scroll">
                  {approvaltabArr.map((obj, index) => (
                    <li className="nav-item" key={index}>
                      <Link
                        to={obj.path}
                        className={`nav-link ${
                          currentCollateralTab(`${obj.activeBorder}`)
                            ? "active"
                            : ""
                        }`}
                        // onClick={this.handleCountApi}
                      >
                        {obj.name}
                        <span className="badge badge-pill badge-soft-secondary ml-2">
                          {obj.totalUser}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({
  pendingCreatorApproval,
  pendingCollateral,
  artistUpdateInfo,
  pendingProjectList,
  projectReviewList,
  callBackRequestList,
  transactionApprovalList,
  deleteCollateralList,
  inCompleteBriefList,
  creatorRejectsBriefList,
  clientRejectsCreatorList,
  closeBriefRequestList,
  closeProjectRequestList,
}) => {
  return {
    _pendingCreatorApproval: pendingCreatorApproval,
    _pendingCollateral: pendingCollateral,
    _artistUpdateInfo: artistUpdateInfo,
    _pendingProjectList: pendingProjectList,
    _projectReviewList: projectReviewList,
    _callBackRequestList: callBackRequestList,
    _transactionApprovalList: transactionApprovalList,
    _deleteCollateralList: deleteCollateralList,
    _inCompleteBriefList: inCompleteBriefList,
    _creatorRejectsBriefList: creatorRejectsBriefList,
    _clientRejectsCreatorList: clientRejectsCreatorList,
    _closeBriefRequestList: closeBriefRequestList,
    _closeProjectRequestList: closeProjectRequestList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  // $pendingCreatorApproval: (values) =>
  //   dispatchEvent(pendingCreatorApproval(values)),
  // $pendingCollateral: (values) => dispatchEvent(pendingCollateral(values)),
  // $artistUpdateInfo: (values) => dispatchEvent(artistUpdateInfo(values)),
  // $pendingProjectList: (values) => dispatchEvent(pendingProjectList(values)),
  // $projectReviewList: (values) => dispatchEvent(projectReviewList(values)),
  // $callBackRequestList: (values) => dispatchEvent(callBackRequestList(values)),
  // $transactionApprovalList: (values) =>
  //   dispatchEvent(transactionApprovalList(values)),
  // $deleteCollateralList: (values) =>
  //   dispatchEvent(deleteCollateralList(values)),
  // $inCompleteBriefList: (values) => dispatchEvent(inCompleteBriefList(values)),
  // $creatorRejectsBriefList: (values) =>
  //   dispatchEvent(creatorRejectsBriefList(values)),
  // $clientRejectsCreatorList: (values) =>
  //   dispatchEvent(clientRejectsCreatorList(values)),
  // $closeBriefRequestList: (values) =>
  //   dispatchEvent(closeBriefRequestList(values)),
  // $closeProjectRequestList: (values) =>
  //   dispatchEvent(closeProjectRequestList(values)),
});
export default connect(mapState, mapDispatch)(ApprovalHeaderTab);
