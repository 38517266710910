import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import {
  getArtistData,
  artistDetails,
  artistProfile,
  locationUser,
} from "../../../store/action/clientList.action";
import { userProfileBaseUrl, WebsiteUrl } from "../../../Utils/BaseUrl";
import LoaderSmall from "../Common/Loader/LoaderSmall";
import ArtistDetailVerifiedStatus from "./ArtistDetailVerifiedStatus";
import Moment from "moment";
import ErrorApi from "../Common/Error/ErrorApi";

class ArtistDetailsForm extends Component {
  state = {
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    phoneNumber: "",
    location: "",
    birthDate: "",
    gender: "",
    secondaryEmail: "",
    profileImage: "",
    about: "",
    emailStatus: "",
    phoneStatus: "",
    artistId: "",
    startDate: new Date(),
    LoadingIs: false,
    imageLoader: false,
    allDataActive: false,
    editIs: true,
    detailsUpdateInfo: 0,
    LoactionActive: false,

    isCreatorApproved:false,
    errors: { firstName: "", lastName: "", userName: "",email:"",phoneNumber:"" },
  };

  handleLocationList = (value) => {
    this.setState({
      LoactionActive: true,
    });
    this.props.$locationUser(value);
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.validateForm(name, value);
    if (name === "location") {
      this.setState({
        [name]: value,
      });
      this.handleLocationList(value);
    } else {
      this.setState({ [name]: value });
    }
  };

  handleChange = (date) => {
    this.setState({
      startDate: date ? date : "",
      birthDate: date ? date : "",
    });
  };

  handleLocationSelected = (value) => {
    const { LoactionActive } = this.state;
    this.setState({
      LoactionActive: !LoactionActive,
      location: value,
    });
  };

  handleUploadImage = (e) => {
    let artistProfile = e.target.files[0];
    if (artistProfile) {
      this.setState({
        imageLoader: true,
      });
      const formData = new FormData();
      formData.append("userImages", artistProfile);
      this.props.$artistProfile(formData);
    }
  };

  handledropdown = (e) => {
    if (e.target.name !== "location" || e.target.name === "undefined") {
      this.setState({
        LoactionActive: false,
      });
    }
  };

  handleEditOnClick = () => {
    this.setState({
      editIs: false,
    });
  };

  validateForm(name, value) {
    const { errors } = this.state;
    switch (name) {
      case "firstName":
        errors.firstName = !value.length ? "* Required Field " : "";
        break;
      case "lastName":
        errors.lastName = !value.length ? "* Required Field " : "";
        break;
      case "userName":
        errors.userName = !value.length ? "* Required Field " : "";
        break;
      case "email":
        errors.email = !/^\S+@\S+\.\S+$/.test(value)? "* Required Field " : "";
        break;
      case "phoneNumber":
        errors.phoneNumber = value.length<10 ? "* Required Field " : "";
          break;
      default:
        break;
    }

    return !errors.firstName && !errors.lastName && !errors.userName &&!errors.email&&!errors.phoneNumber;
  }

  handleUpdateOnClick = () => {
    if (this.validateForm()) {
      this.setState({
        LoadingIs: true,
      });
      const {
        firstName,
        lastName,
        userName,
        email,
        location,
        phoneNumber,
        birthDate,
        gender,
        secondaryEmail,
        artistId,
        phoneStatus,
        emailStatus,
        about,
        profileImage,
      } = this.state;

      let dateIs = Moment(birthDate).format("DD-MM-YYYY");

      const updateData = {
        _id: artistId,
        first_name: firstName,
        last_name: lastName,
        username: userName,
        email: email,
        phone_no: phoneNumber,
        location: location,
        dob: dateIs && dateIs !== "Invalid date" ? dateIs : "",
        gender: gender,
        secondary_email: secondaryEmail,
        phone_status: Number(phoneStatus),
        email_status: Number(emailStatus),
        bio: about,
        profile_image: profileImage,
      };
      this.props.$artistDetails(updateData);
    }
  };

  handleGetArtist = (obj) => {
    this.setState({
      allDataActive: true,
    });
    this.props.$getArtistData(obj);
  };

  componentDidMount() {
    const artistParamsId = this.props.match.params.id.split(":").pop();
    this.handleGetArtist(artistParamsId);
  }

  componentWillReceiveProps(nextProps) {
    const { LoadingIs, artistId, imageLoader } = this.state;
    const creatorData = nextProps._getArtistData.getCreatorData;
    const dataIs = creatorData.userData;
    const detailsData = nextProps._artistDetails.artistDetails;
    const detailsProfile = nextProps._artistProfile.artistDetailProfile;
    // if (creatorData && creatorData.status === "1" && allDataActive) {
    if(this.props?._getArtistData?.getCreatorData !== creatorData){
      this.setState({
        firstName: dataIs.first_name,
        lastName: dataIs.last_name,
        userName: dataIs.username,
        email: dataIs.email,
        phoneNumber: dataIs.phone_no,
        location: dataIs.location,
        birthDate:
          dataIs.dob && dataIs.dob !== "Invalid date" ? dataIs.dob : "",
        gender: dataIs.gender,
        secondaryEmail: dataIs.secondary_email,
        profileImage: dataIs.profile_image,
        about: dataIs.bio,
        emailStatus: dataIs.email_status ? dataIs.email_status.toString() : "",
        phoneStatus: dataIs.phone_status ? dataIs.phone_status.toString() : "",
        artistId: dataIs._id,
        detailsUpdateInfo: dataIs.update_profile_status,
        allDataActive: false,
        isCreatorApproved:dataIs && (dataIs.review_status == 2 || dataIs.review_status==3) && (dataIs.status == 1 || dataIs.status == 3)
      });
    }
    if (detailsData.status === "1" && LoadingIs) {
      this.setState({
        LoadingIs: false,
      });
      this.props.history.push("/artist/artistCollateral/:" + artistId);
    }
    if (detailsProfile.status === "1" && imageLoader) {
      this.setState({
        profileImage: detailsProfile.name,
        imageLoader: false,
      });
    }
  }

  render() {
    const {
      firstName,
      lastName,
      userName,
      email,
      phoneNumber,
      location,
      birthDate,
      gender,
      secondaryEmail,
      editIs,
      profileImage,
      about,
      LoadingIs,
      imageLoader,
      errors,
      LoactionActive,
      isCreatorApproved
    } = this.state;

    const artistDetailFunc = {
      handleOnChange: this.handleOnChange,
    };

    const contentArr = [
      { name: "firstName", value: firstName, label: "First name" },
      { name: "lastName", value: lastName, label: "Last name" },
      { name: "email", value: email, label: "Email address" },
      { name: "userName", value: userName, label: "User name" },
      { name: "phoneNumber", value: phoneNumber, label: "Phone" },
      // { name: "location", value: location, label: "Location" },
      {
        name: "secondaryEmail",
        value: secondaryEmail,
        label: "Secondary Email",
      },
    ];

    const { _locationUser } = this.props;
    const locationData = _locationUser.locationUserIs.data;

    return (
      <React.Fragment>
        {this.props._getArtistData.getArtistError ? (
          <ErrorApi {...this.props} />
        ) : (
          <section className="container" onClick={this.handledropdown}>
            {/* <form className="mb-4"> */}
            {/*------------------Artist Profile -------------------------*/}

            <div className="row justify-content-between align-items-center">
              <div className="col">
                <div className="row align-items-center">
                  <div className="col-auto">
                    <div className="avatar">
                      <img
                        className="avatar-img rounded-circle"
                        src={
                          profileImage
                            ? userProfileBaseUrl + profileImage
                            : require("../../../Assets/img/avatars/profiles/avatar-1.jpg")
                        }
                        alt="..."
                      />
                    </div>
                  </div>
                  <div className="col ml-n2">
                    <h4 className="mb-1">Your avatar</h4>
                    <small className="text-muted">PNG, JPG or JPEG only.</small>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <input
                  type="file"
                  ref={(ref) => (this.upload = ref)}
                  accept="jpg,jpeg.png"
                  style={{ display: "none" }}
                  onChange={this.handleUploadImage}
                />
                {!editIs && <button
                  className="btn btn-sm btn-primary"
                  onClick={() => this.upload.click()}
                >
                  {imageLoader ? "Uploading....." : "Upload"}
                </button>}
              </div>
            </div>

            {/*------------------Artist Profile -------------------------*/}

            <hr className="my-5" />

            <div className="row">
              {contentArr.map((obj, index) => (
                <React.Fragment key={index}>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>{obj.label}</label>
                      <input
                        type="text"
                        className={`form-control 
                      ${
                        ((obj.name === "firstName" && errors.firstName) ||
                          (obj.name === "lastName" && errors.lastName) ||
                          (obj.name === "userName" && errors.userName)||
                          (obj.name === "email" && errors.email) ||
                          (obj.name === "phoneNumber" && errors.phoneNumber) 
                          ) &&
                        `error-show`
                      }
                      `}
                        name={obj.name}
                        value={obj.value}
                        onChange={this.handleOnChange}
                        disabled={
                          !editIs
                            ? ""
                            : "disabled"
                        }
                      />
                      {((errors.firstName && obj.name === "firstName") ||
                        (errors.lastName && obj.name === "lastName") ||
                        (errors.userName && obj.name === "userName") ||
                        (errors.email && obj.name === "email") ||
                        (errors.phoneNumber && obj.name === "phoneNumber") 
                        ) && (
                        <span className="error">{`${
                          errors.firstName || errors.lastName || errors.userName||errors.email||errors.phoneNumber
                        }`}</span>
                      )}
                    </div>
                  </div>
                  {obj.name === "phoneNumber" && (
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>Dob</label>
                        <DatePicker
                          className="form-control "
                          selected={this.state.startDate}
                          onChange={this.handleChange}
                          name="birthDate"
                          value={birthDate}
                          placeholder="Date of birth"
                          autoComplete="off"
                          disabled={editIs ? "disabled" : ""}
                          dateFormat="dd-MM-yyyy"
                        />
                      </div>
                    </div>
                  )}
                  {obj.name === "phoneNumber" && (
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>Gender</label>
                        <select
                          className="form-control"
                          name="gender"
                          value={gender}
                          onChange={this.handleOnChange}
                          disabled={editIs ? "disabled" : ""}
                        >
                          <option>Male</option>
                          <option>Female</option>
                        </select>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
              {/*------------------------location-----------------------------*/}

              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>Location</label>
                  <input
                    type="text"
                    className="form-control"
                    name="location"
                    value={location}
                    onChange={this.handleOnChange}
                    autoComplete="off"
                    disabled={editIs ? "disabled" : ""}
                    style={{ position: "relative" }}
                  />
                  {locationData && LoactionActive && (
                    <ul
                      className="list-group list-group"
                      style={{
                        position: "absolute",
                        zIndex: "99",
                        background: "white",
                        width: "95%",
                        maxHeight: "200px",
                        overflow: "scroll",
                      }}
                    >
                      {locationData &&
                        locationData.map((obj, index) => (
                          <li
                            className="list-group-item"
                            key={index}
                            onClick={() =>
                              this.handleLocationSelected(`${obj.name}`)
                            }
                          >
                            {obj.name}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              </div>
              {/*------------------------location-----------------------------*/}
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label>About</label>
                  <textarea
                    type="text"
                    className="form-control"
                    data-toggle="flatpickr"
                    name="about"
                    value={about}
                    onChange={this.handleOnChange}
                    disabled={editIs ? "disabled" : ""}
                  ></textarea>
                </div>
              </div>
            </div>
            <hr className="mt-4 mb-5" />

            {/*---------------------------phone email verified ------------------------*/}

            <ArtistDetailVerifiedStatus
              {...this.props}
              {...this.state}
              handleFunc={artistDetailFunc}
            />

            <hr className="mt-4 mb-5" />
            <div className="d-flex justify-content-start">

            {/* <div className="col-md-12"> */}
              <button
                className="btn btn-primary"
                onClick={
                  editIs ? this.handleEditOnClick : this.handleUpdateOnClick
                }
                >
                {editIs ? "Edit" : LoadingIs ? <LoaderSmall /> : "Update"}
              </button>
            {/* </div> */}
            {/* <div className="col-12 col-md-12"> */}
            {isCreatorApproved && <a href={`${WebsiteUrl}creator/${userName}`} target="_blank">

              <button
                className="btn btn-primary mx-4"
                >
               View Public Profile
              </button>
                  </a>}
                </div>
            {/* </div> */}
            {/* </form> */}
          </section>
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({
  getArtistData,
  artistDetails,
  artistProfile,
  locationUser,
}) => {
  return {
    _getArtistData: getArtistData,
    _artistDetails: artistDetails,
    _artistProfile: artistProfile,
    _locationUser: locationUser,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $getArtistData: (values) => dispatchEvent(getArtistData(values)),
  $artistDetails: (values) => dispatchEvent(artistDetails(values)),
  $artistProfile: (values) => dispatchEvent(artistProfile(values)),
  $locationUser: (values) => dispatchEvent(locationUser(values)),
});
export default connect(mapState, mapDispatch)(ArtistDetailsForm);
