import React,{ Component } from 'react';
import { Switch, Route } from "react-router-dom";
import CreativeField from './CreativeField';
import CreativeFieldForms from '../../Autorized/ParentCreativeField/CreativeFieldForms';

export default class ChildRoute extends Component{

    componentDidMount(){
        this.props.handleClients.handlepathName("/parentcreative");
    }
    render(){
        return(
            <React.Fragment>
                <Switch>
                    <Route path="/parentcreative/:type/:id/:name" render={(props) => <CreativeFieldForms {...props} />}/>
                    <CreativeField {...this.props}/>
                </Switch>
            </React.Fragment>
        )
    }
}