import React, { Component } from "react";
import { connect } from "react-redux";
import { checkStatus } from "../../../../store/action/clientList.action";
import LoaderSmall from "../Loader/LoaderSmall";

class CheckStatus extends Component {
  state = {
    clientStatusIs: "",
    statusEmail: "",
    LoadingIs: false,
    errorIs: { statusEmail: "" },
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    this.validation(name, value);
  };

  validation(name, value) {
    const { errorIs } = this.state;
    switch (name) {
      case "statusEmail":
        errorIs.statusEmail = !value.length ? "* Required Field " : "";
        break;
      default:
        break;
    }
    return !errorIs.statusEmail ;
  }

  validateForm() {
    const { statusEmail, errorIs } = this.state;
    errorIs.statusEmail = !statusEmail.length ? "* Required Field " : "";

    return !errorIs.statusEmail;
  }

  handleCreateArtistClient = (e) => {
    const { handleClients, $checkStatus } = this.props;
    e.preventDefault();    
    if (this.validateForm()) {
      this.setState({ LoadingIs: true });
      const { statusEmail } = this.state;
      $checkStatus(statusEmail);
      handleClients.mainIndexstate({ signupEmail: statusEmail });
    }else {
      this.setState({ LoadingIs: false });
    }
  };

  resetField = () => {
    this.setState({
      statusEmail: "",
    });
  };

  handleStatusIs = (obj) => {
    const { pathIs, location } = this.props;
    const { statusEmail } = this.state;
    const paramIs = location.pathname.split("/").pop();
    if (obj === "0") {
      this.props.handleClients.mainIndexstate({
        userArtist: true,
        fireBaseUserIs: false,
      });
      this.props.history.push(`/signup/:` + paramIs + `/:` + statusEmail);
      this.setState({ LoadingIs: false });
    } else if (obj === "1") {
      alert(`This ${pathIs} is already exist....`);
      this.setState({ LoadingIs: false });
    } else if (obj === "2") {
      this.props.handleClients.mainIndexstate({
        fireBaseUserIs: true,
        userArtist: true,
      });
      this.props.history.push(`/signup/:` + paramIs + `/:` + statusEmail);
      this.setState({ LoadingIs: false });
    } else if (obj === "3") {
      alert(`This ${pathIs} is already exist...`);
      this.setState({ LoadingIs: false });
    }

    this.resetField();
  };

  componentWillReceiveProps(nextProps) {
    const { LoadingIs } = this.state;
    const StattusData = nextProps._checkStatus.clientStatus;
    if (StattusData && LoadingIs) {
      this.handleStatusIs(StattusData);
    }
  }

  render() {
    const { pathIs } = this.props;
    const { LoadingIs, statusEmail, errorIs } = this.state;
    return (
      <React.Fragment>
        <div
          className="create-client ml-auto"
          style={{ justifyContent: "flex-end" }}
        >
          <form
            className="form-inline my-2 my-lg-0"
            onSubmit={this.handleCreateArtistClient}
          >
            <input
              className={`form-control mr-sm-2 ${
                errorIs.statusEmail ? "error-show" : ""
              }`}
              type="email"
              placeholder="Enter email"
              aria-label="Search"            
              autoComplete="off"
              name="statusEmail"
              value={statusEmail}
              onChange={this.handleOnChange}
            />
            <button className="btn btn-primary my-2 my-sm-0 mt-0" type="submit">
              {pathIs === "Artist" ? (
                LoadingIs ? (
                  <LoaderSmall />
                ) : (
                  "Create Artist"
                )
              ) : LoadingIs ? (
                <LoaderSmall />
              ) : (
                "Create Client"
              )}
            </button>
          </form>
          {/*---------------------error msg ---------------------------*/}
          {errorIs.statusEmail && (
            <span className="error">{errorIs.statusEmail}</span>
          )}
          {/*---------------------error msg ---------------------------*/}
        </div>
      </React.Fragment>
    );
  }
}

const mapState = ({ checkStatus }) => {
  return {
    _checkStatus: checkStatus,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $checkStatus: (values) => dispatchEvent(checkStatus(values)),
});
export default connect(
  mapState,
  mapDispatch
)(CheckStatus);
