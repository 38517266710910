import React, { Component } from "react";

export default class CollateralHeadingSubtext extends Component {
  render() {
    const currentVal = this.props;
    const { handlefunc } = this.props;

    const contentArr = [
      {
        name: "budgetHeading",
        value: currentVal.budgetHeading,
        label: "Budget (Heading)",
      },
      {
        name: "budgetSubtext",
        value: currentVal.budgetSubtext,
        label: "Budget (Subtext)",
      },
      {
        name: "deadlineHeading",
        value: currentVal.deadlineHeading,
        label: "Deadline (Heading)",
        line: "hr",
      },
      {
        name: "deadlineSubtext",
        value: currentVal.deadlineSubtext,
        label: "Deadline (Subtext)",
        line: "hr",
      },
      {
        name: "projectNameHeading",
        value: currentVal.projectNameHeading,
        label: "Project Name (Heading)",
      },
      {
        name: "projectNameSubtext",
        value: currentVal.projectNameSubtext,
        label: "Project Name (Subtext)",
      },
      {
        name: "projectNamePlaceholder",
        value: currentVal.projectNamePlaceholder,
        label: "Project Name Placeholder",
        space: "blank",
      },
      {
        name: "projectDescriptionHeading",
        value: currentVal.projectDescriptionHeading,
        label: "Project Description (Heading)",
      },
      {
        name: "projectDescriptionSubtext",
        value: currentVal.projectDescriptionSubtext,
        label: "Project Description (Subtext)",
      },
      {
        name: "projectdescPlaceholder",
        value: currentVal.projectdescPlaceholder,
        label: "Project Description Placeholder",
        space: "blank",
      },
      {
        name: "projectRefernceLinkHeading",
        value: currentVal.projectRefernceLinkHeading,
        label: "Project Reference Link (Heading)",
      },
      {
        name: "projectReferenceLinkSubtext",
        value: currentVal.projectReferenceLinkSubtext,
        label: "Project Reference Link (Subtext)",
      },
      {
        name: "projectReferencefilesHeading",
        value: currentVal.projectReferencefilesHeading,
        label: "Project Reference Files (Heading)",
        line: "hr",
      },
      {
        name: "projectReferencefilesSubtext",
        value: currentVal.projectReferencefilesSubtext,
        label: "Project Reference Files (Subtext)",
        line: "hr",
      },
      {
        name: "companyNameHeading",
        value: currentVal.companyNameHeading,
        label: "Company Name (Heading)",
      },
      {
        name: "companyNameSubtext",
        value: currentVal.companyNameSubtext,
        label: "Company Name (Subtext)",
      },
      {
        name: "companyNamePlaceholder",
        value: currentVal.companyNamePlaceholder,
        label: "Company Name Placeholder",
        space: "blank",
      },
      {
        name: "companyWorkTypeHeading",
        value: currentVal.companyWorkTypeHeading,
        label: "Company work Type (Heading)",
      },
      {
        name: "companyWorkTypeSubtext",
        value: currentVal.companyWorkTypeSubtext,
        label: "Company work Type (Subtext)",
      },
      {
        name: "companyJobRoleHeading",
        value: currentVal.companyJobRoleHeading,
        label: "Company Job Role (Heading)",
      },
      {
        name: "companyJObRoleSubtext",
        value: currentVal.companyJObRoleSubtext,
        label: "Company Job Role (Subtext)",
      },
      {
        name: "companyJobRolePlaceholder",
        value: currentVal.companyJobRolePlaceholder,
        label: "Company Job Role Placeholder",
        space: "blank",
      },
      {
        name: "companyLinkHeading",
        value: currentVal.companyLinkHeading,
        label: "Company Link (Heading)",
      },
      {
        name: "companyLinkSubtext",
        value: currentVal.companyLinkSubtext,
        label: "Company Link (Subtext)",
      },
      {
        name: "companydescribesHeading",
        value: currentVal.companydescribesHeading,
        label: "Company Describes (Heading)",
      },
      {
        name: "companydescribesSubtext",
        value: currentVal.companydescribesSubtext,
        label: "Company Describes (Subtext)",
      },
      {
        name: "companydescPlaceholder",
        value: currentVal.companydescPlaceholder,
        label: "Company Describes Placeholder",
        space: "blank",
      },
      {
        name: "companyLocationHeading",
        value: currentVal.companyLocationHeading,
        label: "Company Location (Heading)",
      },
      {
        name: "companyLocationSubtext",
        value: currentVal.companyLocationSubtext,
        label: "Company Location (Subtext)",
      },
      {
        name: "companyIndustryHeading",
        value: currentVal.companyIndustryHeading,
        label: "Company Industry (Heading)",
      },
      {
        name: "companyIndustrySubtext",
        value: currentVal.companyIndustrySubtext,
        label: "Company Industry (Subtext)",
      },
      {
        name: "companyEmpHeading",
        value: currentVal.companyEmpHeading,
        label: "Company Employee (Heading)",
      },
      {
        name: "companyEmpSubtext",
        value: currentVal.companyEmpSubtext,
        label: "Company Employee (Subtext)",
      },
      {
        name: "companyIndivualHeading",
        value: currentVal.companyIndivualHeading,
        label: "Company Individual Location (Heading)",
      },
      {
        name: "companyIndivualSubtext",
        value: currentVal.companyIndivualSubtext,
        label: "Company Individual Location (Subtext)",
      },
    ];

    return (
      <React.Fragment>
        <div className="static-question">
          <div className="row">
            {contentArr.map((obj, index) => (
              <React.Fragment key={index}>
                <div className="col-md-6">
                  <div className="form-group col-12">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="text-secondary"
                    >
                      {obj.label}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name={obj.name}
                      value={obj.value ? obj.value : ""}
                      onChange={handlefunc.handleOnchange}
                    />
                  </div>
                  {obj.line === "hr" && <hr className="my-5" />}
                </div>
                {obj.space === "blank" && <div className="col-md-6"></div>}
              </React.Fragment>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
