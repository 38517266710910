import React, { Component } from "react";
import { connect } from 'react-redux';
import { collaterlApprovedProject } from '../../../store/action/collateralList.action';
// import ReactPaginate from "react-paginate";
import Loader from '../Common/Loader/Loader';

 class ApprovedProjects extends Component {
  state = {
    LoadingIs: false,
    approvedProject : '',
    approvedProjectList : 1,
    dataIs: [],
    totalpagesIs: ''
  };

  handleOnChange = (e) =>{
    const{ name, value } = e.target;
    this.setState({
      [name] : value
    })
  }

  // handlePageClick = (data) =>{
  //   let currentData = (data.selected)+1;
  // }

  handleApprovedProject = () =>{
    this.setState({
      LoadingIs: true
    })
    const{ approvedProjectList } = this.state;
    this.props.$collaterlApprovedProject(approvedProjectList);    
  }

  componentDidMount(){
    this.handleApprovedProject();
  }

  componentWillReceiveProps(nextProps){
    const approvedProject = nextProps._collaterlApprovedProject.collApprovedProject;
    if(approvedProject){
      this.setState({
        dataIs : approvedProject.users,
        totalpagesIs : approvedProject.totalpages,
        LoadingIs : false,
      })
    }
  }

  render() {
    const tableArr = [
      { name: "Name" },
      { name: "Url" },
      { name: "Artist" },
      { name: "Status" },
    ];

    const { LoadingIs, approvedProject } = this.state;
    return (
      <React.Fragment>
        <section className="client">
          <div className="container-fluid mx-auto">
            {/*------------------Search ---------------------*/}
            <div className="row col-md-12">
              <div className="col">
                <form className="row align-items-center">
                  <div className="col-auto pr-0">
                    <span className="fe fe-search text-muted"></span>
                  </div>
                  <div className="col">
                    <input
                      type="search"
                      className="form-control form-control-flush search"
                      autoComplete="off"
                      placeholder="Search"
                      name="approvedProject"
                      value={approvedProject}
                      onChange={this.handleOnChange}
                    />
                  </div>
                </form>
              </div>
            </div>
            {/*------------------Search ---------------------*/}

            {LoadingIs ? (
              <Loader />
            ) : (
              <div className="table-responsive mb-5 mx-auto">
                <table className="table table-sm table-nowrap card-table">
                  {/*---------table head ------------------*/}
                  <thead>
                    <tr>
                      {tableArr.map((obj, index) => (
                        <th key={index}>
                          <a href="!#" className="text-muted sort">
                            {obj.name}
                          </a>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {/*---------table head ------------------*/}
                  <tbody className="list">                                        
                    <tr>
                      <td className="orders-order">#6520</td>
                      <td className="orders-product">5' x 3' Wall Poster</td>
                      <td className="orders-total">$55.25</td>
                      <td className="orders-status">
                        <div className="badge badge-soft-success">Shipped</div>
                      </td>
                    </tr>                   
                  </tbody>
                </table>
              </div>
            )}
            {/**** Pagination****** */}            
            {/* {this.state.totalpagesIs > 0 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.totalpagesIs}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )} */}
          </div>
        </section>
      </React.Fragment>
    );
  }
}
const mapState = ({ collaterlApprovedProject }) => {
  return {
    _collaterlApprovedProject: collaterlApprovedProject,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $collaterlApprovedProject: (values) =>
    dispatchEvent(collaterlApprovedProject(values)),
});
export default connect(
  mapState,
  mapDispatch
)(ApprovedProjects);