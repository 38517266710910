import {
  TRANSACTIONS_LIST,
  TRANSACTIONS_LIST_SUCCESS,
  TRANSACTIONS_LIST_ERROR,
  TRANSACTIONS_DATA,
  TRANSACTIONS_DATA_SUCCESS,
  TRANSACTIONS_STATUS,
  TRANSACTIONS_STATUS_SUCCESS,
  ACTIVE_TRANSACTIONS,
  ACTIVE_TRANSACTIONS_SUCCESS,
  ACTIVE_TRANSACTIONS_ERROR,
  PENDING_TRANSACTIONS,
  PENDING_TRANSACTIONS_SUCCESS,
  PENDING_TRANSACTIONS_ERROR,
  INCOMPLETE_TRANSACTIONS,
  INCOMPLETE_TRANSACTIONS_SUCCESS,
  INCOMPLETE_TRANSACTIONS_ERROR,
  INCOMPLETE_HIGH_TRANSACTIONS,
  INCOMPLETE_HIGH_TRANSACTIONS_SUCCESS,
  INCOMPLETE_HIGH_TRANSACTIONS_ERROR,
  CLOSE_PROJECT,
  CLOSE_PROJECT_SUCCESS,
  COMPLETE_PROJECT,
  COMPLETE_PROJECT_SUCCESS,
  CLOSE_TRANSACTION_LIST,
  CLOSE_TRANSACTION_LIST_SUCCESS,
  COMPLETE_PROJECT_ERROR,
  CLOSE_PROJECT_ERROR,
  DONT_LIKE_TRANSACTION_LIST,
  DONT_LIKE_TRANSACTION_LIST_SUCCESS,
  DONT_LIKE_TRANSACTION_LIST_ERROR,
  CHOOSE_CREATOR,
  CHOOSE_CREATOR_SUCCESS,
  TRANSCATION_APPROVAL_LIST,
  TRANSCATION_APPROVAL_LIST_SUCCESS,
  CALL_BACK_REQUEST,
  CALL_BACK_REQUEST_SUCCESS,
  CALL_BACK_DATA,
  CALL_BACK_DATA_SUCCESS,
  CALL_BACK_SEARCH_CREATOR,
  CALL_BACK_SEARCH_CREATOR_SUCCESS,
  RECOMMENDED_CREATOR,
  RECOMMENDED_CREATOR_SUCCESS,
  INCOMPLETE_BRIEF_LIST,
  INCOMPLETE_BRIEF_LIST_SUCCESS,
  CRETAOR_REJECTS_BRIEF_LIST,
  CRETAOR_REJECTS_BRIEF_LIST_SUCCESS,
  REJECTS_CREATOR_DATA,
  REJECTS_CREATOR_DATA_SUCCESS,
  CLEAR_BRIEF_LIST,
  CLEAR_BRIEF_LIST_SUCCESS,
  CALL_BACK_REQUEST_ERROR,
  TRANSCATION_APPROVAL_LIST_ERROR,
  INCOMPLETE_BRIEF_LIST_ERROR,
  CRETAOR_REJECTS_BRIEF_LIST_ERROR,
  CLOSED_BRIEF_PROJECT,
  CLOSED_BRIEF_PROJECT_SUCCESS,
  CLOSED_BRIEF_PROJECT_ERROR,
  REJECTED_BRIEF_LIST,
  REJECTED_BRIEF_LIST_SUCCESS,
  REJECTED_BRIEF_LIST_ERROR,
  DELETE_PROJECT_LIST,
  DELETE_PROJECT_LIST_SUCCESS,
  DELETE_BRIEF_LIST,
  DELETE_BRIEF_LIST_SUCCESS,
  DELETE_BRIEF_LIST_ERROR,
  DELETE_PROJECT_ALL_LIST,
  DELETE_PROJECT_ALL_LIST_SUCCESS,
  DELETE_PROJECT_ALL_LIST_ERROR,
  CLOSED_BRIEF_ALL_LIST,
  CLOSED_BRIEF_ALL_LIST_SUCCESS,
  CLOSED_BRIEF_ALL_LIST_ERROR,
  CLOSE_BRIEF_REQUEST_LIST,
  CLOSE_BRIEF_REQUEST_LIST_SUCCESS,
  CLOSE_BRIEF_REQUEST_ERR,
  CLOSE_PROJECT_REQUEST_LIST,
  CLOSE_PROJECT_REQUEST_LIST_SUCCESS,
  CLOSE_PROJECT_REQUEST_ERR,
  COMPLETED_MILES,
  COMPLETED_MILES_SUCCESS,
  MILESTONE_COMMENT,
  MILESTONE_COMMENT_SUCCESS,
  MILESTONE_APPROVED,
  MILESTONE_APPROVED_SUCCESS,
  ADD_MULTIPLE_CREATOR,
  ADD_MULTIPLE_CREATOR_SUCCESS,
  DELETE_PENDING_CREATOR,
  DELETE_PENDING_CREATOR_SUCCESS,
  OFFLINE_PAYMENT,
  OFFLINE_PAYMENT_SUCCESS,
  UPDATE_TRANSCATIONS,
  UPDATE_TRANSCATIONS_SUCCESS,
  APPROVED_PROPOSAL,
  APPROVED_PROPOSAL_SUCCESS,
  APPROVED_PROPOSAL_JOB,
  APPROVED_PROPOSAL_JOB_SUCCESS,
  REQUEST_CHANGE_PROPOSAL,
  REQUEST_CHANGE_PROPOSAL_SUCCESS,
  EDIT_CLIENT,
  EDIT_CLIENT_SUCCESS,
  MANAGER_DETAILS,
  MANAGER_DETAILS_SUCCESS,
  INACTIVE_BRIEF,
  INACTIVE_BRIEF_SUCCESS,
  INACTIVE_BRIEF_ERROR,
  INACTIVE_PROJECT,
  INACTIVE_PROJECT_SUCCESS,
  INACTIVE_PROJECT_ERR,
  INACT_BRIEF_FOR_AM,
  INACT_BRIEF_FOR_AM_SUCCESS,
  INACT_BRIEF_FOR_AM_ERR,
  TRANSACTIONS_COUNT,
  TRANSACTIONS_COUNT_SUCCESS,
  TRANSACTIONS_COUNT_ERROR,
  RESET_TRANSACTION_LIST
} from "../type.action";

// transactions/ post Project All List with Pagination***********

export const transactionList = (values) => ({
  type: TRANSACTIONS_LIST,
  values,
});
export const transactionListSuccess = (values) => ({
  type: TRANSACTIONS_LIST_SUCCESS,
  values,
});
export const transactionListError = (error) => ({
  type: TRANSACTIONS_LIST_ERROR,
  error,
});

export const resetTransactionList = (values) => ({
  type:RESET_TRANSACTION_LIST,
  values,
})

// Post Project get data based on id***********

export const transactionGetData = (values,payload) => ({
  type: TRANSACTIONS_DATA,
  values,
  payload,
});
export const transactionGetDataSuccess = (values) => ({
  type: TRANSACTIONS_DATA_SUCCESS,
  values,
});

// transactions status accept/reject**************

export const transactionStatus = (values) => ({
  type: TRANSACTIONS_STATUS,
  values,
});
export const transactionStatusSuccess = (values) => ({
  type: TRANSACTIONS_STATUS_SUCCESS,
  values,
});

// transactions count********************************

export const transactionsCount = (values) => ({
  type : TRANSACTIONS_COUNT,
  values,
});

export const transactionsCountSuccess = (values) => ({
  type : TRANSACTIONS_COUNT_SUCCESS,
  values,
});

export const transactionsCountError = (error) => ({
  type : TRANSACTIONS_COUNT_ERROR,
  error,
})

// transactions Active List with pagination***********

export const activeTransactions = (values) => ({
  type: ACTIVE_TRANSACTIONS,
  values,
});
export const activeTransactionsSuccess = (values) => ({
  type: ACTIVE_TRANSACTIONS_SUCCESS,
  values,
});
export const activeTransactionsError = (error) => ({
  type: ACTIVE_TRANSACTIONS_ERROR,
  error,
});

// transactions Pending List with pagination**************

export const pendingTransactions = (values) => ({
  type: PENDING_TRANSACTIONS,
  values,
});
export const pendingTransactionsSuccess = (values) => ({
  type: PENDING_TRANSACTIONS_SUCCESS,
  values,
});
export const pendingTransactionsError = (error) => ({
  type: PENDING_TRANSACTIONS_ERROR,
  error,
});

// transactions Incomplete List with pagination************

export const incompleteTransactions = (values) => ({
  type: INCOMPLETE_TRANSACTIONS,
  values,
});
export const incompleteTransactionsSuccess = (values) => ({
  type: INCOMPLETE_TRANSACTIONS_SUCCESS,
  values,
});
export const incompleteTransactionError = (error) => ({
  type: INCOMPLETE_TRANSACTIONS_ERROR,
  error,
});

// ----------------

export const incompleteHighTransaction = (values) => ({
  type: INCOMPLETE_HIGH_TRANSACTIONS,
  values,
});
export const incompleteHighTransactionSuccess = (values) => ({
  type: INCOMPLETE_HIGH_TRANSACTIONS_SUCCESS,
  values,
});
export const incompleteHighTransactionError = (error) => ({
  type: INCOMPLETE_HIGH_TRANSACTIONS_ERROR,
  error,
});

// transactions close project******************************

export const closeProject = (values) => ({
  type: CLOSE_PROJECT,
  values,
});
export const closeProjectSucces = (values) => ({
  type: CLOSE_PROJECT_SUCCESS,
  values,
});

// complete Transactions ****************************************

export const completeProject = (values) => ({
  type: COMPLETE_PROJECT,
  values,
});
export const completeProjectSuccess = (values) => ({
  type: COMPLETE_PROJECT_SUCCESS,
  values,
});
export const completeProjectError = (error) => ({
  type: COMPLETE_PROJECT_ERROR,
  error,
});

// close Transaction List *************************************

export const closeTransactionList = (values) => ({
  type: CLOSE_TRANSACTION_LIST,
  values,
});
export const closeTransactionListSuccess = (values) => ({
  type: CLOSE_TRANSACTION_LIST_SUCCESS,
  values,
});
export const closeTransactionListError = (error) => ({
  type: CLOSE_PROJECT_ERROR,
  error,
});

// Dont Like Transaction List **********************************

export const DontLikeTranscationList = (values) => ({
  type: DONT_LIKE_TRANSACTION_LIST,
  values,
});
export const DontLikeTransactionListSuccess = (values) => ({
  type: DONT_LIKE_TRANSACTION_LIST_SUCCESS,
  values,
});
export const DontLikeTransactionError = (error) => ({
  type: DONT_LIKE_TRANSACTION_LIST_ERROR,
  error,
});

// add creator in dont Like case (transaction) *****************************

export const chooseCreator = (values) => ({
  type: CHOOSE_CREATOR,
  values,
});
export const chooseCreatorSuccess = (values) => ({
  type: CHOOSE_CREATOR_SUCCESS,
  values,
});

// transaction List (approval tab)
export const transactionApprovalList = (values) => ({
  type: TRANSCATION_APPROVAL_LIST,
  values,
});
export const transactionApprovalListSuccess = (values) => ({
  type: TRANSCATION_APPROVAL_LIST_SUCCESS,
  values,
});
export const transactionApprovalListError = (error) => ({
  type: TRANSCATION_APPROVAL_LIST_ERROR,
  error,
});

// call back request  List (Approval tab)
export const callBackRequestList = (values) => ({
  type: CALL_BACK_REQUEST,
  values,
});
export const callBackRequestListSuccess = (values) => ({
  type: CALL_BACK_REQUEST_SUCCESS,
  values,
});
export const callBackRequestListError = (error) => ({
  type: CALL_BACK_REQUEST_ERROR,
  error,
});

// call back request data based on id (Approval tab)
export const callBackData = (values) => ({
  type: CALL_BACK_DATA,
  values,
});
export const callBackDataSuccess = (values) => ({
  type: CALL_BACK_DATA_SUCCESS,
  values,
});

// call back search creator (approaval tab)

export const callBackSearchCreator = (values) => ({
  type: CALL_BACK_SEARCH_CREATOR,
  values,
});
export const callBackSearchCreatorSuccess = (values) => ({
  type: CALL_BACK_SEARCH_CREATOR_SUCCESS,
  values,
});

// call back recommended creator (approabval tab)

export const recommendedCreator = (values) => ({
  type: RECOMMENDED_CREATOR,
  values,
});
export const recommendedCreatorSuccess = (values) => ({
  type: RECOMMENDED_CREATOR_SUCCESS,
  values,
});

// inComplete Brief List (Approval tab)

export const inCompleteBriefList = (values) => ({
  type: INCOMPLETE_BRIEF_LIST,
  values,
});
export const inCompleteBriefListSuccess = (values) => ({
  type: INCOMPLETE_BRIEF_LIST_SUCCESS,
  values,
});
export const inCompleteBriefListError = (error) => ({
  type: INCOMPLETE_BRIEF_LIST_ERROR,
  error,
});

// creator Rejects Brief (Approaval tab)

export const creatorRejectsBriefList = (values) => ({
  type: CRETAOR_REJECTS_BRIEF_LIST,
  values,
});
export const creatorRejectsBriefListSuccess = (values) => ({
  type: CRETAOR_REJECTS_BRIEF_LIST_SUCCESS,
  values,
});
export const creatorRejectsBriefListError = (error) => ({
  type: CRETAOR_REJECTS_BRIEF_LIST_ERROR,
  error,
});

// creator Rejects data based on id (Approval tab )
export const rejectCreatorData = (values) => ({
  type: REJECTS_CREATOR_DATA,
  values,
});
export const rejectsCreatorDataSuccess = (values) => ({
  type: REJECTS_CREATOR_DATA_SUCCESS,
  values,
});

//  clear brief (Approaval tab)

export const clearBriefList = (values) => ({
  type: CLEAR_BRIEF_LIST,
  values,
});
export const clearBriefListSuccess = (values) => ({
  type: CLEAR_BRIEF_LIST_SUCCESS,
  values,
});

//closed brief Project (Transcation tab)

export const closedBriefProject = (values) => ({
  type: CLOSED_BRIEF_PROJECT,
  values,
});
export const closedBriefProjectSuccess = (values) => ({
  type: CLOSED_BRIEF_PROJECT_SUCCESS,
  values,
});
export const closedBriefProjectError = (error) => ({
  type: CLOSED_BRIEF_PROJECT_ERROR,
  error,
});

// rejected Transaction List (Transcation Tab )

export const rejectedBriefList = (values) => ({
  type: REJECTED_BRIEF_LIST,
  values,
});
export const rejectedBriefListSuccess = (values) => ({
  type: REJECTED_BRIEF_LIST_SUCCESS,
  values,
});
export const rejectedBriefListError = (error) => ({
  type: REJECTED_BRIEF_LIST_ERROR,
  error,
});

// delete Project (transaction tab)

export const deleteProjectList = (values) => ({
  type: DELETE_PROJECT_LIST,
  values,
});
export const deleteProjectListSuccess = (values) => ({
  type: DELETE_PROJECT_LIST_SUCCESS,
  values,
});

// Delete Brief List (transcation tab)

export const deleteBriefList = (values) => ({
  type: DELETE_BRIEF_LIST,
  values,
});
export const deleteBriefListSuccess = (values) => ({
  type: DELETE_BRIEF_LIST_SUCCESS,
  values,
});
export const deleteBriefListError = (error) => ({
  type: DELETE_BRIEF_LIST_ERROR,
  error,
});

// Delete Project List (transcation tab)

export const deleteProjectAllList = (values) => ({
  type: DELETE_PROJECT_ALL_LIST,
  values,
});
export const deleteProjectAllListSuccess = (values) => ({
  type: DELETE_PROJECT_ALL_LIST_SUCCESS,
  values,
});
export const deleteProjectAllListError = (error) => ({
  type: DELETE_PROJECT_ALL_LIST_ERROR,
  error,
});

// Closed Brief List (Transaction tab)

export const closedBriefAllList = (values) => ({
  type: CLOSED_BRIEF_ALL_LIST,
  values,
});
export const closeBriefAllListSuccess = (values) => ({
  type: CLOSED_BRIEF_ALL_LIST_SUCCESS,
  values,
});
export const closeBriefAllListError = (error) => ({
  type: CLOSED_BRIEF_ALL_LIST_ERROR,
  error,
});

// Brief Close Request (Approval tab)
export const closeBriefRequestList = (values) => ({
  type: CLOSE_BRIEF_REQUEST_LIST,
  values,
});
export const closeBriefRequestListSuccess = (values) => ({
  type: CLOSE_BRIEF_REQUEST_LIST_SUCCESS,
  values,
});
export const closeBriefRequestListErr = (error) => ({
  type: CLOSE_BRIEF_REQUEST_ERR,
  error,
});

// Project Close Request (Approval tab)
export const closeProjectRequestList = (values) => ({
  type: CLOSE_PROJECT_REQUEST_LIST,
  values,
});
export const closeProjectRequestSuccess = (values) => ({
  type: CLOSE_PROJECT_REQUEST_LIST_SUCCESS,
  values,
});
export const closeProjectReqListErr = (error) => ({
  type: CLOSE_PROJECT_REQUEST_ERR,
  error,
});

// get submitedMilesData based on id (client/creator tab)
export const completedMiles = (values) => ({
  type: COMPLETED_MILES,
  values,
});
export const completedMilesSuccess = (values) => ({
  type: COMPLETED_MILES_SUCCESS,
  values,
});

// get milestone comment
export const milesComment = (values) => ({
  type: MILESTONE_COMMENT,
  values,
});
export const milesCommentSuccess = (values) => ({
  type: MILESTONE_COMMENT_SUCCESS,
  values,
});

// enterprise project milestone approved
export const milestoneApproved = (values) => ({
  type: MILESTONE_APPROVED,
  values,
});
export const milestoneApprovedSuccess = (values) => ({
  type: MILESTONE_APPROVED_SUCCESS,
  values,
});

// add multiple creator
export const addMultipleCreator = (values) => ({
  type: ADD_MULTIPLE_CREATOR,
  values,
});
export const addMultipleCreatorSuccess = (values) => ({
  type: ADD_MULTIPLE_CREATOR_SUCCESS,
  values,
});
export const addMultipleCreator2 = (values) => ({
  type: ADD_MULTIPLE_CREATOR,
  values,
});
export const addMultipleCreator2Success = (values) => ({
  type: ADD_MULTIPLE_CREATOR_SUCCESS,
  values,
});

// delete pending creator
export const deletePendingCreator = (values) => ({
  type: DELETE_PENDING_CREATOR,
  values,
});
export const deletePendingCreatorSuccess = (values) => ({
  type: DELETE_PENDING_CREATOR_SUCCESS,
  values,
});

//  payment offline (transaction tab)
export const paymentOffline = (values) => ({
  type: OFFLINE_PAYMENT,
  values,
});
export const paymentOfflineSuccess = (values) => ({
  type: OFFLINE_PAYMENT_SUCCESS,
  values,
});

// update transaction details
export const updateTransactions = (values) => ({
  type: UPDATE_TRANSCATIONS,
  values,
});
export const updateTransactionsSuccess = (values) => ({
  type: UPDATE_TRANSCATIONS_SUCCESS,
  values,
});

// approved proposal
export const approvedProposal = (values) => ({
  type: APPROVED_PROPOSAL,
  values,
});

export const approvedProposalSuccess = (values) => ({
  type: APPROVED_PROPOSAL_SUCCESS,
  values,
});

// approved proposal job

export const approvedProposalJob = (values) => ({
  type: APPROVED_PROPOSAL_JOB,
  values,
});

export const approvedProposalJobSuccess = (values) => ({
  type: APPROVED_PROPOSAL_JOB_SUCCESS,
  values,
});

// request change
export const requestChangeProposal = (values) => ({
  type: REQUEST_CHANGE_PROPOSAL,
  values,
});
export const requestChangeProposalSuccess = (values) => ({
  type: REQUEST_CHANGE_PROPOSAL_SUCCESS,
  values,
});

// edit Client
export const editClient = (values) => ({
  type: EDIT_CLIENT,
  values,
});
export const editClientSuccess = (values) => ({
  type: EDIT_CLIENT_SUCCESS,
  values,
});

// Manager Details
export const managerDetails = (values) => ({
  type: MANAGER_DETAILS,
  values,
});
export const managerDetailsSuccess = (values) => ({
  type: MANAGER_DETAILS_SUCCESS,
  values,
});

// inactive briefs *****************************************

export const inactiveBrief = (values) => ({
  type : INACTIVE_BRIEF,
  values
})

export const inactiveBriefSuccess = (values) => ({
  type : INACTIVE_BRIEF_SUCCESS,
  values
})

export const inactiveBriefErr = (error) => ({
  type : INACTIVE_BRIEF_ERROR,
  error
})

// inactive project *****************************************

export const inactiveProject = (values) => ({
  type : INACTIVE_PROJECT,
  values
})

export const inactiveProjectSuccess = (values) => ({
  type : INACTIVE_PROJECT_SUCCESS,
  values
})

export const inactiveProjectErr = (error) => ({
  type : INACTIVE_PROJECT_ERR,
  error
})


// inactive brief for AM transaction **************************************
export const inActBriefForAm = (values) => ({
  type : INACT_BRIEF_FOR_AM,
  values
})

export const inActBriefForAmSuccess = (values) => ({
  type : INACT_BRIEF_FOR_AM_SUCCESS,
  values
})

export const inActBriefForErr = (error) => ({
  type : INACT_BRIEF_FOR_AM_ERR,
  error
})