import React, { Component } from "react";

export default class ArtistReview extends Component {
  render() {
      const{ reviewIs, 
        // handleProject 
      } = this.props;
    return (
      <React.Fragment>
        <div className="col-12 col-md-12">
          <div className="form-group">
            <label className="mb-1">Review</label>
            <div>
              <span
                className={`mr-3 ${
                  reviewIs ? `fa fa-star checked` : `fa fa-star-o`
                }`}
                value="2"
                //  onClick={() => handleProject.handleRating(1)}
              ></span>
              <span
                className={`mr-3 ${
                  reviewIs === 2 ||
                  reviewIs === 3 ||
                  reviewIs === 4 ||
                  reviewIs === 5
                    ? `fa fa-star checked`
                    : `fa fa-star-o`
                }`}
                value="2"
                //  onClick={() => handleProject.handleRating(2)}
              ></span>
              <span
                 className={`mr-3 ${
                    reviewIs === 3 ||
                    reviewIs === 4 ||
                    reviewIs === 5
                      ? `fa fa-star checked`
                      : `fa fa-star-o`
                  }`}
                value="3"
                //  onClick={() => handleProject.handleRating(3)}
              ></span>
              <span
                className={`mr-3 ${
                    reviewIs === 4 ||
                    reviewIs === 5
                      ? `fa fa-star checked`
                      : `fa fa-star-o`
                  }`}
                value="4"
                //  onClick={() => handleProject.handleRating(4)}
              ></span>
              <span
                 className={`mr-3 ${
                    reviewIs === 5
                      ? `fa fa-star checked`
                      : `fa fa-star-o`
                  }`}
                value="5"
                //  onClick={() => handleProject.handleRating(5)}
              ></span>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
