import { takeLatest, all } from "redux-saga/effects";

import {
  LOGIN_DATA,
  GET_ADMIN_USER_DATA,
  RESET_PWD,
  CLIENT_LIST,
  CHECK_STATUS,
  USER_SIGNUP,
  CREATIVE_USERS_DATA,
  ADD_USERS_DATA,
  UPDATE_USERS_DATA,
  VIEW_USERS_DATA,
  COLLATERAL_LIST,
  COLLATERAL_IMAGE,
  COLLATERAL_PCF,
  COLLATERAL_GET,
  PROJECT_LIST,
  CLIENT_DATA,
  ARTIST_LIST,
  GET_ARTIST_DATA,
  STATUS_ARTIST_COLLATERAL,
  STATUS_ARTIST_PROJECT,
  ARTIST_DETAILS,
  CLIENTS_DETAILS,
  CLIENTS_PROFILE,
  ARTIST_PROFILE,
  COLLATERAL_STATUS,
  ARTIST_UPDATE_INFO,
  ARTIST_APPROVAL_LIST,
  ARTIST_BUSY_LIST,
  ARTIST_STARRED_LIST,
  ARTIST_CLEAN_UPDATE,
  ARTIST_REVIEW_STATUS,
  TRANSACTIONS_LIST,
  COLLATERAL_APPROVED_PROJECT,
  COLLATERAL_PENDING_PROJECT,
  TRANSACTIONS_DATA,
  TRANSACTIONS_STATUS,
  ACTIVE_TRANSACTIONS,
  PENDING_TRANSACTIONS,
  INCOMPLETE_TRANSACTIONS,
  INCOMPLETE_HIGH_TRANSACTIONS,
  COLLATERAL_PENDING_CREATOR,
  COLLATERAL_APPROVED_CREATOR,
  COLLATERAL_CHANGE_HISTORY,
  COLLATERAL_ACTIVE_TRANSACTION,
  COLLATERAL_ACTIVE_NEGOCIATIONS,
  TEMP_RESET_PWD,
  BACKEND_LIST,
  BACKEND_DELETE_LIST,
  REJECTED_ARTIST,
  DELETE_USER,
  APPROVED_ARTIST,
  CLOSE_PROJECT,
  DELETE_ARTIST_LIST,
  CLIENT_DELETE_LIST,
  LOCATION_USER,
  COMPLETE_PROJECT,
  CLOSE_TRANSACTION_LIST,
  DONT_LIKE_TRANSACTION_LIST,
  CHOOSE_CREATOR,
  PENDING_COLLATERAL,
  PENDING_COLLATERAL_LIST,
  PENDING_PROJECT_LIST,
  GET_PENDING_PROJECT_DATA,
  HIDE_COLLATERAL,
  PROJECT_REVIEW_LIST,
  GET_PROJECT_REVIEW_DATA,
  TRANSCATION_APPROVAL_LIST,
  CALL_BACK_REQUEST,
  CALL_BACK_DATA,
  CALL_BACK_SEARCH_CREATOR,
  RECOMMENDED_CREATOR,
  PENDING_CREATOR_APPR_LIST,
  GET_BACKEND_USER,
  GET_TAB_LIST,
  ADD_TAB_LIST,
  DELETE_COLLATERAL_LIST,
  INCOMPLETE_BRIEF_LIST,
  CRETAOR_REJECTS_BRIEF_LIST,
  REJECTS_CREATOR_DATA,
  CLEAR_BRIEF_LIST,
  CLIENT_REJECTS_CREATOR_LIST,
  APPROVED_CREATOR_LIST,
  APPROVED_STARRED_LIST,
  ADD_BACKEND_USER,
  CLOSED_BRIEF_PROJECT,
  REJECTED_BRIEF_LIST,
  ALL_COLLATERAL_LIST,
  GET_USER_LIST,
  ADD_POST_PROJECT,
  GET_POST_PROJECT_DATA,
  ADD_PROPOSAL,
  UPDATE_PROJECT,
  UPDATE_PROPOSAL,
  DELETE_PROJECT_LIST,
  DELETE_BRIEF_LIST,
  DELETE_PROJECT_ALL_LIST,
  CLOSED_BRIEF_ALL_LIST,
  CLOSE_BRIEF_REQUEST_LIST,
  CLOSE_PROJECT_REQUEST_LIST,
  CLIENT_BRIEF_LIST,
  CHANGE_EMBEDDED_LINK,
  MILESTONE_LIST,
  COMPLETED_MILES,
  MILESTONE_COMMENT,
  MILESTONE_APPROVED,
  ARTIST_BUSY_OR_AVAILABLE,
  REPORT_FOR_PROJECT_BRIEF,
  REPORT_FOR_ENTERPRISE_PROJECT,
  REPORT_FOR_PROJECT,
  REPORT_FOR_COLLATERAL,
  REPORT_FOR_ALL_ARTIST,
  ADD_MULTIPLE_CREATOR,
  DELETE_BACKEND_USER,
  DELETE_PENDING_CREATOR,
  FEATURED_COLLATERAL,
  OFFLINE_PAYMENT,
  UPDATE_TRANSCATIONS,
  DISABLED_ARTIST,
  DISABLE_ENABLE_ARTIST,
  REPORT_FOR_APPROVED_ARTIST,
  REPORT_FOR_CLIENT,
  APPROVED_PROPOSAL,
  APPROVED_PROPOSAL_JOB,
  REQUEST_CHANGE_PROPOSAL,
  EDIT_CLIENT,
  MANAGER_DETAILS,
  INACTIVE_BRIEF,
  INACTIVE_PROJECT,
  INACT_BRIEF_FOR_AM,
  TRANSITION_DASHBOARD,
  TRANSACTIONS_COUNT,
  GET_CUSTOM_INDUSTRY_LIST,
  GET_CUSTOM_SOFTWARE_LIST,
  GET_CUSTOM_SPECIALIZATION_LIST,
  ADD_PROPOSAL_CYCLE,
  EDIT_PROPOSAL_CYCLE,
  DELETE_PROPOSAL_CYCLE,
} from "../type.action";
import { onloginData, onResetPwd, getAdminData } from "./loginData.saga";
import {
  onClientList,
  onCheckStatus,
  onUserSignup,
  onCreativeUsersData,
  onAddUserData,
  onupdateUserData,
  onViewUserData,
  onGetClientData,
  onArtistList,
  onGetArtistData,
  onArtistCollateralStatus,
  onArtistProjectStatus,
  onArtistDetails,
  onClientsDetails,
  onClientsProfile,
  onArtistProfile,
  onArtistUpdateProfileInfo,
  onArtistApprovalsList,


  onArtistBusyList,
  onArtistStarredList,


  onArtistCleanUpdate,
  onArtistReviewStatus,
  ontempResetPwd,
  onArtistRejeced,
  onDeleteUser,
  onApprovedArtist,
  onDeletedArtistList,
  onDeleteClientList,
  onLocationUser,
  onPendingCreatorAppr,
  onClientRejectsCreator,
  onClientAllBriefList,
  onEmbeddedLink,
  onArtistAvailable,
  onDisabledArtist,
  ondisableEnableArtist,
} from "./clientList.saga";
import {
  oncollateralList,
  onCollateralUploadPic,
  onCollateralPcf,
  onGetCollateral,
  onStatusCollateral,
  onCollateralApprovedProject,
  onCollateralPendingProject,
  onPendingCreator,
  onApprovedCreator,
  onCollateralHistory,
  onCollateralActiveTransactions,
  onCollateralActiveNegociation,
  onPendingCollateral,
  onPendingCollateralList,
  onHideCollateral,
  ondeleteCollateralList,
  onApprovedCreatorList,
  onfeaturedCollateral,
  onApprovedStarredList
} from "./collateralList.saga";
import {
  onTransactionsList,
  onTransactionsGetData,
  onTransactionStatus,
  onActiveTransactions,
  onPendingTransactions,
  onIncompleteTransactions,
  onIncompleteHighTransactions,
  onCloseProject,
  onCompleteProject,
  onClosedTransactionList,
  onDontLikeTransaction,
  onChooseCreator,
  onTransactionAppRList,
  onCallBackRequest,
  onCallBackData,
  onSearchCallBack,
  onRecommendCreator,
  onInCompleteBrief,
  onCraetorRejectsBrief,
  onCraetorRejectsBriefData,
  onClearBrief,
  onClosedBriefProject,
  onRejectBriefProject,
  onDeleteProjectIs,
  onCloesdBriefs,
  onDeleteProjectList,
  onClosedAllList,
  onClosedBriefRequest,
  onCloseProjectReq,
  onCompMiles,
  onMilesComment,
  onMilesApproved,
  onMultipleCreator,
  // onMultipleCreator2,
  ondeletePendingCreator,
  onPaymentOffline,
  onDetailsTransaction,
  onApprovedProposal,
  onApprovedProposalJob,
  onReqChangeProposal,
  onEditClient,
  onManagerDetails,
  onInActiveBrief,
  onInactiveProject,
  onInActBrief,
  onTransactionCount
} from "./transaction.saga";
import {
  onProjectList,
  onPendingProjectList,
  onGetPendingData,
  onGetProjectReviewList,
  onProjectReviewDta,
  onAllCollateralList,
  onGetUserList,
  onAddPostProject,
  onGetPostProject,
  onAddProposal,
  onUpdateProject,
  onUpdateProposal,
  onAddProposalCycle,
  OnDeleteProposalCycle,
  OnEditProposalCycle
} from "./projectList.saga";
import {
  onBackendUser,
  onGetBackendUser,
  onGetTabList,
  onAddTabList,
  onAddBackendUser,
  ondeleteBackendUser,
  onBackendDeleteList
} from "./BackendUser.saga";
import {
  onReportProject,
  onReportsEnterPrise,
  onAllReportProject,
  onCollateralReport,
  onAllReport,
  onApprovedArtistReport,
  onClientReport,
} from "./Reports.saga";
import { onMilesList , onTransitionDasboard} from "./Milestone.saga";

import {onCustomIndustryList, onCustomSoftwareList, onCustomSpecializationList} from "./custom.saga";

function* sagas() {
  //Login and Reset Pwd-------------------------
  yield all([
    takeLatest(LOGIN_DATA, onloginData),
    takeLatest(RESET_PWD, onResetPwd),
    takeLatest(GET_ADMIN_USER_DATA,getAdminData),
    takeLatest(CLIENT_LIST, onClientList),
    takeLatest(CLIENT_DATA, onGetClientData),
    takeLatest(CLIENTS_DETAILS, onClientsDetails),
    takeLatest(CLIENTS_PROFILE, onClientsProfile),
    takeLatest(CLIENT_DELETE_LIST, onDeleteClientList),
    takeLatest(PENDING_CREATOR_APPR_LIST, onPendingCreatorAppr),
    takeLatest(CLIENT_REJECTS_CREATOR_LIST, onClientRejectsCreator),
    takeLatest(CHECK_STATUS, onCheckStatus),
    takeLatest(USER_SIGNUP, onUserSignup),
    takeLatest(CREATIVE_USERS_DATA, onCreativeUsersData),
    takeLatest(ADD_USERS_DATA, onAddUserData),
    takeLatest(UPDATE_USERS_DATA, onupdateUserData),
    takeLatest(VIEW_USERS_DATA, onViewUserData),
    takeLatest(COLLATERAL_LIST, oncollateralList),
    takeLatest(COLLATERAL_IMAGE, onCollateralUploadPic),
    takeLatest(COLLATERAL_PCF, onCollateralPcf),
    takeLatest(COLLATERAL_GET, onGetCollateral),
    takeLatest(COLLATERAL_STATUS, onStatusCollateral),
    takeLatest(COLLATERAL_PENDING_CREATOR, onPendingCreator),
    takeLatest(COLLATERAL_APPROVED_CREATOR, onApprovedCreator),
    takeLatest(COLLATERAL_CHANGE_HISTORY, onCollateralHistory),
    takeLatest(PROJECT_LIST, onProjectList),
    takeLatest(PENDING_COLLATERAL, onPendingCollateral),
    takeLatest(PENDING_COLLATERAL_LIST, onPendingCollateralList),
    takeLatest(HIDE_COLLATERAL, onHideCollateral),
    takeLatest(DELETE_COLLATERAL_LIST, ondeleteCollateralList),
    takeLatest(APPROVED_CREATOR_LIST, onApprovedCreatorList), 
    takeLatest(APPROVED_STARRED_LIST, onApprovedStarredList),
    takeLatest(ARTIST_LIST, onArtistList),
    takeLatest(GET_ARTIST_DATA, onGetArtistData),
    takeLatest(STATUS_ARTIST_COLLATERAL, onArtistCollateralStatus),
    takeLatest(STATUS_ARTIST_PROJECT, onArtistProjectStatus),
    takeLatest(ARTIST_DETAILS, onArtistDetails),
    takeLatest(ARTIST_PROFILE, onArtistProfile),
    takeLatest(ARTIST_UPDATE_INFO, onArtistUpdateProfileInfo),
    takeLatest(ARTIST_APPROVAL_LIST, onArtistApprovalsList),


    takeLatest(ARTIST_BUSY_LIST, onArtistBusyList),
    takeLatest(ARTIST_STARRED_LIST, onArtistStarredList),

    takeLatest(TRANSACTIONS_COUNT,onTransactionCount),

    takeLatest(ARTIST_CLEAN_UPDATE, onArtistCleanUpdate),
    takeLatest(ARTIST_REVIEW_STATUS, onArtistReviewStatus),
    takeLatest(REJECTED_ARTIST, onArtistRejeced),
    takeLatest(APPROVED_ARTIST, onApprovedArtist),
    takeLatest(DELETE_ARTIST_LIST, onDeletedArtistList),
    takeLatest(TRANSACTIONS_LIST, onTransactionsList),
    takeLatest(TRANSACTIONS_DATA, onTransactionsGetData),
    takeLatest(ACTIVE_TRANSACTIONS, onActiveTransactions),
    takeLatest(PENDING_TRANSACTIONS, onPendingTransactions),
    takeLatest(INCOMPLETE_TRANSACTIONS, onIncompleteTransactions),
    takeLatest(INCOMPLETE_HIGH_TRANSACTIONS, onIncompleteHighTransactions),
    takeLatest(CLOSE_PROJECT, onCloseProject),
    takeLatest(COMPLETE_PROJECT, onCompleteProject),
    takeLatest(CLOSE_TRANSACTION_LIST, onClosedTransactionList),
    takeLatest(DONT_LIKE_TRANSACTION_LIST, onDontLikeTransaction),
    takeLatest(CHOOSE_CREATOR, onChooseCreator),
    takeLatest(TRANSCATION_APPROVAL_LIST, onTransactionAppRList),
    takeLatest(CALL_BACK_REQUEST, onCallBackRequest),
    takeLatest(CALL_BACK_DATA, onCallBackData),
    takeLatest(CALL_BACK_SEARCH_CREATOR, onSearchCallBack),
    takeLatest(RECOMMENDED_CREATOR, onRecommendCreator),
    takeLatest(INCOMPLETE_BRIEF_LIST, onInCompleteBrief),
    takeLatest(CRETAOR_REJECTS_BRIEF_LIST, onCraetorRejectsBrief),
    takeLatest(REJECTS_CREATOR_DATA, onCraetorRejectsBriefData),
    takeLatest(CLEAR_BRIEF_LIST, onClearBrief),
    takeLatest(TEMP_RESET_PWD, ontempResetPwd),
    takeLatest(BACKEND_LIST, onBackendUser), 
    takeLatest(BACKEND_DELETE_LIST, onBackendDeleteList),
    takeLatest(GET_BACKEND_USER, onGetBackendUser),
    takeLatest(GET_TAB_LIST, onGetTabList),
    takeLatest(ADD_TAB_LIST, onAddTabList),
    takeLatest(DELETE_USER, onDeleteUser),
    takeLatest(LOCATION_USER, onLocationUser),
    takeLatest(PENDING_PROJECT_LIST, onPendingProjectList),
    takeLatest(GET_PENDING_PROJECT_DATA, onGetPendingData),
    takeLatest(PROJECT_REVIEW_LIST, onGetProjectReviewList),
    takeLatest(GET_PROJECT_REVIEW_DATA, onProjectReviewDta),
    takeLatest(COLLATERAL_APPROVED_PROJECT, onCollateralApprovedProject),
    takeLatest(COLLATERAL_PENDING_PROJECT, onCollateralPendingProject),
    takeLatest(COLLATERAL_ACTIVE_TRANSACTION, onCollateralActiveTransactions),
    takeLatest(COLLATERAL_ACTIVE_NEGOCIATIONS, onCollateralActiveNegociation),
    takeLatest(TRANSACTIONS_STATUS, onTransactionStatus),
    takeLatest(ADD_BACKEND_USER, onAddBackendUser),
    takeLatest(CLOSED_BRIEF_PROJECT, onClosedBriefProject),
    takeLatest(REJECTED_BRIEF_LIST, onRejectBriefProject),
    takeLatest(ALL_COLLATERAL_LIST, onAllCollateralList),
    takeLatest(GET_USER_LIST, onGetUserList),
    takeLatest(ADD_POST_PROJECT, onAddPostProject),
    takeLatest(GET_POST_PROJECT_DATA, onGetPostProject),
    takeLatest(ADD_PROPOSAL, onAddProposal),
    takeLatest(UPDATE_PROJECT, onUpdateProject),
    takeLatest(UPDATE_PROPOSAL, onUpdateProposal),
    takeLatest(DELETE_PROJECT_LIST, onDeleteProjectIs),
    takeLatest(DELETE_BRIEF_LIST, onCloesdBriefs),
    takeLatest(DELETE_PROJECT_ALL_LIST, onDeleteProjectList),
    takeLatest(CLOSED_BRIEF_ALL_LIST, onClosedAllList),
    takeLatest(CLOSE_BRIEF_REQUEST_LIST, onClosedBriefRequest),
    takeLatest(CLOSE_PROJECT_REQUEST_LIST, onCloseProjectReq),
    takeLatest(CLIENT_BRIEF_LIST, onClientAllBriefList),
    takeLatest(CHANGE_EMBEDDED_LINK, onEmbeddedLink),
    takeLatest(MILESTONE_LIST, onMilesList),
    takeLatest(TRANSITION_DASHBOARD, onTransitionDasboard),
    takeLatest(COMPLETED_MILES, onCompMiles),
    takeLatest(MILESTONE_COMMENT, onMilesComment),
    takeLatest(MILESTONE_APPROVED, onMilesApproved),
    takeLatest(ARTIST_BUSY_OR_AVAILABLE, onArtistAvailable),
    takeLatest(REPORT_FOR_PROJECT_BRIEF, onReportProject),
    takeLatest(REPORT_FOR_ENTERPRISE_PROJECT, onReportsEnterPrise),
    takeLatest(REPORT_FOR_PROJECT, onAllReportProject),
    takeLatest(REPORT_FOR_ALL_ARTIST, onAllReport),
    takeLatest(REPORT_FOR_COLLATERAL, onCollateralReport),
    takeLatest(ADD_MULTIPLE_CREATOR, onMultipleCreator),
    // takeLatest(ADD_MULTIPLE_CREATOR, onMultipleCreator2),
    takeLatest(DELETE_BACKEND_USER, ondeleteBackendUser),
    takeLatest(DELETE_PENDING_CREATOR, ondeletePendingCreator),
    takeLatest(FEATURED_COLLATERAL, onfeaturedCollateral),
    takeLatest(OFFLINE_PAYMENT, onPaymentOffline),
    takeLatest(UPDATE_TRANSCATIONS, onDetailsTransaction),
    takeLatest(DISABLED_ARTIST, onDisabledArtist),
    takeLatest(DISABLE_ENABLE_ARTIST, ondisableEnableArtist),
    takeLatest(REPORT_FOR_APPROVED_ARTIST, onApprovedArtistReport),
    takeLatest(REPORT_FOR_CLIENT, onClientReport),
    takeLatest(APPROVED_PROPOSAL, onApprovedProposal), 
    takeLatest(APPROVED_PROPOSAL_JOB, onApprovedProposalJob),
    takeLatest(REQUEST_CHANGE_PROPOSAL, onReqChangeProposal),
    takeLatest(EDIT_CLIENT, onEditClient),
    takeLatest(MANAGER_DETAILS, onManagerDetails),
    takeLatest(INACTIVE_BRIEF, onInActiveBrief),
    takeLatest(INACTIVE_PROJECT, onInactiveProject),
    takeLatest(INACT_BRIEF_FOR_AM, onInActBrief),
    takeLatest(GET_CUSTOM_INDUSTRY_LIST,onCustomIndustryList),
    takeLatest(GET_CUSTOM_SOFTWARE_LIST,onCustomSoftwareList),
    takeLatest(GET_CUSTOM_SPECIALIZATION_LIST,onCustomSpecializationList),
    takeLatest(ADD_PROPOSAL_CYCLE,onAddProposalCycle),
    takeLatest(DELETE_PROPOSAL_CYCLE ,OnDeleteProposalCycle),
    takeLatest(EDIT_PROPOSAL_CYCLE ,OnEditProposalCycle),
  ]);
}

export default sagas;
