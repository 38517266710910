import {
  BACKEND_LIST_SUCCESS,
  BACKEND_DELETE_LIST_SUCCESS,
  GET_BACKEND_USER_SUCCESS,
  GET_TAB_LIST_SUCCESS,
  ADD_TAB_LIST_SUCCESS,
  ADD_BACKEND_USER_SUCCESS,
  ADD_BACKEND_UESR_ERROR,
  DELETE_BACKEND_USER_SUCCESS,
} from "../type.action";

const initState = {
  userListIs: "",
  backendUserIs: "",
  tabListIs: "",
  addbackendUserIs: "",
  addBackendUserErrIs: "",
  deleteUSerIs: "",
  backendDeleteList:""
};

const reducers = (state = initState, { type, values, error }) => {
  switch (type) {
    case BACKEND_LIST_SUCCESS:
      return {
        ...state,
        userListIs: values,
      };
    case BACKEND_DELETE_LIST_SUCCESS:
      return {
        ...state,
        backendDeleteList: values,
      };
    case GET_BACKEND_USER_SUCCESS:
      return {
        ...state,
        backendUserIs: values,
      };
    case GET_TAB_LIST_SUCCESS:
      return {
        ...state,
        tabListIs: values,
      };
    case ADD_TAB_LIST_SUCCESS:
      return {
        ...state,
        addTabIs: values,
      };
    case ADD_BACKEND_USER_SUCCESS:
      return {
        ...state,
        addbackendUserIs: values,
      };
    case ADD_BACKEND_UESR_ERROR:
      return {
        ...state,
        addBackendUserErrIs: error,
      };
    case DELETE_BACKEND_USER_SUCCESS:
      return {
        ...state,
        deleteUSerIs: values,
      };
    default:
      return state;
  }
};
export default reducers;
