import React, { Component } from "react";
import Modal from "react-modal";

import { connect } from "react-redux";
import {clientsDetailsProfile} from "../../../../store/action/clientList.action";
import {managerDetails, transactionGetData} from "../../../../store/action/transaction.action";
import Select from "react-select";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "800px",
    width: "100%",
  },
};

class Manager extends Component {

  state = {
    managerName: "",
    profileImage: "",
    imageLoader: false,
    LoadingIs : false,
    selectedManager: ""
  };

  handleSelect = obj => {
    this.setState({selectedManager: obj})
  }

  handleSave = () => {
    const {selectedManager} = this.state;
    if(window.confirm(`Sure to proceed with the selected manager.?`)){
        this.setState({LoadingIs : true})
        const transactionId = this.props.match.params.id.split(":").pop();
        const currentData = {_id:transactionId, admin_id: selectedManager.value}
        this.props.$managerDetails(currentData);
    }
  }

  componentWillReceiveProps(nextProps){
    const managerData = nextProps?._managerDetails?.managerDetailsIs;
    const {manager_id} = nextProps?.projectDataIs;
    const { managerList } = nextProps;
    if(manager_id){
      const manager = managerList.find(val => val._id === manager_id);
      this.setState({selectedManager: manager ? {value: manager?._id, label: `${manager?.first_name} ${manager?.last_name}`} : ""});
    } else {
      managerList.length && this.setState({selectedManager: {value: managerList[0]._id, label: `${managerList[0].first_name} ${managerList[0].last_name}`}});
    }

    if(this.props.location.pathname.includes('ProjectMilestone') && !this.props.manager_id_validation && manager_id){
      this.props.handleFunc.handleSetManagerId(manager_id)

    }
    
    if(managerData && this.state.LoadingIs){
        this.setState({LoadingIs : false})
        const transactionId = this.props.match.params.id.split(":").pop();
        this.props.$transactionGetData(transactionId,{admin:this.props.admin});
        this.props.handleFunc.handleCloseManagerModal();
      }  
    
  }

  render() {
    const { modalManagerOpen, handleFunc, managerList } = this.props;
    const { selectedManager } = this.state;
    
    const managerOptions = managerList.map(val =>  {return {value: val._id, label: `${val.first_name} ${val.last_name}`}})
  
    return (
      <React.Fragment>
        <Modal
          isOpen={modalManagerOpen}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <button
            type="button"
            className="close media-upload-modal"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleFunc.handleCloseManagerModal}
          >
            <span
              aria-hidden="true"
              style={{ color: "black", fontSize: "32px" }}
            >
              &times;
            </span>
          </button>
          <div className="container">
            <h3 className="text-center">Manager</h3>
            
            <div className="row">
              <div className="col">
                <label>Select Manager</label>
                <Select
                  options={managerOptions} 
                  onChange={this.handleSelect}
                  value={selectedManager}
                />
                
              </div>
            </div>
            <div className="col-12 col-md-12 text-right mt-5">
              <button className="btn btn-primary" onClick={this.handleSave}>
                Save
              </button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
const mapState = ({
    clientsDetailsProfile,
    managerDetails,
    transactionGetData
  }) => {
    return {
      _clientsDetailsProfile: clientsDetailsProfile,
      _managerDetails: managerDetails,
      _transactionGetData: transactionGetData
    };
  };
  const mapDispatch = (dispatchEvent) => ({
    $clientsDetailsProfile: (values) =>
      dispatchEvent(clientsDetailsProfile(values)),
    $managerDetails: (values) => dispatchEvent(managerDetails(values)),
    $transactionGetData: (values,payload) => dispatchEvent(transactionGetData(values,payload))
  });
  export default connect(mapState, mapDispatch)(Manager);