import React, { Component } from "react";
import { profileBaseUrl } from "../../../Utils/BaseUrl";
import QuestionForm from './QuestionForm';

export default class ProjectStyleQuestion extends Component {
  render() {
    const { styleQuestion, indexIs, styleQuesList, handlefunc } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            {styleQuestion.map((obj, index) => (
              <span key={index}>
              <div className="mx-auto">
                <div className="questin-Listing">
                  <div className="row col-md-12">
                    <div className="col-md-4">
                      <h5 className="mb-0 text-secondary">{obj.type}</h5>
                    </div>
                    <div className="col-md-6">
                      <ul className="mb-0 pl-0 text-secondary show-ques">
                        <li>Qs : {obj.question}</li>
                      </ul>
                    </div>
                    <div className="col-md-2">
                      <ul className="mb-0 mr-auto d-flex">
                        <li>
                          <i
                            className="fe fe-edit"
                            style={{
                              display: "inline-block",
                              marginRight: "5px",
                            }}
                              onClick={() =>
                                handlefunc.handleTypeEditQues(obj, index, "styleQues")
                              }
                          ></i>
                        </li>
                        {/* <li>
                          <i
                            className="fe fe-trash"
                            //   onClick={() =>
                            //     handlefunc.handleTypeQsDelete(index)
                            //   }
                          ></i>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-12">                    
                    <ul className="mb-0 pl-0 text-secondary show_option">
                      Options :
                      {obj.option.length ? obj.option.map((val, index) => (
                        <li
                          key={index}
                          style={{
                            display: "inline-block",
                            marginRight: "10px",
                          }}
                        >
                          {!val.image ? (
                            val.item
                          ) : val.image ? (
                            <span className="d-flex">
                              <p className="mb-0 mr-2">{val.item}</p>
                              <img
                                src={`${profileBaseUrl + val.image}`}
                                height="20px"
                                width="20px"
                                alt=""
                              />
                            </span>
                          ) : (
                            ""
                          )}
                        </li>
                      )):""}
                    </ul>
                  </div>
                </div>
              </div>
              { 
                (Number(indexIs) === index && styleQuesList) && 
                <QuestionForm {...this.props} {...this.state} />
              }
              </span>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
