import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import {
  AllCollateralList,
  getUserList,
  addPostProject,
  getPostProjectData,
  updateProject,
} from "../../../store/action/projectList.action";
import { userProfileBaseUrl,profileBaseUrl } from "../../../Utils/BaseUrl";
import {
  transactionGetData,
} from "../../../store/action/transaction.action";
import {
  completedMiles,
  milestoneApproved,
} from "../../../store/action/transaction.action";
import ProjectOtherDetails from "./AddProject/ProjectOtherDetails";
import LoaderSmall from "../Common/Loader/LoaderSmall";
import { getPublicAPI, postAPI } from "../../../store/api-interface";
import CompanyDetails from "./AddProject/CompanyDetails";
import Review from "./AddProject/Review";
import LastMsgBy from "./AddProject/LastMsgBy";
import ProjectStatus from "./AddProject/ProjectStatus";
// import { validationForm, validation } from "./AddProject/AddProjectFunction";
import AskQuestion from "./AskQuestion";
// import SubmitMilestone from "../../Common/ModalCommon/SubmitMilestone";
import Milestone2 from "./AddProject/Milestone2";
import {
  addProposal,
  updateProposal,
} from "../../../store/action/projectList.action";
import moment from 'moment'

class Clone extends Component {
  state = {
    selectedOption: '',
    deadlineStatus: 0,
    selectedClient: '',
    selectedCreator: '',
    selectedRepresntative: '',
    collateralName: "",
    collateralData: [],
    AllClientList: [],
    minBudget: "",
    maxBudget: "",
    unit: "",
    project: "",
    projectDescription: "",
    inputCollateral: "",
    EditIs: false,
    projectDetailsIs: [],
    flag: false,
    clientMsg: "",
    creatorMsg: "",
    representativeMsg: "",
    StatusIs: 0,
    editRes: false,
    clientId: "",
    creatorId: "",
    companyName: '',
    companyJob: "",
    companyLink: "",
    companyLocation: "",
    companyIndustry: '',
    companyDesc: "",
    companyType: "Company",
    selectedLocation: '',
    selectedEmp: '',
    selectedLocIndividual: '',
    industryMsg: "",
    locationMsg: "",
    empMsg: "",
    locationIndividualMsg: "",
    companyMsg: "",
    clientListIs: [],
    creatorListIs: [],
    ReviewIs: [],
    projectCompDate: "",
    LastMsgIs: "",
    reviewQuestn: [],
    Gst: "",
    clientBudget: "",
    note: "",
    creatorCost: "",
    selectedDate: new Date(),
    milestoneInputs: [{ name: "", due_date: "", iteration: "" }],
    projectDetails: [],
    LoadingIs: false,
    errorIs: { Gst: "", clientBudget: "", creatorCost: "" },
    mileNameIs: "",
    mileDueDateIs: "",
    DeletedMileStone: [],
    LoaderIs: false,
    showCalendar: false,
    disableDate: false,
    statusIs: 0,
    submitMilesOpen: false,
    deliverableMiles: "",
    submitMilesData: "",
    milesApprovedIs: false,
    clientAll: "",
    creatorAll: [],
    checkApi: false,
    sub_type: "fixed",
    monthValue: 0,
    monthValueMes: "",
    hourWeek: 0,
    hourWeekMes: "",
    Collateral_style_question: [],
    Collateral_type_question: [],
    load:false
  };


  // async handleLastSubmit(e) {
  //   let flag = 0;
  //   let obj1 = {};
  //   // let obj2 = {};
  //   const {
  //     // Gst,
  //     // clientBudget,
  //     // note,
  //     // creatorCost,
  //     // milestoneInputs,
  //     // projectDetails,
  //     selectedOption,
  //     minBudget,
  //     maxBudget,
  //     unit,
  //     project,
  //     projectDescription,
  //     selectedClient,
  //     selectedCreator,
  //     deadlineStatus,
  //     companyName,
  //     companyJob,
  //     companyLink,
  //     companyIndustry,
  //     companyDesc,
  //     companyType,
  //     selectedEmp,
  //     selectedLocation,
  //     selectedLocIndividual,
  //     clientAll,
  //     creatorAll,
  //     hourWeek,
  //     monthValue,
  //     sub_type

  //   } = this.state;
  //   if (
  //     // validation(this.state) &&
  //     // selectedClient &&
  //     // selectedCreator &&
  //     clientAll?._id && creatorAll?.length > 0 &&
  //       (((companyType === "Company" || companyType === "Agency") &&
  //         companyIndustry &&
  //         selectedLocation &&
  //         selectedEmp &&
  //         companyName) ||
  //         (companyType === "Individual" && selectedLocIndividual))
  //   ) {
  //     let creatorArr = []
  //     creatorAll.forEach(obj => {
  //       creatorArr.push(obj._id)
  //     })

  //     const data = {
  //       collateral_id: selectedOption._id,
  //       client_id: clientAll._id,
  //       creator_id: creatorArr,
  //       type: "post_project_admin",
  //       min_budget: minBudget,
  //       max_budget: maxBudget,
  //       unit: unit ? unit : "",
  //       dealline: deadlineStatus,
  //       project_name: project,
  //       project_description: projectDescription,
  //       company_work_type: companyType,
  //       company_name: companyName ? companyName.label : "",
  //       company_job_role: companyJob,
  //       company_add_link: companyLink,
  //       company_location: selectedLocation ? selectedLocation.label : "",
  //       company_employees: selectedEmp ? selectedEmp.label : "",
  //       company_describes: companyDesc,
  //       company_industry: companyIndustry ? companyIndustry.label : "",
  //       company_individual_location: selectedLocIndividual
  //         ? selectedLocIndividual.label
  //         : "",
  //       number_month:hourWeek,
  //       number_hour: monthValue,
  //       sub_type
  //     };


  //     this.setState({
  //       LoadingIs: true,
  //       clientMsg: "",
  //     });
  //     flag++;
  //     obj1 = Object.assign(data)
  //   } else {
  //     this.setState({
  //       // clientMsg: !selectedClient ? "Required Field" : "",
  //       // creatorMsg: !selectedCreator ? "Required Field" : "",
  //       clientMsg: !clientAll?._id ? "Required Field" : "",
  //       creatorMsg: !creatorAll?.length > 0 ? "Required Field" : "",
  //       hourWeekMes : !hourWeek || hourWeek == 0 ? "Required Field" : "",
  //       monthValueMes : !monthValue || monthValue == 0 ? "Required Field" : "",
  //       companyMsg:
  //         (companyType === "Company" || companyType === "Agency") &&
  //           !companyName
  //           ? " Required Field"
  //           : "",
  //       industryMsg: !(
  //         (companyType === "Company" || companyType === "Agency") &&
  //         companyIndustry
  //       )
  //         ? "Required Field"
  //         : "",
  //       locationMsg: !(
  //         (companyType === "Company" || companyType === "Agency") &&
  //         selectedLocation
  //       )
  //         ? "Required Field"
  //         : "",
  //       empMsg: !(
  //         (companyType === "Company" || companyType === "Agency") &&
  //         selectedEmp
  //       )
  //         ? "Required Field"
  //         : "",
  //       locationIndividualMsg: !(
  //         companyType === "Individual" && selectedLocIndividual
  //       )
  //         ? "Required Field"
  //         : "",
  //     });
  //   }

  //   // if (this.validation1() && this.validationMileStone()) {
  //   //   const mileIs = milestoneInputs.map((obj, index) => {
  //   //     let dataMile = {
  //   //       name: obj.name,
  //   //       due_date: obj.due_date,
  //   //       number: index + 1,
  //   //       iteration: obj.iteration,
  //   //     };
  //   //     return dataMile;
  //   //   });
  //   //   if (projectDetails) {
  //   //     const data = {
  //   //       tax: Gst,
  //   //       sub_total_cost: clientBudget,
  //   //       total_cost: Number(Gst) + Number(clientBudget),
  //   //       total_earning: creatorCost,
  //   //       note: note,
  //   //       milestone: mileIs,
  //   //     };
  //   //     this.setState({ LoadingIs: true });
  //   //     flag++;
  //   //     obj2 = Object.assign(data)
  //   //   }

  //   // }
  //   // if (flag === 2) {
    
    
  //   // if (flag === 1) {
  //   //   // const finalObj = Object.assign(obj1, obj2)
  //   //   const finalObj = Object.assign(obj1)
  //   //   try {
  //   //     const res = await postAPI('/project/add-post-project-clone', finalObj);
  //   //     console.log(res);
  //   //     alert("Success")
  //   //     const projectid = res.data.users.post_project_id
  //   //     this.props.history.push(`/transactions/transactionsDetails/:${projectid}`)
  //   //   } catch (ex) {
  //   //     alert('something went wrong')
  //   //   }
  //   // }
  // }

    async handleLastSubmit(e) {
    const {
        // Gst,
        // clientBudget,
        // note,
        // creatorCost,
        // milestoneInputs,
        // projectDetails,
        selectedOption,
        minBudget,
        maxBudget,
        unit,
        project,
        projectDescription,
        selectedClient,
        selectedCreator,
        deadlineStatus,
        companyName,
        companyJob,
        companyLink,
        companyIndustry,
        companyDesc,
        companyType,
        selectedEmp,
        selectedLocation,
        selectedLocIndividual,
        clientAll,
        creatorAll,
        hourWeek,
        monthValue,
        sub_type,
        Collateral_style_question,
        Collateral_type_question
      } = this.state;
      
      let resultTyle = Collateral_type_question
      let resultStyle = Collateral_style_question

      let typeCheck = []
      let styleCheck = []

      resultTyle.forEach(ele => {
        let filter = ele.option.filter(obj => obj.check === true)
        ele.answer = filter?.length > 0 ? filter : []
        if (filter?.length == 0) {
          typeCheck.push("ok")
        }
      })

      resultStyle.forEach(ele => {
        let filter2 = ele.option.filter(obj => obj.check === true)
        ele.answer = filter2?.length > 0 ? filter2 : []
         if (filter2?.length == 0) {
          styleCheck.push("ok")
        }
      })

      let arr = this.validate(typeCheck,styleCheck);
      // console.log("arr = ", arr)
      let result = "Required Filds : \n \n"
      if (arr.length > 0) {
        arr.forEach((ele,i) => {
          result = result + (i+1) + ". " + ele + "\n"
        })
        alert(result)
      }else {
        let creatorArr = []
        creatorAll.forEach(obj => {
          creatorArr.push(obj._id)
        })
        
        this.setState({load:true})

        const data = {
          collateral_id: selectedOption._id,
          client_id: clientAll._id,
          creator_id: creatorArr,
          min_budget: minBudget,
          max_budget: maxBudget,
          unit: sub_type == "retainer" || selectedOption?.pricing_unit != "per_unit" ? 0 : unit ? unit : "",
          dealline: deadlineStatus,
          project_name: project,
          project_description: projectDescription,
          company_work_type: companyType,
          company_name: companyType !== "Individual" ? companyName ? companyName.label : "" : "",
          company_job_role: companyType !== "Individual" ? companyJob : "",
          company_add_link: companyType !== "Individual" ? companyLink : "",
          company_location: companyType !== "Individual" ? selectedLocation ? selectedLocation.label : "" : "",
          company_employees: companyType !== "Individual" ? selectedEmp ? selectedEmp.label : "" : "",
          company_describes: companyType !== "Individual" ? companyDesc : "",
          company_industry: companyIndustry ? companyIndustry.label : "",
          company_individual_location: companyType === "Individual" ? selectedLocIndividual
            ? selectedLocIndividual.label
            : "" : "",
          number_month: sub_type == "retainer" ? monthValue : 0,
          number_hour: sub_type == "retainer" ? hourWeek : 0,
          sub_type,
          question_answer_style: styleCheck.length == 0 ? resultStyle : [],
          question_answer_type: typeCheck.length == 0 ? resultTyle : [],
          project_from:"admin_clone_project"
        };

        // console.log("data = ",data)
        this.handleApi(data)
      }
  }
  
  handleApi = async (data) => {
     try {
        const res = await postAPI('/project/add-post-project-clone', data);
        // console.log(res);
       this.setState({load:false})
        alert("Success")
        this.props.history.push(`/transactions`)
     } catch (ex) {
       this.setState({load:false})
        alert('something went wrong')
      }
  }
  
  validate(typeCheck,styleCheck){
    const { unit, sub_type, selectedLocIndividual, clientAll, creatorAll, hourWeek, monthValue,selectedOption,
          companyName,companyJob,companyLink,selectedLocation,selectedEmp,companyDesc,companyIndustry,Collateral_type_question,Collateral_style_question,
      minBudget, maxBudget, project, companyType,projectDescription } = this.state;
    let array = []
    if (!project) {
      array.push("project")
    } 
    if (!selectedOption?.label) {
      array.push("choose collateral")
    }
    if (!minBudget) {
      array.push("minBudget")   
    }
    if (!maxBudget) {
      array.push("maxBudget")   
    }
    if (Number(minBudget) > Number(maxBudget)) {
      array.push("Max Budget will be grater from min budget")   
    }if ((!unit || unit == 0) && sub_type != "retainer" && selectedOption?.pricing_unit === "per_unit") {
      array.push("total GIFs")   
    }
    // if (!projectDescription) {
    //   array.push("project description")   
    // }
    if (typeCheck.length > 0 && (typeCheck.length < Collateral_type_question.length)) {
      array.push("please attend all or none type question")   
     }
    if (styleCheck.length > 0 && (styleCheck.length < Collateral_style_question.length)) {
      array.push("please attend all or none style question")   
    }
    if (!clientAll && !clientAll?._id) {
      array.push("please choose client")   
    }
    if (!creatorAll || creatorAll?.length == 0) {
      array.push("please choose creator")   
    }
    if (sub_type === "retainer") {
      if (!monthValue || monthValue == 0) {
      array.push("How many months")
      }
      if (!hourWeek || hourWeek == 0) {
        array.push("How many hours")  
      }
    }
    if (companyType === "Individual") {
      if (!selectedLocIndividual?.label) {
        array.push("Where are you based out of ?") 
      }
    }
    if (companyType !== "Individual") {
      if (!companyName?.label) {
        array.push("company name")   
      }
      if (!companyJob) {
        array.push("role/job title at the company")   
      }
      const isLink = companyLink && /[(http(s)?):(www)?a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/.test( companyLink);
      if (!companyLink) {
        array.push("website or social link")   
      }
      if (companyLink && !isLink) {
        array.push("Enter valid link")   
      }
      if (!selectedLocation?.label) {
        array.push("your company based out of?")   
      }
      if (!selectedEmp?.label) {
        array.push("company employees")   
      }
      if (!companyIndustry?.label) {
        array.push("company industry")   
      }
    }
    return array
  }

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    // validationForm(name, value, this.state);
  };

  // handleInputChange = (newValue) => {
  //   const inputValue = newValue.replace(/\W/g, "");
  //   this.setState({ inputCollateral: inputValue }, () => {
  //     this.handleCollateralList(inputValue);
  //   });
  // };

  handleCollChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleUserList = (emailValue) => {
    getPublicAPI(`/postProject/get-user-for-project?email=${emailValue}`).then(
      (res) => {
        this.setState({ AllClientList: res.data.data ? res.data.data : "" });
      }
    );
  };

  handleClientList = (clientValue) => {
    getPublicAPI(
      `/postProject/get-client-for-project?email=${clientValue}`
    ).then((res) => {
      const response = res.data.data;
      if (response) {
        this.setState({ clientListIs: response });
      }
    });
  };

  handleCreatorList = (creatorEmail) => {
    const { selectedOption } = this.state;
    const id = selectedOption ? selectedOption._id : "";
    if (id) {
      getPublicAPI(
        `/postProject/get-creator-for-project?email=${creatorEmail}&collateral_id=${id}`
      ).then((res) => {
        const response = res.data.data;
        if (response) {
          this.setState({ creatorListIs: response });
        }
      });
    }
  };

  handleClient = (inputValue) => {
    this.handleClientList(inputValue);
  };

  handleClientDetails = (id) => {
    // console.log("id = ",id)
    getPublicAPI(`/users/get-company-details?userId=${id}`).then(
        (res) => {
        let data = res?.data
        // console.log("data = = ", data)
        this.setState({
          companyType: data?.company_work_type?.toLowerCase() == "individual" ? "Individual" : data?.company_work_type?.toLowerCase() == "company" ? "Company" : "Agency",
          companyName: data
            ? { label: data.company_name, value: data.company_name }
            : "",
          companyJob: data?.company_job_role,
          companyLink: data?.company_add_link,
          companyDesc: data?.company_describes,
          selectedLocation: data
            ? {
              value: data.company_location,
              label: data.company_location,
            }
            : "",
          companyIndustry: data
            ? {
              value: data.company_industry,
              label: data.company_industry,
            }
            : "",
          selectedEmp: data
            ? {
              value: data.company_employees,
              label: data.company_employees,
            }
            : "",
          selectedLocIndividual: data.company_individual_location
            ? {
              value: data.company_individual_location,
              label: data.company_individual_location,
            }
            : "",
        });
      }
      ).catch(err => {
      console.log(err)
    })
  }

  handleClientChange = (selectedClient) => {
    // console.log("clientListIs = ",this.state.clientListIs)
    let arr = this.state.clientListIs.filter(obj => obj?._id === selectedClient?._id)
    let op = arr ? arr[0] : ""
    this.setState({ selectedClient, clientMsg: "", clientAll: op }, () => {
      this.handleClientDetails(this.state.selectedClient?._id)
    });
  };

  handleCreatorChange = (selectedCreator) => {
    // console.log("clientListIs = ",this.state.creatorListIs)
    let arr = this.state.creatorListIs.filter(obj => obj?._id === selectedCreator?._id)
    let check = arr ? this.state.creatorAll.find(obj => obj?._id === arr[0]._id) ? [] : arr : []
    this.setState({ selectedCreator, creatorMsg: "", creatorAll: [...this.state.creatorAll, ...check] });
  };

  handleCreator = (inputValue) => {
    this.handleCreatorList(inputValue);
  };

  handleRepresntative = (inputValue) => {
    this.handleUserList(inputValue);
  };

  handleCompanyType = (status) => {
    const {
      errorIs,
      companyIndustry,
      selectedLocation,
      selectedEmp,
    } = this.state;
    this.setState({ companyType: status });
    if (status === "Company" || status === "Agency") {
      this.setState({
        locationIndividualMsg: "",
        empMsg: selectedEmp && selectedEmp.label && "",
        locationMsg: selectedLocation && selectedLocation.label && "",
        industryMsg: companyIndustry && companyIndustry.label && "",
        // selectedLocIndividual:"",locationIndividualMsg: ""
      });
    } else if (status === "Individual") {
      errorIs.companyName = "";
      errorIs.companyJob = "";
      errorIs.companyLink = "";
      errorIs.companyDesc = "";
      this.setState({
        empMsg: "", locationMsg: "", industryMsg: "", errorIs,
      });
    }
  };

  handleChangeEmp = (selectedEmp) => {
    this.setState({ selectedEmp: selectedEmp, empMsg: "" });
  };

  handleLocationSelect = (selectedLocation) => {
    this.setState({ selectedLocation, locationMsg: "" });
  };

  handleLocaIndividual = (selectedLocIndividual) => {
    this.setState({ selectedLocIndividual, locationIndividualMsg: "" });
  };

  handleIndustrySelect = (companyIndustry) => {
    this.setState({ companyIndustry, industryMsg: "" });
  };

  handleChange = (companyName) => {
    this.setState({ companyName, companyMsg: "" });
  };

  handleOnChangeType = (e) => {
    const { name, value } = e.target;
    this.setState({[name]:value})
  };

  handleChangeTypeValue = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      // this.handleValidate(name)
    })
  };

  handleValidate = (name) => {
    const { hourWeek, monthValue } = this.state;
    let h = ""
    let m = ""
    switch (name) {
      case "hourWeek":
        h = !hourWeek || hourWeek == 0 ? "Required Field" : "";
        break;
      case "monthValue":
        m = !monthValue || monthValue == 0 ? "Required Field" : "";
        break;
    }
    this.setState({hourWeekMes:h,monthValueMes:m});
  }

  handleCollateralList = (inputCollateral) => {
    this.setState({ chooseCollateralIs: false });
    this.props.$AllCollateralList(inputCollateral);
  };

  handleGetProjectData = (id) => {
    this.setState({ checkApi: true }, () => {
      this.props.$getPostProjectData(id);
     });
  };

  handleTransactionData = (obj) => {
    this.props.$transactionGetData(obj);
  };

  componentDidMount() {
    const newValue = "";
    const transactionId = this.props.match.params.id.split(":")?.[1].split("=");
    // console.log("transactionId = ", transactionId)
    if (transactionId[1]) {
      this.handleGetProjectData(transactionId[0]);
    } else {
      this.handleTransactionData(transactionId[0]);
    }
    this.handleCollateralList("");
    this.handleUserList(newValue);
    this.handleClientList(newValue);
    this.handleCreatorList(newValue);
    setTimeout(() => {
      this.setState({
        EditIs: true,
        editRes: true,
      });
    }, 200);
  }

  componentWillReceiveProps(nextProps) {
    const { LoadingIs, LoaderIs, EditIs, flag,checkApi } = this.state;
    let getData = []
    let projectData = []
    let arr = []
    let id_client = ""
    let num_hour = 0
    let num_month = 0
    let su_type = "fixed"
    if (checkApi) {
      getData = nextProps._getPostProjectData.postProjectDataIs;
      projectData = getData?.project;
      arr.push(getData?.users?.creator_id)
      id_client = getData?.users?.client_id
      su_type = getData?.project?.sub_type?.length > 0 ? getData.project.sub_type : "fixed"
      num_hour = getData?.project?.number_month
      num_month = getData?.project?.number_hour
      
    } else {
      getData = nextProps._transactionGetData?.transactionData;
      projectData = getData?.project_data;
      su_type = getData?.project_data?.sub_type?.length > 0 ? getData.project_data.sub_type : "fixed"
      num_hour = getData?.project_data?.number_month
      num_month = getData?.project_data?.number_hour
      let creatorUsers = getData?.creatorUser
      // console.log("creatorUsers 1 = ", creatorUsers)
      if (creatorUsers) {
        creatorUsers.forEach(ele => {
          arr.push(ele?.user_id2)
        });
      }
      id_client = getData?.clientUser?.user_id1
    }
    // console.log("getData = ", getData)

    const dataList = nextProps._AllCollateralList.allCollateralIs;
    // const projectData = nextProps._addPostProject.addProjectIs;
    // const projectData = getData?.project_data;
    const updateData = nextProps._updateProject.updateDataIs;
    const feedbackIs = getData.completeProjectReview;
    const closeReason = getData.closeProjectReview;
    const lastMsgData = getData.lastMsg;

    if (projectData && LoadingIs) {
      this.setState({ LoadingIs: false });
    }
    if (dataList.status === "1") {
      this.setState({
        collateralData: dataList ? dataList.data : "",
      });
    }
    // if (getData && EditIs && flag) {
    if (getData) {
      const collateralData = getData?.collateral;
      // const projectData = getData?.project_data;
      // const userData = getData.users;
      // const clientNow = userData.client_id;
      // const creatorNow = userData.creator_id;
      // const repNow = userData.representative_id;

      this.handleQuesAns(getData,collateralData);

      this.setState({
        projectDetailsIs: getData,
        selectedOption: collateralData
          ? {
            value: collateralData.name,
            label: collateralData.name,
            ...collateralData,
          }
          : "",
        deadlineStatus: projectData?.dealline,
        minBudget: projectData?.min_budget,
        maxBudget: projectData?.max_budget,
        project: projectData?.project_name,
        projectDescription: projectData?.project_description,
        unit: projectData?.unit,
        StatusIs: projectData?.status,
        LastMsgIs: lastMsgData ? lastMsgData : "",
        flag: false,
        listIs: false,
        // selectedClient: clientNow
        //   ? {
        //       label: `${clientNow.first_name} ${clientNow.last_name} (${clientNow.email})`,
        //       _id: clientNow._id,
        //       value: clientNow.first_name + " " + clientNow.last_name,
        //       email: clientNow.email,
        //     }
        //   : "",
        // selectedCreator: creatorNow
        //   ? {
        //       _id: creatorNow._id,
        //       label: `${creatorNow.first_name} ${creatorNow.last_name} (${creatorNow.email})`,
        //       value: creatorNow.first_name + " " + creatorNow.last_name,
        //       email: creatorNow.email,
        //     }
        //   : "",
        // selectedRepresntative: repNow
        //   ? {
        //       _id: repNow._id,
        //       label: `${repNow.first_name} ${repNow.last_name} (${repNow.email})`,
        //       value: repNow.first_name + " " + repNow.last_name,
        //       email: repNow.email,
        //     }
        //   : "",
        editRes: false,
        // clientId: userData?.client_id,
        // creatorId: userData?.creator_id,

// --------------------------------company details-----------------------------------
        
        // companyType: projectData?.company_work_type == "individual" ? "Individual" : projectData?.company_work_type == "company" ? "Company" : "Agency",
        // companyName: projectData
        //   ? { label: projectData.company_name, value: projectData.company_name }
        //   : "",
        // companyJob: projectData?.company_job_role,
        // companyLink: projectData?.company_add_link,
        // companyDesc: projectData?.company_describes,
        // selectedLocation: projectData
        //   ? {
        //     value: projectData.company_location,
        //     label: projectData.company_location,
        //   }
        //   : "",
        // companyIndustry: projectData
        //   ? {
        //     value: projectData.company_industry,
        //     label: projectData.company_industry,
        //   }
        //   : "",
        // selectedEmp: projectData
        //   ? {
        //     value: projectData.company_employees,
        //     label: projectData.company_employees,
        //   }
        //   : "",
        // selectedLocIndividual: projectData.company_individual_location
        //   ? {
        //     value: projectData.company_individual_location,
        //     label: projectData.company_individual_location,
        //   }
        //   : "",
        
// -------------------------------------------------------------------------------------------------
        
        // ReviewIs:
        //   projectData.status === 7 && feedbackIs
        //     ? feedbackIs
        //     : (projectData.status === 8 && closeReason) ||
        //       (projectData.status === 5 && closeReason) ||
        //       (projectData.status === 6 && closeReason)
        //       ? closeReason
        //       : [],
        // projectCompDate: projectData?.project_completed_date,
        // reviewQuestn: getData.completeProjectQuestion,

        clientAll: id_client,
        creatorAll: arr,
        sub_type: su_type,
        hourWeek: num_hour,
        monthValue: num_month
      }, () => {
           this.handleClientDetails(id_client?._id)
      });
    }
    if (updateData && LoaderIs) {
      alert("Successfully Updated ...");
      this.setState({ LoaderIs: false });
    }
  }
  handleMilestoneChange = (e, index) => {
    const { milestoneInputs } = this.state;
    if (e.target) {
      const { name, value } = e.target;
      const list = [...milestoneInputs];
      list[index][name] = value;
      this.setState({
        [name]: value,
      });
      const currentMile = milestoneInputs[index];
      if (e.target.name === "name") {
        currentMile.nameError && delete currentMile.nameError;
      } else {
        currentMile.iterationError && delete currentMile.iterationError;
      }
      this.setState({ milestoneInputs });
    } else {
      const list = [...milestoneInputs];
      const currentDate = e;
      list[index].due_date = currentDate.toDateString();
      list[index].selectedDate = currentDate.toDateString();
      if (milestoneInputs.length > 1) {
        const nextDateIs =
          milestoneInputs[index + 1] &&
          new Date(milestoneInputs[index + 1].due_date);
        if (nextDateIs && this.getDateDiff(currentDate?.toDateString(), nextDateIs?.toDateString()) > 0) {
          milestoneInputs[index + 1].dateError = true;
          const currentMileIs = milestoneInputs[index];
          currentMileIs.dateError && delete currentMileIs.dateError;
        } else {
          const currentMileIs = milestoneInputs[index];
          currentMileIs.dateError && delete currentMileIs.dateError;
          this.setState({ milestoneInputs });
        }
      } else {
        const currentMileIs = milestoneInputs[index];
        currentMileIs.dateError && delete currentMileIs.dateError;
      }
      this.setState({ milestoneInputs });
    }
  };


  getDateDiff = (a, b) => {
    if (a) {
      const x = moment(a);
      const y = moment(b);
      const r = x.diff(y, "days");
      return r;
    }
  };
  validation1 = () => {
    const { errorIs, Gst, clientBudget, creatorCost } = this.state;
    errorIs.Gst = Gst === "" ? "Required Field" : "";
    errorIs.clientBudget = clientBudget === "" ? "Required Field" : "";
    errorIs.creatorCost = creatorCost === "" ? "Required field" : "";

    this.setState({ errorIs });
    this.validationMileStone();
    return !errorIs.Gst && !errorIs.clientBudget && !errorIs.creatorCost;
  };
  validationMileStone = () => {
    const { milestoneInputs } = this.state;
    let formValidIs = true;
    const updatedMileStone = milestoneInputs.map((obj, index) => {
      if (obj.name === "") {
        milestoneInputs[index].nameError = true;
        formValidIs = false;
      }
      if (obj.due_date === "") {
        milestoneInputs[index].dateError = true;
        formValidIs = false;
      } else if (obj.due_date !== "" && milestoneInputs[index].dateError) {
        formValidIs = false;
      }
      if (obj.iteration === "" || !(obj.iteration > 0)) {
        milestoneInputs[index].iterationError = true;
        formValidIs = false;
      }
      return obj;
    });
    this.setState({ milestoneInputs: updatedMileStone });
    return formValidIs;
  };

  handleProposalSubmit = () => {
    const {
      Gst,
      clientBudget,
      note,
      creatorCost,
      milestoneInputs,
      projectDetails,
    } = this.state;

    if (this.validation1() && this.validationMileStone()) {
      const mileIs = milestoneInputs.map((obj, index) => {
        let dataMile = {
          name: obj.name,
          due_date: obj.due_date,
          number: index + 1,
          iteration: obj.iteration,
        };
        return dataMile;
      });
      if (projectDetails) {
        const data = {
          creator_id: projectDetails.users
            ? projectDetails.users.creator_id
            : "",
          tax: Gst,
          sub_total_cost: clientBudget,
          total_cost: Number(Gst) + Number(clientBudget),
          total_earning: creatorCost,
          note: note,
          milestone: mileIs,
        };
        this.setState({ LoadingIs: true });
        return data
      }

    }
  };
  handleAppendMilestone = () => {
    const { milestoneInputs } = this.state;
    var list = [...milestoneInputs, { name: "", due_date: "", iteration: "" }];
    this.setState({
      milestoneInputs: list,
      selectedDate: milestoneInputs.slice(-1).pop().due_date
        ? milestoneInputs.slice(-1).pop().due_date
        : new Date(),
    });
  };

  handleRemoveMilestone = (index, obj) => {
    const { milestoneInputs, DeletedMileStone } = this.state;
    if (index !== -1) {
      const remainMilestone = milestoneInputs.filter((_, i) => i !== index);
      const deletedMileStone = DeletedMileStone.concat(obj);

      this.setState({
        milestoneInputs: remainMilestone,
        DeletedMileStone: deletedMileStone,
      });
    }
  };

  handleViewcompletedMiles = (obj, index) => {
    this.setState({
      submitMilesOpen: true,
      deliverableMiles: index + 1,
      submitMilesData: obj,
    });
    this.props.$completedMiles(obj._id);
  };

  handleCloseMilesModal = () => {
    this.setState({ submitMilesOpen: false });
  };

  handleGetConfirmation = (currentData) => {
    let confirmIs = window.confirm(`Do you want to approve this Milestone ?`);
    if (confirmIs === true) {
      this.setState({ milesApprovedIs: true });
      this.props.$milestoneApproved(currentData);
      return true;
    } else {
      return false;
    }
  };

  handleApprovedMiles = (obj) => {
    const currentData = {
      proposal_id: obj.proposal_id,
      milestone_id: obj._id,
      type: "milestone",
    };
    this.handleGetConfirmation(currentData);
  };
  handleChange1 = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.validationForm1(name, value);
  };
  validationForm1 = (name, value) => {
    const { errorIs } = this.state;
    switch (name) {
      case "Gst":
        errorIs.Gst = value === "" ? "Required Field" : "";
        break;
      case "clientBudget":
        errorIs.clientBudget = value === "" ? "Required Field" : "";
        break;
      case "creatorCost":
        errorIs.creatorCost = value === "" ? "Required field" : "";
        break;
      default:
        break;
    }
  };

  handleUpdateProposal = () => {
    const {
      Gst,
      clientBudget,
      note,
      creatorCost,
      milestoneInputs,
      projectDetails,
      DeletedMileStone,
    } = this.state;
    if (this.validation1() && this.validationMileStone()) {
      if (projectDetails) {
        const ExistMile = milestoneInputs.filter((item) => item._id);
        const currentExistMile = ExistMile.map((obj, index) => {
          let dataMile = {
            _id: obj._id,
            name: obj.name,
            due_date: obj.due_date,
            iteration: obj.iteration,
            number: index + 1,
          };
          return dataMile;
        });
        const newMile = milestoneInputs.filter(
          ({ _id: id1 }) =>
            !projectDetails.Milestone.some(({ _id: id2 }) => id2 === id1)
        );

        const currentNewMile = newMile.map((obj, index) => {
          let dataMile = {
            name: obj.name,
            due_date: obj.due_date,
            iteration: obj.iteration,
            number: index + 1 + currentExistMile.slice(-1).pop().number,
          };
          return dataMile;
        });
        const currentData = {
          creator_id: projectDetails.users
            ? projectDetails.users.creator_id
            : "",
          proposal_id: projectDetails.Proposal
            ? projectDetails.Proposal._id
            : "",
          tax: Gst,
          sub_total_cost: clientBudget,
          total_cost: Number(Gst) + Number(clientBudget),
          total_earning: creatorCost,
          note: note,
          milestone_existing: currentExistMile,
          milestone_new: currentNewMile,
          milestone_delete: DeletedMileStone,
        };

        this.setState({ LoaderIs: true });
        this.props.$updateProposal(currentData);
      }
    }
  };

  getDeleteCreator = (id) => {
    const { creatorAll } = this.state;
    let arr = creatorAll.filter(obj => obj?._id !== id)
    this.setState({ creatorAll: arr })
  }

  handleTypeOptions = (val,idx,ix) => {
    let array = this.state.Collateral_type_question
    array.forEach((ele,i) => {
      ele.option.forEach((val, index) => { 
        if (idx === index) {
          val.check = !val.check
        }
      })
    })
    this.setState({Collateral_type_question:array})
  }

  handleStyleOptions = (val,idx,ix) => {
    let array = this.state.Collateral_style_question
    array.forEach((ele,i) => {
      ele.option.forEach((val, index) => { 
        if (idx === index) {
          val.check = !val.check
        }
      })
    })
    this.setState({Collateral_style_question:array})
  }

  handleQuesAns = async (getData,collateral) => {
    try {
      const res = await getPublicAPI(`/project/get-collateral-global?_id=${collateral._id}`);
      if (res?.data) {
        let data1 = []
        let data2 = []

        let testData = getData
        if (testData?.ProjectTypeQuestion && testData?.ProjectTypeQuestion?.length > 0) {
          data1 = testData.ProjectTypeQuestion
          data1.forEach(ele => {
            ele.option.forEach(val => {
              let flt = ele.answer.filter(obj => (val.item === obj.item) && (val.image === obj.image))
              if (flt.length > 0) {
                val.check = true
              } else {
                val.check = false
              }
            })
          })
        } else {
          data1 = res.data?.Collateral_type_question
          data1.forEach(ele => {
            ele.option.forEach(val => {
              val.check = false
            })
          })
        }

        if (testData?.projectStyleQuestion && testData?.projectStyleQuestion?.length > 0) {
          data2 = testData.projectStyleQuestion
          data2.forEach(ele => {
            ele.option.forEach(val => {
              let flt = ele.answer.filter(obj => (val.item === obj.item) && (val.image === obj.image))
              if (flt.length > 0) {
                val.check = true
              } else {
                val.check = false
              }
            })
          })
        } else {
          data2 = res.data?.Collateral_style_question
          data2.forEach(ele => {
            ele.option.forEach(val => {
            val.check = false
            })
          })
        }

        // console.log("1 = ",data1,data2)
        this.setState({Collateral_style_question: data2,Collateral_type_question:data1})
      }
    } catch (ex) {
      console.log('something went wrong')
    }
  }

  render() {
    const {
      selectedOption,
      collateralData,
      StatusIs,
      ReviewIs,
      projectCompDate,
      LastMsgIs,
      clientAll,
      creatorAll,
      sub_type,
      monthValue,
      monthValueMes,
      hourWeek,
      hourWeekMes,
      Collateral_type_question,
      Collateral_style_question
    } = this.state;

    const optionData = collateralData?.map((obj) => {
      let options = {
        value: obj.name,
        label: obj.name,
        ...obj,
      };
      return options;
    });
    const projectFunc = {
      handleOnChange: this.handleOnChange,
      handleClientChange: this.handleClientChange,
      handleCreatorChange: this.handleCreatorChange,
      handleCreator: this.handleCreator,

      handleRepresntative: this.handleRepresntative,
      handleClient: this.handleClient,
      handleCompanyType: this.handleCompanyType,
      handleLocationSelect: this.handleLocationSelect,
      handleChangeEmp: this.handleChangeEmp,
      handleLocaIndividual: this.handleLocaIndividual,
      handleIndustrySelect: this.handleIndustrySelect,
      handleChange: this.handleChange,
    };
    const milesFunc = {
      handleCloseMilesModal: this.handleCloseMilesModal,
      handleChange: this.handleChange1,
      handleMilestoneChange: this.handleMilestoneChange,
      handleRemoveMilestone: this.handleRemoveMilestone,
      handleViewcompletedMiles: this.handleViewcompletedMiles,
      handleApprovedMiles: this.handleApprovedMiles,
      handleAppendMilestone: this.handleAppendMilestone,
    };

    return (
      <React.Fragment>
        <section className="container">

      <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="mb-3" style={{fontSize:"18px"}}>Type</label>
              <div className="row">
                <div className="col-2">
                  {/* <small className="text-muted"> */}
                    <input
                      type="radio"
                      name="sub_type"
                      checked={sub_type === "fixed"}
                      value={"fixed"}
                      onChange={this.handleOnChangeType}
                    />{" "}
                    Fixed
                  {/* </small> */}
                </div>
                <div className="col-2">
                  {/* <small className="text-muted"> */}
                    <input
                      type="radio"
                      name="sub_type"
                      checked={sub_type === "retainer"}
                      value={"retainer"}
                      onChange={this.handleOnChangeType}
                    />{" "}
                    Retainer
                  {/* </small> */}
                </div>
              </div>
            </div>
            </div>

            {sub_type === "retainer" &&
              <div className="col-12">
                <div className="form-group">
                  <div className="row">
                    <div className="col">
                      <label htmlFor="exampleInputEmail1" className="text-secondary">
                        How many months are you looking to hire the resource for?
                      </label>
                      <input
                        type="number"
                        className={`form-control ${monthValueMes ? `error-show` : ``}`}
                        name="monthValue"
                        value={monthValue}
                        onChange={this.handleChangeTypeValue}
                      />
                      {monthValueMes && <span className="error">{monthValueMes}</span>}
                    </div>
                    <div className="col">
                      <label htmlFor="exampleInputEmail1" className="text-secondary">
                        How many hours do you expect the resource to work in a week?
                      </label>
                      <input
                        type="number"
                        className={`form-control ${hourWeekMes ? `error-show` : ``}`}
                        name="hourWeek"
                        value={hourWeek}
                        onChange={this.handleChangeTypeValue}
                      />
                      {hourWeekMes && <span className="error">{hourWeekMes}</span>}
                    </div>
                  </div>
                </div>
              </div>
            }
            
        </div>

          <form>
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <h2
                    htmlFor="exampleInputEmail2"
                    className="header-title text-secondary"
                  >
                    Choose Collateral
                  </h2>
                  <div className="my-3"></div>
                  <Select
                    value={selectedOption}
                    onChange={this.handleCollChange}
                    options={optionData}
                    // onInputChange={this.handleInputChange}
                    cacheOptions
                    defaultOptions
                  />
                </div>
              </div>
              <hr className="my-5" />
            </div>

            {/*----------------------Project Other Details --------------------------*/}

            {/* {selectedOption && ( */}


              <ProjectOtherDetails noRep='true'
                {...this.state}
                {...this.props}
                handleFunc={projectFunc}
                disab="clone"
              />
            {/* )} */}

            {clientAll &&
              <>
                <hr className="my-5" />
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <h2 > Client </h2>
                    </div>
                    <div className="col-6">
                      <div className="card mb-0 card-new">
                        <div className="card-body">
                          <div className="row align-items-center">
                            <div className="col-auto rounded">
                              <a
                                href={`/clients/:edit/:${clientAll?._id}`}
                                className="avatar avatar-lg"
                              >
                                <img
                                  src={
                                    clientAll?.profile_image
                                      ? userProfileBaseUrl +
                                      clientAll?.profile_image
                                      : require("../../../Assets/img/avatars/profiles/images.jpeg")
                                  }
                                  alt="..."
                                  className="avatar-img rounded"
                                />
                              </a>
                            </div>
                            <div className="col ml-n2">
                              <h4 className="card-title mb-1 name">
                                <a href={`/clients/:edit/:${clientAll?._id}`}>
                                  {clientAll?.first_name +
                                    " " +
                                    clientAll.last_name}
                                </a>
                              </h4>

                              <p className="card-text small text-muted mb-1">
                                <i className="fe fe-user"></i> Client
                              </p>
                              {/* {transactionsStatus === 3 && (
                            <div className="col-auto text-right">
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={this.handleEditClient}
                              >
                                Edit
                              </button>
                            </div>
                          )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }

            {creatorAll.length > 0
              ?
              <>
                <hr className="my-5" />
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <h2 > Creator </h2>
                    </div>
                    {creatorAll.map((obj, index) => (
                      <div className="col-6" key={index}>
                        <div className="card card-new">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-auto">
                                <a
                                  href={`/artist/:edit/:${obj?._id
                                    }`}
                                  className="avatar avatar-lg"
                                >
                                  <img
                                    src={
                                      obj?.profile_image
                                        ? userProfileBaseUrl +
                                        obj?.profile_image
                                        : require("../../../Assets/img/avatars/profiles/images.jpeg")
                                    }
                                    alt="..."
                                    className="avatar-img rounded"
                                  />
                                </a>
                              </div>
                              <div className="col ml-n2">
                                <h4 className="card-title mb-1 name">
                                  <a
                                    href={`/artist/:edit/:${obj?._id
                                      }`}
                                  >
                                    {obj?.first_name +
                                      " " +
                                      obj?.last_name}
                                  </a>
                                </h4>
                                {/* <p>Total Projects: {obj?.totalProject}</p> */}
                                <p className="card-text small text-muted mb-1">
                                  <i className="fe fe-user"></i> Creator
                                </p>
                                {/* {(obj?.status === 2 || obj?.status === 4) &&
                                  obj?.reason &&
                                  obj?.reason.reason.map((object, index) => (
                                    <p
                                      className="card-text small text-muted mb-1"
                                      key={index}
                                    >
                                      {object?.text}
                                    </p>
                                  ))} */}
                                {/* {(obj?.status === 2 || obj?.status === 4) &&
                                  obj?.reason && (
                                    <p className="card-text small text-muted mb-1">
                                      (Reason)
                                    </p>
                                  )} */}
                              </div>
                              <div className="col-auto">
                                <div className="avatar-group d-none d-md-inline-flex">
                                  {/* {obj?.status === 1 && obj?.last_msg &&
                              <div
                                className={`badge mr-2 badge-soft-success`}
                              >
                                Approved
                              </div>
                            } */}
                                  {/* {(obj?.status === 1 ||
                              obj?.status === 2 ||
                              obj?.status === 3 ||
                              obj?.status === 4 ||
                              obj?.status === 0) && (
                                <div
                                  className={`badge mr-2 ${obj?.status === 0
                                      ? `badge-soft-info`
                                      : obj?.proposal &&
                                        obj?.proposal.proposal.status === 2
                                        ? `badge-soft-success`
                                        : obj?.status === 1
                                          ? `badge-soft-primary`
                                          : obj?.status === 2 || obj?.status === 4
                                            ? `badge-soft-danger`
                                            : obj?.status === 3
                                              ? `badge-soft-warning`
                                              : ``
                                    }`}
                                >
                                  {obj?.status === 0
                                    ? "Pending"
                                    : obj?.proposal &&
                                      obj?.proposal.proposal.status === 2
                                      ? `Completed`
                                      : obj?.status === 1
                                        ? "Accepted"
                                        : obj?.status === 2 || obj?.status === 4
                                          ? "Rejected"
                                          : obj?.status === 3
                                            ? "Asking"
                                            : ""
                                  }
                                </div>
                              )} */}

                                  {/* {obj?.status !== 0 && obj?.status !== 2 && obj?.status !== 4 && !isProjectActive &&
                              <div
                                className="badge badge-soft-secondary mr-2"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.rejectCreator(obj)}
                              >
                                Reject
                              </div>
                            } */}

                                  <div
                                    className="badge badge-soft-danger mr-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.getDeleteCreator(obj?._id)}
                                  >
                                    Delete
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                    }
                  </div>
                </div>
              </>
              : ""
            }

                        {/* -------------------------------------------types--------------------------- */}
            <hr className="my-5" />
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <h2>Type Questions</h2>
                  {Collateral_type_question?.map((ele,i) => {
                    return <>
                    <label key={i + "a"} className="col-12">{ele?.question}</label>
                    {ele?.option?.length > 0
                        ? ele?.option.map((val, index) => {
                      return <>
                        <div
                          className="col-4 mb-3"
                          key={index + "b"}
                          style={{cursor:"pointer"}}
                          onClick={(e) => this.handleTypeOptions(val,index,i)}
                        >
                          <div
                          key={index + "e"}
                          className={`questin-Listing ${ !val?.check
                              ? ""
                              : "selected-option"
                          }`}
                        >
                          <div className="row">
                            {val.item && (
                              <div className="col-10">
                                <p className="mb-0">{val.item}</p>
                              </div>
                            )}
                            {val.image && (
                              <div className="col-2">
                                <img
                                  src={`${profileBaseUrl + val.image}`}
                                  alt="..."
                                  height="30"
                                  width="30"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        </div>
                        </>
                    })
                        : ""}
                    </>
                  })}
                </div>
              </div>
            </div>  
            <hr className="my-5" />
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <h2>Style Questions</h2>
                  {Collateral_style_question?.map((ele,i) => {
                    return <>
                    <label key={i + "c"} className="col-12">{ele?.question}</label>
                    {ele?.option?.length > 0
                        ? ele?.option.map((val, index) => {
                      return <>
                        <div
                          className="col-4 mb-3"
                          key={index + "d"}
                          style={{cursor:"pointer"}}
                          onClick={() => this.handleStyleOptions(val,index,i)}
                        >
                          <div
                            key={index + "f"}
                            className={`questin-Listing ${ !val?.check
                                ? ""
                                : "selected-option"
                            }`}
                        >
                          <div className="row">
                            {val.item && (
                              <div className="col-10">
                                <p className="mb-0">{val.item}</p>
                              </div>
                            )}
                            {val.image && (
                              <div className="col-2">
                                <img
                                  src={`${profileBaseUrl + val.image}`}
                                  alt="..."
                                  height="30"
                                  width="30"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        </div>
                        </>
                    })
                        : ""}
                    </>
                  })}
                </div>
              </div>
            </div>
            <hr className="my-5" />
             {/* ---------------------------------------------------------------------- */}


            {/*----------------------Project Other Details --------------------------*/}

            {/*---------------- project Company details -------------------------- */}
            {/* {selectedOption && <hr className="my-5" />}
            {selectedOption && ( */}
              <CompanyDetails
                {...this.props}
                {...this.state}
                handleFunc={projectFunc}
              />
            {/* )} */}

            {/*----------------project Company details -------------------------- */}
            {/*------------------------Review -----------------------------*/}

            {/* {ReviewIs && <Review {...this.state} {...this.props} />} */}

            {/*-----------------------Review ------------------------------*/}

            {/*--------------------review Questions -------------------------*/}

            {/* {StatusIs === 7 && <AskQuestion {...this.state} {...this.props} />}
            {StatusIs === 7 && <hr className="my-5" />} */}

            {/*--------------------review Questions -------------------------*/}

            {/*--------------------last msg by--------------------------------*/}

            {/* {LastMsgIs && <LastMsgBy {...this.state} {...this.props} />} */}

            {/*--------------------last msg by--------------------------------*/}

            {/*-----------------------Project status -----------------------*/}

            {/* {StatusIs === 7 && (
              <ProjectStatus projectCompDate={projectCompDate} />
            )} */}

            {/*-----------------------Project status ------------------------*/}
          </form>
        </section>
        {/* seperation or joinment    */}

        <style>
          {`.react-date-picker__clear-button{
              display: none
            }
            .react-date-picker__wrapper{
              border : none
            }
            .disabled{
              opacity : 0.5 !important;
              background-color : #f0f0f0 !important
            }
            
            .react-date-picker__calendar.react-date-picker__calendar--open {
              top: 0!important;
          }
            `}
        </style>
        <button className='btn btn-primary' style={{ float: 'right', marginTop: '15px' }} onClick={e => this.handleLastSubmit(e)} >
          {this.state.load ?
            <div className="d-flex">
              <LoaderSmall />
              <span>Submit</span>
            </div> :
            "Submit"}
        </button>
      </React.Fragment>
    );
  }
}
const mapState = ({
  AllCollateralList,
  getUserList,
  addPostProject,
  getPostProjectData,
  updateProject,
  addProposal,
  updateProposal,
  completedMiles,
  milestoneApproved,
  transactionGetData,
}) => {

  return {
    _getPostProjectData: getPostProjectData,
    _addProposal: addProposal,
    _updateProposal: updateProposal,
    _completedMiles: completedMiles,
    _milestoneApproved: milestoneApproved,
    _AllCollateralList: AllCollateralList,
    _getUserList: getUserList,
    _addPostProject: addPostProject,
    _updateProject: updateProject,
    _transactionGetData: transactionGetData,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $AllCollateralList: (values) => dispatchEvent(AllCollateralList(values)),
  $getUserList: (values) => dispatchEvent(getUserList(values)),
  $addPostProject: (values) => dispatchEvent(addPostProject(values)),
  $getPostProjectData: (values) => dispatchEvent(getPostProjectData(values)),
  $updateProject: (values) => dispatchEvent(updateProject(values)),

  $addProposal: (values) => dispatchEvent(addProposal(values)),
  $updateProposal: (values) => dispatchEvent(updateProposal(values)),
  $completedMiles: (values) => dispatchEvent(completedMiles(values)),
  $milestoneApproved: (values) => dispatchEvent(milestoneApproved(values)),

  $transactionGetData: (values) => dispatchEvent(transactionGetData(values)),
});
export default connect(mapState, mapDispatch)(Clone);
