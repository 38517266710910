import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import {
  checkStatus,
  artistDataList,
  artistApprovalList,
  pendingCreatorApproval
} from "../../../store/action/clientList.action";
import { putAPI } from "../../../store/api-interface";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    maxWidth: "600px",
    width: "100%",
  },
};

class ReviewStatusModal extends Component {
  state = {
    LoadingIs: false,
    reasonIs: "",
  };

  handleOnChange = (e) => {
    if (e.target.value.length < 500) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  handleCloseModal = () => {
    this.props.setArtistState({ showReviewPopUp: false });
  };

  validateForm() {
    const { reasonIs } = this.state;
    return (reasonIs.trim())?.length;
  }

  handleChangeReview = () => {
    this.setState({
      LoadingIs: true,
    });
    const {
      artistId,
      profileReviewStatus,
      artistList,
      firstname,
      lastname,
      username,
      email,
      // reviewStatus,
    } = this.props;
    const { reasonIs } = this.state;

    let reviewIs = "";
    if (profileReviewStatus === 3) {
      reviewIs = { status: profileReviewStatus, _id: artistId, text: reasonIs.trim() };
    } else {
      reviewIs = { status: profileReviewStatus, _id: artistId, text: "" };
    }

    let artist = {
      page: artistList,
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
      sortData: -1
    };

    if (this.validateForm() || profileReviewStatus === 1 || profileReviewStatus === 2 || profileReviewStatus === 5) {
      if (profileReviewStatus === 5) {
        let data = { status: 5, _id: artistId };
        putAPI("/backendUser/change-review-status-new", data)
        .then((res) => {
          if (res.data.status === "1") {
            this.props.$artistDataList(artist);
            this.props.$artistApprovalList(artist);
            this.props.$pendingCreatorApproval(artist)
            this.props.setArtistState({ showReviewPopUp: false });
            this.setState({
              LoadingIs: false,
            });
          }
        })
        .catch((error) => {
          console.log("Change Review api error--->", error);
        });
      } else {
        putAPI("/backendUser/change-review-status", reviewIs)
        .then((res) => {
          if (res.data.status === "1") {
            this.props.$artistDataList(artist);
            this.props.$artistApprovalList(artist);
            this.props.$pendingCreatorApproval(artist)
            this.props.setArtistState({ showReviewPopUp: false });
            this.setState({
              LoadingIs: false,
            });
          }
        })
        .catch((error) => {
          console.log("Change Review api error--->", error);
        });
      }
    
    } else {
      alert("Please Enter Profile Reject Reason.....");
      this.setState({
        LoadingIs: false,
      });
    }
  };

  render() {
    const { LoadingIs, reasonIs } = this.state;
    const { 
      // reviewStatus,
      profileReviewStatus } = this.props;
    return (
      <React.Fragment>
        <div>
          <Modal
            isOpen={true}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <h3 className={`text-center ${profileReviewStatus === 1 || profileReviewStatus === 2  ? "" : "mb-4"}`}>{`${
              profileReviewStatus === 1
                ? "Are you sure you want to move this profile to approval pending?" : profileReviewStatus === 2 ? "Are you sure you want to Accept this profile"
                 : profileReviewStatus === 5 ? "Are you sure you want to Give Access to Onboarding this profile?" : "Are you sure you want to reject this profile?"
            }`}</h3>

            {profileReviewStatus === 3 && (
              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Enter Reason"
                  name="reasonIs"
                  value={reasonIs}
                  onChange={this.handleOnChange}
                ></textarea>
              </div>
            )}
            <div className="mx-auto text-center">
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={this.handleChangeReview}
              >
                {LoadingIs ? "Processing......" : "Yes"}
              </button>
              <button
                type="button"
                className="btn btn-secondary ml-2"
                data-dismiss="modal"
                onClick={this.handleCloseModal}
              >
                No
              </button>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

const mapState = ({ checkStatus, artistDataList, artistApprovalList, pendingCreatorApproval }) => {
  return {
    _checkStatus: checkStatus,
    _artistDataList: artistDataList,
    _artistApprovalList: artistApprovalList,
    _pendingCreatorApproval: pendingCreatorApproval
  };
};
const mapDispatch = (dispatchEvent) => ({
  $checkStatus: (values) => dispatchEvent(checkStatus(values)),
  $artistDataList: (values) => dispatchEvent(artistDataList(values)),
  $artistApprovalList: (values) => dispatchEvent(artistApprovalList(values)),
  $pendingCreatorApproval: (values) => dispatchEvent(pendingCreatorApproval(values)),
});
export default connect(mapState, mapDispatch)(ReviewStatusModal);
