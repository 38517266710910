import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../Common/Loader/Loader";

export default class Table extends Component {
  render() {
    const {
      clientAllContent,
      LoaderIs,
      firstname,
      lastname,
      username,
      email,
      handleFunc,
    } = this.props;

    const searchTable = [
      { placeholder: "Search firstname", name: "firstname", value: firstname },
      { placeholder: "Search lastname", name: "lastname", value: lastname },
      { placeholder: "Search username", name: "username", value: username },
      { placeholder: "Search email", name: "email", value: email },
    ];

    const tableContentArr = [
      { name: "Action" },
      { name: "Name" },
      { name: "Username" },
      { name: "Email" },
      { name: "Phone no." },
      { name: "Location" },
      { name: "Created Date" },
      { name: "Last Active" },
      { name: "Created from"}     
    ];

    const pathIs = this.props.location.pathname;
    return (
      <React.Fragment>
        {/*------------------Search ---------------------*/}
        {pathIs !== "/clients/allBrief" && (
          <div className="row col-md-12">
            <div className="col">
              <form className="row align-items-center">
                <div className="col-auto pr-0">
                  <span className="fe fe-search text-muted"></span>
                </div>
                {searchTable.map((obj, index) => (
                  <div className="col" key={index}>
                    <input
                      name={obj.name}
                      type="search"
                      value={obj.value}
                      className="form-control form-control-flush search"
                      placeholder={obj.placeholder}
                      onChange={handleFunc.handleOnChange}
                      autoComplete="off"
                    />
                  </div>
                ))}
              </form>
            </div>
          </div>
        )}
        {/*------------------Search ---------------------*/}

        {LoaderIs ? (
          <Loader />
        ) : (
          <div className="table-responsive mb-5">
            <table className="table table-sm table-nowrap card-table">
              {/*-----------------------table head ------------------*/}

              <thead>
                <tr>
                  {tableContentArr.map((obj, index) => (
                    <th key={index}>
                      <Link to="#!" className="text-muted sort">
                        {obj.name}
                      </Link>
                    </th>
                  ))}
                </tr>
              </thead>

              {/*-----------------------table head ------------------*/}
              <tbody className="list">
                {clientAllContent.map((obj, index) => (
                  <tr key={index}>
                    {/*--------------------------drpdown ----------------------------------*/}
                    <td className="text-center">
                      <div className={`dropdown`}>
                        <a
                          href="#!"
                          className="dropdown-ellipses dropdown-toggle text-secondary"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fe fe-more-vertical" ></i>
                        </a>
                        
                          <div className={`ma-auto dropdown-menu dropdown-menu-right text-center`}>
                            {/******************Reset Temp Password ******************** */}
                            {pathIs !== "/clients/allBrief" && (
                              <span>
                                <Link
                                  to="#!"
                                  className="dropdown-item"
                                  datatoggle="modal"
                                  datatarget="#exampleModalCenter"
                                  onClick={() =>
                                    handleFunc.handleResetTemproryPassword(
                                      obj._id
                                    )
                                  }
                                >
                                  Reset Password
                                </Link>
                              </span>
                            )}
                            {/******************Reset Temp Password ******************** */}
                            <span>
                              <Link
                                to={`/clients/:edit/:${obj._id}`}
                                className="dropdown-item"
                                datatoggle="modal"
                                datatarget="#exampleModalCenter"
                              >
                                View/Edit
                              </Link>
                            </span>
                            {this.props.location.pathname==='/clients' && <span
                              onClick={() =>
                                handleFunc.handleDeleteArtist(obj._id)
                              }
                            >
                              <Link
                                to="#!"
                                className="dropdown-item"
                                datatoggle="modal"
                                datatarget="#exampleModalCenter"
                              >
                                Delete
                              </Link>
                            </span>}
                          </div>
                      </div>
                    </td>
                    {/*--------------------------drpdown ----------------------------------*/}
                    <td className="orders-order">
                      <Link to={`/clients/:edit/:${obj._id}`}>
                          {obj.first_name + " " + obj.last_name}
                      </Link>                      
                    </td>
                    <td className="orders-date">{obj.username}</td>
                    <td className="orders-total">{obj.email}</td>
                    <td className="orders-method">{obj.phone_no}</td>
                    <td className="orders-method">{obj.location}</td>
                    <td className="orders-method">
                      {obj?.createdAt && moment(obj?.createdAt).format("DD/MM/YYYY")}
                    </td>
                    <td className="orders-method">
                      {obj?.last_active && moment(obj?.last_active).format("DD/MM/YYYY")}
                    </td>
                    <td className="orders-method text-center">
                      {obj?.created_status === 0 ? 'Admin' : 'FMS'}
                    </td>                    
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </React.Fragment>
    );
  }
}
