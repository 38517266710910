import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../../Common/Loader/Loader";
import { connect } from "react-redux";
import {
  creatorRejectsBriefList,
  clearBriefList,
} from "../../../../store/action/transaction.action";
import ReactPaginate from "react-paginate";
import ErrorApi from "../../Common/Error/ErrorApi";

class CreatorRejectsBrief extends Component {
  state = {
    LoadingIs: false,
    currentPage: 1,
    sort: -1,
    CreatorDataIs: [],
    totalPage: 0,
    showAction: false,
    showActionIndex: "",
    clearBriefActive: false,
    sortFlagIs: false,
  };

  handleSorting = () => {
    this.setState({ sortFlagIs: !this.state.sortFlagIs }, () => {
      const { currentPage } = this.state;
      let currentData = null;
      if (this.state.sortFlagIs) {
        currentData = { page: currentPage, sortIs: 1 };
      } else {
        currentData = { page: currentPage, sortIs: -1 };
      }
      this.setState({ sort: this.state.sortFlagIs ? 1 : -1 });
      this.handleCreatorRejectsBrief(currentData);
    });
  };

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    const { sort } = this.state;
    const currentData = { page: selectedData, sortIs: sort };
    this.handleCreatorRejectsBrief(currentData);
  };

  handleClearBrief = (id) => {
    const briefData = { _id: id };
    this.setState({ clearBriefActive: true });
    this.props.$clearBriefList(briefData);
  };

  handleCreatorRejectsBrief = (currentData) => {
    this.setState({
      LoadingIs: true,
    });
    this.props.$creatorRejectsBriefList(currentData);
  };

  componentDidMount() {
    const { currentPage, sort } = this.state;
    const currentData = { page: currentPage, sortIs: sort };
    this.handleCreatorRejectsBrief(currentData);
  }

  componentWillReceiveProps(nextProps) {
    const { clearBriefActive } = this.state;
    const RejectionListIs =
      nextProps._creatorRejectsBriefList.rejectsCreatorListIs;
    const currentClearBrief = nextProps._clearBriefList.clearListIs;
    if (RejectionListIs) {
      this.setState({
        CreatorDataIs: RejectionListIs.briefProposalRejects
          ? RejectionListIs.briefProposalRejects
          : "",
        totalPage: RejectionListIs.totalPages,
        LoadingIs: false,
      });
    }
    if (currentClearBrief && clearBriefActive) {
      const { currentPage, sort } = this.state;
      const currentData = { page: currentPage, sortIs: sort };
      this.handleCreatorRejectsBrief(currentData);
      this.setState({
        clearBriefActive: false,
      });
    }
  }

  render() {
    const otherDbsArr = [
      { name: "Action" },
      { name: "Transaction name" },
      { name: "collateral name" },
      { name: "creator name" },
      { name: "creator email" },
      { name: "creator number" },
      { name: "client name" },
      // { name: "Brief Received Date" },
      { name: "Brief Rejected Date" },      
    ];

    const {
      LoadingIs,
      CreatorDataIs
    } = this.state;

    return (
      <section>
        {this.props._creatorRejectsBriefList.ceatorRejectsErrIs ? (
          <ErrorApi {...this.props} />
        ) : (
          <React.Fragment>
            <section className="client mt-5">
              <div className="container-fluid mx-auto">
                {LoadingIs ? (
                  <Loader />
                ) : (
                  <div className="table-responsive">
                    <table className="table table-sm table-nowrap card-table">
                      {/*----------------- table head --------------------*/}
                      <thead>
                        <tr>
                          {otherDbsArr.map((obj, index) => (
                            <React.Fragment key={index}>
                              {obj.name !== "Brief Rejected Date" && (
                                <th>
                                  <Link to="#!" className="text-muted ">
                                    {obj.name}
                                  </Link>
                                </th>
                              )}
                              {obj.name === "Brief Rejected Date" && (
                                <th key={index}>
                                  <Link
                                    to="#!"
                                    className="text-muted sort"
                                    data-sort="tables-row"
                                    onClick={this.handleSorting}
                                  >
                                    {obj.name}
                                  </Link>
                                </th>
                              )}
                            </React.Fragment>
                          ))}
                        </tr>
                      </thead>
                      {/*----------------- table head --------------------*/}
                      <tbody className="list">
                        {CreatorDataIs.length > 0 ? (
                          CreatorDataIs.map((obj, index) => (
                            <tr key={index}>
                              <td className="text-center">
                                <div
                                  className={`dropdown`}
                                >
                                  <a
                                    href="#!"
                                    className="dropdown-ellipses dropdown-toggle text-secondary"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="fe fe-more-vertical"></i>
                                  </a>
                                  
                                    <div
                                      className={`ma-auto dropdown-menu dropdown-menu-right text-center`}
                                      
                                    >
                                      {obj.status === 0 && (
                                        <span>
                                          <Link
                                            to="#!"
                                            className="dropdown-item mb-2"
                                            onClick={() =>
                                              this.handleClearBrief(obj._id)
                                            }
                                          >
                                            Clear
                                          </Link>
                                        </span>
                                      )}
                                      <span>
                                        <Link
                                          to={`/approvals/ViewCreatorReject/:${obj._id}`}
                                          className="dropdown-item mb-2"
                                          datatoggle="modal"
                                          datatarget="#exampleModalCenter"
                                        >
                                          View Project
                                        </Link>
                                      </span>
                                    </div>
                                </div>
                              </td>
                              <td className="orders-order">
                                {`${obj.PostProject.project_name.substring(
                                  0,
                                  30
                                )} ${
                                  obj.PostProject.project_name.substring(20)
                                    ? "..."
                                    : ""
                                }`}
                              </td>
                              <td className="orders-product">
                                {obj.collateral.name}
                              </td>
                              <td className="orders-date">
                                {obj.creator.first_name +
                                  " " +
                                  obj.creator.last_name}
                              </td>
                              <td className="orders-total">
                                {obj.creator.email}
                              </td>
                              <td className="orders-status">
                                {obj.creator.phone_no}
                              </td>
                              <td className="orders-method">
                                {obj.client.first_name +
                                  " " +
                                  obj.client.last_name}
                              </td>
                              {/* <td className="orders-method">Mastercard</td> */}
                              <td className="orders-method">
                                {obj.createdAt
                                  ? obj.createdAt.split("T").slice(0)[0]
                                  : ""}
                              </td>                              
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>No data Found...</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                {/****************** Pagination ********************/}

                {this.state.totalPage > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                )}
              </div>
            </section>
          </React.Fragment>
        )}
      </section>
    );
  }
}
const mapState = ({ creatorRejectsBriefList, clearBriefList }) => {
  return {
    _creatorRejectsBriefList: creatorRejectsBriefList,
    _clearBriefList: clearBriefList,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $creatorRejectsBriefList: (values) =>
    dispatchEvent(creatorRejectsBriefList(values)),
  $clearBriefList: (values) => dispatchEvent(clearBriefList(values)),
});
export default connect(mapState, mapDispatch)(CreatorRejectsBrief);
