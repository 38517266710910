import React,{Component} from "react";
import { Switch, Route } from "react-router";
import Milestones from "./Milestones";
import AMDashboard from "./AMDashboard";

export default class MilestoneRoute extends Component{

    componentDidMount(){
        this.props.handleClients.handlepathName("/milestones");
    }
    render(){
        return(
            <React.Fragment>
                <Switch>
                    <Route path="/milestones/dashboard/:id" render={(props)=> <AMDashboard {...props}/>}/>
                    <Milestones {...this.props} />
                </Switch>
            </React.Fragment>
        )
    }
}