import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { collateralActiveTransaction } from "../../../store/action/collateralList.action";
import ActiveNegociations from "./ActiveNegociations";
import Loader from "../Common/Loader/Loader";
// import ReactPaginate from "react-paginate";
// import CompletedTransactions from "./CompletedTransactions";

class CollateralTransactions extends Component {
  state = {
    LoadingIs: false,
    projectIs: "",
    transactionList: 1,
    transactionDataIs : [],
    totalPagesIs : ''
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handlePageClick = (data) =>{
    let currentData = (data.selected)+1;    
    this.props.$collateralActiveTransaction(currentData);    
  }

  handleActiveTransaction = () =>{
    this.setState({
      LoadingIs : false
    })
    const{ transactionList } = this.state;
    this.props.$collateralActiveTransaction(transactionList);
  }

  componentDidMount(){
    this.handleActiveTransaction();
  }

  componentWillReceiveProps(nextProps){
    const activeTransactionData = nextProps._collateralActiveTransaction.collActiveTransactions;   
    if(activeTransactionData){
      this.setState({
        transactionDataIs : activeTransactionData.users,
        totalPagesIs : activeTransactionData.totalPages,
        LoadingIs : false
      })
    }  
  }

  render() {
    const { LoadingIs, projectIs } = this.state;

    const transcationArr = [
      { name: "Project" },
      { name: "Client" },
      { name: "Approved Creator" },
      { name: "Contacted Creator" },
      { name: "Project Size" },
      { name: "Status" },
    ];

    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/collateralsQuestions/transactions/negociations/:id/"
            render={(props) => (
              <ActiveNegociations {...props} {...this.state} />
            )}
          />

          {/* <Route
            path="/collateralsQuestions/transactions/completedTransactions/:id/"
            render={(props) => (
              <CompletedTransactions {...props} {...this.state} />
            )}
          /> */}

          <section className="client">
            <div className="container-fluid mx-auto">
              {/*------------------search ---------------------*/}
              <div className="row col-md-12">
                <div className="col">
                  <form className="row align-items-center">
                    <div className="col-auto pr-0">
                      <span className="fe fe-search text-muted"></span>
                    </div>
                    <div className="col">
                      <input
                        type="search"
                        className="form-control form-control-flush search"
                        autoComplete="off"
                        placeholder="Search"
                        name="projectIs"
                        value={projectIs}
                        onChange={this.handleOnChange}
                      />
                    </div>
                  </form>
                </div>
              </div>
              {/*------------------search ---------------------*/}

              {LoadingIs ? (
                <Loader />
              ) : (
                <div className="table-responsive mb-5 mx-auto">
                  <table className="table table-sm table-nowrap card-table">
                    {/*-----------------table head-----------------------*/}
                    <thead>
                      <tr>
                        {transcationArr.map((obj, index) => (
                          <th key={index}>
                            <a href="!#" className="text-muted sort">
                              {obj.name}
                            </a>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    {/*-----------------table head-----------------------*/}
                    <tbody className="list">
                      <tr>
                        <td className="orders-order">#6520</td>
                        <td className="orders-product">5' x 3' Wall Poster</td>
                        <td className="orders-total">$55.25</td>
                        <td className="orders-total">$55.25</td>
                        <td className="orders-total">$55.25</td>
                        <td className="orders-status">
                          <div className="badge badge-soft-success">
                            Shipped
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}              
              {/*------------------- Pagination---------------- */}

              {/* {this.state.totalPagesIs > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.totalPagesIs}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )} */}
            </div>
          </section>
        </Switch>
      </React.Fragment>
    );
  }
}
const mapState = ({ collateralActiveTransaction }) => {
  return {
    _collateralActiveTransaction: collateralActiveTransaction,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $collateralActiveTransaction: (values) =>
    dispatchEvent(collateralActiveTransaction(values)),
});
export default connect(
  mapState,
  mapDispatch
)(CollateralTransactions);
