import React from "react";
import DatePicker from "react-date-picker";
const ProposalUpperSection = ({EditIs, handleFunc, errorIs, proposal_type, proposed_start_date, milestoneInputs, clientBudget, Gst, totalCost, creatorCost,_getPostProjectData }) => {
  var totalCost = clientBudget ? Number(Gst) + Number(clientBudget) : 0;

  return (
    <>
      {false && <div className="form-group">
        <div className="row">
          <div className="col">
            <label htmlFor="exampleInputEmail1" className="text-secondary">
              Client Budget
            </label>
            <input
              type="number"
              className={`form-control ${errorIs.clientBudget ? "error-show" : ""
                }`}
              placeholder="Enter Client Budget"
              name="clientBudget"
              value={clientBudget}
              onChange={handleFunc.handleChange}
            />
            {errorIs.clientBudget && (
              <span className="error">{errorIs.clientBudget}</span>
            )}
          </div>
          {false && <div className="col">
            <label htmlFor="exampleInputEmail1" className="text-secondary">
              Creator cost
            </label>
            <input
              type="number"
              className={`form-control ${errorIs.creatorCost ? `error-show` : ``
                }`}
              placeholder="Enter Creator cost"
              name="creatorCost"
              value={creatorCost}
              onChange={handleFunc.handleChange}
            />
            {errorIs.creatorCost && (
              <span className="error">{errorIs.creatorCost}</span>
            )}
          </div>}
        </div>
      </div>}
      {false && <div className="form-group">
        <div className="row">
          <div className="col text-right">
            <small>
              Total Project Cost for Client : {totalCost ? totalCost : 0}
            </small>
          </div>
        </div>
      </div>}
      <div className="form-group">
        <div className="row">

          <div className="col">
            <label htmlFor="exampleInputEmail1" className="text-secondary">
              Proposed Start Date
            </label>

            <DatePicker
              className={`form-control ${EditIs?"disabled":""}`}
              selected={proposed_start_date}
              onChange={(value) => handleFunc.handleOnChangeProposedStartDate(value)}
              name="project_deadline_date"
              disabled={EditIs}
              // minDate={new Date()}
              maxDate={
                milestoneInputs?.length && milestoneInputs[0].due_date ? new Date(milestoneInputs[0].due_date) : ""
              }
              value={proposed_start_date}
              placeholder="Deadline Date"
              autoComplete="off"
              dateFormat="dd/MM/yyyy"
            />
          </div>

        </div>
      </div>
    </>
  )

}

export default ProposalUpperSection;