import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../../Common/Loader/Loader";

export default class CollateralTable extends Component {
  render() {
    const {
      listData,
      handleCollateral,
      LoadinListIs,
    } = this.props;

    const otherDbsArr = [
      { name: "Action" },
      { name: "Name" },
      { name: "username" },
      { name: "Collateral Name" },
      { name: "collateral Pricing" },
      { name: "Pricing unit" },
      { name: "Collateral Experience" },
      { name: "Collateral Time" },
      { name: "Submission Date" },      
    ];
    return (
      <React.Fragment>
        {LoadinListIs ? (
          <Loader />
        ) : (
          <div className="table-responsive">
            <table className="table table-sm table-nowrap card-table">
              {/*----------------- table head --------------------*/}
              <thead>
                <tr>
                  {otherDbsArr.map((obj, index) => (
                    <React.Fragment key={index}>
                      {obj.name !== "Submission Date" && (
                        <th key={index}>
                          <Link to="#!" className="text-muted">
                            {obj.name}
                          </Link>
                        </th>
                      )}
                      {obj.name === "Submission Date" && (
                        <th>
                          <Link to="#!" className="text-muted sort" data-sort="tables-row" onClick={handleCollateral.handleSorting}>
                            {obj.name}
                          </Link>
                        </th>
                      )}
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              {/*----------------- table head --------------------*/}
              <tbody className="list">
                {Array.isArray(listData) &&
                  listData.map((obj, index) => (
                    <tr key={index}>
                      <td className="text-center">
                        <div className={`dropdown`}>
                          <a
                            href="#!"
                            className="dropdown-ellipses dropdown-toggle text-secondary"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fe fe-more-vertical"></i>
                          </a>
                          
                            <div className={`ma-auto dropdown-menu dropdown-menu-right text-center`}>
                              {obj.status === 0 && (
                                <span>
                                  <Link
                                    to="#!"
                                    className="dropdown-item"
                                    datatoggle="modal"
                                    datatarget="#exampleModalCenter"
                                    onClick={() =>
                                      handleCollateral.handleCollateralStatus(
                                        obj._id,
                                        "accept"
                                      )
                                    }
                                  >
                                    Accept
                                  </Link>
                                  <Link
                                    to="#!"
                                    className="dropdown-item mb-2"
                                    onClick={() =>
                                      handleCollateral.handleCollateralStatus(
                                        obj._id,
                                        "reject"
                                      )
                                    }
                                  >
                                    Reject
                                  </Link>
                                  <Link
                                    to={`/approvals/PendingViewCollateral/:${obj._id}`}
                                    className="dropdown-item mb-2"
                                  >
                                    View Collateral
                                  </Link>
                                </span>
                              )}
                            </div>
                        </div>
                      </td>
                      <td className="orders-order">
                        {obj.user.first_name + " " + obj.user.last_name}
                      </td>
                      <td className="orders-product">{obj.user.username}</td>
                      <td className="orders-date">{obj.collateral.name}</td>
                      <td className="orders-total">
                        {obj.start_price + "-" + obj.end_price}
                      </td>
                      <td className="orders-method">
                        {obj.collateral.pricing_unit}
                      </td>
                      <td className="orders-method">
                        {`${obj.year} year - ${obj.month} Month`}
                      </td>
                      <td className="orders-method">{obj.time}{" "}{obj.type}</td>
                      <td className="orders-method">
                        {obj.updatedAt.split("T").slice(0)[0]}
                      </td>                      
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </React.Fragment>
    );
  }
}
