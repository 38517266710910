import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getArtistData,
  stausArtistProject,
  artistReviewStatus,
} from "../../../store/action/clientList.action";
import ArtistProjectForm from "./ArtistProgectForm";
import ArtistProjectReview from "./artistProjectReviewForm";
import ErrorApi from "../Common/Error/ErrorApi";
import ArtistProjectMediaModal from "./ArtistProjectMediaModal";
import Modal from "react-modal";
Modal.setAppElement("#root");
Modal.defaultStyles.overlay.backgroundColor = "rgb(0 0 0 / 50%)";
Modal.defaultStyles.overlay.zIndex = "9999";

class ArtistProject extends Component {
  state = {
    artistProject: [],
    artistIndex: "",
    projectFormVisibility: false,
    aboutProject: "",
    projectTitle: "",
    projectEndMonth: "",
    projectEndYear: "",
    artistRole: "",
    projectKeywords: [],
    projectTools: [],
    projectImageLinks: [],
    statusIs: false,

    projectLinksEdit: false,
    projectImageIndex: "",
    reviewStatus: false,
    reviewIndex: "",

    reviewerFeedback: "",
    reviewerName: "",
    reviewerCompany: "",
    reviewerWebsite: "",
    reviewIs: "",

    ProjectMediaIs : false,
    LinkedCollateral: [],
    MediaData: [],
    artistProfile : '',
    projectId : '',

    reviewerEmail : '',
    reqReviewName: ""
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleEditProjectImageOrLinks = (obj, index) => {
    const { projectLinksEdit } = this.state;
    this.setState({
      projectLinksEdit: !projectLinksEdit,
      projectImageIndex: index,
    });
  };

  handleShowArtistMedia = (obj, index) =>{
    this.setState({
      artistIndex: index,
      reviewIndex: "",
      ProjectMediaIs: true,
      aboutProject: obj.project.about,
      projectTitle: obj.project.title,
      projectEndMonth: obj.project.end_month,
      projectEndYear: obj.project.end_year,
      projectKeywords: obj.project.keywords,
      projectTools: obj.project.tools,
      projectImageLinks: obj.project.images_or_links,
      artistRole: obj.project.role,
      LinkedCollateral : obj.project_collateral,
      MediaData : obj,
      projectId : obj.project._id
    });
  }

  handleCloseMediaModal = () => {
    this.setState({
      ProjectMediaIs : false
    })
  }

  handleShowArtistProject = (obj, index) => {
    const { projectFormVisibility } = this.state;
    this.setState({
      artistIndex: index,
      reviewIndex: "",
      projectFormVisibility: !projectFormVisibility,
      aboutProject: obj.project.about,
      projectTitle: obj.project.title,
      projectEndMonth: obj.project.end_month,
      projectEndYear: obj.project.end_year,
      projectKeywords: obj.project.keywords,
      projectTools: obj.project.tools,
      projectImageLinks: obj.project.images_or_links,
      artistRole: obj.project.role,
    });
  };

  handleShowArtistProjectReview = (obj, index) => {
    const { projectFormVisibility } = this.state;
    // console.log("*******",obj)
    this.setState({
      reviewIndex: index,
      artistIndex: "",
      projectFormVisibility: !projectFormVisibility,
      reviewerName: obj.review_data.name,
      reviewerFeedback: obj.review_data.feedback,
      reviewerCompany: obj.review_data.company_name,
      reviewerWebsite: obj.review_data.website_url,
      reviewIs: obj.review_data.review,
      reviewerEmail: obj.review_data.email,
      reqReviewName: obj.review_data.request_review_name
    });
  };

  handleRating = (review) => {
    this.setState({
      reviewIs: review,
    });
  };

  handleGetConfirmation(obj, id) {
    let confirmIs = window.confirm(
      `Do you want to ${obj === 1 ? `accept` : `reject`} this Project ?`
    );
    if (confirmIs === true) {
      const projectStatus = { _id: id, status: obj };
      this.props.$stausArtistProject(projectStatus);
      this.setState({
        statusIs: true,
      });
      return true;
    } else {
      return false;
    }
  }

  handleStatusProject = (status, id) => {
    let currentStatus = 0;
    if (status === "accept") {
      currentStatus = 1;
    } else if (status === "reject") {
      currentStatus = 2;
    }

    this.handleGetConfirmation(currentStatus, id);
  };

  handleGetReviewConfirmation(obj, id) {
    let confirmIs = window.confirm(
      `Do you want to ${obj === 1 ? `accept` : `reject`} this Review ?`
    );
    if (confirmIs === true) {
      const reviewIs = { _id: id, status: obj };
      this.props.$artistReviewStatus(reviewIs);
      this.setState({
        reviewStatus: true,
      });
      return true;
    } else {
      return false;
    }
  }

  handleStatusProjctReview = (status, id) => {
    let reviewStatus = 0;
    if (status === "accept") {
      reviewStatus = 1;
    } else if (status === "reject") {
      reviewStatus = 2;
    }

    this.handleGetReviewConfirmation(reviewStatus, id);
  };

  handleGetArtisProject = (id) => {
    this.props.$getArtistData(id);
  };

  componentDidMount() {
    const artistParamsId = this.props.match.params.id.split(":").pop();
    this.handleGetArtisProject(artistParamsId);
    // this.props.handleClients.mainIndexstate({ profileUpdates: true });
  }

  componentWillReceiveProps(nextProps) {
    const { statusIs, reviewStatus } = this.state;
    const creatorData = nextProps._getArtistData.getCreatorData;
    // console.log("12334",creatorData)
    const projectStatus = nextProps._stausArtistProject.artistProStatus;
    const reviewStatusIs = nextProps._artistReviewStatus.reviewStatus;
    if (creatorData) {
      this.setState({
        artistProject: creatorData.userProject,
        artistProfile : creatorData.userData
      });
    }
    if (projectStatus && statusIs) {
      const artistParamsId = this.props.match.params.id.split(":").pop();
      this.handleGetArtisProject(artistParamsId);
      this.setState({
        statusIs: false,
      });
    }
    if (reviewStatus && reviewStatusIs) {
      const artistParamsId = this.props.match.params.id.split(":").pop();
      this.handleGetArtisProject(artistParamsId);
      this.setState({
        reviewStatus: false,
      });
    }
  }

  render() {
    const {
      artistProject,
      projectFormVisibility,
      artistIndex,
      reviewIndex,
      ProjectMediaIs
    } = this.state;

    const projectFunc = {
      handleOnChange: this.handleOnChange,
      handleEditProjectImageOrLinks: this.handleEditProjectImageOrLinks,
      handleRating: this.handleRating,
      handleCloseMediaModal : this.handleCloseMediaModal
    };

    return (
      this.props._getArtistData.getArtistError ? 
        <ErrorApi {...this.props} />
        :
      <React.Fragment>
        {artistProject.length ? (
          <section className="container">
            {artistProject.map((obj, index) => (
              <div className="mx-auto" key={index}>
                <div className="questin-Listing p-4">
                  <div className="row">
                    <div className="col-md-3 ">
                      <h4>Project</h4>
                      <div className="d-flex align-items-center">
                        <h5 className="mb-0 text-secondary">
                          {obj.project.title}
                        </h5>
                      </div>
                    </div>
                    <div className="col-md-3 d-flex align-items-center">
                      <ul className="mb-0 show_option text-secondary artist-col p-0">
                        <li className="mr-3">
                          {obj.project.end_month}-{obj.project.end_year}
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-4">
                          {obj.project.status === 0 && (
                            <span>
                              <div
                                className="badge badge-soft-info"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.handleStatusProject(
                                    "accept",
                                    obj.project._id
                                  )
                                }
                              >
                                Accept
                              </div>
                              <div
                                className="badge badge-soft-info ml-4"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.handleStatusProject(
                                    "reject",
                                    obj.project._id
                                  )
                                }
                              >
                                Reject
                              </div>
                            </span>
                          )}
                          {obj.project.status === 1 && (
                            <div className="badge badge-soft-success">
                              Accepted
                            </div>
                          )}
                          {obj.project.status === 2 && (
                            <div className="badge badge-soft-danger">
                              Rejected
                            </div>
                          )}
                          {obj.project.status === 3 && (
                              <div className="badge badge-soft-dark">
                                Deleted
                              </div>
                            )}
                        </div>
                        <div className="col-6">
                          <ul className="mb-0 show_option text-secondary artist-col p-0">
                            {/* <li className="mr-3">Admin user (accepted /rejected)</li> */}
                            <li className="mr-3">{obj.project.admin_email}</li>
                          </ul>
                        </div>
                        <div className="col-md-2 d-flex align-items-center" role="button">
                         <p className="mb-0"
                         onClick={() =>
                          this.handleShowArtistMedia(obj, index)
                         }
                         >                           
                           <i className="fe fe-eye"></i>
                         </p>
                          <p
                            className="mb-0 ml-2"
                            onClick={() =>
                              this.handleShowArtistProject(obj, index)
                            }
                          >
                            <i
                              className={`${
                                projectFormVisibility && artistIndex === index
                                  ? "fe fe-chevron-up"
                                  : "fe fe-chevron-down"
                              }`}
                            ></i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {projectFormVisibility && artistIndex === index && (
                  <ArtistProjectForm
                    {...this.props}
                    {...this.state}
                    handleProject={projectFunc}
                  />
                )}

                {obj.review_data !== "" && obj.review_data !== null && (
                  <div className="questin-Listing p-4 mt-4">
                    <div className="row">
                      <div className="col-md-3">
                        <h4>Reviewed by</h4>
                        <div className="d-flex align-items-center">
                          <h5 className="mb-0 text-secondary">
                            {obj.review_data.name}
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-3 d-flex align-items-center">
                        <ul className="mb-0 show_option text-secondary artist-col p-0">
                          <li className="mr-3">
                            {obj.review_data.company_name}
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-4">
                            {obj.review_data.status === 0 && (
                              <span>
                                <div
                                  className="badge badge-soft-info"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.handleStatusProjctReview(
                                      "accept",
                                      obj.review_data.project_id
                                    )
                                  }
                                >
                                  Accept
                                </div>
                                <div
                                  className="badge badge-soft-info ml-4"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.handleStatusProjctReview(
                                      "reject",
                                      obj.review_data.project_id
                                    )
                                  }
                                >
                                  Reject
                                </div>
                              </span>
                            )}
                            {obj.review_data.status === 1 && (
                              <div className="badge badge-soft-success">
                                Accepted
                              </div>
                            )}
                            {obj.review_data.status === 2 && (
                              <div className="badge badge-soft-danger">
                                Rejected
                              </div>
                            )}                          
                          </div>
                          <div className="col-6">
                            <ul className="mb-0 show_option text-secondary artist-col p-0">
                              {/* <li className="mr-3">Admin user (accepted /rejected)</li> */}
                              <li className="mr-3">
                                {obj.review_data.admin_email}
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-2 d-flex align-items-center">
                            <p
                              className="mb-0"
                              onClick={() =>
                                this.handleShowArtistProjectReview(obj, index)
                              }
                            >
                              <i
                                className={`${
                                  projectFormVisibility && reviewIndex === index
                                    ? "fe fe-chevron-up"
                                    : "fe fe-chevron-down"
                                }`}
                              ></i>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}                               

                {projectFormVisibility && reviewIndex === index && (
                  <ArtistProjectReview
                    {...this.props}
                    {...this.state}
                    handleProject={projectFunc}
                  />
                )}

                {/*------------------------media Modal ----------------------------*/}

                {ProjectMediaIs && artistIndex === index && (
                  <ArtistProjectMediaModal
                    {...this.props}
                    {...this.state}
                    handleProject={projectFunc}
                  />
                )}

                <hr className="my-5" />
              </div>
            ))}                              
          </section>
        ) : (
          <h3 className="text-center">Artist dont have any Project data...</h3>
        )}  

      </React.Fragment>
    );
  }
}
const mapState = ({
  getArtistData,
  stausArtistProject,
  artistReviewStatus,
}) => {
  return {
    _getArtistData: getArtistData,
    _stausArtistProject: stausArtistProject,
    _artistReviewStatus: artistReviewStatus,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $getArtistData: (values) => dispatchEvent(getArtistData(values)),
  $stausArtistProject: (values) => dispatchEvent(stausArtistProject(values)),
  $artistReviewStatus: (values) => dispatchEvent(artistReviewStatus(values)),
});
export default connect(
  mapState,
  mapDispatch
)(ArtistProject);
