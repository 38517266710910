import {
  CLIENT_LIST,
  CLIENT_LIST_SUCCESS,
  CLIENT_LIST_ERROR,
  CHECK_STATUS,
  CHECK_STATUS_SUCCESS,
  USER_SIGNUP,
  USER_SIGNUP_SUCCESS,
  CREATIVE_USERS_DATA,
  CREATIVE_USERS_DATA_SUCCESS,
  ADD_USERS_DATA,
  ADD_USERS_DATA_SUCCESS,
  UPDATE_USERS_DATA,
  UPDATE_USERS_DATA_SUCCESS,
  VIEW_USERS_DATA,
  VIEW_USERS_DATA_SUCCESS,
  CLIENT_DATA,
  CLIENT_DATA_SUCCESS,
  ARTIST_LIST,
  ARTIST_LIST_SUCCESS,
  ARTIST_LIST_ERROR,
  GET_ARTIST_DATA_SUCCESS,
  GET_ARTIST_DATA,
  STATUS_ARTIST_COLLATERAL,
  STATUS_ARTIST_COLLATERAL_SUCCESS,
  STATUS_ARTIST_PROJECT,
  STATUS_ARTIST_PROJECT_SUCCESS,
  ARTIST_DETAILS,
  ARTIST_DETAILS_SUCCESS,
  CLIENTS_DETAILS,
  CLIENTS_DETAILS_SUCCESS,
  CLIENTS_PROFILE,
  CLIENTS_PROFILE_SUCCESS,
  ARTIST_PROFILE,
  ARTIST_PROFILE_SUCCESS,
  ARTIST_UPDATE_INFO,
  ARTIST_UPDATE_INFO_SUCCESS,
  ARTIST_UPDATE_INFO_ERROR,
  ARTIST_APPROVAL_LIST,
  ARTIST_APPROVAL_LIST_SUCCESS,
  ARTIST_APPROVAL_LIST_ERROR,

  ARTIST_BUSY_LIST,
  ARTIST_BUSY_LIST_SUCCESS,
  ARTIST_BUSY_LIST_ERROR,
  ARTIST_STARRED_LIST,
  ARTIST_STARRED_LIST_SUCCESS,
  ARTIST_STARRED_LIST_ERROR,

  ARTIST_CLEAN_UPDATE,
  ARTIST_CLEAN_UPDATE_SUCCESS,
  ARTIST_REVIEW_STATUS,
  ARTIST_REVIEW_STATUS_SUCCESS,
  TEMP_RESET_PWD,
  TEMP_RESET_PWD_SUCCESS,
  REJECTED_ARTIST,
  REJECTED_ARTIST_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  APPROVED_ARTIST,
  APPROVED_ARTIST_SUCCESS,
  DELETE_ARTIST_LIST,
  DELETE_ARTIST_LIST_SUCCESS,
  CLIENT_DELETE_LIST,
  CLIENT_DELETE_LIST_SUCCESS,
  LOCATION_USER,
  LOCATION_USER_SUCCESS,
  REJECTED_ARTIST_ERROR,
  APPROVED_ARTIST_ERROR,
  DELETE_ARTIST_LIST_ERROR,
  DELETE_CLIENT_LIST_ERROR,
  GET_ARTIST_DATA_ERROR,
  CLIENT_DATA_ERROR,
  PENDING_CREATOR_APPR_LIST,
  PENDING_CREATOR_APPR_LIST_SUCCESS,
  CLIENT_REJECTS_CREATOR_LIST,
  CLIENT_REJECTS_CREATOR_LIST_SUCCESS,
  PENDING_CREATOR_APPR_LIST_ERROR,
  CLIENT_REJECTS_CREATOR_LIST_ERROR,
  CLIENT_BRIEF_LIST,
  CLIENT_BRIEF_LIST_SUCCESS,
  CLIENT_BRIEF_LIST_ERR,
  CHANGE_EMBEDDED_LINK,
  CHANGE_EMBEDDED_LINK_SUCCESS,
  ARTIST_BUSY_OR_AVAILABLE,
  ARTIST_BUSY_OR_AVAILABLE_SUCCESS,
  DISABLED_ARTIST,
  DISABLED_ARTIST_SUCCESS,
  DISABLE_ENABLE_ARTIST,
  DISABLE_ENABLE_ARTIST_SUCCESS,
  DISABLED_ARTIST_ERROR
} from "../type.action";

export const clientList = (values) => ({
  type: CLIENT_LIST,
  values,
});
export const clientListSuccess = (values) => ({
  type: CLIENT_LIST_SUCCESS,
  values,
});
export const clientListError = (error) => ({
  type: CLIENT_LIST_ERROR,
  error,
});

// get client *************

export const getClientData = (values) => ({
  type: CLIENT_DATA,
  values,
});
export const getClientDataSuccess = (values) => ({
  type: CLIENT_DATA_SUCCESS,
  values,
});
export const getClientError = (error) => ({
  type: CLIENT_DATA_ERROR,
  error,
});

// check status **************

export const checkStatus = (values) => ({
  type: CHECK_STATUS,
  values,
});
export const checkStatusSuccess = (values) => ({
  type: CHECK_STATUS_SUCCESS,
  values,
});

// clients basic details update *******************

export const clientsDetail = (values) => ({
  type: CLIENTS_DETAILS,
  values,
});
export const clientsDetailSuccess = (values) => ({
  type: CLIENTS_DETAILS_SUCCESS,
  values,
});

// clients basic detail upload profile **************

export const clientsDetailsProfile = (values) => ({
  type: CLIENTS_PROFILE,
  values,
});
export const clientsDetailProfileSuccess = (values) => ({
  type: CLIENTS_PROFILE_SUCCESS,
  values,
});

//sign up *****************

export const userSignup = (values) => ({
  type: USER_SIGNUP,
  values,
});
export const userSignupSuccess = (values) => ({
  type: USER_SIGNUP_SUCCESS,
  values,
});

//get creative field users and paginatin data******

export const creativeUsersData = (values) => ({
  type: CREATIVE_USERS_DATA,
  values,
});
export const creativeUsersDataSuccess = (values) => ({
  type: CREATIVE_USERS_DATA_SUCCESS,
  values,
});

// add creative field data **********

export const addUsersData = (values) => ({
  type: ADD_USERS_DATA,
  values,
});
export const addUsersDataSuccess = (values) => ({
  type: ADD_USERS_DATA_SUCCESS,
  values,
});

//update creative field data **********

export const updateUsersData = (values) => ({
  type: UPDATE_USERS_DATA,
  values,
});
export const updateUsersDataSuccess = (values) => ({
  type: UPDATE_USERS_DATA_SUCCESS,
  values,
});

//view creative field data **************

export const viewUsersData = (values) => ({
  type: VIEW_USERS_DATA,
  values,
});
export const viewUsersDataSuccess = (values) => ({
  type: VIEW_USERS_DATA_SUCCESS,
  values,
});

// Artist Pagination & artist list ***********************

export const artistDataList = (values) => ({
  type: ARTIST_LIST,
  values,
});
export const artistDataListSuccess = (values) => ({
  type: ARTIST_LIST_SUCCESS,
  values,
});
export const artistDataError = (error) => ({
  type: ARTIST_LIST_ERROR,
  error,
});

// get Artist ***********************

export const getArtistData = (values) => ({
  type: GET_ARTIST_DATA,
  values,
});
export const getArtistDataSuccess = (values) => ({
  type: GET_ARTIST_DATA_SUCCESS,
  values,
});
export const getArtistDataError = (error) => ({
  type: GET_ARTIST_DATA_ERROR,
  error,
});

// accept/reject Artist collateral *************

export const stausArtistCollateral = (values) => ({
  type: STATUS_ARTIST_COLLATERAL,
  values,
});
export const stausArtistCollateralSuccess = (values) => ({
  type: STATUS_ARTIST_COLLATERAL_SUCCESS,
  values,
});

// accept/reject Artist Project *****************

export const stausArtistProject = (values) => ({
  type: STATUS_ARTIST_PROJECT,
  values,
});
export const stausArtistProjectSuccess = (values) => ({
  type: STATUS_ARTIST_PROJECT_SUCCESS,
  values,
});

//artist basic detail update ********************

export const artistDetails = (values) => ({
  type: ARTIST_DETAILS,
  values,
});
export const artistDetailsSuccess = (values) => ({
  type: ARTIST_DETAILS_SUCCESS,
  values,
});

// artist deatils upload profile **************

export const artistProfile = (values) => ({
  type: ARTIST_PROFILE,
  values,
});
export const artistProfileSuccess = (values) => ({
  type: ARTIST_PROFILE_SUCCESS,
  values,
});

// artist profile update info tab************

export const artistUpdateInfo = (values) => ({
  type: ARTIST_UPDATE_INFO,
  values,
});
export const artistUpdateInfosuccess = (values) => ({
  type: ARTIST_UPDATE_INFO_SUCCESS,
  values,
});
export const artistUpdateInfoError = (error) => ({
  type: ARTIST_UPDATE_INFO_ERROR,
  error,
});

// artist approval list********************

export const artistApprovalList = (values) => ({
  type: ARTIST_APPROVAL_LIST,
  values,
});
export const artistApprovalListSuccess = (values) => ({
  type: ARTIST_APPROVAL_LIST_SUCCESS,
  values,
});
export const artistApprovalListError = (error) => ({
  type: ARTIST_APPROVAL_LIST_ERROR,
  error,
});

// ----------------busy list-----------------------

export const artistBusyList = (values) => ({
  type: ARTIST_BUSY_LIST,
  values,
});
export const artistBusyListSuccess = (values) => ({
  type: ARTIST_BUSY_LIST_SUCCESS,
  values,
});
export const artistBusyListError = (error) => ({
  type: ARTIST_BUSY_LIST_ERROR,
  error,
});

// -----------------starred list--------------------------

export const artistStarredList = (values) => ({
  type: ARTIST_STARRED_LIST,
  values,
});
export const artistStarredListSuccess = (values) => ({
  type: ARTIST_STARRED_LIST_SUCCESS,
  values,
});
export const artistStarredListError = (error) => ({
  type: ARTIST_STARRED_LIST_ERROR,
  error,
});

// artist profile updates cleaning*****************

export const artistCleanUpdates = (values) => ({
  type: ARTIST_CLEAN_UPDATE,
  values,
});
export const artistCleanUpdatesSuccess = (values) => ({
  type: ARTIST_CLEAN_UPDATE_SUCCESS,
  values,
});

// artist project Review accept/reject******************

export const artistReviewStatus = (values) => ({
  type: ARTIST_REVIEW_STATUS,
  values,
});
export const artistReviewStatusSuccess = (values) => ({
  type: ARTIST_REVIEW_STATUS_SUCCESS,
  values,
});

// temporary reset Password (for 15 min)**************

export const tempResetPwd = (values) => ({
  type: TEMP_RESET_PWD,
  values,
});
export const tempResetPwdSuccess = (values) => ({
  type: TEMP_RESET_PWD_SUCCESS,
  values,
});

// reject Artist *************************************

export const rejectedArtist = (values) => ({
  type: REJECTED_ARTIST,
  values,
});
export const rejectedArtsitSuccess = (values) => ({
  type: REJECTED_ARTIST_SUCCESS,
  values,
});
export const rejectedArtistError = (error) => ({
  type: REJECTED_ARTIST_ERROR,
  error,
});

// client/artist delete *********************************

export const userDelete = (values) => ({
  type: DELETE_USER,
  values,
});
export const userDeleteSuccess = (values) => ({
  type: DELETE_USER_SUCCESS,
  values,
});

// approved artist ****************************************

export const approvedArtist = (values) => ({
  type: APPROVED_ARTIST,
  values,
});
export const approvedArtistSuccess = (values) => ({
  type: APPROVED_ARTIST_SUCCESS,
  values,
});
export const approvedArtistError = (error) => ({
  type: APPROVED_ARTIST_ERROR,
  error,
});

// deleted artist List **************************************

export const deleteArtist = (values) => ({
  type: DELETE_ARTIST_LIST,
  values,
});
export const deleteArtistSuccess = (values) => ({
  type: DELETE_ARTIST_LIST_SUCCESS,
  values,
});
export const deleteArtistError = (error) => ({
  type: DELETE_ARTIST_LIST_ERROR,
  error,
});

// client Deleted List ****************************************

export const deleteClient = (values) => ({
  type: CLIENT_DELETE_LIST,
  values,
});
export const deleteClientSuccess = (values) => ({
  type: CLIENT_DELETE_LIST_SUCCESS,
  values,
});
export const deleteClientError = (error) => ({
  type: DELETE_CLIENT_LIST_ERROR,
  error,
});

// location api ****************************
export const locationUser = (values) => ({
  type: LOCATION_USER,
  values,
});
export const locationUserSuccess = (values) => ({
  type: LOCATION_USER_SUCCESS,
  values,
});

//pending creator (approval tab) **************************

export const pendingCreatorApproval = (values) => ({
  type: PENDING_CREATOR_APPR_LIST,
  values,
});
export const pendingCreatorApprovalSuccess = (values) => ({
  type: PENDING_CREATOR_APPR_LIST_SUCCESS,
  values,
});
export const pendingCreatorApprError = (error) => ({
  type: PENDING_CREATOR_APPR_LIST_ERROR,
  error,
});

// client Rejects creator List (Approaval tab)
export const clientRejectsCreatorList = (values) => ({
  type: CLIENT_REJECTS_CREATOR_LIST,
  values,
});
export const clientRejectsCreatorListSuccess = (values) => ({
  type: CLIENT_REJECTS_CREATOR_LIST_SUCCESS,
  values,
});
export const clientRejectsCreatorListError = (error) => ({
  type: CLIENT_REJECTS_CREATOR_LIST_ERROR,
  error,
});

// client brief list(client tab)
export const clientBriefList = (values) => ({
  type: CLIENT_BRIEF_LIST,
  values,
});
export const clientBriefListSuccess = (values) => ({
  type: CLIENT_BRIEF_LIST_SUCCESS,
  values,
});
export const clientBriefListErr = (error) => ({
  type: CLIENT_BRIEF_LIST_ERR,
  error,
});

// edit embedded link
export const editEmbeddedLink = (values) => ({
  type: CHANGE_EMBEDDED_LINK,
  values,
});
export const editEmbeddedLinkSuccess = (values) => ({
  type: CHANGE_EMBEDDED_LINK_SUCCESS,
  values,
});

// artist busy or available
export const artistBusyOrAvailable = (values) => ({
  type: ARTIST_BUSY_OR_AVAILABLE,
  values,
});
export const artistBusyOrAvailableSuccess = (values) => ({
  type: ARTIST_BUSY_OR_AVAILABLE_SUCCESS,
  values,
});

// disabled artist list
export const disabledArtist = (values) => ({
  type: DISABLED_ARTIST,
  values
})
export const disabledArtistSuccess = (values) => ({
  type: DISABLED_ARTIST_SUCCESS,
  values
})
export const disabledArtistError = (error) => ({
  type: DISABLED_ARTIST_ERROR,
  error
})

// disable enable artist
export const disableEnableArtist = (values) => ({
  type: DISABLE_ENABLE_ARTIST,
  values
})
export const disableEnableArtistSuccess = (values) => ({
  type: DISABLE_ENABLE_ARTIST_SUCCESS,
  values
})
