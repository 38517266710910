import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { postAPI } from "../../../store/api-interface";
import {
  closeProject,
  transactionList,
  activeTransactions,
  inactiveBrief,
  inactiveProject
} from "../../../store/action/transaction.action";
import LoaderSmall from "../../Autorized/Common/Loader/LoaderSmall";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "700px",
    width: "100%",
  },
};

class CloseProjectReasonModal extends Component {
  state = {
    reasondesc: "",
    reason1: "",
    reason2: "",
    reason3: "",
    LoadingIs: false,
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name !== "reasondesc") {
      this.setState({
        [name]: e.target.checked ? value : "",
      });
    } else {
      if (e.target.value.length < 500) {
        this.setState({
          [name]: value,
        });
      }
    }
  };

  handleSubmit = () => {
    const { reason1, reason2, reason3, reasondesc } = this.state;
    const { transctionId } = this.props;
    const allReason = [{ text: reason1 }, { text: reason2 }, { text: reason3 }];
    const currentReasonData =
      allReason && allReason.filter((item) => item.text !== "");
    const currentData = {
      _id: transctionId,
      description: reasondesc.trim(),
      feedback: currentReasonData,
    };
    if ((reason1 || reason2 || reason3) && reasondesc.trim()) {
      this.setState({ LoadingIs: true });
      this.handleReasonApi(currentData);
    } else {
      alert("Please Enter atleast one reason and description also..");
    }
  };

  handleReasonApi = async (currentData) => {
    const { transctionId } = this.props;
    postAPI(
      `/postProject/add-post-project-close-review-admin`,
      currentData
    ).then((res) => {
      if (res) {
        this.handleApi(transctionId);
      }
    });
  };

  handleApi = async (transctionId) => {
    this.props.funcIs.handleCloseModal();
    const currentData = { _id: transctionId };
    let res = await this.props.$closeProject(currentData);
    this.resetField();
    if (res) {
      if (this.props?.timeOutIs) {
        this.props.handleTimeOut();
      }
    }
  };

  resetField = () => {
    this.setState({
      reasondesc: "",
      reason1: "",
      reason2: "",
      reason3: "",
    });
  };

  handleClose = () => {
    this.props.funcIs.handleCloseModal();
    this.resetField();
  };

  componentWillReceiveProps(nextProps) {
    const pathIs = nextProps.location.pathname;
    const closedProject = nextProps._closeProject.closeProjectIs;
    const { LoadingIs } = this.state;
    if (closedProject && LoadingIs) {
      const transactionData = {
        List: 1,
        project: "",
        min_budget: "",
        max_budget: "",
        deadline: "",
      };
      if (pathIs === "/transactions") {
        this.props.$transactionList(transactionData);
      } else if(pathIs === "/transactions/ActiveTransactions") {
        this.props.$activeTransactions(transactionData);
      } else if(pathIs === "/transactions/InactiveBriefs"){
        this.props.$inactiveBrief(transactionData);
      }else{
        this.props.$inactiveProject(transactionData);
      }
      this.setState({ LoadingIs: false });
    }
  }

  componentDidMount() {
    this.resetField();
  }

  render() {
    const { modalIsOpen } = this.props;
    const { reasondesc, LoadingIs } = this.state;

    return (
      <React.Fragment>
        <div>
          <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <button
              type="button"
              className="close media-upload-modal"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleClose}
            >
              <span
                aria-hidden="true"
                style={{ color: "black", fontSize: "32px" }}
              >
                &times;
              </span>
            </button>
            <div className="text-center">
              <h3>Tell us why you close this project</h3>
            </div>
            <hr className="my-5" />
            <div className="container">
              <div>
                <div className="col-12">
                  <div className="form-group">
                    <div className="row col">
                      <div className="col ml-n2">
                        <small className="">
                          <input
                            type="checkbox"
                            name="reason1"
                            disabled=""
                            value="Not interested"
                            onChange={this.handleOnChange}
                          />{" "}
                          Client backed out
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <div className="row col">
                      <div className="col ml-n2">
                        <small className="">
                          <input
                            type="checkbox"
                            name="reason2"
                            disabled=""
                            value="From Admin Side"
                            onChange={this.handleOnChange}
                          />{" "}
                          Creator backed out
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <div className="row col">
                      <div className="col ml-n2">
                        <small className="">
                          <input
                            type="checkbox"
                            name="reason3"
                            disabled=""
                            value="Other"
                            onChange={this.handleOnChange}
                          />{" "}
                          Other
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="reasondesc"
                  value={reasondesc}
                  onChange={this.handleOnChange}
                ></textarea>
              </div>
              <div className="form-group">
                <button className="btn btn-primary" onClick={this.handleSubmit}>
                  {LoadingIs ? <LoaderSmall /> : "Submit"}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}
const mapState = ({ closeProject, transactionList, activeTransactions, inactiveBrief, inactiveProject }) => {
  return {
    _closeProject: closeProject,
    _transactionList: transactionList,
    _activeTransactions: activeTransactions,
    _inactiveBrief : inactiveBrief,
    _inactiveProject : inactiveProject
  };
};
const mapDispatch = (dispatchEvent) => ({
  $closeProject: (values) => dispatchEvent(closeProject(values)),
  $transactionList: (values) => dispatchEvent(transactionList(values)),
  $activeTransactions: (values) => dispatchEvent(activeTransactions(values)),
  $inactiveBrief: (values) => dispatchEvent(inactiveBrief(values)),
  $inactiveProject: (values) => dispatchEvent(inactiveProject(values)),
});
export default connect(mapState, mapDispatch)(CloseProjectReasonModal);
