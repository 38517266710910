import React, { Component } from "react";
import Modal from "react-modal";
import { putAPI } from "../../../store/api-interface";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    maxWidth: "600px",
    width: "100%",
  },
};

class StarredModal extends Component {
  state = {
    LoadingIs: false,
    reasonIs: "",
    allCollateralList: [],
    allCheck: false,
  };

  handleOnChange = (e, id) => {
    if (e.target.value.length < 200) {
      this.setState({
        allCollateralList: this.state.allCollateralList.map((item) => {
          if (item.collateral_id === id) {
            item.star_description = e.target.value
          }
          return item
        })
      })
    }
  };

  handleCloseModal = () => {
    this.props.handleCloseModal()
  };

  handleAllCheckbox = (e) => {
    this.setState({
      allCollateralList: this.state.allCollateralList.map((item) => {
        if (e.target.checked) {
          item.star = 1
          item.block = false
        } else {
          item.star = 0
          item.star_description = ""
          item.block = false
        }
        return item
      })
      , allCheck: e.target.checked
    })
  }
    
  handleCheckbox = (e, obj) => {
    this.setState({ allCollateralList : this.state.allCollateralList.map((item) =>{
      if(item?.collateral_id === obj?.collateral_id){
        if (e.target.checked) {
          item.star = 1
          item.block = false
        } else {
          item.star = 0
          item.star_description = ""
          item.block = false
        }
      }
      return item
    }) })
  }

  handleSubmit = () => {
    const { allCollateralList } = this.state;
    let arr = []
    allCollateralList.forEach(item => {
      arr.push({ ...item, star_description:item.star_description.trim() })
    })
    const data = {
      user_id : this.props.starId,
      collateral_id: arr,
    }
    // console.log("data == ",data)
    putAPI("/collateral/star-collateral", data)
    .then((res) => {          
      this.setState({
          LoadingIs : false
      }, () => {
        this.handleCloseModal()
        this.props.handleCallApi()
      });
    })
    .catch((error) => {         
      alert(error)
      this.setState({
        LoadingIs : false
      })
    });
  }

  componentDidUpdate(prevProps) {
    const { collateralCheckList } = this.props;
    if (prevProps.collateralCheckList != this.props.collateralCheckList) {
      // console.log("collateralCheckList = ",collateralCheckList)
      let arr = collateralCheckList.filter(item => item.status == 1)
      let array = []
      arr.forEach(item => {
        if (item?.star_description && item?.star_description?.length > 0) {
           array.push({...item,block:true})
        } else {
           array.push({...item,block:false})
        }
      })
      this.setState({ allCollateralList: array})
    }
  }

  render() {
    const { LoadingIs,allCheck,allCollateralList } = this.state;
    const { starredModIs } = this.props;
    return (
      <React.Fragment>
        <div>
          <Modal
            isOpen={starredModIs}
            style={customStyles}
            contentLabel="Example Modal"
          >

            <div className="table-responsive mb-5" style={{maxHeight:"330px",overflowY:"auto"}}>
              <table className="table table-sm table-nowrap card-table" >
                <thead>
                  <tr>
                <th style={{width:"6rem"}}>
                  <div className="d-flex">
                    <input type="checkbox" name ={`allcheckbox`} defaultChecked={allCheck} onClick={(e) => this.handleAllCheckbox(e)}/>
                    <span className="ml-2">Check</span> 
                  </div>
                  </th>
                  <th> Collateral Name</th>
                  <th> Text</th>
                  </tr>
                </thead>
                <tbody className="list">
                    {allCollateralList.map((obj, index) => (
                    <tr key={index}>
                        <td className="orders-method" style={{width:"5rem"}}>
                            <input type="checkbox" name ={`checkbox${index}`} defaultChecked={obj.star == 1 ? true : false} onClick={(e) => this.handleCheckbox(e, obj, index)}/>
                        </td>   
                        <td className="orders-method">{obj?.collateral_name}</td>     
                        <td className="orders-method">
                           <textarea
                              className="form-control"
                              disabled={obj?.star == 0 || obj?.block ? true : false}
                              style={{backgroundColor: obj?.star == 0 || obj?.block ? "#f5f2f2" : ""}}
                              placeholder="Enter Reason"
                              value={obj?.star_description}
                              onChange={(e) => this.handleOnChange(e,obj?.collateral_id)}
                            ></textarea>
                        </td>  
                    </tr>
                    ))}
                </tbody>
              </table>
            </div>
                    
            <div className="mx-auto text-center">
            <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={this.handleSubmit}
            >
                {LoadingIs ? "Processing......" : "Submit"}
            </button>
            <button
                type="button"
                className="btn btn-secondary ml-2"
                data-dismiss="modal"
                onClick={this.handleCloseModal}
            >
                Close
            </button>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export default StarredModal;
