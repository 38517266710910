import React, { Component } from "react";
import { connect } from "react-redux";
import { getArtistData } from "../../../store/action/clientList.action";
import ErrorApi from "../Common/Error/ErrorApi";

class ArtistSpeclization extends Component {
  state = {
    ArtistSpeclization: [],
    artistSoftware: [],
    primarySpec: "",
    primarySpecExp:0,
    secondarySpec: "",
    software: "",

    editIs: true,
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleEditOnClick = () => {
    this.setState({
      editIs: false,
    });
  };

  handleGetArtistSpeclization = (id) => {
    this.props.$getArtistData(id);
  };

  componentDidMount() {
    // this.props.handleClients.handlepathName("/artist");
    const artistParamsId = this.props.match.params.id.split(":").pop();
    this.handleGetArtistSpeclization(artistParamsId);
  }

  componentWillReceiveProps(nextProps) {
    const creatorData = nextProps._getArtistData.getCreatorData;
    if (creatorData.status === "1" && creatorData.userSpecialization) {
      this.setState({
        ArtistSpeclization: creatorData.userSpecialization.specialization,
        artistSoftware: creatorData.userSoftware.software,
        primarySpec: creatorData.userSpecialization.primary_specialization,
        primarySpecExp:creatorData?.userSpecialization?.primary_specialization_experience?creatorData.userSpecialization.primary_specialization_experience:0,
      });
    }
  }

  render() {
    const {
      artistSoftware,
      ArtistSpeclization,
      primarySpec,
      secondarySpec,
      software,
      editIs,
      primarySpecExp,
    } = this.state;
    return (
        this.props._getArtistData.getArtistError ? (
          <ErrorApi {...this.props} />
        ) : (
          <React.Fragment>
            {ArtistSpeclization.length ? (
              <section className="container">
                <div className="mx-auto">
                  <div className="questin-Listing">
                    <div className="row mt-3">
                      <div className="col-12 col-md-12">
                        <div className="form-group ">
                          <label>Primary Speclization</label>
                          <input
                            type="text"
                            className="form-control"
                            name="primarySpec"
                            value={primarySpec}
                            onChange={this.handleOnChange}
                            disabled={editIs ? "disabled" : ""}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-12">
                        <div className="form-group ">
                          <label>Experience (Primary Specialization)</label>
                          <div className="row"> 
                          <div className="col-11">

                          <input
                            type="number"
                            className="form-control"
                            name="primarySpecExp"
                            value={primarySpecExp}
                            onChange={this.handleOnChange}
                            disabled={editIs ? "disabled" : ""}
                            /> 
                            </div>
                          <div className="col-1 text-right">

                          <label className="pt-2">{primarySpecExp>1?'Years':'Year'}</label> 
                            </div>
                            </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-12">
                        <div className="form-group">
                          <label className="mb-1">Secondary Speclization</label>
                          {/* <div className="selection-tags d-flex align-items-center"> */}
                            {/* <div className="ml-2">
                              {ArtistSpeclization.map((obj, index) => (
                                <span className="keyword-span" key={index}>
                                  {obj.name}
                                </span>
                              ))}
                            </div> */}
                            <div className="row align-items-center specialisation_block" >
                              {ArtistSpeclization.map((obj, index) => (
                                <span className="keyword-span m-1" key={index}>
                                  {obj.name}
                                </span>
                              ))}
                            <span style={{ display: "inline-block" }}>
                              <input
                                type="text"
                                className={`${
                                  ArtistSpeclization.length
                                    ? "key-input"
                                    : "form-control"
                                }`}
                                name="secondarySpec"
                                value={secondarySpec}
                                onChange={this.handleOnChange}
                                disabled={editIs ? "disabled" : ""}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-12">
                        <div className="form-group">
                          <label className="mb-1">Software</label>
                          {/* <div className="selection-tags d-flex align-items-center"> */}
                            {/* <div className="ml-2">
                              {artistSoftware.map((obj, index) => (
                                <span className="keyword-span" key={index}>
                                  {obj.name}
                                </span>
                              ))}
                            </div> */}
                            <div className="row align-items-center specialisation_block">
                              {artistSoftware.map((obj, index) => (
                                <span className=" keyword-span m-1" key={index}>
                                  {obj.name}
                                </span>
                              ))}
                            <span style={{ display: "inline-block" }}>
                              <input
                                type="text"
                                className={`${
                                  artistSoftware.length
                                    ? "key-input"
                                    : "form-control"
                                }`}
                                name="software"
                                value={software}
                                onChange={this.handleOnChange}
                                disabled={editIs ? "disabled" : ""}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-12">
                  <button className="btn btn-primary" onClick={this.handleEditOnClick}>
                    {editIs ? "Edit" : "Update"}
                  </button>
                </div> */}
                    </div>
                  </div>
                  <hr className="my-5" />
                </div>
              </section>
            ) : (
              <h3 className="text-center">No data Found</h3>
            )}
          </React.Fragment>
        )
    );
  }
}

const mapState = ({ getArtistData }) => {
  return {
    _getArtistData: getArtistData,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $getArtistData: (values) => dispatchEvent(getArtistData(values)),
});
export default connect(mapState, mapDispatch)(ArtistSpeclization);
