import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import LogIn from "../Component/Unauthorized/LogIn/Login";
import Home from "../Component/Unauthorized/Home/Home";
import NotFound from "../NotFound/NotFound";
import MainRoute from "../Component/Autorized/main";

export default class RootRouting extends Component {

  state = {
    adminLogin: false,
  };

  componentDidMount() {
    const token = localStorage.getItem("adminLogin");
    if (token) {
      this.setState({ adminLogin: true });
    } else {
      this.setState({ adminLogin: false });
    }

  }

  setIndexState = (stateObj) => {
     this.setState(stateObj);
  };

  render() {
    const { adminLogin } = this.state;
    return (
      adminLogin 
        ? 
          <Switch>
            <Route path="/" render={(props) => <MainRoute {...props} {...this.state} setIndexState={this.setIndexState}/>} />
            <Route exact path="*/" render={(props) => <NotFound {...props}/>} />
          </Switch>
        :          
          <Switch>
            <Route exact path="/" render={(props) => <LogIn {...props} setIndexState={this.setIndexState} />}/>
            <Route exact path="/home" render={(props) => <Home {...props} />} />
            <Route exact path="*/" render={(props) => <NotFound {...props}/>} />
          </Switch>
    )
  }
}
