import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { creativeUsersData } from "../../../store/action/clientList.action";
// import Pagination from "../Common/Pagination/paginate";
import ReactPaginate from "react-paginate";

class CreativeField extends Component {
  state = {
    fieldData: [],
    fieldName: "",
    fieldId: "",
    creativePageIs: 1,
    creativeTotalPage: "",
    creativeCurrentPage: "",
    LoadingIs: false,
    creativeFieldActive: false,
  };

  handlecreativefield = () => {
    const { creativePageIs } = this.state;
    this.setState({
      LoadingIs : true
    })
    this.props.$creativeUsersData(creativePageIs);
  };

  handlePageClick = (data) =>{
    let currentSelected = (data.selected)+1;
    this.props.$creativeUsersData(currentSelected);
  }

  componentDidMount() {
    this.handlecreativefield();
    this.setState({
      creativeFieldActive: true,
    });
  }

  componentWillReceiveProps(nextProps) {
    const dataIs = nextProps._creativeUsersData.getUsersData;
    if (dataIs) {
      this.setState({
        fieldData: dataIs.parentCreativeFields,
        creativeTotalPage: dataIs.totalPages,
        creativeCurrentPage: dataIs.currentPage,
        LoadingIs: false,
      });
    }
  }

  render() {
    const { fieldData, LoadingIs } = this.state;

    /*------------------list sort -----------------*/
    function listCompare( a, b ) { 
      const name = a.name.toLowerCase();
      const nameIs = b.name.toLowerCase();
      if ( name < nameIs){
        return -1;
      }
      if ( name > nameIs ){
        return 1;
      }
      return 0;
    }        

    const ListIs = fieldData.sort( listCompare );

    /*------------------list sort -----------------*/

    return (
      <React.Fragment>        
        <section className="creative-field">
          <div className="container mx-auto">
            {
          LoadingIs ? 
          <div className="col-md-12">
             <div className="d-flex justify-content-center align-items-center">
               <h3>Loading......</h3>
             </div>
          </div>:
            <div className="table-responsive mb-5">
              <table className="table table-sm table-nowrap card-table">
                <thead>
                  <tr>
                  <th>
                      <a
                        href="!#"
                        className="text-muted sort"
                        // data-sort="orders-product"
                      >
                        Action
                      </a>
                    </th>
                    <th>
                      <a
                        href="#!"
                        className="text-muted sort"
                        // data-sort="orders-order"
                      >
                        Name
                      </a>
                    </th>                    
                  </tr>
                </thead>
                <tbody className="list">
                  {ListIs.map((obj, index) => (
                    <tr key={index}>
                       <td className="orders-method">
                        <Link to={`/parentcreative/:view/:${obj._id}/:`}>
                          <button className="btn btn-outline-success btn-sm mr-2">
                            <i className="fe fe-eye"></i>
                          </button>
                        </Link>
                        <Link
                          to={`/parentcreative/:edit/:${obj._id}/:${obj.name}`}
                        >
                          <button className="btn btn-outline-info btn-sm mr-2">
                            <i className="fe fe-edit"></i>
                          </button>
                        </Link>
                      </td>
                      <td className="orders-order">{obj.name}</td>                     
                      
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
             }
            {/**** Pagination****** */}

            { (this.state.creativeTotalPage > 0) && 
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.creativeTotalPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />}
            
          </div>
        </section>
      </React.Fragment>
    );
  }
}
const mapState = ({ creativeUsersData }) => {
  return {
    _creativeUsersData: creativeUsersData,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $creativeUsersData: (values) => dispatchEvent(creativeUsersData(values)),
});
export default connect(mapState, mapDispatch)(CreativeField);
