import React, { Component } from "react";
const seacrhPath = [
  { name: "/transactions/ActiveTransactions" },
  { name: "/transactions/closedTransactions" },
  { name: "/transactions/completeTransactions" },
  { name: "/transactions/DeletedProjects" },
];

export default class TableArtistTransHead extends Component {
  isShowMinBudget = () => {
    const artistId = this.props.artistId;
    return !seacrhPath.find((el) => el.name === artistId);
  };

  render() {
    const artistId = this.props.artistId;
    
    const { closedTranscationIs } = this.props;
    const { onCheckboxClick } = this.props.handleTransaction;

    // const tableContentArr = [
    //   { name: "Project", show: true },
    //   { name: "Min Budget", show: this.isShowMinBudget() },
    //   { name: "Max Budget", show: this.isShowMinBudget() },
    //   { name: "Deadline", show: this.isShowMinBudget() },
    //   { name: "Status", show: true },
    //   {name:"Account Manager",artistIs:artistId,show:true},
    //   { name: "Admin User", artistIs: artistId, show: true },
    // ];

    const notAllSelected = this.props.transactionData.some(val => !val.selected)

    const incomplete = [
      { field : "name" },
      { field : "stage" },
      { field : "scope" },
      { field : "collateral" },
      { field : "brief type (fixed/retainer)" },
      { field : "client name" },
      { field : "Min budget" },
      { field : "Max budget" },
      { field : "AM" },
      { field : "Start date" },
      { field : "Last updated on" }
    ]

    const pending = [
      { field : "name" },
      { field : "scope" },
      { field : "client name" },
      { field : "company name" },
      { field : "collateral" },
      { field : "brief type (fixed/retainer)" },
      { field : "Min budget" },
      { field : "Max budget" },
      { field : "AM" },
      { field : "#Creators Invited"},
      { field : "Start date" },
      { field : "Last updated on" }
    ]

    const activeBrief = [
      { field : "name" },
      { field : "client name" },
      { field : "company name" },
      { field : "collateral" },
      { field : "brief type (fixed/retainer)" },
      { field : "AM" },
      { field : "payment mode" },
      { field : "#proposal" },
      { field : "#Creators Invited" },
      { field : "time to 1st proposal (Client Submission) " },
      { field : "time to 1st proposal (Active Brief Onwards) " },
      { field : "3 Proposals within 24 hours (Client Submission)" },
      { field : "3 Proposals within 24 hours (Active Brief Onwards)" },
      { field : "scope" },
      { field : "Min budget" },
      { field : "Max budget" },
      { field : "Start date" },
      { field : "Approval date" },
      { field : "Last updated on" },
    ]

    const activeProject = [
      { field : "view proposal" },
      { field : "name" },
      { field : "client name" },
      { field : "company name" },
      { field : "creator name" },
      { field : "collateral" },
      { field : "brief type (fixed/retainer)" },
      { field : "proposal type" },
      { field : "AM" },
      { field : "budget" },
      { field : "creator earnings" },
      { field : "posted by (client/admin)" },
      { field : "current milestone" },
      { field : "total milestone" },
      { field : "next milestone due on" },
      { field : "payment mode" },
      { field : "#proposal" },
      { field : "#Creators Invited" },
      { field : "time to 1st proposal (Client Submission) " },
      { field : "time to 1st proposal (Active Brief Onwards) " },
      { field : "3 Proposals within 24 hours (Client Submission)" },
      { field : "3 Proposals within 24 hours (Active Brief Onwards)" },
      { field : "start date" },
      { field : "project approved date" },
      { field : "last updated on" },
      { field : "project completion date" },
    ]

    const completedProject = [
      { field : "view proposal" },
      { field : "name" },
      { field : "client name" },
      { field : "company name" },
      { field : "creator name" },
      { field : "client rating" },
      { field : "creator rating" },
      { field : "am review" },
      { field : 'AM'},
      { field : "collateral" },
      { field : "brief type (fixed/retainer)" },
      { field : "proposal type" },
      { field : "budget" },
      { field : "creator earnings" },
      { field : "posted by (client/admin)" },
      { field : "payment mode" },
      { field : "#proposal" },
      { field : "#Creators Invited" },
      { field : "time to 1st proposal (Client Submission) " },
      { field : "time to 1st proposal (Active Brief Onwards) " },
      { field : "3 Proposals within 24 hours (Client Submission)" },
      { field : "3 Proposals within 24 hours (Active Brief Onwards)" },
      { field : "start date" },
      { field : "project approved date" },
      { field : "project completion date" },
    ]

    const rejectedProject = [
      { field : "name" },
      { field : "client name" },
      { field : "company name" },
      { field : "rejected by" },
      { field : "rejection note" },
      { field : "collateral" },
      { field : "brief type (fixed/retainer)" },
      { field : "scope" },
      { field : "min budget" },
      { field : "max budget" },
      { field : "AM" },
      { field : "creators invited" },
      { field : "start date" },
      { field : "rejected on" },
    ]

    const closeBrief = [
      { field : "name" },
      { field : "client name" },
      { field : "company name" },
      { field : "closed by" },
      { field : "closed note" },
      { field : "collateral" },
      { field : "brief type (fixed/retainer)" },
      { field : "AM" },
      { field : "payment mode" },
      { field : "#proposal" },
      { field : "#Creators Invited" },
      { field : "time to 1st proposal (Client Submission) " },
      { field : "time to 1st proposal (Active Brief Onwards) " },
      { field : "3 Proposals within 24 hours (Client Submission)" },
      { field : "3 Proposals within 24 hours (Active Brief Onwards)" },
      { field : "scope" },
      { field : "min budget" },
      { field : "max budget" },
      { field : "start date" },
      { field : "approved date" },
      { field : "closed on" }
    ]

    const closedProject = [
      { field : "view proposal" },
      { field : "name" },
      { field : "client name" },
      { field : "company name" },
      { field : "creator name" },
      { field : "closed by" },
      { field : "closed note" },
      { field : "total budget" },
      { field : "budget approved" },
      { field : "creator earnings" },
      { field : "actual creator earnings" },
      { field : "collateral" },
      { field : "brief type (fixed/retainer)" },
      { field : "proposal type" },
      { field : "AM" },
      { field : "posted by" },
      { field : "current milestone" },
      { field : "total milestone" },
      { field : "payment mode" },
      { field : "#proposals" },
      { field : "#Creators Invited" },
      { field : "time to 1st proposal (Client Submission) " },
      { field : "time to 1st proposal (Active Brief Onwards) " },
      { field : "3 Proposals within 24 hours (Client Submission)" },
      { field : "3 Proposals within 24 hours (Active Brief Onwards)" },
      { field : "start date" },
      { field : "project approved date" },
      { field : "project closed on" }
    ]

    const deletedBrief = [
      { field : "name" },
      { field : "client name" },
      { field : "company name" },
      { field : "deleted by" },
      { field : "deletion note" },
      { field : "collateral" },
      { field : "brief type (fixed/retainer)" },
      { field : "AM" },
      { field : "payment mode" },
      { field : "#proposals" },
      { field : "#Creators Invited" },
      { field : "time to 1st proposal (Client Submission) " },
      { field : "time to 1st proposal (Active Brief Onwards) " },
      { field : "3 Proposals within 24 hours (Client Submission)" },
      { field : "3 Proposals within 24 hours (Active Brief Onwards)" },
      { field : "scope" },
      { field : "min budget" },
      { field : "max budget" },
      { field : "start date" },
      { field : "approved date" },
      { field : "deleted on" }
    ]

    const deletdProject = [
      { field : "view proposal" },
      { field : "name" },
      { field : "client name" },
      { field : "company name" },
      { field : "creator name" },
      { field : "deleted by" },
      { field : "deletion note" },
      { field : "total budget" },
      { field : "budget approved" },
      { field : "creator earnings" },
      { field : "actual creator earnings" },
      { field : "collateral" },
      { field : "brief type (fixed/retainer)" },
      { field : "proposal type" },
      { field : "AM" },
      { field : "posted by" },
      { field : "current milestone" },
      { field : "total milestone" },
      { field : "payment mode" },
      { field : "#proposals" },
      { field : "#Creators Invited" },
      { field : "time to 1st proposal (Client Submission) " },
      { field : "time to 1st proposal (Active Brief Onwards) " },
      { field : "3 Proposals within 24 hours (Client Submission)" },
      { field : "3 Proposals within 24 hours (Active Brief Onwards)" },
      { field : "start date" },
      { field : "project approved date" },
      { field : "project deleted on" },
    ]

    const callback = [
      { field : "project" },
      { field : "min budget" },
      { field : "max budget" },
      { field : "deadline" },
      { field : "status" },
      { field : "AM" },
      { field : "admin user" },
      { field : "name" },
      { field : "email" },
      { field : "phone number" },
      { field : "collateral" },
      { field : "posted by" },
      { field : "scope" },
      { field : "start date" },
      { field : "call back request on" },
      { field : "reason" }
    ]

    const inActiveBrief = [
      { field : "name" },
      { field : "Last updated on" },
      { field : "Client name" },
      { field : "Company name" },
      { field : "AM" },
      { field : "Collateral" },
      { field : "brief type (fixed/retainer)" },
      { field : "Payment mode" },
      { field : "#proposal" },
      { field : "#Creators Invited" },
      { field : "time to 1st proposal (Client Submission) " },
      { field : "time to 1st proposal (Active Brief Onwards) " },
      { field : "3 Proposals within 24 hours (Client Submission)" },
      { field : "3 Proposals within 24 hours (Active Brief Onwards)" },
      { field : "Scope" },
      { field : "Min budget" },
      { field : "Max budget" },
      { field : "Start date" },
      { field : "Approved date" },
    ]

    const inActiveProject = [
      { field : "View Proposal" },
      { field : "name" },
      { field : "Last updated on" },
      { field : "Client name" },
      { field : "Company name" },
      { field : "Creator name" },
      { field : "AM" },
      { field : "Collateral" },
      { field : "brief type (fixed/retainer)" },
      { field : "proposal type" },
      { field : "Budget" },
      { field : "Creators earnings" },
      { field : "Posted by" },
      { field : "Current Milesones" },
      { field : "Total Milestones" },
      { field : "Next Milestones due on" },
      { field : "Payment mode" },
      { field : "#Proposal" },
      { field : "#Creators Invited" },
      { field : "time to 1st proposal (Client Submission) " },
      { field : "time to 1st proposal (Active Brief Onwards) " },
      { field : "3 Proposals within 24 hours (Client Submission)" },
      { field : "3 Proposals within 24 hours (Active Brief Onwards)" },
      { field : "Start date" },
      { field : "Project approved date" },
      { field : "Project completion date" }
    ]

    return (
      <React.Fragment>
        <thead>
          <tr>
            {(artistId === "/transactions" || 
              artistId === "/transactions/ActiveTransactions" ||
              artistId === "/transactions/incompleteTransactions" ||
              artistId === "/transactions/IncompleteHighScopeTrans" ||
              artistId === "/transactions/PendingTransactions") &&
              <th>
                <input className="" type="checkbox" value="" checked={!notAllSelected} onChange={() => onCheckboxClick('all')} />  
              </th>
            }
            {!closedTranscationIs && (
              <th><a href="#!" className="text-muted sort">Action</a></th>
            )}
            
            {/*  for incomplete tab ******************************************** */}

            {artistId === "/transactions/incompleteTransactions" &&
              incomplete.map((obj, index) => {
                return<th key={index}>
                  <a href="#!" className="text-muted sort">
                    {obj.field}
                  </a>
                </th>
              })
            }

            {/* for pending ************************************************************ */}

            {artistId === "/transactions/PendingTransactions" &&
              pending.map((obj, index) => {
                return<th key={index}>
                  <a href="#!" className="text-muted sort">
                    {obj.field}
                  </a>
                </th>
              })
            }

            {/* Active brief ********************************************************* */}
            
            {artistId === "/transactions" &&
              activeBrief.map((obj, index) => {
                return<th key={index}>
                  <a href="#!" className="text-muted sort">
                    {obj.field}
                  </a>
                </th>
              })
            }

            {/* Active Project ****************************************************** */}

            {artistId === "/transactions/ActiveTransactions" &&
              activeProject.map((obj, index) => {
                return<th key={index}>
                  <a href="#!" className="text-muted sort">
                    {obj.field}
                  </a>
                </th>
              })
            }

            {/* Inactive Brief ************************************************* */}
            
            {artistId === "/transactions/InactiveBriefs" &&
              inActiveBrief.map((obj, index) => {
                return<th key={index}>
                  <a href="#!" className="text-muted sort">
                    {obj.field}
                  </a>
                </th>
              })
            }

            {/* Inactive Project ************************************************* */}
            
            {artistId === "/transactions/InactiveProjects" &&
              inActiveProject.map((obj, index) => {
                return<th key={index}>
                  <a href="#!" className="text-muted sort">
                    {obj.field}
                  </a>
                </th>
              })
            }

            {/* completed project ***************************************************** */}

            {artistId === "/transactions/completeTransactions" &&
              completedProject.map((obj, index) => {
                return<th key={index}>
                  <a href="#!" className="text-muted sort">
                    {obj.field}
                  </a>
                </th>
              })
            }

            {/* Rejected ******************************************************************** */}

            {artistId === "/transactions/RejectedTransactionList" &&
              rejectedProject.map((obj, index) => {
                return<th key={index}>
                  <a href="#!" className="text-muted sort">
                    {obj.field}
                  </a>
                </th>
              })
            }

            {/* close brief ****************************************************************** */}

            {artistId === "/transactions/ClosedBrief" &&
              closeBrief.map((obj, index) => {
                return<th key={index}>
                  <a href="#!" className="text-muted sort">
                    {obj.field}
                  </a>
                </th>
              })
            }

            {/* close project **************************************************************** */}

            {artistId === "/transactions/closedTransactions" &&
              closedProject.map((obj, index) => {
                return<th key={index}>
                  <a href="#!" className="text-muted sort">
                    {obj.field}
                  </a>
                </th>
              })
            }

            {/* deleted brief ****************************************************************** */}

            {artistId === "/transactions/DeletedBrief" &&
              deletedBrief.map((obj, index) => {
                return<th key={index}>
                  <a href="#!" className="text-muted sort">
                    {obj.field}
                  </a>
                </th>
              })
            }

            {/* deleted project ***************************************************************** */}

            {artistId === "/transactions/DeletedProjects" &&
              deletdProject.map((obj, index) => {
                return<th key={index}>
                  <a href="#!" className="text-muted sort">
                    {obj.field}
                  </a>
                </th>
              })
            }

            {/* call back request ************************************************************ */}

            {artistId === "/transactions/DontLikeTransactions" &&
              callback.map((obj, index) => {
                return<th key={index}>
                  <a href="#!" className="text-muted sort">
                    {obj.field}
                  </a>
                </th>
              })
            }


           {/* ************ */}
            {/* {tableContentArr.map(
              (obj, index) =>
                !(
                  obj.artistIs === "/transactions/PendingTransactions" ||
                  obj.artistIs === "/transactions/incompleteTransactions" ||
                  obj.artistIs === "/transactions/IncompleteHighScopeTrans"
                ) && (
                  <React.Fragment key={index}>
                    {obj.show && (
                      <th
                        className={`${
                          obj.name === "Action" ? "text-center" : ``
                        }`}
                      >
                        <a href="#!" className="text-muted sort">
                          {obj.name}
                        </a>
                      </th>
                    )}
                    {closedTranscationIs && obj.name === "Admin user" && (
                      <React.Fragment>
                        <th>
                          <a href="#!" className="text-muted sort">
                            Rejected By
                          </a>
                        </th>
                        <th>
                          <a href="#!" className="text-muted sort">
                            Reason
                          </a>
                        </th>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )
            )}
            <th>
              <a href="#!" className="text-muted sort">
                Email
              </a>
            </th>
            <th>
              <a href="#!" className="text-muted sort">
                Phone Number
              </a>
            </th>
            <th>
              <a href="#!" className="text-muted sort">
                Collateral
              </a>
            </th>
            {(artistId === "/transactions/DeletedBrief" ||
              artistId === "/transactions/DeletedProjects" ||
              artistId === "/transactions/ClosedBrief" ||
              artistId === "/transactions/closedTransactions" ||
              artistId === "/transactions/IncompleteHighScopeTrans" ||
              artistId === "/transactions/incompleteTransactions") && (
              <React.Fragment>
                {(artistId !== "/transactions/incompleteTransactions" || artistId !== "/transactions/IncompleteHighScopeTrans") && (
                  <th>
                    <a href="#!" className="text-muted sort">
                      {artistId === "/transactions/ClosedBrief" ||
                      artistId === "/transactions/closedTransactions"
                        ? "Closed By"
                        : " Deleted By"}
                    </a>
                  </th>
                )}
                {(artistId === "/transactions/incompleteTransactions" ||
                  artistId === "/transactions/IncompleteHighScopeTrans" ||
                  artistId === "/transactions/ClosedBrief" ||
                  artistId === "/transactions/closedTransactions" ||
                  artistId === "/transactions/DeletedBrief" ||
                  artistId === "/transactions/DeletedProjects") && (
                  <th>
                    <a href="#!" className="text-muted sort">
                      {artistId === "/transactions/ClosedBrief" ||
                      artistId === "/transactions/closedTransactions"
                        ? "Closed Stage"
                        : (artistId === "/transactions/incompleteTransactions" || artistId === "/transactions/IncompleteHighScopeTrans")
                        ? "Stage"
                        : "Deleted Stage"}
                    </a>
                  </th>
                )}
              </React.Fragment>
            )}
            <th>
              <a href="#!" className="text-muted sort">
                Posted BY
              </a>
            </th>
          
             {(artistId === "/transactions/ClosedBrief" ||
                artistId === "/transactions/ActiveTransactions" ||
                artistId === "/transactions" ||
                artistId === "/transactions/completeTransactions" ||
                artistId === "/transactions/closedTransactions") && (
              <th>
                <a href="#!" className="text-muted sort">
                  1st Proposal Date
                </a>
              </th>
              )}
            {(artistId === "/transactions/ClosedBrief" ||
              artistId === "/transactions/ActiveTransactions" ||
              artistId === "/transactions" ||
               artistId === "/transactions/completeTransactions" ||
                  artistId === "/transactions/closedTransactions" ||
                  artistId === "/transactions/DeletedBrief" ||
                  artistId === "/transactions/DeletedProjects") && (
              <th>
                <a href="#!" className="text-muted sort">
                  Time To 1st Proposal
                </a>
              </th>
              )} */}
            {/* { ( artistId === "/transactions/completeTransactions") && 
               <th>
                <a href="#!" className="text-muted sort"> 
                  Creator Has Reviewed 
                </a>
               </th>
            }
            { ( artistId === "/transactions/completeTransactions") && 
               <th>
                <a href="#!" className="text-muted sort"> 
                   AM Has Reviewed
                </a>
               </th>
            } */}
            {/*-----------------------Date Field ----------------------------*/}
            {/* <th>
              <a href="#!" className="text-muted sort">
                Start Date
              </a>
            </th>
            {artistId === "/transactions/ClosedBrief" && (
              <th>
                <a href="#!" className="text-muted sort">
                  Approved Date
                </a>
              </th>
            )}
            {artistId !== "/transactions/DontLikeTransactions" && (
              <th>
                <a href="#!" className="text-muted sort">
                  {artistId === "/transactions/DeletedBrief"
                    ? "Deleted Date"
                    : artistId === "/transactions/PendingTransactions" ||
                      artistId === "/transactions/RejectedTransactionList"
                      ? "Completion Date"
                      : artistId === "/transactions" ||
                        artistId === "/transactions/ActiveTransactions" ||
                        artistId === "/transactions/completeTransactions" ||
                        artistId === "/transactions/closedTransactions" ||
                        artistId === "/transactions/DeletedProjects"
                        ? "Approved Date"
                        : artistId === "/transactions/ClosedBrief"
                          ? "Closed Date"
                          : "Last Updated Date"
                    }
                </a>
              </th>
            )}
            {(artistId === "/transactions/closedTransactions" ||
              artistId === "/transactions/completeTransactions" ||
              artistId === "/transactions/DeletedProjects") && (
              <th>
                <a href="#!" className="text-muted sort">
                  {artistId === "/transactions/completeTransactions"
                    ? "Completion Date"
                    : "Close Date"}
                </a>
              </th>
            )}
            {artistId === "/transactions/RejectedTransactionList" && (
              <React.Fragment>
                <th>
                  <a href="#!" className="text-muted sort">
                    Rejected Date
                  </a>
                </th>
                <th>
                  <a href="#!" className="text-muted sort">
                    Rejected By
                  </a>
                </th>
              </React.Fragment>
            )} */}
            {/*-----------------------Date Field ----------------------------*/}

            {/*-----------------------Call Back Request on ----------------------------*/}
            {/* {artistId === "/transactions/DontLikeTransactions" && (
              <React.Fragment>
                <th>
                  <a href="#!" className="text-muted sort">
                    Call Back Request On
                  </a>
                </th>
                <th>
                  <a href="#!" className="text-muted sort">
                    Reason
                  </a>
                </th>
              </React.Fragment>
            )} */}
            {/*-----------------------Call Back Request on ----------------------------*/}

            {/*------------------------Proposal data ------------------*/}
            {/* {(artistId === "/transactions/ActiveTransactions" ||
              artistId === "/transactions/completeTransactions" ||
              artistId === "/transactions/closedTransactions" ||
              artistId === "/transactions/DeletedProjects") && (
              <React.Fragment>
                <th>
                  <a href="#!" className="text-muted sort">
                    Client Cost (Including gst)
                  </a>
                </th>
                <th>
                  <a href="#!" className="text-muted sort">
                    Creator Earnings
                  </a>
                </th>
                {artistId !== "/transactions/completeTransactions" && (
                  <th>
                    <a href="#!" className="text-muted sort">
                      Total Paid
                    </a>
                  </th>
                )}
                <th>
                  <a href="#!" className="text-muted sort">
                    Number Of Milestone
                  </a>
                </th>
                {artistId !== "/transactions/completeTransactions" && (
                  <th>
                    <a href="#!" className="text-muted sort">
                      {artistId === "/transactions/closedTransactions"
                        ? "Milestone Completed"
                        : "Current Milestone"}
                    </a>
                  </th>
                )}
                {artistId !== "/transactions/completeTransactions" &&
                  artistId !== "/transactions/closedTransactions" &&
                  artistId !== "/transactions/DeletedProjects" && (
                    <th>
                      <a href="#!" className="text-muted sort">
                        Next Deadline Date
                      </a>
                    </th>
                  )}
                <th>
                  <a href="#!" className="text-muted sort">
                    Name (Creator)
                  </a>
                </th>
                <th>
                  <a href="#!" className="text-muted sort">
                    Email (Creator)
                  </a>
                </th>
              </React.Fragment>
            )}
            {artistId === "/transactions/ActiveTransactions" && (
              <React.Fragment>
                <th>
                  <a href="#!" className="text-muted sort">
                    Last Message By
                  </a>
                </th>
                <th>
                  <a href="#!" className="text-muted sort">
                    Last Submission (Milestone)
                  </a>
                </th>
              </React.Fragment>
            )} */}
            {/*------------------------Proposal data ------------------*/}

            {/*--------------------- Payment offline-------------------*/}
             {/* { artistId === "/transactions" &&
               <th>
                <a href="#!" className="text-muted sort">
                  Payment Offline
                </a>
               </th>
             }
             { (artistId === "/transactions/ActiveTransactions" ||
                artistId === "/transactions/completeTransactions" ||
                artistId === "/transactions/closedTransactions") && 
               <th>
                <a href="#!" className="text-muted sort"> 
                  Payment Mode
                </a>
               </th>
            }
            {(artistId === "/transactions/ClosedBrief" ||
                artistId === "/transactions/ActiveTransactions" ||
                artistId === "/transactions" ||
                artistId === "/transactions/completeTransactions" ||
                artistId === "/transactions/closedTransactions") && (
              <th>
                <a href="#!" className="text-muted sort">
                  3 Proposals Date
                </a>
              </th>
              )}
            {(artistId === "/transactions/ClosedBrief" ||
                artistId === "/transactions/ActiveTransactions" ||
                artistId === "/transactions" ||
                artistId === "/transactions/completeTransactions" ||
                artistId === "/transactions/closedTransactions") && (
              <th>
                <a href="#!" className="text-muted sort">
                  Proposals Within 24
                </a>
              </th>
              )} */}
            {/*--------------------- Payment offline-------------------*/}            
          </tr>
        </thead>
      </React.Fragment>
    );
  }
}
