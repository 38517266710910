import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import UploadMedia from './UploadMedia';
import { getPendingProjectData } from "../../../../store/action/projectList.action";
import { connect } from "react-redux";
import moment from "moment";
Modal.setAppElement("#root");
Modal.defaultStyles.overlay.backgroundColor = "rgb(0 0 0 / 50%)";
Modal.defaultStyles.overlay.zIndex = "9999";

 class Table extends Component {
  
  state={
    showModalIs : false,
    projectId : '',
    ModalData : [],
  }

  setMediaState = (stateObject) => {
    this.setState(stateObject);
  };

  handleGetUploadProject = (id) => {
    this.props.$getPendingProjectData(id);
  };

  handleshowMediaModal = (id) => {
    const { showModalIs } = this.state;
    this.setState({ showModalIs: !showModalIs, projectId : id });
    this.props.handleProject.setArtistState({projectIdIs : id})
    this.handleGetUploadProject(id);
  };

  handleCloseMediaModal = () => {
    this.setState({ showModalIs: false });
  };

  componentWillReceiveProps(nextProps) {
    const projectData = nextProps._getPendingProjectData.getPendingData;
    if (projectData) {
      this.setState({
        ModalData : projectData
      });
    }
  }

  render() {
    const {
      projectDataIs,
      handleProject,
    } = this.props;

    const ProjectArr = [
      { name: "Action" },
      { name: "project name" },
      { name: "creator name" },
      { name: "creator username" },
      { name: "created for" },
      { name: "upload/update date" },      
    ];

    const allFunc = {
      handleCloseMediaModal : this.handleCloseMediaModal,
      setMediaState : this.setMediaState
    };

    return (
      <React.Fragment>
        <div className="table-responsive">
          <table className="table table-sm table-nowrap card-table">
            {/*----------------- table head --------------------*/}
            <thead>
              <tr>
                {ProjectArr.map((obj, index) => (
                  <React.Fragment key={index}>
                  {obj.name !== "upload/update date" &&
                   <th key={index}>
                    <Link to="#!" className="text-muted">
                      {obj.name}
                    </Link>
                  </th>}
                  {obj.name === "upload/update date" && 
                  <th key={index}>
                    <Link to="#!" className="text-muted sort" data-sort="tables-row" 
                    onClick={handleProject.handleSorting}
                  >
                      {obj.name}
                    </Link>
                  </th>}
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            {/*----------------- table head --------------------*/}
            <tbody className="list">
              {projectDataIs.map((obj, index) => (
                <tr key={index}>
                  <td className="text-center">
                    <div className={`dropdown`}>
                      <a
                        href="#!"
                        className="dropdown-ellipses dropdown-toggle text-secondary"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fe fe-more-vertical"></i>
                      </a>
                      
                        <div className={`ma-auto dropdown-menu dropdown-menu-right text-center`}>
                          {obj.status === 0 && (
                            <span>
                              <Link
                                to="#!"
                                className="dropdown-item"
                                datatoggle="modal"
                                datatarget="#exampleModalCenter"
                                onClick={() => handleProject.handleStatusProject( obj._id,"accept")}
                              >
                                Accept
                              </Link>
                              <Link
                                to="#!"
                                className="dropdown-item mb-2"
                                onClick={() => handleProject.handleStatusProject(obj._id, "reject")}
                              >
                                Reject
                              </Link>
                              <Link
                                to={`#!`}
                                className="dropdown-item mb-2"
                                data-toggle="modal"
                                data-target="#media-embdded"
                                onClick={()=>this.handleshowMediaModal(obj._id)}
                              >
                                View
                              </Link>
                            </span>
                          )}
                        </div>
                    </div>
                  </td>
                  <td className="orders-order">{obj.title}</td>
                  <td className="orders-product">
                    {obj.user.first_name + " " + obj.user.last_name}
                  </td>
                  <td className="orders-date">{obj.user.username}</td>
                  <td className="orders-total">{obj.created_type}</td>
                  <td className="orders-method">
                    {obj?.updatedAt && moment(obj?.updatedAt).format("DD/MM/YYYY")}
                  </td>                  
                </tr>
              ))}
            </tbody>
          </table>

          {/*-----------------modal----------------*/}
          {this.state.showModalIs && <UploadMedia {...this.state} {...this.props} handleFunction={allFunc}/> }

        </div>
      </React.Fragment>
    );
  }
}
const mapState = ({ getPendingProjectData }) => {
  return {
    _getPendingProjectData: getPendingProjectData,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $getPendingProjectData: (values) =>
    dispatchEvent(getPendingProjectData(values)),
});
export default connect(mapState, mapDispatch)(Table);
