import React, { Component } from "react";
import { connect } from "react-redux";
import { getArtistData } from "../../../store/action/clientList.action";
import ErrorApi from "../Common/Error/ErrorApi";

class ArtistEducation extends Component {
  state = {
    artistEducationDta: [],
    institute: "",
    course: "",
    startMonth: "",
    startYear: "",
    endMonth: "",
    endYear: "",
    instituteId: "",
    instituteList: false,
    educationEditIs: true,
    eduIndexIs: "",
    LoadingIs: false,
  };

  handleOnChange = (e) => {
    const { instituteId } = this.state;
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    if (name === "institute" && instituteId) {
      this.handleInstituteData();
    }
  };

  handleInstituteData = () => {
    const { instituteList } = this.state;
    if (instituteList) {
      this.setState({
        instituteList: !instituteList,
      });
    }
  };

  handleEditOnClick = (index, obj) => {
    this.setState({
      educationEditIs: false,
      eduIndexIs: index,
      course: obj.course,
      startMonth: obj.start_month,
      startYear: obj.start_year,
      endMonth: obj.end_month,
      endYear: obj.end_year,
      institute: obj.Institute_name,
    });
  };

  // handleUpdateOnClick = (index) => {
  //   console.log(":::123");
  // };

  handleEducationSelection = (obj) => {
    this.setState({
      institute: obj,
      instituteList: false,
    });
  };

  handleGetArtistEducation = (id) => {
    this.props.$getArtistData(id);
    this.setState({
      LoadingIs: true,
    });
  };

  componentDidMount() {
    // this.props.handleClients.handlepathName("/artist");
    const artistParamsId = this.props.match.params.id.split(":").pop();
    this.handleGetArtistEducation(artistParamsId);
    // this.props.handleClients.mainIndexstate({ profileUpdates: true });
  }

  componentWillReceiveProps(nextProps) {
    const creatorData = nextProps._getArtistData.getCreatorData;
    if (creatorData && creatorData.status === "1") {
      this.setState({
        artistEducationDta: creatorData.userEducation,
        LoadingIs: false,
      });
    }
  }

  render() {
    const monthArr = [
      { name: "Jan" },
      { name: "Feb" },
      { name: "Mar" },
      { name: "Apr" },
      { name: "May" },
      { name: "June" },
      { name: "July" },
      { name: "Aug" },
      { name: "Sep" },
      { name: "Oct" },
      { name: "Nov" },
      { name: "Dec" },
    ];

    const yearArr = [
      { name: "2010" },
      { name: "2011" },
      { name: "2012" },
      { name: "2013" },
      { name: "2014" },
      { name: "2015" },
      { name: "2016" },
      { name: "2017" },
      { name: "2018" },
      { name: "2019" },
      { name: "2020" },
    ];

    const educationArr = [
      { name: "Institute" },
      { name: "course" },
      { name: "start month" },
      { name: "start year" },
      { name: "end month" },
      { name: "end year" },
    ];

    const {
      artistEducationDta,
      educationEditIs,
      eduIndexIs,
      institute,
      course,
      LoadingIs,
      startMonth,
      startYear,
      endMonth,
      endYear,
      instituteList,
    } = this.state;
    return (
     this.props._getArtistData.getArtistError ? (
          <ErrorApi {...this.props} />
        ) : (
          <React.Fragment>
            {LoadingIs ? (
              <div className="col-md-12">
                <div className="d-flex justify-content-center align-items-center">
                  <h3>Loading.....</h3>
                </div>
              </div>
            ) : (
              <section className="container-fluid">
                <div className="table-responsive">
                  <table className="table table-sm table-nowrap card-table">
                    {/*---------------table head--------------------*/}
                    <thead>
                      <tr>
                        {educationArr.map((obj, index) => (
                          <th key={index}>
                            <a href="!#" className="text-muted sort">
                              {obj.name}
                            </a>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    {/*---------------table head--------------------*/}
                    <tbody className="list">
                      {artistEducationDta.map((obj, index) => (
                        <tr key={index}>
                          <td className="orders-order">
                            <input
                              className="form-control"
                              placeholder="Institute"
                              name="institute"
                              autoComplete="off"
                              value={
                                eduIndexIs === index
                                  ? institute
                                  : obj.Institute_name
                              }
                              onChange={this.handleOnChange}
                              onClick={this.handleInstituteData}
                              disabled={educationEditIs ? "disabled" : ""}
                            />
                            {instituteList && eduIndexIs === index && (
                              <table style={{ position: "relative" }}>
                                <tbody>
                                  <tr>
                                    <td>
                                      <ul
                                        className={`list-group list-group-sm scroll-list`}
                                        style={{
                                          boxShadow: "0px 0px 3px 0px silver",
                                          left: "0px",
                                        }}
                                        // pcf-list
                                      >
                                        <li
                                          className="list-group-item"
                                          onClick={() =>
                                            this.handleEducationSelection(
                                              "Cliffex pvt ltd"
                                            )
                                          }
                                        >
                                          Cliffex pvt ltd.
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            )}
                          </td>
                          <td className="orders-product">
                            <input
                              className="form-control"
                              placeholder="Course"
                              name="course"
                              value={eduIndexIs === index ? course : obj.course}
                              onChange={this.handleOnChange}
                              disabled={educationEditIs ? "disabled" : ""}
                            />
                          </td>
                          <td className="orders-date">
                            <select
                              className="form-control mx-auto"
                              name="startMonth"
                              value={
                                eduIndexIs === index
                                  ? startMonth
                                  : obj.start_month
                              }
                              onChange={this.handleOnChange}
                              disabled={educationEditIs ? "disabled" : ""}
                            >
                              <option>Select options</option>
                              {monthArr.map((obj, index) => (
                                <option key={index}>{obj.name}</option>
                              ))}
                            </select>
                          </td>
                          <td className="orders-total">
                            <select
                              className="form-control mx-auto"
                              name="startYear"
                              value={
                                eduIndexIs === index
                                  ? startYear
                                  : obj.start_year
                              }
                              onChange={this.handleOnChange}
                              disabled={educationEditIs ? "disabled" : ""}
                            >
                              <option>Select options</option>
                              {yearArr.map((obj, index) => (
                                <option key={index}>{obj.name}</option>
                              ))}
                            </select>
                          </td>
                          <td className="orders-status">
                            <select
                              className="form-control"
                              name="endMonth"
                              value={
                                eduIndexIs === index ? endMonth : obj.end_month
                              }
                              onChange={this.handleOnChange}
                              disabled={educationEditIs ? "disabled" : ""}
                            >
                              <option>Select options</option>
                              {monthArr.map((obj, index) => (
                                <option key={index}>{obj.name}</option>
                              ))}
                            </select>
                          </td>
                          <td className="orders-method">
                            <select
                              className="form-control mx-auto"
                              name="endYear"
                              value={
                                eduIndexIs === index ? endYear : obj.end_year
                              }
                              onChange={this.handleOnChange}
                              disabled={educationEditIs ? "disabled" : ""}
                            >
                              <option>Select options</option>
                              {yearArr.map((obj, index) => (
                                <option key={index}>{obj.name}</option>
                              ))}
                            </select>
                          </td>
                          {/* <td className="text-method">
                        <Link to="#">
                          {educationEditIs ? (
                            <button
                              className="btn btn-outline-info btn-sm mr-2"
                              onClick={() => this.handleEditOnClick(index, obj)}
                            >
                              <i className="fe fe-edit"></i>
                            </button>
                          ) : (
                            <button
                              className="btn btn-outline-info btn-sm mr-2"
                              onClick={() => this.handleUpdateOnClick(index)}
                            >
                              <p className="mb-0">Update</p>
                            </button>
                          )}
                        </Link>
                        <Link to="#">
                          <button className="btn btn-outline-danger btn-sm">
                            <i className="fe fe-trash"></i>
                          </button>
                        </Link>
                      </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </section>
            )}
          </React.Fragment>
        )
    );
  }
}

const mapState = ({ getArtistData }) => {
  return {
    _getArtistData: getArtistData,
  };
};
const mapDispatch = (dispatchEvent) => ({
  $getArtistData: (values) => dispatchEvent(getArtistData(values)),
});
export default connect(mapState, mapDispatch)(ArtistEducation);
