import { LOGIN_DATA_SUCCESS, LOGIN_DATA_ERROR, RESET_PWD_SUCCESS, RESET_PWD_ERROR, GET_ADMIN_USER_DATA_SUCCESS, GET_ADMIN_USER_DATA_ERR } from "../type.action";

const initState = {
    Loading: false,
    tokenIs : '',
    loginErrStatus: '',
    resetPwdIs: '',
    resetPwderr: '',
    adminData:'',
    adminDataErr:''
};

const reducers = (state = initState, { type, values, error }) => {
  switch (type) {
    case LOGIN_DATA_SUCCESS:
      return {
          ...state,
          tokenIs : values,
          Loading: false
      };
    case LOGIN_DATA_ERROR:
      return{
        ...state,
        loginErrStatus : error,
        Loading: true
      } 
    case RESET_PWD_SUCCESS:
      return{
        ...state,
        resetPwdIs : values,
        Loading : false
      }
     case RESET_PWD_ERROR:
       return{
         ...state,
         resetPwderr : error,
         Loading: true
       }    
      case GET_ADMIN_USER_DATA_SUCCESS:
        return {
          ...state,
          adminData:values,
        }
        case GET_ADMIN_USER_DATA_ERR:
          return {
            ...state,
            adminDataErr:error,
          }
    default:
      return state;
  }
};

export default reducers;